export enum StipulationType {
  FINANCIAL = 'financial',
  NON_FINANCIAL = 'non financial'
}

export enum StipulationStatus {
  SUBMITTED = 'submitted',
  PENDING = 'pending',
  DRAFT = 'draft'
}

export enum HeaderButton {
  RE_SUBMIT_ORDER = 'Re-Submit Deal',
  SAVE_CHANGES = 'Save Changes'
}

export enum FinancialStipType {
  TERMS = 'Terms',
  PERIODIC_PAYMENT = 'Monthly Payment',
  FINANCED_AMOUNT = 'Financed Amount'
}

export enum StipCodes {
  TERMS = 'ST003'
}
