import { useEffect, useState } from 'react'
import { FC } from 'react'
import { useTheme } from '@mui/material'
import { AddressLayout, ContactNumInput, Grid } from '@ntpkunity/controls'
import { accessToken } from '@helpers/constants'
import { useStoreContext } from '@store/storeContext'
import { Controller } from 'react-hook-form'
import { IStates } from '@models'
import { ICountry } from 'models/country.interface'

interface IAddressComponentProps {
  isGoogleEnabled: boolean
  form: any
  addressValue?: string
  errorProps?: any
  setIsAddressDirty?: any
  moveIn?: boolean
  control?: any
  showContactNumber?: boolean
  contactError?: string
  hideMoveInHelper?: boolean
  dealerCode?: string
  placeholders?: any
  isContactRequired?: boolean
  isAddressTypeRequired?: boolean
}

const AddressDetailsComponent: FC<IAddressComponentProps> = ({
  isGoogleEnabled,
  form,
  addressValue,
  setIsAddressDirty,
  errorProps,
  moveIn = true,
  control,
  showContactNumber = false,
  contactError,
  hideMoveInHelper = false,
  dealerCode,
  isContactRequired = false,
  placeholders,
  isAddressTypeRequired
}) => {
  const theme = useTheme()
  const { setValue, watch } = form
  const { states } = useStoreContext()

  const [statesData, setStatesData] = useState<IStates[]>([])
  const [countriesData, setCountriesData] = useState<ICountry[]>([])
  const [dealerCountry, setDealerCountry] = useState<Array<any>>([])
  const [initialAddressValues, setInitialAddressValues] = useState<any>(null)

  useEffect(() => {
    if (addressValue) {
      const initialValues = watch(addressValue)
      setInitialAddressValues(initialValues)
    }
  }, [addressValue, watch])

  useEffect(() => {
    if (initialAddressValues && addressValue) {
      const currentValues = watch(addressValue)
      const isAddressDirty = Object.keys(currentValues).some(
        (key) => currentValues[key] !== initialAddressValues[key]
      )

      if (isAddressDirty) {
        setIsAddressDirty?.(true)
        setValue(addressValue, currentValues, { shouldDirty: true })
      }
    }
  }, [initialAddressValues, setValue, setIsAddressDirty, addressValue, watch])

  useEffect(() => {
    setCountriesData(states?.dealDesk?.countries)
    setStatesData(states?.dealDesk?.states)
  }, [states?.dealDesk?.states, states?.dealDesk?.countries])

  useEffect(() => {
    setDealerCountry(
      countriesData?.filter(
        (x: any) => x?.id == states?.dealDesk?.dealerProfile?.dealer_address?.country_id
      )
    )
  }, [countriesData, states?.dealerProfileData])

  return (
    <>
      {dealerCountry?.length > 0 && statesData?.length > 0 ? (
        <>
          <AddressLayout
            addressVerificationRequired={isGoogleEnabled}
            hideAddressButton={!isGoogleEnabled}
            theme={theme}
            state={{
              data: statesData,
              selectedItemObject: { text: 'name', value: 'name' },
              controlName: 'state_name'
            }}
            countries={dealerCountry}
            validationProps={errorProps}
            isContactRequired={isContactRequired}
            value={watch(`${addressValue}`)}
            verifyAddressURL={`${process.env.INTEGRATION_API_GATEWAY}/api/verify-address/`}
            token={JSON.parse(localStorage.getItem(accessToken) as string)?.access_token}
            dealerCode={dealerCode}
            setIsAddressDirty={setIsAddressDirty}
            onChange={(e: any) => {
              setValue(`${addressValue}`, e, { shouldDirty: true, shouldValidate: true })
              setValue(`${addressValue}.state_name`, e?.state_name, { shouldDirty: true, shouldValidate: true })
              setValue(`${addressValue}.state_id`, e?.state_id, { shouldDirty: true, shouldValidate: true })
            }}
            showCountryDropdown={false}
            disableCounty={isGoogleEnabled}
            moveInRequired={moveIn}
            hideMoveInHelper={hideMoveInHelper}
            placeholders={placeholders}
            zipCodeErrorMessage={errorProps?.zip_code?.message}
            isAddressTypeRequired={isAddressTypeRequired}
          ></AddressLayout>
          {showContactNumber && (
            <Grid theme={theme} item xs={12} mt={3}>
              <Controller
                name="agent_phone"
                control={control}
                render={({ field }) => (
                  <ContactNumInput
                    theme={theme}
                    {...field}
                    inputLabel={`Contact Number`}
                    countriesList={[...dealerCountry.map((country: any) => country?.country_code.toLowerCase())]}
                    country={dealerCountry[0]?.country_code.toLowerCase()}
                    id={'agent_phone'}
                    disabled={false}
                    errorMessage={contactError}
                    value={field.value ?? ''}
                  />
                )}
              />
            </Grid>
          )}
        </>
      ) : null}
    </>
  )
}
export default AddressDetailsComponent
