import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import {
  Box,
  Typography,
  Button,
  // Tooltip,
  Icon,
  Dialog,
  Menu
} from '@ntpkunity/controls'
import { DocumentViewModal, DocumentViewer } from '@ntpkunity/controls-common'
import { DetailCard, Grid, MultiSelect } from 'components'
import {
  useGetAllPreSignedUrls,
  useMutateUploadDocument,
  useUpdateUnlockDocumentPackage,
  UseQuery_GetTemplatesByEvent
} from '@apis/order-management.service'
import { Events, QueryKeys, Status, ToastMessages } from '@helpers/enums'
import ContractDocuments from './contract-documents'
import { capitalizeWords, downloadDocument } from '@helpers/methods'
import { ContractstWrap } from './contracts.style'
import { PDFDocument } from 'pdf-lib'
import printJS from 'print-js'
import { SignerRole } from '@helpers/enums/contract-documents.enum'
import { useStoreContext } from '@store/storeContext'
import { useQueryClient } from 'react-query'
import DisableLoader from '@public/assets/images/loader-disabled.gif'

interface IContractsProps {
  orderStatus: string
  dealerId: string
  referenceNumber: string
  referenceId: string
  contractDocument?: any
  generalDocument?: any
  creditDocument?: any
  setApprovedDoc?: any
  orderObject?: any
  disableOptions?: boolean
  coApplicantReferenceId?: string
}
const Contracts: FC<IContractsProps> = ({
  dealerId,
  referenceNumber,
  referenceId,
  contractDocument,
  generalDocument,
  creditDocument,
  setApprovedDoc,
  orderObject,
  disableOptions = false,
  coApplicantReferenceId
}) => {
  const { actions, states } = useStoreContext()

  const queryClient = useQueryClient()
  const company_id: number = (queryClient.getQueryData(QueryKeys.GET_LENDER_PROFILE) as any)?.id
  const { mutate: getAllPreSignedUrls } = useGetAllPreSignedUrls()
  const { mutate: uploadDocument, isLoading } = useMutateUploadDocument()
  const { mutate: updateUnlockDocumentPackage } = useUpdateUnlockDocumentPackage()
  const theme = useTheme()
  const [openDocumentViewer, setOpenDocumentViewer] = useState<boolean>(false)
  const [viewDocument, setViewDocument] = useState<any>({})
  const [openDocumentViewMode, setOpenDocumentViewMode] = useState<boolean>(false)
  const [signerRole, setSignerRole] = useState<SignerRole>(SignerRole.DEALER_ADMIN)
  const [openPopUp, setOpenPopUp] = useState<boolean>(false)
  const [selectedTemplate, setSelectedTemplate] = useState<Array<any>>([])
  const [documents, setDocuments] = useState<Array<any>>([])
  const [error, setError] = useState('')
  const getQueryString = () => {
    return `order_reference=${referenceNumber}&dealer_code=${
      states?.dealerAndLenderInfo?.dealer_code
    }&finance_type=${orderObject?.finance_type}&document_event=${
      orderObject?.has_insurance ? Events.INSURANCE_RECEIVED : Events.DEAL_APPROVED
    }&dealer_state_id=${states?.dealDesk?.dealerProfile?.dealer_address?.state_id}`
  }
  const [queryData, setQueryData] = useState(getQueryString())
  const { data: templatesData, isFetching: fetchingTemplatesData } = UseQuery_GetTemplatesByEvent(queryData, company_id)
  useEffect(() => {
    if (viewDocument && Object.keys(viewDocument).length > 0 && !openDocumentViewer) {
      if (setApprovedDoc) {
        setApprovedDoc(!openDocumentViewer)
      }
    }

    if (!openDocumentViewer && !openDocumentViewMode) {
      queryClient.invalidateQueries(QueryKeys.GET_ORDER_DOCUMENT_PACKAGES)
    }
  }, [openDocumentViewer])

  const handleClose = () => {
    setOpenDocumentViewMode(false)
  }
  const hasCreditDocuments = creditDocument?.some(
    (documentData: any) => documentData?.documents?.length > 0
  )

  const handleDocumentSigningSession = (document: any) => {
    updateUnlockDocumentPackage(
      { order_id: orderObject?.reference_number, doc_package_id: document?.identifier },
      {
        onSuccess() {
          queryClient.invalidateQueries(QueryKeys.GET_ORDER_DOCUMENT_PACKAGES)
        }
      }
    )
  }
  const downloadAllDocuments = async (documents: Array<any>) => {
    const downloadPromises = documents.map((document) =>
      downloadDocument(document.presigned_url, document.key)
    )
    await Promise.all(downloadPromises)
  }
  const mergeAndPrintPDFs = async (urls: string[]) => {
    const mergedPdf = await PDFDocument.create()

    for (const url of urls) {
      const response = await fetch(url)
      const pdfBytes = await response.arrayBuffer()
      const pdf = await PDFDocument.load(pdfBytes)
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices())
      copiedPages.forEach((page) => mergedPdf.addPage(page))
    }

    const mergedPdfBytes = await mergedPdf.save()
    const mergedPdfBlob = new Blob([mergedPdfBytes], { type: 'application/pdf' })
    const mergedPdfUrl = URL.createObjectURL(mergedPdfBlob)

    printJS({ printable: mergedPdfUrl, type: 'pdf', showModal: false })
  }

  const handleChange = (value: any) => {
    if (value.length === 0) {
      setError('Document(s) are required')
    } else {
      setError('')
    }
    setSelectedTemplate(value)
  }
  const handleUpload = () => {
    const data = templatesData?.result?.filter((item: any) => selectedTemplate?.includes(item.id))
    uploadDocument(
      {
        documents: data,
        order_reference: referenceNumber,
        document_event: orderObject?.has_insurance
          ? Events.INSURANCE_RECEIVED
          : Events.DEAL_APPROVED
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(QueryKeys.GET_ORDER_DOCUMENT_PACKAGES)
          setOpenPopUp(false)
          actions.setToast({
            toastMessage: ToastMessages.Record_Added_Success,
            toastState: true
          })
        },
        onError(error: any) {
          setOpenPopUp(false)
          actions.setToast({
            toastMessage: error?.response?.data?.detail ?? ToastMessages.INTERNAL_SERVER_ERROR,
            toastState: true,
            variant: 'error'
          })
        }
      }
    )
  }
  return (
    <>
      {contractDocument &&
        Array.isArray(contractDocument) &&
        contractDocument?.[0] &&
        contractDocument?.[0]?.documents &&
        contractDocument?.[0]?.documents?.length > 0 && (
          <DetailCard className="detail-card" mb={2}>
            <Box theme={theme} display={'flex'} justifyContent={'space-between'} mb={2}>
              <Box theme={theme}>
                <Typography
                  component="h4"
                  variant="h4"
                  theme={theme}
                  children={
                    orderObject?.status === Status.Approved ? 'Generated Documents' : 'Documents'
                  }
                />
                {contractDocument?.[0]?.is_locked ? (
                  <Typography component="p" variant="caption" theme={theme} className="text-danger">
                    {'Please unlock the document signing session for the customer.'}
                  </Typography>
                ) : null}
              </Box>
              <Menu
                sx={{ ml: 2 }}
                theme={theme}
                options={[
                  {
                    optionText: <>Download Documents</>,
                    optionkey: 'Download',
                    optionValue: document
                  },
                  {
                    optionText: <>Print Documents</>,
                    optionkey: 'Print',
                    optionValue: document
                  },
                  {
                    optionText: <>Unlock Signing Session</>,
                    optionkey: 'UnlockSigningSession',
                    optionValue: contractDocument?.[0],
                    disabled: !contractDocument?.[0]?.is_locked
                  },
                  {
                    optionText: <>Upload Documents</>,
                    optionkey: 'Upload',
                    optionValue: document
                  }
                ]}
                handleOptionClick={(_event: any, key: any, value: any) => {
                  switch (key) {
                    case 'Download':
                      const documents = contractDocument?.[0]?.documents
                        .map((document: any) => `keys=${document.generated_document_uri}`)
                        ?.join('&')
                      getAllPreSignedUrls(documents, {
                        onSuccess: (res: any) => {
                          downloadAllDocuments(res)
                        }
                      })
                      break
                    case 'Print':
                      const allDocuments = contractDocument?.[0]?.documents
                        .map((document: any) => `keys=${document.generated_document_uri}`)
                        ?.join('&')
                      getAllPreSignedUrls(allDocuments, {
                        onSuccess: (res: any) => {
                          mergeAndPrintPDFs(res?.map((x: any) => x.presigned_url))
                        }
                      })

                      break
                    case 'UnlockSigningSession':
                      if (value) {
                        handleDocumentSigningSession(value)
                      }
                      break
                    case 'Upload':
                      setQueryData(getQueryString())
                      setDocuments(
                        contractDocument?.[0]?.documents?.map((item: any) => item?.title)
                      )
                      setOpenPopUp(true)
                      break
                    default:
                      break
                  }
                }}
                render={(handleOptionClick: any) => (
                  <Box theme={theme}>
                    <Button
                      theme={theme}
                      defaultBtn
                      iconText={<Icon name="MoreIcon" />}
                      onClick={handleOptionClick}
                    />
                  </Box>
                )}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              />
            </Box>
            {contractDocument?.[0]?.documents?.length > 0 && (
              <Box className="card-body" theme={theme}>
                <Box className="row" mb={3} theme={theme}>
                  <Typography
                    component="b"
                    variant="caption"
                    theme={theme}
                    className="fw-600 text-uppercase"
                    children={`${capitalizeWords(
                      contractDocument?.[0]?.signature_type
                    )} Signature Document(s)`}
                  />
                </Box>
                {contractDocument?.[0]?.documents?.map((document: any, index: number) => (
                  <Box theme={theme} key={index} className="secondary-wrap with-border">
                    {orderObject?.has_insurance && orderObject?.status === Status.Approved ? (
                      <ContractDocuments
                        document={document}
                        setOpenDocumentViewer={setOpenDocumentViewer}
                        setSignerRole={setSignerRole}
                        setViewDocument={setViewDocument}
                        setOpenDocumentViewMode={setOpenDocumentViewMode}
                        hasInsurance={orderObject?.Insurance_Inforation ? true : ''}
                        type={contractDocument?.[0]?.type}
                        isLocked={contractDocument?.[0]?.is_locked}
                        isOptionsDisabled={disableOptions || fetchingTemplatesData}
                      />
                    ) : (
                      <ContractDocuments
                        document={document}
                        setOpenDocumentViewer={setOpenDocumentViewer}
                        setSignerRole={setSignerRole}
                        setOpenDocumentViewMode={setOpenDocumentViewMode}
                        setViewDocument={setViewDocument}
                        hasInsurance={orderObject?.Insurance_Inforation ? true : ''}
                        type={contractDocument?.[0]?.type + '_'}
                        isLocked={contractDocument?.[0]?.is_locked}
                        isOptionsDisabled={disableOptions || fetchingTemplatesData}
                      />
                    )}
                  </Box>
                ))}
              </Box>
            )}
          </DetailCard>
        )}
      {((generalDocument &&
        Array.isArray(generalDocument) &&
        generalDocument?.[0] &&
        generalDocument?.[0]?.documents &&
        generalDocument?.[0]?.documents?.length > 0) ||
        (creditDocument && Array.isArray(creditDocument) && hasCreditDocuments)) && (
        <DetailCard className="detail-card" mb={2}>
          <Box theme={theme} display={'flex'} justifyContent={'space-between'} mb={2}>
            <Box theme={theme}>
              <Typography
                component="h4"
                variant="h4"
                theme={theme}
                children={orderObject?.has_insurance ? 'Documents' : 'Generated Documents'}
              />
              {generalDocument?.[0]?.is_locked || creditDocument?.[0]?.is_locked ? (
                <Typography component="p" variant="caption" theme={theme} className="text-danger">
                  {'Please unlock the document signing session for the customer.'}
                </Typography>
              ) : null}
            </Box>
            {!orderObject?.has_insurance && (
              <Menu
                sx={{ ml: 2 }}
                theme={theme}
                options={[
                  {
                    optionText: <>Download Documents</>,
                    optionkey: 'Download',
                    optionValue: document,
                    disabled: !!disableOptions
                  },
                  {
                    optionText: <>Print Documents</>,
                    optionkey: 'Print',
                    optionValue: document,
                    disabled: !!disableOptions
                  },
                  {
                    optionText: <>Unlock Signing Session</>,
                    optionkey: 'UnlockSigningSession',
                    optionValue: generalDocument?.[0] ?? creditDocument?.[0],
                    disabled: !(generalDocument?.[0]?.is_locked || creditDocument?.[0]?.is_locked) || !!disableOptions
                  },
                  {
                    optionText: <>Upload Documents</>,
                    optionkey: 'Upload',
                    optionValue: document,
                    disabled: !!disableOptions
                  }
                ]}
                handleOptionClick={(_event: any, key: any, value: any) => {
                  switch (key) {
                    case 'Download':
                      const getDocument = [
                        ...(generalDocument?.[0]?.documents ?? []),
                        creditDocument?.[0]?.documents ?? []
                      ]
                      const documents = getDocument
                        ?.map((document: any) => `keys=${document.generated_document_uri}`)
                        ?.join('&')
                      getAllPreSignedUrls(documents, {
                        onSuccess: (res: any) => {
                          downloadAllDocuments(res)
                        }
                      })
                      break
                    case 'Print':
                      const getDocuments = [
                        ...(generalDocument?.[0]?.documents ?? []),
                        creditDocument?.[0]?.documents ?? []
                      ]
                      const document = getDocuments
                        ?.map((document: any) => `keys=${document.generated_document_uri}`)
                        ?.join('&')
                      getAllPreSignedUrls(document, {
                        onSuccess: (res: any) => {
                          mergeAndPrintPDFs(res?.map((x: any) => x.presigned_url))
                        }
                      })
                      break
                    case 'UnlockSigningSession':
                      if (value) {
                        if (generalDocument?.[0]?.documents?.length > 0) {
                          handleDocumentSigningSession(generalDocument?.[0])
                        }
                        if (creditDocument?.[0]?.documents?.length > 0) {
                          handleDocumentSigningSession(creditDocument?.[0])
                        }
                      }
                      break
                    case 'Upload':
                      setQueryData(getQueryString())
                      setDocuments(
                        (generalDocument?.[0]?.documents ?? [])
                          .concat(creditDocument?.[0]?.documents ?? [])
                          ?.map((item: any) => item?.title)
                      )
                      setOpenPopUp(true)
                      break
                    default:
                      break
                  }
                }}
                render={(handleOptionClick: any) => (
                  <Box theme={theme}>
                    <Button
                      theme={theme}
                      defaultBtn
                      iconText={<Icon name="MoreIcon" />}
                      onClick={handleOptionClick}
                    />
                  </Box>
                )}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              />
            )}
          </Box>
          {generalDocument?.[0]?.documents?.length > 0 && (
            <Box className="card-body" theme={theme}>
              <Box className="row" mb={3} theme={theme}>
                <Typography
                  component="b"
                  variant="caption"
                  theme={theme}
                  className="fw-600 text-uppercase"
                  children={`${capitalizeWords(
                    generalDocument?.[0]?.signature_type
                  )} Signature Document(s)`}
                />
              </Box>
              {generalDocument?.[0]?.documents?.map((document: any, index: number) => (
                <Box theme={theme} key={index} className="secondary-wrap with-border">
                  {orderObject?.has_insurance ? (
                    <ContractDocuments
                      document={document}
                      isOptionsDisabled={disableOptions || fetchingTemplatesData}
                      setSignerRole={setSignerRole}
                      setOpenDocumentViewer={setOpenDocumentViewer}
                      setViewDocument={setViewDocument}
                      setOpenDocumentViewMode={setOpenDocumentViewMode}
                      hasInsurance={orderObject?.Insurance_Inforation ? true : ''}
                      type={generalDocument?.[0]?.type}
                      isLocked={generalDocument?.[0]?.is_locked}
                    />
                  ) : (
                    <ContractDocuments
                      isOptionsDisabled={disableOptions || fetchingTemplatesData}
                      document={document}
                      setSignerRole={setSignerRole}
                      setOpenDocumentViewer={setOpenDocumentViewer}
                      setOpenDocumentViewMode={setOpenDocumentViewMode}
                      setViewDocument={setViewDocument}
                      hasInsurance={false}
                      type={generalDocument?.[0]?.type}
                      isLocked={generalDocument?.[0]?.is_locked}
                    />
                  )}
                </Box>
              ))}
            </Box>
          )}
          {hasCreditDocuments && (
            <Box className="card-body" theme={theme}>
              <Box className="row" mb={3} theme={theme}>
                <Typography
                  component="b"
                  variant="caption"
                  theme={theme}
                  className="fw-600 text-uppercase"
                  children={`${capitalizeWords(
                    creditDocument?.[0]?.signature_type
                  )} Signature Document(s)`}
                />
              </Box>

              {creditDocument?.map(
                (documentData: any) =>
                  documentData?.documents?.length > 0 &&
                  documentData?.documents?.map((document: any, docIndex: number) => (
                    <Box theme={theme} key={docIndex} className="secondary-wrap with-border">
                      {orderObject?.has_insurance ? (
                        <ContractDocuments
                          isOptionsDisabled={disableOptions || fetchingTemplatesData}
                          document={document}
                          setSignerRole={setSignerRole}
                          setOpenDocumentViewer={setOpenDocumentViewer}
                          setViewDocument={setViewDocument}
                          setOpenDocumentViewMode={setOpenDocumentViewMode}
                          hasInsurance={orderObject?.Insurance_Inforation ? true : ''}
                          type={creditDocument?.[0]?.type}
                          isLocked={creditDocument?.[0]?.is_locked}
                        />
                      ) : (
                        <ContractDocuments
                          isOptionsDisabled={disableOptions || fetchingTemplatesData}
                          document={document}
                          setSignerRole={setSignerRole}
                          setOpenDocumentViewer={setOpenDocumentViewer}
                          setOpenDocumentViewMode={setOpenDocumentViewMode}
                          setViewDocument={setViewDocument}
                          hasInsurance={false}
                          type={creditDocument?.[0]?.type}
                          isLocked={creditDocument?.[0]?.is_locked}
                        />
                      )}
                    </Box>
                  ))
              )}
            </Box>
          )}
        </DetailCard>
      )}
      {openDocumentViewer && (
        <>
          <DocumentViewModal
            theme={theme}
            selectedDocument={viewDocument}
            openPopup={openDocumentViewer}
            referenceId={
              signerRole == SignerRole.CO_APPLICANT ? coApplicantReferenceId : referenceId
            }
            referenceNumber={referenceNumber}
            dealerId={signerRole == SignerRole.DEALER_ADMIN ? dealerId : undefined}
            setOpenPopup={setOpenDocumentViewer}
            signerRole={signerRole}
          />
        </>
      )}
      {openDocumentViewMode && (
        <ContractstWrap theme={theme} className="contract-wrap">
          <Dialog
            disablePortal
            theme={theme}
            variant={undefined}
            open={openDocumentViewMode}
            size="xl"
            onCloseDialog={handleClose}
            noFooter
            title={viewDocument?.title}>
            <DocumentViewer
              theme={theme}
              selectedDocument={viewDocument}
              referenceId={referenceId}
              dealerId={''}
              documentPackageIdentifier={viewDocument?.identifier}
              setOpenPopup={setOpenDocumentViewMode}
              viewOnly
              signerRole={SignerRole.CO_APPLICANT}
            />
          </Dialog>
        </ContractstWrap>
      )}
      {openPopUp && (
        <Dialog
          disablePortal={false}
          size="xs"
          variant={undefined}
          open={openPopUp}
          noFooter={false}
          title="Add Documents"
          customFooter={
            <>
              <Button
                theme={theme}
                secondary
                text={'Cancel'}
                onClick={() => {
                  setOpenPopUp(false)
                }}></Button>
              <Button
                theme={theme}
                onClick={handleUpload}
                disabled={isLoading}
                endIcon={isLoading && <img src={DisableLoader} alt="Loader" />}
                primary={true}
                text={'Save Changes'}></Button>
            </>
          }
          className={'u-dialog-sm'}
          onCloseDialog={() => {
            setOpenPopUp(false)
          }}
          theme={theme}
          children={
            <>
              <Grid theme={theme} item xs={12}>
                <MultiSelect
                  theme={theme}
                  fullWidth
                  label="Choose Documents"
                  items={templatesData?.result
                    ?.filter((item: any) => !documents?.includes(item?.name))
                    .map((item: any) => {
                      return { text: item.name, value: item.id }
                    })}
                  value={selectedTemplate}
                  onChange={(e: any) => handleChange(e?.target?.value || [])}
                  selectError={error}
                />
              </Grid>
            </>
          }
        />
      )}
    </>
  )
}

export default Contracts
