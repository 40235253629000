import { FC, memo, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { Dialog, Icon, Button, Grid, Box, Typography } from '@ntpkunity/controls'
import { ConfirmationDialog, Divider, PriceList } from 'components'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import { InsurancePopup } from '@app/order-management'
import { DialogMessages } from '@helpers/enums'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const schema = yup.object().shape({
  insurer_name: yup.string().nullable().required('Insurance Provider Name is required'),
  agent_name: yup.string().nullable().required('Insurance Agent Name is required'),
  policy_number: yup.string().nullable().required('Policy Number is required'),
  expiration_date: yup
    .string()
    .nullable()
    .required('Expiration is required')
    .test(
      'notFutureOrCurrentDate',
      'Expiration Date cannot be equal to or lesser than current date.',
      function (value) {
        const selectedDate = dayjs(value).startOf('day')
        const currentDate = dayjs().startOf('day')
        return selectedDate.isAfter(currentDate)
      }
    ),
  insurance_documents: yup.array().nullable().required('Atleast one Image is required'),
  insurer_address: yup.object().shape({
    address_line_1: yup.string().nullable().required('Address Line 1 is required'),
    city: yup.string().nullable().required('City is required'),
    state_name: yup.string().nullable().required('State Name is required'),
    zip_code: yup.string().nullable().required('Zip Code is required')
  })
})
const OrderInsurance: FC<{
  selectedInsurance: any
  isDisable?: boolean
  insuranceRequestData: any
}> = ({ selectedInsurance, isDisable, insuranceRequestData }) => {
  const theme = useTheme()
  const [openInsuranceDialog, setOpenInsuranceDialog] = useState(false)
  const [confirmationPopupState, setConfirmationPopupState] = useState<boolean>(false)
  const handleClickOpenInsurance = () => {
    form.reset()
    selectedInsurance({})
    setOpenInsuranceDialog(true)
  }
  const form: {
    trigger: any
    handleSubmit: any
    reset: any
    formState: any
    getValues: any
  } = useForm({
    resolver: yupResolver(schema)
  })
  const hasFormValues = Object.keys(insuranceRequestData).length > 0
  const formattedExpirationDate =
    form.getValues()?.expiration_date &&
    new Date(form.getValues().expiration_date).toLocaleDateString()
  const handleCloseInsuranceDialog = () => {
    setOpenInsuranceDialog(false)
    if (Object.keys(form.formState.errors)?.length > 0) {
      form.reset()
      selectedInsurance({})
    }
  }
  const saveInsurance = () => {
    selectedInsurance({
      ...form.getValues(),
      created_by: 'null',
      expiration_date: dayjs(form.getValues('expiration_date')).format('YYYY-MM-DD')
    })
    handleCloseInsuranceDialog()
  }
  useEffect(() => {
    insuranceRequestData && form.reset(insuranceRequestData)
  }, [insuranceRequestData])
  const removeFormData = () => {
    form.reset()
    selectedInsurance({})
    setConfirmationPopupState(false)
  }
  function isFormContainValues(): boolean {
    let obj = form.getValues()
    let check = false
    for (var x in obj) {
      if (obj[x] !== null && obj[x] !== '' && obj[x] !== undefined) {
        check = true
        break
      }
    }
    return check
  }
  return (
    <>
      <Typography theme={theme} variant="h4" component="h4" sx={{ mb: 2.5 }}>
        Insurance
      </Typography>
      {hasFormValues && isFormContainValues() ? (
        <>
          <PriceList theme={theme} className="price-list" sx={{ mb: 4 }}>
            <Grid container theme={theme} columnSpacing={1} sx={{ mb: 2 }}>
              <Grid item theme={theme} xs={4}>
                Provider
              </Grid>
              <Grid item theme={theme} xs={8} textAlign="right">
                {' '}
                {form.getValues()?.insurer_name}
                {''}
              </Grid>
            </Grid>
            <Grid container theme={theme} columnSpacing={1} sx={{ mb: 2 }}>
              <Grid item theme={theme} xs={4}>
                Policy #
              </Grid>
              <Grid item theme={theme} xs={8} textAlign="right">
                {form.getValues()?.policy_number}
              </Grid>
            </Grid>
            <Grid container theme={theme} columnSpacing={1} sx={{ mb: 2 }}>
              <Grid item theme={theme} xs={4}>
                Expiry Date
              </Grid>
              <Grid item theme={theme} xs={8} textAlign="right">
                {formattedExpirationDate}
              </Grid>
            </Grid>
            <Grid container theme={theme} columnSpacing={1} sx={{ mb: 2 }}>
              <Grid item theme={theme} xs={4}>
                <Button
                  theme={theme}
                  secondary
                  text="Remove"
                  disabled={isDisable}
                  onClick={() => {
                    setConfirmationPopupState(true)
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item theme={theme} xs={8} textAlign="right">
                <Button
                  theme={theme}
                  secondary
                  text="Edit Details"
                  fullWidth
                  disabled={isDisable}
                  onClick={() => {
                    form.reset(insuranceRequestData)
                    setOpenInsuranceDialog(true)
                  }}
                />
              </Grid>
            </Grid>
          </PriceList>
        </>
      ) : (
        <Box theme={theme} sx={{ mb: 4 }}>
          <Button
            theme={theme}
            secondary
            text="Add Insurance Details"
            fullWidth
            onClick={handleClickOpenInsurance}
            disabled={isDisable}
          />
        </Box>
      )}
      <Box theme={theme} sx={{ mb: 4 }}>
        <Divider />
      </Box>
      <Dialog
        variant={undefined}
        size="lg"
        title="Insurance"
        open={openInsuranceDialog}
        onCloseDialog={handleCloseInsuranceDialog}
        customFooter={
          <>
            <Button
              theme={theme}
              primary
              text="Continue "
              onClick={form.handleSubmit(saveInsurance)}
            />
          </>
        }
        theme={theme}
        children={<InsurancePopup InsuranceForm={form} />}
      />
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={removeFormData}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.insuranceConfirmationTitle}
          confirmationText={'Once confirmed, you cannot undo this action.'}
          primaryBtnText={'Confirm'}
          secondaryBtnText={'Cancel'}
          icon={<Icon className='alert-icon' name="ErrorTriangle" />}
          onCancel={function (_action: any): void {
            setConfirmationPopupState(false)
          }}
          hideCancelButton={false}
        />
      )}
    </>
  )
}

export default memo(OrderInsurance)
