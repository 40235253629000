import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TableCell, TableRow, useTheme, Link } from '@mui/material'
import { ConfirmationDialog, DataTableWrap, DetailCard } from 'components'
import {
  Box,
  Button,
  DataTable,
  Icon,
  Menu,
  Tooltip,
  Typography,
  unityTheme,
  Skeleton
} from '@ntpkunity/controls'
import { formatCurrency } from '@helpers/methods'
import { CREDIT_DECLINE_REASONS, CreditApplicationCases } from '@helpers/constants'
import { CreditApplicationStatus, Status, DEALPROVIDERS } from '@helpers/enums'
import { useDealRecalculation, useUpdateOrderStatusAndLender } from '@apis/dealer-workqueue.service'
import { useStoreContext } from '@store/storeContext'
import { ILender } from 'controls/multi-lender-dialog'
import SubmissionErrors from 'controls/SubmissionErrors'

interface IOrderSubmission {
  id: number
  order_id: number
  lender_name: string
  provider: string
  status: string
  decision: {
    net_financed_amount: number
    apr: number
    terms: number
    tier: string
    monthly_payment: number
  }
  lender_id: number
  is_continue_order: boolean
  errors: any
  Credit?: IError[]
  Contract?: IError[]
}

interface ICreditApplicationProps {
  order_detail: any
  openSlModal: any
  lenders: any
  setSelectedLenders: (lenders: number[]) => void
  setDealRecalculation: any
  isLoadingOrderInformation: any
}

interface SelectedOptions {
  isCreditApproved: boolean
  isContinueOrder: boolean
  lender_id: number
  submission_id: number
}

interface IError {
  message: string
}

const CreditApplication = ({
  order_detail,
  setSelectedLenders,
  openSlModal,
  lenders,
  setDealRecalculation,
  isLoadingOrderInformation
}: ICreditApplicationProps) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { actions } = useStoreContext()

  const { mutate: updateOrderStatus, isLoading: orderApproveLoading } =
    useUpdateOrderStatusAndLender()

  const { mutate: dealRecalculation, isLoading: dealLoading } = useDealRecalculation()
  const [creditMessages, setCreditMessages] = useState<string[]>([])
  const [openComments, setComments] = useState(false)
  const [orderSubmissions, setOrderSubmissions] = useState<IOrderSubmission[]>([])
  const [showLenderConfirmationPopup, setShowLenderConfirmationPopup] = useState<boolean>(false)
  const [selectedLender, setSelectedLender] = useState({
    lender_name: '',
    status: ''
  })
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>({
    isCreditApproved: false,
    isContinueOrder: false,
    lender_id: 0,
    submission_id: 0
  })

  useEffect(() => {
    if (order_detail?.order_submissions?.length > 0) {
      const updatedOrderSubmissions = order_detail?.order_submissions?.map(
        (item: IOrderSubmission) => {
          let updatedItem: any = item
          if (item?.decision) {
            updatedItem.is_continue_order = checkOrderDetails(item)
            updatedItem.decision.net_financed_amount =
              item?.decision?.net_financed_amount &&
              `$${formatCurrency(item?.decision?.net_financed_amount)}`
            updatedItem.decision.apr = item?.decision?.apr && `${item?.decision?.apr}%`
            updatedItem.decision.terms = item?.decision?.terms && `${item?.decision?.terms} Months`
            updatedItem.decision.monthly_payment =
              item?.decision?.monthly_payment &&
              `$${formatCurrency(item?.decision?.monthly_payment)}`
          }
          return updatedItem || {}
        }
      )

      setOrderSubmissions(updatedOrderSubmissions)
    }
  }, [order_detail?.order_submissions])

  useEffect(() => {
    const lenderIds = order_detail?.order_submissions
      ?.filter(
        (item: IOrderSubmission) =>
          item?.provider === DEALPROVIDERS.BMWDeals &&
          item?.status === CreditApplicationStatus.APPROVED
      )
      .map((item: IOrderSubmission) => item?.lender_id)
    if (lenderIds.length > 0) {
      dealRecalculation(
        {
          lender_ids: lenderIds,
          reference_number: order_detail?.reference_number
        },
        {
          onSuccess() {
            setDealRecalculation(true)
          }
        }
      )
    }
  }, [])

  const handleOptionClick = (
    isCreditApproved: boolean,
    isContinueOrder: boolean,
    lender_id: number,
    submission_id: number
  ) => {
    setSelectedOptions({
      isCreditApproved,
      isContinueOrder,
      lender_id,
      submission_id
    })

    if (!isCreditApproved) {
      setShowLenderConfirmationPopup(true)
      return
    }
    const payload = {
      order_id: order_detail.reference_number,
      lender_id
    }

    updateOrderStatus(payload, {
      async onSuccess() {
        actions.setToast({
          toastMessage: 'Deal Approved Successfully',
          toastState: true
        })
        navigate(`/lane/deal-approved/${order_detail?.reference_number}`)
      },
      onError(error: any) {
        actions.setToast({
          toastMessage: error.error.toString(),
          toastState: true,
          variant: 'error'
        })
      }
    })
  }

  const handleOpenSelectModal = () => {
    if (lenders && lenders?.length > 0) {
      const defaultLender = lenders?.find(
        (lender: ILender) => lender.default_lender && !lender.is_already_selected
      )
      setSelectedLenders((defaultLender?.id && [defaultLender.id]) || [])

      openSlModal()
    }
  }

  const checkOrderDetails = (item: IOrderSubmission): boolean => {
    if (
      parseFloat(item?.decision?.apr?.toString() ?? '') === order_detail?.apr &&
      parseFloat(item?.decision?.net_financed_amount?.toString() ?? '') >=
      order_detail?.net_finance_amount &&
      parseFloat(item?.decision?.terms?.toString() ?? '') === order_detail?.contract_term
    ) {
      return true
    }

    return false
  }

  const handleCloseLenderConfirmationPopup = () => setShowLenderConfirmationPopup(false)
  const handleOnClick = (creditError: IOrderSubmission) => {
    setSelectedLender({
      lender_name: creditError?.lender_name,
      status: creditError?.status
    })

    let savedComments = []
    if (creditError?.errors?.Credit?.length) {
      savedComments = creditError?.errors?.Credit?.map((error: any) => error?.message)
    }

    if (creditError?.errors?.[CREDIT_DECLINE_REASONS]?.length) {
      savedComments = [...savedComments, ...creditError?.errors?.[CREDIT_DECLINE_REASONS]?.map((error: any) => error?.message)]
    }

    if (savedComments.length) setCreditMessages(savedComments)
    setComments(true)
  }
  const handleClose = () => {
    setComments(false)
  }

  const handleDealRecalculation = (lender_id: number) => {
    dealRecalculation(
      {
        lender_ids: [lender_id],
        reference_number: order_detail?.reference_number
      },
      {
        onSuccess() {
          setDealRecalculation(true)
        }
      }
    )
  }

  return (
    <>
      <ConfirmationDialog
        openPopUp={showLenderConfirmationPopup}
        onConfirm={() => {
          if (order_detail.status == CreditApplicationStatus.CONDITIONED) {
            navigate(
              `/lane/deal-stipulation/?vin=${order_detail?.Vehicle_details?.vin}&reference_id=${order_detail?.reference_number}&customer_reference_id=${order_detail?.customer_info?.reference_id}`,
              {
                state: { submission_id: selectedOptions.submission_id }
              }
            )
          } else if (!!selectedOptions.lender_id && !!selectedOptions.submission_id) {
            const payload = {
              order_id: order_detail.reference_number,
              lender_id: selectedOptions.lender_id,
              order_status: CreditApplicationStatus.CONDITIONED
            }

            updateOrderStatus(payload, {
              onSuccess() {
                navigate(
                  `/lane/deal-stipulation/?vin=${order_detail?.Vehicle_details?.vin}&reference_id=${order_detail?.reference_number}&customer_reference_id=${order_detail?.customer_info?.reference_id}`,
                  {
                    state: { submission_id: selectedOptions.submission_id }
                  }
                )
              }
            })
          }
        }}
        onCancel={handleCloseLenderConfirmationPopup}
        setPopUpState={handleCloseLenderConfirmationPopup}
        hideCancelButton={false}
        confirmationTitle={'Are you sure you want to proceed with this lender?'}
        primaryBtnText={'Continue'}
        secondaryBtnText={'Cancel'}
        icon={<Icon className="alert-icon" name="ErrorTriangle" />}
      />
      <DetailCard className="detail-card" mb={2}>
        <Box theme={theme} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography theme={theme} component="h4" variant="h4" mb={2}>
            Credit Application
          </Typography>
          {lenders?.filter((item: ILender) => !item?.is_already_selected)?.length > 0 &&
            order_detail?.status === Status.FullyReceived && (
              <Menu
                theme={theme}
                disablePortal
                options={[
                  {
                    optionText: 'Add more lenders ',
                    optionValue: 'AddMoreLenders '
                  }
                ]}
                handleOptionClick={handleOpenSelectModal}
                render={(onMenuSelection: any) => (
                  <Button
                    defaultBtn
                    iconText={<Icon name="MoreIcon" />}
                    onClick={onMenuSelection}
                  />
                )}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              />
            )}
        </Box>
        <DataTableWrap className="table-pagination simple-table">
          <DataTable
            theme={unityTheme}
            variant="basic"
            theadChildren={
              <>
                <TableRow>
                  <TableCell id="statusHead">Status</TableCell>
                  <TableCell id="lenderHead">Lender</TableCell>
                  <TableCell id="nfaHead">NFA</TableCell>
                  <TableCell id="ratetHead">Rate</TableCell>
                  <TableCell id="termHead">Term</TableCell>
                  <TableCell id="periodicHead">Periodic Payment</TableCell>
                  <TableCell id="commentsHead">Comments</TableCell>
                  <TableCell className="action-cell fixed-cell"></TableCell>
                </TableRow>
              </>
            }
            tbodyChildren={
              <>
                {orderSubmissions?.map((item: IOrderSubmission, index: number) => {
                  const key: string = item?.status
                  const isCreditApproved: boolean = CreditApplicationStatus.APPROVED === key
                  const caseIcon = CreditApplicationCases[key]

                  const isBmwDealApprovedWithoutPayment =
                    !item?.decision?.monthly_payment &&
                    item.provider === DEALPROVIDERS.BMWDeals &&
                    item.status === CreditApplicationStatus.APPROVED

                  const shouldShowRetryOption = 
                    isBmwDealApprovedWithoutPayment && 
                    !dealLoading && 
                    !isLoadingOrderInformation

                  return (
                    <TableRow className="child-tr" key={index}>
                      <TableCell>
                        <Box theme={theme} className="center">
                          <Icon name={caseIcon?.icon ?? 'InfoIcon'} className={caseIcon?.class} />
                          {key || '-'}
                        </Box>
                      </TableCell>
                      <TableCell>{item?.lender_name || '-'}</TableCell>
                        {isBmwDealApprovedWithoutPayment ? (
                        <TableCell colSpan={4}>
                          {dealLoading || isLoadingOrderInformation ? (
                            <Skeleton theme={theme} variant="text" width={'100%'} height={'32px'} />
                          ) : (
                            <Box theme={theme} className="not-allowd">
                              Unable to fetch the details. Please{' '}
                              <Link
                                href="javascript:void(0)"
                                className="link hover-underline"
                                onClick={() => handleDealRecalculation(item?.lender_id)}>
                                Retry
                              </Link>
                            </Box>
                          )}
                        </TableCell>
                      ) : (
                        <>
                          <TableCell>{item?.decision?.net_financed_amount || '-'}</TableCell>
                          <TableCell>{item?.decision?.apr || '-'}</TableCell>
                          <TableCell>{item?.decision?.terms || '-'}</TableCell>
                          <TableCell>{item?.decision?.monthly_payment || '-'}</TableCell>
                        </>
                      )}
                      <TableCell>
                        {(item?.errors?.Credit?.length || item?.errors?.[CREDIT_DECLINE_REASONS]?.length) ? (
                          <Link
                            href="javascript:void(0)"
                            className="link hover-underline"
                            onClick={() => handleOnClick(item)}>
                            View Comments
                          </Link>
                        ) : (
                          '-'
                        )}
                      </TableCell>
                      <TableCell className="action-cell fixed-cell" align="center">
                        {[CreditApplicationStatus.CONDITIONED]?.includes(
                          key as unknown as CreditApplicationStatus
                        ) &&
                          [Status.FullyReceived, Status.Conditioned]?.includes(
                            order_detail?.status
                          ) && (
                            <Menu
                              theme={theme}
                              options={[
                                {
                                  optionText: isCreditApproved
                                    ? 'Continue Deal'
                                    : 'View Conditions',
                                  optionValue: 'orderOption',
                                  disabled: orderApproveLoading
                                }
                              ]}
                              handleOptionClick={() =>
                                handleOptionClick(
                                  isCreditApproved,
                                  item?.is_continue_order,
                                  item.lender_id,
                                  item.id
                                )
                              }
                              render={(onMenuSelection: any) => (
                                <Button
                                  defaultBtn
                                  iconText={<Icon name="MoreIcon" />}
                                  onClick={onMenuSelection}
                                />
                              )}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                              }}
                            />
                          )}
                        {[CreditApplicationStatus.APPROVED]?.includes(
                          key as unknown as CreditApplicationStatus
                        ) &&
                          shouldShowRetryOption && (
                            <Menu
                              theme={theme}
                              options={[
                                {
                                  optionText: 'Retry',
                                  optionValue: 'retryOption',
                                  disabled: dealLoading
                                }
                              ]}
                              handleOptionClick={() => handleDealRecalculation(item?.lender_id)}
                              render={(onMenuSelection: any) => (
                                <Button
                                  defaultBtn
                                  iconText={<Icon name="MoreIcon" />}
                                  onClick={onMenuSelection}
                                />
                              )}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                              }}
                            />)
                          }
                        {key === CreditApplicationStatus.FAILED && (
                          <Tooltip
                            theme={theme}
                            placement="left"
                            disablePortal={false}
                            title="Please Contact Support Team"
                            children={<Icon name="InfoIcon" />}
                          />
                        )}
                        {key === Status.Approved &&
                          (item?.decision?.monthly_payment ||
                            item?.provider !== DEALPROVIDERS.BMWDeals) &&
                          [Status.FullyReceived, Status.Conditioned]?.includes(
                            order_detail?.status
                          ) && (
                            <Menu
                              theme={theme}
                              options={[
                                {
                                  optionText: 'Continue Deal',
                                  optionValue: 'orderOption',
                                  disabled: orderApproveLoading
                                }
                              ]}
                              handleOptionClick={() =>
                                handleOptionClick(
                                  isCreditApproved,
                                  item?.is_continue_order,
                                  item.lender_id,
                                  item.id
                                )
                              }
                              render={(onMenuSelection: any) => (
                                <Button
                                  defaultBtn
                                  iconText={<Icon name="MoreIcon" />}
                                  onClick={onMenuSelection}
                                />
                              )}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                              }}
                            />
                          )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </>
            }
          />
        </DataTableWrap>
      </DetailCard>
      <SubmissionErrors
        openComments={openComments}
        creditMessages={creditMessages}
        selectedLender={selectedLender}
        handleClose={handleClose}
      />
    </>
  )
}

export default CreditApplication
