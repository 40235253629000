// @ts-nocheck

import { FeeHandlingType, InstallationMode, RebateTypes } from '@helpers/enums'
import { DeskingState, FinanceTypes, OrderStage, VehiclesForComparisonTypes } from './desking-context-provider'
import { IContractTerms, ICustomer, IFee, IOrderTradeIn, IProgramDetails, IRebate } from '@models'
import { DealDeskingState } from '@store/ducks/desking'
import { findAddress } from '@helpers/methods'
import { FeeEvent } from '@helpers/enums/finance-type.enum'
import { dealButtonOptions } from '@helpers/constants'

export const createV2Key = (vin: string, comparison: string, pricingPayload: any) => {
  const {
    annual_usage,
    apr,
    dealer_id,
    lender_id,
    down_payment,
    fees,
    finance_type,
    fni_products,
    rv_value,
    options,
    terms,
    trade_ins,
    customer_address,
    per_unit_selling_price,
    tspk,
    taxes,
    rebates,
    program
  } = pricingPayload

  return [
    'PricingCalculation',
    per_unit_selling_price,
    finance_type,
    comparison,
    vin,
    down_payment,
    annual_usage,
    terms,
    apr,
    rv_value,
    dealer_id,
    lender_id,
    tspk,
    fees,
    fni_products,
    options,
    trade_ins,
    customer_address,
    taxes,
    rebates,
    program
  ]
}

export const createCurrencyFormatter = (code: string) => {
  return (
    amount: number | string,
    minimumFractionDigits: number = 2,
    maximumFractionDigits: number = 2
  ): string => {
    const numericAmount = typeof amount === 'string' ? parseFloat(amount) : amount
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: code,
      minimumFractionDigits,
      maximumFractionDigits
    }).format(numericAmount ?? 0)
  }
}
export const createSymbolFormatter = (currencySymbol?: string) => {
  return (
    amount: number | string,
    minimumFractionDigits: number = 2,
    maximumFractionDigits: number = 2
  ): string => {
    const numericAmount = typeof amount === 'string' ? parseFloat(amount) : amount
    return `${currencySymbol ?? ''}${formatNumber(
      numericAmount,
      maximumFractionDigits,
      minimumFractionDigits
    )}`
  }
}

export const formatNumber = (
  value: number | string,
  maximumFractionDigits = 2,
  minimumFractionDigits = 2
): string => {
  const numericValue = typeof value === 'string' ? parseFloat(value) : value
  return (numericValue ?? 0)?.toLocaleString('en-US', {
    minimumFractionDigits,
    maximumFractionDigits
  })
}

export const organizeByFinanceType = (data: any[], orderFees: any[]) => {
  const organizedData = Object.values(FinanceTypes).reduce((acc, type) => {
    acc[type] = []
    return acc
  }, {})

  const activeFees = data.filter((dta) => dta.is_active)

  activeFees.forEach((_dta) => {
    Object.values(FinanceTypes).forEach((type) => {
      if (_dta.applicable_finance_type.includes(type)) {
        const existingFee = orderFees.find((oFee) => oFee?.dealer_fee_id === _dta.id)
        organizedData[type].push(existingFee ?? _dta)
      }
    })
  })

  const cashFees = activeFees.filter((fee) => fee.deal_type === FinanceTypes.CASH)
  cashFees.forEach((cashFee) => {
    const existingFee = orderFees.find((oFee) => oFee?.dealer_fee_id === cashFee.id)
    organizedData[FinanceTypes.CASH].push(existingFee ?? cashFee)
  })

  return organizedData
}

export const calculateTotalOfPayments = (
  rentalAmount: number,
  terms: number,
  program: IProgramDetails,
  sellingPrice: number
) => {
  const { rv_balloon_applicable, rv_balloon_type, rv_chart } = program
  const rvBalloonApplicable = rv_balloon_applicable ?? false

  if (!rvBalloonApplicable) {
    return rentalAmount * terms
  }

  const value = ((rv_chart?.[0]?.rv_value ?? 0) * sellingPrice) / 100
  return rentalAmount * (rv_balloon_type === 'rv' ? terms : terms - 1) + value
}

export const createPricingRequestPayloadFromState = (
  state: DeskingState,
  globalState: DealDeskingState,
  rowIndex: number,
  colIndex: number,
  vin: string
) => {
  const singleVehicleComparison = state.vehiclesForComparison === VehiclesForComparisonTypes.SINGLE
  const isVehicleSelectedAndOrderSaved = state.isVehicleSelectedAndOrderSaved
  const order = state.order
  const finance_type = getDealType(state, vin)
  const isCash = finance_type == FinanceTypes.CASH
  if (isCash) {
    return createPricingRequestPayloadFromStateForCash(state, globalState, rowIndex, colIndex, vin)
  }
  const { contract_term, apr, rv_value, annual_usage } =
    (isVehicleSelectedAndOrderSaved
      ? state.preview.quote_params
      : singleVehicleComparison
      ? state[VehiclesForComparisonTypes.SINGLE][finance_type].calculationsBoxes[rowIndex][0]
      : state[VehiclesForComparisonTypes.MULTIPLE][finance_type]?.find(
          (financeItem: any, idx: number) => financeItem.vin === vin || idx === colIndex
        )?.quote_params) ?? {}

  const downPayment = isVehicleSelectedAndOrderSaved
    ? state.preview.down_payments.value
    : singleVehicleComparison
    ? state[VehiclesForComparisonTypes.SINGLE][finance_type].calculationsBoxes[0][colIndex]
    : state[VehiclesForComparisonTypes.MULTIPLE][finance_type]?.find(
        (financeItem: any, idx: number) => financeItem.vin === vin || idx === colIndex
      )?.down_payments.value

  const calculation =
    (isVehicleSelectedAndOrderSaved
      ? state.preview.calculationsBoxes
      : singleVehicleComparison
      ? state[VehiclesForComparisonTypes.SINGLE][finance_type].calculationsBoxes[rowIndex][colIndex]
      : state[VehiclesForComparisonTypes.MULTIPLE][finance_type]?.find(
          (financeItem: any, idx: number) => financeItem.vin === vin || idx === colIndex
        )?.calculationsBoxes) ?? {}

  const misc =
    (isVehicleSelectedAndOrderSaved
      ? state.preview.misc
      : singleVehicleComparison
      ? state[VehiclesForComparisonTypes.SINGLE][finance_type]?.misc?.[rowIndex - 1]?.[colIndex - 1]
      : state[VehiclesForComparisonTypes.MULTIPLE][finance_type]?.find(
          (financeItem: any, idx: number) => financeItem.vin === vin || idx === colIndex
        )?.misc) ?? {}

  const vehiclesData = state.vehiclesData.get(vin)
  const program = (vehiclesData?.program ?? {})?.[finance_type]?.find(
    (p) =>
      p?.contract_term === contract_term && p?.is_default &&
      (finance_type === FinanceTypes.FINANCE || p?.mileage === annual_usage)
  )

  const fees = (vehiclesData?.fees ?? {})?.[finance_type] ?? []
  const fni = (vehiclesData?.fni ?? {})?.[finance_type?.toLowerCase()] ?? []
  const { customer, tradeIn, insurance } = state
  const { dealerProfile, dealerPreferences } = globalState

  let customerGaragingAddress = dealerProfile?.dealer_address
  if (customer?.customer_addresses?.length > 0) {
    const garagingAddress = findAddress(customer?.customer_addresses)
    if (garagingAddress?.state_name) customerGaragingAddress = garagingAddress
  }

  const perUnitSellingPrice = isVehicleSelectedAndOrderSaved
    ? order?.order_asset?.unit_price
    : vehiclesData?.vehicle?.selling_price ?? vehiclesData?.vehicle?.internet_price

  const tradeInAmount =
    Object.keys(tradeIn ?? {}).length > 0 ? tradeIn?.trade_in_amount - tradeIn?.payoff_amount : 0

  const financedFeeSum = fees.reduce(
    (total: number, fee: any) =>
      fee?.fee_handling?.toLowerCase() === FeeHandlingType.FINANCE
        ? total + (fee.applied_price ?? fee.default_amount)
        : total,
    0
  )

  const fniProductsPrice = (fni ?? [])?.reduce(
    (total: number, currentItem: any) =>
      total + roundToTwoDecimal(currentItem?.price ?? currentItem?.applied_price ?? 0),
    0
  )

  const optionsPrice = (vehiclesData?.dealer_options ?? [])?.reduce(
    (total: number, currentItem: any) =>
      total +
      ((currentItem.installation_mode === InstallationMode.PRE_INSTALLED &&
        !currentItem.is_price_inclusive) ||
      currentItem.installation_mode !== InstallationMode.PRE_INSTALLED
        ? currentItem.applied_price ?? currentItem.price
        : 0),
    0
  )

  const rebatesSum = (vehiclesData?.rebates ?? []).reduce(
    (sum: number, item: IRebate) => sum + Number(item.rebate_amount),
    0
  )

  const netFinancedAmount = Math.abs(
    perUnitSellingPrice +
      financedFeeSum +
      fniProductsPrice +
      optionsPrice -
      (downPayment + tradeInAmount)
  )

  const creditRating =
    ((customer as ICustomer)?.credit_rating ?? '').length > 0
      ? (customer as ICustomer)?.credit_rating
      : globalState?.creditTerms?.[0]?.description

  const taxes = (state?.taxes || {})[finance_type]
  const isEmptyTaxes = (taxes) =>
    Object?.values(taxes)?.every((value) => Object?.keys(value).length === 0)
  const calculationRequestObject = {
    program,
    annual_usage: Number(annual_usage ?? 0),
    apr: Number(apr),
    asset_condition: 63,
    base_rate: isVehicleSelectedAndOrderSaved
      ? order.base_rate
      : program?.base_rate_chart?.base_rate,
    calculation_method: isVehicleSelectedAndOrderSaved
      ? order.calcuation_method
      : program?.calculation_method,
    credit_rating: creditRating,
    customer_address: {
      street_address: customerGaragingAddress?.address_line_1,
      city: customerGaragingAddress?.city,
      state: customerGaragingAddress?.state_name,
      zip_code: customerGaragingAddress?.zip_code
    },
    dealer_address: {
      street_address: dealerProfile?.dealer_address?.address_line_1,
      city: dealerProfile?.dealer_address?.city,
      state: dealerProfile?.dealer_address?.state_name,
      zip_code: dealerProfile?.dealer_address?.zip_code
    },
    dealer_code: dealerProfile?.dealer_code ?? order?.dealer_id,
    lender_code: state.lender_code,
    down_payment: Number(downPayment),
    down_payment_paid_by_dealer: 0,
    down_payment_paid_by_oem: 0,
    fees: fees
      .filter((fee: any) => fee.is_active)
      ?.map((fee: any) => {
        return {
          fee_name: fee.fee_name,
          fee_amount: fee.applied_price ?? fee.default_amount,
          fee_handling: fee?.fee_handling,
          is_active: fee?.is_active,
          applicable_finance_type: fee?.applicable_finance_type,
          is_taxable: fee?.taxable
        }
      }),
    finance_type: finance_type,
    fni_products: fni?.map((item: any) => {
      return {
        fni_product_name: item.product_name,
        fni_product_amount: roundToTwoDecimal(item.price || item.applied_price || 0),
        // amount_handling: item?.fee_handling
        is_taxable: item?.taxable
      }
    }),
    insurance: insurance,
    insurance_amount: 0,
    interest_chart_margin: isVehicleSelectedAndOrderSaved
      ? order?.margin ?? 0
      : program?.interest_chart?.[0]?.margin ?? 0,
    is_rv_guaranteed: 'False',
    markup_rate: 0,
    net_financed_amount: netFinancedAmount,
    options: vehiclesData?.dealer_options
      ?.filter(
        (option: any) =>
          (option.installation_mode === 'Pre-Installed' && !option.is_price_inclusive) ||
          option.installation_mode === 'Optional'
      )
      .map((option: any) => ({
        option_name: option.product_name,
        option_amount: option.applied_price ?? option.price ?? 0,
        rv_amount_on_option: option.rv_balloon_value ?? option.rv_adder ?? 0,
        installation_mode: option.installation_mode,
        is_price_inclusive: option.is_price_inclusive
      })) ?? [],
    per_unit_selling_price: perUnitSellingPrice,
    quantity: 1,
    rental_frequency: isVehicleSelectedAndOrderSaved ? order.rental_frequency : 'Monthly',
    rental_mode: isVehicleSelectedAndOrderSaved ? order.rental_mode : program?.rental_mode,
    rv_amount: 0,
    rv_value: Number(rv_value),
    rebate: { price: rebatesSum ?? 0 },
    structure_rentals: [],
    subsidy: {
      subsidy_calculation_method: 'percentage from Dealer and percentage from Manufacturer',
      financier_rate: 0,
      manufacturer_subsidy_rate: 2,
      dealer_subsidy_rate: 1
    },
    tax_on_selling_price:
      (!state.isVehicleSelectedAndOrderSaved && state?.preview?.calculationsBoxes?.tax_amount) ||
      misc?.tax_on_selling_price ||
      state?.order?.tax_amount ||
      calculation?.tax_on_selling_price,
    tspk:
      (!state.isVehicleSelectedAndOrderSaved && state?.preview?.calculationsBoxes?.tax_amount) ||
      misc?.tax_on_selling_price ||
      state?.order?.tax_amount,
    taxes: taxes && Object.keys(taxes).length === 0 ? undefined : taxes,
    taxes_amount: 0,
    terms: Number(contract_term),
    trade_in_amount: tradeInAmount,
    trade_ins: [
      {
        vin: 'vin' in tradeIn ? tradeIn.vin : '',
        offer_amount: 'trade_in_amount' in tradeIn ? tradeIn.trade_in_amount : 0,
        pay_off_amount: 'payoff_amount' in tradeIn ? tradeIn.payoff_amount : 0,
        allowance_amount: 'trade_in_amount' in tradeIn ? tradeIn.trade_in_amount : 0
      }
    ],
    vehicle: {
      vin: vehiclesData?.vehicle?.vin,
      is_new: true,
      odometer_mileage: vehiclesData?.vehicle?.mileage ? vehiclesData?.vehicle?.mileage : 0,
      msrp: vehiclesData?.vehicle?.msrp,
      year: vehiclesData?.vehicle?.year,
      transmission_type: vehiclesData?.vehicle?.transmission_type
    }
  }
  return calculationRequestObject
}

export const createPricingRequestPayloadFromStateForCash = (
  state: DeskingState,
  globalState: DealDeskingState,
  rowIndex: number,
  colIndex: number,
  vin: string
) => {
  const singleVehicleComparison = state.vehiclesForComparison === VehiclesForComparisonTypes.SINGLE
  const isVehicleSelectedAndOrderSaved = state.isVehicleSelectedAndOrderSaved
  const order = state.order

  const vehiclesData = state.vehiclesData.get(vin)
  const finance_type = getDealType(state, vin)

  const fees = (vehiclesData?.fees ?? {})?.[finance_type] ?? []
  const fni = (vehiclesData?.fni ?? {})?.[finance_type?.toLowerCase()] ?? []
  const { customer, tradeIn, insurance } = state
  const { dealerProfile, dealerPreferences } = globalState

  let customerGaragingAddress = dealerProfile?.dealer_address
  if (customer?.customer_addresses?.length > 0) {
    const garagingAddress = findAddress(customer?.customer_addresses)
    if (garagingAddress?.state_name) customerGaragingAddress = garagingAddress
  }

  const perUnitSellingPrice = isVehicleSelectedAndOrderSaved
    ? order?.order_asset?.unit_price
    : vehiclesData?.vehicle?.selling_price ?? vehiclesData?.vehicle?.internet_price

  const tradeInAmount =
    Object.keys(tradeIn ?? {}).length > 0 ? tradeIn?.trade_in_amount - tradeIn?.payoff_amount : 0

  const financedFeeSum = fees.reduce(
    (total: number, fee: any) =>
      fee?.fee_handling?.toLowerCase() === FeeHandlingType.FINANCE
        ? total + (fee.applied_price ?? fee.default_amount)
        : total,
    0
  )

  const fniProductsPrice = (fni ?? [])?.reduce(
    (total: number, currentItem: any) =>
      total + roundToTwoDecimal(currentItem?.price ?? currentItem?.applied_price ?? 0),
    0
  )

  const optionsPrice = (vehiclesData?.dealer_options ?? [])?.reduce(
    (total: number, currentItem: any) =>
      total +
      ((currentItem.installation_mode === InstallationMode.PRE_INSTALLED &&
        !currentItem.is_price_inclusive) ||
      currentItem.installation_mode !== InstallationMode.PRE_INSTALLED
        ? currentItem.applied_price ?? currentItem.price
        : 0),
    0
  )

  const netFinancedAmount = Math.abs(
    perUnitSellingPrice + financedFeeSum + fniProductsPrice + optionsPrice - tradeInAmount
  )

  const creditRating =
    ((customer as ICustomer)?.credit_rating ?? '').length > 0
      ? (customer as ICustomer)?.credit_rating
      : globalState?.creditTerms?.[0]?.description

  const rebatesSum = (vehiclesData?.rebates ?? []).reduce(
    (sum: number, item: IRebate) => sum + Number(item.rebate_amount),
    0
  )

  const calculationRequestObject = {
    program: null,
    annual_usage: 0,
    apr: 0,
    asset_condition: 63,
    base_rate: null,
    calculation_method: null,
    credit_rating: creditRating,
    customer_address: {
      street_address: customerGaragingAddress?.address_line_1,
      city: customerGaragingAddress?.city,
      state: customerGaragingAddress?.state_name,
      zip_code: customerGaragingAddress?.zip_code
    },
    dealer_address: {
      street_address: dealerProfile?.dealer_address?.address_line_1,
      city: dealerProfile?.dealer_address?.city,
      state: dealerProfile?.dealer_address?.state_name,
      zip_code: dealerProfile?.dealer_address?.zip_code
    },
    dealer_code: dealerProfile?.dealer_code ?? order?.dealer_id,
    lender_code: null,
    down_payment: null,
    down_payment_paid_by_dealer: null,
    down_payment_paid_by_oem: null,
    fees: fees
      .filter((fee: any) => fee.is_active)
      ?.map((fee: any) => {
        return {
          fee_name: fee.fee_name,
          fee_amount: fee.applied_price ?? fee.default_amount,
          fee_handling: fee?.fee_handling,
          is_active: fee?.is_active,
          applicable_finance_type: fee?.applicable_finance_type,
          is_taxable: fee?.taxable
        }
      }),
    finance_type: finance_type,
    deal_type: FinanceTypes.CASH,
    fni_products: fni?.map((item: any) => {
      return {
        fni_product_name: item.product_name,
        fni_product_amount: roundToTwoDecimal(item.price || item.applied_price || 0),
        // amount_handling: item?.fee_handling
        is_taxable: item?.taxable
      }
    }),
    insurance: [],
    insurance_amount: null,
    interest_chart_margin: 0,
    is_rv_guaranteed: 'False',
    markup_rate: 0,
    net_financed_amount: netFinancedAmount,
    options: vehiclesData?.dealer_options
      ?.filter(
        (option: any) =>
          (option.installation_mode === 'Pre-Installed' && !option.is_price_inclusive) ||
          option.installation_mode === 'Optional'
      )
      .map((option: any) => ({
        option_name: option.product_name,
        option_amount: option.applied_price ?? option.price ?? 0,
        rv_amount_on_option: option.rv_balloon_value ?? option.rv_adder ?? 0,
        installation_mode: option.installation_mode,
        is_price_inclusive: option.is_price_inclusive
      })) ?? [],
    per_unit_selling_price: perUnitSellingPrice,
    quantity: 1,
    rental_frequency: null,
    rental_mode: null,
    rv_amount: 0,
    rv_value: null,
    rebate: { price: rebatesSum ?? 0 },
    structure_rentals: [],
    subsidy: {
      subsidy_calculation_method: 'percentage from Dealer and percentage from Manufacturer',
      financier_rate: 0,
      manufacturer_subsidy_rate: 2,
      dealer_subsidy_rate: 1
    },
    tax_on_selling_price: null,
    tspk: null,
    taxes: null,
    taxes_amount: 0,
    terms: 1,
    trade_in_amount: tradeInAmount,
    trade_ins: [
      {
        vin: 'vin' in tradeIn ? tradeIn.vin : '',
        offer_amount: 'trade_in_amount' in tradeIn ? tradeIn.trade_in_amount : 0,
        pay_off_amount: 'payoff_amount' in tradeIn ? tradeIn.payoff_amount : 0,
        allowance_amount: 'trade_in_amount' in tradeIn ? tradeIn.trade_in_amount : 0
      }
    ],
    vehicle: {
      vin: vehiclesData?.vehicle?.vin,
      is_new: true,
      odometer_mileage: vehiclesData?.vehicle?.mileage ? vehiclesData?.vehicle?.mileage : 0,
      msrp: vehiclesData?.vehicle?.msrp,
      year: vehiclesData?.vehicle?.year,
      transmission_type: vehiclesData?.vehicle?.transmission_type
    }
  }
  return calculationRequestObject
}

export const createOrderRequestPayloadFromState = (
  state: DeskingState,
  dealerInfo: any,
  lender_id: number
) => {
  const finance_type = state?.selection?.finance_type ?? state?.preview?.finance_type
  const { row: rowIndex, col: colIndex, vin, vehicleForComparison } = state?.selection
  const isCash = finance_type === FinanceTypes.CASH
  if (isCash) return createOrderRequestPayloadFromStateForCash(state, dealerInfo)

  const singleVehicleComparison = vehicleForComparison === VehiclesForComparisonTypes.SINGLE
  const isVehicleSelectedAndOrderSaved = state.isVehicleSelectedAndOrderSaved

  const { contract_term, rv_value, annual_usage, apr } = isVehicleSelectedAndOrderSaved
    ? state.preview.quote_params
    : singleVehicleComparison
    ? state[VehiclesForComparisonTypes.SINGLE][finance_type]?.calculationsBoxes[rowIndex][0]
    : state[VehiclesForComparisonTypes.MULTIPLE][finance_type]?.find(
        (financeItem: any, idx: number) => financeItem.vin === vin || idx === colIndex
      )?.quote_params || {}

  const calculation = isVehicleSelectedAndOrderSaved
    ? state.preview.calculationsBoxes
    : singleVehicleComparison
    ? state[VehiclesForComparisonTypes.SINGLE][finance_type].calculationsBoxes[rowIndex][colIndex]
    : state[VehiclesForComparisonTypes.MULTIPLE][finance_type]?.find(
        (financeItem: any, idx: number) => financeItem.vin === vin || idx === colIndex
      )?.calculationsBoxes || {}

  const securityDeposit = state.security_deposit
  const contractStartdate = state.contract_start_date
  const paymentDays =
    isCash || finance_type === FinanceTypes.LEASE
      ? 0
      : state?.payment_days || state.order?.payment_days || 30

  const vehiclesData = state.vehiclesData.get(vin)
  const program = (vehiclesData?.program ?? {})?.[finance_type]?.find(
    (p) =>
      p?.contract_term === contract_term && p?.is_default &&
      (finance_type === FinanceTypes.FINANCE || p?.mileage === annual_usage)
  )

  const fees = (vehiclesData?.fees ?? {})?.[finance_type] ?? []

  const filteredData = vehiclesData?.dealer_options?.filter(
    (option: any) =>
      (option.installation_mode === InstallationMode.PRE_INSTALLED && !option.is_price_inclusive) ||
      option.installation_mode === InstallationMode.OPTIONAL
  )
  const optionSum = filteredData?.reduce(
    (sum: any, option: any) => sum + (typeof option.price === 'number' ? option.price : 0),
    0
  )
  const fni = (vehiclesData?.fni ?? {})?.[finance_type?.toLowerCase()] ?? []
  const finance_fni = (fni ?? [])?.reduce((sum: any, item: any) => {
    const price = typeof item.price === 'number' ? item.price : 0
    return sum + roundToTwoDecimal(price)
  }, 0)
  const financedFeeSum = fees.reduce(
    (total: number, fee: any) =>
      fee?.fee_handling?.toLowerCase() === FeeHandlingType.FINANCE
        ? total + (fee?.applied_price ?? fee?.default_amount ?? 0)
        : total,
    0
  )
  const rebatesSum = (vehiclesData?.rebates ?? []).reduce(
    (sum: number, item: IRebate) => sum + Number(item.rebate_amount),
    0
  )
  const sellingPrice = Number(vehiclesData?.vehicle?.internet_price || 0)
  const tradeIn = state?.tradeIn
  const { payment_type, ...tradeInWithoutPaymentType } = tradeIn || {}
  const tradeInAmount =
    Object.keys(tradeIn ?? {}).length > 0
      ? (tradeIn as IOrderTradeIn)?.trade_in_amount - (tradeIn as IOrderTradeIn)?.payoff_amount
      : 0
  const tradeInValue = tradeInAmount < 0 ? tradeInAmount : 0
  const tax_amount = calculation?.tax_amount ?? 0
  const taxes = state?.taxes[finance_type] ?? []
  const requestObject = {
    reference_number: state?.order?.reference_number ? state?.order?.reference_number : undefined,
    capitalized_cost_reduction: calculation?.capitalized_cost_reduction,
    monthly_deprecation: calculation?.monthly_deprecation,
    monthly_sales_use_tax: calculation?.monthly_sales_use_tax,
    capital_cost_reduction_tax: calculation?.taxes?.capital_cost_reduction_tax,
    tax_on_capitalized_cost_reduction: calculation?.tax_on_capitalized_cost_reduction,
    rebate: rebatesSum,
    tax_amount: calculation?.tax_amount,
    tax_amount_number: calculation?.tax_amount,
    finance_amount: calculation?.gross_capitalized_cost,
    identifier: state?.order?.identifier ? state?.order?.identifier : undefined,
    dealer_id: dealerInfo?.id,
    introducer_name: dealerInfo?.dealer_name,
    company_id: dealerInfo?.company_id,
    lender_id: lender_id,
    reference_id: state?.customer?.reference_id ? state?.customer?.reference_id : '',
    contract_term: contract_term,
    allowed_usage: annual_usage,
    credit_rating: state?.customer?.credit_rating ? state?.customer?.credit_rating : '',
    apr: !!Number(apr) || Number(apr) === 0 ? Number(apr) : program?.final_customer_rate,
    estimated_monthly_payment: calculation?.monthly_payment ? calculation?.monthly_payment : 0,
    annual_usage: annual_usage,
    due_at_signing: calculation?.due_at_signing ? calculation?.due_at_signing : 0,
    selling_price: calculation?.selling_price,
    down_payment: (Number(calculation?.down_payment) * 100) / sellingPrice,
    down_payment_value: Number(calculation?.down_payment),
    margin: apr ? parseFloat(apr) : program?.interest_chart ? program?.interest_chart[0].margin : 0,
    base_residual_type: program?.base_residual_type ? program?.base_residual_type : 0,
    excess_mileage_rate: program?.excess_mileage_rate ? program?.excess_mileage_rate : 0,
    base_rate: program?.base_rate_chart?.base_rate || 0,
    rv_balloon_percentage: rv_value || 0,
    rv_balloon_value: calculation?.rv_amount || (Number(rv_value || 0) * sellingPrice) / 100,
    asset_rv_amount: program?.rv_chart ? program?.rv_chart[0]?.rv_value : 0,
    fp_id: program?.fp_id,
    program_name: program?.program_name,
    vin: vin,
    net_finance_amount: calculation?.adjusted_capitalized_cost
      ? calculation?.adjusted_capitalized_cost
      : calculation?.selling_price || 0,
    finance_type: finance_type ? finance_type : '',
    order_options:
      vehiclesData?.dealer_options && vehiclesData?.dealer_options.length > 0
        ? vehiclesData?.dealer_options?.map((option: any) => {
            return {
              installation_mode: option.installation_mode,
              is_price_inclusive: option.is_price_inclusive,
              product_name: option?.product_name,
              option_id: option.option_id ? option.option_id : option.id,
              applied_price: (option.applied_price ? option.applied_price : option.price) || 0,
              rv_balloon_percentage:
                (option.rv_balloon_percentage ? option.rv_balloon_percentage : option.rv_adder) ||
                0,
              rv_balloon_value:
                (option.rv_balloon_value ? option.rv_balloon_value : option.rv_adder) || 0
            }
          })
        : undefined,
    order_rebates:
      vehiclesData?.rebates && vehiclesData?.rebates.length > 0
        ? vehiclesData?.rebates?.map((rebate: IRebate) => {
            return {
              rebate_id: rebate.id ?? rebate.rebate_id,
              name: rebate.rebate_name,
              amount: Number(rebate.rebate_amount),
              code: rebate.rebate_code,
              image_uri: rebate?.rebate_images?.[0]?.location || undefined,
              type: rebate?.type ?? RebateTypes.Dealer,
              documents: rebate?.documents?.map((doc) => {
                const { location, ...document } = doc
                return {
                  ...document
                }
              })
            }
          })
        : undefined,
    order_fnI: (fni ?? [])?.map((fniItem: any) => {
      return {
        ...(fniItem?.bundle_id && { bundle_id: fniItem?.bundle_id }),
        financial_insurance_id: fniItem?.financial_insurance_id ?? fniItem.id,
        applied_price: (fniItem?.price ?? fniItem?.applied_price) || 0,
        form_id: fniItem?.form_id,
        rate_id: fniItem?.rate_id,
        session_id: fniItem?.session_id,
        product_name: fniItem?.product_name,
        identifier: fniItem?.identifier,
        is_msp: fniItem?.MSP ?? fniItem?.is_msp,
        mileage: fniItem?.mileage,
        term: parseInt(fniItem?.term_months),
        surcharges: fniItem?.surcharges,
        taxable: fniItem?.applicable_finance_type?.includes(finance_type)
      }
    }),
    order_tradein:
      Object.keys(tradeIn ?? {}).length > 0
        ? tradeIn?.loan_lease_balance
          ? tradeIn
          : tradeInWithoutPaymentType
        : null,
    order_fees: fees
      .filter((fee: any) => fee.is_active)
      ?.map((fee: any) => {
        return createOrderFeeObject(fee)
      }),
    order_asset: {
      make: vehiclesData?.vehicle?.make,
      model: vehiclesData?.vehicle?.model,
      model_code: vehiclesData?.vehicle?.model_code,
      trim_description: vehiclesData?.vehicle?.trim_description,
      year: vehiclesData?.vehicle?.year,
      mileage: state?.order?.order_asset?.mileage
        ? state?.order?.order_asset?.mileage
        : vehiclesData?.vehicle?.mileage ?? null,
      msrp: vehiclesData?.vehicle?.msrp,
      exterior_color_description: vehiclesData?.vehicle?.exterior_color_description,
      interior_color_description: vehiclesData?.vehicle?.interior_color_description,
      type: vehiclesData?.vehicle?.type,
      classification: vehiclesData?.vehicle?.vehicle_class,
      engine_cylinders:
        state?.order?.order_asset?.engine_cylinders ?? vehiclesData?.vehicle?.engine_cylinders,
      power_type: vehiclesData?.vehicle?.engine_power,
      engine_displacement: vehiclesData?.vehicle?.engine_displacement,
      fuel_type: vehiclesData?.vehicle?.fuel_type,
      quantity: 1,
      unit_price: isVehicleSelectedAndOrderSaved
        ? state?.order?.order_asset?.unit_price
        : vehiclesData?.vehicle?.internet_price,
      vin: vehiclesData?.vehicle?.vin,
      unit_tax: vehiclesData?.vehicle?.unit_tax,
      stock_number: vehiclesData?.vehicle?.stock_number,
      category: vehiclesData?.vehicle?.category,
      asset_type: vehiclesData?.vehicle?.asset_type,
      asset_sub_type: vehiclesData?.vehicle?.asset_sub_type,
      description: vehiclesData?.vehicle?.description,
      supplier_name: vehiclesData?.vehicle?.supplier_name,
      photo_urls: vehiclesData?.vehicle?.photo_urls,
      transmission_type:
        state?.order?.order_asset?.transmission_type ?? vehiclesData?.vehicle?.transmission_type,
      rv_balloon_amount: vehiclesData?.vehicle?.rv_balloon_amount,
      body_type: vehiclesData?.vehicle?.body_type,
      body_style: vehiclesData?.vehicle?.body_style,
      transmission_speed: vehiclesData?.vehicle?.transmission_speed,
      engine_description: vehiclesData?.vehicle?.engine_description,
      engine_power: vehiclesData?.vehicle?.engine_power,
      horse_power: vehiclesData?.vehicle?.horse_power,
      drive_train: vehiclesData?.vehicle?.drive_train,
      vehicle_display_name: vehiclesData?.vehicle?.vehicle_display_name
    },
    rental_mode: isVehicleSelectedAndOrderSaved
      ? state?.order?.rental_mode
      : program?.rental_mode ?? null,
    rental_frequency: 'Monthly',
    insurance:
      state?.insurance && Object.keys(state?.insurance).length > 0
        ? {
            ...state?.insurance[0],
            expiration_date: state?.insurance[0].expiration_date?.split('T')[0]
              ? state?.insurance[0]?.expiration_date?.split('T')[0]
              : state?.insurance[0]?.expiration_date
          }
        : null,
    calcuation_method: program?.calculation_method,
    taxes: [taxes],
    security_deposit: securityDeposit,
    contract_start_date: contractStartdate,
    payment_days: paymentDays,
    payment_recieved: false,
    ...(state.vehiclesForComparison === VehiclesForComparisonTypes.SINGLE
      ? {
          meta_data: {
            row: rowIndex,
            col: colIndex
          }
        }
      : {})
  }
  return requestObject
}

export const createOrderRequestPayloadFromStateForCash = (state: DeskingState, dealerInfo: any) => {
  const { vin, tax_amount, due_at_signing, selling_price } = state?.selection

  const singleVehicleComparison = state.vehiclesForComparison === VehiclesForComparisonTypes.SINGLE
  const isVehicleSelectedAndOrderSaved = state.isVehicleSelectedAndOrderSaved

  const contractStartdate = state.contract_start_date

  const vehiclesData = state.vehiclesData.get(vin)

  const fees = (vehiclesData?.fees ?? {})?.Cash ?? []

  const filteredData = vehiclesData?.dealer_options?.filter(
    (option: any) =>
      (option.installation_mode === InstallationMode.PRE_INSTALLED && !option.is_price_inclusive) ||
      option.installation_mode === InstallationMode.OPTIONAL
  )
  const optionSum = filteredData?.reduce(
    (sum: any, option: any) => sum + (typeof option.price === 'number' ? option.price : 0),
    0
  )
  const fni = (vehiclesData?.fni ?? {})?.cash ?? []
  const finance_fni = (fni ?? [])?.reduce((sum: any, item: any) => {
    const price = typeof item.price === 'number' ? item.price : 0
    return sum + roundToTwoDecimal(price)
  }, 0)
  const financedFeeSum = fees.reduce(
    (total: number, fee: any) =>
      fee?.fee_handling?.toLowerCase() === FeeHandlingType.FINANCE
        ? total + (fee?.applied_price ?? fee?.default_amount ?? 0)
        : total,
    0
  )
  const sellingPrice = Number(vehiclesData?.vehicle?.internet_price || 0)
  const tradeIn = state?.tradeIn
  const { payment_type, ...tradeInWithoutPaymentType } = tradeIn || {}
  const tradeInAmount =
    Object.keys(tradeIn ?? {}).length > 0
      ? (tradeIn as IOrderTradeIn)?.trade_in_amount - (tradeIn as IOrderTradeIn)?.payoff_amount
      : 0
  const tradeInValue = tradeInAmount < 0 ? tradeInAmount : 0

  const taxes = state?.taxes?.Cash ?? []
  const rebatesSum = (vehiclesData?.rebates ?? []).reduce(
    (sum: number, item: IRebate) => sum + Number(item.rebate_amount),
    0
  )
  const requestObject = {
    reference_number: state?.order?.reference_number ? state?.order?.reference_number : undefined,
    capitalized_cost_reduction: 0,
    monthly_deprecation: 0,
    monthly_sales_use_tax: 0,
    capital_cost_reduction_tax: 0,
    tax_on_capitalized_cost_reduction: 0,
    rebate: rebatesSum,
    tax_amount,
    tax_amount_number: tax_amount,
    finance_amount: 0,
    identifier: state?.order?.identifier ? state?.order?.identifier : undefined,
    dealer_id: dealerInfo?.id,
    introducer_name: dealerInfo?.dealer_name,
    company_id: dealerInfo?.company_id,
    lender_id: state?.order?.lender_id ?? null,
    reference_id: state?.customer?.reference_id ? state?.customer?.reference_id : '',
    contract_term: 1,
    allowed_usage: null,
    credit_rating: state?.customer?.credit_rating ? state?.customer?.credit_rating : '',
    apr: 0,
    estimated_monthly_payment: 0,
    annual_usage: null,
    due_at_signing,
    selling_price,
    down_payment: 0,
    down_payment_value: 0,
    margin: 0,
    base_residual_type: 0,
    excess_mileage_rate: 0,
    base_rate: 0,
    rv_balloon_percentage: 0,
    rv_balloon_value: 0,
    asset_rv_amount: 0,
    fp_id: null,
    program_name: null,
    vin,
    net_finance_amount: 0,
    finance_type: FinanceTypes.CASH,
    deal_type: FinanceTypes.CASH,
    order_rebates:
      vehiclesData?.rebates && vehiclesData?.rebates.length > 0
        ? vehiclesData?.rebates?.map((rebate: IRebate) => {
            return {
              rebate_id: rebate.id ?? rebate.rebate_id,
              name: rebate.rebate_name,
              amount: Number(rebate.rebate_amount),
              code: rebate.rebate_code,
              image_uri: rebate?.rebate_images?.[0]?.location || undefined,
              type: rebate?.type ?? RebateTypes.Dealer,
              documents: rebate?.documents?.map((doc) => {
                const { location, ...document } = doc
                return {
                  ...document
                }
              })
            }
          })
        : undefined,
    order_options:
      vehiclesData?.dealer_options && vehiclesData?.dealer_options.length > 0
        ? vehiclesData?.dealer_options?.map((option: any) => {
            return {
              installation_mode: option.installation_mode,
              is_price_inclusive: option.is_price_inclusive,
              product_name: option?.product_name,
              option_id: option.option_id ? option.option_id : option.id,
              applied_price: (option.applied_price ? option.applied_price : option.price) || 0,
              rv_balloon_percentage:
                (option.rv_balloon_percentage ? option.rv_balloon_percentage : option.rv_adder) ||
                0,
              rv_balloon_value:
                (option.rv_balloon_value ? option.rv_balloon_value : option.rv_adder) || 0
            }
          })
        : undefined,
    order_fnI: (fni ?? [])?.map((fniItem: any) => {
      return {
        ...(fniItem?.bundle_id && { bundle_id: fniItem?.bundle_id }),
        financial_insurance_id: fniItem?.financial_insurance_id ?? fniItem.id,
        applied_price: (fniItem?.price ?? fniItem?.applied_price) || 0,
        form_id: fniItem?.form_id,
        rate_id: fniItem?.rate_id,
        session_id: fniItem?.session_id,
        product_name: fniItem?.product_name,
        identifier: fniItem?.identifier,
        is_msp: fniItem?.MSP ?? fniItem?.is_msp,
        mileage: fniItem?.mileage,
        term: parseInt(fniItem?.term_months),
        surcharges: fniItem?.surcharges,
        taxable: fniItem?.applicable_finance_type?.includes(FinanceTypes.CASH)
      }
    }),
    order_tradein:
      Object.keys(tradeIn ?? {}).length > 0
        ? tradeIn?.loan_lease_balance
          ? tradeIn
          : tradeInWithoutPaymentType
        : null,
    order_fees: fees
      .filter((fee: any) => fee.is_active)
      ?.map((fee: any) => {
        return createOrderFeeObject(fee)
      }),
    order_asset: {
      make: vehiclesData?.vehicle?.make,
      model: vehiclesData?.vehicle?.model,
      model_code: vehiclesData?.vehicle?.model_code,
      trim_description: vehiclesData?.vehicle?.trim_description,
      year: vehiclesData?.vehicle?.year,
      mileage: state?.order?.order_asset?.mileage
        ? state?.order?.order_asset?.mileage
        : vehiclesData?.vehicle?.mileage ?? null,
      msrp: vehiclesData?.vehicle?.msrp,
      exterior_color_description: vehiclesData?.vehicle?.exterior_color_description,
      interior_color_description: vehiclesData?.vehicle?.interior_color_description,
      type: vehiclesData?.vehicle?.type,
      classification: vehiclesData?.vehicle?.vehicle_class,
      engine_cylinders:
        state?.order?.order_asset?.engine_cylinders ?? vehiclesData?.vehicle?.engine_cylinders,
      power_type: vehiclesData?.vehicle?.engine_power,
      engine_displacement: vehiclesData?.vehicle?.engine_displacement,
      fuel_type: vehiclesData?.vehicle?.fuel_type,
      quantity: 1,
      unit_price: isVehicleSelectedAndOrderSaved
        ? state?.order?.order_asset?.unit_price
        : vehiclesData?.vehicle?.internet_price,
      vin: vehiclesData?.vehicle?.vin,
      unit_tax: vehiclesData?.vehicle?.unit_tax,
      stock_number: vehiclesData?.vehicle?.stock_number,
      category: vehiclesData?.vehicle?.category,
      asset_type: vehiclesData?.vehicle?.asset_type,
      asset_sub_type: vehiclesData?.vehicle?.asset_sub_type,
      description: vehiclesData?.vehicle?.description,
      supplier_name: vehiclesData?.vehicle?.supplier_name,
      photo_urls: vehiclesData?.vehicle?.photo_urls,
      transmission_type:
        state?.order?.order_asset?.transmission_type ?? vehiclesData?.vehicle?.transmission_type,
      rv_balloon_amount: vehiclesData?.vehicle?.rv_balloon_amount,
      body_type: vehiclesData?.vehicle?.body_type,
      body_style: vehiclesData?.vehicle?.body_style,
      transmission_speed: vehiclesData?.vehicle?.transmission_speed,
      engine_description: vehiclesData?.vehicle?.engine_description,
      engine_power: vehiclesData?.vehicle?.engine_power,
      horse_power: vehiclesData?.vehicle?.horse_power,
      drive_train: vehiclesData?.vehicle?.drive_train,
      vehicle_display_name: vehiclesData?.vehicle?.vehicle_display_name
    },
    rental_mode: null,
    rental_frequency: null,
    insurance:
      state?.insurance && Object.keys(state?.insurance).length > 0
        ? {
            ...state?.insurance[0],
            expiration_date: state?.insurance[0].expiration_date?.split('T')[0]
              ? state?.insurance[0]?.expiration_date?.split('T')[0]
              : state?.insurance[0]?.expiration_date
          }
        : null,
    calcuation_method: null,
    taxes: [taxes],
    security_deposit: null,
    contract_start_date: contractStartdate,
    payment_days: 0,
    payment_recieved: false,
    // ...(state.vehiclesForComparison !== VehiclesForComparisonTypes.MULTIPLE && {
      order_stage: state.vehiclesForComparison == VehiclesForComparisonTypes.MULTIPLE ? OrderStage.QUOTATION : OrderStage.APPLICATION
    // })/
  }
  return requestObject
}

export const createOrderFeeObject = (fee: any) => {
  return {
    dealer_fee_id: fee.dealer_fee_id ? fee.dealer_fee_id : fee.id,
    fee_name: fee.fee_name,
    applied_price: fee.applied_price ?? fee.default_amount,
    fee_handling: fee?.fee_handling,
    is_active: fee?.is_active,
    applicable_finance_type: fee?.applicable_finance_type,
    vendor: fee?.vendor,
    fee_type: fee?.event,
    is_taxable: fee?.taxable,
    identifier: fee?.identifier
  }
}
export const selectDefaultTerms = (terms: any[], defaultTermId: number): any[] => {
  if (terms.length === 0) {
    return []
  }

  const sortedTerms = terms.sort((a, b) => a.term - b.term)
  const defaultIndex = sortedTerms.findIndex((term) => term.id === defaultTermId)

  if (defaultIndex === -1) {
    return sortedTerms.slice(0, Math.min(3, sortedTerms.length))
  }

  if (defaultIndex === 0) {
    return sortedTerms.slice(0, 3)
  } else if (defaultIndex === sortedTerms.length - 1) {
    return sortedTerms.slice(-3)
  } else {
    return sortedTerms.slice(defaultIndex - 1, defaultIndex + 2)
  }
}

export const selectDefaultMileages = (mileages: any[], defaultMileageId: number): any[] => {
  if (mileages.length === 0) {
    return []
  }
  const sortedMileages = mileages.sort((a, b) => a.value - b.value)
  const defaultIndex = sortedMileages.findIndex((mileage) => mileage.id === defaultMileageId)

  if (defaultIndex === -1) {
    return sortedMileages.slice(0, Math.min(3, sortedMileages.length))
  }

  if (defaultIndex === 0) {
    return sortedMileages.slice(0, 3)
  } else if (defaultIndex === sortedMileages.length - 1) {
    return sortedMileages.slice(-3)
  } else {
    return sortedMileages.slice(defaultIndex - 1, defaultIndex + 2)
  }
}

export const filterEotFees = (orgranizedFees) => {
  const feesWithoutEOT = { Finance: [], Lease: [], Cash: [] }
  Object.keys(orgranizedFees || {})?.map((finance_type) => {
    feesWithoutEOT[finance_type] = orgranizedFees[finance_type]?.filter(
      (fee: IFee) => fee?.event === FeeEvent.INCEPTION
    )
  })

  return feesWithoutEOT
}

export const roundToTwoDecimal = (value) => Math.round(parseFloat(value || 0) * 100) / 100

export const createFniMonthlyImpactPayloadFromState = (
  deskingState: DeskingState,
  states: any,
  fniProducts: any,
  selectedVehicle: any
) => {
  const pricingPayload = createPricingRequestPayloadFromState(
    deskingState,
    states?.dealDesk,
    0,
    0,
    selectedVehicle.vin ?? deskingState.vehiclesVins[0] ?? ''
  )

  const requestObject = {
    ...pricingPayload,
    fni_products: fniProducts
  }

  return requestObject
}

export const isDealerInUs = (states: any) => {
  const usCountryId = states?.dealDesk?.countries?.find(
    (country: any) => country?.country_code === 'US'
  )?.id
  return states?.dealerProfileData?.dealer_address?.country_id === usCountryId
}

export const getDealerDealTypes = (vehiclesData, dealerProfile, isSingleVehicle, deal_type) => {
  let { available_finance_types = [], taxable_deal_types = [] } = dealerProfile
  const cash = taxable_deal_types.includes(FinanceTypes.CASH) ? FinanceTypes.CASH : ''
  available_finance_types = dealButtonOptions?.filter(item => available_finance_types.includes(item?.type)).map((item => item.type))

  if (!isSingleVehicle) {
    if (deal_type == FinanceTypes.CASH) {
      return [cash]
    } else if (Object.values(FinanceTypes).some((type) => type == deal_type)) {
      return [...available_finance_types]
    }
  }
  return [...available_finance_types, cash]
}

export const getDealType = (globalState: DeskingState, vin?: string) => {
  if (globalState.isVehicleSelectedAndOrderSaved) {
    return globalState.preview?.finance_type
  }
  if (globalState.vehiclesForComparison === VehiclesForComparisonTypes.SINGLE) {
    return globalState.finance_type
  }

  const vehicleFinanceType = globalState?.vehiclesData?.get(vin)?.finance_type
  if (vehicleFinanceType) return vehicleFinanceType

  return globalState.finance_type
}

export const getIsProgramLoading = (globalState: DeskingState, index: number) => {
  if (globalState?.vehiclesForComparison === VehiclesForComparisonTypes.MULTIPLE) {
    return globalState.isProgramLoading === index?.toString()
  }

  return globalState.isProgramLoading
}

export const selectDefaultTermsBasedOnPosition = (
  terms: IContractTerms[],
  defaultTermId: number,
  desiredPosition: number = 2
): IContractTerms[] => {
  const emptyArray = new Array(3).fill({})

  if (terms.length === 0) {
    return []
  }

  const sortedTerms = terms.sort((a, b) => a.term - b.term)
  const defaultIndex = sortedTerms.findIndex((term) => term.id === defaultTermId)
  if (defaultIndex === -1) {
    return emptyArray
  }

  const result: IContractTerms[] = []
  const beforeTerms = sortedTerms.slice(0, defaultIndex)
  const afterTerms = sortedTerms.slice(defaultIndex + 1)

  switch (desiredPosition) {
    case 1:
      result.push(sortedTerms[defaultIndex])
      result.push(afterTerms[0] || {})
      result.push(afterTerms[1] || {})
      break
    case 2:
      result.push(beforeTerms[beforeTerms.length - 1] || {})
      result.push(sortedTerms[defaultIndex])
      result.push(afterTerms[0] || {})
      break
    case 3:
      result.push(beforeTerms[beforeTerms.length - 2] || {})
      result.push(beforeTerms[beforeTerms.length - 1] || {})
      result.push(sortedTerms[defaultIndex])
      break
    default:
      result.push(...emptyArray)
  }

  return result
}

export const selectDefaultMileagesBasedOnPosition = (
  mileages,
  defaultMileageId: number,
  desiredPosition: number = 2
) => {
  const emptyArray = new Array(3).fill({})

  if (mileages.length === 0) {
    return []
  }

  const sortedMileages = mileages.sort((a, b) => a.value - b.value)
  const defaultIndex = sortedMileages.findIndex((mileage) => mileage.id === defaultMileageId)
  if (defaultIndex === -1) {
    return emptyArray
  }

  const result = []
  const beforeMileages = sortedMileages.slice(0, defaultIndex)
  const afterMileages = sortedMileages.slice(defaultIndex + 1)

  switch (desiredPosition) {
    case 1:
      result.push(sortedMileages[defaultIndex])
      result.push(afterMileages[0] || {})
      result.push(afterMileages[1] || {})
      break
    case 2:
      result.push(beforeMileages[beforeMileages.length - 1] || {})
      result.push(sortedMileages[defaultIndex])
      result.push(afterMileages[0] || {})
      break
    case 3:
      result.push(beforeMileages[beforeMileages.length - 2] || {})
      result.push(beforeMileages[beforeMileages.length - 1] || {})
      result.push(sortedMileages[defaultIndex])
      break
    default:
      result.push(...emptyArray)
  }

  return result
}

export const getQuoteParamsToMap = (globalState: DeskingState, financeType: string) => {
  let misc
  if (Object.keys(globalState.selection)?.length > 0) {
    const selection = globalState.selection
    misc =
      globalState[VehiclesForComparisonTypes.SINGLE][financeType]?.misc?.[selection.row - 1]?.[
        selection.col - 1
      ]
    if (globalState.isVehicleSelectedAndOrderSaved)
      misc = Array.from({ length: 3 }, () => Array(3).fill({}))
    if (selection.vehicleForComparison === VehiclesForComparisonTypes.MULTIPLE) {
      misc = Array.from({ length: 3 }, () => Array(3).fill({}))
      misc[1][1] = globalState[VehiclesForComparisonTypes.MULTIPLE][financeType]?.[0]?.misc
    }

    return {
      vin: selection.vin,
      misc: misc,
      validation_errors: {
        rv: [],
        down_payment: []
      },
      down_payments: selection.downPayment,
      quote_params: selection.quoteParams,
      calculationsBoxes: '0'
    }
  }

  let currentSelectedSingleVehicle = globalState[VehiclesForComparisonTypes.SINGLE][financeType]
  misc = currentSelectedSingleVehicle?.misc?.[1]?.[1]
  if (globalState.vehiclesForComparison === VehiclesForComparisonTypes.MULTIPLE) {
    currentSelectedSingleVehicle =
      globalState[VehiclesForComparisonTypes.MULTIPLE][financeType]?.[0]
    misc = Array.from({ length: 3 }, () => Array(3).fill({}))
    misc[1][1] = currentSelectedSingleVehicle?.misc
    return {
      vin: currentSelectedSingleVehicle?.vin,
      misc: misc,
      validation_errors: {
        rv: [],
        down_payment: []
      },
      down_payments: {
        ...currentSelectedSingleVehicle?.down_payments,
        value: currentSelectedSingleVehicle?.down_payments?.value
      },
      quote_params: currentSelectedSingleVehicle?.quote_params,
      calculationsBoxes: '0'
    }
  }

  return {
    vin: currentSelectedSingleVehicle?.vin,
    misc: misc,
    validation_errors: {
      rv: [],
      down_payment: []
    },
    down_payments: {
      ...currentSelectedSingleVehicle?.down_payments,
      value: currentSelectedSingleVehicle?.down_payments?.value?.[1]
    },
    quote_params: currentSelectedSingleVehicle?.quote_params?.[1],
    calculationsBoxes: '0'
  }
}

export function reorderObjectsByVin(data, targetVin, state, currentMappedVehicle) {
  const defaultCashObject = { vin: '', calculationsBoxes: {} }

  if (!data) { 
    data = [state?.single?.Cash]
    while (data.length < 3) {
      data.push(defaultCashObject)
    }
    return data
  }
  const targetIndex = data?.findIndex(obj => obj.vin === targetVin)
  if (targetIndex !== -1 && targetIndex != undefined) {
      const [targetObject] = data.splice(targetIndex, 1)
      data.unshift(state?.single?.Cash)
  } else if (targetIndex == -1) {
      data.unshift(state?.single?.Cash)
  }
  const filteredDealKeys = Array.from(currentMappedVehicle.keys())
  
  const updatedCashDeals = data.filter(d => filteredDealKeys.includes(d.vin))

  while (updatedCashDeals.length < 3) {
    updatedCashDeals.push(defaultCashObject)
  }
  return updatedCashDeals
}
export const getQuoteParamsFromTermsAndMileages = (
  globalState,
  deskingState,
  contract_term,
  allowed_usage,
  finance_type,
  row,
  apr,
  rvBalloonValue
) => {
  const orderContractTermId = globalState?.contractTerms?.find(
    (ct) => ct.term === contract_term
  )?.id
  const allowedUsageId = globalState?.allowedMillages?.find((am) => am.value === allowed_usage)?.id
  const defaultContractTerms = selectDefaultTermsBasedOnPosition(
    finance_type === FinanceTypes.FINANCE
      ? globalState.contractTermOptionsFinance
      : globalState.contractTermOptionsLease,
    orderContractTermId,
    row
  )
  const defaultAllowedMillage = selectDefaultMileagesBasedOnPosition(
    globalState.allowedMillages,
    allowedUsageId,
    row
  )

  const leastTermObject = defaultContractTerms?.reduce((prev, current) => {
    return !current.term || prev.term < current.term ? prev : current
  })

  const maxTermObject = defaultContractTerms?.reduce((prev, current) => {
    return !current.term || prev.term > current.term ? prev : current
  })

  const leastMileageObject = defaultAllowedMillage?.reduce((prev, current) => {
    return !current.value || prev.value < current.value ? prev : current
  })

  const maxMileageObject = defaultAllowedMillage?.reduce((prev, current) => {
    return !current.value || prev.value > current.value ? prev : current
  })

  const singleDefaultQuoteParams = (
    deskingState[VehiclesForComparisonTypes.SINGLE][finance_type]?.quote_params as QuoteParams[]
  )?.map((item: IContractTerms, index: number) => {
    let current_term = defaultContractTerms[index]?.term
    let current_mileage = defaultAllowedMillage[index]?.value

    if (!current_term && index === 0) {
      current_term = leastTermObject.term
    }

    if (!current_term && index === 2) {
      current_term = maxTermObject.term
    }

    if (finance_type === FinanceTypes.LEASE && !current_mileage && index === 0) {
      current_mileage = leastMileageObject.value
    }

    if (finance_type === FinanceTypes.LEASE && !current_mileage && index === 2) {
      current_mileage = maxMileageObject.value
    }

    return {
      contract_term: current_term ?? 0,
      apr: apr,
      rv_value: rvBalloonValue,
      ...(finance_type === FinanceTypes.LEASE ? { annual_usage: current_mileage ?? 0 } : {})
    }
  })

  return singleDefaultQuoteParams
}

export const getDealerState = (states: any) => {
  return states?.dealerProfileData?.dealer_address?.state_name
}

export const getRoute = (pendingRoute: number | null, defaultRoute: number) => {
  return (pendingRoute !== null && pendingRoute !== undefined) ? pendingRoute : defaultRoute
}

export const isNotNullOrUndefined = (value: any) => {
  return value !== null && value !== undefined
}

export const isValueDifferentWhenBaseExists = (value1: any, value2: any) => {
  return isNotNullOrUndefined(value2) && value1 !== value2
}
