// @ts-nocheck

import { FC } from 'react'
import { useTheme } from '@mui/material'
import { Box, Button, Grid, Icon, Input, Menu, Typography } from '@ntpkunity/controls'
import {
  CalculationBox,
  DownPayment,
  Label,
  QuoteParam,
  Value,
  SellingPrice
} from './finance-lease-calculator'
import { AddVehicle } from './add-vehicle'
import {
  DeskingActionTypes,
  useDeskingContext,
  VehiclesForComparisonTypes
} from './desking-context-provider'
import { useExtrasModal } from './add-options'
import { InstallationMode, TabName } from '@helpers/enums'
import { useStoreContext } from '@store/storeContext'

interface ISingleVehiclePricing {
  vin: string | undefined
  index: number
}

interface IExtrasProps {
  vin: string | undefined
  label: string
  tabName: TabName
}

const getArray = (tabName: TabName, vehiclesData: any, finance_type: string) => {
  switch (tabName) {
    case TabName.OPTIONS:
      return vehiclesData?.dealer_options ?? []
    case TabName.FEES:
      return vehiclesData?.fees?.[finance_type] ?? []
    default:
      return []
  }
}

const getValue = (tabName: TabName, data: any) => {
  switch (tabName) {
    case TabName.OPTIONS:
      const val =
        (data.installation_mode === InstallationMode.PRE_INSTALLED && !data.is_price_inclusive) ||
        data.installation_mode !== InstallationMode.PRE_INSTALLED
          ? data.applied_price ?? data.price
          : 0
      return val
    case TabName.FEES:
      return data.applied_price ?? data.default_amount
    default:
      return 0
  }
}

const ExtrasRow: FC<IExtrasProps> = ({ vin, label, tabName }) => {
  const theme = useTheme()
  const {
    states: { dealDesk }
  } = useStoreContext()
  const { state, dispatch } = useDeskingContext()
  const { showExtrasPopup, hideExtrasPopup } = useExtrasModal()

  const { vehiclesData, finance_type, selection } = state
  const { defaultCurrency } = dealDesk
  const _vehiclesData = vehiclesData?.get(vin) ?? {}

  const value = getArray(tabName, _vehiclesData, finance_type)?.reduce(
    (sum: number, data: any) => sum + getValue(tabName, data),
    0
  )

  const handleExtrasData = (data: any) => {
    dispatch({
      type: DeskingActionTypes.UPDATE_OPTIONS_FEES_FNI,
      payload: {
        vinUOFF: vin,
        // fni: [],
        ...data
      }
    })
    hideExtrasPopup()
  }

  return (
    <Box theme={theme} className="flc-li-left-col" mb={0.3}>
      <Box theme={theme} className="flc-labels-wrap">
        <Box theme={theme} className="label-row">
          <Typography
            theme={theme}
            variant="caption"
            component="div"
            className="label text-overflow"
            children={label}
          />
          <Input
            theme={theme}
            fullWidth
            type="number"
            value={(value ?? 0)?.toFixed(2)}
            startAdornment={defaultCurrency?.symbol}
          />
          <Menu
            theme={theme}
            options={[
              {
                optionText: 'Add',
                optionValue: 'add',
                disabled: !vin
              }
            ]}
            handleOptionClick={(_event, _key, value) => {
              switch (value) {
                case 'add':
                  showExtrasPopup({
                    vin: vin as string,
                    vehiclesData,
                    finance_type,
                    tabName,
                    contract_term: selection?.quoteParams?.contract_term,
                    data: _vehiclesData,
                    saveExtrasData: handleExtrasData
                  })
              }
            }}
            render={(cb) => (
              <Button theme={theme} iconText={<Icon name="MoreIcon" />} onClick={cb} />
            )}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export const SingleVehiclePricing: FC<ISingleVehiclePricing> = ({ vin, index }) => {
  const theme = useTheme()
  const { state } = useDeskingContext()

  const { vehiclesData, finance_type } = state

  const { down_payments, quote_params, calculationsBoxes } = state[
    VehiclesForComparisonTypes.MULTIPLE
  ][finance_type].find((data, idx) => data.vin === (vin ?? '') || idx === index)
  const financedAmount = calculationsBoxes?.adjusted_capitalized_cost
  const vehicleData = vehiclesData.get(vin)

  const LTVPercent =
    (vin ?? '').length > 0 && !!financedAmount && Object.keys(vehicleData ?? {}).length > 0
      ? (calculationsBoxes?.adjusted_capitalized_cost / vehicleData.vehicle.msrp) * 100
      : null

  return (
    <Grid theme={theme} item xs>
      <Box theme={theme} mb={1}>
        <AddVehicle vin={vin} index={index} />
      </Box>
      <Box theme={theme} className="flc-li-left-col" mb={0.5}>
        <Box theme={theme} className="flc-labels-wrap">
          <Box theme={theme} className="label-row">
            <Box theme={theme} className="label text-overflow-wrap">
              <Label name={'Selling Price'} />
            </Box>
            <SellingPrice vin={vin} />
          </Box>
        </Box>
      </Box>
      <Box theme={theme} className="flc-li-left-col" mb={0.5}>
        <Box theme={theme} className="flc-labels-wrap">
          <Box theme={theme} className="label-row">
            <Box theme={theme} className="label text-overflow-wrap">
              <Label name={'Down Payment'} />
            </Box>
            <DownPayment
              key={index}
              vin={vin}
              rowIndex={0}
              colIndex={index}
              value={down_payments.value}
            />
          </Box>
        </Box>
      </Box>
      <Box theme={theme} mb={0.2}>
        <QuoteParam vin={vin} rowIndex={0} colIndex={index} value={quote_params} />
      </Box>
      <Box theme={theme} mb={1}>
        <ExtrasRow key={`${index}-${TabName.FNI}`} vin={vin} label="F&I" tabName={TabName.FNI} />
        <ExtrasRow
          key={`${index}-${TabName.OPTIONS}`}
          vin={vin}
          label="Add-Ons"
          tabName={TabName.OPTIONS}
        />
        <ExtrasRow key={`${index}-${TabName.FEES}`} vin={vin} label="Fees" tabName={TabName.FEES} />
        {/* <ExtrasRow vin={vin} label='Rebates' tabName={TabName} /> */}
      </Box>
      <Box theme={theme} mb={1}>
        <CalculationBox
          key={`${VehiclesForComparisonTypes.MULTIPLE}-${0}-${index}`}
          comparison={VehiclesForComparisonTypes.MULTIPLE}
          vin={vin}
          value={calculationsBoxes}
          rowIndex={0}
          colIndex={index}
        />
      </Box>
      <Box theme={theme} className="flc-li flc-footer border-bottom full-width" mb={1}>
        <Label name={'Financed Amount'} />
        <Value key={''} value={financedAmount ?? '0.00'} />
      </Box>
      <Box theme={theme} className="flc-li flc-footer border-bottom full-width" mb={1}>
        <Label name={'LTV%'} />
        <Value key={''} value={LTVPercent ?? '0.00'} isCurrency={false} />
      </Box>
    </Grid>
  )
}

const MultipleVehicleComparison: FC = () => {
  const theme = useTheme()
  const {
    state: { vehiclesVins }
  } = useDeskingContext()

  const vins = Array.from({ length: 3 }, (_, i) => vehiclesVins[i])

  return (
    <>
      <Box theme={theme} className="flc-wrap">
        <Box theme={theme} className="flc-ul">
          <Box theme={theme} className="flc-li full-width list-view">
            <Grid theme={theme} container rowSpacing={1} columnSpacing={1}>
              {vins.map((vin: string | undefined, index: number) => {
                return <SingleVehiclePricing key={index} vin={vin} index={index} />
              })}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default MultipleVehicleComparison
