import { FC, useState } from 'react'
import { useTheme, ToggleButton } from '@mui/material'
import {
  Box,
  Typography,
  Button,
  // Tooltip,
  Icon,
  Menu,
  Grid
} from '@ntpkunity/controls'
import { ConfirmationDialog, ToggleCollapse, ToggleCollapseWrap } from 'components'
import { useDeleteDocumentByIdentifier, useGetPreSignedUrl } from '@apis/order-management.service'
import printJS from 'print-js'
import { SignerRole } from '@helpers/enums/contract-documents.enum'
import { downloadDocument } from '@helpers/methods'
import { DialogMessages, QueryKeys, ToastMessages } from '@helpers/enums'
import { useStoreContext } from '@store/storeContext'
import { useQueryClient } from 'react-query'
interface IContractDocumentProps {
  document?: any
  setViewDocument: any
  setOpenDocumentViewer: any
  hasInsurance: any
  setOpenDocumentViewMode?: any
  setSignerRole?: any
  type?: string
  isLocked?: boolean
  isOptionsDisabled?: boolean
}
const ContractDocuments: FC<IContractDocumentProps> = ({
  document,
  setViewDocument,
  setOpenDocumentViewer,
  hasInsurance,
  setOpenDocumentViewMode,
  type,
  isLocked,
  setSignerRole,
  isOptionsDisabled
}) => {
  const theme = useTheme()
  const { actions } = useStoreContext()
  const queryClient = useQueryClient()

  const [ToggleCollapseOpen, ToggleCollapseSetOpen] = useState(true)
  const [documentIdentifier, setDocumentIdentifier] = useState()
  const [confirmationPopupState, setConfirmationPopupState] = useState<boolean>(false)

  const { mutate: getPreSignedUrl } = useGetPreSignedUrl()
  const { mutate: deleteDocument } = useDeleteDocumentByIdentifier()

  const printPDF = async (url: string) => {
    printJS({ printable: url, type: 'pdf', showModal: false })
  }
  const getMenuOptions = () => {
    const options: Array<any> = []
    if (isLocked) {
      options.push({
        optionText: <>View</>,
        optionkey: 'View',
        optionValue: document,
        disabled: !!isOptionsDisabled
      })
    }
    options.push(
      {
        optionText: <>Download</>,
        optionkey: 'Download',
        optionValue: document,
        disabled: !!isOptionsDisabled
      },
      {
        optionText: <>Print</>,
        optionkey: 'Print',
        optionValue: document,
        disabled: !!isOptionsDisabled
      }
    )
    if (document?.data) {
      options.push({
        optionText: <>Delete</>,
        optionkey: 'Delete',
        optionValue: document,
        disabled: !!isOptionsDisabled
      })
    }
    if (!isLocked) {
      document?.signatures
        ?.filter((item: any) => item.type == 'signature')
        ?.forEach((obj: any) => {
          if (obj?.signer == SignerRole.DEALER_ADMIN) {
            options.push({
              optionText: <>Sign as Dealer </>,
              optionkey: 'Sign as Dealer',
              optionValue: document,
              disabled: !!isOptionsDisabled
            })
          } else if (obj?.signer == SignerRole.APPLICANT) {
            options.push({
              optionText: <>Sign as Customer </>,
              optionkey: 'Sign as Customer',
              optionValue: document,
              disabled: !!isOptionsDisabled
            })
          } else if (obj?.signer == SignerRole.CO_APPLICANT) {
            options.push({
              optionText: <>Sign as Co-applicant </>,
              optionkey: 'Sign as Co-applicant',
              optionValue: document,
              disabled: !!isOptionsDisabled
            })
          }
        })
    }
    return options
  }

  const onDeleteConfirm = () => {
    deleteDocument(
      { identifier: documentIdentifier },
      {
        onSuccess(_res: any) {
          queryClient.invalidateQueries(QueryKeys.GET_ORDER_DOCUMENT_PACKAGES)
          setConfirmationPopupState(false)
          actions.setToast({
            toastMessage: ToastMessages.Record_Deleted_Success,
            toastState: true
          })
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error?.response?.data?.detail ?? ToastMessages.INTERNAL_SERVER_ERROR,
            toastState: true,
            variant: 'error'
          })
        }
      }
    )
  }
  return (
    <>
      <Box className="row" theme={theme}>
        <ToggleCollapse className="toggle-collapse" theme={theme}>
          <ToggleButton
            value="check"
            selected={ToggleCollapseOpen}
            onChange={() => {
              ToggleCollapseSetOpen(!ToggleCollapseOpen)
            }}>
            <Icon name="ChevronDown" />
          </ToggleButton>
        </ToggleCollapse>

        <Box className="center title" theme={theme}>
          <Icon name="DocumentIcon" />
          <Typography
            variant="body2"
            sx={{ ml: 2 }}
            component="h3"
            className="card-text"
            theme={theme}
            children={document?.title}
          />
        </Box>
        <Box className="center actions" theme={theme}>
          <Icon
            className="icon"
            name={`${
              document?.signatures?.every((signature: any) => signature?.status === 'Signed')
                ? 'GreenCheckIcon'
                : 'NotVerifiedIcon'
            }`}
          />
          {hasInsurance && type !== 'Contract' ? (
            <Menu
              sx={{ ml: 2 }}
              theme={theme}
              options={[
                {
                  optionText: <>View</>,
                  optionkey: 'View',
                  optionValue: document
                }
              ]}
              handleOptionClick={(_event: any, key: any, value: any) => {
                switch (key) {
                  case 'View':
                    setOpenDocumentViewMode(true)
                    setViewDocument(value)
                    break
                  default:
                    break
                }
              }}
              render={(handleOptionClick: any) => (
                <Box theme={theme}>
                  <Button
                    theme={theme}
                    defaultBtn
                    iconText={<Icon name="MoreIcon" />}
                    onClick={handleOptionClick}
                  />
                </Box>
              )}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            />
          ) : (
            <Menu
              sx={{ ml: 2 }}
              theme={theme}
              options={getMenuOptions()}
              handleOptionClick={(_event: any, key: any, value: any) => {
                switch (key) {
                  case 'Download':
                    getPreSignedUrl(
                      { key: value?.generated_document_uri },
                      {
                        onSuccess(res: any) {
                          downloadDocument(res, value?.generated_document_uri)
                        }
                      }
                    )
                    break
                  case 'Delete':
                    setDocumentIdentifier(value?.identifier)
                    setConfirmationPopupState(true)
                    break
                  case 'View':
                    setOpenDocumentViewMode(true)
                    setViewDocument(value)
                    break
                  case 'Print':
                    getPreSignedUrl(
                      { key: value?.generated_document_uri },
                      {
                        onSuccess(res: any) {
                          printPDF(res)
                        }
                      }
                    )
                    break
                  case 'Sign as Dealer':
                    setViewDocument(value)
                    setOpenDocumentViewer(true)
                    setSignerRole(SignerRole.DEALER_ADMIN)
                    break
                  case 'Sign as Customer':
                    setViewDocument(value)
                    setOpenDocumentViewer(true)
                    setSignerRole(SignerRole.APPLICANT)
                    break
                  case 'Sign as Co-applicant':
                    setViewDocument(value)
                    setOpenDocumentViewer(true)
                    setSignerRole(SignerRole.CO_APPLICANT)
                    break
                  default:
                    break
                }
              }}
              render={(handleOptionClick: any) => (
                <Box theme={theme}>
                  <Button
                    theme={theme}
                    defaultBtn
                    iconText={<Icon name="MoreIcon" />}
                    onClick={handleOptionClick}
                  />
                </Box>
              )}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            />
          )}
        </Box>
      </Box>
      <ToggleCollapseWrap className="toggle-collapse-wrap" theme={theme}>
        <Box
          theme={theme}
          pt={2}
          pl={9}
          pr={9}
          className={ToggleCollapseOpen ? 'hide-slide-top' : 'show-slide-top'}>
          {document?.signatures
            ?.filter((item: any) => item.type == 'signature')
            ?.map((item: any, index: number) => (
              <Grid
                theme={theme}
                className="container"
                container
                rowSpacing={0}
                columnSpacing={1}
                key={index}>
                <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={6}>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="h3"
                    className="label"
                    children={item?.signer}
                  />
                </Grid>
                <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={6}>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="span"
                    children={item?.status === 'NotSigned' ? 'Not Signed' : 'Signed'}
                  />
                </Grid>
              </Grid>
            ))}
        </Box>
      </ToggleCollapseWrap>
      {/* <Box className="line" theme={theme}>
        <hr />
      </Box> */}
      <ConfirmationDialog
        openPopUp={confirmationPopupState}
        onConfirm={onDeleteConfirm}
        setPopUpState={setConfirmationPopupState}
        confirmationTitle={DialogMessages.removeDocument}
        confirmationText="Once confirmed, you cannot undo this action."
        primaryBtnText="Confirm"
        secondaryBtnText="Cancel"
        icon={<Icon className="alert-icon" name="ErrorTriangle" />}
        onCancel={() => setConfirmationPopupState(false)}
        hideCancelButton={false}
        disablePortal={false}
      />
    </>
  )
}

export default ContractDocuments
