import { TableList } from "@components"
import { useTheme } from '@mui/material'
import { Input } from "@ntpkunity/controls"
// import { useStoreContext } from "@store/storeContext"
import { DeskingActionTypes, FinanceTypes, useDeskingContext, VehiclesForComparisonTypes } from "./desking-context-provider"
import { InstallationMode } from "@helpers/enums"
import {
    cashDueAtSigningCalculator,
    cashTaxCalculator,
    feeSumCalculator,
    fniTotalCalculator,
    mspTotalCalculator,
    optionPriceCalculator,
    tradeInAmountCalculator
} from "@helpers/caculationHelper"
import { useEffect, useState } from "react"
import { VehicleLoader } from "./finance-lease-calculator"
import { FinanceType } from "@helpers/enums/finance-type.enum"
import { IRebate } from "@models"
import { useStoreContext } from "@store/storeContext"

const CashPaymentDetails = () => {
    const theme = useTheme()
    const { states: { dealDesk: { defaultCurrency: { symbol } } } } = useStoreContext()
    const { state, formatSymbol, dispatch } = useDeskingContext()
    const [sellingPrice, setSellingPrice] = useState(0)
    const [dealerDiscount, setDealerDiscount] = useState(0)
    const isVehicleSelectedAndOrderSaved = state.isVehicleSelectedAndOrderSaved
    const vin = isVehicleSelectedAndOrderSaved ? state.preview.vin : (state.selection?.vin)
    const isVehicleSelected = Object.keys(state.selection ?? {}).length > 0

    let calculationsData: any = {}
    const vehicleComparisonType = state?.vehiclesForComparison

    if (isVehicleSelectedAndOrderSaved && vehicleComparisonType == VehiclesForComparisonTypes.PREVIEW) {
      calculationsData = state.preview.calculationsBoxes
    } else if (isVehicleSelected) {
      if (vehicleComparisonType === VehiclesForComparisonTypes.SINGLE) {
        calculationsData =
          state?.[state.vehiclesForComparison]?.Cash?.calculationsBoxes
      } else if (vehicleComparisonType === VehiclesForComparisonTypes.MULTIPLE) {
        calculationsData =
          state?.[state.vehiclesForComparison]?.Cash?.find((vehicleData: any) => vehicleData.vin == state.selectedVehicleVin)?.calculationsBoxes
      }
    }    

    const calcReqLoading = false
    const vehicleData = state.vehiclesData.get(vin)
    const fees = (vehicleData?.fees ?? {})?.[FinanceTypes.CASH] ?? []
    const msrp = vehicleData?.vehicle?.msrp
    const fni = (vehicleData?.fni)?.[FinanceTypes.CASH.toLowerCase()] ?? []

    const fniAmount = fniTotalCalculator(fni)
    const mspAmount = mspTotalCalculator(fni)
    const fniTotal = fniAmount + mspAmount
    const cashFeeSum = feeSumCalculator(fees, FinanceType.CASH)
    const optionsPrice = optionPriceCalculator(vehicleData?.dealer_options ?? [], InstallationMode.PRE_INSTALLED)
    const tradeInEquity = tradeInAmountCalculator(state?.tradeIn)
    const tradeInInEquity = tradeInEquity < 0 ? tradeInEquity : 0
    const cashRebates = (vehicleData?.rebates ?? []).reduce(
        (sum: number, item: IRebate) => sum + Number(item.rebate_amount),
        0
    )
    const dueAtSigningTotal = cashDueAtSigningCalculator(calculationsData)
    const totalTaxes = cashTaxCalculator(calculationsData)

    useEffect(() => {
        if (calculationsData?.selling_price) {
            const sellingPrice = calculationsData?.selling_price
            setSellingPrice(sellingPrice)
            const discount = +msrp < +sellingPrice ? 0 : msrp - sellingPrice
            setDealerDiscount(discount)
        } else if (!state?.selection?.[FinanceTypes.CASH]?.vin) {
            setSellingPrice(0)
            setDealerDiscount(0)
        }
    }, [calculationsData])

    const handleSellingPrice = () => {
        dispatch({
            type: DeskingActionTypes.UPDATE_SELLING_PRICE,
            payload: {
                vinUSP: vin as string,
                valueUSP: Number(sellingPrice)
            }
        })
    }

    const handleDealerDiscount = () => {
        const sellingPrice = msrp - Number(dealerDiscount)
        dispatch({
            type: DeskingActionTypes.UPDATE_SELLING_PRICE,
            payload: {
                vinUSP: vin as string,
                valueUSP: Number(sellingPrice)
            }
        })
    }

    return (
        <TableList
            listTitle="Due at Signing"
            items={[
                {
                    title: 'MSRP',
                    value: formatSymbol(msrp ?? 0)
                },
                {
                    title: 'Dealer Discount',
                    editable: true,
                    value: (
                        calcReqLoading
                            ? <VehicleLoader height={'25px'} />
                            : <Input
                                theme={theme}
                                type="text"
                                fullWidth
                                startAdornment={symbol}
                                masking
                                maskNumeric
                                maskDecimalScale={2}
                                value={dealerDiscount}
                                onChange={(discount) => setDealerDiscount(discount)}
                                onBlur={handleDealerDiscount}
                                disabled={!vin}
                            />
                    )
                },
                {
                    title: 'Selling Price',
                    editable: true,
                    value: (
                        calcReqLoading
                            ? <VehicleLoader height={'25px'} />
                            : <Input
                                theme={theme}
                                type="text"
                                fullWidth
                                startAdornment={symbol}
                                masking
                                maskNumeric
                                maskDecimalScale={2}
                                value={sellingPrice}
                                onChange={(sellingPrice) => setSellingPrice(sellingPrice)}
                                onBlur={handleSellingPrice}
                                disabled={!vin}
                            />
                    )
                },
                {
                    title: 'Add-Ons',
                    value: formatSymbol(optionsPrice ?? 0)
                },
                {
                    title: 'F&I Products',
                    value: formatSymbol(fniTotal ?? 0)
                },
                {
                    title: 'Fees',
                    value: formatSymbol(cashFeeSum ?? 0)
                },
                {
                    title: 'Trade-In Equity',
                    value: formatSymbol(tradeInEquity > 0 ? tradeInEquity ?? 0 : 0)
                },                
                {
                    title: 'Trade-In Inequity',
                    value: tradeInInEquity < 0 ? `-${formatSymbol(tradeInInEquity * -1)}` : formatSymbol(tradeInInEquity)
                },
                {
                    title: 'Taxes',
                    value: formatSymbol(totalTaxes ?? 0)
                },
                {
                    title: 'Rebates & Incentives',
                    value: formatSymbol(cashRebates ?? 0)
                },
                {
                    title: 'Total',
                    value: (
                        calcReqLoading
                            ? <VehicleLoader height={'25px'} />
                            : formatSymbol(dueAtSigningTotal)
                    ),
                    hasBoldText: true
                }
            ]}
        />
    )
}

export default CashPaymentDetails
