import axios from 'axios'
import { accessToken } from '@helpers/constants'

const getToken = () => JSON.parse(localStorage.getItem(accessToken) as string)

const getIndex = async (url: string) => {
  let response = undefined
  await axios
    .get(process.env.API_GATEWAY + url, {
      headers: {
        Authorization: 'Bearer ' + getToken().access_token
      }
    })
    .then((res) => {
      response = res.data
    })
    .catch((ex) => {
      throw ex
    })
  return response
}

const Post = async (url: string, requestBody: any) => {
  let response = undefined

  const token = getToken()

  await axios
    .post('https://dms-api-dev.netsolapp.io' + url, requestBody, {
      headers: {
        Authorization: 'Bearer ' + token.access_token
      }
    })
    .then((res) => {
      response = res.data
    })
    .catch((ex) => {
      throw ex
    })
  return response
}

export { getIndex, Post }
