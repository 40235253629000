import { FC, useEffect, useState } from 'react'
import { Divider, useTheme } from '@mui/material'
import {
  Typography,
  FileDragDrop,
  FilePreview,
  DatePicker,
  Box,
  Grid,
  Input,
  Dialog,
  Autocomplete
} from '@ntpkunity/controls'
import { Controller, useForm } from 'react-hook-form'
import AddressDetailsComponent from '@app/customer-details/address-detail'
import { useGetDealerProfileById } from '@apis/dealer-configurations.service'
import { useStoreContext } from '@store/storeContext'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import dayjs from 'dayjs'
import { InsuranceControlProps, InsuranceFormData } from './insurance-control.types'
import { Button, CustomDialogWrap } from '@components'
import { base64ToFile } from '@helpers/methods'
import { useGetInsuranceCompanies } from '@hooks/queries/useGetInsuranceCompanies'
import { IInsuranceCompanies } from 'types/responses/insuranceCompanies'
import {
  useHasPermissions,
  dealexPermissions
  //@ts-ignore
} from '@ntpkunity/controls-ums'
import { REMOVE_DASHES_REGEX, ZIP_CODE_REGEX_MAP } from '@helpers/constants'
import { isDealerInUs } from '@app/desking/utils'

function createValidationSchema(permissions: any, isUsAddress: boolean) {
  const schema = yup.object().shape({
    insurer_name: yup
      .mixed()
      .nullable()
      .test('is-valid-insurer', 'Insurance Provider Name is required', (value) => {
        return (
          value != null &&
          value.id != null &&
          value.id !== '' &&
          value.label != null &&
          value.label.trim() !== ''
        )
      }),
    agent_name: yup.string().when('$a', {
      is: () => permissions?.agentName,
      then: yup.string().nullable().notRequired(),
      otherwise: yup.string().nullable().required('Insurance Agent Name is required')
    }),
    agent_phone: yup.string().when('$a', {
      is: () => permissions?.policyNumber,
      then: yup.string().nullable().required('Agent Number is required'),
      otherwise: yup.string().notRequired()
    }),
    policy_number: yup.string().when('$a', {
      is: () => permissions?.policyNumber,
      then: yup.string().nullable().required('Policy Number is required'),
      otherwise: yup.string().nullable().required('Policy Number Name is required')
    }),
    expiration_date: yup.string().when('$a', {
      is: () => permissions?.expirationDate,
      then: yup.string().notRequired(),
      otherwise: yup
        .string()
        .nullable()
        .required('Expiration Date is required')
        .test(
          'notFutureOrCurrentDate',
          'Expiration Date cannot be equal to or lesser than current date.',
          function (value) {
            const selectedDate = dayjs(value).startOf('day')
            const currentDate = dayjs().startOf('day')
            return selectedDate.isAfter(currentDate)
          }
        )
    }),
    insurance_documents: yup.mixed().when('$a', {
      is: () => permissions?.addressDocument,
      then: yup.array().nullable().notRequired(),
      otherwise: yup
        .array()
        .of(
          yup.object().shape({
            document: yup.string().required('Document is required'),
            content_type: yup.string().required('Content type is required'),
            name: yup.string().required('Name is required'),
            file_size: yup.string().required('File size is required'),
            created_by: yup.string().nullable()
          })
        )
        .min(1, 'At least one document is required')
        .required('At least one document is required')
        .nullable()
    }),
    insurer_address: yup.object().shape({
      address_line_1: yup.string().when('$a', {
        is: () => permissions?.addressLine,
        then: yup.string().nullable().notRequired(),
        otherwise: yup.string().nullable().required('Address Line 1 is required')
      }),
      city: yup.string().when('$a', {
        is: () => permissions?.addressCity,
        then: yup.string().nullable().notRequired(),
        otherwise: yup.string().nullable().required('City is required')
      }),
      state_name: yup.string().when('$a', {
        is: () => permissions?.addressState,
        then: yup.string().nullable().notRequired(),
        otherwise: yup.string().nullable().required('State Name is required')
      }),
      zip_code: yup.string().when('$a', {
        is: () => permissions?.addressZipCode,
        then: yup
          .string()
          .nullable()
          .test(function (value) {
            if (!value) {
              return this.createError({ message: 'Zip Code is required' })
            }

            if (isUsAddress && !ZIP_CODE_REGEX_MAP.US.regex.test(value)) {
              return this.createError({ message: ZIP_CODE_REGEX_MAP.US.errorMessage })
            }

            return true
          }),
        otherwise: yup.string().nullable().notRequired()
      })
    })
  })
  return schema
}

const InsuranceControl: FC<InsuranceControlProps> = ({
  insuranceFormData,
  setInsuranceFormData,
  setInsuranceOpenDialog,
  openInsuranceDialog
}) => {
  const theme = useTheme()
  const { states } = useStoreContext()
  const { mutate: getDealerProfile } = useGetDealerProfileById()
  const permissions = {
    policyNumber: useHasPermissions(dealexPermissions?.ICONS?.POLICY_NUMBER),
    agentName: useHasPermissions(dealexPermissions?.ICONS?.INSURANCE_AGENT_NAME),
    insuranceProviderName: useHasPermissions(dealexPermissions?.ICONS?.INSURANCE_PROVIDER_NAME),
    addressCity: useHasPermissions(dealexPermissions?.ICONS?.ADDRESS_CITY),
    addressState: useHasPermissions(dealexPermissions?.ICONS?.ADDRESS_STATE),
    addressZipCode: useHasPermissions(dealexPermissions?.ICONS?.ADDRESS_ZIP_CODE),
    addressLine: useHasPermissions(dealexPermissions?.ICONS?.ADDRESS_LINE_1),
    addressDocument: useHasPermissions('Address-Document'),
    contactNumber: useHasPermissions(dealexPermissions?.ICONS?.INSURANCE_CONTACT_NUMBER),
    expirationDate: useHasPermissions(dealexPermissions?.ICONS?.Date)
  }
  const isUsAddress = isDealerInUs(states)
  const validationSchema = createValidationSchema(permissions, isUsAddress)
  const { data: insuranceCompanies } = useGetInsuranceCompanies(states?.dealerInfo?.company_id)
  const insuranceProviderList = insuranceCompanies?.map((item: IInsuranceCompanies) => {
    return { id: item?.insurance_provider_name ?? '', label: item?.insurance_provider_name ?? '' }
  })

  useEffect(() => {
    if (states?.dealerInfo?.dealer_code) {
      getDealerProfile({ dealer_id: states?.dealerInfo?.dealer_code }, {})
    }
  }, [states?.dealerInfo?.dealer_code])

  const form = useForm<InsuranceFormData>({
    defaultValues: insuranceFormData,
    resolver: yupResolver(validationSchema)
  })

  const {
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    clearErrors,
    setValue,
    control,
    watch
  } = form
  const insuranceProviderName = watch('insurer_name')
  const onChangeProvider = (_event: any, newValue: any) => {
    const removeDashes = (num: string) => num.replace(REMOVE_DASHES_REGEX, '')

    clearErrors('insurer_name')
    const newInsurerValue = newValue?.id ? newValue : { id: newValue ?? '', label: newValue ?? '' }
    setValue('insurer_name', newInsurerValue)
    if (newInsurerValue?.id) {
      const insurancePhoneNum = insuranceCompanies?.find((item: IInsuranceCompanies) => {
        return item.insurance_provider_name === newInsurerValue?.id
      })?.contact_number

      if (insurancePhoneNum) {
        setValue('agent_phone', removeDashes(insurancePhoneNum))
      }
    } else {
      if (!Array.isArray(insuranceProviderName)) {
        if (insuranceFormData?.agent_phone) {
          setValue('agent_phone', removeDashes(insuranceFormData.agent_phone))
        } else {
          const insurancePhoneNum = insuranceCompanies?.find((item: IInsuranceCompanies) => {
            return item.insurance_provider_name === insuranceProviderName?.id
          })?.contact_number
          if (insurancePhoneNum) {
            setValue('agent_phone', removeDashes(insurancePhoneNum))
          }
        }
      }
    }
  }
  const [imageFiles, setImageFiles] = useState<any[]>([])
  const readFileAsDataURL = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.onload = (event) => {
        if (event.target && event.target.result) {
          resolve(event.target.result.toString())
        } else {
          reject('Failed to read file as data URL.')
        }
      }
      fileReader.onerror = () => {
        reject('Error reading file as data URL.')
      }
      fileReader.readAsDataURL(file)
    })
  }

  const handleImageUpload = async (files: any[]) => {
    const maxFileSize = states?.lenderInfo?.individual_file_size
      ? states.lenderInfo.individual_file_size * 1024 * 1024
      : 3 * 1024 * 1024
    const maxTotalSize = states?.lenderInfo?.total_file_size
      ? states.lenderInfo.total_file_size * 1024 * 1024
      : 10 * 1024 * 1024

    const newImagesArray: any[] = []
    let fileSizeError = false
    let totalSizeError = false

    let totalSizeInBytes = 0

    imageFiles.forEach((file: any) => {
      const sizeInKB = parseFloat(file.file_size.replace('KB', '').trim())
      totalSizeInBytes += sizeInKB * 1024
    })

    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      if (file.size >= maxFileSize) {
        fileSizeError = true
        break
      }

      totalSizeInBytes += file.size
      if (totalSizeInBytes >= maxTotalSize) {
        totalSizeError = true
        break
      }

      try {
        const result = await readFileAsDataURL(file)
        newImagesArray.push({
          document: result?.split(',')[1],
          content_type: file.type,
          name: file.name,
          file_size: `${(file.size / 1024).toFixed(2)}KB`,
          created_by: 'null'
        })
      } catch (error) {
        console.error('Error reading file:', error)
      }
    }

    if (fileSizeError) {
      setError('insurance_documents', {
        type: 'manual',
        message: `Each file must be less than or equal to ${states?.lenderInfo?.individual_file_size} Mb.`
      })
      return
    }

    if (totalSizeError) {
      setError('insurance_documents', {
        type: 'manual',
        message: `Total file size must not exceed ${states?.lenderInfo?.total_file_size} Mb.`
      })
      return
    }

    if (!fileSizeError && !totalSizeError) {
      clearErrors('insurance_documents')

      setImageFiles((prevFiles) => {
        const updatedFiles = [...prevFiles, ...newImagesArray]
        setValue('insurance_documents', updatedFiles)
        return updatedFiles
      })
    }
  }

  const onImageUpload = (e: any) => {
    if (e && e.target && e.target.files && e.target.files.length > 0) {
      const files = Array.from(e.target.files)
      handleImageUpload(files)
    }
  }

  const onImageDrop = (e: any) => {
    e.preventDefault()
    if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const files = Array.from(e.dataTransfer.files)
      handleImageUpload(files)
    }
  }

  const onRemoveFile = (_fileKey: string, index: number) => {
    setImageFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter((_, i) => i !== index)
      return updatedFiles
    })
    setValue(
      'insurance_documents',
      imageFiles.filter((_, i) => i !== index)
    )
  }

  useEffect(() => {
    if (insuranceFormData?.agent_phone) {
      setValue('agent_phone', insuranceFormData.agent_phone)
    }
  }, [insuranceFormData?.agent_phone])

  useEffect(() => {
    const insuranceDocuments = getValues('insurance_documents') || []
    if (insuranceDocuments && insuranceDocuments.length > 0) {
      const filesArray = base64ToFile(insuranceDocuments)
      setImageFiles(filesArray.length > 0 ? filesArray : insuranceDocuments)
    }
  }, [getValues('insurance_documents')])
  useEffect(() => {
    if (Object.keys(insuranceFormData?.insurer_address ?? {}).length > 0) {
      setValue('insurer_address', insuranceFormData?.insurer_address)
    }
  }, [insuranceFormData?.insurer_address])
  const handleInsuranceClose = () => {
    setInsuranceOpenDialog(false)
  }

  const onSubmit = (data: any) => {
    const isExpirationDateValid = data?.expiration_date
    const payload = {
      ...data,
      insurance_documents: imageFiles,
      insurer_name: data?.insurer_name?.id,
      created_by: 'lender'
    }
    if (!isExpirationDateValid) {
      delete payload.expiration_date
    }
    setInsuranceOpenDialog(false)
    setInsuranceFormData(payload)
  }

  return (
    <CustomDialogWrap theme={theme} className="insurance-dialog-wrap">
      <Dialog
        variant={undefined}
        size="md"
        title="Insurance"
        open={openInsuranceDialog}
        onCloseDialog={handleInsuranceClose}
        disablePortal
        customFooter={
          <Button theme={theme} primary text="Continue" onClick={handleSubmit(onSubmit)} />
        }
        theme={theme}
        children={
          <>
            <Grid theme={theme} container rowSpacing={3} columnSpacing={3}>
              <Grid item theme={theme} xs={12} sm={6}>
                <Box theme={theme} className="insurance-autocomplete">
                  <Controller
                    name="insurer_name"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        theme={theme}
                        fullWidth
                        label="Insurance Provider Name"
                        items={insuranceProviderList ?? []}
                        searchMatchFrom="any"
                        disablePortal={true}
                        freeSolo
                        placeholder="Select or Add here . . "
                        {...field}
                        onInputChange={onChangeProvider}
                        onChange={onChangeProvider}
                        error={errors?.insurer_name?.message}
                      />
                    )}
                  />
                </Box>
              </Grid>
              {!permissions?.agentName && (
                <Grid item theme={theme} xs={12} sm={6}>
                  <Controller
                    name="agent_name"
                    control={control}
                    render={({ field }) => (
                      <Input
                        theme={theme}
                        fullWidth
                        label="Insurance Agent Name"
                        placeholder="Add here . . "
                        type="text"
                        {...field}
                        error={errors?.agent_name?.message}
                      ></Input>
                    )}
                  />
                </Grid>
              )}
              <Grid item theme={theme} xs={12} sm={6}>
                <Controller
                  name="policy_number"
                  control={control}
                  render={({ field }) => (
                    <Input
                      theme={theme}
                      fullWidth
                      label="Policy Number"
                      placeholder="Add here . . "
                      type="text"
                      {...field}
                      error={errors?.policy_number?.message}
                    ></Input>
                  )}
                />
              </Grid>
              <Grid item theme={theme} xs={12} sm={6}>
                <Controller
                  name="expiration_date"
                  control={control}
                  defaultValue=""
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      label="Expiration"
                      disablePast
                      disablePortal
                      theme={theme}
                      value={value ?? ''}
                      onChange={(e: any) => {
                        if (
                          e instanceof Date &&
                          e.getFullYear() === 1970 &&
                          e.getMonth() === 0 &&
                          e.getDate() === 1
                        ) {
                          onChange(null)
                        } else {
                          const formattedDate = e.toISOString().split('T')[0]
                          onChange(formattedDate)
                        }
                      }}
                      error={errors.expiration_date?.message}
                      inputReadOnly
                    />
                  )}
                />
              </Grid>
              <Grid item theme={theme} xs={12}>
                <Divider />
              </Grid>
              <Grid item theme={theme} xs={12}>
                <Typography
                  theme={theme}
                  variant="subtitle1"
                  component="h5"
                  children="Insurance Provider Address"
                />
              </Grid>
              <Grid item theme={theme} xs={12}>
                <AddressDetailsComponent
                  form={form}
                  isGoogleEnabled={false}
                  addressValue={'insurer_address'}
                  moveIn={false}
                  errorProps={errors.insurer_address}
                  control={control}
                  showContactNumber
                  contactError={errors?.agent_phone?.message}
                />
              </Grid>
            </Grid>
            <Divider sx={{ mb: 3, mt: 3 }} />
            <Box theme={theme} sx={{ mb: 1 }}>
              <Typography
                theme={theme}
                variant="subtitle1"
                component="h5"
                children="Proof of Insurance"
              />
              <Typography
                theme={theme}
                variant="caption"
                component="span"
                children="Supported formats: JPG, JPEG, PNG, PDF."
              />
            </Box>
            <Controller
              name="insurance_documents"
              control={control}
              render={({ field }) => (
                <FileDragDrop
                  {...field}
                  accept=".jpg,.jpeg,.png,.pdf"
                  theme={theme}
                  width="300"
                  height="200px"
                  backgroundColor="white"
                  onDrop={onImageDrop}
                  onChange={onImageUpload}
                  allowMultiple={true}
                  error={!!errors?.insurance_documents?.message}
                />
              )}
            />
            <Typography
              theme={theme}
              variant="caption"
              component="p"
              className="text-danger"
              mx={2}
            >
              {errors?.insurance_documents?.message}
            </Typography>
            {imageFiles && imageFiles.length > 0 && imageFiles[0] != null && (
              <FilePreview
                files={imageFiles.length > 0 ? imageFiles : []}
                onRemoveFile={onRemoveFile}
                theme={theme}
              />
            )}
            <Divider sx={{ mt: 4 }} />
          </>
        }
      />
    </CustomDialogWrap>
  )
}
export default InsuranceControl
