import { styled } from '@mui/material'
import { Box } from '@ntpkunity/controls'

export const ContractstWrap = styled(Box)(() => ({
  '&.contract-wrap': {
    ".u-dialog": {
        ".MuiPaper-root": {
            ".u-dialog-content": {
                "&.has-no-footer": {
                    padding: 0,
                    ".iframe": {
                        borderRadius: 0,
                        height: 'calc(100vh - 154px)',
                        ".viewport": {
                            height: 'auto'
                        }
                    }
                }
            }
        }
    }
  }
}))
