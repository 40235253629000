import { FC, useEffect, useState } from 'react'
import { Icon, Box, DataTable, Input, Button, Menu, Checkbox } from '@ntpkunity/controls'
import { TablePagination, useTheme } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { DataTableWrap } from '@components'
import { useAddonsAccessories } from '@apis/dealer-addon.service'
import { useStoreContext } from '@store/storeContext'
import { InstallationMode } from '@helpers/enums'
import { UseQuery_GetAllOptionCategorys } from '@apis/configurations.service'
import { IOption } from 'controls/addon-control/addon-interface'
import AddOnsSupplier from '../add-ons-supplier/add-ons-supplier'

interface IOptionsTable {
    callBack: any
    callBackBulk: any
    defaultCurrency: string
    addedItems: IOption[]
    setAddedItems: any
    addedItemPrices: any
    setAddedItemPrices: any
}

const OptionsTable: FC<IOptionsTable> = ({ callBack, callBackBulk, defaultCurrency, addedItems, setAddedItems, addedItemPrices, setAddedItemPrices }) => {
    const theme = useTheme()
    const { states } = useStoreContext()
    const [filters, setFilters] = useState<any>()
    const [pageSize, setPageSize] = useState<number>(5)
    const [pageNumber, setPageNumber] = useState<number>(0)
    const [columnFilters, setColumnFilters] = useState()

    const { data: addonAccessories, mutate: getAddonAccessories } = useAddonsAccessories()
    const [optionsData, setOptionsData] = useState<any>([])
    const [editingPrice, setEditingPrice] = useState<{ [key: number]: boolean }>({})
    const [priceDraft, setPriceDraft] = useState<{ [key: number]: string }>({})

    const vinModelName = (states?.orderData?.selected_vehicle as any)?.vehicle?.model

    const [cardDetails, setCardDetails] = useState({ product_name: '' })
    const [openProductDialog, setOpenProductDialog] = useState(false)
    const { data: optionCategories } = UseQuery_GetAllOptionCategorys()

    const calculateSum = (obj: any) => {
        const itemId = obj?.option_id || obj?.id
        if (!addedItems.includes(itemId)) {
            setAddedItems([...addedItems, itemId])
            setAddedItemPrices((prevPrices: any) => ({
                ...prevPrices,
                [itemId]: obj?.price
            }))
            callBack('add', obj)
        }
    }

    const removeItem = (data: any) => {
        const itemId = data?.option_id ?? data?.id

        setAddedItems(addedItems.filter((id: any) => id !== (itemId)))
        setAddedItemPrices((prevPrices: any) => {
            const newPrices = { ...prevPrices }
            delete newPrices[itemId]
            return newPrices
        })
        callBack('remove', data)
    }
    const isAdded = (id: any) => addedItems.includes(id)

    useEffect(() => {
        if (states?.dealerInfo?.dealer_code) {
            getAddonAccessories({
                data: `dealer_code=${states?.dealerInfo?.dealer_code}&compatible_models=${vinModelName}${columnFilters ? columnFilters : `&page_number=${pageNumber}&page_size=${pageSize}`}`
            })
        }
    }, [states?.dealerInfo?.dealer_code, columnFilters])

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPageNumber(newPage)
        setColumnFilters(setQueryString(newPage, pageSize))
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageSize(+event.target.value)
        setPageNumber(0)
        setColumnFilters(setQueryString(0, +event.target.value))
    }

    const setQueryString = (pgNo: number, pgSize: number): any => {
        let query_string: string = `&page_number=${pgNo}&page_size=${pgSize}`
        if (filters?.productNameFilter?.trimStart() != null && filters?.productNameFilter?.trimStart() != '') {
            query_string = query_string.concat(`&product_name=${filters?.productNameFilter}`)
        }

        if (filters?.installationModeFilter?.trimStart() != null && filters?.installationModeFilter?.trimStart() != '') {
            query_string = query_string.concat(`&installation_mode=${filters?.installationModeFilter}`)
        }

        if (filters?.installationTypeFilter?.trimStart() != null && filters?.installationTypeFilter?.trimStart() != '') {
            query_string = query_string.concat(`&is_price_inclusive=${filters?.installationTypeFilter}`)
        }

        if (filters?.priceFilter?.trimStart() != null && filters?.priceFilter?.trimStart() != '') {
            query_string = query_string.concat(`&price=${filters?.priceFilter}`)
        }

        if (filters?.categoryFilter?.trimStart() != null && filters?.categoryFilter?.trimStart() != '') {
            query_string = query_string.concat(`&category=${filters?.categoryFilter}`)
        }

        if (filters?.supplierFilter?.trimStart() != null && filters?.supplierFilter?.trimStart() != '') {
            query_string = query_string.concat(`&supplier=${filters?.supplierFilter}`)
        }

        if (filters?.partNumberFilter?.trimStart() != null && filters?.partNumberFilter?.trimStart() != '') {
            query_string = query_string.concat(`&part_no=${filters?.partNumberFilter}`)
        }

        if (filters?.descriptionFilter?.trimStart() != null && filters?.descriptionFilter?.trimStart() != '') {
            query_string = query_string.concat(`&description=${filters?.descriptionFilter}`)
        }

        return query_string
    }

    const handleFilter = () => {
        setPageNumber(0)
        setColumnFilters(setQueryString(0, pageSize))
    }

    useEffect(() => {
        if (addonAccessories) {
        let filteredAccessories = { result: [] }
        filteredAccessories.result = addonAccessories?.result?.filter((item: any) => (
            item.installation_mode === InstallationMode.OPTIONAL ||
            (item.installation_mode === InstallationMode.PRE_INSTALLED && 
            addedItems?.includes(item.id))
        ))
        setOptionsData(filteredAccessories)
    }
    }, [addonAccessories])

    const handleProductDetailsView = (cardData: any) => {
        setOpenProductDialog(true)
        setCardDetails(cardData)
    }

    const handleCloseProductDetails = () => {
        setOpenProductDialog(false)
    }

    const handleCheckboxChange = (product: any) => {
        if (isAdded(product?.id)) {
            removeItem(product)
        } else {
            calculateSum(product)
        }
    }

    const handleEditPrice = (index: number) => {
        setEditingPrice((prevState) => ({
            ...prevState,
            [index]: !prevState[index]
        }))
    }

    const setPriceDraftValue = (index: number, newPrice: string) => {
        setPriceDraft((prevDraft) => ({
            ...prevDraft,
            [index]: newPrice
        }))
    }

    const handlePriceChange = (index: number, newPrice: string) => {
        setOptionsData((prevData: any) => {
            const updatedData = [...prevData.result]
            updatedData[index].price = parseFloat(newPrice)
            return { ...prevData, result: updatedData }
        })
        setAddedItemPrices((prevPrices: any) => ({
            ...prevPrices,
            [optionsData?.result?.[index]?.id]: parseFloat(newPrice)
        }))

        const updatedOption = optionsData?.result?.[index]

        if (updatedOption) {
            callBack('update', {
                ...updatedOption,
                price: parseFloat(newPrice)
            })
        }
    }

    const handleCancelEdit = (index: number) => {
        setEditingPrice((prevState) => ({
            ...prevState,
            [index]: false
        }))
        setPriceDraft((prevDraft) => ({
            ...prevDraft,
            [index]: addedItemPrices?.[optionsData?.result?.[index]?.id] ?? optionsData?.result?.[index]?.price
        }))
    }

    const selectedAllOptions =
        (optionsData?.result?.length > 0 &&
        optionsData?.result?.every((option: any) => addedItems?.includes(option?.id))) || false

    const allPreInstalled = optionsData?.result?.every(
        (option: any) => option?.installation_mode === InstallationMode.PRE_INSTALLED) || false

    const handleSelectAll = () => {
    const notPreInstalledOptions =
      optionsData?.result
        ?.filter((option: any) => (
          option?.installation_mode !== InstallationMode.PRE_INSTALLED &&
          (selectedAllOptions || !isAdded(option?.id))
        ))
        ?.map((option: any) => {
          return {
            id: option?.id,
            price: option?.price
          }
        }) || []
    
    const nonPreInstalledIds = notPreInstalledOptions.map((option: any) => option.id)

    if (selectedAllOptions) {
      setAddedItems(addedItems.filter((item) => !nonPreInstalledIds?.includes(item)))
      setAddedItemPrices((prevPrices: any) => {
        const newPrices = { ...prevPrices }
        notPreInstalledOptions.forEach((item: any) => {
          delete newPrices[item.id]
        })

        return newPrices
      })

      callBackBulk('remove', optionsData?.result?.filter((item: any) => 
        nonPreInstalledIds?.includes(item?.id)
      ))
      return
    }

    setAddedItems([...addedItems, ...nonPreInstalledIds])
    setAddedItemPrices((prevPrices: any) => ({
      ...prevPrices,
      ...notPreInstalledOptions?.reduce((acc: any, item: any) => {
        acc[item.id] = item.price
        return acc
      }, {})
    }))
    callBackBulk('add', optionsData?.result?.filter((item: any) => 
      nonPreInstalledIds?.includes(item?.id)
    ))
  }

    return (
        <>
            <DataTableWrap theme={theme} className="table-pagination table-dialog">
                <Box theme={theme} className="scroll">
                    <Box theme={theme} className="scroll-hide sh-fixed-cell spr-border" />
                </Box>
                <DataTable
                    theme={theme}
                    variant="basic"
                    theadChildren={
                        <>
                            <TableRow>
                                <TableCell className="checkbox-cell fixed-cell">
                                    <Checkbox
                                      label=""
                                      theme={theme}
                                      onChange={handleSelectAll}
                                      formControldisabled={allPreInstalled}
                                      checkBoxDisabled={allPreInstalled}
                                      checkBoxChecked={selectedAllOptions}
                                    />
                                </TableCell>
                                <TableCell className="img-cell">
                                    <Icon name="ImageIcon" />
                                </TableCell>
                                <TableCell id="productName">Product Name</TableCell>
                                <TableCell id="installationMode">Installation Mode</TableCell>
                                <TableCell id="installationType">Installation Type</TableCell>
                                <TableCell id="price">Price</TableCell>
                                <TableCell id="category">Category</TableCell>
                                <TableCell id="supplier">Supplier</TableCell>
                                <TableCell id="partNumber">Part No.</TableCell>
                                <TableCell id="description">Description</TableCell>
                                <TableCell className="action-cell fixed-cell"></TableCell>
                            </TableRow>
                            <TableRow className="filters-row">
                                <TableCell className="checkbox-cell"></TableCell>
                                <TableCell className="img-cell"></TableCell>
                                <TableCell>
                                    <Box theme={theme} className="table-filter">
                                        <Input
                                            theme={theme}
                                            fullWidth={true}
                                            placeholder={'Search...'}
                                            type="text"
                                            startAdornment={undefined}
                                            endAdornment={undefined}
                                            id={'productName'}
                                            onChange={(value: string) => {
                                                setFilters({ ...filters, productNameFilter: value })
                                            }}
                                            onBlur={() => {
                                                handleFilter()
                                            }}
                                            value={filters?.productNameFilter?.trimStart()}
                                        />
                                        <Button
                                            theme={theme}
                                            defaultBtn
                                            id="productNameFilter"
                                            iconText={<Icon name="IconFilter" />}
                                        ></Button>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box theme={theme} className="table-filter">
                                        <Input
                                            theme={theme}
                                            fullWidth={true}
                                            placeholder={'Search...'}
                                            type="text"
                                            startAdornment={undefined}
                                            endAdornment={undefined}
                                            id={'installationMode'}
                                            onChange={(value: string) => {
                                                setFilters({ ...filters, installationModeFilter: value })
                                            }}
                                            onBlur={() => {
                                                handleFilter()
                                            }}
                                            value={filters?.installationModeFilter?.trimStart()}
                                        />
                                        <Button
                                            theme={theme}
                                            defaultBtn
                                            id="installationModeFilter"
                                            iconText={<Icon name="IconFilter" />}
                                        ></Button>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box theme={theme} className="table-filter">
                                        <Input
                                            theme={theme}
                                            fullWidth={true}
                                            placeholder={'Search...'}
                                            type="text"
                                            startAdornment={undefined}
                                            endAdornment={undefined}
                                            id={'installationType'}
                                            onChange={(value: string) => {
                                                setFilters({ ...filters, installationTypeFilter: value })
                                            }}
                                            onBlur={() => {
                                                handleFilter()
                                            }}
                                            value={filters?.installationTypeFilter?.trimStart()}
                                        />
                                        <Button
                                            theme={theme}
                                            defaultBtn
                                            id="installationTypeFilter"
                                            iconText={<Icon name="IconFilter" />}
                                        ></Button>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box theme={theme} className="table-filter">
                                        <Input
                                            theme={theme}
                                            fullWidth={true}
                                            placeholder={'Search...'}
                                            type="text"
                                            startAdornment={undefined}
                                            endAdornment={undefined}
                                            id={'price'}
                                            onChange={(value: string) => {
                                                setFilters({ ...filters, priceFilter: value })
                                            }}
                                            onBlur={() => {
                                                handleFilter()
                                            }}
                                            value={filters?.priceFilter?.trimStart()}
                                            masking
                                            maskDecimalScale={2}
                                            maskNumeric
                                        />
                                        <Button
                                            theme={theme}
                                            defaultBtn
                                            id="priceFilter"
                                            iconText={<Icon name="IconFilter" />}
                                        ></Button>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box theme={theme} className="table-filter">
                                        <Input
                                            theme={theme}
                                            fullWidth={true}
                                            placeholder={'Search...'}
                                            type="text"
                                            startAdornment={undefined}
                                            endAdornment={undefined}
                                            id={'category'}
                                            onChange={(value: string) => {
                                                setFilters({ ...filters, categoryFilter: value })
                                            }}
                                            onBlur={() => {
                                                handleFilter()
                                            }}
                                            value={filters?.categoryFilter?.trimStart()}
                                        />
                                        <Button
                                            theme={theme}
                                            defaultBtn
                                            id="categoryFilter"
                                            iconText={<Icon name="IconFilter" />}
                                        ></Button>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box theme={theme} className="table-filter">
                                        <Input
                                            theme={theme}
                                            fullWidth={true}
                                            placeholder={'Search...'}
                                            type="text"
                                            startAdornment={undefined}
                                            endAdornment={undefined}
                                            id={'supplier'}
                                            onChange={(value: string) => {
                                                setFilters({ ...filters, supplierFilter: value })
                                            }}
                                            onBlur={() => {
                                                handleFilter()
                                            }}
                                            value={filters?.supplierFilter?.trimStart()}
                                        />
                                        <Button
                                            theme={theme}
                                            defaultBtn
                                            id="supplierFilter"
                                            iconText={<Icon name="IconFilter" />}
                                        ></Button>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box theme={theme} className="table-filter">
                                        <Input
                                            theme={theme}
                                            fullWidth={true}
                                            placeholder={'Search...'}
                                            type="text"
                                            startAdornment={undefined}
                                            endAdornment={undefined}
                                            id={'partNumber'}
                                            onChange={(value: string) => {
                                                setFilters({ ...filters, partNumberFilter: value })
                                            }}
                                            onBlur={() => {
                                                handleFilter()
                                            }}
                                            value={filters?.partNumberFilter?.trimStart()}
                                        />
                                        <Button
                                            theme={theme}
                                            defaultBtn
                                            id="partNumberFilter"
                                            iconText={<Icon name="IconFilter" />}
                                        ></Button>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box theme={theme} className="table-filter">
                                        <Input
                                            theme={theme}
                                            fullWidth={true}
                                            placeholder={'Search...'}
                                            type="text"
                                            startAdornment={undefined}
                                            endAdornment={undefined}
                                            id={'description'}
                                            onChange={(value: string) => {
                                                setFilters({ ...filters, descriptionFilter: value })
                                            }}
                                            onBlur={() => {
                                                handleFilter()
                                            }}
                                            value={filters?.descriptionFilter?.trimStart()}
                                        />
                                        <Button
                                            theme={theme}
                                            defaultBtn
                                            id="descriptionFilter"
                                            iconText={<Icon name="IconFilter" />}
                                        ></Button>
                                    </Box>
                                </TableCell>
                                <TableCell className="action-cell fixed-cell"></TableCell>
                            </TableRow>
                        </>
                    }
                    tbodyChildren={
                        <>
                            {optionsData?.result
                                ?.filter((data: any) => (!filters?.installationTypeFilter || ((filters?.installationTypeFilter && data?.installation_mode !== InstallationMode.OPTIONAL))))
                                ?.map((data: any, index: number) => (
                                <TableRow className="child-tr" key={index}>
                                    <TableCell className="checkbox-cell fixed-cell">
                                        <Checkbox
                                            label=""
                                            theme={theme}
                                            checkBoxChecked={isAdded(data?.id) || data?.installation_mode === InstallationMode.PRE_INSTALLED}
                                            onChange={() => handleCheckboxChange(data)}
                                            formControldisabled={data?.installation_mode === InstallationMode.PRE_INSTALLED}
                                            checkBoxDisabled={data?.installation_mode === InstallationMode.PRE_INSTALLED}
                                        />
                                    </TableCell>
                                    <TableCell className="img-cell">
                                        <img
                                            className="prd-img"
                                            src={data?.image?.[0]?.location ?? require('../../../src/public/assets/images/no-img.svg')}
                                            alt="Car"
                                        />
                                    </TableCell>
                                    <TableCell>{data?.product_name}</TableCell>
                                    <TableCell>{data?.installation_mode}</TableCell>
                                    <TableCell>{data?.installation_mode === InstallationMode.OPTIONAL ? '-' : data?.is_price_inclusive ? 'Inclusive' : 'Exclusive'}</TableCell>
                                    <TableCell className="editable-cell">
                                        <Box theme={theme} className="cell-content" justifyContent={'flex-end'}>
                                            {editingPrice[index] ? (
                                                <>
                                                    <Input
                                                        theme={theme}
                                                        startAdornment={defaultCurrency}
                                                        type="text"
                                                        value={priceDraft[index] || addedItemPrices[data?.id] || data?.price}
                                                        onChange={(e) => setPriceDraftValue(index, e)}
                                                        masking
                                                        maskDecimalScale={2}
                                                        maskNumeric
                                                    />
                                                    <Button
                                                        iconText={<Icon name="TickIcon" />}
                                                        onClick={() => {
                                                            handlePriceChange(index, priceDraft[index] || addedItemPrices[data?.id] || data?.price)
                                                            handleEditPrice(index)
                                                        }}
                                                    />
                                                    <Button
                                                        iconText={<Icon name="CloseBlack" />}
                                                        onClick={() => handleCancelEdit(index)}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <span>{defaultCurrency}{parseFloat(addedItemPrices[data?.id] ?? data?.price ?? 0)?.toLocaleString(undefined, {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    })}
                                                    </span>
                                                    {!(data?.installation_mode === InstallationMode.PRE_INSTALLED && data?.is_price_inclusive) ? (
                                                        <Button
                                                            iconText={<Icon name="EditIcon" />}
                                                            onClick={() => handleEditPrice(index)}
                                                        />
                                                    ) : null}
                                                </>
                                            )}
                                        </Box>
                                    </TableCell>
                                    <TableCell>{optionCategories?.find((x: any) => x?.id == data?.category_id)?.description ?? "-"}</TableCell>
                                    <TableCell>{data?.supplier}</TableCell>
                                    <TableCell>{data?.part_no ?? '-'}</TableCell>
                                    <TableCell>{data?.description}</TableCell>
                                    <TableCell className="action-cell fixed-cell">
                                        <Menu
                                            theme={theme}
                                            disablePortal
                                            options={[
                                                {
                                                    optionText: 'View Details',
                                                    optionValue: 'view_details'
                                                }
                                            ]}
                                            handleOptionClick={(_event, _key, value) => {
                                                switch (value) {
                                                    case 'view_details':
                                                        handleProductDetailsView(data)
                                                        break
                                                    default:
                                                        break
                                                }
                                            }}
                                            render={(onMenuSelection: any) => (
                                                <Button
                                                    theme={theme}
                                                    defaultBtn
                                                    iconText={<Icon name="MoreIcon" />}
                                                    onClick={onMenuSelection}
                                                />
                                            )}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left'
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right'
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </>
                    }
                />
                {addonAccessories && (
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 20]}
                        component="div"
                        id="pagination"
                        count={addonAccessories?.total_results ?? -1}
                        page={addonAccessories?.page_number}
                        rowsPerPage={addonAccessories?.page_size}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </DataTableWrap>
            <AddOnsSupplier details={cardDetails && cardDetails} open={openProductDialog} onBackBtn={handleCloseProductDetails} />
      </>
    )
}

export default OptionsTable
