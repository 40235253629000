import React, { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { ConfirmationDialog, DetailCard } from 'components'
import { Box, Button, Dialog, Grid, Icon, Menu, Typography } from '@ntpkunity/controls'
import AddNewPayment from './payment-details-popup'
import { useForm } from 'react-hook-form'
import {
  useGetPaymentDetails,
  useSavePaymentDetail,
  useUpdatePaymentDetail
} from '@apis/payment.service'
import { IOrderPaymentDetail } from '@models'
import { DialogMessages, QueryKeys } from '@helpers/enums'
import { useStoreContext } from '@store/storeContext'
import { useQueryClient } from 'react-query'
import { FundingType, PaymentMode } from '@helpers/enums/payment-details.enum'

const PaymentDetailsNew: FC<{
  order_detail: any
  setPaymentIndicator?: any
  viewMode?: boolean
}> = ({ order_detail, setPaymentIndicator, viewMode }) => {
  const theme = useTheme()
  const form: {
    handleSubmit: any
    reset: any
    getValues: any
    setValue: any
    setError: any
    formState: { isDirty: any }
    watch: any
    trigger: any
    clearErrors: any
  } = useForm<IOrderPaymentDetail>()
  const { states, actions } = useStoreContext()
  const queryClient = useQueryClient()
  const [defaultCurrency, setDefaultCurrency] = useState('')
  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])
  const [open, setOpen] = React.useState(false)
  const [payments, setPayments] = React.useState(true)
  const { mutate: getPaymentDetails, data: _documentPackage } = useGetPaymentDetails()
  const { mutate: createPaymentDetails, isLoading: paymentLoading } = useSavePaymentDetail()
  const { mutate: deletePaymentDetails } = useUpdatePaymentDetail()
  const [paymentDetailObject, setPaymentDetailObject] = useState<any>()
  const [totalAmount, setTotalAmount] = useState<any>()
  const [customAmountError, setCustomAmountError] = useState(false)
  const [confirmationPopupState, setConfirmationPopupState] = useState<boolean>(false)
  const [actionData, setActionData] = useState<IOrderPaymentDetail>()
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    form.clearErrors()
    setOpen(false)
  }
  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )

    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0].symbol : ''
    )
  }, [currencies])

  const savePayment = () => {
    if (customAmountError) {
      form.clearErrors('amount')
    }
    const currentAmount = parseFloat(form.getValues('amount'))
    const paymentDetailId = form.getValues('order_payment_detail_id')
    let totalPaid = parseFloat((totalAmount + currentAmount).toFixed(2))
    if (paymentDetailId) {
      const updatedPaymentDetails = paymentDetailObject.map((payment: any) =>
        payment.order_payment_detail_id === paymentDetailId
          ? { ...payment, order_amount: currentAmount }
          : payment
      )

      // Calculate the total amount
      totalPaid = updatedPaymentDetails.reduce(
        (sum: any, payment: any) => sum + payment.order_amount,
        0
      )
    }
    if (order_detail?.due_at_signing - totalPaid >= 0) {
      const data = {
        ...form.getValues(),
        reference_number: order_detail?.reference_number,
        email: order_detail?.customer_info?.email,
        token: 'abc'
      }
      createPaymentDetails(data, {
        onSuccess(_response: any) {
          setOpen(false)
          setPayments(true)
          form.reset()
        }
      })
    } else {
      form.setError('amount', { message: 'Amount paid cannot be greater than outstanding amount' })
      setCustomAmountError(true)
    }
  }
  const handleEdit = (value: any) => {
    form.setValue('amount', value?.order_amount)
    form.setValue('payment_method_type', value?.funding)
    form.setValue('cheque_number', value?.cheque_number)
    form.setValue('payment_date', value?.payment_date)
    form.setValue('order_payment_detail_id', value?.order_payment_detail_id)
    setOpen(true)
  }
  const onDeleteConfirm = () => {
    deletePaymentDetails(
      { order_payment_detail_id: actionData?.order_payment_detail_id },
      {
        onSuccess(_res: any) {
          setPayments(true)
          setConfirmationPopupState(false)
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: 'error'
          })
        }
      }
    )
  }
  useEffect(() => {
    if (order_detail?.reference_number && payments) {
      getPaymentDetails(
        { reference_number: order_detail?.reference_number },
        {
          onSuccess(response: any) {
            setPaymentDetailObject(response?.transaction_history)
            setTotalAmount(parseFloat((response?.total_paid_amount ?? 0).toFixed(2)))
            if (response?.total_paid_amount === order_detail?.due_at_signing) {
              setPaymentIndicator(true)
            } else {
              setPaymentIndicator(false)
            }
            setPayments(false)
          }
        }
      )
    }
  }, [order_detail?.reference_number, payments])

  return (
    <>
      <DetailCard className="detail-card" mb={2}>
        <Box className="card-body" theme={theme}>
          <Box className="row" mb={2} theme={theme}>
            <Typography component="h4" variant="h4" theme={theme} children="Payment Details" />
            {(totalAmount < order_detail?.due_at_signing || totalAmount === 0) && !viewMode && (
              <Button
                defaultBtn
                iconText={<Icon name="AddIcon" />}
                onClick={() => {
                  form.reset()
                  handleOpen()
                }}
              />
            )}
          </Box>
          <Box className="card-body" theme={theme}>
            <Box className="secondary-wrap" theme={theme}>
              <Box className="row" theme={theme}>
                <Box className="center title" theme={theme}>
                  <Grid
                    theme={theme}
                    className="container"
                    container
                    rowSpacing={0}
                    columnSpacing={1}
                  >
                    <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={6}>
                      <Typography
                        theme={theme}
                        variant="body2"
                        component="h3"
                        className="label"
                        children="Status"
                      />
                    </Grid>
                    <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={6}>
                      <Typography
                        theme={theme}
                        variant="body2"
                        component="span"
                        children={
                          totalAmount === order_detail?.due_at_signing
                            ? 'Paid'
                            : totalAmount < order_detail?.due_at_signing && totalAmount !== 0
                            ? 'Partially Paid'
                            : 'Unpaid'
                        }
                      />
                    </Grid>
                    {/* **************** */}
                    <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={6}>
                      <Typography
                        theme={theme}
                        variant="body2"
                        component="h3"
                        className="label"
                        children="Outstanding Amount"
                      />
                    </Grid>
                    <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={6}>
                      <Typography
                        theme={theme}
                        variant="body2"
                        component="span"
                        children={
                          defaultCurrency +
                          (order_detail?.due_at_signing - totalAmount)?.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              {paymentDetailObject?.map((item: any, index: number) => (
                <div key={index}>
                  <Box className="line" theme={theme}>
                    <hr />
                  </Box>
                  <Box className="row" theme={theme}>
                    <Box className="center title" theme={theme}>
                      <Grid
                        theme={theme}
                        className="container"
                        container
                        rowSpacing={0}
                        columnSpacing={1}
                      >
                        <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={6}>
                          <Typography
                            theme={theme}
                            variant="body2"
                            component="h3"
                            className="label"
                            children="Amount Paid"
                          />
                        </Grid>
                        <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={6}>
                          <Typography
                            theme={theme}
                            variant="body2"
                            component="span"
                            children={
                              <>
                                {defaultCurrency}
                                {item?.order_amount.toLocaleString(undefined, {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2
                                })}
                              </>
                            }
                          />
                        </Grid>
                        {/* **************** */}
                        <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={6}>
                          <Typography
                            theme={theme}
                            variant="body2"
                            component="h3"
                            className="label"
                            children="Payment Mode"
                          />
                        </Grid>
                        <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={6}>
                          <Typography
                            theme={theme}
                            variant="body2"
                            component="span"
                            children={
                              item?.funding == FundingType.CARD ||
                              item?.funding == FundingType.CREDIT
                                ? PaymentMode.CREDIT_CARD
                                : item?.funding == FundingType.ACH_DEBIT ||
                                  item?.funding == FundingType.CHECKING
                                ? PaymentMode.ACH
                                : `${PaymentMode.CHEQUE} - ${item?.cheque_number}`
                            }
                          />
                        </Grid>
                        {/* **************** */}
                        <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={6}>
                          <Typography
                            theme={theme}
                            variant="body2"
                            component="h3"
                            className="label"
                            children="Payment Date"
                          />
                        </Grid>
                        <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={6}>
                          <Typography
                            theme={theme}
                            variant="body2"
                            component="span"
                            children={new Date(item?.payment_date).toLocaleDateString()}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    {!viewMode && item?.order_payment_detail_id && (
                      <Box className="center actions" theme={theme}>
                        <Menu
                          theme={theme}
                          options={[
                            {
                              optionText: (
                                <>
                                  <Icon className="menu-icon" name="EditIcon" /> Edit
                                </>
                              ),
                              optionkey: 'edit',
                              optionValue: item
                            },
                            {
                              optionText: (
                                <>
                                  <Icon className="menu-icon" name="DeleteIcon" /> Delete
                                </>
                              ),
                              optionkey: 'delete',
                              optionValue: item
                            }
                          ]}
                          handleOptionClick={(_event, key, value) => {
                            switch (key) {
                              case 'delete':
                                setActionData(value)
                                setConfirmationPopupState(true)
                                break
                              case 'edit':
                                handleEdit(value)
                                break
                            }
                          }}
                          render={(handleOptionClick) => (
                            <Button
                              defaultBtn
                              iconText={<Icon name="MoreIcon" />}
                              onClick={handleOptionClick}
                            ></Button>
                          )}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </div>
              ))}
            </Box>
          </Box>
        </Box>
      </DetailCard>

      <Dialog
        theme={theme}
        disablePortal
        size="sm"
        title={
          form.watch('order_payment_detail_id') ? 'Edit Payment Details' : 'Add Payment Details'
        }
        open={open}
        onCloseDialog={handleClose}
        customFooter={
          <>
            <Button theme={theme} secondary text="Cancel" onClick={handleClose} />
            <Button
              theme={theme}
              fullWidth
              disabled={!form.formState.isDirty || paymentLoading}
              primary
              text="Save Details"
              onClick={form.handleSubmit(savePayment)}
            />
          </>
        }
        children={<AddNewPayment paymentForm={form} defaultCurrency={defaultCurrency} />}
      />
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.paymentConfirmationTitle}
          confirmationText={'Once confirmed, you cannot undo this action.'}
          primaryBtnText={'Confirm'}
          secondaryBtnText={'Cancel'}
          icon={<Icon className='alert-icon' name="ErrorTriangle" />}
          onCancel={function (_action: any): void {
            setConfirmationPopupState(false)
          }}
          hideCancelButton={false}
        />
      )}
    </>
  )
}

export default PaymentDetailsNew
