export enum CustomerTypes {
  Applicant = 'applicant',
  CoApplicant = 'co-applicant'
}
export enum ExemptionLevel {
  State = 'State',
  Federal = 'Federal'
}

export enum MaritalStatus {
  Married = 'Married',
  Divorced = 'Divorced',
  Single = 'Single',
  Unmarried = 'Unmarried'
}
