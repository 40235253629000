export enum FeeHandlingType {
  UPFRONT = 'upfront',
  FINANCE = 'financed'
}
export enum FeeEvent {
  INCEPTION = 'Inception',
  ENDOFTERM = 'End of Term'
}
export enum FinanceType {
  LEASE = 'lease',
  FINANCE = 'finance'
}
