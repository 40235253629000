import { FC, useState } from 'react'
import { useTheme, TablePagination } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Box, Button, DataTableWrap } from 'components'
import { unityTheme, Icon, Input, DataTable } from '@ntpkunity/controls'
import { IWQOptions, IWorkOrder } from '@models'
import { useNavigate } from 'react-router'
import { formatedDate, dateFormat } from 'helpers/methods'

const CompletedOrderTable: FC<IWQOptions> = ({
  pageNumber,
  pageSize,
  setPageNumber,
  setPageSize,
  setColumnFilters,
  data,
  statusFilter,
  showDealerColumn = false
}) => {
  const theme = useTheme()
  const [filters, setFilters] = useState<any>()
  let navigate = useNavigate()
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage)
    setColumnFilters(setQueryString(newPage, pageSize))
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value)
    setPageNumber(0)
    setColumnFilters(setQueryString(0, +event.target.value))
  }
  const setQueryString = (pgNo: number, pgSize: number): any => {
    let query_string: string = `page_number=${pgNo}&page_size=${pgSize}`.concat(statusFilter ?? '')
    if (filters?.orderIdFilter?.trimStart() != null && filters?.orderIdFilter?.trimStart() != '') {
      query_string = query_string.concat(`&reference_number=${filters?.orderIdFilter}`)
    }
    if (filters?.dealerFilter?.trimStart() != null && filters?.dealerFilter?.trimStart() != '') {
      query_string = query_string.concat(`&dealer_name=${filters?.dealerFilter}`)
    }
    if (filters?.assetFilter?.trimStart() != null && filters?.assetFilter?.trimStart() != '') {
      query_string = query_string.concat(`&make_model_trim=${filters?.assetFilter}`)
    }
    if (filters?.creationDate?.trimStart() != null && filters?.creationDate?.trimStart() != '') {
      query_string = query_string.concat(`&updated_at=${filters?.creationDate}`)
    }
    if (filters?.vinFilter?.trimStart() != null && filters?.vinFilter?.trimStart() != '') {
      query_string = query_string.concat(`&vin=${filters?.vinFilter}`)
    }
    if (
      filters?.stockNumberFilter?.trimStart() != null &&
      filters?.stockNumberFilter?.trimStart() != ''
    ) {
      query_string = query_string.concat(`&stock=${filters?.stockNumberFilter}`)
    }
    if (
      filters?.customerNameFilter?.trimStart() != null &&
      filters?.customerNameFilter?.trimStart() != ''
    ) {
      query_string = query_string.concat(`&customer_name=${filters?.customerNameFilter}`)
    }
    if (
      filters?.vehicleCondition?.trimStart() != null &&
      filters?.vehicleCondition?.trimStart() != ''
    ) {
      query_string = query_string.concat(`&vehicle_condition=${filters?.vehicleCondition}`)
    }
    if (
      filters?.programNameFilter?.trimStart() != null &&
      filters?.programNameFilter?.trimStart() != ''
    ) {
      query_string = query_string.concat(`&program=${filters?.programNameFilter}`)
    }
    if (filters?.selling_price?.trimStart() != null && filters?.selling_price?.trimStart() != '') {
      query_string = query_string.concat(`&selling_price=${filters?.selling_price}`)
    }
    if (
      filters?.creationDateFilter?.trimStart() != null &&
      filters?.creationDateFilter?.trimStart() != ''
    ) {
      query_string = query_string.concat(`&created_at=${filters?.creationDateFilter}`)
    }
    return query_string
  }
  const handleFilter = () => {
    setPageNumber(0)
    setColumnFilters(setQueryString(0, pageSize))
  }

  return (
    <>
      <DataTableWrap className="table-pagination">
        <Box className="scroll">
          <Box className="scroll-hide sh-fixed-cell spr-border" theme={unityTheme} />
        </Box>
        <DataTable
          theme={unityTheme}
          variant="basic"
          theadChildren={
            <>
              <TableRow>
                <TableCell className="img-cell">
                  <Icon name="ImageIcon" />
                </TableCell>
                <TableCell id="descriptionHead">Deal ID</TableCell>
                {showDealerColumn && (<TableCell id="dealer">Dealer</TableCell>)}
                <TableCell id="priceHead">Year / Make / Model / Trim</TableCell>
                <TableCell id="completionData">Completion Date</TableCell>
                <TableCell id="installationModeHead">VIN</TableCell>
                <TableCell id="supplierHead">Stock Number</TableCell>
                <TableCell id="supplierHead">Customer Name</TableCell>
                <TableCell id="SellingPrice">Selling Price</TableCell>
                <TableCell id="supplierHead">Vehicle Condition</TableCell>
                <TableCell id="supplierHead">Program Name</TableCell>
                <TableCell id="supplierHead">Deal Creation Date</TableCell>
              </TableRow>
              <TableRow className="filters-row">
                <TableCell className="img-cell"></TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'orderIdFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, orderIdFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.orderIdFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="descriptionFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                {showDealerColumn && (<TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'dealerFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, dealerFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.dealerFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="dealerFilterBtn"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>)}
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'assetFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, assetFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.assetFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="priceFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'creationDateFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, creationDate: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.creationDate?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="priceFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'vinFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, vinFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.vinFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="installationModeFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'stockNumberFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, stockNumberFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.stockNumberFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="supplierFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'customerNameFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, customerNameFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.customerNameFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="supplierFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'SellingPriceFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, selling_price: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.selling_price?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="supplierFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'vehicleConditionFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, vehicleCondition: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.vehicleCondition?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="supplierFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'programNameFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, programNameFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.programNameFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="categoryFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'creationDateFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, creationDateFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.creationDateFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="supplierFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {data?.result?.map((workOrder: IWorkOrder, index: number) => (
                <TableRow
                  key={index}
                  className="child-tr"
                  onDoubleClick={(_e: any) => {
                    navigate(`/dms/deal-summary/${workOrder?.reference_number}`)
                  }}
                >
                  <TableCell className="img-cell">
                    <img
                      className="prd-img"
                      src={workOrder?.photo_urls?.[0]?.location.toString()}
                      alt="Car"
                    />
                  </TableCell>
                  <TableCell id={'order_id' + workOrder?.reference_number}>
                    {workOrder?.reference_number}
                  </TableCell>
                  {showDealerColumn && (<TableCell id={'dealer' + workOrder?.dealer_name}>
                    {workOrder?.dealer_name}
                  </TableCell>)}
                  <TableCell
                    id={
                      'assets' +
                      workOrder?.year +
                      ' ' +
                      workOrder?.make +
                      ' ' +
                      workOrder?.model +
                      ' ' +
                      workOrder?.trim_description
                    }
                  >
                    {workOrder?.year +
                      ' ' +
                      workOrder?.make +
                      ' ' +
                      workOrder?.model +
                      ' ' +
                      workOrder?.trim_description}
                  </TableCell>
                  <TableCell id={'completionDate' + workOrder?.order_updated_at}>
                    {workOrder?.order_updated_at &&
                      formatedDate(
                        new Date(workOrder?.order_updated_at).toLocaleDateString('en-US'),
                        dateFormat
                      )}
                  </TableCell>
                  <TableCell id={'vin' + workOrder?.vin}>{workOrder?.vin}</TableCell>
                  <TableCell>{workOrder?.stock_number}</TableCell>
                  <TableCell id={'name' + workOrder?.first_name}>
                    {`${workOrder?.first_name || ''} ${workOrder?.middle_name || ''} ${
                      workOrder?.last_name || ''
                    }`}
                  </TableCell>
                  <TableCell id={'selling_price' + workOrder?.selling_price}>
                    {workOrder.selling_price?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}
                  </TableCell>
                  <TableCell id={'vehicleCondition' + index}>
                    {workOrder?.vehicle_condition}
                  </TableCell>
                  <TableCell id={'program_name' + workOrder?.program_name}>
                    {workOrder.program_name}
                  </TableCell>
                  <TableCell id={'creation_date' + workOrder?.created_at}>
                    {workOrder?.created_at &&
                      formatedDate(new Date(workOrder?.created_at), dateFormat)}
                  </TableCell>
                </TableRow>
              ))}
            </>
          }
        />

        {data && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            count={data?.total_results ?? -1}
            rowsPerPage={pageSize}
            page={pageNumber}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </DataTableWrap>
    </>
  )
}

export default CompletedOrderTable
