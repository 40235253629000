import { useGetVehicleOptions } from '@apis/dealer-configurations.service'
import { InstallationMode } from '@helpers/enums'
import { useTheme } from '@mui/material'
import { Box } from '@ntpkunity/controls'
import { useStoreContext } from '@store/storeContext'
import { DialogTotal } from 'components'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { IOption } from './addon-interface'
import OptionsTable from '@app/add-ons/options-table'

const AddOnOption: FC<{
  defaultOptions?: IOption[]
  defaultCurrency?: any
  setSelectedObjectsArray: any
  modelName: string
  vin: string
}> = ({ defaultOptions, defaultCurrency, setSelectedObjectsArray, modelName, vin }: any) => {
  const theme = useTheme()
  const { states } = useStoreContext()
  const [totalSumArray, setTotalSumArray] = useState<any>([])
  const [addedItems, setAddedItems] = useState<any>([])
  const [addedItemPrices, setAddedItemPrices] = useState<{ [key: number]: number }>({})
  const [popupAddedItems, setPopupAddedItems] = useState<any>([])
  const { data } = useGetVehicleOptions(vin, states?.dealerAndLenderInfo?.dealer_code, modelName)
  const dealerOptions = Array.isArray(data) ? data : []
  useEffect(() => {
    if (defaultOptions) {
      const data = defaultOptions?.filter(
        (f: any) => !addedItems.find((e: any) => e === (f.option_id ?? f.id))
      )
      const prices = data.reduce((acc: any, item: any) => {
        acc[item.id] = item.price
        return acc
      }, {})
      setAddedItems([...data.map((e: any) => e.option_id ?? e.id)])
      setPopupAddedItems([...data.map((e: any) => e.option_id ?? e.id)])
      setAddedItemPrices(prices)
      setTotalSumArray([...defaultOptions])
      setSelectedObjectsArray([...defaultOptions])
    }
  }, [])

  const preInstalledOptions = (dealerOptions || [])?.filter((item: any) => {
    return item.category_name
      ? item.installation_mode === InstallationMode.PRE_INSTALLED && item.is_active === true
      : null
  })

  const callBack = (type: string, data: any) => {
    setTotalSumArray((prev: any) => {
      let updatedArray

      if (type === 'add') {
        updatedArray = [...prev, data]
      } else if (type === 'remove') {
        updatedArray = prev?.filter((item: any) => (item.option_id ?? item.id) !== (data.option_id ?? data.id))
      } else if (type === 'update') {
        updatedArray = prev?.map((item: any) => {
          if ((item.option_id ?? item.id) === (data.option_id ?? data.id)) {
            return {
              ...item,
              price: data.price
            }
          }
          return item
        })
      } else {
        updatedArray = prev
      }

      setSelectedObjectsArray(updatedArray)
      return updatedArray
    })
  }

  const callBackBulk = (type: string, data: any) => {
    setTotalSumArray((prev: any) => {
      let updatedArray

      if (type === 'add') {
        updatedArray = [...prev, ...data]
      } else if (type === 'remove') {
        updatedArray = prev?.filter((item: any) => 
          !data.some((dataItem: any) => (dataItem.option_id ?? dataItem.id) === (item.option_id ?? item.id))
        )
      } else {
        updatedArray = prev
      }

      setSelectedObjectsArray(updatedArray)
      return updatedArray
    })
  }

  const calculatedTotalSum = useMemo(() => {
    return totalSumArray.reduce((lastSum: number, obj: any) => {
      if (preInstalledOptions?.length > 0) {
        const matchingOption = preInstalledOptions.find(
          (preInstalledOption: any) =>
            preInstalledOption.id === obj.id || preInstalledOption.id === obj.option_id
        )
        if (matchingOption) {
          obj.applied_price = obj.is_price_inclusive
            ? 0
            : addedItemPrices?.[matchingOption?.id] || matchingOption.applied_price || matchingOption.price
        }
      }
      return lastSum + (obj.applied_price ?? obj.price ?? 0)
    }, 0)
  }, [totalSumArray, preInstalledOptions])

  return (
    <>
      <OptionsTable 
        callBack={callBack}
        callBackBulk={callBackBulk}
        defaultCurrency={defaultCurrency} 
        addedItems={popupAddedItems}
        setAddedItems={setPopupAddedItems}
        addedItemPrices={addedItemPrices}
        setAddedItemPrices={setAddedItemPrices}
      />
      <DialogTotal
        theme={theme}
        className="dialog-total"
        display={'flex'}
        gap={2}
        justifyContent={'space-between'}
      >
        <Box theme={theme}>Total</Box>
        <Box theme={theme} className="primary" textAlign="right">
          {defaultCurrency}
          {parseFloat(calculatedTotalSum ?? 0)?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          })}
        </Box>
      </DialogTotal>
    </>
  )
}

export default React.memo(AddOnOption)
