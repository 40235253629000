import { styled } from "@mui/material"
import { Box, IBoxProps } from "@ntpkunity/controls"

export const RadioGroupWrap = styled(
    Box,
    {}
  )<Partial<IBoxProps>>(() => ({
    '&.radio-group-wrap': {
    '.MuiFormGroup-root': {
      '.MuiFormControlLabel-root': {
        marginRight: '24px !important',
  
        '&:last-child': {
          marginRight: 0
        }
      }
    }
}
  }))
