import React from 'react'
import { Box, Label, Typography } from '@ntpkunity/controls'
import { useTheme } from '@mui/material'

interface ScoreItem {
  title: string
  score: string
  scoreStatus: string
  description: string
}

interface FieldValidation {
  score: string
  fields: { fieldName: string; fieldStatus: string }[]
  scoreStatus: string
}

interface decisionList {
  description: string
  code: string
  status: string
}

interface HeaderSectionProps {
  scoreList: ScoreItem[]
  fieldValidations?: FieldValidation[]
  decisionList?: decisionList[]
}

const HeaderSection: React.FC<HeaderSectionProps> = ({
  scoreList,
  decisionList,
  fieldValidations
}) => {
  const theme = useTheme()

  function groupByValidationScore(data: any) {
    const groupedData = {} as any

    data?.forEach((item: any) => {
      Object.entries(item as any).forEach(([key, score]) => {
        if (groupedData[score as any]) {
          groupedData[score as any].push(key)
        } else {
          groupedData[score as any] = [key]
        }
      })
    })

    return groupedData
  }

  const fieldValidationsByScore = groupByValidationScore(fieldValidations) as any

  return (
    <Box theme={theme} className="content-header">
      {scoreList &&
        scoreList?.length > 0 &&
        scoreList?.map((scoreItem, index) => (
          <Box key={index} theme={theme} marginBottom={1}>
            <Typography theme={theme} component="p" variant="caption" children={scoreItem.title} />
            <Typography theme={theme} component="p" variant="h4" children={scoreItem.score} />
            <Typography
              theme={theme}
              component="p"
              variant="body2"
              children={scoreItem.description}
            />
          </Box>
        ))}
      {decisionList &&
        decisionList?.length > 0 &&
        decisionList?.map((decisionItem, index) => (
          <Box key={index} theme={theme} mb={1}>
            <Box theme={theme} mb={1}>
              <Typography
                theme={theme}
                component="p"
                variant="caption"
                children={decisionItem?.description}
                className="title"
              />
            </Box>
            <Label
              theme={theme}
              state={
                decisionItem.status === 'primary'
                  ? 'primary'
                  : decisionItem.status === 'info'
                  ? 'info'
                  : decisionItem.status === 'error'
                  ? 'error'
                  : decisionItem.status === 'warning'
                  ? 'warning'
                  : decisionItem.status === 'success'
                  ? 'success'
                  : undefined
              }
              value={decisionItem.code}
            />
          </Box>
        ))}

      {fieldValidations && fieldValidations?.length > 0 ? (
        <Box theme={theme} marginBottom={1}>
          {Object.keys(fieldValidationsByScore)?.map((score) => (
            <div key={score}>
              <Typography theme={theme} component="p" variant="caption">
                FIELDS VALIDATIONS
              </Typography>
              <Typography theme={theme} component="p" variant="h4">
                {score === "null" || score === "undefined" || !score ? "-" : score}
              </Typography>
              <Typography theme={theme} component="p" variant="body2">
                <Box theme={theme}>
                  {fieldValidationsByScore?.[score]?.map((field: any, index: number) => (
                    <span key={index}>
                      {field}
                      {index < fieldValidationsByScore?.[score]?.length - 1 && ', '}
                    </span>
                  ))}
                </Box>
              </Typography>
            </div>
          ))}
        </Box>
      ) : null}
    </Box>
  )
}

export default HeaderSection
