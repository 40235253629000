import { QueryKeys } from '@helpers/enums'
import { useQuery, UseQueryResult } from 'react-query'
import {
  IFilterData,
  IInventoryModels,
  IInventoryTrims,
  IReferenceDataInventory
} from 'types/responses/inventoryFilterData'
import {
  getInventoryByFilters,
  getInventoryModels,
  getInventoryTrims,
  getReferenceDataInventory
} from '@apis/inventory-management.service'
import { getQueryParamsString } from '@ntpkunity/controls-common'

export const useGetInventoryByFilters = (
  dealerCode: string,
  companyId: number,
  params: string
): UseQueryResult<IFilterData> => {
  return useQuery<IFilterData>({
    queryKey: [QueryKeys.GET_INVENTORY_BY_FILTERS, params],
    queryFn: () => getInventoryByFilters(companyId, params),
    enabled: !!dealerCode && !!params
  })
}

export const useGetFilterReferenceData = (
  dealerCode: string,
  companyId: number
): UseQueryResult<IReferenceDataInventory> => {
  return useQuery<IReferenceDataInventory>({
    queryKey: [QueryKeys.GET_REFERENCE_DATA_INVENTORY, dealerCode],
    queryFn: () => getReferenceDataInventory(dealerCode, companyId),
    enabled: !!dealerCode
  })
}

export const useGetInventoryModels = (
  dealerCode: string,
  companyId: number,
  makes: string[]
): UseQueryResult<IInventoryModels> => {
  const params = getQueryParamsString({ dealer_code: dealerCode, makes })
  return useQuery<IInventoryModels>({
    queryKey: [QueryKeys.GET_INVENTORY_MODELS, makes],
    queryFn: () => getInventoryModels(companyId, params),
    enabled: makes?.length > 0 && !!dealerCode
  })
}

export const useGetInventoryTrims = (
  dealerCode: string,
  company_id: number,
  modelCodes: string[]
): UseQueryResult<IInventoryTrims> => {
  const params = getQueryParamsString({ dealer_code: dealerCode, model_codes: modelCodes })
  return useQuery<IInventoryTrims>({
    queryKey: [QueryKeys.GET_INVENTORY_TRIMS, modelCodes],
    queryFn: () => getInventoryTrims(company_id, params),
    enabled: modelCodes?.length > 0 && !!dealerCode
  })
}
