// @ts-nocheck

import { FeeHandlingType, InstallationMode } from '@helpers/enums'
import { DeskingState, FinanceTypes, VehiclesForComparisonTypes } from './desking-context-provider'
import { ICustomer, IOrderTradeIn, IProgramDetails } from '@models'
import { DealDeskingState } from '@store/ducks/desking'
import { findAddress } from '@helpers/methods'

export const createV2Key = (vin: string, comparison: string, pricingPayload: any) => {
  const {
    annual_usage,
    apr,
    dealer_id,
    lender_id,
    down_payment,
    fees,
    finance_type,
    fni_products,
    rv_value,
    options,
    terms,
    trade_ins,
    customer_address,
    per_unit_selling_price,
    tspk
  } = pricingPayload

  return [
    'PricingCalculation',
    per_unit_selling_price,
    finance_type,
    comparison,
    vin,
    down_payment,
    annual_usage,
    terms,
    apr,
    rv_value,
    dealer_id,
    lender_id,
    tspk,
    fees,
    fni_products,
    options,
    trade_ins,
    customer_address?.state
  ]
}

export const createCurrencyFormatter = (code: string) => {
  return (
    amount: number | string,
    minimumFractionDigits: number = 2,
    maximumFractionDigits: number = 2
  ): string => {
    const numericAmount = typeof amount === 'string' ? parseFloat(amount) : amount
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: code,
      minimumFractionDigits,
      maximumFractionDigits
    }).format(numericAmount ?? 0)
  }
}

export const formatNumber = (
  value: number | string,
  maximumFractionDigits = 2,
  minimumFractionDigits = 2
): string => {
  const numericValue = typeof value === 'string' ? parseFloat(value) : value

  return (numericValue ?? 0)?.toLocaleString('en-US', {
    minimumFractionDigits,
    maximumFractionDigits
  })
}

export const organizeByFinanceType = (data: any[]) => {
  const organizedData = Object.values(FinanceTypes).reduce((acc, type) => {
    acc[type] = []
    return acc
  }, {})

  data
    .filter((dta) => dta.is_active)
    .forEach((_dta: { applicable_finance_type: unknown[] }) => {
      Object.entries(FinanceTypes).forEach(([key, type]) => {
        if (_dta?.applicable_finance_type?.includes(type)) {
          organizedData[type].push(_dta)
        }
      })
    })

  return organizedData
}

export const calculateTotalOfPayments = (
  rentalAmount: number,
  terms: number,
  program: IProgramDetails,
  sellingPrice: number
) => {
  const { rv_balloon_applicable, rv_balloon_type, rv_chart } = program
  const rvBalloonApplicable = rv_balloon_applicable ?? false

  if (!rvBalloonApplicable) {
    return rentalAmount * terms
  }

  const value = ((rv_chart?.[0]?.rv_value ?? 0) * sellingPrice) / 100
  return rentalAmount * (rv_balloon_type === 'rv' ? terms : terms - 1) + value
}

export const createPricingRequestPayloadFromState = (
  state: DeskingState,
  globalState: DealDeskingState,
  rowIndex: number,
  colIndex: number,
  vin: string
) => {
  const singleVehicleComparison = state.vehiclesForComparison === VehiclesForComparisonTypes.SINGLE
  const isVehicleSelectedAndOrderSaved = state.isVehicleSelectedAndOrderSaved
  const order = state.order

  const { contract_term, apr, rv_value, annual_usage } =
    (isVehicleSelectedAndOrderSaved
      ? state.preview.quote_params
      : singleVehicleComparison
      ? state[VehiclesForComparisonTypes.SINGLE][state.finance_type].calculationsBoxes[rowIndex][0]
      : state[VehiclesForComparisonTypes.MULTIPLE][state.finance_type].find(
          (financeItem: any, idx: number) => financeItem.vin === vin || idx === colIndex
        )?.quote_params) ?? {}

  const downPayment = isVehicleSelectedAndOrderSaved
    ? state.preview.down_payments.value
    : singleVehicleComparison
    ? state[VehiclesForComparisonTypes.SINGLE][state.finance_type].calculationsBoxes[0][colIndex]
    : state[VehiclesForComparisonTypes.MULTIPLE][state.finance_type].find(
        (financeItem: any, idx: number) => financeItem.vin === vin || idx === colIndex
      )?.down_payments.value

  const calculation =
    (isVehicleSelectedAndOrderSaved
      ? state.preview.calculationsBoxes
      : singleVehicleComparison
      ? state[VehiclesForComparisonTypes.SINGLE][state.finance_type].calculationsBoxes[rowIndex][
          colIndex
        ]
      : state[VehiclesForComparisonTypes.MULTIPLE][state.finance_type].find(
          (financeItem: any, idx: number) => financeItem.vin === vin || idx === colIndex
        )?.calculationsBoxes) ?? {}

  const misc =
    (isVehicleSelectedAndOrderSaved
      ? state.preview.misc
      : singleVehicleComparison
      ? state[VehiclesForComparisonTypes.SINGLE][state.finance_type].misc[rowIndex - 1][
          colIndex - 1
        ]
      : state[VehiclesForComparisonTypes.MULTIPLE][state.finance_type].find(
          (financeItem: any, idx: number) => financeItem.vin === vin || idx === colIndex
        )?.misc) ?? {}

  const vehiclesData = state.vehiclesData.get(vin)
  const program = (vehiclesData?.program ?? {})?.[state.finance_type]?.find(
    (p) =>
      p?.contract_term === contract_term &&
      (state.finance_type === FinanceTypes.FINANCE || p?.mileage === annual_usage)
  )

  const fees = (vehiclesData?.fees ?? {})?.[state.finance_type] ?? []
  const fni = (vehiclesData?.fni ?? {})?.[state.finance_type?.toLowerCase()] ?? []
  const { customer, finance_type, tradeIn, insurance } = state
  const { dealerProfile, dealerPreferences } = globalState

  const customerGaragingAddress =
    customer?.customer_addresses?.length > 0
      ? findAddress(customer?.customer_addresses)
      : dealerProfile?.dealer_address

  const perUnitSellingPrice = isVehicleSelectedAndOrderSaved
    ? order?.order_asset?.unit_price
    : vehiclesData?.vehicle?.selling_price ?? vehiclesData?.vehicle?.internet_price

  const tradeInAmount =
    Object.keys(tradeIn ?? {}).length > 0 ? tradeIn?.trade_in_amount - tradeIn?.payoff_amount : 0

  const financedFeeSum = fees.reduce(
    (total: number, fee: any) =>
      fee?.fee_handling?.toLowerCase() === FeeHandlingType.FINANCE
        ? total + (fee.applied_price ?? fee.default_amount)
        : total,
    0
  )

  const fniProductsPrice = (fni ?? [])?.reduce(
    (total: number, currentItem: any) =>
      total + (currentItem?.price ?? currentItem?.applied_price ?? 0),
    0
  )

  const optionsPrice = (vehiclesData?.dealer_options ?? [])?.reduce(
    (total: number, currentItem: any) =>
      total +
      ((currentItem.installation_mode === InstallationMode.PRE_INSTALLED &&
        !currentItem.is_price_inclusive) ||
      currentItem.installation_mode !== InstallationMode.PRE_INSTALLED
        ? currentItem.applied_price ?? currentItem.price
        : 0),
    0
  )

  const netFinancedAmount = Math.abs(
    perUnitSellingPrice +
      financedFeeSum +
      fniProductsPrice +
      optionsPrice -
      (downPayment + tradeInAmount)
  )

  const creditRating =
    ((customer as ICustomer)?.credit_rating ?? '').length > 0
      ? (customer as ICustomer)?.credit_rating
      : globalState?.creditTerms?.[0]?.description

  const calculationRequestObject = {
    annual_usage: Number(annual_usage ?? 0),
    apr: Number(apr),
    asset_condition: 63,
    base_rate: isVehicleSelectedAndOrderSaved
      ? order.base_rate
      : program?.base_rate_chart?.base_rate,
    calculation_method: isVehicleSelectedAndOrderSaved
      ? order.calcuation_method
      : program?.calculation_method,
    credit_rating: creditRating,
    customer_address: {
      street_address: customerGaragingAddress?.address_line_1,
      city: customerGaragingAddress?.city,
      state: customerGaragingAddress?.state_name,
      zip_code: customerGaragingAddress?.zip_code
    },
    dealer_address: {
      street_address: dealerProfile?.dealer_address?.address_line_1,
      city: dealerProfile?.dealer_address?.city,
      state: dealerProfile?.dealer_address?.state_name,
      zip_code: dealerProfile?.dealer_address?.zip_code
    },
    dealer_id: dealerPreferences?.dealer_id,
    lender_id: state.lender_id,
    down_payment: Number(downPayment),
    down_payment_paid_by_dealer: 0,
    down_payment_paid_by_oem: 0,
    fees: fees
      .filter((fee: any) => fee.is_active)
      ?.map((fee: any) => {
        return {
          fee_name: fee.fee_name,
          fee_amount: fee.applied_price ?? fee.default_amount,
          fee_handling: fee?.fee_handling,
          is_active: fee?.is_active,
          applicable_finance_type: fee?.applicable_finance_type,
          is_taxable: fee?.taxable
        }
      }),
    finance_type: finance_type,
    fni_products: fni?.map((item: any) => {
      return {
        fni_product_name: item.product_name,
        fni_product_amount: item.price ?? item.applied_price,
        // amount_handling: item?.fee_handling
        is_taxable: item?.taxable
      }
    }),
    insurance: insurance,
    insurance_amount: 0,
    interest_chart_margin: isVehicleSelectedAndOrderSaved
      ? order?.margin ?? 0
      : program?.interest_chart?.[0]?.margin ?? 0,
    is_rv_guaranteed: 'False',
    markup_rate: 0,
    net_financed_amount: netFinancedAmount,
    options: vehiclesData?.dealer_options
      ?.filter(
        (option: any) =>
          (option.installation_mode === 'Pre-Installed' && !option.is_price_inclusive) ||
          option.installation_mode === 'Optional'
      )
      .map((option: any) => ({
        option_name: option.product_name,
        option_amount: option.applied_price ?? option.price ?? 0,
        rv_amount_on_option: option.rv_balloon_value ?? option.rv_adder ?? 0,
        installation_mode: option.installation_mode,
        is_price_inclusive: option.is_price_inclusive
      })),
    per_unit_selling_price: perUnitSellingPrice,
    quantity: 1,
    rental_frequency: isVehicleSelectedAndOrderSaved ? order.rental_frequency : 'Monthly',
    rental_mode: isVehicleSelectedAndOrderSaved ? order.rental_mode : program?.rental_mode,
    rv_amount: 0,
    rv_value: Number(rv_value),
    rebate: { price: program?.rebates ?? 0 },
    structure_rentals: [],
    subsidy: {
      subsidy_calculation_method: 'percentage from Dealer and percentage from Manufacturer',
      financier_rate: 0,
      manufacturer_subsidy_rate: 2,
      dealer_subsidy_rate: 1
    },
    tax_on_selling_price: calculation?.tax_on_selling_price,
    tspk: misc?.tax_on_selling_price || state?.order?.tax_amount,
    taxes: [],
    taxes_amount: 0,
    terms: Number(contract_term),
    trade_in_amount: tradeInAmount,
    trade_ins: [
      {
        vin: 'vin' in tradeIn ? tradeIn.vin : '',
        offer_amount: 'trade_in_amount' in tradeIn ? tradeIn.trade_in_amount : 0,
        pay_off_amount: 'payoff_amount' in tradeIn ? tradeIn.payoff_amount : 0,
        allowance_amount: 'trade_in_amount' in tradeIn ? tradeIn.trade_in_amount : 0
      }
    ],
    vehicle: {
      vin: vehiclesData?.vehicle?.vin,
      is_new: true,
      odometer_mileage: vehiclesData?.vehicle?.mileage ? vehiclesData?.vehicle?.mileage : 0,
      msrp: vehiclesData?.vehicle?.msrp,
      year: vehiclesData?.vehicle?.year,
      transmission_type: vehiclesData?.vehicle?.transmission_type
    }
  }
  return calculationRequestObject
}

export const createOrderRequestPayloadFromState = (
  state: DeskingState,
  dealerInfo: any,
  lender_id: number
) => {
  const { row: rowIndex, col: colIndex, vin, finance_type, vehicleForComparison } = state?.selection

  const singleVehicleComparison = vehicleForComparison === VehiclesForComparisonTypes.SINGLE
  const isVehicleSelectedAndOrderSaved = state.isVehicleSelectedAndOrderSaved

  const { contract_term, rv_value, annual_usage, apr } = isVehicleSelectedAndOrderSaved
    ? state.preview.quote_params
    : singleVehicleComparison
    ? state[VehiclesForComparisonTypes.SINGLE][state.finance_type]?.calculationsBoxes[rowIndex][0]
    : state[VehiclesForComparisonTypes.MULTIPLE][state.finance_type]?.find(
        (financeItem: any, idx: number) => financeItem.vin === vin || idx === colIndex
      )?.quote_params

  const calculation = isVehicleSelectedAndOrderSaved
    ? state.preview.calculationsBoxes
    : singleVehicleComparison
    ? state[VehiclesForComparisonTypes.SINGLE][finance_type].calculationsBoxes[rowIndex][colIndex]
    : state[VehiclesForComparisonTypes.MULTIPLE][finance_type].find(
        (financeItem: any, idx: number) => financeItem.vin === vin || idx === colIndex
      )?.calculationsBoxes

  const vehiclesData = state.vehiclesData.get(vin)
  const program = (vehiclesData?.program ?? {})?.[state.finance_type]?.find(
    (p) =>
      p?.contract_term === contract_term &&
      (state.finance_type === FinanceTypes.FINANCE || p?.mileage === annual_usage)
  )

  const fees = (vehiclesData?.fees ?? {})?.[state.finance_type] ?? []

  const filteredData = vehiclesData?.dealer_options?.filter(
    (option: any) =>
      (option.installation_mode === InstallationMode.PRE_INSTALLED && !option.is_price_inclusive) ||
      option.installation_mode === InstallationMode.OPTIONAL
  )
  const optionSum = filteredData?.reduce(
    (sum: any, option: any) => sum + (typeof option.price === 'number' ? option.price : 0),
    0
  )
  const fni = (vehiclesData?.fni ?? {})?.[state.finance_type?.toLowerCase()] ?? []
  const finance_fni = (fni ?? [])?.reduce((sum: any, item: any) => {
    const price = typeof item.price === 'number' ? item.price : 0
    return sum + price
  }, 0)
  const financedFeeSum = fees.reduce(
    (total: number, fee: any) =>
      fee?.fee_handling?.toLowerCase() === FeeHandlingType.FINANCE
        ? total + (fee?.applied_price ?? fee?.default_amount ?? 0)
        : total,
    0
  )
  const sellingPrice = Number(vehiclesData?.vehicle?.internet_price || 0)
  const tradeIn = state?.tradeIn
  const { payment_type, ...tradeInWithoutPaymentType } = tradeIn || {}
  const tradeInAmount =
    Object.keys(tradeIn ?? {}).length > 0
      ? (tradeIn as IOrderTradeIn)?.trade_in_amount - (tradeIn as IOrderTradeIn)?.payoff_amount
      : 0
  const tradeInValue = tradeInAmount < 0 ? tradeInAmount : 0
  const tax_amount = calculation?.tax_amount ?? 0
  const leaseRebate = state?.selection?.quoteParams?.rebates
  const requestObject = {
    reference_number: state?.order?.reference_number ? state?.order?.reference_number : undefined,
    capitalized_cost_reduction: calculation?.capitalized_cost_reduction,
    monthly_deprecation: calculation?.monthly_deprecation,
    monthly_sales_use_tax: calculation?.monthly_sales_use_tax,
    capital_cost_reduction_tax: calculation?.taxes?.capital_cost_reduction_tax,
    tax_on_capitalized_cost_reduction: calculation?.tax_on_capitalized_cost_reduction,
    rebate: leaseRebate,
    tax_amount: calculation?.tax_amount,
    tax_amount_number: calculation?.tax_amount,
    finance_amount: calculation?.gross_capitalized_cost,
    identifier: state?.order?.identifier ? state?.order?.identifier : undefined,
    dealer_id: dealerInfo?.id,
    introducer_name: dealerInfo?.dealer_name,
    company_id: dealerInfo?.company_id,
    lender_id,
    reference_id: state?.customer?.reference_id ? state?.customer?.reference_id : '',
    contract_term: contract_term,
    allowed_usage: annual_usage,
    credit_rating: state?.customer?.credit_rating ? state?.customer?.credit_rating : '',
    apr: program?.final_customer_rate > 0 ? program?.final_customer_rate : apr,
    estimated_monthly_payment: calculation?.estimated_monthly_payment
      ? calculation?.estimated_monthly_payment
      : 0,
    annual_usage: annual_usage,
    due_at_signing: calculation?.due_at_signing ? calculation?.due_at_signing : 0,
    selling_price: calculation?.selling_price,
    down_payment: (Number(calculation?.down_payment) * 100) / sellingPrice,
    down_payment_value: Number(calculation?.down_payment),
    margin: apr ? parseFloat(apr) : program?.interest_chart ? program?.interest_chart[0].margin : 0,
    base_residual_type: program?.base_residual_type ? program?.base_residual_type : 0,
    excess_mileage_rate: program?.excess_mileage_rate ? program?.excess_mileage_rate : 0,
    base_rate: program?.base_rate_chart?.base_rate || 0,
    rv_balloon_percentage: rv_value || 0,
    rv_balloon_value: (Number(rv_value || 0) * sellingPrice) / 100,
    asset_rv_amount: rv_value || 0,
    fp_id: program?.fp_id,
    vin: vin,
    net_finance_amount: calculation?.adjusted_capitalized_cost
      ? calculation?.adjusted_capitalized_cost
      : calculation?.selling_price || 0,
    finance_type: finance_type ? finance_type : '',
    order_options:
      vehiclesData?.dealer_options && vehiclesData?.dealer_options.length > 0
        ? vehiclesData?.dealer_options?.map((option: any) => {
            return {
              installation_mode: option.installation_mode,
              is_price_inclusive: option.is_price_inclusive,
              product_name: option?.product_name,
              option_id: option.option_id ? option.option_id : option.id,
              applied_price: (option.applied_price ? option.applied_price : option.price) || 0,
              rv_balloon_percentage:
                (option.rv_balloon_percentage ? option.rv_balloon_percentage : option.rv_adder) ||
                0,
              rv_balloon_value:
                (option.rv_balloon_value ? option.rv_balloon_value : option.rv_adder) || 0
            }
          })
        : undefined,
    order_fnI: (fni ?? [])?.map((fniItem: any) => {
      return {
        financial_insurance_id: fniItem?.financial_insurance_id ?? fniItem.id,
        applied_price: fniItem?.applied_price ?? fniItem.price,
        form_id: fniItem?.form_id,
        rate_id: fniItem?.rate_id,
        session_id: fniItem?.session_id,
        product_name: fniItem?.product_name,
        identifier: fniItem?.identifier,
        is_msp: fniItem?.MSP ?? fniItem?.is_msp,
        mileage: fniItem?.mileage,
        term: fniItem?.term,
        taxable: fniItem?.applicable_finance_type?.includes(finance_type)
      }
    }),
    order_tradein:
      Object.keys(tradeIn ?? {}).length > 0
        ? tradeIn?.loan_lease_balance
          ? tradeIn
          : tradeInWithoutPaymentType
        : null,
    order_fees: fees
      .filter((fee: any) => fee.is_active)
      ?.map((fee: any) => {
        return {
          dealer_fee_id: fee.id,
          fee_name: fee.fee_name,
          applied_price: fee.applied_price ?? fee.default_amount,
          fee_handling: fee?.fee_handling,
          is_active: fee?.is_active,
          applicable_finance_type: fee?.applicable_finance_type,
          vendor: fee?.vendor,
          fee_type: fee?.event,
          is_taxable: fee?.taxable
        }
      }),
    order_asset: {
      make: vehiclesData?.vehicle?.make,
      model: vehiclesData?.vehicle?.model,
      model_code: vehiclesData?.vehicle?.model_code,
      trim_description: vehiclesData?.vehicle?.trim_description,
      year: vehiclesData?.vehicle?.year,
      msrp: vehiclesData?.vehicle?.msrp,
      exterior_color_description: vehiclesData?.vehicle?.exterior_color_description,
      interior_color_description: vehiclesData?.vehicle?.interior_color_description,
      type: vehiclesData?.vehicle?.type,
      classification: vehiclesData?.vehicle?.vehicle_class,
      engine_cylinders: vehiclesData?.vehicle?.engine_cylinders,
      power_type: vehiclesData?.vehicle?.engine_power,
      engine_displacement: vehiclesData?.vehicle?.engine_displacement,
      fuel_type: vehiclesData?.vehicle?.fuel_type,
      quantity: 1,
      unit_price: isVehicleSelectedAndOrderSaved
        ? state?.order?.order_asset?.unit_price
        : vehiclesData?.vehicle?.internet_price,
      vin: vehiclesData?.vehicle?.vin,
      unit_tax: vehiclesData?.vehicle?.unit_tax,
      stock_number: vehiclesData?.vehicle?.stock_number,
      category: vehiclesData?.vehicle?.category,
      asset_type: vehiclesData?.vehicle?.asset_type,
      asset_sub_type: vehiclesData?.vehicle?.asset_sub_type,
      description: vehiclesData?.vehicle?.description,
      supplier_name: vehiclesData?.vehicle?.supplier_name,
      photo_urls: vehiclesData?.vehicle?.photo_urls,
      transmission_type: vehiclesData?.vehicle?.transmission_type,
      rv_balloon_amount: vehiclesData?.vehicle?.rv_balloon_amount,
      body_type: vehiclesData?.vehicle?.body_type,
      body_style: vehiclesData?.vehicle?.body_style,
      transmission_speed: vehiclesData?.vehicle?.transmission_speed,
      engine_description: vehiclesData?.vehicle?.engine_description,
      engine_power: vehiclesData?.vehicle?.engine_power,
      horse_power: vehiclesData?.vehicle?.horse_power,
      drive_train: vehiclesData?.vehicle?.drive_train,
      vehicle_display_name: vehiclesData?.vehicle?.vehicle_display_name
    },
    rental_mode: isVehicleSelectedAndOrderSaved
      ? state?.order?.rental_mode
      : program?.rental_mode ?? null,
    rental_frequency: 'Monthly',
    insurance:
      state?.insurance && Object.keys(state?.insurance).length > 0
        ? {
            ...state?.insurance[0],
            expiration_date: state?.insurance[0].expiration_date?.split('T')[0]
              ? state?.insurance[0]?.expiration_date?.split('T')[0]
              : state?.insurance[0]?.expiration_date
          }
        : null,
    calcuation_method: program?.calculation_method
  }
  return requestObject
}

export const selectDefaultTerms = (terms: any[], defaultTermId: number): any[] => {
  if (terms.length === 0) {
    return []
  }

  const sortedTerms = terms.sort((a, b) => a.term - b.term)
  const defaultIndex = sortedTerms.findIndex((term) => term.id === defaultTermId)

  if (defaultIndex === -1) {
    return sortedTerms.slice(0, Math.min(3, sortedTerms.length))
  }

  if (defaultIndex === 0) {
    return sortedTerms.slice(0, 3)
  } else if (defaultIndex === sortedTerms.length - 1) {
    return sortedTerms.slice(-3)
  } else {
    return sortedTerms.slice(defaultIndex - 1, defaultIndex + 2)
  }
}

export const selectDefaultMileages = (mileages: any[], defaultMileageId: number): any[] => {
  if (mileages.length === 0) {
    return []
  }
  const sortedMileages = mileages.sort((a, b) => a.value - b.value)
  const defaultIndex = sortedMileages.findIndex((mileage) => mileage.id === defaultMileageId)

  if (defaultIndex === -1) {
    return sortedMileages.slice(0, Math.min(3, sortedMileages.length))
  }

  if (defaultIndex === 0) {
    return sortedMileages.slice(0, 3)
  } else if (defaultIndex === sortedMileages.length - 1) {
    return sortedMileages.slice(-3)
  } else {
    return sortedMileages.slice(defaultIndex - 1, defaultIndex + 2)
  }
}
