import { FC, useEffect, useState } from 'react'
import { Link, styled, useTheme } from '@mui/material'
import { Box, Dialog, Icon, Typography, Grid, IBoxProps, Image } from '@ntpkunity/controls'
import { ProductDetailDialog, ReactCarousel } from '@components'
import { useQueryClient } from 'react-query'
import { useStoreContext } from '@store/storeContext'
import { QueryKeys } from '@helpers/enums'

const IconRightArrow = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13" fill="none"><path d="M1.5 11.1251L6.5 6.12512L1.5 1.12512" stroke="${encodeURIComponent(
  '#808080'
)}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>')`

const IconLeftArrow = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13" fill="none"><path d="M6.5 11.1251L1.5 6.12512L6.5 1.12512" stroke="${encodeURIComponent(
  '#808080'
)}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>')`

const ProviderWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.provider-wrap': {
    '.custom-link': {
      color: theme.palette.grey[600] + '!important',
      '&:hover': {
        textDecoration: 'none' + '!important'
      }
    },
    '.react-multi-carousel-list': {
      padding: 0,
      position: 'relative',
      '.react-multi-carousel-track': {
        '.react-multi-carousel-item': {
          padding: '0 30px',
          '.aspect-ratio-image-wrap .aspect-ratio': {
            marginBottom: 14,
            padding: '0 20px'
          }
        }
      },
      '.carousel-control': {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: 'transparent',
        border: 'none',
        borderRadius: 8,
        backgroundSize: 10,
        width: 40,
        height: 40,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        '&:hover': {
          backgroundColor: '#f2f2f2'
        },
        svg: {
          display: 'none'
        },
        '&.next-btn': {
          backgroundImage: IconRightArrow,
          right: 0
        },
        '&.prev-btn': {
          backgroundImage: IconLeftArrow,
          left: 0
        }
      }
    }
  }
}))

const AddOnsSupplier: FC<{ details?: any; open: boolean; onBackBtn: any }> = ({
  details,
  open,
  onBackBtn
}) => {
  const theme = useTheme()
  const queryClient = useQueryClient()
  const { states } = useStoreContext()
  const [defaultCurrency, setDefaultCurrency] = useState('')
  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])
  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0].symbol : ''
    )
  }, [currencies])

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }

  const AssetDetail: FC = () => {
    const handleBrochureLink = () => {
      let url = details?.brochure_url
      window.open(url)
    }

    const handleVideoLink = () => {
      let url = details?.video_url
      window.open(url)
    }
    return (
      <>
        <ProductDetailDialog theme={theme} sx={{ minHeight: '100%' }}>
          <ProviderWrap theme={theme} className="provider-wrap">
            <Grid theme={theme} container columnSpacing={2} rowSpacing={2}>
              <Grid theme={theme} item md={6} sm={12} xs={12} mt={2}>
                <Typography theme={theme} component="p" variant="body2" children="Supplier Name" />
                <Typography
                  theme={theme}
                  component="h3"
                  variant="h3"
                  children={details?.supplier}
                />
                <Box theme={theme} display={'flex'} alignItems={'center'} mb={1}>
                  <Typography
                    theme={theme}
                    component="span"
                    variant="subtitle1"
                    children={
                      <>
                        {defaultCurrency}
                        {parseFloat(details?.price).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                      </>
                    }
                    mr={1}
                  />
                </Box>
                <Typography
                  theme={theme}
                  component="p"
                  variant="body2"
                  mb={3}
                  children={`Part Number: ${details?.part_no}`}
                />
                <Typography
                  theme={theme}
                  component="p"
                  variant="body2"
                  mb={3}
                  children={details?.description}
                />
                <Box theme={theme}>
                  <Link className="custom-link" onClick={handleBrochureLink}>
                    <Typography
                      theme={theme}
                      variant="body2"
                      component={'span'}
                      className="custom-link"
                      children={
                        <>
                          View Product Brochure <Icon name="IconLeftArrow" />
                        </>
                      }
                    />
                  </Link>
                </Box>
                <Box theme={theme}>
                  <Link className="custom-link" onClick={handleVideoLink}>
                    <Typography
                      theme={theme}
                      variant="body2"
                      component={'span'}
                      children={
                        <>
                          View Product Video <Icon name="IconLeftArrow" />
                        </>
                      }
                    />
                  </Link>
                </Box>
              </Grid>
              <Grid theme={theme} item md={6} sm={12} xs={12}>
                {details?.image?.length > 1 ? (
                  <ReactCarousel
                    responsive={responsive}
                    autoPlaySpeed={5000}
                    autoPlay
                    showDots
                    infinite
                  >
                    {details?.image?.map((img: any, index: number) => (
                      <Image
                        theme={theme}
                        key={index}
                        src={img?.location}
                        alt={`Product ${index + 1}`}
                        aspectRatio="16:9"
                      />
                    ))}
                  </ReactCarousel>
                ) : (
                  <Image
                    theme={theme}
                    src={
                      details?.image?.[0]?.location ??
                      require('../../../src/public/assets/images/no-img.svg')
                    }
                    alt="Product"
                    aspectRatio="16:9"
                  />
                )}
              </Grid>
            </Grid>
          </ProviderWrap>
        </ProductDetailDialog>
      </>
    )
  }

  return (
    <>
      <Dialog
        variant={undefined}
        disablePortal
        size="xl"
        title={details?.product_name}
        open={open}
        onCloseDialog={onBackBtn}
        noFooter
        theme={theme}
        onBackBtn={onBackBtn}
        children={
          <>
            <AssetDetail />
          </>
        }
      />
    </>
  )
}

export default AddOnsSupplier
