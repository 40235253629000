import jwt_decode from 'jwt-decode'
// import { LocalStorage } from "@helpers/browserStorage";
import {
  EXTERNAL_SUBSCRIPTION_KEY,
  Months,
  // ACCESS_TOKEN_KEY,
  SETTINGS,
  TimeZones,
  URL_PATTERN_REGEX,
  urlPattern
} from '@helpers/constants'
import dayjs from 'dayjs'
import { AddressType, IntegrationProvider, LenderIntegrationType } from './enums'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { ProfileChannel } from 'types/chat'
dayjs.extend(utc)
dayjs.extend(timezone)
export const validation = (
  name: string,
  required: boolean,
  matchPattern: boolean = false,
  passwordRegex: RegExp = new RegExp(''),
  regexMessage: string = ''
): any => ({
  required: {
    value: required,
    message: `${name} is required`
  },
  validate: {
    matchesPattern: (value: string | undefined) => {
      if (matchPattern && value && !passwordRegex.exec(value)) {
        return `${regexMessage}`
      }
      return true
    }
  }
})

export const getUser = (): any | undefined => {
  // const localStorage: LocalStorage = new LocalStorage();
  const token = getMarketPlaceToken()
  return jwt_decode(token as string) || undefined
}

export const getMarketPlaceToken = (): any | undefined => {
  const settings: any = localStorage.getItem(SETTINGS)
  return JSON.parse(settings)?.access_token
}

export const getAllSettingsFromLocalStorage = (): any | undefined => {
  const settings: any = localStorage.getItem(SETTINGS)
  return JSON.parse(settings)
}

export const getANToken = (): any | undefined => {
  const token1: any = localStorage.getItem('settings')
  const token = JSON.parse(token1)?.access_token
  return token
}

export const getDecodedANToken = (access_token: string): any | undefined => {
  return jwt_decode(access_token as string) || undefined
}

export const dateTime12HourFormat = () => {
  const currentDate = new Date()
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
  const day = currentDate.getDate().toString().padStart(2, '0')
  const year = currentDate.getFullYear()

  return (
    month +
    '/' +
    day +
    '/' +
    year +
    ' ' +
    currentDate.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    })
  )
}

export const convertToDecimal = (amount: number): string => amount.toFixed()

export const formatedDate = (date: string | Date, format: string): string =>
  dayjs(date).format(format)

export const dateFormat = 'MM/DD/YYYY'

export const containsKeyValue = (obj: any, key: any, value: any) => {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop) && prop === key && obj[prop] === value) {
      return true
    }
  }
  return false
}

export const openInNextWindow = (url: string): Window | null => window.open(url)

export const getBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      const result: string = reader.result as string
      const base64Data = result.split(',')[1] // Remove the data URL scheme
      resolve(base64Data)
    }
    reader.onerror = (error) => reject(error)
  })
}

export const isEmpty = (value: any) =>
  value === undefined ||
  value === null ||
  (typeof value === 'string' && !value.trim().length) ||
  (typeof value === 'object' && !Object.keys(value).length)

export const formatPayloadDate = (date: any) => dayjs(date).format('YYYY-MM-DD')
export const getGoogleKey = (integrationsData: any) => {
  let googleKey = null
  const integarationObj = integrationsData?.find(
    (x: any) =>
      x?.integration_type === LenderIntegrationType.ADDRESS_VALIDATION &&
      x?.provider_name === IntegrationProvider.GOOGLE &&
      x?.is_active
  )
  if (integarationObj && integarationObj?.lender_integration_user_inputs?.length > 0) {
    googleKey = integarationObj?.lender_integration_user_inputs[0]?.user_input_value
  }
  return googleKey
}

export const isValidUrl = (url: any) => {
  return urlPattern.test(url)
}

export const formatDate = (inputDate: any) => {
  const date = new Date(inputDate)
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const year = date.getFullYear()
  return `${month}/${day}/${year}` // returns format MM/DD/YYYY
}

export const convertDateTimeToISOZFormat = (inputDateTime: any) => {
  const parts = inputDateTime.split(' ')
  const dateParts = parts[0].split('/')
  const timeParts = parts[1].split(':')

  let hours = parseInt(timeParts[0])
  const minutes = parseInt(timeParts[1])
  const isPM = parts[2] === 'PM'

  if (isPM && hours !== 12) {
    hours += 12
  } else if (!isPM && hours === 12) {
    hours = 0
  }

  const isoFormattedDateTime = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}T${hours
    .toString()
    .padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00.000Z`

  return isoFormattedDateTime
}

export const removeUndefinedKeys = (obj: any) => {
  const newObj: any = {}

  for (const [key, value] of Object.entries(obj)) {
    if (value !== undefined) {
      newObj[key] = value
    }
  }

  return newObj
}

export const getInitials = (firstName: string, lastName: string): string => {
  let firstInitial = firstName[0]?.toUpperCase() || ''
  let lastInitial = lastName[0]?.toUpperCase() || ''

  return firstInitial + lastInitial
}

export const dateAndTimeIn12HourFormat = () => {
  const date = new Date().toLocaleDateString().split('/')
  let actualReturnDate =
    date[1] +
    '/' +
    date[0] +
    '/' +
    date[2] +
    ' ' +
    new Date().toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    })
  return actualReturnDate
}

export const formatDateAsDay_Month_Year = (inputDate: any) => {
  const date = new Date(inputDate)
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const year = date.getFullYear()
  return `${day}/${month}/${year}` // returns format MM/DD/YYYY
}

export const removeSpecialChars = (input: unknown): string => {
  // Convert input to string and remove all non-numeric characters
  const cleanedString = String(input).replace(/\D+/g, '')
  // Format the cleaned string as "(###) ###-####"
  const formattedString = cleanedString.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
  return formattedString
}

export const setExternalSubscriptionKey = (key: string) => {
  localStorage.setItem(EXTERNAL_SUBSCRIPTION_KEY, JSON.stringify(key))
}

export const getExternalSubscriptionKey = (): string => {
  const externalSubscriptionKey: any = localStorage.getItem(EXTERNAL_SUBSCRIPTION_KEY)
  return JSON.parse(externalSubscriptionKey) as string
}
export const compareAnswers = (formValues: any, checklistData: any) => {
  const checklistErrors = []

  // Check if checklistData is defined and not empty
  if (checklistData && checklistData.length > 0 && checklistData[0]?.length > 0) {
    for (const [question, answer] of Object.entries(formValues)) {
      const matchingQuestion = checklistData[0][0]?.questions?.find(
        (q: { question: string }) => q?.question === question
      )

      if (matchingQuestion && answer !== undefined && answer !== null && answer !== '') {
        const matchingAnswer = matchingQuestion?.answers?.find(
          (a: { answer: any; is_correct?: boolean }) =>
            a?.answer === answer && (a?.is_correct ?? false)
        )

        if (!matchingAnswer?.is_correct) {
          checklistErrors.push({ question, message: 'Amend the option to proceed.' })
        }
      }
    }
  }
  return checklistErrors
}

export const removeApostrophes = (question: string) => {
  // Check if question is null or undefined
  if (question == null) {
    return '' // or handle accordingly
  }

  // Replace apostrophes with an empty string
  return question.replace(/'/g, '')
}

export const question_check = (formData: { [x: string]: any }, questionArray: any[]) => {
  // Check if formData is defined and not null
  if (formData) {
    const formDataWithOriginalQuestions = Object.keys(formData).reduce(
      (acc: { [x: string]: any }, key) => {
        // Find the corresponding entry in the original and modified questions array
        const questionEntry = questionArray.find(
          (entry: { modifiedQuestion: string }) => entry.modifiedQuestion === key
        )

        // If found, replace the key with the original question
        if (questionEntry) {
          acc[questionEntry.originalQuestion as string] = formData[key]
        } else {
          // If not found, keep the key as it is
          acc[key] = formData[key]
        }

        return acc
      },
      {}
    )

    return formDataWithOriginalQuestions
  } else {
    // If formData is undefined or null, return an empty object
    return {}
  }
}

export const utcToLocal = (date: string, format?: string) => {
  if (date) {
    const utcDate = dayjs.utc(date)
    const localDate = utcDate.tz(dayjs.tz.guess())
    return format ? localDate.format(format) : localDate.format()
  } else return ''
}

export const formatPhoneNumber = (phoneNumber: any) => {
  // Remove any non-numeric characters from the phone number
  const cleaned = ('' + phoneNumber).replace(/\D/g, '')

  // Apply the formatting
  const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')

  return formatted
}

export const addConditionToRequestBody = (
  field: string,
  value: any,
  operator: string,
  requestBody: any[]
) => {
  // Push the condition object to the requestBody array
  requestBody.push({
    field,
    operator: operator,
    value,
    and_or: 'And'
  })
}

export const initialDealerWorkQueueState = {
  result: [],
  page_number: 0,
  page_size: 0,
  total_results: 0,
  total_pages: 0
}

export const getTenantIdFromLocalStorage = (): string => {
  const settingsJson = localStorage?.getItem('settings')
  if (settingsJson) {
    const settings = JSON.parse(settingsJson)
    return (settings.tenant_id as string) || '' // Ensure tenant_id is treated as string
  }
  return '' // Default value if settings are not found or parsing fails
}

export const convertToAmountWithOutSign = (
  value: number,
  maximumFractionDigits = 2,
  minimumFractionDigits = 2
): string =>
  value?.toLocaleString('en-US', {
    minimumFractionDigits,
    maximumFractionDigits
  })

export const formatCurrency = (
  value: number | null | undefined,
  options: {
    maximumFractionDigits?: number
    minimumFractionDigits?: number
    fallbackValue?: string
    prefix?: string
  } = {}
): string => {
  const { maximumFractionDigits = 2, minimumFractionDigits = 2, fallbackValue = '-' } = options

  if (value == null) {
    return fallbackValue
  }

  const formattedValue = value.toLocaleString('en-US', {
    minimumFractionDigits,
    maximumFractionDigits
  })

  return `${formattedValue}`
}

export const formatDateStringPreQualification = (
  dateString: string = '',
  includeTime: boolean = false
) => {
  let date = new Date(dateString)

  if (isNaN(date.getTime())) {
    date = new Date()
  }

  const day = String(date?.getDate())?.padStart(2, '0')
  const month = Months[date?.getMonth()]
  const year = date?.getFullYear()

  if (!includeTime) {
    return `${month}/${day}/${year}`
  }

  const hours = date?.getHours()
  const minutes = String(date?.getMinutes())?.padStart(2, '0')

  const isAM = hours < 12
  const formattedHours = String(hours % 12 || 12)?.padStart(2, '0')
  const period = isAM ? ' AM' : ' PM'

  return `${month}/${day}/${year} ${formattedHours}:${minutes}${period}`
}

export const formatSSNAndPhone = (input: string = '0000000000', isPhone: boolean = false) => {
  const cleaned = input?.replace(/[^a-zA-Z0-9]/g, '')

  if (cleaned?.length === 9 && !isPhone) {
    return cleaned.replace(/(.{3})(.{2})(.{4})/, '$1 - $2 - $3')
  } else if (cleaned?.length === 10 && isPhone) {
    return cleaned.replace(/(.{3})(.{3})(.{4})/, '$1 - $2 - $3')
  }

  return input
}

export const getMonthsFromGivenDateFormat = (dateFormat: string) => {
  if (dateFormat) {
    const month = parseInt(dateFormat?.substring(0, 2), 10) - 1
    const year = parseInt(dateFormat?.substring(2), 10)

    const givenDate = new Date(year, month)
    const currentDate = new Date()

    const monthsDiff =
      (currentDate?.getFullYear() - givenDate?.getFullYear()) * 12 +
      (currentDate?.getMonth() - givenDate?.getMonth())
    return monthsDiff
  }

  return 0
}

export const readFileAsDataURL = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()

    fileReader.onload = (event) => {
      if (event.target && event.target.result) {
        resolve(event.target.result.toString())
      } else {
        reject('Failed to read file as data URL.')
      }
    }

    fileReader.onerror = () => {
      reject('Error reading file as data URL.')
    }

    fileReader.readAsDataURL(file)
  })
}

export const base64ToFile = (base64Strings: any[]): File[] => {
  return base64Strings
    ?.map((base64String, index) => {
      const matches = base64String?.document?.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/)
      if (!matches || matches.length !== 3) {
        console.error(`Invalid Base64 string format for element at index ${index}.`)
        return null
      }
      const mime = matches[1]
      const bstr = matches[2]
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], base64String.name || `file_${index + 1}`, {
        type: mime
      })
    })
    .filter((file) => file !== null) as File[]
}

export const createFileUploadUtils = (
  fieldName: string,
  callbacks: any,
  allowMultiple: boolean = false,
  allowedExtensions: string[] = []
) => {
  const { setValue, getValues, setImageFiles, clearErrors } = callbacks

  const addFilesForPreview = async (files: FileList) => {
    const newImagesArray: any[] = []

    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      try {
        const result = await readFileAsDataURL(file)
        newImagesArray.push({
          document: result?.split(',')[1],
          content_type: file.type,
          name: file.name,
          file_size: `${(file.size / 1024).toFixed(2)}KB`,
          created_by: 'null'
        })
      } catch (error) {
        console.error('Error reading file:', error)
      }
    }

    setValue(
      fieldName,
      allowMultiple ? [...(getValues(fieldName) || []), ...newImagesArray] : newImagesArray
    )
    setImageFiles((prev: any) => [...prev, ...newImagesArray])
  }

  const onImageUpload = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      addFilesForPreview(e.target.files)
      clearErrors(fieldName)
    }
  }

  const onImageDrop = (e: any) => {
    e.preventDefault()
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const files = Array.from(e?.dataTransfer?.files)
      const filteredFiles =
        allowedExtensions?.length === 0
          ? files
          : files?.filter((file: any) => allowedExtensions?.includes(file?.name?.split('.')?.pop()))
      const dataTransfer = new DataTransfer()
      filteredFiles.forEach((file) => dataTransfer.items.add(file as File))
      addFilesForPreview(dataTransfer.files)
      clearErrors(fieldName)
    }
  }

  const onRemoveFile = (e: any, index: number) => {
    e.preventDefault()
    setImageFiles((prev: any) => prev?.filter((_: any, i: number) => i !== index))
    const formImages = getValues(fieldName)?.filter((_: any, i: number) => i !== index)
    setValue(fieldName, formImages)
  }

  return {
    onImageUpload,
    onImageDrop,
    onRemoveFile
  }
}

export const findAddress = (addresses: Array<any>) => {
  const isMailingSameAsGaraging = addresses?.find(
    (address) =>
      (address?.address_type === AddressType.GARAGING &&
        address?.entity_type === AddressType.MAILING) ||
      (address?.address_type === AddressType.MAILING && address?.is_garaging === true)
  )

  if (isMailingSameAsGaraging) {
    const mailingAddress = addresses?.find(
      (address) => address?.address_type === AddressType.MAILING
    )
    return mailingAddress
  }

  const garagingAddress = addresses?.find(
    (address) => address?.address_type === AddressType.GARAGING
  )
  return garagingAddress
}

export const formatTime = (dateTime: any) => {
  const date = new Date(dateTime * 1000)
  const year = date.getUTCFullYear()
  const month = date.getUTCMonth() + 1
  const day = date.getUTCDate()

  let hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()

  const ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours ? hours : 12

  const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day
    .toString()
    .padStart(2, '0')}`

  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(seconds).padStart(2, '0')

  const formattedTime = `${hours}:${formattedMinutes}:${formattedSeconds} ${ampm}`
  const combinedDateTime = `${formattedDate} ${formattedTime}`
  return combinedDateTime
}

export const getUserNameFromChannel = (channel: ProfileChannel | null, getInitials?: boolean) => {
  if (!channel) return ''
  const firstName = channel.firstName
  const lastName = channel.lastName
  const nameArr = [firstName, lastName]
  if (!getInitials) return nameArr.filter(Boolean).join(' ')
  return nameArr
    .map((namePart) => namePart?.[0])
    .filter(Boolean)
    .join('')
}

export const combineDateTime = (
  selectedDate: moment.Moment,
  selectedTime: string,
  timeZone: string
) => {
  if (!selectedDate || !selectedTime || typeof selectedTime !== 'string') return null

  const datePart = selectedDate.format('YYYY-MM-DD')
  const [timeString, modifier] = selectedTime.split(' ')
  if (!timeString || !modifier) return null

  let [hours, minutes] = timeString.split(':')

  if (modifier === 'PM' && hours !== '12') {
    hours = String(Number(hours) + 12)
  } else if (modifier === 'AM' && hours === '12') {
    hours = '00'
  }

  const combinedDateTimeString = `${datePart}T${hours}:${minutes}:00`
  return dayjs.tz(combinedDateTimeString, timeZone).toDate()
}

export const getTimeZoneData = (
  orderTimeZoneOffset?: string,
  orderTimeZone?: string,
  dealerProfileData?: any
): { value: string; name: string } => {
  if (orderTimeZoneOffset && orderTimeZone) {
    const orderTimeZoneData = Object.entries(TimeZones).find(
      ([key, value]) => value === orderTimeZoneOffset && key === orderTimeZone
    )

    if (orderTimeZoneData) {
      return {
        value: orderTimeZoneData[1],
        name: orderTimeZoneData[0]
      }
    }
  }

  if (dealerProfileData?.time_zone_difference && dealerProfileData?.time_zone) {
    const dealerTimeZone = Object.entries(TimeZones).find(
      ([key, value]) =>
        value === dealerProfileData?.time_zone_difference &&
        dealerProfileData?.time_zone.includes(key)
    )

    if (dealerTimeZone) {
      return {
        value: dealerTimeZone[1],
        name: dealerTimeZone[0]
      }
    }
  }

  return {
    value: '',
    name: ''
  }
}

export const normalizeUrl = (url: string) => {
  if (url && !URL_PATTERN_REGEX.test(url)) {
    url = 'https://' + url
  }

  return url
}
export const checkPermission = (permissionsList: any, permissionName: any) => {
  if (permissionsList?.permissions?.length > 0) {
    const permission = permissionsList?.permissions?.find(
      (item: any) => item?.name === permissionName
    )
    return permission ? permission?.is_mandatory === true : false
  }
}

export const checkFieldPermission = (permissionsList: any, permissionName: any) => {
    const fieldPermission = { isMandatory: false, isShow: false }
    const permission = permissionsList?.permissions?.find(
      (item: any) => item?.name === permissionName
    )
    if (permission) {
      fieldPermission.isMandatory = permission?.is_mandatory === true
      fieldPermission.isShow = true
    }
    return fieldPermission 
}

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

export const validatePhoneNumber = (value: string | null | undefined): boolean => {
  if (value) {
    return /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value)
  }
  return true
}

export const formatDateFromString = (dateString: string | undefined | null): Date | null => {
  if (!dateString) return null
  const datePart = dateString.includes('T') ? dateString.split('T')[0] : dateString

  return new Date(datePart)
}

export const handleImageUpload = async (files: File[]) => {
  const newImagesArray = []

  for (let i = 0; i < files.length; i++) {
    const file = files[i]
    try {
      const result = await readFileAsDataURL(file)
      newImagesArray.push({
        location: result,
        content_type: file.type,
        name: file.name,
        size: file.size,
        created_by: 'null'
      })
    } catch (error) {
      console.error('Error reading file:', error)
    }
  }

  return newImagesArray
}

export const capitalizeWords = (str: string) => {
  return str
    ?.split(' ')
    ?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase())
    ?.join(' ')
}

export const downloadDocument = async (url: string, key: string) => {
  fetch(url).then((response) => {
    response.blob().then((blob) => {
      const fileURL = window.URL.createObjectURL(blob)
      let alink = window.document.createElement('a')
      alink.href = fileURL
      alink.download = key
      alink.click()
    })
  })
}

export const validateObject = (value: any) => value && Object.keys(value).length

export const findCustomerAddress = (addresses: Array<any>, addressType: string) => {
    const mailingAddress = addresses?.find(
      (address) => address?.address_type === addressType
    )
    return mailingAddress
}
