import { FC, useEffect } from 'react'
import { useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { Box, CircleLoader, Grid } from '@ntpkunity/controls'
import AddVtc from '@app/desking/add-vtc'
import OrderDetails from '@app/desking/order-details'
import CalculationSection from '@app/desking/calculation-section'
import { useQueryClient } from 'react-query'
import {
  useGetCompanyConfigurations,
  useGetDealerData,
  useGetOrder
} from '@apis/dealer-configurations.service'
import { useStoreContext } from '@store/storeContext'
import { DeskingWrap } from '@components'
import {
  DeskingActionTypes,
  useDeskingContext,
  VehiclesForComparisonTypes
} from '@app/desking/desking-context-provider'
import { useLocation, useSearchParams } from 'react-router-dom'
import Customer from './customer'
import { useGetOrderSubmissionResponse } from '@apis/stipulation.service'
import { mapPayload } from '@helpers/lead'
import { LeadKey, LeadStatus } from '@helpers/enums/lead'
import { updateLead } from '@apis/lead-management'
import { ICustomer, IOrderTradeIn } from '@models'
import { Status } from '@helpers/enums'

const Desking: FC = () => {
  const theme = useTheme()
  const location = useLocation()
  const { states } = useStoreContext()
  const [searchParams] = useSearchParams()
  const orderReferenceId = searchParams.get('reference_id')
  const {
    state: { vehiclesForComparison, order, selection, tradeIn, customer, vehiclesData },
    dispatch
  } = useDeskingContext()

  const { state } = location
  const { data: orderData, isLoading: _orderLoading } = useGetOrder(orderReferenceId)
  const { isLoading: companyConfigurationsLoading } = useGetCompanyConfigurations(
    states?.dealerInfo?.company_id
  )
  const queryClient = useQueryClient()

  useEffect(() => {
    queryClient.invalidateQueries({
      predicate: (query) => query?.queryKey?.includes('VehicleData') || query?.queryKey?.includes('Order')
    })
  }, [])

  const { isLoading: dealerDataLoading } = useGetDealerData(
    states?.dealerInfo.dealer_code,
    states?.dealDesk?.stateCode,
    states?.dealerProfileData?.available_finance_types
  )
  const { mutate: getOrderSubmissionRecord } = useGetOrderSubmissionResponse()
  useEffect(() => {
    if (Object.keys(orderData ?? {}).length > 0) {
      if (state?.submission_id) {
       const lenderId = (orderData as any)?.order_submissions?.find(
          (item: any) => item.id === state?.submission_id
        )?.lender_id
        const selectedLenderCode = states.lendersData?.find((st) => st.id === lenderId)?.external_code
        dispatch({
          type: DeskingActionTypes.SELECTED_LENDER,
          payload: { lenderId: lenderId, lenderCode: selectedLenderCode }
        })
      }
      dispatch({
        type: DeskingActionTypes.MAP_ORDER_TO_STORE,
        payload: orderData
      })
    }
  }, [orderData])

  useEffect(() => {
    if (
      vehiclesData &&
      states?.dealerInfo?.id &&
      customer &&
      (customer as ICustomer)?.reference_id
    ) {
      const vehicleObject = Array.from(vehiclesData?.values())?.[0]?.vehicle

      if (vehicleObject && Object.keys(vehicleObject).length > 0) {
        let lead = mapPayload(
          states?.dealerInfo?.id,
          (customer as ICustomer)?.reference_id,
          LeadKey.Vehicle_Detail,
          {
            ...vehicleObject,
            lead_source: 'Lane',
            status: LeadStatus.Active,
            deal_id: order?.reference_number,
            deal_status: order?.status,
            down_payment_value: order?.down_payment_value,
            contract_term: order?.contract_term,
            apr: order?.apr,
            net_finance_amount: order?.net_finance_amount,
            estimated_monthly_payment: order?.estimated_monthly_payment,
            finance_type: order?.finance_type,
            monthly_sales_use_tax: order?.monthly_sales_use_tax,
            dealer_name: states?.dealerInfo?.dealer_name,
            order_tradein: order?.order_tradein,
            order_options: order?.order_options,
            order_fees: order?.order_fees,
            due_at_signing: order?.due_at_signing,
            tax_amount: order?.tax_amount,
            capitalized_cost_reduction: order?.capitalized_cost_reduction,
            finance_amount: order?.finance_amount,
            rebate: order?.rebate,
            security_deposit: order?.security_deposit,
            annual_usage: order?.annual_usage,
            selling_price: order?.selling_price
          }
        )
        updateLead(lead)
      }
    }
  }, [vehiclesData, customer])

  useEffect(() => {
    if (
      tradeIn &&
      Object.keys(tradeIn).length > 0 &&
      customer &&
      (customer as ICustomer)?.reference_id
    ) {
      const lead = mapPayload(
        states?.dealerInfo?.id,
        (customer as ICustomer)?.reference_id,
        LeadKey.Trade_In,
        tradeIn as IOrderTradeIn,
        LeadKey.Customer_Detail
      )
      updateLead(lead)
    }
  }, [states?.dealerInfo?.id, tradeIn, customer])

  useEffect(() => {
    if (
      selection &&
      Object.keys(selection ?? {}).length > 0 &&
      customer &&
      (customer as ICustomer)?.reference_id
    ) {
      const lead = mapPayload(
        states?.dealerInfo?.id,
        (customer as ICustomer)?.reference_id,
        selection?.finance_type?.toLowerCase(),
        order,
        LeadKey.Customer_Detail
      )
      updateLead(lead)
    }
  }, [selection, customer])

  useEffect(() => {
    if (state?.submission_id) {
      getOrderSubmissionRecord(
        { submission_id: state?.submission_id },
        {
          onSuccess: (orderSubmission: any) => {
            if (orderSubmission && orderSubmission?.decision?.tier) {
              dispatch({
                type: DeskingActionTypes.ADD_SUBMISSION_TIER,
                payload: orderSubmission?.decision?.tier
              })
            }
          }
        }
      )
    }
  }, [state?.submission_id])

  if (companyConfigurationsLoading || dealerDataLoading || _orderLoading) {
    return (
      <Box theme={theme} className="absolute-loader">
        <CircleLoader theme={theme} size="lg" />
      </Box>
    )
  }
  return (
    <LayoutWithSideNav theme={theme}>
      {orderReferenceId &&
      order?.order_stage === 'Application' &&
      !state?.skipReservation &&
      !state?.submission_id &&
      order?.status !== Status.PendingConfirmation ? (
        <Customer />
      ) : (
        <DeskingWrap theme={theme} className="desking-wrap" mt={1} mb={16}>
          <Grid theme={theme} container columnSpacing={2} rowSpacing={1}>
            <Grid theme={theme} item xs={12} pt={0}>
              <AddVtc />
            </Grid>
            {vehiclesForComparison !== VehiclesForComparisonTypes.MULTIPLE ? (
              <Grid theme={theme} item lg={2} md={3} sm={4}>
                <OrderDetails orderReferenceId={orderReferenceId} />
              </Grid>
            ) : null}
            <Grid theme={theme} item xs>
              <CalculationSection />
            </Grid>
          </Grid>
        </DeskingWrap>
      )}
    </LayoutWithSideNav>
  )
}

export default Desking
