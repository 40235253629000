import { Fragment, useEffect, useState } from 'react'
import { Link, useTheme } from '@mui/material'
import { DetailCard } from 'components'
import {
    Typography,
    Grid
} from '@ntpkunity/controls'
import { IRebate, IRebateDocument } from '@models'
import AddRebateDocumentControl from 'controls/rebates-control/add-document'
import { capitalizeWords, formatCurrency } from '@helpers/methods'
import { useStoreContext } from '@store/storeContext'
import { RebateTypes } from '@helpers/enums'

interface IRebateDisplayProps extends IRebate {
    name: string
    amount: string
}

const RebateDetails = ({ rebates, order_id, hideDetails = false }: { rebates: IRebateDisplayProps[], order_id: number, hideDetails?: boolean }) => {
    const theme = useTheme()
    const { states: { dealDesk: { defaultCurrency } } } = useStoreContext()
    const [allRebates, setAllRebates] = useState<IRebateDisplayProps[]>(rebates)
    const [rebate, setRebate] = useState<IRebateDisplayProps | undefined>(undefined)

    useEffect(() => {
        if (Array.isArray(rebates)) {
            setAllRebates(rebates)
        }
    }, [rebates])

    const handleClose = () => setRebate(undefined)
    const handleSaveDocuments = (documents: IRebateDocument[], rebate_id: number | undefined) => {
        setAllRebates(allRebates?.map(item => {
            if (item.rebate_id === rebate_id) return {
                ...item,
                documents: documents
            }

            return item
        }))
    }
    return (
        <>
            <DetailCard className="detail-card" mb={2}>
                <Typography theme={theme} component="h4" variant="h4" mb={2}>
                    Rebates
                </Typography>
                <Grid theme={theme} className="container" container>
                    {allRebates?.map((item: IRebateDisplayProps, key: number) => {
                        return <Fragment key={key}>
                            <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                                <Typography theme={theme} variant="body2" component="h3" className="label">
                                    {capitalizeWords(item.rebate_name ?? item.name)}
                                </Typography>
                            </Grid>
                            <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
                                <Typography theme={theme} variant="body2" component="span">
                                    {defaultCurrency?.symbol ?? ''}{formatCurrency(Number(item.rebate_amount ?? item.amount))}
                                    {' '}
                                    {(!hideDetails && item.type !== RebateTypes.Lender) && (
                                        <>
                                            {'- '}
                                            <Link className="link hover-underline" onClick={() => setRebate({
                                                ...item,
                                                rebate_name: item.name ?? item.rebate_name
                                            })}>
                                                {(item.documents ?? [])?.length > 0 ? 'View Details' : 'Add Documents'}
                                            </Link>
                                        </>
                                    )}
                                </Typography>
                            </Grid>
                        </Fragment>
                    })}
                </Grid>
            </DetailCard>
            <AddRebateDocumentControl
                rebate={rebate}
                order_id={order_id}
                handleClose={handleClose}
                handleSaveDocumentsCallBack={handleSaveDocuments}
            />
        </>
    )
}

export default RebateDetails
