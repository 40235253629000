import { useTheme } from "@mui/material"
import { Dialog, Typography } from "@ntpkunity/controls"

interface ISubmissionErrors {
    selectedLender: {
        lender_name: string;
        status: string
    }
    openComments: boolean
    handleClose: () => void
    creditMessages: string[]
}

const SubmissionErrors = ({ selectedLender, openComments, handleClose, creditMessages }: ISubmissionErrors) => {
    const theme = useTheme()
    return <Dialog
        theme={theme}
        title={`${selectedLender?.lender_name} - ${selectedLender?.status}`}
        size="lg"
        open={openComments}
        disablePortal
        onCloseDialog={handleClose}
        noFooter
        children={
            <>
                <Typography theme={theme} variant="subtitle2" component={'div'} children="Comments" />
                <Typography
                    theme={theme}
                    variant="body2"
                    className="text-muted"
                    component={'ul'}
                    children={
                        <>
                            {creditMessages?.map((message: string, index: number) => (
                                <li key={index}>{message}</li>
                            ))}
                        </>
                    }
                />
            </>
        }
    />
}

export default SubmissionErrors
