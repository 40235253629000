import { useUploadImage } from "@apis/image-upload.service"
import { useUpdateOrderRebateDocument } from "@apis/order-management.service"
import { ConfirmationDialog } from "@components"
import { DialogMessages } from "@helpers/enums"
import { handleImageUpload } from "@helpers/methods"
import { IRebate, IRebateDocument } from "@models"
import { useTheme } from "@mui/material"
import { Button, Dialog, FileDragDrop, Typography, FileUploadPreview, Icon } from "@ntpkunity/controls"
import { FileUploadType } from "@ntpkunity/controls/dist/cjs/components/FileUploadPreview/FileUploadPreview.type"
import { ChangeEvent, DragEvent, useEffect, useState } from "react"

interface IRebateDocumentProps {
    rebate: IRebate | undefined
    order_id?: number
    handleClose: () => void
    handleSaveDocumentsCallBack: (documents: IRebateDocument[], rebate_id: number | undefined) => void
}

const AddRebateDocumentControl = ({ rebate, order_id, handleClose, handleSaveDocumentsCallBack }: IRebateDocumentProps) => {
    const theme = useTheme()
    const [documents, setDocuments] = useState<IRebateDocument[]>([])
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<number | undefined>(undefined)

    const { mutate: uploadDocuments } = useUploadImage()
    const { mutate: updateOrderDocuments } = useUpdateOrderRebateDocument()

    useEffect(() => {
        if (rebate?.documents) setDocuments(rebate.documents)
    }, [rebate])

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        const files = event.target.files

        if (files && files?.length > 0) handleDragAndUploadImageUploader(files)
    }

    const handleDragUpload = (event: DragEvent<HTMLElement>) => {
        event.preventDefault()
        const files = event.dataTransfer.files

        if (files?.length > 0) handleDragAndUploadImageUploader(files)
    }

    const handleDragAndUploadImageUploader = async (files: FileList) => {
        let incomingFiles = Array.from(files)
        const acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf']
        incomingFiles = incomingFiles?.filter(file => acceptedTypes.includes(file.type))
        if (incomingFiles?.length > 0) {
            const images = await handleImageUpload(incomingFiles)
            setDocuments([...documents, ...images])
        }
    }

    const handleRemoveFile = (_key: string, _file: IRebateDocument, index: number) => setShowDeleteConfirmation(index)
    const handleRemoveFileConfirmation = () => {
        setDocuments(documents?.filter((_: any, i: number) => i !== showDeleteConfirmation))
        setShowDeleteConfirmation(undefined)
    }

    const handleModalClose = () => {
        setDocuments([])
        handleClose()
    }

    const handleSaveDocuments = async () => {
        if (Array.isArray(documents)) {
            const payload = documents?.map((item: IRebateDocument) => {
                if (item.key) return
                return {
                    document: item.location?.split(',')[1],
                    content_type: item.content_type,
                    name: item.name,
                    file_size: `${(item.size / 1024).toFixed(2)}KB`,
                    created_by: item.created_by
                }
            })?.filter(item => item !== undefined)

            if (payload.length > 0) uploadDocuments(payload)
            if (!order_id) {
                handleSaveDocumentsCallBack([...documents], rebate?.rebate_id ?? rebate?.id)
                setDocuments([])
            } else {
                const docWithoutLocation = documents?.map(item => {
                    const { location, ...doc } = item
                    return doc
                })
                updateOrderDocuments({ order_id, rebate_id: rebate?.rebate_id, documents: docWithoutLocation })
                handleSaveDocumentsCallBack([...documents], rebate?.rebate_id)
                handleClose()
            }
        }
    }

    const onViewFile = (_index: number, file: { file: IRebateDocument }) => {
        if (file?.file?.content_type?.includes('image')) return
        if (file?.file?.content_type === 'application/pdf') {
            const pdfWindow = window.open()
            pdfWindow?.document.write(
                `<iframe width="100%" height="100%" src="${file?.file?.location}" frameborder="0"></iframe>`
            )
            pdfWindow?.document.close()
        }
    }

    return <>
        {(showDeleteConfirmation === 0 || !!showDeleteConfirmation)
            ? <ConfirmationDialog
                openPopUp={showDeleteConfirmation === 0 || !!showDeleteConfirmation}
                confirmationTitle={DialogMessages.removeCoAPplicantSecondaryText}
                primaryBtnText={DialogMessages.fniAndMspRemoveBtnText}
                icon={<Icon className="alert-icon" name="DialogAlertIcon" />}
                hideCancelButton={false}
                setPopUpState={() => { }}
                onConfirm={handleRemoveFileConfirmation}
                onCancel={() => setShowDeleteConfirmation(undefined)}
            />
            : <Dialog
                theme={theme}
                title="Add Documents"
                size="sm"
                open={!!rebate}
                onCloseDialog={handleModalClose}
                customFooter={
                    <>
                        <Button theme={theme} secondary text={'Cancel'} onClick={handleModalClose} />
                        <Button theme={theme} primary text="Save Details" onClick={handleSaveDocuments} />
                    </>
                }
                children={
                    <>
                        <Typography component={"div"} variant="caption" theme={theme} textAlign={'center'} mb={2}
                            children={
                                <>
                                    Please upload eligibility document(s) of the customer for <b> {rebate?.rebate_name}</b>
                                </>
                            }
                        />
                        <FileDragDrop
                            theme={theme}
                            accept=".jpg,.jpeg,.png,.pdf"
                            hoverLabel={<>Drag and drop image file, or <span className='text-primary'>browse</span></>}
                            allowMultiple={true}
                            onChange={handleFileUpload}
                            onDrop={handleDragUpload} />
                        <FileUploadPreview
                            files={documents?.map((item: IRebateDocument, index: number) => {
                                return {
                                    key: index,
                                    file: {
                                        ...item,
                                        type: item.content_type
                                    },
                                    viewIcon: true,
                                    preview: 'fileIcon',
                                    onRemoveFile: handleRemoveFile,
                                    onViewFile: onViewFile
                                }
                            }) as unknown as FileUploadType[]}
                            theme={theme}
                        />
                    </>
                }
            />
        }
    </>
}

export default AddRebateDocumentControl
