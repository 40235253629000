import { styled } from '@mui/material'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const TableListWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.table-list-wrap': {
    '.table-list-ul': {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
      '.table-list-li': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '8px',
        borderBottom: '1px solid' + theme.palette.grey[50],
        minHeight: 32,
        whiteSpace: 'nowrap',
        '.label-col': {
          width: '60%'
        },
        '.value-col, .editable-col': {
          width: '40%'
        },
        '.editable-col': {
          '.auto-search': {
            'span.MuiTypography-body2': {
              display: 'none'
            },
            '.btn-toggle': {
              '.MuiButtonBase-root': {
                color: theme.palette.primary.main,
                fontSize: theme.typography.caption.fontSize,
                fontWeight: theme.typography.fontWeightRegular,
                '.btn-label': {
                  width: 'calc(100% - 16px)',
                  textAlign: 'right'
                },
                svg: {
                  width: 16,
                  height: 16,

                  path: {
                    stroke: theme.palette.grey[300]
                  }
                }
              }
            },
            '.list-wrap': {
              width: 200,
              marginTop: 4,
              '.u-custom-autocomplete .MuiAutocomplete-root .u-form-group': {
                width: '100%',
                float: 'none',
                '.MuiInputBase-root': {
                  height: 32,
                  '.MuiInputBase-input': {
                    padding: '7px 12px 7px 12px !important',
                    fontSize: theme.typography.caption.fontSize,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    maxWidth: '83%'
                  }
                },
                '.MuiAutocomplete-endAdornment': {
                  top: 'calc(50% - 12px)',
                  '.MuiButtonBase-root': {
                    svg: {
                      width: 16,
                      height: 16
                    }
                  }
                }
              },
              '.MuiAutocomplete-popper': {
                textAlign: 'left',
                width: '100% !important',
                '.MuiPaper-root .MuiAutocomplete-listbox .MuiAutocomplete-option, .MuiAutocomplete-noOptions':
                  {
                    fontSize: theme.typography.caption.fontSize,
                    padding: 8,
                    lineHeight: 'normal',
                    textWrap: 'wrap'
                  }
              }
            }
          },
          '.u-form-group': {
            width: 85,
            float: 'right',
            '.MuiFormControl-root': {
              '.u-form-control, .u-form-control.u-select': {
                height: 24,
                '.MuiInputBase-input': {
                  padding: '3px 4px 3px 4px !important',
                  textAlign: 'right'
                },
                '.MuiSelect-icon': {
                  right: 0,
                  width: 16,
                  height: 16
                },
                '.MuiPaper-root.MuiMenu-paper': {
                  top: '38px !important',
                  minWidth: '250px !important ',
                  '.MuiList-root.MuiMenu-list': {
                    '.MuiButtonBase-root.u-dropdown-item': {
                      padding: 8,
                      fontSize: theme.typography.caption.fontSize,
                      textAlign: 'left'
                    }
                  }
                }
              },
              '.MuiInputBase-adornedStart': {
                '.adornment-icon': {
                  marginRight: '2px !important'
                },
                '.MuiInputBase-input': {
                  padding: '3px 4px 3px 2px !important'
                }
              },
              '.MuiInputBase-adornedEnd': {
                '.adornment-icon': {
                  marginLeft: '2px !important'
                },
                '.MuiInputBase-input': {
                  padding: '3px 2px 3px 4px !important'
                }
              },
              '.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderWidth: 1
              }
            },
            '&.u-date-picker': {
              width: 85,
              float: 'right',
              '.MuiFormControl-root .MuiInputBase-adornedEnd .MuiInputBase-input': {
                textAlign: 'right',
                padding: '3px 4px 3px 2px !important'
              }
            }
          },
          '&.no-bg': {
            '.u-form-group': {
              width: 'auto',
              '.MuiFormControl-root': {
                position: 'relative',
                top: 2,
                '.u-form-control, .u-form-control.u-select': {
                  height: 13,
                  backgroundColor: 'transparent',
                  '.MuiInputBase-input': {
                    padding: '0 16px 0 0',
                    fontSize: theme.typography.caption.fontSize,
                    color: theme.palette.primary.main
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    border: '0 solid transparent',
                    borderRadius: 0
                  }
                }
              }
            }
          },
          '.btn.btn-dialog': {
            padding: 0,
            float: 'right',
            width: 16,
            height: 16,
            position: 'relative',
            top: 4
          }
        }
      }
    },
    '&.with-bg': {
      backgroundColor: theme.palette.common.white,
      borderRadius: 4,
      padding: '0 12px',
      '.table-list-ul .table-list-li .editable-col': {
        '.u-form-group .MuiFormControl-root': {
          '.u-form-control.u-select': {
            marginTop: 2,
            backgroundColor: 'transparent',
            '.MuiInputBase-input': {
              paddingRight: '17px !important'
            },
            '.MuiSelect-icon': {
              top: 5
            },
            '&:hover, &.Mui-focused': {
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent !important'
              }
            }
          }
        }
      }
    }
  }
}))
