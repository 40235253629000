import * as yup from 'yup'
import { Employment_Type } from '@helpers/enums'
import { ZIP_CODE_REGEX_MAP } from '@helpers/constants'

export function createEmploymentValidationSchema(permissions: any, isUsAddress: boolean) {
  const isEmployment = (type: string) =>
    type === Employment_Type.Employed || type === Employment_Type.Self_Employed
  const schema = yup.object().shape({
    employment_type: yup.string().when('$a', {
      is: () => permissions?.employmentDetailType,
      then: yup.string().nullable().required('Employment Type is required'),
      otherwise: yup.string().nullable().notRequired()
    }),
    name: yup
      .string()
      .nullable()
      .when('employment_type', {
        is: isEmployment, // Check if employment_type matches the condition
        then: yup.string().when('$a', {
          is: () => permissions?.employmentDetailName, // Check if permission exists
          then: yup
            .string()
            .nullable()
            .required('Employer Name is required')
            .max(60, 'Employer Name maximum length is up to 60 characters'),
          otherwise: yup.string().nullable().notRequired()
        }),
        otherwise: yup.string().nullable().notRequired()
      }),
    occupation: yup
      .string()
      .nullable()
      .when('employment_type', {
        is: isEmployment,
        then: yup.string().when('$a', {
          is: () => permissions?.employmentDetailOccupation,
          then: yup
            .string()
            .nullable()
            .required('Occupation is required')
            .max(60, 'Occupation maximum length is up to 60 characters'),
          otherwise: yup.string().nullable().notRequired()
        }),
        otherwise: yup.string().nullable().notRequired()
      }),
    employed_since: yup
      .string()
      .nullable()
      .when('employment_type', {
        is: isEmployment,
        then: yup.string().when('$a', {
          is: () => permissions?.employmentDetailSince,
          then: yup
            .string()
            .nullable()
            .required('Employed Since Date is required')
            .matches(/^(0[1-9]|1[0-2])\d{4}$/, 'Employed Since Date should be in MM/YYYY format'),
          otherwise: yup.string().nullable().notRequired()
        }),
        otherwise: yup.string().nullable().notRequired()
      }),
    annual_employment_income: yup
      .number()
      .transform((originalValue) => {
        return isNaN(originalValue) ? undefined : Number(originalValue)
      })
      .nullable()
      .when('employment_type', {
        is: isEmployment, // when employment_type matches the condition
        then: yup
          .number() // Ensure the schema type is always number
          .transform((originalValue) => {
            return isNaN(originalValue) ? undefined : Number(originalValue)
          })
          .when('$a', {
            is: () => permissions?.employmentDetailAnnualEmploymentIncome, // check permission
            then: yup
              .number() // Apply number schema here for required value
              .required('Annual Employment Income is required'),
            otherwise: yup
              .number() // When permission is not set, keep it a nullable number
              .nullable()
              .notRequired()
          }),
        otherwise: yup
          .number() // Keep it as number when employment_type is not matching
          .nullable()
          .notRequired()
      }),
    annual_other_income: yup
      .number()
      .transform((originalValue) => {
        return isNaN(originalValue) ? undefined : Number(originalValue)
      })
      .nullable()
      .required('Annual Other Income is required'),
    source_of_other_income: yup.string().when('annual_other_income', {
      is: (annualOtherIncome: number) => annualOtherIncome > 0,
      then: yup
        .string()
        .nullable()
        .required('Source of Other Income is required')
        .max(100, 'Source of Other Income maximum length is up to 100 characters'),
      otherwise: yup
        .string()
        .nullable()
        .max(100, 'Source of Other Income maximum length is up to 100 characters')
    }),
    address: yup
      .object()
      .shape({
        address_line_1: yup.string().nullable(),
        city: yup.string().nullable(),
        state_name: yup.string().nullable(),
        state_id: yup.string().nullable(),
        zip_code: yup.string().nullable()
      })
      .when('employment_type', {
        is: isEmployment,
        then: yup.object().shape({
          address_line_1: yup.string().when('$a', {
            is: () => permissions?.employmentDetailsAddressLine1,
            then: yup.string().nullable().required('Address Line 1 is required'),
            otherwise: yup.string().nullable().notRequired()
          }),
          city: yup.string().when('$a', {
            is: () => permissions?.employmentDetailsAddressCity,
            then: yup.string().nullable().required('City is required'),
            otherwise: yup.string().nullable().notRequired()
          }),
          state_name: yup.string().when('$a', {
            is: () => permissions?.employmentDetailsAddressState,
            then: yup.string().nullable().required('State Name is required'),
            otherwise: yup.string().nullable().notRequired()
          }),
          state_id: yup.string().when('$a', {
            is: () => permissions?.employmentDetailsAddressState,
            then: yup.string().nullable().required('State Name is required'),
            otherwise: yup.string().nullable().notRequired()
          }),
          zip_code: yup.string().when('$a', {
            is: () => permissions?.employmentDetailsAddressZipCode,
            then: yup
              .string()
              .nullable()
              .test(function (value) {
                if (!value) {
                  return this.createError({ message: 'Zip Code is required' })
                }

                if (isUsAddress && !ZIP_CODE_REGEX_MAP.US.regex.test(value)) {
                  return this.createError({ message: ZIP_CODE_REGEX_MAP.US.errorMessage })
                }

                return true
              }),
            otherwise: yup.string().nullable().notRequired()
          })
        })
      })
  })

  return schema
}
