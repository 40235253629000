import { FC, useState, useEffect } from 'react'
import { Icon, Box, DataTable, Input, Button, Menu, Checkbox } from '@ntpkunity/controls'
import { useTheme, TablePagination } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { DataTableWrap } from '@components'
import FIPriceDialog from './products-rate-dialog'
import FAndISupplier from './../finance-insurance-supplier/finance-insurance-supplier'

interface FAndIData {
    result?: any[]
    page_size?: number
    page_number?: number
}

const FiProductsTable: FC<{
    defaultCurrency?: string
    data?: any,
    setAddedItemPrices?: any
    addedItemPrices?: any
    setColumnFilters?: any
    filters?: any
    setFilters?: any
    financeRates?: any
    leaseRates?: any
    selectedLeaseItems?: any
    selectedFinanceItems?: any
    setSelectedLeaseItems?: any
    setSelectedFinanceItems?: any
    activeTab: 0 | 1,
    terms?: any
    draftItemPrices?: any
    setDraftItemPrices?: any

}> = ({
    financeRates,
    leaseRates,
    selectedFinanceItems,
    selectedLeaseItems,
    setSelectedLeaseItems,
    data,
    setFilters,
    setSelectedFinanceItems,
    activeTab,
    defaultCurrency,
    setColumnFilters,
    terms,
    filters,
    addedItemPrices,
    setAddedItemPrices,
    draftItemPrices,
    setDraftItemPrices
}) => {
        const theme = useTheme()
        const [rates, setRates] = useState<any>([])
        const [isOpen, setIsOpen] = useState(false)
        const [cardDetails, setCardDetails] = useState()
        const [pageSize, setPageSize] = useState<number>(5)
        const [_pageNumber, setPageNumber] = useState<number>(0)
        const [openProductDialog, setOpenProductDialog] = useState(false)
        const [fAndIDat, setFAndIData] = useState<FAndIData>({ result: [], page_size: 10, page_number: 1 })
        const isAdded = (id: any) => id in addedItemPrices

        useEffect(() => {
            if (activeTab == 0 && financeRates) {
                setRates(financeRates)
                setFAndIData(data)
            } else if (activeTab == 1 && leaseRates) {
                setRates(leaseRates)
                setFAndIData(data)
            }
        }, [activeTab, financeRates, leaseRates])

        const handleCheckboxChange = (product: any) => {
            if (isAdded(product?.id)) {
                const fAndIID = product?.id
                setAddedItemPrices((prevPrices: any) => {
                    const newPrices = { ...prevPrices }
                    delete newPrices[fAndIID]
                    return newPrices
                })
            } else {
                const fAndIID = product?.id
                const selectedObject = activeTab == 0 ? selectedFinanceItems : selectedLeaseItems
                const foundItem = selectedObject.find((x: any) => x.id == fAndIID)
                const price = foundItem?.price
                setAddedItemPrices((prevPrices: any) => ({
                    ...prevPrices,
                    [fAndIID]: price ?? 0
                }))
            }
        }

        const getFAndIRetailPrice = (cardData: any) => {
            let smallestRetailPrice = Infinity
            let rate_id = ''
            let form_id = ''

            for (let i = 0; i < rates?.length; i++) {
                if (rates?.[i]?.product_id == cardData?.product_id) {
                    cardData.session_id = rates?.[i]?.session_id
                    let getCoveragesWithMatchedTerm = rates?.[i]?.coverages?.coverage?.filter(
                        (x: any) => x?.term_months == terms
                    )
                    getCoveragesWithMatchedTerm?.forEach((item: any) => {
                        const retailPrice = item?.deductibles?.deductible?.retail_price
                        if (retailPrice < smallestRetailPrice) {
                            smallestRetailPrice = retailPrice
                            rate_id = item?.deductibles?.deductible?.rate_id
                            form_id = item?.form?.form_id
                        }
                    })
                }
            }
            if (activeTab == 0 && !selectedFinanceItems.some((x: any) => x.id == cardData?.id)) {
                setSelectedFinanceItems([
                    ...selectedFinanceItems,
                    {
                        ...cardData,
                        price: smallestRetailPrice === Infinity ? 0 : smallestRetailPrice,
                        rate_id: rate_id,
                        form_id: form_id
                    }
                ])
            } else if (activeTab == 1 && !selectedLeaseItems.some((x: any) => x.id == cardData?.id)) {
                setSelectedLeaseItems([
                    ...selectedLeaseItems,
                    {
                        ...cardData,
                        price: smallestRetailPrice === Infinity ? 0 : smallestRetailPrice,
                        rate_id: rate_id,
                        form_id: form_id
                    }
                ])
            }
            return smallestRetailPrice === Infinity
                ? '-'
                : `${defaultCurrency}${smallestRetailPrice.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                })}`
        }

        const getCoverage = (cardData: any) => {
            const item = Array.isArray(rates)
                ? rates.find((rate: any) => rate?.product_id === cardData?.product_id && rate?.coverages)
                : null
            if (item) {
                const termMonths = item?.coverages?.coverage[0]?.term_months
                const termMiles = item?.coverages?.coverage[0]?.term_miles
                const termMonthsWithLabel = termMonths ? `${termMonths} Months, ` : ''
                const termMilesWithLabel = termMiles ? `${termMiles?.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0
                })} Miles` : ''
                return { termMonths: termMonthsWithLabel, termMiles: termMilesWithLabel }
            }

            return {}
        }

        const handleProductDetailsView = (cardData: any) => {
            setOpenProductDialog(true)
            const selectedObject = activeTab == 0 ? selectedFinanceItems : selectedLeaseItems
            const finalObj = selectedObject?.filter((x: { id: any }) => x?.id == cardData?.id)
            setCardDetails(finalObj[0])
        }

        const handleProductEditView = (cardData: any) => {
            setIsOpen(true)
            const selectedObject = activeTab == 0 ? selectedFinanceItems : selectedLeaseItems
            const finalObj = selectedObject?.filter((x: { id: any }) => x?.id == cardData?.id)
            setCardDetails(finalObj[0])
        }

        const handleCloseProductDetails = () => {
            setOpenProductDialog(false)
        }

        const selectedObject = activeTab == 0 ? selectedFinanceItems : selectedLeaseItems
        const selectedAllFnis = Array.isArray(selectedObject) && selectedObject?.length > 0 &&
            selectedObject?.every((item: any) => isAdded(item?.id))

        const handleSelectAll = () => {
            const visibleFnis = selectedObject.map((item: any) => ({
                id: item?.id,
                price: item?.price
            }))

            if (selectedAllFnis) {
                const deselectedIds = visibleFnis?.map((item: any) => item?.id)
                setAddedItemPrices((prevPrices: any) => {
                    const newPrices = { ...prevPrices }
                    deselectedIds?.forEach((id: any) => delete newPrices[id])
                    return newPrices
                })
            } else {
                const newAddedItemPrices = visibleFnis?.reduce((acc: any, item: any) => {
                    if (!isAdded(item?.id)) {
                        acc[item?.id] = item?.price ?? 0
                    }
                    return acc
                }, {})

                setAddedItemPrices((prevPrices: any) => ({
                    ...prevPrices,
                    ...newAddedItemPrices
                }))
            }
        }

        const handleChangePage = (_event: unknown, newPage: number) => {
            setPageNumber(newPage)
            setColumnFilters(setQueryString(newPage, pageSize))
        }

        const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
            setPageSize(+event.target.value)
            setPageNumber(0)
            setColumnFilters(setQueryString(0, +event.target.value))
        }

        const setQueryString = (pgNo: number, pgSize: number): any => {
            let query_string: string = `&page_number=${pgNo}&page_size=${pgSize}`
            if (filters?.productNameFilter?.trimStart() != null && filters?.productNameFilter?.trimStart() != '') {
                query_string = query_string.concat(`&product_name=${filters?.productNameFilter}`)
            }

            if (filters?.categoryFilter?.trimStart() != null && filters?.categoryFilter?.trimStart() != '') {
                query_string = query_string.concat(`&category=${filters?.categoryFilter}`)
            }

            if (filters?.providerFilter?.trimStart() != null && filters?.providerFilter?.trimStart() != '') {
                query_string = query_string.concat(`&provider_name=${filters?.providerFilter}`)
            }

            if (filters?.productTypeFilter?.trimStart() != null && filters?.productTypeFilter?.trimStart() != '') {
                query_string = query_string.concat(`&product_type=${filters?.productTypeFilter}`)
            }

            if (filters?.priceFilter?.trimStart() != null && filters?.priceFilter?.trimStart() != '') {
                query_string = query_string.concat(`&price=${filters?.priceFilter}`)
            }

            if (filters?.coverageFilter?.trimStart() != null && filters?.coverageFilter?.trimStart() != '') {
                query_string = query_string.concat(`&provider_name=${filters?.coverageFilter}`)
            }

            return query_string
        }

        const handleFilter = () => {
            setPageNumber(0)
            setColumnFilters(setQueryString(0, pageSize))
        }
        return (
            <>
                <DataTableWrap theme={theme} className="table-pagination table-dialog" mt={2}>
                    <Box theme={theme} className="scroll">
                        <Box theme={theme} className="scroll-hide sh-fixed-cell spr-border" />
                    </Box>
                    <DataTable
                        theme={theme}
                        variant="basic"
                        theadChildren={
                            <>
                                <TableRow>
                                    <TableCell className="checkbox-cell fixed-cell">
                                        <Checkbox
                                            label=""
                                            theme={theme}
                                            onChange={handleSelectAll}
                                            checkBoxChecked={selectedAllFnis}
                                        />
                                    </TableCell>
                                    <TableCell className="img-cell">
                                        <Icon name="ImageIcon" />
                                    </TableCell>
                                    <TableCell id="productName">Product Name</TableCell>
                                    <TableCell id="category">Category</TableCell>
                                    <TableCell id="provider">Provider</TableCell>
                                    <TableCell id="productType">Product Type</TableCell>
                                    <TableCell id="price">Price</TableCell>
                                    <TableCell id="coverage">Coverage</TableCell>
                                    <TableCell className="action-cell fixed-cell"></TableCell>
                                </TableRow>
                                <TableRow className="filters-row">
                                    <TableCell className="checkbox-cell"></TableCell>
                                    <TableCell className="img-cell"></TableCell>
                                    <TableCell>
                                        <Box theme={theme} className="table-filter">
                                            <Input
                                                theme={theme}
                                                fullWidth={true}
                                                placeholder={'Search...'}
                                                type="text"
                                                id={'productName'}
                                                onChange={(value: string) => {
                                                    setFilters({ ...filters, productNameFilter: value })
                                                }}
                                                onBlur={() => {
                                                    handleFilter()
                                                }}
                                                value={filters?.productNameFilter?.trimStart()}
                                            />
                                            <Button
                                                theme={theme}
                                                defaultBtn
                                                id="productNameFilter"
                                                iconText={<Icon name="IconFilter" />}
                                            ></Button>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box theme={theme} className="table-filter">
                                            <Input
                                                theme={theme}
                                                fullWidth={true}
                                                placeholder={'Search...'}
                                                type="text"
                                                id={'category'}
                                                onChange={(value: string) => {
                                                    setFilters({ ...filters, categoryFilter: value })
                                                }}
                                                onBlur={() => {
                                                    handleFilter()
                                                }}
                                                value={filters?.categoryFilter?.trimStart()}
                                            />
                                            <Button
                                                theme={theme}
                                                defaultBtn
                                                id="categoryFilter"
                                                iconText={<Icon name="IconFilter" />}
                                            ></Button>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box theme={theme} className="table-filter">
                                            <Input
                                                theme={theme}
                                                fullWidth={true}
                                                placeholder={'Search...'}
                                                type="text"
                                                id={'provider'}
                                                onChange={(value: string) => {
                                                    setFilters({ ...filters, providerFilter: value })
                                                }}
                                                onBlur={() => {
                                                    handleFilter()
                                                }}
                                                value={filters?.providerFilter?.trimStart()}
                                            />
                                            <Button
                                                theme={theme}
                                                defaultBtn
                                                id="providerFilter"
                                                iconText={<Icon name="IconFilter" />}
                                            ></Button>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box theme={theme} className="table-filter">
                                            <Input
                                                theme={theme}
                                                fullWidth={true}
                                                placeholder={'Search...'}
                                                type="text"
                                                id={'productType'}
                                                onChange={(value: string) => {
                                                    setFilters({ ...filters, productTypeFilter: value })
                                                }}
                                                onBlur={() => {
                                                    handleFilter()
                                                }}
                                                value={filters?.productTypeFilter?.trimStart()}
                                            />
                                            <Button
                                                theme={theme}
                                                defaultBtn
                                                id="productTypeFilter"
                                                iconText={<Icon name="IconFilter" />}
                                            ></Button>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box theme={theme} className="table-filter">
                                            <Input
                                                theme={theme}
                                                fullWidth={true}
                                                placeholder={'Search...'}
                                                type="text"
                                                id={'price'}
                                                onChange={(value: string) => {
                                                    setFilters({ ...filters, priceFilter: value })
                                                }}
                                                onBlur={() => {
                                                    handleFilter()
                                                }}
                                                value={filters?.priceFilter?.trimStart()}
                                                disabled
                                            />
                                            <Button
                                                theme={theme}
                                                defaultBtn
                                                id="priceFilter"
                                                disabled
                                                iconText={<Icon name="IconFilter" />}
                                            ></Button>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box theme={theme} className="table-filter">
                                            <Input
                                                theme={theme}
                                                fullWidth={true}
                                                placeholder={'Search...'}
                                                type="text"
                                                id={'coverage'}
                                                onChange={(value: string) => {
                                                    setFilters({ ...filters, coverageFilter: value })
                                                }}
                                                onBlur={() => {
                                                    handleFilter()
                                                }}
                                                value={filters?.coverageFilter?.trimStart()}
                                                disabled
                                            />
                                            <Button
                                                theme={theme}
                                                defaultBtn
                                                id="coverageFilter"
                                                iconText={<Icon name="IconFilter" />}
                                                disabled
                                            ></Button>
                                        </Box>
                                    </TableCell>
                                    <TableCell className="action-cell fixed-cell"></TableCell>
                                </TableRow>
                            </>
                        }
                        tbodyChildren={
                            <>
                                {fAndIDat?.result?.map((item: any, index: number) => (
                                    <TableRow className="child-tr" key={index}>
                                        <TableCell className="checkbox-cell fixed-cell">
                                            <Checkbox
                                                label=""
                                                theme={theme}
                                                checkBoxChecked={isAdded(item?.id)}
                                                onChange={() => handleCheckboxChange(item)}
                                            />
                                        </TableCell>
                                        <TableCell className="img-cell">
                                            <img
                                                className="prd-img"
                                                src={item?.image?.[0]?.location ?? require('../../../../src/public/assets/images/no-img.svg')}
                                                alt="Car"
                                            />
                                        </TableCell>
                                        <TableCell>{item?.product_name}</TableCell>
                                        <TableCell>{item?.category}</TableCell>
                                        <TableCell>{item?.provider_name}</TableCell>
                                        <TableCell>{item?.product_type}</TableCell>
                                        <TableCell className="editable-cell">
                                            <Box theme={theme} className="cell-content" justifyContent={'flex-end'}>
                                                <span>
                                                {
                                                    draftItemPrices?.[item?.id]
                                                    ? `${defaultCurrency}${(draftItemPrices?.[item?.id]).toLocaleString(undefined, {
                                                        maximumFractionDigits: 2,
                                                        minimumFractionDigits: 2
                                                        })}`
                                                    : getFAndIRetailPrice(item)
                                                }
                                                </span>
                                                {getFAndIRetailPrice(item) !== '-' && (
                                                    <Button
                                                        theme={theme}
                                                        defaultBtn
                                                        iconText={
                                                            <Icon
                                                                name="EditIcon"
                                                                onClick={() => handleProductEditView(item)}
                                                            />
                                                        }
                                                    />
                                                )}
                                            </Box>
                                        </TableCell>
                                        <TableCell>{item && getCoverage(item)?.termMonths} {item && getCoverage(item)?.termMiles}
                                        </TableCell>
                                        <TableCell className="action-cell fixed-cell">
                                            <Menu
                                                theme={theme}
                                                disablePortal
                                                options={[
                                                    {
                                                        optionText: 'View Details',
                                                        optionValue: 'view_details'
                                                    },
                                                    {
                                                        optionText: 'Edit Coverage',
                                                        optionValue: 'editCoverage'
                                                    }
                                                ]}
                                                handleOptionClick={(_event, _key, value) => {
                                                    switch (value) {
                                                        case 'view_details':
                                                            handleProductDetailsView(item)
                                                            break
                                                        case 'editCoverage':
                                                            handleProductEditView(item)
                                                            break
                                                        default:
                                                            break
                                                    }
                                                }}
                                                render={(onMenuSelection: any) => (
                                                    <Button
                                                        theme={theme}
                                                        defaultBtn
                                                        iconText={<Icon name="MoreIcon" />}
                                                        onClick={onMenuSelection}
                                                    />
                                                )}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left'
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right'
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </>
                        }
                    />
                    {data && (
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15, 20]}
                            component="div"
                            id="pagination"
                            count={data?.total_results ?? -1}
                            page={data?.page_number}
                            rowsPerPage={data?.page_size}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </DataTableWrap>
                {
                    openProductDialog ? (<FAndISupplier
                        details={cardDetails}
                        open={openProductDialog}
                        onBackBtn={handleCloseProductDetails}
                        defaultCurrency={defaultCurrency}
                    />
                    ) : ''
                }
                {
                    isOpen ? (
                        <FIPriceDialog
                            details={cardDetails}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            rates={rates}
                            selectDataObj={activeTab == 0 ? selectedFinanceItems : selectedLeaseItems}
                            selectDataState={activeTab == 0 ? setSelectedFinanceItems : setSelectedLeaseItems}
                            defaultCurrency={defaultCurrency}
                            setDraftItemPrices={setDraftItemPrices}
                            addedItemPrices={addedItemPrices}
                            setAddedItemPrices={setAddedItemPrices}
                        />)
                        : ''
                }
            </>
        )
    }

export default FiProductsTable
