import { FC, useEffect, useState } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { LayoutWithSideNav } from '@styles/layout'
import { useTheme, Link } from '@mui/material'
import {
  Button,
  Grid,
  Box,
  Input,
  Typography,
  Dialog,
  IBoxProps,
  IGridProps,
  Icon,
  PaginatedHeader,
  Select
} from '@ntpkunity/controls'
import { ResponsiveCarousel, Menu, VehicleCardUi } from 'components'
import { OrderState, QueryKeys } from '@helpers/enums'
import { validation } from '@helpers/methods'
import { ConsentStatus, DisclaimerNames, EMAIL_REGEX, PHONE_REGEX } from '@helpers/constants'
import { styled } from '@mui/system'
import { useModal } from 'react-modal-hook'
import CustomFilterControl from '@app/vehicle-selection/filter/filter-control'
import { useStoreContext } from '@store/storeContext'
import clsx from 'clsx'
import { useAddCustomerConsent, useCheckCustomerStatus } from '@apis/customer-management.service'
import { useUpdateCustomerContact } from '@apis/customer-detail.service'
import { useNavigate, useParams } from 'react-router-dom'
import { APP_ROUTES } from '@helpers/links'
import {
  useGetFilterReferenceData,
  useGetInventoryByFilters
} from '@apis/inventory-management.service'
import noImage from '@public/assets/images/no-img.svg'
import NotInventory from './not-inventory'
import NotFound from './not-found'
import { useQueryClient } from 'react-query'
import { CustomTheme } from '@styles/customTheme'
import { useDisclaimers } from '@hooks/useDisclaimer'
import { CUSTOMER_MANDATORY_DISCLAIMERS } from '@helpers/endpoints'
import { empty, mapPayload } from '@helpers/lead'
import { useUpdateLead } from '@hooks/lead-management'
import { LeadKey } from '@helpers/enums/lead'

const CustomGrid = styled(
  Grid,
  {}
)<Partial<IGridProps>>(() => ({
  '&.main-content-section': {
    marginTop: 8,
    position: 'relative'
  }
}))

const FilterControl = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.filters-control': {
    borderBottom: '1px solid' + theme.palette.grey[100]
  }
}))

const FilterWrap = styled(
  Grid,
  {}
)<Partial<IGridProps>>(({ theme }) => ({
  '&.filters-toggle-wrap': {
    transition: 'all 0.5s ease-in-out 0s',
    position: 'absolute',
    width: '100%',
    left: 0,
    '&.hide': {
      left: '-100%',
      [theme.breakpoints.down('md')]: {
        left: 0,
        position: 'absolute',
        transform: 'scale(0)'
      }
    },
    [theme.breakpoints.down('md')]: {
      backgroundColor: theme.palette.common.white,
      zIndex: 10,
      transform: 'scale(1)',
      position: 'relative'
    }
  }
}))

const RightPanel = styled(
  Grid,
  {}
)<Partial<IGridProps>>(({ theme }) => ({
  '&.right-content': {
    transition: 'all 0.5s ease-in-out 0s',
    marginLeft: '25%',
    '&.expand': {
      marginLeft: 0
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 0
    }
  }
}))

interface IFormInputs {
  customer_addresses: any
  email: string
  zipCode: string
  preferred_contact: string
  consent: boolean
  mobile_number: string
}

const VehicleSelection: FC<{ defaultOrderState?: OrderState }> = ({
  defaultOrderState = OrderState.Draft
}): any => {
  const theme = useTheme()

  let navigate = useNavigate()
  const { mutateAsync: addCustomerConsent } = useAddCustomerConsent()

  const [customerData, setCustomerData] = useState<any | null>()
  const { mutate: updateLead } = useUpdateLead()
  const {
    mutate: customerStatus,
    data: customerStatusData,
    error: customerStatusError,
    isLoading: customerStatusIsLoading
  } = useCheckCustomerStatus()
  const {
    states: { dealerAndLenderInfo }
  } = useStoreContext()

  const { mutate: getInventory, data: inventoryData } = useGetInventoryByFilters()
  const { mutate: getRefernceData, data: refernceData } = useGetFilterReferenceData()
  const { vin, customer_reference_id } = useParams()
  const [openFilter, setOpenFilter] = useState(true)
  const { mutate: updateCustomerByReferenceId } = useUpdateCustomerContact()
  const [orderState, setOrderState] = useState(defaultOrderState)
  const [selectedVehicle, setSelectedVehicle] = useState<any>(null)
  const [orderByState, setOrderByState] = useState<any>(null)
  const [defaultCurrency, setDefaultCurrency] = useState('')
  const { actions, states } = useStoreContext()
  const form: {
    handleSubmit: any
    control: any
    formState: any
    setError: any
    getValues: any
    setValue: any
    watch: any
    trigger: any
    clearErrors: any
  } = useForm<IFormInputs>({
    mode: 'onBlur'
  })
  const queryClient = useQueryClient()
  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])

  const preferredContactMethodPhoneOrText = form.watch('preferred_contact') === 'Mobile Phone'

  const { disclaimers } = useDisclaimers([
    DisclaimerNames.TERMS_AND_CONDITIONS,
    DisclaimerNames.CCPA,
    DisclaimerNames.TCPA
  ])

  const {
    dealerAndLenderInfo: { dealer_code }
  } = states

  const onSubmit: SubmitHandler<IFormInputs> = (data: IFormInputs) => {
    if (
      data?.preferred_contact != customerData?.preferred_contact ||
      data?.mobile_number != customerData?.mobile_number
    ) {
      updateCustomerByReferenceId({
        customer_profile: {
          updated_by: customerData?.email,
          preferred_contact: data?.preferred_contact,
          mobile_number: data?.mobile_number
        },
        reference_id: customerData?.reference_id
      })
    }
    if (dealerAndLenderInfo?.id && customerData) {
      empty()
      let lead = mapPayload(
        dealerAndLenderInfo?.id,
        customer_reference_id,
        LeadKey.Customer_Detail,
        { email: customerData?.email }
      )
      updateLead(lead)
    }
    if (customerStatusData) {
      const { preferred_contact } = data
      const cached_disclaimers = queryClient.getQueryData(CUSTOMER_MANDATORY_DISCLAIMERS) as any[]
      if (Array.isArray(cached_disclaimers) && (cached_disclaimers ?? []).length > 0) {
        const allDisclaimers = cached_disclaimers.filter(
          (d) =>
            d.status === ConsentStatus.PUBLISHED &&
            [
              DisclaimerNames.TERMS_AND_CONDITIONS,
              DisclaimerNames.CCPA,
              ...(preferred_contact !== 'Email' ? [DisclaimerNames.TCPA] : [])
            ].includes(d.name) &&
            !customerData?.consent?.some((consent: any) => consent.disclaimer_name === d.name)
        )
        const consentPromises = allDisclaimers.map((disclaimer) =>
          addCustomerConsent({
            customer_reference_id: customerData?.reference_id,
            disclaimer_name: disclaimer.name,
            disclaimer_version: disclaimer?.version
          })
        )
        Promise.all(consentPromises)
          .then(() => {
            if (vin !== ':vin') {
              navigate(
                `/dms/create-deal-ui/${vin}/${
                  customerStatusData?.reference_id || customer_reference_id
                }`,
                {
                  state: {
                    selectedVehicle
                  }
                }
              )
            } else {
              actions?.setQuotationDefaultParam({
                defaultCreditRating: undefined,
                defaultTerms: undefined
              })
            }
            setOrderState(OrderState.New)
            localStorage.setItem('closeModal', 'true' as any)
            closeModal()
          })
          .catch((error) => {
            return error
          })
      }
    } else {
      if (vin !== ':vin') {
        navigate(
          `/dms/create-deal-ui/${vin}/${
            customerStatusData?.reference_id || customer_reference_id
          }`,
          {
            state: {
              selectedVehicle
            }
          }
        )
      } else {
        actions?.setQuotationDefaultParam({
          defaultCreditRating: undefined,
          defaultTerms: undefined
        })
      }
      setOrderState(OrderState.New)
      closeModal()
      localStorage.setItem('closeModal', 'true' as any)
    }
  }
  const filterData = (data: any) => {
    getInventory(
      {
        data:
          `dealer_code=${dealer_code}&page_number=${0}&page_size=${100}&${data}` +
          (orderByState ? `&order_by_price=${orderByState}` : ``)
      },
      {
        onSuccess() {}
      }
    )
  }
  const checkCustomerStatus = () => {
    form.trigger(['email'])
    setTimeout(() => {
      if (!form.formState.errors?.email) {
        customerStatus(
          { email: form.getValues('email') },
          {
            onSuccess(res: any) {
              setCustomerData(res)
              if (res && res?.consent) {
                form.setValue('consent', true)
                form.setValue('preferred_contact', res?.preferred_contact)
              }
              if (res && res?.preferred_contact != 'Email') {
                form.setValue('mobile_number', res?.mobile_number)
              } else {
                form.setValue('consent', false)
              }
              navigate(`/dms/create-deal/${vin}/${res?.reference_id}`)
            },
            onError() {
              setCustomerData(null)
            }
          }
        )
      }
    })
  }
  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )

    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0].symbol : ''
    )
  }, [currencies])

  useEffect(() => {
    if (customerStatusError) {
      form.setValue('consent', false)
    }
  }, [form.formState.errors?.email])

  useEffect(() => {
    if (vin && localStorage.getItem('closeModal') !== 'true') {
      openModal()
    }
  }, [vin])

  useEffect(() => {
    if (customerStatusError) {
      form.setValue('consent', false)
    }
  }, [customerStatusError])
  const [openModal, closeModal] = useModal(
    () => (
      <>
        <CustomTheme className="custom-theme" theme={theme}>
          <Dialog
            disablePortal
            theme={theme}
            title={'Enter Customer’s Details'}
            size="sm"
            open
            onCloseDialog={() => {
              setOrderState(OrderState.Cancel)
              closeModal()
              navigate(APP_ROUTES.WORKQUEUE)
            }}
            customFooter={
              <>
                <Button
                  theme={theme}
                  secondary
                  text={'Cancel'}
                  onClick={() => {
                    setOrderState(OrderState.Cancel)
                    closeModal()
                    navigate(APP_ROUTES.WORKQUEUE)
                  }}
                ></Button>
                <Button
                  theme={theme}
                  type="submit"
                  primary
                  disabled={customerStatusIsLoading}
                  text={
                    !customerStatusIsLoading && customerStatusError
                      ? 'Send Invite & Initiate Deal'
                      : 'Save & Initiate Deal'
                  }
                  form={'customerForm'}
                ></Button>
              </>
            }
          >
            <form onSubmit={form.handleSubmit(onSubmit)} id="customerForm">
              <Box theme={theme}>
                <Controller
                  name="email"
                  control={form.control}
                  defaultValue={''}
                  rules={validation('Email', true, true, EMAIL_REGEX, 'Valid Email is Required.')}
                  render={({ field }) => (
                    <Input
                      theme={theme}
                      testid="email"
                      fullWidth
                      label={'Email Address'}
                      placeholder={'Please enter email'}
                      type={'text'}
                      {...field}
                      onBlur={() => checkCustomerStatus()}
                      error={form.formState.errors?.email?.message}
                      helperText={customerStatusIsLoading && 'Waiting'}
                    />
                  )}
                />
                <Controller
                  name="preferred_contact"
                  rules={validation('Preferred Contact', true)}
                  control={form.control}
                  defaultValue={''}
                  render={({ field }) => (
                    <Select
                      disablePortal={false}
                      theme={theme}
                      disabled={customerStatusIsLoading}
                      label={'Preferred Communication'}
                      items={[
                        { text: 'Mobile Phone', value: 'Mobile Phone' },
                        { text: 'Email', value: 'Email' }
                      ]}
                      {...field}
                      selectError={form?.formState?.errors?.preferred_contact?.message}
                    />
                  )}
                />
                {preferredContactMethodPhoneOrText && (
                  <Controller
                    name="mobile_number"
                    control={form.control}
                    defaultValue={''}
                    rules={validation(
                      'Phone',
                      true,
                      true,
                      PHONE_REGEX,
                      'Valid Phone number is Required'
                    )}
                    render={({ field }) => (
                      <Box theme={theme} mb={3}>
                        <Input
                          theme={theme}
                          masking
                          maskNumeric
                          format="###-###-####"
                          fullWidth
                          label={'Mobile Phone Number'}
                          type={'text'}
                          {...field}
                          error={form.formState.errors?.mobile_number?.message}
                        />
                      </Box>
                    )}
                  />
                )}
                <Box theme={theme}>
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="span"
                    display={'block'}
                    className="text-muted"
                  >
                    {(customerData?.consent?.length >= 2 &&
                      form.watch('preferred_contact') == 'Email') ||
                    (customerData?.consent?.length >= 3 &&
                      ['Mobile Phone'].includes(form.watch('preferred_contact'))) ? (
                      false
                    ) : (
                      <span>
                        By continuing, you agree to the
                        {
                          <Link
                            href="javascript:void(0)"
                            className="link hover-underline"
                            onClick={openTermsConditionsModal}
                          >
                            {' '}
                            terms and conditions ,{' '}
                          </Link>
                        }
                        to the
                        {
                          <Link
                            href="javascript:void(0)"
                            className="link hover-underline"
                            onClick={openCcpaDisclosureModal}
                          >
                            {' '}
                            CCPA disclosure{' '}
                          </Link>
                        }
                        {preferredContactMethodPhoneOrText ? (
                          <>
                            , to the
                            {
                              <Link
                                href="javascript:void(0)"
                                className="link hover-underline"
                                onClick={openTcpaModal}
                              >
                                {' '}
                                TCPA disclosure{' '}
                              </Link>
                            }
                          </>
                        ) : null}
                        and to receive marketing material related to product(s).
                      </span>
                    )}
                  </Typography>
                </Box>
              </Box>
            </form>
          </Dialog>
        </CustomTheme>
      </>
    ),
    [form.control, form.formState]
  )
  const [openTermsConditionsModal, closeTermsConditionsModal] = useModal(
    () => (
      <>
        <CustomTheme className="custom-theme" theme={theme}>
          <Dialog
            disablePortal
            theme={theme}
            title={'Terms and Conditions'}
            open
            onCloseDialog={() => {
              closeTermsConditionsModal()
            }}
            noFooter
          >
            <form onSubmit={form.handleSubmit(onSubmit)} id="customerForm">
              <Box theme={theme} className="custom-p">
                <div
                  dangerouslySetInnerHTML={{ __html: disclaimers?.TERMS_AND_CONDITIONS as string }}
                ></div>
              </Box>
            </form>
          </Dialog>
        </CustomTheme>
      </>
    ),
    [form.control, form.formState]
  )

  const [openTcpaModal, closeTcpaModal] = useModal(
    () => (
      <>
        <CustomTheme className="custom-theme" theme={theme}>
          <Dialog
            disablePortal
            theme={theme}
            title={'TCPA Disclosure'}
            open
            onCloseDialog={() => {
              closeTcpaModal()
            }}
            noFooter
          >
            <form onSubmit={form.handleSubmit(onSubmit)} id="customerForm">
              <Box theme={theme} className="custom-p">
                <div dangerouslySetInnerHTML={{ __html: disclaimers?.TCPA as string }}></div>
              </Box>
            </form>
          </Dialog>
        </CustomTheme>
      </>
    ),
    [form.control, form.formState]
  )

  const [openCcpaDisclosureModal, closeCcpaDisclosureModal] = useModal(
    () => (
      <>
        <CustomTheme className="custom-theme" theme={theme}>
          <Dialog
            disablePortal
            theme={theme}
            title={'CCPA Disclosure'}
            open
            onCloseDialog={() => {
              closeCcpaDisclosureModal()
            }}
            noFooter
          >
            <form onSubmit={form.handleSubmit(onSubmit)} id="customerForm">
              <Box theme={theme} className="custom-p">
                <div dangerouslySetInnerHTML={{ __html: disclaimers?.CCPA as string }}></div>
              </Box>
            </form>
          </Dialog>
        </CustomTheme>
      </>
    ),
    [form.control, form.formState]
  )

  useEffect(() => {
    const step = localStorage.getItem('step')
    if (step && step === 'GoToStep1') {
      closeModal()
      setOrderState(OrderState.New)
    }
  }, [orderState])

  useEffect(() => {
    if (orderState === OrderState.Draft) {
      if (dealerAndLenderInfo && Object.keys(dealerAndLenderInfo).length !== 0) {
        if (localStorage.getItem('closeModal') !== 'true') {
          openModal()
        } else {
          setOrderState(OrderState.New)
        }
      }
    } else if (orderState === OrderState.New) {
      getRefernceData({ dealer_code: dealer_code })
    }
  }, [orderState, dealerAndLenderInfo])

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }

  const [openVdModal, closeVdModal] = useModal(
    () => (
      <>
        <Dialog
          className="dialog-with-border"
          theme={theme}
          title={'Asset Details'}
          disablePortal
          open
          onCloseDialog={closeVdModal}
          customFooter={
            <>
              <Button
                primary
                theme={theme}
                text={'Select & Continue'}
                onClick={() => {
                  actions.setOfferInitialData({
                    selected_vehicle: selectedVehicle
                  })
                  navigate(
                    `/dms/create-deal-ui/${selectedVehicle?.vehicle?.vin}/${
                      customer_reference_id ?? vin
                    }`,
                    {
                      state: {
                        selectedVehicle
                      }
                    }
                  )
                }}
              ></Button>
            </>
          }
        >
          <VehicleCardUi theme={theme} className="vehicle-card full-width vd-popup m-dialog">
            <Box
              theme={theme}
              className="vc-info-head"
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Box theme={theme} className="vc-type-name">
                <Typography
                  theme={theme}
                  className="vc-type text-muted"
                  display={'block'}
                  variant="body1"
                  component="p"
                >
                  {selectedVehicle?.vehicle?.year} {selectedVehicle?.vehicle?.make}{' '}
                  {selectedVehicle?.vehicle?.model}
                </Typography>
                <Typography
                  theme={theme}
                  className="vc-name text-dark"
                  display={'block'}
                  variant="h3"
                  component="h3"
                >
                  {selectedVehicle?.vehicle?.trim_description}
                </Typography>
              </Box>
              <Box theme={theme} className="price-info">
                <Typography
                  theme={theme}
                  className="price-title text-muted"
                  display={'block'}
                  variant="body1"
                  component="p"
                >
                  Selling Price
                </Typography>
                <Typography
                  theme={theme}
                  className="vc-price text-primary"
                  display={'block'}
                  variant="h3"
                  component="h3"
                >
                  {defaultCurrency}
                  {selectedVehicle?.vehicle?.internet_price?.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}
                </Typography>
              </Box>
            </Box>
            {selectedVehicle?.vehicle?.photo_urls?.every(
              (photo: any) => photo.location !== '' && photo.location?.split('//')?.[1] !== ''
            ) ? (
              <ResponsiveCarousel responsive={responsive}>
                {selectedVehicle?.vehicle?.photo_urls?.length > 0 &&
                  selectedVehicle?.vehicle?.photo_urls?.map((obj: any, index: any) => (
                    <Box theme={theme} key={index}>
                      <img src={obj?.location === '' ? noImage : obj?.location} alt="vehicle" />
                    </Box>
                  ))}
              </ResponsiveCarousel>
            ) : (
              <Box theme={theme} className="vc-img-wrap blank-img-wrap">
                <img src={noImage} alt="Blank" />
              </Box>
            )}
            {selectedVehicle?.vehicle?.photo_urls?.length <= 0 && (
              <Icon name="NoPreviewAvailable" />
            )}
            <Box theme={theme} className="vc-features">
              <Typography
                theme={theme}
                className="vf-title text-dark"
                display={'block'}
                variant="h4"
                component="h4"
              >
                Vehicle Features & Specifications
              </Typography>
              <Grid theme={theme} container item spacing={3}>
                <Grid theme={theme} item xs={6} md={3} lg={4}>
                  <Box theme={theme} className="feat-item">
                    <Typography
                      theme={theme}
                      className="text-muted"
                      display={'block'}
                      variant="caption"
                      component="small"
                    >
                      Drivetrain:
                    </Typography>
                    <Typography
                      theme={theme}
                      className="text-dark"
                      display={'block'}
                      variant="body2"
                      component="span"
                    >
                      {selectedVehicle?.vehicle?.drive_train}
                    </Typography>
                  </Box>
                </Grid>
                <Grid theme={theme} item xs={6} md={3} lg={4}>
                  <Box theme={theme} className="feat-item">
                    <Typography
                      theme={theme}
                      className="text-muted"
                      display={'block'}
                      variant="caption"
                      component="small"
                    >
                      Engine:
                    </Typography>
                    <Typography
                      theme={theme}
                      className="text-dark"
                      display={'block'}
                      variant="body2"
                      component="span"
                    >
                      {selectedVehicle?.vehicle?.engine_description}
                    </Typography>
                  </Box>
                </Grid>
                <Grid theme={theme} item xs={6} md={3} lg={4}>
                  <Box theme={theme} className="feat-item">
                    <Typography
                      theme={theme}
                      className="text-muted"
                      display={'block'}
                      variant="caption"
                      component="small"
                    >
                      Exterior Color:
                    </Typography>
                    <Typography
                      theme={theme}
                      className="text-dark"
                      display={'block'}
                      variant="body2"
                      component="span"
                    >
                      {selectedVehicle?.vehicle?.exterior_color_description}
                    </Typography>
                  </Box>
                </Grid>
                <Grid theme={theme} item xs={6} md={3} lg={4}>
                  <Box theme={theme} className="feat-item">
                    <Typography
                      theme={theme}
                      className="text-muted"
                      display={'block'}
                      variant="caption"
                      component="small"
                    >
                      Interior Color:
                    </Typography>
                    <Typography
                      theme={theme}
                      className="text-dark"
                      display={'block'}
                      variant="body2"
                      component="span"
                    >
                      {selectedVehicle?.vehicle?.interior_color_description}
                    </Typography>
                  </Box>
                </Grid>
                <Grid theme={theme} item xs={6} md={3} lg={4}>
                  <Box theme={theme} className="feat-item">
                    <Typography
                      theme={theme}
                      className="text-muted"
                      display={'block'}
                      variant="caption"
                      component="small"
                    >
                      Transmission Type:
                    </Typography>
                    <Typography
                      theme={theme}
                      className="text-dark"
                      display={'block'}
                      variant="body2"
                      component="span"
                    >
                      {selectedVehicle?.vehicle?.transmission_type}
                    </Typography>
                  </Box>
                </Grid>
                <Grid theme={theme} item xs={6} md={3} lg={4}>
                  <Box theme={theme} className="feat-item">
                    <Typography
                      theme={theme}
                      className="text-muted"
                      display={'block'}
                      variant="caption"
                      component="small"
                    >
                      Stock Number:
                    </Typography>
                    <Typography
                      theme={theme}
                      className="text-dark"
                      display={'block'}
                      variant="body2"
                      component="span"
                    >
                      {selectedVehicle?.vehicle?.stock_number}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </VehicleCardUi>
        </Dialog>
      </>
    ),
    [selectedVehicle]
  )
  if (orderState === OrderState.Draft) {
    return <LayoutWithSideNav theme={theme}></LayoutWithSideNav>
  } else if (orderState === OrderState.New) {
    return (
      <LayoutWithSideNav theme={theme}>
        <PaginatedHeader theme={theme} step={1} text="Choose Asset" />
        {refernceData?.result === null ? (
          <NotInventory />
        ) : (
          <>
            <CustomGrid className="main-content-section" theme={theme} container item spacing={2}>
              <FilterWrap
                theme={theme}
                item
                xs={12}
                md={12}
                lg={3}
                className={clsx('filters-toggle-wrap', openFilter ? '' : 'hide')}
              >
                {refernceData && (
                  <CustomFilterControl
                    theme={theme}
                    referenceData={refernceData}
                    orderBy={orderByState}
                    onSave={(data) => {
                      filterData(data)
                    }}
                  ></CustomFilterControl>
                )}
              </FilterWrap>
              <RightPanel
                theme={theme}
                item
                xs={12}
                md={12}
                lg={openFilter ? 9 : 12}
                className={clsx('right-content', openFilter ? '' : 'expand')}
              >
                <Grid theme={theme} container item spacing={2}>
                  <Grid theme={theme} item xs={12}>
                    <FilterControl
                      pb={2}
                      theme={theme}
                      className="filters-control"
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <Button
                        defaultBtn
                        theme={theme}
                        startIcon={<Icon name="FilterIcon" />}
                        text={openFilter ? 'Hide Filters' : 'Show Filters'}
                        onClick={() => setOpenFilter(!openFilter)}
                      ></Button>
                      <Menu
                        theme={theme}
                        options={[
                          {
                            optionText: 'Selling Price (High to Low)',
                            optionKey: 'descending'
                          },
                          {
                            optionText: 'Selling Price (Low to High)',
                            optionKey: 'ascending'
                          }
                        ]}
                        handleOptionClick={(_e, key, _value) => {
                          setOrderByState(key)
                        }}
                        render={(cb) => (
                          <Button
                            defaultBtn
                            endIcon={<Icon name="ChevronDown" />}
                            onClick={cb}
                            text={'Sort By'}
                          ></Button>
                        )}
                      />
                    </FilterControl>
                  </Grid>
                  {inventoryData?.result?.length == 0 ? (
                    <Grid theme={theme} item xs={12}>
                      <NotFound />
                    </Grid>
                  ) : (
                    inventoryData?.result?.map((vehicle: any, index: number) => (
                      <Grid theme={theme} key={index} item xs={12} md={6} lg={openFilter ? 4 : 3}>
                        <VehicleCardUi
                          theme={theme}
                          className="vehicle-card full-width mini-carousel"
                        >
                          <Grid container spacing={2} theme={theme} sx={{ mb: 2 }}>
                            <Grid item xs={7} theme={theme}>
                              <Box theme={theme}>
                                <Typography
                                  theme={theme}
                                  variant="subtitle2"
                                  component="span"
                                  textAlign="left"
                                  className="w-100 text-muted"
                                >
                                  {vehicle?.vehicle.year} {vehicle?.vehicle.model}
                                </Typography>
                              </Box>
                              <Box theme={theme}>
                                <Typography
                                  theme={theme}
                                  variant="subtitle2"
                                  component="span"
                                  textAlign="left"
                                  className="w-100 text-dark"
                                >
                                  {vehicle?.vehicle.trim_description}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={5} theme={theme}>
                              <Box theme={theme}>
                                <Typography
                                  theme={theme}
                                  variant="caption"
                                  component="span"
                                  textAlign="right"
                                  className="w-100 text-muted"
                                >
                                  Selling Price
                                </Typography>
                              </Box>
                              <Box theme={theme}>
                                <Typography
                                  theme={theme}
                                  variant="subtitle2"
                                  component="span"
                                  textAlign="right"
                                  className="w-100 text-dark"
                                >
                                  {defaultCurrency}
                                  {vehicle?.vehicle?.internet_price?.toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                  })}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          {vehicle?.vehicle?.photo_urls?.every(
                            (photo: any) =>
                              photo.location !== '' && photo.location?.split('//')?.[1] !== ''
                          ) ? (
                            <>
                              <ResponsiveCarousel
                                responsive={responsive}
                                removeArrowOnDeviceType={'xs'}
                                swipeable={false}
                                draggable={false}
                                autoPlay={true}
                                pauseOnHover={false}
                                autoPlaySpeed={3000}
                              >
                                {vehicle?.vehicle?.photo_urls?.length > 0 &&
                                  vehicle?.vehicle?.photo_urls?.map((obj: any, index: any) => (
                                    <img
                                      src={obj?.location === '' ? noImage : obj?.location}
                                      key={index}
                                      alt="vehicle"
                                    />
                                  ))}
                              </ResponsiveCarousel>
                              {vehicle?.vehicle?.photo_urls?.length > 0 && (
                                <Box theme={theme} className="vc-img-wrap single-img">
                                  <img
                                    src={vehicle?.vehicle?.photo_urls[0]?.location}
                                    key={index}
                                    alt="vehicle"
                                  />
                                </Box>
                              )}
                            </>
                          ) : (
                            <Box theme={theme} className="vc-img-wrap blank-img-wrap">
                              <img src={noImage} alt="Blank" />
                            </Box>
                          )}
                          <Box theme={theme} className="vc-footer w-100" textAlign={'center'}>
                            <Box theme={theme} className="specs">
                              <Box theme={theme} className="w-100">
                                <Typography
                                  theme={theme}
                                  variant="caption"
                                  component="span"
                                  className="text-muted"
                                  sx={{ mr: 0.5 }}
                                >
                                  Stock:{' '}
                                </Typography>
                                <Typography
                                  theme={theme}
                                  variant="subtitle2"
                                  component="span"
                                  className="text-dark subtitle2"
                                >
                                  {vehicle?.vehicle?.stock_number}
                                </Typography>
                              </Box>
                              <Box theme={theme} className="w-100">
                                <Typography
                                  theme={theme}
                                  variant="caption"
                                  component="span"
                                  className="text-muted"
                                  sx={{ mr: 0.5 }}
                                >
                                  VIN:{' '}
                                </Typography>
                                <Typography
                                  theme={theme}
                                  variant="subtitle2"
                                  component="span"
                                  className="text-dark subtitle2"
                                >
                                  {vehicle?.vehicle?.vin}
                                </Typography>
                              </Box>
                            </Box>
                            <Box theme={theme} className="action">
                              <Button
                                theme={theme}
                                onClick={() => {
                                  setSelectedVehicle(vehicle)
                                  openVdModal()
                                }}
                                secondary
                                className="btn-view"
                                iconText={<Icon name="IcView" />}
                              >
                                Button
                              </Button>
                              <Button
                                theme={theme}
                                primary
                                text={'Select & Continue'}
                                onClick={() => {
                                  actions.setOfferInitialData({
                                    selected_vehicle: vehicle
                                  })
                                  navigate(
                                    `/dms/create-deal-ui/${vehicle?.vehicle?.vin}/${
                                      customer_reference_id ?? vin
                                    }`,
                                    {
                                      state: {
                                        selectedVehicle: vehicle
                                      }
                                    }
                                  )
                                }}
                              ></Button>
                            </Box>
                          </Box>
                        </VehicleCardUi>
                      </Grid>
                    ))
                  )}
                </Grid>
              </RightPanel>
            </CustomGrid>
          </>
        )}
      </LayoutWithSideNav>
    )
  }
}

export default VehicleSelection
