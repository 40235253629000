import { FC } from 'react'
import { useTheme } from '@mui/material'
import { Grid } from '@ntpkunity/controls'
import {
  Contracts,
  CustomerDetails,
  InsuranceDetails,
  OrderDetails,
  PaymentBreakdown,
  PaymentDetailsNew,
  PickupAppointment,
  TradeInDetails
} from '@app/order-management/order-summary'
import { Status } from '@helpers/enums'

const OrderLayoutSummary: FC<{
  orderDetail: any
  setOrderObject?: any
  generalDocument: any
  contractDocument: any
}> = ({ orderDetail, setOrderObject, generalDocument, contractDocument }) => {
  const theme = useTheme()
  const disableInsuranceEditing = [
    Status.SchedulePickup,
    Status.Appointment,
    Status.Completed
  ].includes(orderDetail?.status)
  return (
    <Grid theme={theme} className="container" container spacing={2}>
      <Grid theme={theme} item xl={8} lg={8} md={8} sm={12} xs={12}>
        {orderDetail?.appointment_details ? (
          <PickupAppointment order_info={orderDetail}></PickupAppointment>
        ) : (
          ''
        )}

        {orderDetail?.documents && orderDetail?.documents.length > 0 ? (
          <Contracts
            orderStatus={orderDetail?.status}
            dealerId={orderDetail?.dealer_id}
            referenceNumber={orderDetail?.reference_number}
            referenceId={orderDetail?.customer_info.reference_id}
            generalDocument={generalDocument}
            contractDocument={contractDocument}
          />
        ) : (
          ''
        )}
        <PaymentDetailsNew order_detail={orderDetail} viewMode={true} />

        <CustomerDetails
          customer_info={orderDetail?.customer_info}
          coapplicant_info={orderDetail?.coapplicant_info}
        />

        <OrderDetails order_detail={orderDetail} />

        {orderDetail?.Insurance_Inforation ? (
          <InsuranceDetails
            insurance={orderDetail?.Insurance_Inforation}
            referenceId={orderDetail?.customer_info.reference_id}
            isInsuranceEditingDisabled={disableInsuranceEditing}
          />
        ) : (
          ''
        )}

        {orderDetail?.order_tradein ? (
          <TradeInDetails orderDetail={orderDetail} setOrderObject={setOrderObject} />
        ) : (
          ''
        )}
      </Grid>
      <PaymentBreakdown order_info={orderDetail} />
    </Grid>
  )
}

export default OrderLayoutSummary
