import { FC, useEffect, useState } from 'react'
import { Icon, Box, DataTable, Input, Button, Menu, Checkbox, Skeleton } from '@ntpkunity/controls'
import { useTheme, TablePagination } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { DataTableWrap } from '@components'
import FIPriceDialog from './products-rate-dialog'
import FAndISupplier from './../finance-insurance-supplier/finance-insurance-supplier'
import ChevronDown from '@public/assets/icons/chevron-down'
import { useGetDealerSurcharges, useGetFniMonthlyImpacts } from '@apis/financial-insurance.service'
import { createFniMonthlyImpactPayloadFromState } from '@app/desking/utils'

const FiProductsTable: FC<{
  isMSP?: boolean
  setSelectedLeaseItems?: any
  setSelectedFinanceItems?: any
  selectedFinanceItems?: any
  selectedLeaseItems?: any
  defaultCurrency?: any
  terms?: any
  data?: any
  activeTab: 0 | 1
  setColumnFilters?: any
  filters?: any
  setFniPrice?: any
  fniPrice?: any
  addedItems?: any
  setAddedItems?: any
  paginationData?: any
  setFilters?: any
  dealerInfo?: any
  deskingState: any
  states: any
}> = ({
  selectedFinanceItems,
  setSelectedLeaseItems,
  setSelectedFinanceItems,
  selectedLeaseItems,
  defaultCurrency,
  data,
  setColumnFilters,
  filters,
  activeTab,
  setFniPrice,
  addedItems,
  setAddedItems,
  setFilters,
  paginationData,
  dealerInfo,
  deskingState,
  states
}) => {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  const [cardDetails, setCardDetails] = useState()
  const [pageSize, setPageSize] = useState<number>(5)
  const [_pageNumber, setPageNumber] = useState<number>(0)
  const [openProductDialog, setOpenProductDialog] = useState(false)
  const [isPriceChanged, setIsPriceChanged] = useState(false)
  const isAdded = (id: any) => addedItems?.includes(id)
  const {
    mutate: getDealerSurcharges,
    data: dealerSurcharges,
    isLoading: isSaveButtonDisabled
  } = useGetDealerSurcharges()
  const {
    mutate: getIndividualFniMonthlyImpacts,
    data: individualFniMonthlyImpacts,
    isLoading: loadingIndividualFniMonthlyImpacts
  } = useGetFniMonthlyImpacts()

  const disabledIds =
    activeTab === 0
      ? selectedFinanceItems
          ?.filter((fniItem: any) => !!fniItem?.bundle_id)
          .map((fniItem: any) => fniItem?.id)
      : selectedLeaseItems
          ?.filter((fniItem: any) => !!fniItem?.bundle_id)
          .map((fniItem: any) => fniItem?.id)

  const removeItem = (data: any) => {
    if (activeTab == 0) {
      setAddedItems(addedItems.filter((id: any) => id !== data?.id))
      setSelectedFinanceItems(
        selectedFinanceItems.filter(
          (item: any) => (item?.financial_insurance_id ?? item?.id) !== data?.id
        )
      )
      setFniPrice((prevState: any) => ({
        ...prevState,
        finance: prevState?.finance?.filter((item: any) => item?.id !== data?.id)
      }))
    } else {
      setAddedItems(addedItems.filter((id: any) => id !== data?.id))
      setSelectedLeaseItems(
        selectedLeaseItems.filter(
          (item: any) => (item?.financial_insurance_id ?? item?.id) !== data?.id
        )
      )
      setFniPrice((prevState: any) => ({
        ...prevState,
        lease: prevState?.lease?.filter((item: any) => item?.id !== data?.id)
      }))
    }
  }

  const calculateSum = (obj: any) => {
    if (activeTab == 0 && !selectedFinanceItems.some((x: any) => x.id == obj?.id)) {
      setAddedItems([...addedItems, obj?.id])
      setSelectedFinanceItems([
        ...selectedFinanceItems,
        {
          ...obj,
          price: obj?.price,
          rate_id: obj?.rate_id,
          form_id: obj?.form_id
        }
      ])
    } else if (activeTab == 1 && !selectedLeaseItems.some((x: any) => x.id == obj?.id)) {
      setAddedItems([...addedItems, obj?.id])
      setSelectedLeaseItems([
        ...selectedLeaseItems,
        {
          ...obj,
          price: obj?.price,
          rate_id: obj?.rate_id,
          form_id: obj?.form_id
        }
      ])
    }
  }

  const handleCheckboxChange = (product: any) => {
    if (isAdded(product?.id)) {
      removeItem(product)
    } else {
      calculateSum(product)
    }
  }
  const getCoverage = (item: any, existingItem: any) => {
    if (item) {
      const termMonths = existingItem?.term_months ?? item?.term ?? item?.term_months
      const termMiles = item?.term_miles
      const termMonthsWithLabel = termMonths ? `${termMonths} mo, ` : ''
      const termMilesWithLabel = termMiles
        ? `${Number(termMiles)?.toLocaleString(undefined, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0
          })} mi`
        : ''
      return { termMonths: termMonthsWithLabel, termMiles: termMilesWithLabel }
    }
    return {}
  }

  const handleProductDetailsView = (cardData: any) => {
    setOpenProductDialog(true)
    setCardDetails(cardData)
  }

  const handleProductEditView = (cardData: any) => {
    getDealerSurcharges({
      pen_dealer_id: cardData?.pen_dealer_id,
      pen_product_id: cardData?.product_id
    })
    setIsOpen(true)
    setCardDetails(cardData)
  }

  const handleCloseProductDetails = () => {
    setOpenProductDialog(false)
  }

  const selectedAllFnis =
    (data?.length > 0 && data?.every((item: any) => addedItems?.includes(item?.id))) || false
  const someFnisSelected =
    data?.length > 0 &&
    data?.some((item: any) => addedItems?.includes(item?.id)) &&
    !selectedAllFnis

  const handleSelectAll = () => {
    const nonPreInstalledIds = data.map((item: any) => item?.id)
    const filteredNonPreInstalledIds = nonPreInstalledIds?.filter(
      (id: any) => !disabledIds?.includes(id)
    )
    const filteredData = data?.filter((item: any) => !disabledIds?.includes(item?.id))
    const isAllSelected = filteredNonPreInstalledIds?.every((id: any) => addedItems?.includes(id))

    if (selectedAllFnis || isAllSelected) {
      if (activeTab === 0) {
        setSelectedFinanceItems((prevItems: any) =>
          prevItems?.filter((item: any) => !filteredNonPreInstalledIds?.includes(item?.id))
        )
        setAddedItems((prevItems: any) =>
          prevItems?.filter((id: any) => !filteredNonPreInstalledIds?.includes(id))
        )
      } else {
        setSelectedLeaseItems((prevItems: any) =>
          prevItems?.filter((item: any) => !filteredNonPreInstalledIds?.includes(item?.id))
        )
        setAddedItems((prevItems: any) =>
          prevItems?.filter((id: any) => !filteredNonPreInstalledIds?.includes(id))
        )
      }
    } else {
      if (activeTab === 0) {
        setSelectedFinanceItems((prevItems: any) => {
          const newItems = filteredData.filter(
            (newItem: any) => !prevItems.some((prevItem: any) => prevItem.id === newItem.id)
          )

          return [...prevItems, ...newItems]
        })
        setAddedItems((prevItems: any) => [...prevItems, ...filteredNonPreInstalledIds])
      } else {
        setSelectedLeaseItems((prevItems: any) => {
          const newItems = filteredData.filter(
            (newItem: any) => !prevItems.some((prevItem: any) => prevItem.id === newItem.id)
          )

          return [...prevItems, ...newItems]
        })
        setAddedItems((prevItems: any) => [...prevItems, ...filteredNonPreInstalledIds])
      }
    }
  }

  const isSelectAllDisabled =
    data?.length > 0 && data?.every((item: any) => disabledIds?.includes(item?.id))

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage)
    setColumnFilters(setQueryString(newPage, pageSize))
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value)
    setPageNumber(0)
    setColumnFilters(setQueryString(0, +event.target.value))
  }

  const setQueryString = (pgNo: number, pgSize: number): any => {
    let query_string: string = `&page_number=${pgNo}&page_size=${pgSize}`
    if (
      filters?.productNameFilter?.trimStart() != null &&
      filters?.productNameFilter?.trimStart() != ''
    ) {
      query_string = query_string.concat(`&product_name=${filters?.productNameFilter}`)
    }

    if (
      filters?.categoryFilter?.trimStart() != null &&
      filters?.categoryFilter?.trimStart() != ''
    ) {
      query_string = query_string.concat(`&category=${filters?.categoryFilter}`)
    }

    if (
      filters?.providerFilter?.trimStart() != null &&
      filters?.providerFilter?.trimStart() != ''
    ) {
      query_string = query_string.concat(`&provider_name=${filters?.providerFilter}`)
    }

    if (
      filters?.productTypeFilter?.trimStart() != null &&
      filters?.productTypeFilter?.trimStart() != ''
    ) {
      query_string = query_string.concat(`&product_type=${filters?.productTypeFilter}`)
    }

    if (filters?.priceFilter?.trimStart() != null && filters?.priceFilter?.trimStart() != '') {
      query_string = query_string.concat(`&price=${filters?.priceFilter}`)
    }

    if (
      filters?.pricePerMonthFilter?.trimStart() != null &&
      filters?.pricePerMonthFilter?.trimStart() != ''
    ) {
      query_string = query_string.concat(`&price_per_month=${filters?.pricePerMonthFilter}`)
    }

    if (
      filters?.coverageFilter?.trimStart() != null &&
      filters?.coverageFilter?.trimStart() != ''
    ) {
      query_string = query_string.concat(`&provider_name=${filters?.coverageFilter}`)
    }

    return query_string
  }

  const handleFilter = () => {
    setPageNumber(0)
    setColumnFilters(setQueryString(0, pageSize))
  }

  const editPopupCallback = (coverage: any) => {
    setIsPriceChanged(true)
    setSelectedLeaseItems(
      selectedLeaseItems?.map((leaseItem: any) => {
        if (leaseItem.product_id === coverage?.product_id) {
          return {
            ...leaseItem,
            rate_id: coverage?.rate_id,
            form_id: coverage?.form_id,
            price: coverage?.price,
            surcharges: coverage?.surcharges,
            deductiblePrice: coverage?.deductiblePrice,
            term_months: coverage?.term_months,
            coverage_name: coverage?.coverageName
          }
        }

        return leaseItem
      })
    )

    setSelectedFinanceItems(
      selectedFinanceItems?.map((financeItem: any) => {
        if (financeItem.product_id === coverage?.product_id) {
          return {
            ...financeItem,
            rate_id: coverage?.rate_id,
            form_id: coverage?.form_id,
            price: coverage?.price,
            surcharges: coverage?.surcharges,
            deductiblePrice: coverage?.deductiblePrice,
            term_months: coverage?.term_months,
            coverage_name: coverage?.coverageName
          }
        }

        return financeItem
      })
    )
  }

  useEffect(() => {
    const hasPrice = data?.some((item: any) => item?.price)

    if (!hasPrice) {
      return
    }

    const isValidPrice = (price: any) => price && price !== 0 && price !== '' && !isNaN(price)

    const individualProducts = individualFniMonthlyImpacts?.products

    const areAllMonthlyImpactsCovered = data?.every(
      (item: any) =>
        !isValidPrice(item?.price) ||
        individualProducts?.some(
          (individualItem: any) =>
            individualItem?.name === item?.product_name && individualItem?.price === item?.price
        )
    )

    if (areAllMonthlyImpactsCovered) {
      return
    }

    const fniProductsPayload = data?.map((product: any) => {
      const selectedItem =
        activeTab === 0
          ? selectedFinanceItems?.find(
              (item: any) => (item?.financial_insurance_id ?? item?.id) === product?.id
            )
          : selectedLeaseItems?.find(
              (item: any) => (item?.financial_insurance_id ?? item?.id) === product?.id
            )

      const price = selectedItem?.price ?? product?.price
      return {
        fni_product_name: product?.product_name,
        fni_product_amount: parseFloat(price || 0),
        amount_handling: '',
        is_taxable: product?.taxable ?? false
      }
    })

    const requestObject = createFniMonthlyImpactPayloadFromState(
      deskingState,
      states,
      fniProductsPayload
    )

    if (!loadingIndividualFniMonthlyImpacts) {
      getIndividualFniMonthlyImpacts({ body: requestObject, type: 'individual' })
    }

    setIsPriceChanged(false)
  }, [data, isPriceChanged])

  function getFniMonthlyImpact(item: any) {
    const products = individualFniMonthlyImpacts?.products
    const monthlyImpact =
      Array.isArray(products) &&
      products?.length > 0 &&
      products?.find((impact: any) => impact?.name === item?.product_name)?.monthly_impact
    return monthlyImpact ? `${defaultCurrency}${monthlyImpact?.toFixed(2)}` : '-'
  }

  return (
    <>
      <DataTableWrap theme={theme} className="table-pagination table-dialog with-tabs" mt={2}>
        <Box theme={theme} className="scroll">
          <Box theme={theme} className="scroll-hide sh-fixed-cell spr-border" />
        </Box>
        <DataTable
          theme={theme}
          variant="basic"
          theadChildren={
            <>
              <TableRow>
                <TableCell className="checkbox-cell fixed-cell">
                  <Checkbox
                    label=""
                    theme={theme}
                    onChange={handleSelectAll}
                    checkBoxChecked={selectedAllFnis}
                    checkBoxIndeterminate={someFnisSelected}
                    checkBoxDisabled={isSelectAllDisabled}
                  />
                </TableCell>
                <TableCell className="img-cell">
                  <Icon name="ImageIcon" />
                </TableCell>
                <TableCell id="productName">Product Name</TableCell>
                <TableCell id="category">Category</TableCell>
                <TableCell id="provider">Provider</TableCell>
                <TableCell id="productType">Product Type</TableCell>
                <TableCell id="price">Price</TableCell>
                <TableCell id="monthly-impact">Price Per Month</TableCell>
                <TableCell id="coverage">Coverage</TableCell>
                <TableCell className="action-cell fixed-cell"></TableCell>
              </TableRow>
              <TableRow className="filters-row">
                <TableCell className="checkbox-cell fixed-cell"></TableCell>
                <TableCell className="img-cell"></TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      id={'productName'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, productNameFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.productNameFilter?.trimStart()}
                    />
                    <Button
                      theme={theme}
                      defaultBtn
                      id="productNameFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      id={'category'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, categoryFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.categoryFilter?.trimStart()}
                    />
                    <Button
                      theme={theme}
                      defaultBtn
                      id="categoryFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      id={'provider'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, providerFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.providerFilter?.trimStart()}
                    />
                    <Button
                      theme={theme}
                      defaultBtn
                      id="providerFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      id={'productType'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, productTypeFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.productTypeFilter?.trimStart()}
                    />
                    <Button
                      theme={theme}
                      defaultBtn
                      id="productTypeFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      id={'price'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, priceFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.priceFilter?.trimStart()}
                      disabled
                    />
                    <Button
                      theme={theme}
                      defaultBtn
                      id="priceFilter"
                      disabled
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      id={'pricePerMonth'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, pricePerMonthFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.pricePerMonthFilter?.trimStart()}
                      disabled
                    />
                    <Button
                      theme={theme}
                      defaultBtn
                      id="pricePerMonthFilter"
                      disabled
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      id={'coverage'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, coverageFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      // value={filters?.coverageFilter?.trimStart()}
                      disabled
                    />
                    <Button
                      theme={theme}
                      defaultBtn
                      id="coverageFilter"
                      iconText={<Icon name="IconFilter" />}
                      disabled
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell className="action-cell fixed-cell"></TableCell>
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {data?.length || data?.length == 0 ? (
                data?.map((dataItem: any, index: number) => {
                  let item = dataItem
                  let productId = item?.financial_insurance_id ?? item?.id

                  const isRowDisabled =
                    activeTab === 0
                      ? selectedFinanceItems?.find(
                          (fniItem: any) => fniItem?.id === productId && !!fniItem?.bundle_id
                        )
                      : selectedLeaseItems?.find(
                          (fniItem: any) => fniItem?.id === productId && !!fniItem?.bundle_id
                        )
                  const existingItemFound =
                    activeTab === 0
                      ? selectedFinanceItems?.find(
                          (fniItem: any) =>
                            (fniItem?.financial_insurance_id ?? fniItem?.id) === productId &&
                            !fniItem?.bundle_id
                        )
                      : selectedLeaseItems?.find(
                          (fniItem: any) =>
                            (fniItem?.financial_insurance_id ?? fniItem?.id) === productId &&
                            !fniItem?.bundle_id
                        )

                  if (existingItemFound) {
                    item = { ...item, ...existingItemFound, id: productId }
                  }

                  return (
                    <TableRow className={`child-tr ${isRowDisabled ? 'disabled' : ''}`} key={index}>
                      <TableCell className="checkbox-cell fixed-cell">
                        <Checkbox
                          label=""
                          theme={theme}
                          checkBoxChecked={isAdded(productId)}
                          onChange={() => handleCheckboxChange(item)}
                          checkBoxDisabled={isRowDisabled}
                        />
                      </TableCell>
                      <TableCell className="img-cell">
                        <img
                          className="prd-img"
                          src={
                            item?.image?.[0]?.location ??
                            require('../../../../src/public/assets/images/no-img.svg')
                          }
                          alt="Car"
                        />
                      </TableCell>
                      <TableCell>{item?.product_name}</TableCell>
                      <TableCell>{item?.category}</TableCell>
                      <TableCell>{item?.provider_name}</TableCell>
                      <TableCell>{item?.product_type}</TableCell>
                      <TableCell className="editable-cell text-right">
                        <Box theme={theme} className="cell-content" justifyContent={'flex-end'}>
                          <span>
                            {!!item?.price
                              ? `${defaultCurrency}${item?.price?.toLocaleString(undefined, {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2
                                })}`
                              : '-'}
                          </span>
                          {!!item?.price && (
                            <Button
                              theme={theme}
                              defaultBtn
                              iconText={
                                <Icon name="EditIcon" onClick={() => handleProductEditView(item)} />
                              }
                            />
                          )}
                        </Box>
                      </TableCell>
                      <TableCell className="text-right">
                        {loadingIndividualFniMonthlyImpacts ? (
                          <Skeleton
                            theme={theme}
                            variant="rectangular"
                            width={'100%'}
                            sx={{ borderRadius: '8px' }}
                          />
                        ) : (
                          <>{getFniMonthlyImpact(item)}</>
                        )}
                      </TableCell>
                      <TableCell>
                        {item && getCoverage(item, existingItemFound)?.termMonths}{' '}
                        {item && getCoverage(item, existingItemFound)?.termMiles}
                      </TableCell>
                      <TableCell className="action-cell fixed-cell">
                        <Menu
                          theme={theme}
                          options={[
                            {
                              optionText: 'View Details',
                              optionValue: 'view_details'
                            },
                            {
                              optionText: 'Edit Coverage',
                              optionValue: 'editCoverage'
                            }
                          ]}
                          handleOptionClick={(_event, _key, value) => {
                            switch (value) {
                              case 'view_details':
                                handleProductDetailsView(item)
                                break
                              case 'editCoverage':
                                handleProductEditView(item)
                                break
                              default:
                                break
                            }
                          }}
                          render={(onMenuSelection: any) => (
                            <Button
                              theme={theme}
                              defaultBtn
                              iconText={<Icon name="MoreIcon" />}
                              onClick={onMenuSelection}
                              disabled={isRowDisabled}
                            />
                          )}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })
              ) : (
                <Skeleton
                  theme={theme}
                  variant="rectangular"
                  width={'100%'}
                  height={'50px'}
                  sx={{ borderRadius: '8px' }}
                />
              )}
            </>
          }
        />
        {paginationData && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            id="pagination"
            count={paginationData?.total_results ?? -1}
            page={paginationData?.page_number}
            rowsPerPage={paginationData?.page_size}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            SelectProps={{
              IconComponent: ChevronDown
            }}
          />
        )}
      </DataTableWrap>
      {openProductDialog ? (
        <FAndISupplier
          details={cardDetails}
          open={openProductDialog}
          onBackBtn={handleCloseProductDetails}
          defaultCurrency={defaultCurrency}
        />
      ) : (
        ''
      )}
      {isOpen ? (
        <FIPriceDialog
          details={cardDetails}
          isOpen={isOpen}
          isSaveButtonDisabled={isSaveButtonDisabled}
          setIsOpen={setIsOpen}
          dealerInfo={dealerInfo}
          selectDataObj={data}
          defaultCurrency={defaultCurrency}
          formattedData={{
            surcharges: dealerSurcharges?.surcharges?.surcharge?.map((surcharge: any) => ({
              code: surcharge.code
            }))
          }}
          editPopupCallback={editPopupCallback}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default FiProductsTable
