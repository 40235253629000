import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react'

import { Link, useTheme } from '@mui/material'
import { DetailCard } from 'components'
import { Typography, Grid } from '@ntpkunity/controls'
import { useStoreContext } from '@store/storeContext'
import { useGetRating } from '@apis/configurations.service'
import { DealPushProviders, Status } from '@helpers/enums'
import { FinanceTypes } from '@app/desking/desking-context-provider'
import SubmissionErrors from 'controls/SubmissionErrors'

const OrderDetails: FC<{ isDraftOrder?: Boolean; order_detail: any; lenderName?: string; setViewComments?: Dispatch<SetStateAction<boolean>> }> = ({
  isDraftOrder = false,
  order_detail,
  setViewComments
}) => {
  const theme = useTheme()
  const dealProvider: keyof typeof DealPushProviders = order_detail?.meta_data?.provider_name?.toLowerCase()
  const { states } = useStoreContext()
  const { mutate: getCreditRating } = useGetRating()
  const [creditRatingData, setCreditRatingData] = useState<any>({})
  const [showErrors, setShowErrors] = useState<boolean>(false)

  const orderLenderName = useMemo(() => {
    if (
      [
        Status.Approved,
        Status.Completed,
        Status.InContarct,
        Status.SchedulePickup,
        Status.Appointment,
        Status.Cancelled
      ].includes(order_detail?.status) && states.lendersData?.length) {
      return states.lendersData?.find(item => item.id === order_detail?.lender_id)?.name
    }
    return ''
  }, [order_detail?.status, states?.lendersData])

  useEffect(() => {
    if (!(states?.creditRatingData.length > 0) && states?.dealerInfo?.company_id && order_detail) {
      getCreditRating(
        { company_id: states?.dealerInfo?.company_id },
        {
          onSuccess(response: any) {
            setCreditRatingData(
              response?.filter(
                (rating: any) => rating?.description == order_detail?.credit_rating
              )[0]
            )
          }
        }
      )
    } else {
      setCreditRatingData(
        states?.creditRatingData?.filter(
          (rating: any) => rating?.description == order_detail?.credit_rating
        )[0]
      )
    }
  }, [states?.dealerInfo?.company_id, order_detail])

  return (
    <DetailCard className="detail-card" mb={2}>
      <Typography theme={theme} component="h4" variant="h4" mb={2}>
        Deal Details
      </Typography>
      {order_detail?.finance_type === FinanceTypes.CASH ? (
        <>
          <Grid theme={theme} className="container" container>
            <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
              <Typography theme={theme} variant="body2" component="h3" className="label">
                Deal Type
              </Typography>
            </Grid>
            <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
              <Typography theme={theme} variant="body2" component="span">
                Cash
              </Typography>
            </Grid>
          </Grid>
          {dealProvider && (
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                <Typography theme={theme} variant="body2" component="h3" className="label">
                  DMS
                </Typography>
              </Grid>
              <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
                <Typography theme={theme} variant="body2" component="h3" className="label-detail">
                  {`${DealPushProviders[dealProvider]} ${order_detail?.meta_data?.success ? '(Successful)'
                    : '(Failed) - '
                    }`}
                  {(order_detail?.meta_data?.error_detail && !order_detail?.meta_data?.success) &&
                    <Link className="link hover-underline" onClick={() => setShowErrors(true)}>
                      View Details
                    </Link>
                  }
                </Typography>
              </Grid>
            </Grid>
          )}
          {order_detail?.meta_data?.deal_number && (
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                <Typography theme={theme} variant="body2" component="h3" className="label">
                  DMS Deal ID
                </Typography>
              </Grid>
              <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
                <Typography theme={theme} variant="body2" component="h3" className="label-detail">
                  {order_detail?.meta_data?.deal_number}
                </Typography>
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <>
          <Grid theme={theme} className="container" container>
            <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
              <Typography theme={theme} variant="body2" component="h3" className="label">
                Contract Terms
              </Typography>
            </Grid>
            <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
              <Typography theme={theme} variant="body2" component="span">
                {order_detail?.contract_term ? order_detail?.contract_term : '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid theme={theme} className="container" container>
            <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
              <Typography theme={theme} variant="body2" component="h3" className="label">
                Product Type
              </Typography>
            </Grid>
            <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
              <Typography theme={theme} variant="body2" component="h3" className="label-detail">
                {isDraftOrder ? '-' : order_detail?.finance_type ? order_detail?.finance_type : '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid theme={theme} className="container" container>
            <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
              <Typography theme={theme} variant="body2" component="h3" className="label">
                Credit Score
              </Typography>
            </Grid>
            <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
              <Typography theme={theme} variant="body2" component="h3" className="label-detail">
                {creditRatingData
                  ? creditRatingData?.description +
                  ' (' +
                  creditRatingData?.score_from +
                  ' - ' +
                  creditRatingData?.score_to +
                  ')'
                  : '-'}
              </Typography>
            </Grid>
          </Grid>
          {orderLenderName && (
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                <Typography theme={theme} variant="body2" component="h3" className="label">
                  Submitted To
                </Typography>
              </Grid>
              <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
                <Typography theme={theme} variant="body2" component="h3" className="label-detail">
                  {orderLenderName}
                </Typography>
              </Grid>
            </Grid>
          )}
          {dealProvider && (
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                <Typography theme={theme} variant="body2" component="h3" className="label">
                  DMS
                </Typography>
              </Grid>
              <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
                <Typography theme={theme} variant="body2" component="h3" className="label-detail">
                  {`${DealPushProviders[dealProvider]} ${order_detail?.meta_data?.success ? '(Successful)'
                    : '(Failed) - '
                    }`}
                  {(order_detail?.meta_data?.error_detail && !order_detail?.meta_data?.success) &&
                    <Link className="link hover-underline" onClick={() => setShowErrors(true)}>
                      View Details
                    </Link>
                  }
                </Typography>
              </Grid>
            </Grid>
          )}
          {order_detail?.meta_data?.deal_number && (
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                <Typography theme={theme} variant="body2" component="h3" className="label">
                  DMS Deal ID
                </Typography>
              </Grid>
              <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
                <Typography theme={theme} variant="body2" component="h3" className="label-detail">
                  {order_detail?.meta_data?.deal_number}
                </Typography>
              </Grid>
            </Grid>
          )}
          {(order_detail?.status == Status.Cancelled ||
            order_detail?.status == Status.Declined ||
            order_detail?.status == Status.Withdrawn) && (
              <Grid theme={theme} className="container" container>
                <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                  <Typography theme={theme} variant="body2" component="h3" className="label">
                    {order_detail?.status === Status.Declined ? "Decline Reason" : "Cancellation Reason"}
                  </Typography>
                </Grid>
                <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
                  {order_detail?.status === Status.Declined && setViewComments ? (
                    <Typography theme={theme} variant="body2" component="h3" className="label-detail">
                      <Link
                        href="#"
                        style={{ cursor: "pointer", color: theme.palette.primary.main }}
                        onClick={(e) => {
                          e.preventDefault()
                          if (setViewComments) {
                            setViewComments(true)
                          }
                        }}
                      >
                        View Comments
                      </Link>
                    </Typography>
                  ) : (
                    <Typography theme={theme} variant="body2" component="h3" className="label-detail">
                      {order_detail?.reason}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            )}
        </>
      )}
      <SubmissionErrors
        openComments={showErrors}
        creditMessages={[(order_detail?.meta_data?.error_detail ?? '')]}
        selectedLender={{
          lender_name: DealPushProviders[dealProvider],
          status: order_detail?.meta_data?.status
        }}
        handleClose={() => setShowErrors(false)}
      />
    </DetailCard>
  )
}

export default OrderDetails
