import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { Box, IBoxProps, DuoTab } from '@ntpkunity/controls'
import { styled } from '@mui/material/styles'
import {
  UseQuery_Get_CustomerContact,
  UseQuery_Get_CustomerEmployment,
  UseQuery_Get_Personal_Finance,
  UseQuery_Get_License_Information,
  useGetIntegrationByProviderName
} from '@apis/customer-detail.service'
import { IntegrationProvider, LenderIntegrationType } from '@helpers/enums'
import CustomerDetailVerticalTabs from '@app/customer-details/customer-detail-tabs'
import { isEmpty } from '@helpers/methods'

const TabWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(() => ({
  '&.tab-wrap': {
    hr: {
      opacity: 0.4
    },

    '.title-icon': {
      width: '100%',
      display: 'inline-block',
      'span, Icon': {
        float: 'left'
      },
      span: {
        marginRight: 10
      },
      svg: {
        marginTop: 4
      }
    },

    '.custom-checkbox-wrap': {
      display: 'inline-block'
    },
    '.u-tab-btn': {
      textTransform: 'capitalize',
      '&:last-child': {
        '.line': {
          display: 'none'
        }
      }
    },
    '.TabsUnstyled-root': {
      '.tab-list-default': {
        marginLeft: 360,
        marginBottom: 48
      }
    }
  }
}))

const CustomerDetails: FC<{
  customerReferenceId?: string
  setTabStates: (Boolean: any) => void
  order?: any
  coApplicantReferenceId?: string | null
}> = ({ customerReferenceId, setTabStates, order, coApplicantReferenceId }) => {
  const theme = useTheme()

  const { mutate: getProviderbyName } = useGetIntegrationByProviderName(
    IntegrationProvider.GOOGLE,
    LenderIntegrationType.ADDRESS_VALIDATION
  )

  const { data: contactData } = UseQuery_Get_CustomerContact(customerReferenceId ?? '')
  const { data: employmentInfo } = UseQuery_Get_CustomerEmployment(customerReferenceId ?? '')
  const { data: licenseInfo } = UseQuery_Get_License_Information(customerReferenceId ?? '')
  const { data: personalFinanceData } = UseQuery_Get_Personal_Finance(customerReferenceId ?? '')

  const { data: coApplicantData } = UseQuery_Get_CustomerContact(coApplicantReferenceId ?? '')
  const { data: coApplicantEmploymentInfo } = UseQuery_Get_CustomerEmployment(
    coApplicantReferenceId ?? ''
  )
  const { data: coApplicantLicenseData } = UseQuery_Get_License_Information(
    coApplicantReferenceId ?? ''
  )
  const { data: coApplicantPersonalFinanceData } = UseQuery_Get_Personal_Finance(
    coApplicantReferenceId ?? ''
  )

  const [isGoogleEnabled, setIsGoogleEnabled] = useState<boolean>(false)
  const [isApplicantDetailsCompleted, setIsApplicantDetailsCompleted] = useState<boolean>(false)
  const [isCoApplicantDetailsCompleted, setIsCoApplicantDetailsCompleted] = useState<
    boolean | undefined
  >(undefined)

  useEffect(() => {
    if (isCoApplicantDetailsCompleted !== undefined) {
      const tabstates =
        isApplicantDetailsCompleted && (!coApplicantReferenceId || isCoApplicantDetailsCompleted)
      setTabStates(tabstates)
    }
  }, [coApplicantReferenceId, isApplicantDetailsCompleted, isCoApplicantDetailsCompleted])

  useEffect(() => {
    if (
      !isEmpty(contactData?.ssn) &&
      !isEmpty(employmentInfo) &&
      !isEmpty(licenseInfo) &&
      !isEmpty(personalFinanceData) &&
      (!coApplicantReferenceId ||
        (!isEmpty(coApplicantData?.first_name) &&
          !isEmpty(coApplicantEmploymentInfo) &&
          !isEmpty(coApplicantLicenseData) &&
          !isEmpty(coApplicantPersonalFinanceData)))
    )
      setTabStates(true)
  }, [
    contactData,
    personalFinanceData,
    licenseInfo,
    employmentInfo,
    coApplicantData,
    coApplicantEmploymentInfo,
    coApplicantLicenseData,
    coApplicantPersonalFinanceData,
    coApplicantReferenceId
  ])

  useEffect(() => {
    getProviderbyName(
      {
        provider_name: IntegrationProvider.GOOGLE,
        integration_type: LenderIntegrationType.ADDRESS_VALIDATION
      },
      {
        onSuccess(response: any) {
          setIsGoogleEnabled(response?.is_active)
        }
      }
    )
  }, [])

  const dealApplicants = [
    {
      title: 'Primary Applicant',
      content: (
        <CustomerDetailVerticalTabs
          customerReferenceId={customerReferenceId}
          order={order}
          isGoogleEnabled={isGoogleEnabled}
          contactData={contactData}
          licenseInfo={licenseInfo}
          employmentInfo={employmentInfo}
          personalFinanceData={personalFinanceData}
          setIsDetailsCompleted={setIsApplicantDetailsCompleted}
        />
      )
    },
    ...(coApplicantReferenceId
      ? [
          {
            title: 'Co-Applicant',
            content: (
              <CustomerDetailVerticalTabs
                customerReferenceId={coApplicantReferenceId}
                order={order}
                isGoogleEnabled={isGoogleEnabled}
                contactData={coApplicantData}
                licenseInfo={coApplicantLicenseData}
                employmentInfo={coApplicantEmploymentInfo}
                personalFinanceData={coApplicantPersonalFinanceData}
                isCoApplicant
                setIsDetailsCompleted={setIsCoApplicantDetailsCompleted}
              />
            )
          }
        ]
      : [])
  ]

  return (
    <TabWrap
      theme={theme}
      className="tab-wrap"
      display={'inline-box'}
      width="90%"
      mb={5}
      pl={4}
      pt={2}
    >
      {dealApplicants?.length > 1 ? (
        <DuoTab theme={theme} defaultTabIndex={0} items={dealApplicants} />
      ) : (
        dealApplicants?.[0]?.content
      )}
    </TabWrap>
  )
}

export default CustomerDetails
