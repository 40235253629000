import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const ToggleCollapse = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.toggle-collapse': {
    width: 24,
    '.MuiButtonBase-root': {
      padding: 0,
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      fontWeight: theme.customVariables.fontWeightSemiBold,
      border: '0 solid transparent',
      '&:hover': {
        backgroundColor: 'transparent'
      },
      svg: {
        transform: 'rotate(-180deg)',
        transition: 'all 0.3s',
        path: {
          stroke: theme.palette.grey[300]
        }
      },
      '&.Mui-selected': {
        backgroundColor: 'transparent',
        svg: {
          transform: 'rotate(0deg)'
        }
      },
      '.MuiTouchRipple-root': {
        display: 'none'
      }
    },
    '&.link': {
      width: 'auto',
      '.MuiButtonBase-root': {
        color: theme.palette.primary.main
      }
    }
  }
}))

export const ToggleCollapseWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(() => ({
  '&.toggle-collapse-wrap': {
    overflow: 'auto',
    scrollbarWidth: 'none',

    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '@supports (-moz-appearance:none)': {
      scrollbarWidth: 'none'
    }
  }
}))
