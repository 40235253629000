import React from 'react'
import { FC, useEffect, useState } from 'react'
import { TextField, useTheme } from '@mui/material'
import {
  Button,
  Dialog,
  DuoTab,
  AddressLayout,
  Skeleton,
  Box,
  Typography,
  Icon
} from '@ntpkunity/controls'
import { RescheduleAppointmentWrap } from './order-summary-style'
import { useAddAppointment, useUpdateAppointment } from '@apis/appointment-management.service'
import { useUpdateOrderStatus } from '@apis/dealer-workqueue.service'
import { useStoreContext } from '@store/storeContext'
import dayjs, { Dayjs } from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import {
  ScheduleOptions,
  Status,
  AddressType,
  IntegrationProvider,
  LenderIntegrationType
} from '@helpers/enums'
import { useNavigate } from 'react-router-dom'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { useGetStates, useGetCountries } from '@apis/dealer-workqueue.service'
import { APP_ROUTES } from '@helpers/links'
import { Controller, useForm } from 'react-hook-form'
import { IAddress } from '@models'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { accessToken } from '@helpers/constants'
import { useGetIntegrationByProviderName } from '@apis/customer-detail.service'

function createValidationSchema() {
  const schema = yup.object().shape({
    address: yup.object().shape({
      address_line_1: yup.string().nullable().required('Address Line 1 is required'),
      city: yup.string().nullable().required('City is required'),
      state_name: yup.string().nullable().required('State Name is required'),
      zip_code: yup.string().nullable().required('Zip Code is required')
      // county: yup.string().nullable().required('County is required')
    })
  })
  return schema
}
interface IFormInputs {
  address: IAddress | null
}

const RescheduleAppointmentDialog: FC<{
  openPopup: boolean
  setOpenPopup: any
  order_detail: any
  dealerProfileData?: any
}> = ({ openPopup, setOpenPopup, order_detail, dealerProfileData }) => {
  const [openAppointmentPopup, setOpenAppointmentPopup] = useState(false)
  const { mutate: addAppointmentDetail, isLoading: isAddLoading } = useAddAppointment()
  const { mutate: updateAppointmentDetail, isLoading: isUpdateLoading } = useUpdateAppointment()
  const { mutate: updateOrderStatus } = useUpdateOrderStatus()
  const [isVerified, setIsVerified] = useState(false)
  const [, setIsAddressDirty] = useState(false)
  const { actions, states } = useStoreContext()
  let navigate = useNavigate()
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(customParseFormat)
  dayjs.extend(localizedFormat)
  const maxSelectableDate = dayjs().add(15, 'day')
  const theme = useTheme()
  const [isGoogleEnabled, setIsGoogleEnabled] = useState(false)
  const [dateTime, setDateTime] = React.useState<Dayjs | null>()
  const [deliveryAddress, setdeliveryAddress] = useState<IAddress | null | undefined>()
  const { mutate: getStates, data: statesData } = useGetStates()
  const { mutate: getCountry, data: countriesData } = useGetCountries()
  const [tab, setTab] = useState(order_detail?.schedule_option == ScheduleOptions.PickUp ? 0 : 1)
  const { mutate: getProviderbyName } = useGetIntegrationByProviderName(
    IntegrationProvider.GOOGLE,
    LenderIntegrationType.ADDRESS_VALIDATION
  )
  const [dealerCountry, setDealerCountry] = useState([])

  const validationSchema = createValidationSchema()
  const form = useForm<IFormInputs | any>({
    defaultValues: deliveryAddress && deliveryAddress,
    resolver: yupResolver(validationSchema),
    mode: 'all'
  })
  const handleAppointmentDialog = () => {
    setOpenAppointmentPopup(false)
    setOpenPopup(false)
  }
  const {
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors }
  } = form
  useEffect(() => {
    if (openPopup) {
      setOpenAppointmentPopup(openPopup)
    }
  }, [openPopup])
  useEffect(() => {
    getStates()
    getCountry()
  }, [])
  useEffect(() => {
    if (order_detail) {
      const address = order_detail?.customer_info?.customer_addresses?.find(
        (x: any) =>
          (x?.address_type === AddressType.MAILING && x?.is_garaging) ||
          x?.address_type === AddressType.GARAGING
      )
      setIsVerified(address?.verified)
      setdeliveryAddress(address)
      setTab(order_detail?.schedule_option == ScheduleOptions.PickUp ? 0 : 1)
    }
  }, [order_detail])
  useEffect(() => {
    getProviderbyName(
      {
        provider_name: IntegrationProvider.GOOGLE,
        integration_type: LenderIntegrationType.ADDRESS_VALIDATION
      },
      {
        onSuccess(response: any) {
          setIsGoogleEnabled(response?.is_active)
        }
      }
    )
  }, [])
  const addAppointment = () => {
    if (order_detail?.appointment_details?.id && dateTime) {
      updateAppointmentDetail(
        {
          id: order_detail?.appointment_details?.id,
          order_id: order_detail?.id,
          dealer_id: order_detail?.dealer_id,
          customer_id: order_detail?.customer_id,
          time_zone: dealerProfileData?.time_zone_difference,
          appointment_date: dateTime.toISOString(),
          appointment_type: tab == 0 ? ScheduleOptions.PickUp : ScheduleOptions.Delivery,
          address_id: order_detail?.appointment_details?.address_id
            ? order_detail?.appointment_details?.address_id
            : null,
          address_data:
            tab == 1 && deliveryAddress
              ? {
                  address_line_1: deliveryAddress.address_line_1,
                  address_line_2: deliveryAddress.address_line_2,
                  state_name: deliveryAddress.state_name,
                  zip_code: deliveryAddress.zip_code,
                  county: deliveryAddress.county,
                  city: deliveryAddress.city,
                  contact_number: deliveryAddress.contact_number,
                  verified: true,
                  address_type: AddressType.GARAGING
                }
              : null
        },
        {
          onSuccess() {
            handleAppointmentDialog()
            const data = {
              order_id: order_detail?.reference_number,
              reqBody: {
                status: Status.Appointment,
                user_id: 0
              }
            }
            if (order_detail?.status !== Status.Appointment) {
              updateOrderStatus(data, {
                onSuccess() {
                  actions.setToast({
                    toastMessage: 'Appointment Scheduled Sucessfully',
                    toastState: true
                  })
                  navigate(APP_ROUTES?.WORKQUEUE)
                },
                onError(error: any) {
                  actions.setToast({
                    toastMessage: error.error.toString(),
                    toastState: true,
                    variant: 'error'
                  })
                }
              })
            } else {
              navigate(APP_ROUTES?.WORKQUEUE)
            }
          },
          onError(error: any) {
            if (error?.error?.message?.includes('status code 409')) {
              actions.setToast({
                toastMessage: 'The appointment date has passed. Please choose a future date.',
                toastState: true,
                variant: 'error'
              })
            } else {
              actions.setToast({
                toastMessage: error.error.toString(),
                toastState: true,
                variant: 'error'
              })
            }
          }
        }
      )
    } else if (dateTime) {
      addAppointmentDetail(
        {
          order_id: order_detail?.id,
          dealer_id: order_detail?.dealer_id,
          customer_id: order_detail?.customer_id,
          time_zone: dealerProfileData?.time_zone_difference,
          appointment_date: dateTime.utc().toISOString(),
          appointment_type: tab == 0 ? ScheduleOptions.PickUp : ScheduleOptions.Delivery,
          dealer_code: states?.dealerInfo?.dealer_code,
          address_id: null,
          address_data:
            tab == 1 && deliveryAddress
              ? {
                  address_line_1: deliveryAddress.address_line_1,
                  address_line_2: deliveryAddress.address_line_2,
                  state_name: deliveryAddress.state_name,
                  zip_code: deliveryAddress.zip_code,
                  county: deliveryAddress.county,
                  city: deliveryAddress.city,
                  contact_number: deliveryAddress.contact_number,
                  verified: true,
                  address_type: AddressType.GARAGING
                }
              : null
        },
        {
          onSuccess() {
            handleAppointmentDialog()
            const data = {
              order_id: order_detail?.reference_number,
              reqBody: {
                status: Status.Appointment,
                user_id: 0
              }
            }
            if (order_detail?.status !== Status.Appointment) {
              updateOrderStatus(data, {
                onSuccess() {
                  actions.setToast({
                    toastMessage: 'Appointment Scheduled Sucessfully',
                    toastState: true
                  })
                  navigate(APP_ROUTES?.WORKQUEUE)
                },
                onError(error: any) {
                  actions.setToast({
                    toastMessage: error.error.toString(),
                    toastState: true,
                    variant: 'error'
                  })
                }
              })
            } else {
              navigate(APP_ROUTES?.WORKQUEUE)
            }
          },
          onError(error: any) {
            if (error?.error?.message?.includes('status code 409')) {
              actions.setToast({
                toastMessage: 'The appointment date has passed. Please choose a future date.',
                toastState: true,
                variant: 'error'
              })
            } else {
              actions.setToast({
                toastMessage: error.error.toString(),
                toastState: true,
                variant: 'error'
              })
            }
          }
        }
      )
    }
  }
  const RescheduleAppointments = [
    {
      title: 'In-Store Pickup',
      content: (
        <>
          <RescheduleAppointmentWrap theme={theme} className="dialog date-time-picker">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                renderInput={(props) => <TextField {...props} />}
                label="Choose Date & Time"
                value={dateTime ? dateTime : null}
                ampm={true}
                minutesStep={30}
                disablePast={true}
                onChange={(newValue: Dayjs | null) => {
                  setDateTime(newValue)
                }}
                minTime={dayjs('2022-02-14T09:00')}
                maxTime={dayjs('2022-02-14T20:30')}
                maxDate={maxSelectableDate}
              />
            </LocalizationProvider>
          </RescheduleAppointmentWrap>
        </>
      )
    },
    {
      title: 'Delivery',
      content: (
        <>
          <RescheduleAppointmentWrap theme={theme} className="dialog date-time-picker">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                renderInput={(props) => <TextField {...props} />}
                label="Choose Date & Time"
                value={dateTime ? dateTime : null}
                ampm={true}
                minutesStep={30}
                disablePast={true}
                onChange={(newValue: Dayjs | null) => {
                  setDateTime(newValue)
                }}
                minTime={dayjs('2022-02-14T09:00')}
                maxTime={dayjs('2022-02-14T20:30')}
                maxDate={maxSelectableDate}
              />
            </LocalizationProvider>
            <Box theme={theme} className="address-layout">
              <Typography
                theme={theme}
                component="h4"
                variant="h4"
                sx={{ mb: 1, mt: 3 }}
                display={'flex'}
                className="title-icon"
              >
                Choose Delivery Location
                {isVerified && isGoogleEnabled ? (
                  <Box theme={theme} sx={{ marginLeft: '6px' }}>
                    <Icon name="GreenCheckIcon" />
                  </Box>
                ) : null}
              </Typography>
              {dealerCountry?.length > 0 && statesData?.length > 0 && tab == 1 ? (
                <Controller
                  name="address"
                  control={control}
                  defaultValue={
                    order_detail?.appointment_details?.address
                      ? order_detail?.appointment_details?.address
                      : watch('address') || deliveryAddress
                  }
                  render={({ field }) => (
                    <AddressLayout
                      {...field}
                      addressVerificationRequired={isGoogleEnabled}
                      hideAddressButton={!isGoogleEnabled}
                      theme={theme}
                      state={{
                        data: statesData,
                        selectedItemObject: { text: 'name', value: 'name' },
                        controlName: 'state_name'
                      }}
                      countries={dealerCountry}
                      validationProps={errors.address}
                      isContactRequired={true}
                      value={watch('address') || deliveryAddress}
                      verifyAddressURL={`${process.env.INTEGRATION_API_GATEWAY}/api/verify-address/`}
                      token={JSON.parse(localStorage.getItem(accessToken) as string)?.access_token}
                      dealerCode={states?.dealerInfo?.dealer_code}
                      setIsAddressDirty={setIsAddressDirty}
                      onChange={(e: any) => {
                        if (isGoogleEnabled && e?.verified) {
                          setdeliveryAddress(e)
                          setIsVerified(true)
                        } else if (!isGoogleEnabled) {
                          setdeliveryAddress(e)
                        }
                        setValue('address', e)
                        setValue('address.state_name', e?.state_name)
                        setValue('address.state_id', e?.state_id)
                      }}
                    ></AddressLayout>
                  )}
                />
              ) : (
                <>
                  <Skeleton theme={theme} width={'100%'} height={'60px'} />
                  <Skeleton theme={theme} width={'100%'} height={'60px'} />
                  <Skeleton theme={theme} width={'100%'} height={'60px'} />
                </>
              )}
            </Box>
          </RescheduleAppointmentWrap>
        </>
      )
    }
  ]
  useEffect(() => {
    setDealerCountry(
      countriesData?.filter(
        (x: any) => x?.id == states?.dealerProfileData?.dealer_address?.country_id
      )
    )
  }, [countriesData, states?.dealerProfileData])
  return (
    order_detail && (
      <Dialog
        disablePortal
        variant={undefined}
        size="sm"
        title="Schedule Appointment"
        open={openAppointmentPopup}
        onCloseDialog={handleAppointmentDialog}
        customFooter={
          <>
            {dateTime &&
              (tab == 1 ? (
                <Button
                  disabled={isUpdateLoading || isAddLoading}
                  theme={theme}
                  primary
                  text="Confirm Appointment"
                  onClick={handleSubmit(addAppointment)}
                />
              ) : (
                <Button
                  disabled={isUpdateLoading || isAddLoading}
                  theme={theme}
                  primary
                  text="Confirm Appointment"
                  onClick={addAppointment}
                />
              ))}
          </>
        }
        theme={theme}
      >
        {order_detail ? (
          <DuoTab
            theme={theme}
            varient={'default'}
            items={RescheduleAppointments}
            defaultTabIndex={
              order_detail && order_detail?.schedule_option == ScheduleOptions.PickUp ? 0 : 1
            }
            onChange={(e: any) => {
              if (e?.target?.innerText == 'In-Store Pickup') {
                setTab(0)
              }
              if (e?.target?.innerText == 'Delivery') {
                setTab(1)
              }
            }}
          />
        ) : (
          <>
            <Skeleton theme={theme} width={'100%'} height={'60px'} />
            <Skeleton theme={theme} width={'100%'} height={'60px'} />
            <Skeleton theme={theme} width={'100%'} height={'60px'} />
          </>
        )}
      </Dialog>
    )
  )
}
export default RescheduleAppointmentDialog
