import { FC, useEffect, useState } from 'react'
import { Typography, useTheme } from '@mui/material'
import { Button, Grid, Box, OrderReview } from 'components'
import { useCustomerFraudCheck } from '@apis/customer-management.service'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useStoreContext } from '@store/storeContext'
import { APP_ROUTES } from '@helpers/links'
import { Status } from '@helpers/enums'
import { DeskingActionTypes, useDeskingContext } from '@app/desking/desking-context-provider'

const OrderInReview: FC<{ orderId: string }> = ({ orderId }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const orderReferenceId = searchParams.get('reference_id')
  const customerReferenceId = searchParams.get('customer_reference_id')
  const [orderStatus, setOrderStatus] = useState<string | null>(null)
  const { mutate: saveCustomerFraudCheck } = useCustomerFraudCheck()
  const { actions, states } = useStoreContext()
  const { dispatch } = useDeskingContext()
  useEffect(() => {
    saveCustomerFraudCheck(
      {
        user_id: 0,
        reference_id: customerReferenceId,
        order_reference_number: orderId,
        dealer_code: states?.dealerAndLenderInfo?.dealer_code
      },
      {
        onSuccess: (response: any) => {
          setOrderStatus(response?.status)
          dispatch({
            type: DeskingActionTypes.UPDATE_STATUS,
            payload: response?.status
          })
          if (response.status === Status.Reviewed)
            navigate(`/dms/deal-summary/${orderReferenceId}`)
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: 'error'
          })
        }
      }
    )
  }, [])

  return (
    <OrderReview>
      <Grid className="container" theme={theme} spacing={2} container>
        <Grid item xl={4} lg={4} md={4} sm={6} xs={12} className="box">
          <Box className="content">
            {orderStatus === Status.Cancelled ? (
              <>
                <Typography className="heading">Deal Cancelled</Typography>
                <Typography className="text">
                  Sorry, this application is cancelled due to fraud check failure. We cannot proceed
                  with this deal and moved it to the cancelled deals queue on your deals page.
                </Typography>
                <Button
                  className="button"
                  theme={theme}
                  primary
                  onClick={() => navigate(APP_ROUTES.WORKQUEUE)}
                  text="Go To Deal Page"
                />
              </>
            ) : (
              <>
                <Typography className="heading">Reviewing The Deal</Typography>
                <Typography className="text">
                  This should not take too long. We will notify you once the application has been
                  reviewed.
                </Typography>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </OrderReview>
  )
}

export default OrderInReview
