import { getIndex, Post } from './ApiHelper'

export const GetStates = async () => {
  var response: any = []
  await getIndex('/config/state')
    .then((res: any) => {
      response = res
    })
    .catch((ex) => {
      throw ex
    })
  return response
}

export const GetCountries = async () => {
  var response: any = []
  await getIndex('/config/countries')
    .then((res: any) => {
      response = res
    })
    .catch((ex) => {
      throw ex
    })
  return response.filter((x: any) => x?.country_code == 'US')
}

export const CreateCustomer = async (param: any = null, employmentDetail = null) => {
  var response
  var requestObject: any = {
    customer_profile: {
      email: '',
      prefix: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      suffix: '',
      ssn: '',
      mobile_number: '',
      home_number: ''
    },
    customer_addresses: [],
    income_info: {}
  }
  if (param != null) {
    requestObject.customer_profile.email = param.email._f.value
    requestObject.customer_profile.prefix = param.prefix._f.value
    requestObject.customer_profile.first_name = param.first_name._f.value
    requestObject.customer_profile.middle_name = param.middle_name._f.value
    requestObject.customer_profile.last_name = param.last_name._f.value
    requestObject.customer_profile.suffix = param.suffix._f.value
    requestObject.customer_profile.ssn = param.ssn._f.value
    requestObject.customer_profile.mobile_number = param.mobile_number._f.value
    requestObject.customer_profile.home_number = param.home_number._f.value

    var mailing = {
      address_line_1: param.Mailing_address._f.value.address_line_1,
      address_line_2: param.Mailing_address._f.value.address_line_2,
      city: param.Mailing_address._f.value.city,
      county: param.Mailing_address._f.value.county,
      state_name: param.Mailing_address._f.value.state_name,
      zip_code: param.Mailing_address._f.value.zip_code,
      address_type: 'Mailing'
    }
    var garaging = {
      address_line_1: param.Garaging_address._f.value.address_line_1,
      address_line_2: param.Garaging_address._f.value.address_line_2,
      city: param.Garaging_address._f.value.city,
      county: param.Garaging_address._f.value.county,
      state_name: param.Garaging_address._f.value.state_name,
      zip_code: param.Garaging_address._f.value.zip_code,
      address_type: 'Garaging'
    }

    requestObject.customer_addresses.push(mailing)
    requestObject.customer_addresses.push(garaging)
  }
  if (employmentDetail != null) {
  }
  await Post('/lane/customer/', requestObject)
    .then((res) => {
      response = res
    })
    .catch((ex) => {
      throw ex
    })
  return response
}
