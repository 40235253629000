export enum FeeHandlingType {
  UPFRONT = 'upfront',
  FINANCE = 'financed',
  CASH = 'cash'
}
export enum FeeEvent {
  INCEPTION = 'Inception',
  ENDOFTERM = 'End of Term'
}
export enum FinanceType {
  LEASE = 'lease',
  FINANCE = 'finance',
  CASH = 'cash'
}
