import { styled } from '@mui/material'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const BtnTabs = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.btn-tabs': {
    padding: 2,
    borderRadius: 8,
    backgroundColor: '#F6F7F9',
    scrollbarWidth: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 'max-content',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0
    },
    '.btn': {
      padding: '4px 12px !important',
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.grey[600],
      position: 'relative',
      flexWrap: 'nowrap',
      flexShrink: 0,
      flexGrow: 1,
      '&.selected': {
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.grey[900],
        backgroundColor: theme.palette.common.white,
        boxShadow: '0 4px 8px rgba(0,0,0, 0.16)',
        '&:hover, &:focus, &:focus-visible': {
          backgroundColor: theme.palette.common.white
        }
      },
      '&.with-icon, &.with-icon.icon-only': {
        height: 28,
        svg: {
          height: 16,
          width: 'auto'
        },
        '&.selected': {
          '&:hover, &:focus, &:focus-visible': {
            backgroundColor: theme.palette.common.white
          },
          'svg rect': {
            fill: theme.palette.common.black
          }
        }
      }
    },
    '&.rounded': {
      borderRadius: 16,
      '.btn': {
        borderRadius: 16
      }
    },
    '&.with-spr': {
      gap: 4,
      '.btn': {
        '&:before': {
          width: 1,
          height: 12,
          display: 'inline-block',
          content: "''",
          backgroundColor: "#ccc",
          position: 'absolute',
          left: -2.5
        },
        '&:first-child': {
          marginLeft: 0,
          '&:before': {
            display: 'none'
          }
        }
      }
    }
  }
}))
