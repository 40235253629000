import { FC, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTheme } from '@mui/material'
import { Button, Icon, Box, Input } from '@ntpkunity/controls'
import { useQueryClient } from 'react-query'
import { InstallationMode, QueryKeys } from '@helpers/enums'
import { useStoreContext } from '@store/storeContext'
import { IQuotationParameters } from 'types/quote-parameters'

const ViewOrders: FC<{
  orderStatus?: string
  leaseObj: any
  calculationData: any
  form: any
  feeData: any
  fnIData: any
  selectedAccordion: any
  sortedOptionsArray: any
  optionsSum: any
  quotationObject: any
}> = ({
  leaseObj,
  calculationData,
  form,
  feeData,
  fnIData,
  selectedAccordion,
  sortedOptionsArray,
  quotationObject
}) => {
  const [defaultCurrency, setDefaultCurrency] = useState('')
  const { states, actions } = useStoreContext()

  const [quotationParameterData, setQuotationParameterData] = useState<any>()
  const [tradeInEquityValue, setTradeInEquityValue] = useState<any>()
  const totalRV =
    (((quotationObject?.selling_price || 0) * (quotationObject?.rv_balloon_value || 0)) / 100 ||
      0) +
    (quotationObject.order_options?.reduce(
      (total: number, currentItem: any) =>
        total +
        ((currentItem.installation_mode === InstallationMode.PRE_INSTALLED &&
          !currentItem.is_price_inclusive) ||
        currentItem.installation_mode !== InstallationMode.PRE_INSTALLED
          ? currentItem.rv_balloon_percentage
          : 0),
      0
    ) || 0)

  useEffect(() => {
    if (quotationObject) {
      const quotationParameters: IQuotationParameters = {
        reference_number: quotationObject.reference_number,
        finance_type: quotationObject.finance_type,
        contract_term_id: quotationObject.contract_term,
        // ? states?.contractTermsData?.filter(
        //     (term: any) => term.term == quotationObject.contract_term
        //   )[0].id || 0
        // : 0,
        finance_down_payment: quotationObject.down_payment,
        lease_down_payment: quotationObject.down_payment,
        annual_mileage: quotationObject.allowed_usage,
        selling_price: quotationObject.selling_price,
        credit_rating: quotationObject.credit_rating,
        rental_frequency: quotationObject.rental_frequency,
        lease_fees: quotationObject.order_fees,
        finance_fees: quotationObject.order_fees,
        options: quotationObject.order_options,
        finance_fni: quotationObject.order_fnI,
        lease_fni: quotationObject.order_fnI,
        insurance: quotationObject.Insurance_Inforation,
        trade_in: quotationObject.order_tradein,
        asset_usage: quotationObject.asset_usage,
        schedule_option: quotationObject.schedule_option,
        lease_rv_rate: quotationObject.rv_balloon_value,
        finance_rv_rate: quotationObject.rv_balloon_value,
        lease_rate: quotationObject.apr,
        finance_rate: quotationObject.apr,
        capitalized_cost_reduction: quotationObject.capitalized_cost_reduction,
        rebate: quotationObject.rebate,
        tax_amount: quotationObject.tax_amount,
        tax_amount_number: quotationObject.tax_amount_number,
        net_finance_amount: quotationObject.net_finance_amount,
        finance_amount: quotationObject.finance_amount,
        total_rv_value: quotationObject.total_rv_value
      }
      setQuotationParameterData(quotationParameters)
      setTradeInEquityValue(
        parseInt(quotationObject.order_tradein?.trade_in_amount || 0) -
          parseInt(quotationObject.order_tradein?.payoff_amount || 0)
      )
    }
  }, [quotationObject])
  const [leaseFees, setLeaseFees] = useState<any>([])
  useEffect(() => {
    if (quotationParameterData) {
      setLeaseFees([
        ...(quotationParameterData?.lease_fees || []),
        ...(quotationParameterData?.finance_fees || [])
      ])
    }
  }, [quotationParameterData])

  const dealerFee =
    leaseObj?.finance_type == 'Finance'
      ? quotationParameterData?.finance_fees
      : quotationParameterData?.lease_fees

  const fni =
    leaseObj?.finance_type == 'Finance'
      ? quotationParameterData?.finance_fni ?? []
      : quotationParameterData?.lease_fni ?? []
  const queryClient = useQueryClient()
  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])
  const financedAmount =
    (calculationData?.selling_price ? calculationData?.selling_price : 0) -
    (calculationData?.down_payment ? calculationData?.down_payment : 0) +
    (dealerFee?.reduce((total: number, currentItem: any) => total + currentItem.applied_price, 0) ||
      0) +
    (fni?.reduce((total: number, currentItem: any) => total + currentItem.applied_price, 0) || 0) +
    (quotationObject?.order_options?.reduce(
      (total: number, currentItem: any) =>
        total +
        ((currentItem.installation_mode === InstallationMode.PRE_INSTALLED &&
          !currentItem.is_price_inclusive) ||
        currentItem.installation_mode !== InstallationMode.PRE_INSTALLED
          ? currentItem.applied_price || currentItem.price
          : 0),
      0
    ) || 0) -
    (tradeInEquityValue || 0)
  useEffect(() => {
    actions.setStipulationsInitialData({
      ...states.stipulationsData,
      monthly_payment: calculationData?.estimated_monthly_payment,
      net_finance_amount: financedAmount
    })
  }, [calculationData?.estimated_monthly_payment, financedAmount])
  const { control } = form
  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )

    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0].symbol : ''
    )
  }, [currencies])
  const theme = useTheme()

  const [open, setOpen] = useState({
    selling_price: false,
    finance_down_payment: false,
    lease_down_payment: false,
    lease_rv_rate: false,
    finance_rv_rate: false,
    fees: leaseFees?.map(() => false) ?? [],
    fni: fni && fni?.length > 0 ? fni?.map(() => false) : [],
    options: sortedOptionsArray?.map(() => false) ?? [],
    trade_in_equity: false
  })
  return (
    <>
      <Box theme={theme} className="title-bg">
        {leaseObj?.finance_type}
      </Box>
      <Box theme={theme} className="package-body">
        <Box theme={theme} className="package-row editable">
          <Box theme={theme}>
            <b>
              <span className="symbol">{defaultCurrency}</span>
              {calculationData?.estimated_monthly_payment
                ? calculationData?.estimated_monthly_payment.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })
                : '-'}
            </b>
          </Box>
        </Box>
        {selectedAccordion.periodicPayment && (
          <>
            <Box theme={theme} className="package-row editable">
              <span className="symbol">{defaultCurrency}</span>
              {calculationData?.estimated_monthly_payment
                ? calculationData?.estimated_monthly_payment.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })
                : '-'}
            </Box>
            <Box theme={theme} className="package-row editable">
              {quotationParameterData?.tax_amount_number
                ? quotationParameterData?.tax_amount_number.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })
                : '-'}
            </Box>
          </>
        )}

        <Box theme={theme} className="package-row editable">
          <b>
            <span className="symbol">{defaultCurrency}</span>
            {calculationData?.due_at_signing
              ? leaseObj?.rental_mode == 'Advance'
                ? (
                    ((quotationParameterData?.selling_price || 0) *
                      ((leaseObj?.finance_type == 'Finance'
                        ? quotationParameterData?.finance_down_payment
                        : quotationParameterData?.lease_down_payment) || 0)) /
                      100 +
                    (calculationData?.estimated_monthly_payment || 0)
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })
                : (
                    ((quotationParameterData?.selling_price || 0) *
                      ((leaseObj?.finance_type == 'Finance'
                        ? quotationParameterData?.finance_down_payment
                        : quotationParameterData?.lease_down_payment) || 0)) /
                    100
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })
              : '-'}
          </b>
        </Box>
        <Box theme={theme} className="package-row editable right-align">
          <Box theme={theme}>
            {quotationParameterData?.contract_term_id
              ? quotationParameterData?.contract_term_id
              : '-'}
          </Box>
        </Box>
        <Box theme={theme} className="package-row editable right-align">
          {(
            leaseObj?.finance_type == 'Finance'
              ? quotationParameterData?.finance_rate
              : quotationParameterData?.lease_rate
          )
            ? (
                (leaseObj?.finance_type == 'Finance'
                  ? quotationParameterData?.finance_rate
                  : quotationParameterData?.lease_rate) || 0
              ).toLocaleString(undefined, {
                maximumFractionDigits: 4,
                minimumFractionDigits: 4
              })
            : '-'}
          <span className="symbol">%</span>
        </Box>

        <Box theme={theme} className="package-row editable">
          {leaseObj?.finance_type === 'Finance' && '-'}
          {leaseObj?.finance_type === 'Lease' &&
            (quotationParameterData?.annual_mileage ? (
              <>
                {quotationParameterData?.annual_mileage}
                <span className="symbol">{states?.lenderInfo?.mileage_unit}</span>
              </>
            ) : (
              '-'
            ))}
        </Box>

        <Box theme={theme} className="package-row blank">
          <hr />
        </Box>
        <Box theme={theme} className="package-row editable">
          {!open.selling_price && quotationParameterData?.selling_price && (
            <>
              <span className="symbol">{defaultCurrency}</span>

              {quotationParameterData?.selling_price
                ? parseFloat(quotationParameterData?.selling_price.toString()).toLocaleString(
                    undefined,
                    {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    }
                  )
                : '-'}
            </>
          )}
          {open.selling_price && (
            <Box theme={theme} className="editable-content">
              <Controller
                name="selling_price"
                control={control}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    placeholder={'0.00'}
                    type="decimal"
                    startAdornment={<span className="start-adornment">{defaultCurrency}</span>}
                    onChange={field.onChange}
                    value={field.value}
                    masking
                    maskDecimalScale={2}
                    maskNumeric
                  />
                )}
              />
              <Button
                theme={theme}
                defaultBtn
                iconText={<Icon name="TickIcon" />}
                onClick={() => {
                  setOpen({ ...open, selling_price: !open.selling_price })
                  setQuotationParameterData({
                    ...quotationParameterData,
                    selling_price: parseFloat(form.getValues()?.selling_price)
                  })
                }}
              />
              <Button
                theme={theme}
                defaultBtn
                iconText={<Icon name="CloseBlack" />}
                onClick={() => {
                  setOpen({ ...open, selling_price: !open.selling_price })
                  form.setValue('selling_price', quotationParameterData?.selling_price)
                }}
              />
            </Box>
          )}
        </Box>
        <Box theme={theme} className="package-row editable right-align">
          {!(leaseObj?.finance_type == 'Finance'
            ? open.finance_down_payment
            : open.lease_down_payment) &&
            (leaseObj?.finance_type == 'Finance'
              ? quotationParameterData?.finance_down_payment
              : quotationParameterData?.lease_down_payment) && (
              <>
                {leaseObj?.finance_type == 'Finance' && quotationParameterData?.finance_down_payment
                  ? '(' +
                    (quotationParameterData?.finance_down_payment
                      ? quotationParameterData?.finance_down_payment
                      : 0
                    ).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    }) +
                    '%) '
                  : leaseObj?.finance_type == 'Lease' && quotationParameterData?.lease_down_payment
                  ? '(' +
                    (quotationParameterData?.lease_down_payment
                      ? quotationParameterData?.lease_down_payment
                      : 0
                    ).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    }) +
                    '%) '
                  : ''}
                <span className="symbol">{defaultCurrency}</span>
                {leaseObj?.finance_type == 'Finance' && quotationParameterData?.finance_down_payment
                  ? (
                      ((quotationParameterData?.selling_price || 0) *
                        quotationParameterData?.finance_down_payment) /
                      100
                    ).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })
                  : leaseObj?.finance_type == 'Lease' && quotationParameterData?.lease_down_payment
                  ? (
                      ((quotationParameterData?.selling_price || 0) *
                        quotationParameterData?.lease_down_payment) /
                      100
                    ).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })
                  : '-'}
              </>
            )}
          {(leaseObj?.finance_type == 'Finance'
            ? open.finance_down_payment
            : open.lease_down_payment) && (
            <Box theme={theme} className="editable-content">
              {leaseObj?.downpayment_chart && (
                <small className="caption">
                  Value should be in between{' '}
                  {leaseObj?.downpayment_chart.length > 0 &&
                    leaseObj?.downpayment_chart[0].minimum_down_payment}
                  % to{' '}
                  {leaseObj?.downpayment_chart.length > 0 &&
                    leaseObj?.downpayment_chart[0].maximum_down_payment}
                  %
                </small>
              )}
              <Controller
                name={
                  leaseObj?.finance_type == 'Finance'
                    ? 'finance_down_payment'
                    : 'lease_down_payment'
                }
                control={control}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    placeholder={'0.00'}
                    type="decimal"
                    endAdornment={<span className="symbol">%</span>}
                    onChange={field.onChange}
                    value={field.value}
                    masking
                    maskDecimalScale={2}
                    maskNumeric
                  />
                )}
              />
              <Button
                theme={theme}
                defaultBtn
                iconText={<Icon name="TickIcon" />}
                onClick={() => {
                  const is_down_payment_valid =
                    (leaseObj?.downpayment_chart &&
                      leaseObj?.downpayment_chart[0].maximum_down_payment) >=
                      (leaseObj?.finance_type == 'Finance'
                        ? form.getValues().finance_down_payment
                        : form.getValues().lease_down_payment) &&
                    (leaseObj?.downpayment_chart &&
                      leaseObj?.downpayment_chart[0].minimum_down_payment) <=
                      (leaseObj?.finance_type == 'Finance'
                        ? form.getValues().finance_down_payment
                        : form.getValues().lease_down_payment)
                  if (is_down_payment_valid || !leaseObj?.downpayment_chart) {
                    setOpen({
                      ...open,
                      [leaseObj?.finance_type == 'Finance'
                        ? 'finance_down_payment'
                        : 'lease_down_payment']: !(leaseObj?.finance_type == 'Finance'
                        ? open.finance_down_payment
                        : open.lease_down_payment)
                    })
                    setQuotationParameterData({
                      ...quotationParameterData,
                      [leaseObj?.finance_type == 'Finance'
                        ? 'finance_down_payment'
                        : 'lease_down_payment']:
                        leaseObj?.finance_type == 'Finance'
                          ? form.getValues().finance_down_payment
                          : form.getValues().lease_down_payment
                    })
                  }
                }}
              />
              <Button
                theme={theme}
                defaultBtn
                iconText={<Icon name="CloseBlack" />}
                onClick={() => {
                  setOpen({
                    ...open,
                    [leaseObj?.finance_type == 'Finance'
                      ? 'finance_down_payment'
                      : 'lease_down_payment']: !(leaseObj?.finance_type == 'Finance'
                      ? open.finance_down_payment
                      : open.lease_down_payment)
                  })
                  leaseObj?.finance_type == 'Lease'
                    ? form.setValue(
                        'lease_down_payment',
                        quotationParameterData?.lease_down_payment
                      )
                    : form.setValue(
                        'finance_down_payment',
                        quotationParameterData?.finance_down_payment
                      )
                }}
              />
            </Box>
          )}
        </Box>
        <Box theme={theme} className="package-row editable">
          {totalRV > 0 ? (
            <>
              <span className="symbol">{defaultCurrency}</span>
              {totalRV.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })}
            </>
          ) : (
            '-'
          )}
        </Box>
        {selectedAccordion.rvBalloon && (
          <>
            <Box theme={theme} className="package-row editable right-align">
              {!(leaseObj?.finance_type == 'Finance'
                ? open.finance_rv_rate
                : open.lease_rv_rate) && (
                <>
                  {(leaseObj?.finance_type == 'Finance' &&
                    quotationParameterData?.finance_rv_rate) ||
                  (leaseObj?.finance_type == 'Lease' && quotationParameterData?.lease_rv_rate)
                    ? leaseObj?.finance_type == 'Finance'
                      ? '(' + quotationParameterData?.finance_rv_rate + '%) '
                      : '(' + quotationParameterData?.lease_rv_rate + '%) '
                    : ''}
                  {(leaseObj?.finance_type == 'Finance' &&
                    quotationParameterData?.finance_rv_rate) ||
                  (leaseObj?.finance_type == 'Lease' && quotationParameterData?.lease_rv_rate) ? (
                    <>
                      <span className="symbol">{defaultCurrency}</span>
                      {(
                        ((quotationParameterData?.selling_price || 0) *
                          ((leaseObj?.finance_type == 'Finance'
                            ? quotationParameterData?.finance_rv_rate
                            : quotationParameterData?.lease_rv_rate) || 0)) /
                        100
                      ).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </>
                  ) : (
                    '-'
                  )}
                </>
              )}
              {(leaseObj?.finance_type == 'Finance'
                ? open.finance_rv_rate
                : open.lease_rv_rate) && (
                <Box theme={theme} className="editable-content">
                  <Controller
                    name={leaseObj?.finance_type == 'Finance' ? 'finance_rv_rate' : 'lease_rv_rate'}
                    control={control}
                    render={({ field }) => (
                      <Input
                        theme={theme}
                        placeholder={'0.00'}
                        type="decimal"
                        endAdornment={<span className="symbol">%</span>}
                        onChange={field.onChange}
                        value={field.value}
                        masking
                        maskDecimalScale={2}
                        maskNumeric
                      />
                    )}
                  />
                  <Button
                    theme={theme}
                    defaultBtn
                    iconText={<Icon name="TickIcon" />}
                    onClick={() => {
                      const is_rv_rate_valid =
                        (leaseObj?.rv_chart && leaseObj?.rv_chart[0].maximum_rv) >=
                          (leaseObj?.finance_type == 'Finance'
                            ? Number(form.getValues().finance_rv_rate)
                            : Number(form.getValues().lease_rv_rate)) &&
                        (leaseObj?.rv_chart && leaseObj?.rv_chart[0].minimum_rv) <=
                          (leaseObj?.finance_type == 'Finance'
                            ? Number(form.getValues().finance_rv_rate)
                            : Number(form.getValues().lease_rv_rate))
                      if (is_rv_rate_valid || !leaseObj?.rv_chart) {
                        setOpen({
                          ...open,
                          [leaseObj?.finance_type == 'Finance'
                            ? 'finance_rv_rate'
                            : 'lease_rv_rate']: !(leaseObj?.finance_type == 'Finance'
                            ? open.finance_rv_rate
                            : open.lease_rv_rate)
                        })
                        setQuotationParameterData({
                          ...quotationParameterData,
                          [leaseObj?.finance_type == 'Finance'
                            ? 'finance_rv_rate'
                            : 'lease_rv_rate']:
                            leaseObj?.finance_type == 'Finance'
                              ? form.getValues().finance_rv_rate
                              : form.getValues().lease_rv_rate
                        })
                      }
                    }}
                  />
                  <Button
                    theme={theme}
                    defaultBtn
                    iconText={<Icon name="CloseBlack" />}
                    onClick={() => {
                      setOpen({
                        ...open,
                        [leaseObj?.finance_type == 'Finance' ? 'finance_rv_rate' : 'lease_rv_rate']:
                          !(leaseObj?.finance_type == 'Finance'
                            ? open.finance_rv_rate
                            : open.lease_rv_rate)
                      })
                    }}
                  />
                </Box>
              )}
            </Box>
            <Box theme={theme} className="package-row editable">
              {quotationParameterData?.options?.reduce(
                (total: number, currentItem: any) =>
                  total +
                  ((currentItem.installation_mode === InstallationMode.PRE_INSTALLED &&
                    !currentItem.is_price_inclusive) ||
                  currentItem.installation_mode !== InstallationMode.PRE_INSTALLED
                    ? currentItem.rv_balloon_percentage
                    : 0),
                0
              ) > 0 ? (
                <>
                  <span className="symbol">{defaultCurrency}</span>
                  {quotationParameterData?.options
                    ?.reduce(
                      (total: number, currentItem: any) =>
                        total +
                        ((currentItem.installation_mode === InstallationMode.PRE_INSTALLED &&
                          !currentItem.is_price_inclusive) ||
                        currentItem.installation_mode !== InstallationMode.PRE_INSTALLED
                          ? currentItem.rv_balloon_percentage
                          : 0),
                      0
                    )
                    .toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}
                </>
              ) : (
                '-'
              )}
            </Box>
          </>
        )}
        <Box theme={theme} className="package-row editable">
          {quotationParameterData?.options && quotationParameterData?.options?.length > 0 ? (
            <>
              {quotationParameterData?.options?.reduce(
                (total: number, currentItem: any) =>
                  total +
                  ((currentItem.installation_mode === InstallationMode.PRE_INSTALLED &&
                    !currentItem.is_price_inclusive) ||
                  currentItem.installation_mode !== InstallationMode.PRE_INSTALLED
                    ? currentItem.applied_price || currentItem.price
                    : 0),
                0
              ) > 0 ? (
                <>
                  <span className="symbol">{defaultCurrency}</span>
                  {(
                    quotationParameterData?.options?.reduce(
                      (total: number, currentItem: any) =>
                        total +
                        ((currentItem.installation_mode === InstallationMode.PRE_INSTALLED &&
                          !currentItem.is_price_inclusive) ||
                        currentItem.installation_mode !== InstallationMode.PRE_INSTALLED
                          ? currentItem.applied_price || currentItem.price
                          : 0),
                      0
                    ) || 0
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}{' '}
                </>
              ) : (
                '-'
              )}
            </>
          ) : (
            '-'
          )}
        </Box>
        {selectedAccordion.options &&
          sortedOptionsArray?.length > 0 &&
          sortedOptionsArray?.map((obj: any, index: any) => (
            <div key={index}>
              {sortedOptionsArray[index - 1]?.installation_mode !== obj?.installation_mode && (
                <Box theme={theme} className="package-row blank"></Box>
              )}
              <Box theme={theme} className="package-row editable">
                {!open.options[index] && (
                  <>
                    {quotationParameterData?.options?.some(
                      (option: any) => option.option_id === obj.option_id
                    ) &&
                    ((obj.installation_mode === InstallationMode.PRE_INSTALLED &&
                      !obj.is_price_inclusive) ||
                      obj.installation_mode !== InstallationMode.PRE_INSTALLED) ? (
                      <>
                        <span className="symbol">{defaultCurrency}</span>
                        {(obj?.price || obj?.applied_price)?.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}

                        {open.selling_price && (
                          <Button
                            theme={theme}
                            defaultBtn
                            className="btn-edit"
                            iconText={
                              <Icon
                                name="EditIcon"
                                onClick={() => {
                                  const openOptions = open.options
                                  openOptions[index] = !openOptions[index]
                                  setOpen({ ...open, options: openOptions })
                                }}
                              />
                            }
                          />
                        )}
                      </>
                    ) : (
                      '-'
                    )}
                  </>
                )}
                {open.options[index] && (
                  <Box theme={theme} className="editable-content">
                    <Controller
                      name="text"
                      control={control}
                      render={({ field }) => (
                        <Input
                          theme={theme}
                          placeholder={'0.00'}
                          type="decimal"
                          startAdornment={
                            <span className="start-adornment">{defaultCurrency}</span>
                          }
                          onChange={field.onChange}
                          value={field.value}
                          masking
                          maskDecimalScale={2}
                          maskNumeric
                        />
                      )}
                    />
                    <Button
                      theme={theme}
                      defaultBtn
                      iconText={<Icon name="TickIcon" />}
                      onClick={() => {
                        const openOptions = open.options
                        openOptions[index] = !openOptions[index]
                        setOpen({ ...open, options: openOptions })
                      }}
                    />
                    <Button
                      theme={theme}
                      defaultBtn
                      iconText={<Icon name="CloseBlack" />}
                      onClick={() => {
                        const openOptions = open.options
                        openOptions[index] = !openOptions[index]
                        setOpen({ ...open, options: openOptions })
                      }}
                    />
                  </Box>
                )}
              </Box>
            </div>
          ))}
        <Box theme={theme} className="package-row editable">
          {leaseFees && leaseFees?.length > 0 ? (
            <>
              <span className="symbol">{defaultCurrency}</span>
              {leaseFees
                ?.reduce((total: number, currentItem: any) => total + currentItem.applied_price, 0)
                .toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}
            </>
          ) : (
            '-'
          )}
        </Box>
        {selectedAccordion.fees &&
          feeData?.map((obj: any, index: any) => (
            <div key={index}>
              <Box theme={theme} className="package-row editable">
                {!open.fees[index] && (
                  <>
                    {dealerFee?.some((fee: any) => fee.dealer_fee_id === obj.dealer_fee_id) ? (
                      <>
                        <span className="symbol">{defaultCurrency}</span>
                        {dealerFee
                          ?.filter((fee: any) => fee.dealer_fee_id == obj.dealer_fee_id)[0]
                          ?.applied_price?.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          })}

                        {open.selling_price && (
                          <Button
                            theme={theme}
                            defaultBtn
                            className="btn-edit"
                            iconText={
                              <Icon
                                name="EditIcon"
                                onClick={() => {
                                  const openFees = open.fees
                                  openFees[index] = !openFees[index]
                                  setOpen({ ...open, fees: openFees })
                                }}
                              />
                            }
                          />
                        )}
                      </>
                    ) : (
                      '-'
                    )}
                  </>
                )}
                {open.fees[index] && (
                  <Box theme={theme} className="editable-content">
                    <Controller
                      name={
                        leaseObj.finance_type == 'Finance'
                          ? `finance_fees[${index}].applied_price`
                          : `lease_fees[${index}].applied_price`
                      }
                      control={control}
                      render={({ field }) => (
                        <Input
                          theme={theme}
                          placeholder={'0.00'}
                          type="decimal"
                          startAdornment={
                            <span className="start-adornment">{defaultCurrency}</span>
                          }
                          onChange={field.onChange}
                          value={field.value}
                          masking
                          maskDecimalScale={2}
                          maskNumeric
                        />
                      )}
                    />
                    <Button
                      theme={theme}
                      defaultBtn
                      iconText={<Icon name="TickIcon" />}
                      onClick={() => {
                        const openFees = open.fees
                        openFees[index] = !openFees[index]
                        setOpen({ ...open, fees: openFees })
                        setQuotationParameterData({
                          ...quotationParameterData,
                          [leaseObj?.finance_type == 'Finance' ? 'finance_fees' : 'lease_fees']:
                            leaseObj?.finance_type == 'Finance'
                              ? quotationParameterData?.finance_fees?.map((fee: any, i: number) =>
                                  i == index
                                    ? {
                                        ...fee,
                                        applied_price: parseFloat(
                                          form.getValues().finance_fees[index].applied_price
                                        )
                                      }
                                    : fee
                                )
                              : quotationParameterData?.lease_fees?.map((fee: any, i: number) =>
                                  i == index
                                    ? {
                                        ...fee,
                                        applied_price: parseFloat(
                                          form.getValues().lease_fees[index].applied_price
                                        )
                                      }
                                    : fee
                                )
                        })
                      }}
                    />
                    <Button
                      theme={theme}
                      defaultBtn
                      iconText={<Icon name="CloseBlack" />}
                      onClick={() => {
                        const openFees = open.fees
                        openFees[index] = !openFees[index]
                        setOpen({ ...open, fees: openFees })
                        leaseObj?.finance_type == 'Lease'
                          ? form.setValue(
                              `lease_fees.[${index}].applied_price`,
                              quotationParameterData?.lease_fees &&
                                quotationParameterData?.lease_fees[index]?.applied_price
                            )
                          : form.setValue(
                              `finance_fees.[${index}].applied_price`,
                              quotationParameterData?.finance_fees &&
                                quotationParameterData?.finance_fees[index]?.applied_price
                            )
                      }}
                    />
                  </Box>
                )}
              </Box>
            </div>
          ))}

        <Box theme={theme} className="package-row editable">
          {fni && fni?.length > 0 ? (
            <>
              <span className="symbol">{defaultCurrency}</span>
              {fni
                ?.reduce((total: number, currentItem: any) => total + currentItem.applied_price, 0)
                .toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}
            </>
          ) : (
            '-'
          )}
        </Box>
        {selectedAccordion.fIProducts &&
          fnIData?.map((obj: any, index: any) => (
            <div key={index}>
              <Box theme={theme} className="package-row editable">
                {!open.fni[index] && (
                  <>
                    {fni?.some(
                      (fni: any) => fni.financial_insurance_id === obj.financial_insurance_id
                    ) ? (
                      <>
                        <span className="symbol">{defaultCurrency}</span>
                        {fni
                          ?.filter(
                            (fni: any) => fni.financial_insurance_id == obj.financial_insurance_id
                          )[0]
                          ?.applied_price?.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          })}

                        {open.selling_price && (
                          <Button
                            theme={theme}
                            defaultBtn
                            className="btn-edit"
                            iconText={
                              <Icon
                                name="EditIcon"
                                onClick={() => {
                                  const openFNI = open.fni
                                  openFNI[index] = !openFNI[index]
                                  setOpen({ ...open, fni: openFNI })
                                }}
                              />
                            }
                          />
                        )}
                      </>
                    ) : (
                      '-'
                    )}
                  </>
                )}
                {open.fni[index] && (
                  <Box theme={theme} className="editable-content">
                    <Controller
                      name={
                        leaseObj.finance_type == 'Finance'
                          ? `finance_fni[${index}].price`
                          : `lease_fni[${index}].price`
                      }
                      control={control}
                      render={({ field }) => (
                        <Input
                          theme={theme}
                          placeholder={'0.00'}
                          type="decimal"
                          startAdornment={
                            <span className="start-adornment">{defaultCurrency}</span>
                          }
                          onChange={field.onChange}
                          value={field.value}
                          masking
                          maskDecimalScale={2}
                          maskNumeric
                        />
                      )}
                    />
                    <Button
                      theme={theme}
                      defaultBtn
                      iconText={<Icon name="TickIcon" />}
                      onClick={() => {
                        const openFNI = open.fni
                        openFNI[index] = !openFNI[index]
                        setOpen({ ...open, fni: openFNI })
                        setQuotationParameterData({
                          ...quotationParameterData,
                          [leaseObj?.finance_type == 'Finance' ? 'finance_fni' : 'lease_fni']:
                            leaseObj?.finance_type == 'Finance'
                              ? quotationParameterData?.finance_fni?.map((fni: any, i: number) =>
                                  i == index
                                    ? {
                                        ...fni,
                                        price: parseFloat(form.getValues().finance_fni[index].price)
                                      }
                                    : fni
                                )
                              : quotationParameterData?.lease_fni?.map((fni: any, i: number) =>
                                  i == index
                                    ? {
                                        ...fni,
                                        price: parseFloat(form.getValues().lease_fni[index].price)
                                      }
                                    : fni
                                )
                        })
                      }}
                    />
                    <Button
                      theme={theme}
                      defaultBtn
                      iconText={<Icon name="CloseBlack" />}
                      onClick={() => {
                        const openFNI = open.fni
                        openFNI[index] = !openFNI[index]
                        setOpen({ ...open, fni: openFNI })
                        leaseObj?.finance_type == 'Lease'
                          ? form.setValue(
                              `lease_fni.[${index}].applied_price`,
                              quotationParameterData?.lease_fni &&
                                quotationParameterData?.lease_fni[index]?.applied_price
                            )
                          : form.setValue(
                              `finance_fni.[${index}].applied_price`,
                              quotationParameterData?.finance_fni &&
                                quotationParameterData?.finance_fni[index]?.applied_price
                            )
                      }}
                    />
                  </Box>
                )}
              </Box>
            </div>
          ))}
        <Box theme={theme} className="package-row editable">
          {tradeInEquityValue && tradeInEquityValue > 0 ? (
            <>
              <span className="symbol">{defaultCurrency}</span>
              {Math.abs(tradeInEquityValue).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })}
            </>
          ) : tradeInEquityValue && tradeInEquityValue < 0 ? (
            <>
              {'-'}
              <span className="symbol">{defaultCurrency}</span>
              {Math.abs(tradeInEquityValue).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })}
            </>
          ) : (
            '-'
          )}
        </Box>
        <Box theme={theme} className="package-row editable">
          {'-'}
        </Box>
        <Box theme={theme} className="package-row divider-row">
          <hr />
        </Box>
        <Box theme={theme} className="package-row">
          <b>
            {quotationParameterData?.net_finance_amount ? (
              <>
                <span className="symbol">{defaultCurrency}</span>
                {quotationParameterData?.net_finance_amount.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}
              </>
            ) : (
              '-'
            )}
          </b>
        </Box>
        {selectedAccordion.nfa && (
          <>
            <Box theme={theme} className="package-row">
              {quotationParameterData?.finance_amount ? (
                <>
                  <span className="symbol">{defaultCurrency}</span>
                  {quotationParameterData?.finance_amount.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}
                </>
              ) : (
                '-'
              )}
            </Box>

            {selectedAccordion.nfaList && (
              <>
                <Box theme={theme} className="package-row">
                  <span className="symbol">$</span>
                  {quotationParameterData?.selling_price
                    ? parseFloat(quotationParameterData?.selling_price.toString()).toLocaleString(
                        undefined,
                        {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        }
                      )
                    : '-'}
                </Box>
                <Box theme={theme} className="package-row">
                  {quotationParameterData?.options &&
                  quotationParameterData?.options?.length > 0 ? (
                    <>
                      {quotationParameterData?.options?.reduce(
                        (total: number, currentItem: any) =>
                          total +
                          ((currentItem.installation_mode === InstallationMode.PRE_INSTALLED &&
                            !currentItem.is_price_inclusive) ||
                          currentItem.installation_mode !== InstallationMode.PRE_INSTALLED
                            ? currentItem.applied_price || currentItem.price
                            : 0),
                        0
                      ) > 0 ? (
                        <>
                          <span className="symbol">{defaultCurrency}</span>
                          {(
                            quotationParameterData?.options?.reduce(
                              (total: number, currentItem: any) =>
                                total +
                                ((currentItem.installation_mode ===
                                  InstallationMode.PRE_INSTALLED &&
                                  !currentItem.is_price_inclusive) ||
                                currentItem.installation_mode !== InstallationMode.PRE_INSTALLED
                                  ? currentItem.applied_price || currentItem.price
                                  : 0),
                              0
                            ) || 0
                          ).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          })}{' '}
                        </>
                      ) : (
                        '-'
                      )}
                    </>
                  ) : (
                    '-'
                  )}
                </Box>
                <Box theme={theme} className="package-row">
                  {fni && fni?.length > 0 ? (
                    <>
                      <span className="symbol">{defaultCurrency}</span>
                      {fni
                        ?.reduce(
                          (total: number, currentItem: any) => total + currentItem.applied_price,
                          0
                        )
                        .toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                    </>
                  ) : (
                    '-'
                  )}
                </Box>
                <Box theme={theme} className="package-row">
                  {leaseFees && leaseFees?.length > 0 ? (
                    <>
                      <span className="symbol">{defaultCurrency}</span>
                      {leaseFees
                        ?.reduce(
                          (total: number, currentItem: any) => total + currentItem.applied_price,
                          0
                        )
                        .toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                    </>
                  ) : (
                    '-'
                  )}
                </Box>
                <Box theme={theme} className="package-row">
                  {tradeInEquityValue && tradeInEquityValue < 0 ? (
                    <>
                      {'-'}
                      <span className="symbol">{defaultCurrency}</span>
                      {Math.abs(tradeInEquityValue).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </>
                  ) : (
                    '-'
                  )}
                </Box>
                <Box theme={theme} className="package-row">
                  {quotationParameterData?.tax_amount > 0 ? (
                    <>
                      <span className="symbol">{defaultCurrency}</span>
                      {quotationParameterData?.tax_amount.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </>
                  ) : (
                    '-'
                  )}
                </Box>
              </>
            )}
            <Box theme={theme} className="package-row">
              {quotationParameterData?.capitalized_cost_reduction ? (
                <>
                  <span className="symbol">{defaultCurrency}</span>
                  {quotationParameterData?.capitalized_cost_reduction.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}
                </>
              ) : (
                '-'
              )}
            </Box>
            {selectedAccordion.ccr && (
              <>
                <Box theme={theme} className="package-row">
                  {quotationParameterData?.lease_down_payment && (
                    <>
                      {leaseObj?.finance_type == 'Finance' &&
                      quotationParameterData?.finance_down_payment
                        ? '(' +
                          (quotationParameterData?.finance_down_payment
                            ? quotationParameterData?.finance_down_payment
                            : 0
                          ).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          }) +
                          '%) '
                        : leaseObj?.finance_type == 'Lease' &&
                          quotationParameterData?.lease_down_payment
                        ? '(' +
                          (quotationParameterData?.lease_down_payment
                            ? quotationParameterData?.lease_down_payment
                            : 0
                          ).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          }) +
                          '%) '
                        : ''}
                      <span className="symbol">{defaultCurrency}</span>
                      {leaseObj?.finance_type == 'Finance' &&
                      quotationParameterData?.finance_down_payment
                        ? (
                            ((quotationParameterData?.selling_price || 0) *
                              quotationParameterData?.finance_down_payment) /
                            100
                          ).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          })
                        : leaseObj?.finance_type == 'Lease' &&
                          quotationParameterData?.lease_down_payment
                        ? (
                            ((quotationParameterData?.selling_price || 0) *
                              quotationParameterData?.lease_down_payment) /
                            100
                          ).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          })
                        : '-'}
                    </>
                  )}
                </Box>
                <Box theme={theme} className="package-row">
                  {tradeInEquityValue && tradeInEquityValue > 0 ? (
                    <>
                      <span className="symbol">{defaultCurrency}</span>
                      {Math.abs(tradeInEquityValue).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </>
                  ) : (
                    '-'
                  )}
                </Box>
                <Box theme={theme} className="package-row">
                  {/* <span className="symbol">$</span> */}
                  {quotationParameterData?.rebate ? (
                    <>
                      <span className="symbol">{defaultCurrency}</span>
                      {quotationParameterData?.rebate.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </>
                  ) : (
                    '-'
                  )}
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </>
  )
}

export default ViewOrders
