import React, { useEffect, useMemo, useState } from 'react'
import { TextField, useTheme } from '@mui/material'
import {
  Grid,
  Typography,
  Box,
  Button,
  Icon,
  Menu,
  Autocomplete,
  Skeleton
} from '@ntpkunity/controls'
import ProductsBundleList from './products-bundle-list'
import { ProductsBundleWrap } from './product-bundles.styles'
import {
  useGetAllFinancialInsuranceProductBundlesByDealerCode,
  useGetFniMonthlyImpacts,
  useGetFnIProductRate
} from '@apis/financial-insurance.service'
import { useStoreContext } from '@store/storeContext'
import { createFniMonthlyImpactPayloadFromState } from '@app/desking/utils'
import { FinanceTypes } from '@app/desking/desking-context-provider'

interface ProductsBundleProps {
  selectedFniItems: any
  setSelectedFniItems: any
  defaultCurrency: string
  terms: any
  allFniProducts: any
  applyRetailPrice: any
  dealerInfo?: any
  fniBundleChanges?: any
  setFniBundleChanges?: any
  deskingState: any
  productRates: any
  finance_type: any
  selectedVehicle: any
}

const ProductsBundle: React.FC<ProductsBundleProps> = ({
  selectedFniItems, 
  setSelectedFniItems, 
  productRates,
  allFniProducts,
  defaultCurrency,
  applyRetailPrice,
  dealerInfo,
  fniBundleChanges,
  setFniBundleChanges,
  deskingState,
  finance_type,
  selectedVehicle
}) => {
  const theme = useTheme()
  const { states } = useStoreContext()
  const [boxData, setBoxData] = useState<any[]>([])
  const [fniMenuAnchorEl, setFniMenuAchorEl] = useState<null | HTMLElement>(null)
  const [isBundleItemPriceChanged, setIsBundleItemPriceChanged] = useState(false)

  const { mutate: getAllProductRates, data: allProductRates } = useGetFnIProductRate()

  const { data: fniBundles } = useGetAllFinancialInsuranceProductBundlesByDealerCode(
    states?.dealerInfo?.dealer_code
  )
  const {
    mutate: getBundleFniMonthlyImpacts,
    data: bundleFniMonthlyImpacts,
    isLoading: loadingBundleFniMonthlyImpacts
  } = useGetFniMonthlyImpacts()

  const [selectedBundleIndex, setSelectedBundleIndex] = useState<any>(null)
  const selectedBundleFniItems = fniBundles?.[selectedBundleIndex]?.financial_products
  const selectedBundle = fniBundles?.[selectedBundleIndex]
  const disabledIds = selectedFniItems?.filter((fniItem: any) => !fniItem?.bundle_id)
        .map((fniItem: any) => fniItem?.financial_insurance_id ?? fniItem?.id)
  const selectedFniIds = selectedFniItems?.filter((fniItem: any) => fniItem?.bundle_id)
        .map((fniItem: any) => fniItem?.financial_insurance_id ?? fniItem?.id)
  const selectedFniBundleItems = selectedBundle?.financial_products
    ?.filter((product: any) => !product?.bundle_fp_status)
    ?.map((product: any) => product?.financial_product_id)
  const availableFniItems = allFniProducts?.filter(
    (item: any) =>
      !disabledIds?.includes(item?.financial_insurance_id ?? item?.id) &&
      !selectedFniIds?.includes(item?.financial_insurance_id ?? item?.id) &&
      !selectedFniBundleItems?.includes(item?.financial_insurance_id ?? item?.id)
  )

  const isCash = useMemo(() => finance_type.includes(FinanceTypes.CASH), [finance_type])

  useEffect(() => {
    if (selectedBundleIndex === null) {
      return
    }
    if (!selectedBundleFniItems?.length) {
      return
    }

    const fniItems = boxData?.[selectedBundleIndex]?.items ?? []
    const updatedItems = selectedFniItems?.filter((item: any) => !item?.bundle_id)
    setSelectedFniItems([...updatedItems, ...fniItems])
  }, [selectedBundleFniItems, selectedBundleIndex])

  useEffect(() => {
    if (!fniBundles) return
    const bundleId = selectedFniItems?.find((item: any) => item?.bundle_id)?.bundle_id
    const index = fniBundles?.findIndex((bundle: any) => bundle?.id === bundleId)
    if (index !== -1) {
      const fniIds = fniBundles?.[index]?.financial_products?.map(
        (product: any) => product?.financial_product_id
      )
      const selectedBundleItems = selectedFniItems?.filter((item: any) => {
        const isAlreadyAdded = fniBundleChanges?.[bundleId]?.added?.some(
          (addedItem: any) => addedItem?.id === item?.id
        )
        return (
          item?.bundle_id &&
          !fniIds?.includes(item?.financial_insurance_id ?? item?.id) &&
          !isAlreadyAdded
        )
      })
      selectedBundleItems?.forEach((item: any) => handleAddItem(item))
    }
    setSelectedBundleIndex(index !== -1 ? index : null)
  }, [fniBundles])

  useEffect(() => {
    if (selectedBundle) {
      const removed = fniBundleChanges?.[selectedBundle?.id]?.removed || []
      const added = fniBundleChanges?.[selectedBundle?.id]?.added || []
      const itemsInSelectedBundle = boxData?.[selectedBundleIndex]?.items || []

      const getProductId = (item: any) => {
        if (item?.product_id) return item?.product_id
        const matchingProduct = allFniProducts?.find(
          (product: any) => product?.id === (item?.financial_insurance_id ?? item?.id)
        )
        return matchingProduct?.product_id || null
      }

        const nonBundledFniItemsList = selectedFniItems?.filter((item: any) => !item?.bundle_id)

        const updatedFniItemsList = itemsInSelectedBundle
          ?.filter((item: any) => {
            const productId = getProductId(item)
            return !removed?.includes(productId)
          })
          ?.map((item: any) => {
            const productId = getProductId(item)
            const addedItem = added?.find((added: any) => getProductId(added) === productId)
            return addedItem || item
          })
          .concat(
            added?.filter(
              (addedItem: any) =>
                !itemsInSelectedBundle?.some(
                  (existingItem: any) => getProductId(existingItem) === getProductId(addedItem)
                )
            )
          )
        let itemsToAddBackInFniBundle = selectedFniItems?.filter((item: any) => {
          const productId = getProductId(item)
          return (
            item?.bundle_id &&
            !removed?.includes(productId) &&
            !updatedFniItemsList?.some((updatedItem: any) => getProductId(updatedItem) === productId)
          )
        })

        const finalLeaseItems = [
          ...updatedFniItemsList,
          ...nonBundledFniItemsList,
          ...itemsToAddBackInFniBundle
        ]
        setSelectedFniItems(finalLeaseItems)
    }
  }, [fniBundleChanges, selectedBundle])

  function getFniMonthlyImpact(item: any) {
    const products = bundleFniMonthlyImpacts?.products
    const monthlyImpact =
      Array.isArray(products) && products?.length > 0
        ? products.find(
            (itemInMonthlyImpact: any) =>
              itemInMonthlyImpact?.name === item?.product_name &&
              itemInMonthlyImpact?.price === item?.price
          )?.monthly_impact
        : 0
    return monthlyImpact || 0
  }

  useEffect(() => {
    if (isCash) return
    const allItems = boxData?.flatMap((bundle: any) => bundle?.items)

    const hasPrice = allItems?.some((item: any) => item?.price)

    if (!hasPrice) {
      return
    }

    const isValidPrice = (price: any) => price && price !== 0 && price !== '' && !isNaN(price)

    const bundleProducts = bundleFniMonthlyImpacts?.products

    const areAllMonthlyImpactsCovered = allItems.every(
      (item: any) =>
        !isValidPrice(item?.price) ||
        bundleProducts?.some(
          (bundleItem: any) =>
            bundleItem?.name === item?.product_name && bundleItem?.price === item?.price
        )
    )

    if (areAllMonthlyImpactsCovered) {
      return
    }

    const fniProductsPayload = allItems?.map((product: any) => {
      return {
        fni_product_name: product?.product_name,
        fni_product_amount: parseFloat(product?.price || 0),
        amount_handling: '',
        is_taxable: product?.taxable ?? false
      }
    })

    const requestObject = createFniMonthlyImpactPayloadFromState(
      deskingState,
      states,
      fniProductsPayload,
      selectedVehicle
    )

    if (!loadingBundleFniMonthlyImpacts) {
      getBundleFniMonthlyImpacts({ body: requestObject, type: 'bundle' })
    }

    setIsBundleItemPriceChanged(false)
  }, [boxData, isBundleItemPriceChanged])

  useEffect(() => {
    const newBoxData = fniBundles?.map((bundle: any) => {
      let addedItems = Array.isArray(fniBundleChanges?.[bundle?.id]?.added)
        ? fniBundleChanges[bundle?.id]?.added
        : []

      let bundleItems =
        bundle?.financial_products?.filter((product: any) => product?.bundle_fp_status) || []

      const removedBundleItems = fniBundleChanges?.[bundle?.id]?.removed || []

      let transformedBundleItems = bundleItems
        ?.map((product: any) => ({
          ...(product?.financial_insurance_product ?? product),
          bundle_id: product?.bundle_id,
          bundle_markup: bundle?.markup
        }))
        ?.filter((bundeItem: any) => !removedBundleItems?.includes(bundeItem?.product_id))

      const filteredBundleItems = transformedBundleItems?.filter((bundleItem: any) => {
        const matchingSelectedItems = selectedFniItems?.filter((selectedItem: any) => selectedItem?.bundle_id === bundleItem?.bundle_id)

        if (matchingSelectedItems?.length === 0) {
          return true
        }

        return matchingSelectedItems?.some(
          (selectedItem: any) =>
            selectedItem?.product_id === bundleItem?.product_id ||
            bundleItem?.id === (selectedItem?.financial_insurance_id ?? selectedItem?.id)
        )
      })

      transformedBundleItems = [...filteredBundleItems, ...addedItems]

      transformedBundleItems = applyRetailPrice(
        transformedBundleItems,
        productRates
      )

      const getProductId = (item: any) => {
        if (item?.product_id) return item?.product_id
        const matchingProduct = allFniProducts?.find(
          (product: any) => product?.id === (item?.financial_insurance_id ?? item?.id)
        )
        return matchingProduct?.product_id || null
      }

      const totalPrice = transformedBundleItems?.reduce((sum: number, product: any) => {
        const itemFoundInSelectedItem = selectedFniItems?.find(
          (selectedItem: any) =>
            getProductId(selectedItem) === getProductId(product) &&
            selectedItem?.bundle_id === bundle?.id
        )

        const price = Number(
          parseFloat(
            (itemFoundInSelectedItem?.price ??
              fniBundleChanges?.[bundle?.id]?.[product?.product_id]?.obj?.price ??
              product?.price) ||
              0
          )?.toFixed(2)
        )
        return sum + price
      }, 0)

      const totalMonthlyImpact = isCash ? 0 : (transformedBundleItems || [])
        ?.map((product: any) => {
          const itemFoundInSelectedItem = selectedFniItems?.find(
            (selectedItem: any) =>
              getProductId(selectedItem) === getProductId(product) &&
              selectedItem?.bundle_id === bundle?.id
          )

          const price =
            itemFoundInSelectedItem?.price ??
            fniBundleChanges?.[bundle?.id]?.[product?.product_id]?.obj?.price ??
            product?.price

          return getFniMonthlyImpact({ ...product, price })
        })
        ?.reduce((sum: number, impact: number) => sum + impact, 0)

      return {
        id: bundle?.id,
        label: bundle?.bundle_name,
        price: `${defaultCurrency}${(totalPrice ?? 0).toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })}${
          totalMonthlyImpact !== 0
            ? ` (${defaultCurrency}${totalMonthlyImpact.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })})`
            : ''
        }`,
        items:
          transformedBundleItems?.map((product: any) => {
            const id = product?.financial_insurance_id ?? product?.id
            const matchedProduct = allFniProducts?.find((fniProduct: any) => fniProduct?.id === id)
            const itemFoundInSelectedItem = selectedFniItems?.find(
              (selectedItem: any) =>
                getProductId(selectedItem) === getProductId(product) &&
                selectedItem?.bundle_id === bundle?.id
            )
            const coverageData = getCoverageObject({
              ...matchedProduct,
              ...product,
              ...fniBundleChanges?.[bundle?.id]?.[product?.product_id]?.obj,
              ...itemFoundInSelectedItem
            })
            const price =
              itemFoundInSelectedItem?.price ??
              fniBundleChanges?.[bundle?.id]?.[product?.product_id]?.obj?.price ??
              product?.price
            const monthlyImpact = isCash ? 0 : getFniMonthlyImpact({ ...product, price })
            const term =
              itemFoundInSelectedItem?.term ??
              fniBundleChanges?.[bundle?.id]?.[product?.product_id]?.obj?.term ??
              product?.term
            const fallbackCoverageName =
              itemFoundInSelectedItem?.coverage_name ??
              fniBundleChanges?.[bundle?.id]?.[product?.product_id]?.obj?.coverage_name ??
              product?.coverage_name

            const duration =
              itemFoundInSelectedItem?.term_months ??
              term ??
              product?.term_months ??
              matchedProduct?.term_months
            return {
              ...matchedProduct,
              ...product,
              ...fniBundleChanges?.[bundle?.id]?.[product?.product_id]?.obj,
              ...itemFoundInSelectedItem,
              title: product?.product_name,
              price: parseFloat(price || 0),
              duration,
              term: duration,
              mileage: product?.term_miles ?? matchedProduct?.term_miles,
              deductiblePrice: coverageData?.retail_price,
              coverage_name: coverageData?.coverage_name ?? fallbackCoverageName,
              monthlyImpact: monthlyImpact ? `${defaultCurrency}${monthlyImpact?.toFixed(2)}` : ''
            }
          }) || []
      }
    })

    setBoxData(newBoxData)
  }, [
    fniBundles,
    selectedBundle,
    allFniProducts,
    productRates,
    fniBundleChanges,
    selectedBundleIndex,
    selectedFniItems,
    loadingBundleFniMonthlyImpacts,
    allProductRates
  ])

  const selectedItemIds = (items: any[]) =>
    items?.filter((item: any) => !item?.bundle_id).map((item: any) => item?.id)

  const disabledBundles = fniBundles
    ?.filter((bundle: any) =>
      bundle?.financial_products?.some((product: any) =>
        selectedItemIds(selectedFniItems)
        ?.includes(product?.financial_product_id)
      )
    )
    ?.map((bundle: any) => bundle?.id)

  const handleBundleSelection = (index: number) => {
    setSelectedFniItems((prevItems: any) => prevItems.filter((item: any) => !item?.bundle_id))

    if (selectedBundleIndex !== index) {
      setFniBundleChanges((prevState: any) => {
        const updatedState = { ...prevState }
        const bundleId = fniBundles?.[selectedBundleIndex]?.id

        if (selectedBundleIndex !== index) {
          if (bundleId) {
            delete updatedState[bundleId]
          }
        }

        return updatedState
      })
    }
    setSelectedBundleIndex(selectedBundleIndex === index ? null : index)
  }

  const handleAddItem = (item: any) => {
    const bundleId = item?.bundle_id
    const productId = item?.product_id
    setFniBundleChanges((prevState: any) => {
      const updatedState = { ...prevState }
      if (!updatedState[bundleId]) {
        updatedState[bundleId] = { removed: [], added: [] }
      }

      const productIdsInBundle = selectedBundleFniItems?.map(
        (item: any) => item?.financial_insurance_product?.product_id
      )

      if (
        !updatedState?.[bundleId]?.added?.find((item: any) => item?.product_id === productId) &&
        !productIdsInBundle?.includes(productId)
      ) {
        updatedState?.[bundleId]?.added?.push(item)
      } else {
        setSelectedFniItems((prev: any) => [...prev, { ...item }])
      }

      updatedState[bundleId].removed = updatedState?.[bundleId]?.removed?.filter(
        (id: any) => id !== productId
      )

      return updatedState
    })
  }

  const getRateRequestObjects = (items: any[], dealerInfo: any) => {
    return {
      deal_info: dealerInfo,
      products_info: items?.map((item: any) => ({
        product_id: item?.product_id,
        starting_mileage: 0,
        ending_mileage: 10000,
        surcharges: undefined
      })),
      test_request: true
    }
  }

  useEffect(() => {
    if (allFniProducts && dealerInfo) {
      getAllProductRates({
        data: getRateRequestObjects(allFniProducts, dealerInfo)
      })
    }
  }, [allFniProducts, dealerInfo])

  function getCoverageObject(cardData: any) {
    if (allProductRates?.rates?.rate) {
      const allRates = allProductRates?.rates?.rate
      for (const rate of allRates) {
        let coverages = rate?.coverages?.coverage
        coverages = Array.isArray(coverages) ? coverages : [coverages]
        for (const coverage of coverages) {
          if (coverage) {
            const isFormMatched = coverage?.form?.form_id === Number(cardData?.form_id)
            let coverageDeductible = coverage?.deductibles?.deductible
            const isDeductibleMatched = Array.isArray(coverageDeductible)
              ? coverageDeductible.some(
                  (deductible: any) => deductible?.rate_id === Number(cardData?.rate_id)
                )
              : coverageDeductible?.rate_id === Number(cardData?.rate_id)

            if (isFormMatched && isDeductibleMatched) {
              const matchedDeductible = Array.isArray(coverageDeductible)
                ? coverageDeductible.find(
                    (deductible: any) => deductible?.rate_id === Number(cardData?.rate_id)
                  )
                : coverageDeductible

              const retailPrice = matchedDeductible?.retail_price

              return { coverage_name: coverage?.coverage_name, retail_price: retailPrice }
            }
          }
        }
      }
    }

    return { coverage_name: null, retail_price: null }
  }

  return allProductRates ? (
    <Grid theme={theme} container columnSpacing={2} rowSpacing={1} mt={1}>
      {boxData?.map((bundle: any, index: number) => (
        <Grid theme={theme} item sm={4} xs={12} key={index}>
          <ProductsBundleWrap className="bundles-wrap" theme={theme}>
            <Box
              theme={theme}
              className={`bundle-box ${selectedBundleIndex === index ? 'selected-box' : ''}`}
            >
              <Box
                theme={theme}
                className="bundle-header"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
                mb={2}
              >
                <Box theme={theme}>
                  <Typography theme={theme} variant="subtitle2" component="p">
                    {bundle?.label}
                  </Typography>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="p"
                    display={'flex'}
                    flexWrap={'wrap'}
                    gap={0.5}
                  >
                    <span className="title-sm">{bundle.price}</span>
                  </Typography>
                </Box>
                <Button
                  theme={theme}
                  disabled={disabledBundles?.includes(bundle?.id)}
                  text={selectedBundleIndex === index ? 'Selected' : 'Select'}
                  primary={selectedBundleIndex === index}
                  secondary={selectedBundleIndex !== index}
                  onClick={() => handleBundleSelection(index)}
                />
              </Box>
              <ProductsBundleList
                isCash={isCash}
                selectedFniItems={selectedFniItems}
                setSelectedFniItems={setSelectedFniItems}
                loadingBundleFniMonthlyImpacts={loadingBundleFniMonthlyImpacts}
                defaultCurrency={defaultCurrency}
                bundle={bundle}
                items={bundle?.items}
                isSelected={selectedBundleIndex === index}
                dealerInfo={dealerInfo}
                setFniBundleChanges={setFniBundleChanges}
                isBundleDisabled={disabledBundles?.includes(bundle?.id)}
                allFniProducts={allFniProducts}
                setIsBundleItemPriceChanged={setIsBundleItemPriceChanged}
              />
              <Box theme={theme} className="add-list">
                <Menu
                  theme={theme}
                  disablePortal
                  options={[]}
                  handleOptionClick={() => {}}
                  setMenuAnchorEl={setFniMenuAchorEl}
                  menuAnchorEl={fniMenuAnchorEl}
                  customChildren={
                    <>
                      <Autocomplete
                        theme={theme}
                        freeSolo={false}
                        disablePortal
                        endAdornment={<Icon name="SearchIcon" />}
                        open={true}
                        searchMatchFrom="any"
                        items={availableFniItems || []}
                        getOptionLabel={(items: any) => items?.product_name || '-'}
                        onInputChange={() => {}}
                        onChange={(_e: any, value: any) => {
                          const selectedBundle = fniBundles?.[selectedBundleIndex]
                          const fniToAddInBundle = applyRetailPrice(
                            [
                              {
                                ...value,
                                bundle_id: selectedBundle?.id,
                                bundle_markup: selectedBundle?.markup
                              }
                            ],
                            productRates
                          )
                          handleAddItem(fniToAddInBundle?.[0])
                          setFniMenuAchorEl(null)
                        }}
                        sx={{ mb: 2 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // label="Name"
                            placeholder="Search..."
                            variant="outlined"
                            defaultValue={''}
                            fullWidth
                          />
                        )}
                        renderOption={(props, item) => (
                          <li {...props} key={item.id}>
                            <span>{item.product_name || '-'}</span>
                          </li>
                        )}
                      />
                    </>
                  }
                  render={(onMenuSelection) => (
                    <Button
                      theme={theme}
                      className="dashed-btn"
                      secondary
                      iconText={<Icon name="AddIcon" />}
                      onClick={onMenuSelection}
                      disabled={!availableFniItems || availableFniItems?.length === 0}
                    />
                  )}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                />
              </Box>
            </Box>
          </ProductsBundleWrap>
        </Grid>
      ))}
    </Grid>
  ) : (
    <Skeleton
      theme={theme}
      variant="rectangular"
      width={'100%'}
      height={'50px'}
      sx={{ borderRadius: '8px' }}
    />
  )
}

export default ProductsBundle
