import { FC, ReactNode, useMemo } from 'react'
import { useTheme } from '@mui/material'
import { ScrollableTabs } from '@ntpkunity/controls'
import VehicleAmountDraft from './vehicle-amount-draft'
import { ScrollableTabsWrap } from '@components'
import AddOpptions from './add-options'
import { useStoreContext } from '@store/storeContext'
import { useCheckFinancialInsuranceByDealerCode } from '@apis/financial-insurance.service'
import { useDeskingContext } from './desking-context-provider'
import { TabName } from '@helpers/enums'

interface OrderDetailsProps {
  orderReferenceId?: string | null
}

type OrderDetailsScrollAbleTab = { content: ReactNode; title: ReactNode }

const OrderDetails: FC<OrderDetailsProps> = ({ orderReferenceId }) => {
  const theme = useTheme()
  const {
    states: {
      dealDesk: {
        dealerProfile: { dealer_code }
      },
      dealerInfo: { company_id }
    }
  } = useStoreContext()
  const {
    state: { vehiclesData, vehiclesVins, isVehicleSelectedAndOrderSaved, preview }
  } = useDeskingContext()

  const isPreviewPage = useMemo(
    () => !!orderReferenceId && !!dealer_code && !!company_id && isVehicleSelectedAndOrderSaved,
    [dealer_code, company_id, orderReferenceId, isVehicleSelectedAndOrderSaved]
  )

  const { data: fniAndMsp } = useCheckFinancialInsuranceByDealerCode(
    isPreviewPage && {
      dealer_code,
      company_id
    }
  )

  const vin = useMemo(
    () => (isVehicleSelectedAndOrderSaved ? preview.vin : vehiclesVins[0]),
    [isVehicleSelectedAndOrderSaved, preview.vin, vehiclesVins]
  )
  const { fni } = vehiclesData?.get(vin) ?? {}

  const { isFniPresentInOrder, isMSPPresentInOrder } = useMemo(() => {
    const isFniOrMsp = fni?.finance?.length ? fni.finance : fni?.lease ?? []

    return {
      isFniPresentInOrder: isFniOrMsp.some((orderItem: any) => !orderItem?.is_msp),
      isMSPPresentInOrder: isFniOrMsp.some((orderItem: any) => orderItem?.is_msp)
    }
  }, [fni])

  const tabs = useMemo(() => {
    const showFni = (fniAndMsp?.fni && isPreviewPage) || isFniPresentInOrder
    const showMsp = (fniAndMsp?.msp && isPreviewPage) || isMSPPresentInOrder

    const items: OrderDetailsScrollAbleTab[] = []

    if (showFni)
      items.push({
        title: 'F&I',
        content: <AddOpptions tabName={TabName.FNI} showFni={showFni} showMsp={showMsp} />
      })

    items.push(
      {
        title: 'Add-Ons',
        content: <AddOpptions tabName={TabName.OPTIONS} showFni={showFni} showMsp={showMsp} />
      },
      {
        title: 'Fees',
        content: <AddOpptions tabName={TabName.FEES} showFni={showFni} showMsp={showMsp} />
      }
    )

    if (showMsp)
      items.push({
        title: 'Maint. & Service Products',
        content: <AddOpptions tabName={TabName.MSP} showFni={showFni} showMsp={showMsp} />
      })

    return items
  }, [fniAndMsp, isPreviewPage, isFniPresentInOrder, isFniPresentInOrder])

  return (
    <>
      <VehicleAmountDraft />
      <ScrollableTabsWrap theme={theme} className="scrollable-tabs-wrap sm">
        <ScrollableTabs
          theme={theme}
          items={tabs}
          defaultTabIndex={0}
          scrollButtons={'auto'}
          onChange={() => {}}
        />
      </ScrollableTabsWrap>
    </>
  )
}

export default OrderDetails
