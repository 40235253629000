export enum DialogMessages {
  confirmationTitle = 'Are you sure you want to cancel deal?',
  confirmationText = 'Once cancelled, you cannot undo this action.',
  cancelBtnText = 'Confirm',
  removeBtnText = 'Remove',
  btnText = 'Save Changes',
  licenseByPassTitle = 'Bypassing Verification!',
  licenseByPassText = 'You are about to override the status and system will not get any more updated from verification service.',
  insuranceConfirmationTitle = 'Are you sure you want to remove insurance details?',
  paymentConfirmationTitle = 'Are you sure you want to remove payment details?',
  tradeInConfirmationTitle = 'Are you sure you want to remove trade-in details?',
  completeOrderTitle = 'Are you sure you want to complete deal?',
  completeOrderText = 'Once confirm, you cannot undo this action.',
  updatedDMSDATA = 'Do you wish to update information received from Dealer Management System?',
  updatedDMSDATATEXT = 'Once updated, you cannot undo this action.',
  assetUnavailableTitle = 'Are you sure asset is unavailable?',
  assetUnavailableConfirmationText = 'Once the asset is marked as unavailable, this deal will be cancelled. Please mention the reason below to mark it as unavailable.',
  assetUnavailableDialogueTitle = 'Asset Unavailable',
  assetUnavailableDialogueSubTitle = 'The asset you are looking for is currently not available in the inventory.',
  assetUnavailableDialogueBtnText = 'Got It',
  fniBundleItemConfirmationTitle = 'Are you sure you want to remove?',
  fniBundleItemConfirmationText = 'Once removed, you cannot undo this action.',
  fniAndMspRemoveTitle = 'Are you sure you want to proceed?',
  fniAndMspRemoveSubTitle = 'All F&I products or rebates attached with this deal will be removed and will need to be added again if required.',
  lenderRebatesRemoveSubTitle = 'Lender rebates attached with this deal will be removed.',
  fniAndMspRemoveBtnText = 'Continue',
  removeCoApplicantPrimaryText = 'Remove Co-Applicant',
  removeCoAPplicantSecondaryText = 'Are you sure you want to remove?',
  saveChanges = 'Do you want to save the changes?',
  unsaveChanges = 'You have unsaved changes. Would you like to save them before exiting?',
  removeDocument = 'Are you sure you want to remove document?'
}
