export enum ValidationMessages {
  FEEDBACK_REQUIRED = 'Feedback is required',
  FEEDBACK_MAX_LENGTH = 'Length should be less than or equal to 1000',
  REQUIRED = 'required',
  EMAIL = 'email',
  WHITE_SPACE = 'whiteSpace',
  ONLY_ALPHABETS = 'onlyAlphabets',
  PHNONE_NUMBER = 'phoneNumber',
  LIMIT_LENGTH = 'limitLength',
  CROSS_VALIDATE = 'crossValidate',
  EMAIL_INVALID = 'Valid Email is Required.'
}
