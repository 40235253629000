import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { PageHeader } from 'components'
import { Typography, Grid, Box } from '@ntpkunity/controls'

import {
  AssetDetails,
  CustomerDetails,
  InsuranceDetails,
  OrderDetails,
  PaymentBreakdown,
  TradeInDetails
} from '@app/order-management/order-summary'
import { useGetOrderInformation } from '@apis/order-management.service'
import { useParams } from 'react-router-dom'
import { Status } from '@helpers/enums'

const OrderCancelled: FC = () => {
  const theme = useTheme()
  const { reference_id } = useParams()
  const [isDraftOrder, setIsDraftOrder] = useState(false)

  const { mutate: getOrderInformation, data: orderDetail } = useGetOrderInformation()
  const [orderinformation, setOrderInformation] = useState<any>(orderDetail)

  useEffect(() => {
    if (reference_id) {
      getOrderInformation(
        { reference_number: reference_id },
        {
          onSuccess(response: any) {
            setIsDraftOrder(Array.isArray(response))
            const orderResponse = Array.isArray(response) ? response?.[0] : response
            if (
              orderResponse?.status === Status.Cancelled ||
              orderResponse?.status === Status.Declined ||
              orderResponse?.status === Status.Withdrawn
            )
              setOrderInformation(orderResponse)
          }
        }
      )
    }
  }, [reference_id])

  return (
    <LayoutWithSideNav theme={theme}>
      <Box theme={theme} sx={{ mb: 2.5 }}>
        <PageHeader
          className="main-page-header border-bottom"
          theme={theme}
          container
          item
          spacing={2}
        >
          <Grid theme={theme} container spacing={2}>
            <Grid theme={theme} item lg={7} md={6} sm={12} xs={12}>
              <Typography theme={theme} variant="h2" component="h2">
                Deal {orderinformation?.status}
              </Typography>
              <Typography theme={theme} variant="body2" component="p">
                {(() => {
                  switch (orderinformation?.status) {
                    case Status.Cancelled:
                      return 'Sorry, the deal has been cancelled and moved to your cancelled deal queue.'
                    case Status.Declined:
                      return 'Sorry, the deal has been declined and moved to your declined deals queue.'
                    case Status.Withdrawn:
                      return 'Sorry, the deal has been withdrawn by the customer and moved to your withdrawn deals queue.'
                    default:
                      return null
                  }
                })()}
              </Typography>
            </Grid>
          </Grid>
        </PageHeader>
      </Box>
      <AssetDetails
        isDraftOrder={isDraftOrder}
        order_info={orderinformation}
        setOptionObject={setOrderInformation}
      />

      <Grid theme={theme} className="container" container spacing={2}>
        <Grid theme={theme} item xl={8} lg={8} md={8} sm={12} xs={12}>
          <CustomerDetails
            customer_info={orderinformation?.customer_info}
            coapplicant_info={orderinformation?.coapplicant_info}
          />

          <OrderDetails isDraftOrder={isDraftOrder} order_detail={orderinformation} />

          {orderinformation?.Insurance_Inforation ? (
            <InsuranceDetails
              insurance={orderinformation?.Insurance_Inforation}
              referenceId={orderDetail?.customer_info.reference_id}
            />
          ) : (
            ''
          )}

          {orderinformation?.order_tradein ? (
            <TradeInDetails orderDetail={orderinformation} setOrderObject={setOrderInformation} />
          ) : (
            ''
          )}
        </Grid>

        {!isDraftOrder && <PaymentBreakdown order_info={orderinformation} />}
      </Grid>
    </LayoutWithSideNav>
  )
}

export default OrderCancelled
