import * as yup from 'yup'

export const feeValidationSchema = yup.object().shape({
  feeType: yup.string().nullable(),
  vendorName: yup.string().nullable(),
  feeAmount: yup
    .string()
    .required('Fee Amount is required')
    .test('not-empty', 'Fee Amount is required', value => !!value?.trim())
})
