import { FC, useEffect, useState } from 'react'
import { Link, useTheme } from '@mui/material'
import { DetailCard, DuoTabWrap } from 'components'
import {
  Typography,
  Grid,
  Accordion,
  Box,
  Dialog,
  Input,
  Select,
  DatePicker,
  DuoTab,
  Skeleton
} from '@ntpkunity/controls'
import { ICustomer } from '@models'
import { useGetStates } from '@apis/customer-detail.service'
import CoApplicant from './coapplicant-details'
import { useGetPreSignedUrl } from '@apis/order-management.service'
import { formatDateFromString } from '@helpers/methods'

const CustomerDetails: FC<{
  customer_info: ICustomer
  coapplicant_info?: ICustomer
  customerReferenceId?: string
}> = ({ customer_info, coapplicant_info }) => {
  const theme = useTheme()
  const [openDialog, setOpenDialog] = useState(false)
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [processedImages, setProcessedImages] = useState({
    applicant: { front: null, back: null, licenseState: null, loading: true },
    coApplicant: { front: null, back: null, licenseState: null, loading: true }
  })
  const { data: licenseStates } = useGetStates()
  const { mutate: getPreSignedUrl } = useGetPreSignedUrl()
  const getLicenseState = (customerData: ICustomer | undefined) => {
    const drivingLicenseAddress = customerData?.customer_addresses?.find(
      (address: any) => address.address_type === 'DrivingLicense'
    )
    return drivingLicenseAddress?.state_name || null
  }

  const getPresignedImageUrl = async (
    imageUri: string | undefined | null
  ): Promise<string | null> => {
    if (!imageUri || typeof imageUri !== 'string' || !imageUri.trim()) return null

    return new Promise((resolve) =>
      getPreSignedUrl({ key: imageUri }, { onSuccess: resolve, onError: () => resolve(null) })
    )
  }

  const processLicenseImages = async (
    customerData: ICustomer,
    coApplicantData: ICustomer | undefined,
    isCoApplicant?: boolean
  ) => {
    if (customerData) {
      const imageKey = isCoApplicant ? 'coApplicant' : 'applicant'
      const licenseData = customerData.customer_license?.[0]
      const frontImage = licenseData?.front_image || null
      const backImage = licenseData?.back_image || null
      const licenseState = getLicenseState(customerData)
      const frontImageUrl = await getPresignedImageUrl(frontImage)
      const backImageUrl = await getPresignedImageUrl(backImage)

      if (coApplicantData) processLicenseImages(coApplicantData, undefined, true)

      setProcessedImages((prev) => ({
        ...prev,
        [imageKey]: {
          front: frontImageUrl,
          back: backImageUrl,
          licenseState,
          loading: false
        }
      }))
    } else {
    }
  }

  const handleOpen = (isCoApplicant = false) => {
    setActiveTabIndex(isCoApplicant ? 1 : 0)
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false)
  }

  useEffect(() => {
    if (customer_info?.customer_license) processLicenseImages(customer_info, coapplicant_info)
  }, [customer_info])

  const mailing_address = customer_info?.customer_addresses?.find(
    (address: any) => address.address_type === 'Mailing'
  )
  const licenseNumber = customer_info?.customer_license?.[0]?.license_number

  const TabsContent: FC<{ isCoApplicant?: boolean }> = ({ isCoApplicant = false }) => {
    const data = isCoApplicant ? coapplicant_info : customer_info
    const images = isCoApplicant ? processedImages.coApplicant : processedImages.applicant

    const renderImage = (src: string | null, side: string) => {
      if (images.loading) {
        return (
          <Grid
            theme={theme}
            container
            justifyContent="center"
            alignItems="center"
            style={{ height: '300px' }}
          >
            <Skeleton variant="rectangular" theme={theme} width={272} height={152} />
          </Grid>
        )
      }

      return src ? (
        <>
          <img
            src={src}
            alt={`License ${side}`}
            style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
          />
          <Typography
            theme={theme}
            component="p"
            variant="body2"
            textAlign={'center'}
            mt={2}
            sx={{ color: theme.palette.grey[600] }}
            children={`${side} side`}
          />
        </>
      ) : null
    }

    return (
      <Grid theme={theme} container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid theme={theme} item xs={12} sm={6}>
          {renderImage(images.front, 'Front')}
        </Grid>
        <Grid theme={theme} item xs={12} sm={6}>
          {renderImage(images.back, 'Back')}
        </Grid>
        <Grid theme={theme} item xs={12} sm={6}>
          <Input
            theme={theme}
            fullWidth
            placeholder="Type here"
            label="First Name"
            type={'text'}
            value={data?.customer_license?.[0]?.first_name || ''}
            disabled
          />
        </Grid>
        <Grid theme={theme} item xs={12} sm={6}>
          <Input
            theme={theme}
            fullWidth
            placeholder="Type here"
            label="Last Name"
            type={'text'}
            value={data?.customer_license?.[0]?.last_name || ''}
            disabled
          />
        </Grid>
        <Grid theme={theme} item xs={12} sm={6}>
          <Input
            theme={theme}
            fullWidth
            placeholder="Type here"
            label="License Number"
            type={'text'}
            value={data?.customer_license?.[0]?.license_number || ''}
            disabled
          />
        </Grid>
        <Grid theme={theme} item xs={12} sm={6}>
          <Select
            theme={theme}
            label="License Issuing State"
            disablePortal={false}
            disabled
            items={licenseStates?.map((x: { id: any; name: any }) => ({
              value: x.id,
              text: x.name
            }))}
            value={images.licenseState}
          />
        </Grid>
        <Grid theme={theme} item xs={12} sm={6}>
          <DatePicker
            theme={theme}
            label="Issuance"
            value={formatDateFromString(data?.customer_license?.[0]?.issue_date)}
            disabled
          />
        </Grid>
        <Grid theme={theme} item xs={12} sm={6}>
          <DatePicker
            theme={theme}
            label="Expiration"
            value={formatDateFromString(data?.customer_license?.[0]?.expiry_date)}
            disabled
          />
        </Grid>
        <Grid theme={theme} item xs={12} sm={6}>
          <DatePicker
            theme={theme}
            label="Date Of Birth"
            value={formatDateFromString(data?.customer_license?.[0]?.date_of_birth)}
            disabled
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <DetailCard className="detail-card" mb={2}>
        <Typography theme={theme} component="h4" variant="h4" mb={2}>
          Customer Details
        </Typography>
        <Grid theme={theme} className="container" container>
          <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
            <Typography theme={theme} variant="body2" component="h3" className="label">
              Name
            </Typography>
          </Grid>
          <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
            {customer_info?.first_name || customer_info?.last_name ? (
              <Typography theme={theme} variant="body2" component="span">
                {customer_info?.first_name} {customer_info?.middle_name || ''}{' '}
                {customer_info?.last_name}
              </Typography>
            ) : (
              <Typography theme={theme} variant="body2" component="h3" className="label-detail">
                {'-'}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid className="container" theme={theme} container>
          <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
            <Typography theme={theme} variant="body2" component="h3" className="label">
              Address
            </Typography>
          </Grid>
          <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
            {mailing_address?.address_line_1 ||
            mailing_address?.city ||
            mailing_address?.state_name ||
            mailing_address?.zip_code ? (
              <Typography theme={theme} variant="body2" component="h3" className="label-detail">
                {mailing_address?.address_line_1} {mailing_address?.city},{' '}
                {mailing_address?.state_name} {mailing_address?.zip_code}
              </Typography>
            ) : (
              <Typography theme={theme} variant="body2" component="h3" className="label-detail">
                {'-'}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid theme={theme} className="container" container>
          <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
            <Typography theme={theme} variant="body2" component="h3" className="label">
              Email
            </Typography>
          </Grid>
          <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
            <Typography theme={theme} variant="body2" component="h3" className="label-detail">
              {customer_info?.email ? customer_info?.email : '-'}
            </Typography>
          </Grid>
        </Grid>
        <Grid className="container" theme={theme} container>
          <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
            <Typography theme={theme} variant="body2" component="h3" className="label">
              License Number
            </Typography>
          </Grid>
          <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
            <Typography theme={theme} variant="body2" component="h3" className="label-detail">
              {licenseNumber ?? '-'}
              {' - '}
              <Link className="link hover-underline" onClick={() => handleOpen(false)}>
                View Details
              </Link>
            </Typography>
          </Grid>
        </Grid>
        {coapplicant_info && (
          <Box theme={theme} className="accordion-c" pt={2}>
            <Accordion
              theme={theme}
              items={[
                {
                  id: `id-${coapplicant_info.reference_id}`,
                  key: coapplicant_info.reference_id,
                  title: 'Co-Applicant',
                  content: (
                    <CoApplicant coapplicant_info={coapplicant_info} onViewDetails={handleOpen} />
                  )
                }
              ]}
              shouldCollapse={true}
            />
          </Box>
        )}
      </DetailCard>
      <Dialog
        size="lg"
        title="Driver's License Details"
        open={openDialog}
        onCloseDialog={handleClose}
        noFooter
        theme={theme}
        children={
          <>
            <DuoTabWrap theme={theme} className="duo-tab-wrap align-center sm">
              <DuoTab
                theme={theme}
                defaultTabIndex={activeTabIndex}
                items={[
                  {
                    title: 'Applicant Details',
                    content: <TabsContent isCoApplicant={false} />
                  },
                  ...(coapplicant_info
                    ? [
                        {
                          title: 'Co-Applicant Details',
                          content: <TabsContent isCoApplicant={true} />
                        }
                      ]
                    : [])
                ]}
              />
            </DuoTabWrap>
          </>
        }
      />
    </>
  )
}

export default CustomerDetails
