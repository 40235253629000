import Http from '@helpers/http-api'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  EP_GET_CONFIG_ALLOWED_MILEAGE,
  EP_GET_CONFIG_CONTRACT_TEMRS,
  EP_GET_CONFIG_SETUP_DATA,
  EP_GET_TRIMS,
  EP_GET_STATES,
  EP_GET_RATING,
  EP_GET_CURRENCIES,
  EP_GET_FP_BY_ID,
  EP_GET_FIND_VEHICLES,
  EP_GET_ACTIVE_ASSET_USAGE,
  EP_GET_FIND_MONTHLY_PAYMENTS,
  EP_GET_OPTION_CATEGORY,
  EP_GET_ASSET_CONDITION,
  EP_DEALER_OPTION,
  EP_GET_LENDER,
  EP_GET_INDIVIDUAL_AGREEMENT,
  EP_GET_INDIVIDUAL,
  EP_UPDATE_ORDER_INDIVIDUAL_AGREEMENT,
  EP_GET_INSURANCE_COMPANIES
} from '@helpers/endpoints'
import { getMarketPlaceToken } from '@helpers/methods'
import { useStoreContext } from '@store/storeContext'
import { QueryKeys } from '@helpers/enums'
import { IThemeBuilder } from '@models'

const token = getMarketPlaceToken()
const headers: any = {
  Authorization: `Bearer ${token}`
}

export const useGetContractTerms = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, error, mutate } = useMutation<{}, any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      const company_id = body?.company_id
      headers.company_id = company_id
      return apiService.get<any>(EP_GET_CONFIG_CONTRACT_TEMRS, {}, { headers })
    },
    {
      onSuccess(response: any) {
        actions.setContractTermsData(response)
      }
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetDataAffordabilityCheck = (): any => {
  const { data, isLoading, error, mutate } = useMutation<{}, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.get<any>(EP_GET_CONFIG_SETUP_DATA + body.query, {})
    },
    {
      onSuccess(_response: any) {}
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetFindVehicles = (): any => {
  const { data, isLoading, mutate } = useMutation<
    {
      max_payment: string
      terms: string
      credit_score: string
      upfront: string
      dealer_code: string
      company_code: string
      lender_code: string
    },
    any
  >(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.CONFIG_API_GATEWAY
      })
      return apiService.post<any>(EP_GET_FIND_VEHICLES, body, {
        headers: {
          company_id: body?.company_id
        }
      })
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}
export const useGetMonthlyPayments = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<any>(EP_GET_FIND_MONTHLY_PAYMENTS, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetAllowedMileage = (): any => {
  const { data, isLoading, error, mutate } = useMutation<{}, any>((body: any) => {
    const apiService = Http.createConnection({
      baseUrl: process.env.API_GATEWAY
    })
    return apiService.get<any>(
      EP_GET_CONFIG_ALLOWED_MILEAGE,
      {},
      {
        headers: {
          company_id: body?.company_id
        }
      }
    )
  })
  return { data, isLoading, error, mutate }
}

export const useGetAssetMakeTrims = (): any => {
  const { data, isLoading, error, mutate } = useMutation(
    () => {
      const apiService = Http.createConnection({
        baseUrl: process.env.CONFIG_API_GATEWAY
      })
      return apiService.get<any>(`${EP_GET_TRIMS}`)
    },
    {
      onSuccess(_response: any) {}
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetStates = (): any => {
  const { data, isLoading, error, mutate } = useMutation(
    () => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.get<any>(EP_GET_STATES)
    },
    {
      onSuccess(_response: any) {}
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetRating = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, error, mutate } = useMutation(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      headers.company_id = body?.company_id
      return apiService.get<any>(EP_GET_RATING, {}, { headers })
    },
    {
      onSuccess(response: any) {
        actions.setCreditRatingData(response)
      }
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetCurrencies = (company_id: number): any => {
  headers.company_id = company_id
  return useQuery(
    [QueryKeys.GET_ALL_CURRENCIES, company_id],
    async () => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.get<any>(`${EP_GET_CURRENCIES}`, {}, { headers })
    },
    { keepPreviousData: true }
  )
}
export const useGetFPById = (): any => {
  const { data, isLoading, mutate, error } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.CONFIG_API_GATEWAY
      })
      return apiService.post<any>(`${EP_GET_FP_BY_ID}/${body.fp_id}`)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate, error }
}
export const useGetAssetUsage = (): any => {
  const { states } = useStoreContext()
  return useQuery(
    [QueryKeys.GET_ALL_ACTIVE_ASSET_USAGE],
    async () => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      headers.company_id = states?.dealerAndLenderInfo?.company_id
      return apiService.get<any>(`${EP_GET_ACTIVE_ASSET_USAGE}`, {}, { headers })
    },
    { keepPreviousData: true }
  )
}

export const getThemeBuilderByEmailFromServer = (user_email: string) => {
  const apiService = Http.createConnection({
    baseUrl: process.env.API_GATEWAY
  })
  return apiService.get<IThemeBuilder>(`theme-builder?user_email=${user_email}`, {}, { headers })
}
export const UseQuery_getThemeBuilderbyEmail = (user_email: string) => {
  return useQuery<IThemeBuilder>(
    [QueryKeys.GET_THEME_BUILDER, user_email],
    async () => {
      return getThemeBuilderByEmailFromServer(user_email).then((data) => {
        return data
      })
    },
    { keepPreviousData: true, enabled: !!user_email }
  )
}
export const getLenderByTenantId = (tenant_id: number) => {
  const apiService = Http.createConnection({
    baseUrl: process.env.API_GATEWAY
  })
  return apiService
    .get(`${'company/by-tenant-id'}/${tenant_id}`, {}, { headers })
    .then((res: any) => {
      return res
    })
}

export const UseQuery_getLenderByTenantId = (tenant_id: number) => {
  return useQuery(
    QueryKeys.GET_LENDER_PROFILE,
    async () => {
      return getLenderByTenantId(tenant_id).then((data) => {
        return data
      })
    },
    { enabled: !!tenant_id }
  )
}

export const getAllOptionCategorysFromServer = () => {
  const apiService = Http.createConnection({
    baseUrl: process.env.CONFIG_API_GATEWAY
  })
  return apiService.get(`${EP_GET_OPTION_CATEGORY}`, {}, { headers }).then((res: any) => {
    return res
  })
}

export const UseQuery_GetAllOptionCategorys = () => {
  return useQuery(QueryKeys.GET_ALL_OPTION_CATEGORIES, async () => {
    return getAllOptionCategorysFromServer().then((data) => {
      return data
    })
  })
}

export const getAllAssetConditionFromServer = () => {
  const apiService = Http.createConnection({
    baseUrl: process.env.CONFIG_API_GATEWAY
  })
  return apiService.get(`${EP_GET_ASSET_CONDITION}`, {}, { headers }).then((res: any) => {
    return res
  })
}

export const UseMutation_AddAssetCondition = () => {
  return useQuery(QueryKeys.GET_ALL_ASSET_CONDITION, async () => {
    return getAllAssetConditionFromServer().then((data) => {
      return data
    })
  })
}

export const useSaveAddOn = (): any => {
  const queryClient = useQueryClient()
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.CONFIG_API_GATEWAY
      })
      headers.company_id = body?.company_id
      return apiService.post<any>(`${EP_DEALER_OPTION}`, body, { headers })
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_ADD_ON)
      }
    }
  )
  return { data, isLoading, mutate }
}

export const UseQuery_getLenderById = (lender_id: string) => {
  return useQuery(
    [QueryKeys.GET_LENDER_BY_ID, lender_id],
    async () => {
      const apiService = Http.createConnection({
        baseUrl: process.env.CONFIG_API_GATEWAY
      })
      return apiService.get<any>(`${EP_GET_LENDER}/${lender_id}`, {}, { headers })
    },
    { keepPreviousData: true, enabled: !!lender_id }
  )
}
export const useGetIndividualAgreement = (
  dealer_code: string,
  orderReferenceId: string | undefined | null
) => {
  return useQuery(
    [QueryKeys.GET_ALL_INDIVIDUAL_AGREEMENT, dealer_code, orderReferenceId],
    async () => {
      const apiService = Http.createConnection({
        baseUrl: process.env.CONFIG_API_GATEWAY
      })
      return apiService.get(
        `${EP_GET_INDIVIDUAL}/${dealer_code}/${EP_GET_INDIVIDUAL_AGREEMENT}`,
        {},
        { headers }
      )
    },
    {
      enabled: !!dealer_code && !!orderReferenceId,
      onSuccess: (_res: any) => {}
    }
  )
}
export const UseQuery_getAllLenders = () => {
  return useQuery(
    QueryKeys.GET_ALL_LENDER,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: process.env.CONFIG_API_GATEWAY
      })
      return apiService.get<any>(`${EP_GET_LENDER}`, {}, { headers })
    },
    { keepPreviousData: true }
  )
}

export const useUpdateIndividualizedAgreement = (): any => {
  const queryClient = useQueryClient()
  const { data, isLoading, mutate } = useMutation<{ body: any }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<any>(`${EP_UPDATE_ORDER_INDIVIDUAL_AGREEMENT}`, body)
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('Order')
      }
    }
  )
  return { data, isLoading, mutate }
}

export const getInsuranceCompanies = (company_id: number) => {
  const apiService = Http.createConnection({
    baseUrl: process.env.CONFIG_API_GATEWAY
  })
  return apiService.get<any>(
    `${EP_GET_INSURANCE_COMPANIES}`,
    {},
    {
      headers: {
        company_id
      }
    }
  )
}
