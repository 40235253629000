import Http from '@helpers/http-api'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  EP_CREATE_CUSTOMER_CONSENT,
  EP_CUSTOMER_CHECK_CREATE_BY_EMAIL,
  EP_FRAUD_CHECK,
  EP_SAVE_CUSTOMER_CONSENT,
  CUSTOMER_MANDATORY_DISCLAIMERS,
  DISCLAIMER_AGREEMENT,
  PRE_QUALIFICATION,
  EP_SEND_ORDER_LINK,
  GET_CUSTOMER_ORDER_SUBMISSIONS
} from '@helpers/endpoints'
import { useStoreContext } from '@store/storeContext'
import { getMarketPlaceToken } from '@helpers/methods'
import { IPreQualification } from 'types/pre-qualified'
import { QueryKeys, Status, ToastMessages } from '@helpers/enums'

const token = getMarketPlaceToken()
const headers = {
  Authorization: `Bearer ${token}`
}

export const useCheckCustomerStatus = (): any => {
  const { actions, states } = useStoreContext()
  const { data, isLoading, error, mutate } = useMutation<{ email: string }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      const reqObj = {
        email: body?.email,
        dealer_id: states?.dealerInfo?.id
      }
      return apiService.patch<any>(
        EP_CUSTOMER_CHECK_CREATE_BY_EMAIL,
        {
          ...reqObj
        },
        { headers }
      )
    },
    {
      onSuccess(_response: any) {
        actions.setCustomerData(_response)
      }
    }
  )
  return { data, isLoading, error, mutate }
}

export const useCreateCustomerConsent = (): any => {
  const { data, isLoading, mutate } = useMutation<
    { email: string; password: string; dealer_id: string; zip_code: string },
    any
  >(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<any>(EP_CREATE_CUSTOMER_CONSENT, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}
export const useSavePreQualification = (): any => {
  const queryClient = useQueryClient()

  const { data, isLoading, mutate } = useMutation<IPreQualification>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<IPreQualification>(PRE_QUALIFICATION, body)
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(QueryKeys.GET_PRE_QUALIFIED_RESPONSES)
      }
    }
  )
  return { data, isLoading, mutate }
}
export const UseQuery_Get_PreQualifiedResponses = () => {
  return useQuery(
    [QueryKeys.GET_PRE_QUALIFIED_RESPONSES],
    async () => {
      const apiService = Http.createConnection()
      return apiService.get<any>(`${PRE_QUALIFICATION}`)
    },
    { keepPreviousData: true }
  )
}
export const useSaveCustomerConsent = (): any => {
  const { data, isLoading, mutate } = useMutation<
    { dealer_id: string; lender_id: string; customer_id: string },
    any
  >(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<any>(EP_SAVE_CUSTOMER_CONSENT, body, { headers })
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useUpdateCustomerConsent = (): any => {
  const { data, isLoading, mutate } = useMutation<{ reference_id: string }, any>(
    (body: any) => {
      let newbody: any = { ...body, address_type: 'MAILING' }
      const apiService = Http.createConnection()
      return apiService.post<any>(`${EP_CREATE_CUSTOMER_CONSENT}`, newbody, {
        headers
      })
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetCustomerFraudCheck = () => {
  const { data, isLoading, mutate } = useMutation<
    any,
    unknown,
    { customer_reference_id: string; co_applicant_reference_id?: string }
  >(
    (body) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      const queryParams = new URLSearchParams({ customer_reference_id: body.customer_reference_id })
      if (body?.co_applicant_reference_id) {
        queryParams.append('co_applicant_reference_id', body.co_applicant_reference_id)
      }
      return apiService.get<any>(`${EP_FRAUD_CHECK}?${queryParams.toString()}`, {})
    },
    {
      onSuccess() {}
    }
  )

  return { data, isLoading, mutate }
}

export const useCustomerFraudCheck = (): any => {
  const { data, isLoading, mutate } = useMutation<
    { user_id: number; reference_number: string },
    any
  >(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<any>(EP_FRAUD_CHECK, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetTenantConsent = () => {
  return useQuery(CUSTOMER_MANDATORY_DISCLAIMERS, async () => {
    const apiService = Http.createConnection({
      baseUrl: process.env.API_GATEWAY
    })
    return await apiService.get<any>(`${CUSTOMER_MANDATORY_DISCLAIMERS}`)
  })
}

export const useAddCustomerConsent = (): any => {
  return useMutation<any>((body: any) => {
    const apiService = Http.createConnection({
      baseUrl: process.env.API_GATEWAY
    })
    return apiService.post<any>(`${DISCLAIMER_AGREEMENT}`, body)
  })
}

export const useGetCustomerConsent = (
  customer_reference_id: number,
  order_reference_number: string
) => {
  return useQuery(
    [QueryKeys.DISCLAIMER_AGREEMENT, customer_reference_id],
    async () => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.get<any>(
        `${DISCLAIMER_AGREEMENT}?customer_reference_id=${customer_reference_id}&order_reference_id=${order_reference_number}`,
        {},
        { headers }
      )
    },
    {
      keepPreviousData: true,
      enabled: !!customer_reference_id && !!order_reference_number,
      retry: false
    }
  )
}

export const useSendCustomerOrderLink = (): any => {
  const { actions } = useStoreContext()
  const { mutate, isLoading } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.post<any>(`${EP_SEND_ORDER_LINK}?reference_number=${body.reference_number}`)
    },
    {
      onSuccess: (response: any) => {
        if (response?.detail?.message) {
          actions.setToast({
            toastMessage: response?.detail?.message,
            toastState: true,
            variant: 'error'
          })
        } else {
          actions.setToast({
            toastMessage: ToastMessages.SEND_ORDER_LINK_SUCCESS,
            toastState: true
          })
        }
      },
      onError: () => {
        actions.setToast({
          toastMessage: ToastMessages.SEND_ORDER_LINK_FAILED,
          toastState: true,
          variant: 'error'
        })
      }
    }
  )

  return { mutate, isLoading }
}

export const useGetCustomerOrderSubmissions = () => {
  return useMutation(
    async ({
      customer_reference_id,
      submission_status,
      lender_ids
    }: {
      customer_reference_id: string
      submission_status: string
      lender_ids: any[]
    }) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })

      return apiService.post<any>(
        `${GET_CUSTOMER_ORDER_SUBMISSIONS}/${customer_reference_id}/${submission_status}`,
        {
          lender_ids,
          order_statuses: [`'${Status.Submitted}'`, `'${Status.AwaitingMore}'`]
        },
        { headers }
      )
    }
  )
}
