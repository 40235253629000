import { roundToTwoDecimal } from "@app/desking/utils"
import { FinanceType } from "./enums/finance-type.enum"

export const fniTotalCalculator = (
  fni: any
) => {
  return fni.filter(
            (currentItem: any) =>
              currentItem?.MSP === false ||
              currentItem?.is_msp === false ||
              ((currentItem?.MSP || currentItem?.is_msp) && currentItem?.bundle_id)
          )
          .reduce(
            (total: number, currentItem: any) =>
              total + roundToTwoDecimal(currentItem?.applied_price ?? currentItem?.price ?? 0),
            0
          )
}

export const mspTotalCalculator = (
    fni: any
  ) => {
    return fni.filter(
        (currentItem: any) =>
          (currentItem?.MSP === true || currentItem?.is_msp === true) && !currentItem?.bundle_id
      )
      .reduce(
        (total: number, currentItem: any) =>
          total + roundToTwoDecimal(currentItem?.applied_price ?? currentItem?.price ?? 0),
        0
      )
  }

export const feeSumCalculator = (
    fees: any,
    feeHandling: string
  ) => {
    return fees.reduce(
        (total: number, fee: any) =>
          (fee?.fee_handling?.toLowerCase() === feeHandling || feeHandling.includes(FinanceType.CASH))
            ? total + (fee?.applied_price ?? fee?.default_amount ?? 0)
            : total,
        0
      )
  }
  export const feeSumGenericCalculator = (
    fees: any
  ) => {
    return fees.reduce((sum: any, current: any) => sum + current.applied_price, 0)
  }
  
export const optionPriceCalculator = (
    options: any,
    installationMode: string
  ) => {
    return options.reduce(
        (total: number, currentItem: any) =>
          total +
          ((currentItem.installation_mode === installationMode &&
            !currentItem.is_price_inclusive) ||
          currentItem.installation_mode !== installationMode
            ? currentItem?.applied_price ?? currentItem?.price
            : 0),
        0
      )
  }

export const tradeInAmountCalculator = (
    tradeIn: any
  ) => {
    return Object.keys(tradeIn ?? {}).length > 0
    ? tradeIn?.trade_in_amount - tradeIn?.payoff_amount
    : 0
  }
  
export const cashDueAtSigningCalculator = (
    selection?: any
  ) => {
    return selection?.due_at_signing ?? 0
  }
export const cashTaxCalculator = (
    selection?: any
  ) => {
    return selection?.tax_amount ?? 0
  }  
export const rebatesCalculator = (
    amount?: any
  ) => {
    return amount ?? 0
  }       
