import { useGetVehicleOptions } from '@apis/dealer-configurations.service'
import { InstallationMode } from '@helpers/enums'
import { useTheme } from '@mui/material'
import { Box } from '@ntpkunity/controls'
import { useStoreContext } from '@store/storeContext'
import { DialogTotal } from 'components'
import React, { FC, Fragment, useEffect, useMemo, useState } from 'react'
import { IOption } from './addon-interface'
import OptionsTable from '@app/add-ons/options-table'

const AddOnOption: FC<{
  defaultOptions?: IOption[]
  defaultCurrency?: any
  setSelectedObjectsArray: any
  modelName: string
  vin: string
  preInstalledDealerOptions?: number[]
  addedItems: any
  setAddedItems: any
  setTotalSumArray?: any
  totalSumArray?: any
}> = ({
  defaultOptions,
  defaultCurrency,
  setSelectedObjectsArray,
  modelName,
  vin,
  preInstalledDealerOptions,
  addedItems,
  setAddedItems,
  setTotalSumArray,
  totalSumArray
}: any) => {
  const theme = useTheme()
  const { states } = useStoreContext()
  const [addedItemPrices, setAddedItemPrices] = useState<{ [key: number]: number }>({})
  const [popupAddedItems, setPopupAddedItems] = useState<any>([])
  const { data } = useGetVehicleOptions(vin, states?.dealerInfo?.dealer_code, modelName)
  const dealerOptions = Array.isArray(data) ? data : []
  useEffect(() => {
    if (defaultOptions) {
      const data = defaultOptions?.filter(
        (f: any) => !addedItems.find((e: any) => e === (f.option_id ?? f.id))
      )
      const prices = data.reduce((acc: Record<string, any>, item: any) => {
        if (item?.installation_mode === InstallationMode.OPTIONAL) {
          acc[item?.option_id ?? item?.id] = item?.applied_price ?? item?.price
        }
        return acc
      }, {})

      const items = addedItems.length > 0 ? addedItems : data.map((e: any) => e.option_id ?? e.id)
      if (items?.length) setAddedItems((pre: any) => Array.from(new Set([...pre, ...items])))
      if (items?.length) setPopupAddedItems((prev: any) => Array.from(new Set([...prev, ...items])))
      if (Object.keys(prices)?.length) setAddedItemPrices((prev: any) => ({ ...prev, ...prices }))

      const existingItems = totalSumArray.filter(
        (item: any) =>
          !defaultOptions.some(
            (def: any) => (def.option_id ?? def.id) === (item.option_id ?? item.id)
          )
      )
      if (existingItems?.length || defaultOptions?.length) setTotalSumArray([...existingItems, ...defaultOptions])
    }
  }, [defaultOptions])

  useEffect(() => {
    if (dealerOptions?.length > 0) {
      const selectedOptions = dealerOptions.filter(
        (option: any) => addedItems.includes(option.id) || addedItems.includes(option.option_id)
      )

      const prices = selectedOptions.reduce((acc: any, item: any) => {
        if (!addedItemPrices[item?.id]) {
          acc[item?.id] = item?.price
        }
        return acc
      }, {})

      setAddedItemPrices((prev: any) => ({ ...prev, ...prices }))
      setTotalSumArray((prev: any) => {
        const existingIds = prev.map((item: any) => item.option_id ?? item.id)
        const newItems = selectedOptions.filter(
          (item: any) => !existingIds.includes(item.option_id ?? item.id)
        )
        return [...prev, ...newItems]
      })

      const allSelectedIds = selectedOptions.map((item: any) => item.option_id ?? item.id)
      setPopupAddedItems((prev: any) => Array.from(new Set([...prev, ...allSelectedIds])))
    }
  }, [dealerOptions, addedItems])

  useEffect(() => {
    const selectedIds =
      addedItems.length > 0
        ? addedItems
        : totalSumArray.map((item: any) => item.option_id ?? item.id)
    setPopupAddedItems((prev: any) => Array.from(new Set([...prev, ...selectedIds])))
  }, [totalSumArray])

  const preInstalledOptions = (dealerOptions || [])?.filter((item: any) => {
    return item.category_name
      ? item.installation_mode === InstallationMode.PRE_INSTALLED && item.is_active === true
      : null
  })

  const callBack = (type: string, data: any) => {
    setTotalSumArray((prev: any) => {
      let updatedArray

      if (type === 'add') {
        if (!addedItems.includes(data.option_id ?? data.id)) {
          updatedArray = [...prev, data]
          setAddedItems((prevItems: any) => [...prevItems, data.option_id ?? data.id])
          setPopupAddedItems((prevItems: any) => [...prevItems, data.option_id ?? data.id])
        } else {
          updatedArray = prev
        }
      } else if (type === 'remove') {
        updatedArray = prev?.filter(
          (item: any) => (item.option_id ?? item.id) !== (data.option_id ?? data.id)
        )
        setAddedItems((prevItems: any) =>
          prevItems.filter((item: any) => item !== (data.option_id ?? data.id))
        )
        setPopupAddedItems((prevItems: any) =>
          prevItems.filter((item: any) => item !== (data.option_id ?? data.id))
        )
      } else if (type === 'update') {
        updatedArray = prev?.map((item: any) => {
          if ((item.option_id ?? item.id) === (data.option_id ?? data.id)) {
            return {
              ...item,
              price: data.price
            }
          }
          return item
        })
      } else {
        updatedArray = prev
      }
      setSelectedObjectsArray(updatedArray)
      return updatedArray
    })
  }

  const callBackBulk = (type: string, data: any) => {
    setTotalSumArray((prev: any) => {
      let updatedArray

      if (type === 'add') {
        updatedArray = [...prev, ...data]
        const newIds = data.map((item: any) => item.option_id ?? item.id)
        setAddedItems((prevItems: any) => Array.from(new Set([...prevItems, ...newIds])))
        setPopupAddedItems((prevItems: any) => Array.from(new Set([...prevItems, ...newIds])))
      } else if (type === 'remove') {
        updatedArray = prev?.filter(
          (item: any) =>
            !data.some(
              (dataItem: any) => (dataItem.option_id ?? dataItem.id) === (item.option_id ?? item.id)
            )
        )
        const removedIds = data.map((item: any) => item.option_id ?? item.id)
        setAddedItems((prevItems: any) => prevItems.filter((id: any) => !removedIds.includes(id)))
        setPopupAddedItems((prevItems: any) =>
          prevItems.filter((id: any) => !removedIds.includes(id))
        )
      } else {
        updatedArray = prev
      }

      setSelectedObjectsArray(updatedArray)
      return updatedArray
    })
  }

  const calculatedTotalSum = useMemo(() => {
    return totalSumArray.reduce((lastSum: number, obj: any) => {
      if (preInstalledOptions?.length > 0) {
        const matchingOption = preInstalledOptions.find(
          (preInstalledOption: any) =>
            preInstalledOption.id === obj.id || preInstalledOption.id === obj.option_id
        )
        if (matchingOption) {
          obj.applied_price = obj.is_price_inclusive
            ? 0
            : addedItemPrices?.[matchingOption?.id] ||
              matchingOption.applied_price ||
              matchingOption.price
        }
      }

      if (
        obj?.installation_mode === InstallationMode.OPTIONAL &&
        Object.keys(addedItemPrices ?? {}).some((key) => +key === obj?.option_id)
      ) {
        obj.applied_price = addedItemPrices[obj.option_id]
      }
      return lastSum + (obj.applied_price ?? obj.price ?? 0)
    }, 0)
  }, [totalSumArray, preInstalledOptions, addedItemPrices])

  return (
    <Fragment key={'AddOnOption'}>
      <OptionsTable
        callBack={callBack}
        callBackBulk={callBackBulk}
        defaultCurrency={defaultCurrency}
        preInstalledDealerOptions={preInstalledDealerOptions}
        addedItems={popupAddedItems}
        setAddedItems={setPopupAddedItems}
        addedItemPrices={addedItemPrices}
        setAddedItemPrices={setAddedItemPrices}
        modelName={modelName}
      />
      <DialogTotal
        theme={theme}
        className="dialog-total"
        display={'flex'}
        gap={2}
        justifyContent={'space-between'}
      >
        <Box theme={theme} className="fw-700">
          Total
        </Box>
        <Box theme={theme} className="primary" textAlign="right">
          {defaultCurrency}
          {parseFloat(calculatedTotalSum ?? 0)?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}
        </Box>
      </DialogTotal>
    </Fragment>
  )
}

export default React.memo(AddOnOption)
