import { FC, useEffect, useState } from 'react'
import { Link, useTheme } from '@mui/material'
import { Grid, Box, Button } from '@ntpkunity/controls'
import { AgreementPopup, ProductBanner } from 'components'
import { OrderInformationDetails } from 'models/order-information.interface'
import { useGetVehicleAgainstVin } from '@apis/customer-management.service'
import { Status, QueryKeys } from '@helpers/enums'
import { useQueryClient } from 'react-query'
import { useStoreContext } from '@store/storeContext'
import OrderAssetControl from 'controls/OrderAsser/order-asset-control'
import {
  useGetIndividualAgreement,
  useUpdateIndividualizedAgreement
} from '@apis/configurations.service'

const AssetDetails: FC<{
  isDraftOrder?: Boolean
  order_info: OrderInformationDetails
  setConfirmationPopupState?: any
  lenderName?: string
  hasError?: Boolean
  setError?: any
  openSlModal?: any
  lenders?: any
  setSelectedLenders?: any
  setOptionObject?: (order_values: OrderInformationDetails) => void
}> = ({
  isDraftOrder = false,
  order_info,
  setConfirmationPopupState,
  lenderName,
  setError,
  openSlModal,
  setOptionObject,
  setSelectedLenders,
  lenders
}) => {
  const theme = useTheme()
  const { mutate: getVehicle, data: vehicleDetails } = useGetVehicleAgainstVin()
  const [vehicleInformation, setVehicleInformation] = useState<any>(vehicleDetails)
  const queryClient = useQueryClient()
  const [defaultCurrency, setDefaultCurrency] = useState('')
  const { states } = useStoreContext()
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [agreementPopUp, setAgreementPopUp] = useState(false)
  const [individual, setIndividualAgreement] = useState<any[]>([])

  const path = window.location.pathname
  const orderReferenceId = path.split('/').pop()
  const { data: individualAgreement } = useGetIndividualAgreement(
    states?.dealerAndLenderInfo?.dealer_code,
    orderReferenceId
  )
  const {
    mutate: updateIndividualizedAgreement,
    isLoading: _updateRequestLoading,
    data: _updateResponse,
    error: _updateRequestError
  } = useUpdateIndividualizedAgreement()
  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])

  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0].symbol : ''
    )
  }, [currencies])
  useEffect(() => {
    const res = individualAgreement?.map((r: any) => ({
      ...r,
      selected: false
    }))
    const doesExist = res?.find(
      (agreement: any) => order_info?.individualized_agreement === agreement.agreement_clause
    )
    if (doesExist) {
      doesExist.selected = true
    }
    if (!doesExist && order_info?.individualized_agreement) {
      res?.push({
        agreement_clause: order_info?.individualized_agreement,
        selected: true,
        id: '1'
      })
    }
    setIndividualAgreement(res)
  }, [order_info, individualAgreement])

  useEffect(() => {
    if (!order_info?.individualized_agreement && updateIndividualizedAgreement) {
      const defaultAgreements = individualAgreement?.filter((item: any) => item.is_default)
      if (defaultAgreements?.length > 0 && order_info) {
        updateIndividualizedAgreement(
          {
            reference_number: order_info?.reference_number,
            individualized_agreement: defaultAgreements[0]['agreement_clause']
          },
          {
            onSuccess(_response: any) {
              setOptionObject?.(_response)
            }
          }
        )
      }
    }
  }, [individualAgreement, order_info])
  useEffect(() => {
    if (order_info?.Vehicle_details?.vin) {
      getVehicle(
        { vin: order_info?.Vehicle_details?.vin },
        {
          onSuccess(response: any) {
            setVehicleInformation(response)
          }
        }
      )
    }
  }, [order_info?.Vehicle_details?.vin])

  return (
    <>
      {agreementPopUp ? (
        <AgreementPopup
          order={order_info}
          setAgreementPopUp={setAgreementPopUp}
          individualAgreement={individual}
          setOptionObject={setOptionObject}
        />
      ) : (
        ''
      )}
      <ProductBanner theme={theme} className="product-banner">
        <Box theme={theme} className="pb-wrap">
          <Box theme={theme} className="thumbnail">
            <img
              src={
                vehicleInformation?.photo_urls && vehicleInformation?.photo_urls?.length > 0
                  ? vehicleInformation?.photo_urls[0]?.location
                  : ''
              }
            />
          </Box>
          <Box theme={theme} className="product-details">
            <Grid theme={theme} container columnSpacing={2}>
              <Grid theme={theme} item sm={6} xs={12}>
                <Box theme={theme} className="title-md">
                  {order_info?.Vehicle_details?.year} {order_info?.Vehicle_details?.make}{' '}
                  {order_info?.Vehicle_details?.model}
                </Box>
                <Box theme={theme} className="title-lg">
                  {order_info?.Vehicle_details?.trim_description}
                </Box>
                <Box theme={theme} className="title-sm">
                  Deal ID: <b>{order_info?.reference_number}</b>
                  {lenderName && (
                    <>
                      , Lender: <b>{lenderName}</b>
                    </>
                  )}
                </Box>
                <Box theme={theme} className="title-sm">
                  VIN: <b>{order_info?.Vehicle_details?.vin}</b>
                  {[
                    Status.FullyReceived,
                    Status.AwaitingMore,
                    Status.Approved,
                    Status.Submitted,
                    Status.InReview,
                    Status.AwaitingMore,
                    Status.Completed,
                    Status.Appointment,
                    Status.SchedulePickup
                  ]?.includes(order_info?.status) && (
                    <>
                      , Odometer{' '}
                      <b>
                        {(order_info?.order_asset.mileage || 0)?.toLocaleString(undefined, {
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0
                        })}
                      </b>
                    </>
                  )}
                  {[Status.PendingConfirmation, Status.Reviewed, Status.Draft].includes(
                    order_info?.status as Status
                  ) && (
                    <>
                      , Odometer:{' '}
                      {
                        <Link
                          href="javascript:void(0)"
                          className="link hover-underline"
                          onClick={() => {
                            setDialogOpen(true)
                            setError(false)
                          }}
                        >
                          {order_info?.order_asset.mileage ? (
                            <Link
                              className="link hover-underline"
                              onClick={() => {
                                setDialogOpen(true)
                              }}
                            >
                              {(order_info?.order_asset.mileage || 0)?.toLocaleString(undefined, {
                                maximumFractionDigits: 0,
                                minimumFractionDigits: 0
                              })}
                            </Link>
                          ) : (
                            'Add'
                          )}
                        </Link>
                      }
                    </>
                  )}
                  <>
                    {[
                      Status.PendingConfirmation,
                      Status.Reviewed,
                      Status.Draft,
                      Status.InReview
                    ]?.includes(order_info?.status) &&
                      (individualAgreement?.length > 0 ? (
                        <>
                          , Individualized Agreement:{' '}
                          <Link
                            className="link"
                            onClick={() => {
                              setAgreementPopUp(true)
                            }}
                          >
                            View/Edit
                          </Link>
                        </>
                      ) : null)}
                  </>
                </Box>
              </Grid>
              <Grid theme={theme} item sm={6} xs={12} textAlign="right">
                <Box theme={theme} className="actions-wrap">
                  <Box theme={theme} className="action-details">
                    <Box theme={theme} className="title-md">
                      Net Financed Amount
                    </Box>
                    <Box theme={theme} className="title-lg">
                      {isDraftOrder
                        ? '-'
                        : `
                ${defaultCurrency}
                ${order_info?.net_finance_amount?.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}`}
                    </Box>
                    <Box theme={theme} className="title-sm">
                      Type:{' '}
                      <b>
                        {order_info?.contract_term} Months
                        {isDraftOrder ? '' : ` ${order_info?.finance_type}`}
                      </b>
                      , Rate:{' '}
                      <b>
                        {isDraftOrder
                          ? '-'
                          : `
                  ${order_info?.apr?.toLocaleString(undefined, {
                    maximumFractionDigits: 4,
                    minimumFractionDigits: 4
                  })}
                  %`}
                      </b>
                    </Box>
                    <Box theme={theme} className="title-sm">
                      {order_info?.status == Status.SchedulePickup ||
                      order_info?.status == Status.Appointment ||
                      order_info?.status == Status.Completed ? (
                        <>Periodic Payment: </>
                      ) : (
                        <>Est. Periodic Payment: </>
                      )}
                      <b>
                        {isDraftOrder
                          ? '-'
                          : `${defaultCurrency}
                  ${(
                    (order_info?.estimated_monthly_payment || 0) +
                    (order_info?.monthly_sales_use_tax || 0)
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}`}
                      </b>
                    </Box>
                  </Box>
                  {order_info?.status == Status.PendingConfirmation && (
                    <Box theme={theme} className="actions">
                      <Button
                        secondary
                        fullWidth
                        theme={theme}
                        onClick={() => {
                          setConfirmationPopupState(true)
                        }}
                        text="Not Available"
                      />
                      <Button
                        primary
                        fullWidth
                        theme={theme}
                        onClick={() => {
                          if (lenders && lenders?.length > 0) {
                            const defaultLender = lenders?.find(
                              (lender: any) => lender.default_lender && !lender.is_already_selected
                            )
                            setSelectedLenders((defaultLender?.id && [defaultLender.id]) || [])
                            openSlModal()
                          }
                        }}
                        text="Available"
                      />
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
            {dialogOpen && (
              <OrderAssetControl
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                orderObj={order_info}
                setOptionObject={setOptionObject}
              />
            )}
          </Box>
        </Box>
      </ProductBanner>
    </>
  )
}

export default AssetDetails
