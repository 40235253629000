import React, { useState } from 'react'
import { useTheme } from '@mui/material'
import { Box, Typography, Button, Icon, Menu, Skeleton } from '@ntpkunity/controls'
import { BundleListWrap } from './product-bundles.styles'
import { DialogMessages } from '@helpers/enums'
import { ConfirmationDialog } from '@components'
import FAndISupplier from '../finance-insurance-supplier/finance-insurance-supplier'
import FIPriceDialog from '../individual-products/products-rate-dialog'
import { useGetDealerSurcharges } from '@apis/financial-insurance.service'

interface ProductsBundleListProps {
  selectedFniItems: any
  setSelectedFniItems: any
  loadingBundleFniMonthlyImpacts?: boolean
  items: any[]
  isSelected: boolean
  bundle: any
  defaultCurrency: string
  dealerInfo?: any
  setFniBundleChanges?: any
  isBundleDisabled?: boolean
  allFniProducts: any
  setIsBundleItemPriceChanged?: any,
  isCash: boolean
}

const ProductsBundleList: React.FC<ProductsBundleListProps> = ({
  selectedFniItems, 
  setSelectedFniItems,
  loadingBundleFniMonthlyImpacts,
  bundle,
  items,
  isSelected,
  defaultCurrency,
  dealerInfo,
  setFniBundleChanges,
  isBundleDisabled,
  allFniProducts,
  setIsBundleItemPriceChanged,
  isCash
}) => {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  const [confirmationPopupState, setConfirmationPopupState] = useState<boolean>(false)
  const [openProductDialog, setOpenProductDialog] = useState(false)
  const [cardDetails, setCardDetails] = useState()
  const {
    mutate: getDealerSurcharges,
    data: dealerSurcharges,
    isLoading: dealerSurchargesLoading
  } = useGetDealerSurcharges()

  const [selectedOption, setSelectedOption] = useState<any>(null)

  const disableRemoveButton = bundle?.items?.length <= 1

  const handleCloseAllDialogs = () => {
    setIsOpen(false)
    setOpenProductDialog(false)
    setConfirmationPopupState(false)
    setSelectedOption(null)
  }

  const handleProductDetailsView = (cardData: any) => {
    handleCloseAllDialogs()
    const updatedResponse = { ...cardData, markup: cardData?.bundle_markup }
    setOpenProductDialog(true)
    setCardDetails(updatedResponse)
  }

  const handleCloseProductDetails = () => {
    handleCloseAllDialogs()
  }

  const handleProductEditView = (cardData: any) => {
    handleCloseAllDialogs()
    const updatedResponse = { ...cardData, markup: cardData?.bundle_markup }
    getDealerSurcharges({
      pen_dealer_id: cardData?.pen_dealer_id,
      pen_product_id: cardData?.product_id
    })
    setCardDetails(updatedResponse)
    setIsOpen(true)
  }

  const editPopupCallback = (coverage: any) => {
    setIsBundleItemPriceChanged(true)
    setSelectedFniItems(
      selectedFniItems?.map((fniItem: any) => {
        if (fniItem.product_id === coverage?.product_id) {
          return {
            ...fniItem,
            rate_id: coverage?.rate_id,
            form_id: coverage?.form_id,
            price: coverage?.price,
            surcharges: coverage?.surcharges,
            deductiblePrice: coverage?.deductiblePrice,
            term: coverage?.term_months,
            term_months: coverage?.term_months,
            coverage_name: coverage?.coverageName
          }
        }

        return fniItem
      })
    )

    setFniBundleChanges((prevChanges: any) => {
      const bundleId = coverage?.bundle_id
      const productId = coverage?.product_id

      return {
        ...prevChanges,
        [bundleId]: {
          ...(prevChanges?.[bundleId] || {}),
          [productId]: {
            obj: {
              ...coverage,
              coverage_name: coverage?.coverageName,
              term: coverage?.term_months,
              term_months: coverage?.term_months
            }
          }
        }
      }
    })

    handleCloseAllDialogs()
  }

  const handleRemoveItem = (item: any) => {
    const bundleId = item?.bundle_id

    const getProductId = (item: any) => {
      if (item?.product_id) return item?.product_id
      const matchingProduct = allFniProducts?.find(
        (product: any) => product?.id === (item?.financial_insurance_id ?? item?.id)
      )
      return matchingProduct?.product_id || null
    }

    const productId = getProductId(item)

    setFniBundleChanges((prevState: any) => {
      const updatedState = { ...prevState }

      if (!updatedState?.[bundleId]) {
        updatedState[bundleId] = { removed: [], added: [] }
      }

      updatedState[bundleId].added = updatedState[bundleId]?.added?.filter(
        (addedItem: any) => getProductId(addedItem) !== productId
      )

      if (!updatedState[bundleId]?.removed?.includes(productId)) {
        updatedState[bundleId]?.removed?.push(productId)
      }

      setSelectedFniItems((prevItems: any) =>
        prevItems.filter((item: any) => getProductId(item) !== productId)
      )

      return updatedState
    })
    handleCloseAllDialogs()
  }

  const onRemoveConfirm = () => {
    if (!selectedOption) {
      return
    }
    handleRemoveItem(selectedOption)
  }

  return (
    <>
      <BundleListWrap className={`product-bundle-list ${isSelected ? 'selected-list' : ''}`}>
        {items?.map((item, index) => (
          <Box theme={theme} className="list-box" display="flex" gap={2} key={index} mb={2}>
            <Box theme={theme} className="list-box-details">
              <Typography theme={theme} className="title-sm" variant="caption" component="p">
                {item?.provider_name} - {item?.title}
              </Typography>
              {item?.price ? (
                <Typography
                  theme={theme}
                  variant="body2"
                  component="p"
                  display={'flex'}
                  flexWrap={loadingBundleFniMonthlyImpacts ? 'nowrap' : 'wrap'}
                  gap={0.5}
                >
                  <span className="title-sm">
                    {defaultCurrency}
                    {item?.price?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}
                  </span>
                  {!isCash &&
                    <>
                      {loadingBundleFniMonthlyImpacts ? (
                        <Skeleton
                          theme={theme}
                          variant="rectangular"
                          width={'100%'}
                          sx={{ borderRadius: '8px' }}
                        />
                      ) : (
                        <span className="title-sm">
                          {!!item?.monthlyImpact && `(${item?.monthlyImpact})`}
                        </span>
                      )}
                    </>
                  }
                </Typography>
              ) : null}
              <Box
                theme={theme}
                display="flex"
                alignItems="center"
                gap={2}
                className="mini-list text-muted"
              >
                {item?.duration && (
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="span"
                    className="mini-list-item"
                  >
                    {`${item?.duration} mo`}
                  </Typography>
                )}
                {item?.mileage && (
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="span"
                    className="mini-list-item"
                  >
                    {`${Number(item?.mileage)?.toLocaleString(undefined, {
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0
                    })} mi`}
                  </Typography>
                )}
                {item?.deductiblePrice ? (
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="span"
                    className="mini-list-item"
                  >
                    {defaultCurrency}
                    {(item?.deductiblePrice ?? 0).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </Typography>
                ) : null}
              </Box>
            </Box>
            <Menu
              theme={theme}
              disablePortal
              options={[
                {
                  optionText: 'View Details',
                  optionValue: 'viewDetails',
                  disabled: isBundleDisabled
                },
                {
                  optionText: 'Edit Coverage',
                  optionValue: 'editCoverage',
                  disabled: isBundleDisabled
                },
                {
                  optionText: 'Remove',
                  optionValue: 'remove',
                  disabled: disableRemoveButton || isBundleDisabled
                }
              ]}
              handleOptionClick={(_event, _key, value) => {
                setSelectedOption(item)
                switch (value) {
                  case 'viewDetails':
                    handleProductDetailsView(item)
                    break
                  case 'editCoverage':
                    handleProductEditView(item)
                    break
                  case 'remove':
                    setConfirmationPopupState(true)
                    break
                  default:
                    break
                }
              }}
              render={(onMenuSelection: any) => (
                <Button
                  theme={theme}
                  defaultBtn
                  iconText={<Icon name="MoreIcon" />}
                  onClick={onMenuSelection}
                />
              )}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            />
          </Box>
        ))}
      </BundleListWrap>
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onRemoveConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.fniBundleItemConfirmationTitle}
          confirmationText={DialogMessages.fniBundleItemConfirmationText}
          primaryBtnText={DialogMessages.removeBtnText}
          icon={<Icon name="DialogDeleteIcon" />}
          onCancel={function (_action: any): void {
            handleCloseAllDialogs()
          }}
          hideCancelButton={false}
        />
      )}
      {openProductDialog && (
        <FAndISupplier
          details={cardDetails}
          open={openProductDialog}
          onBackBtn={handleCloseProductDetails}
          defaultCurrency={defaultCurrency}
        />
      )}
      {isOpen && (
        <FIPriceDialog
          details={cardDetails}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          isSaveButtonDisabled={dealerSurchargesLoading}
          selectDataObj={items}
          dealerInfo={dealerInfo}
          defaultCurrency={defaultCurrency}
          formattedData={{
            surcharges: dealerSurcharges?.surcharges?.surcharge?.map((surcharge: any) => ({
              code: surcharge.code
            }))
          }}
          editPopupCallback={editPopupCallback}
        />
      )}
    </>
  )
}

export default ProductsBundleList
