import { FC } from 'react'
import { Link, useTheme } from '@mui/material'
import { Typography, Grid } from '@ntpkunity/controls'
import { ICustomer } from '@models'

interface CoApplicantProps {
  coapplicant_info: ICustomer
  onViewDetails: (isCoApplicant: boolean) => void
}

const CoApplicant: FC<CoApplicantProps> = ({ coapplicant_info, onViewDetails }) => {
  const theme = useTheme()

  if (!coapplicant_info) return null

  const coApplicantMailingAddress = coapplicant_info?.customer_addresses?.find(
    (address) => address.address_type === 'Mailing'
  )

  return (
    <Grid theme={theme} className="container" container rowSpacing={0} columnSpacing={1}>
      <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={6}>
        <Typography
          theme={theme}
          variant="body2"
          component="h3"
          className="label"
          children="Name"
        />
      </Grid>
      <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={6}>
        <Typography
          theme={theme}
          variant="body2"
          component="span"
          children={`${coapplicant_info?.first_name || ''} ${coapplicant_info?.middle_name || ''} ${coapplicant_info?.last_name || ''}`}
        />
      </Grid>
      <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={6}>
        <Typography
          theme={theme}
          variant="body2"
          component="h3"
          className="label"
          children="Address"
        />
      </Grid>
      <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={6}>
        <Typography
          theme={theme}
          variant="body2"
          component="span"
          children={
            coApplicantMailingAddress
              ? `${coApplicantMailingAddress.address_line_1} ${coApplicantMailingAddress.city}, ${coApplicantMailingAddress.state_name} ${coApplicantMailingAddress.zip_code}`
              : '-'
          }
        />
      </Grid>
      <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={6}>
        <Typography
          theme={theme}
          variant="body2"
          component="h3"
          className="label"
          children="Email"
        />
      </Grid>
      <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={6}>
        <Typography
          theme={theme}
          variant="body2"
          component="span"
          children={coapplicant_info?.email || '-'}
        />
      </Grid>
      <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={6}>
        <Typography
          theme={theme}
          variant="body2"
          component="h3"
          className="label"
          children="License Number"
        />
      </Grid>
      <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={6}>
        <Typography theme={theme} variant="body2" component="h3" className="label-detail">
          {coapplicant_info?.customer_license?.[0]?.license_number ?? '-'}
          {' - '}
          <Link className="link hover-underline" onClick={() => onViewDetails(true)}>
            View Details
          </Link>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default CoApplicant
