import { FC, useEffect, useState } from 'react'
import { Box, PackageWrap } from 'components'
import 'react-toastify/dist/ReactToastify.css'
import { NoCalculationData } from '@app/no-quote'
import { useStoreContext } from '@store/storeContext'
import { useOrderCalculationRequest } from '@apis/inventory-management.service'
import { useGetProgramInformation } from '@apis/order-management.service'
import useToastFunction from '@hooks/useToast'
import {
  useGetAllowedMileage,
  useGetContractTerms,
  useGetRating
} from '@apis/configurations.service'
import {
  useGetAllDealerFeebyState
  // useGetPreferenceByDealerCode
} from '@apis/dealer-configurations.service'
import OrderGrid from './order-grid'
import { Accordion, AccordionDetails, AccordionSummary, Link, useTheme } from '@mui/material'
import { Button, Icon, Typography } from '@ntpkunity/controls'
import { APP_ROUTES } from '@helpers/links'
import { useNavigate } from 'react-router-dom'

const CompareOrders: FC<{
  programCalculationData: any
  setProgramCalculationData: any
  maxCreditRating: any
  setMaxCreditRating: (data: any) => unknown
  setQuotationParameterData: (data: any) => unknown
  quotationParameterData: any
  quotationObject: any
  calculationPayload: any
  setCalculationPayload: (data: any) => unknown
  customerEmail: any
}> = ({
  programCalculationData,
  setProgramCalculationData,
  setQuotationParameterData,
  quotationParameterData,
  quotationObject,
  customerEmail
}) => {
  const [_defaultMileageValue, _setDefaultMileageValue] = useState(0)
  const { states } = useStoreContext()
  const navigate = useNavigate()
  const [feeData, setFeeData] = useState([])
  const [optionData, setOptionData] = useState([])
  const [fnIData, setFnIData] = useState([])
  const {
    mutate: _getProgramInformationRequest,
    isLoading: programReqLoading,
    error: programReqError
  } = useGetProgramInformation()
  const { mutate: getAllDealerFee, data: allDealerFeeData } = useGetAllDealerFeebyState()
  const { mutate: getContractTerms, data: contractTermsData } = useGetContractTerms()
  const { mutate: getCreditRating, data: _creditRatingData } = useGetRating()
  const { mutate: getAllowedMileage, data: allowedMileageData } = useGetAllowedMileage()

  useEffect(() => {
    if (states?.dealerAndLenderInfo?.company_id)
      getAllowedMileage({ company_id: states?.dealerAndLenderInfo?.company_id })
  }, [states?.dealerAndLenderInfo?.company_id])

  useEffect(() => {
    if (states?.dealerAndLenderInfo?.dealer_code) {
      getAllDealerFee({
        dealer_id: states?.dealerAndLenderInfo?.dealer_code
      })
    }
    if (states?.dealerAndLenderInfo?.company_id) {
      getContractTerms({ company_id: states?.dealerAndLenderInfo?.company_id })
      getCreditRating({ company_id: states?.dealerAndLenderInfo?.company_id })
    }
    const quotationsFee: any = []
    quotationObject.forEach((quotations: any) => {
      quotations.forEach((quotation: any) => {
        if (quotation.order_fees) {
          quotationsFee.push(
            ...quotation.order_fees.filter((item: any) => {
              return !quotationsFee.some(
                (feeItem: any) => feeItem.dealer_fee_id === item.dealer_fee_id
              )
            })
          )
        }
      })
    })

    setFeeData(quotationsFee)
    const quotationsOption: any = []
    quotationObject.forEach((quotations: any) => {
      quotations.forEach((quotation: any) => {
        if (quotation.order_options) {
          quotationsOption.push(
            ...quotation.order_options.filter((item: any) => {
              return !quotationsOption.some(
                (optionItem: any) => optionItem.option_id === item.option_id
              )
            })
          )
        }
      })
    })

    setOptionData(quotationsOption)
    const quotationsfnI: any = []
    quotationObject.forEach((quotations: any) => {
      quotations.forEach((quotation: any) => {
        if (quotation.order_fnI) {
          quotationsfnI.push(
            ...quotation.order_fnI.filter((item: any) => {
              return !quotationsfnI.some(
                (fniItem: any) => fniItem.financial_insurance_id === item.financial_insurance_id
              )
            })
          )
        }
      })
    })

    setFnIData(quotationsfnI)
  }, [states?.dealerAndLenderInfo?.company_id, states?.dealerAndLenderInfo?.dealer_code])
  const [selectedAccordion, setSelectedAccordion] = useState({
    periodicPayment: false,
    rvBalloon: false,
    options: false,
    fees: false,
    fIProducts: false,
    insurance: false,
    nfa: false,
    nfaList: false,
    ccr: false
  })
  const {
    mutateAsync: _getOrderCalculationRequest,
    isLoading: calcReqLoading,
    error: calcReqError,
    data: orderRequestData
  } = useOrderCalculationRequest()
  useEffect(() => {
    const programData = quotationObject.map((quotations: any) =>
      quotations.map((quotation: any) => ({
        program: {
          finance_type: quotation?.finance_type,
          rental_mode: quotation?.rental_mode,
          final_customer_rate: quotation?.final_customer_rate,
          fp_id: quotation?.fp_id,
          calculation_method: quotation?.calcuation_method,
          interest_chart: [{ margin: quotation?.margin }],
          base_rate_chart: { base_rate: quotation?.base_rate },
          reference_number: quotation?.reference_number
        },
        calculation: {
          down_payment: (quotation?.selling_price * quotation?.down_payment) / 100,
          due_at_signing: quotation?.due_at_signing,
          estimated_monthly_payment: quotation?.estimated_monthly_payment,
          rv_amount: (quotation?.selling_price * quotation?.rv_balloon_percentage) / 100,
          selling_price: quotation?.selling_price
        }
      }))
    )
    const quotationData = quotationObject.map((quotations: any) =>
      quotations.map((quotation: any) => ({
        reference_number: quotation?.reference_number,
        contract_term_id: contractTermsData?.filter(
          (term: any) => term.term === quotation?.contract_term
        )[0].id,
        finance_rate: quotation?.apr ? quotation?.apr : 0,
        lease_rate: quotation?.apr ? quotation?.apr : 0,
        finance_down_payment: quotation?.down_payment ? quotation?.down_payment : 0,
        lease_down_payment: quotation?.down_payment ? quotation?.down_payment : 0,
        annual_mileage:
          quotation?.annual_usage ??
          (states?.dealerPreferenceData?.default_mileage_id && allowedMileageData
            ? allowedMileageData?.find(
                (f: any) => f?.id == states?.dealerPreferenceData?.default_mileage_id
              )?.value
            : 0),
        selling_price: quotation?.selling_price,
        credit_rating: quotation?.credit_rating,
        rental_frequency: quotation?.rental_frequency,
        options: quotation?.order_options,
        lease_fni: quotation?.order_fnI,
        finance_fni: quotation?.order_fnI,
        insurance: quotation?.Insurance_Inforation,
        trade_in: quotation?.order_tradein,
        asset_usage: quotation?.asset_usage,
        schedule_option: quotation?.schedule_option,
        finance_rv_rate: quotation?.rv_balloon_percentage,
        lease_rv_rate: quotation?.rv_balloon_percentage,
        lease_fees: quotation?.order_fees,
        finance_fees: quotation?.order_fees
      }))
    )
    setProgramCalculationData(programData)
    setQuotationParameterData(quotationData)
  }, [allowedMileageData])
  useToastFunction(
    calcReqLoading || programReqLoading,
    orderRequestData,
    calcReqError || programReqError,
    {
      text:
        calcReqLoading || programReqLoading
          ? 'Calculation in progress'
          : calcReqError || programReqError
          ? 'Some Error Orccured'
          : 'Successful',
      type:
        calcReqError || programReqError
          ? 'error'
          : calcReqLoading || programReqLoading
          ? 'info'
          : 'success'
    }
  )

  const sortedOptionsArray: any = optionData?.sort((a: any, b: any) =>
    a?.installation_mode?.localeCompare(b.installation_mode)
  )
  const theme = useTheme()

  return (
    <>
      <Box sx={{ mb: 5, mt: 3 }}>
        <PackageWrap theme={theme} className="package-wrap" sx={{ mb: 5 }}>
          <Box theme={theme} className="package-container fixed-container">
            <Box theme={theme} className="package-cell">
              <Box theme={theme} className="package-row header header-top" sx={{ mb: 2 }}>
                <Box theme={theme} display="inline-block">
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconLeftArrow" />}
                    className="btn-back"
                    onClick={() => navigate(APP_ROUTES?.WORKQUEUE)}
                  />
                </Box>
                <Box theme={theme} display="block" sx={{ mb: 2 }}>
                  Deals Comparison
                </Box>
                <Typography
                  theme={theme}
                  component="div"
                  variant="body2"
                  display="block"
                  className="text-muted"
                  children="You are comparing the deals of"
                />
                <Typography theme={theme} component="div" variant="body2" display="block">
                  <Link className="link">{customerEmail}</Link>
                </Typography>
              </Box>
              <Box theme={theme} className="package-row header">
                Quotation
              </Box>
              <Box theme={theme} className="package-body">
                <Box theme={theme} className="package-row toggle">
                  <span>
                    <b>Estimated Periodic Payment</b>
                  </span>
                  {selectedAccordion.periodicPayment ? (
                    <Icon
                      name="ChevronDown"
                      className="toggle-btn ch-up"
                      onClick={() =>
                        setSelectedAccordion({
                          ...selectedAccordion,
                          periodicPayment: !selectedAccordion.periodicPayment
                        })
                      }
                    />
                  ) : (
                    <Icon
                      name="ChevronDown"
                      className="toggle-btn"
                      onClick={() =>
                        setSelectedAccordion({
                          ...selectedAccordion,
                          periodicPayment: !selectedAccordion.periodicPayment
                        })
                      }
                    />
                  )}
                </Box>
                <Accordion expanded={selectedAccordion.periodicPayment}>
                  <AccordionSummary
                    aria-con-trols="panel1a-content"
                    id="panel1a-header"
                  ></AccordionSummary>
                  <AccordionDetails>
                    <Box theme={theme} sx={{ pl: 3 }}>
                      <Box theme={theme} className="package-row">
                        Net Periodic Payment
                      </Box>
                      <Box theme={theme} className="package-row">
                        Tax Amount
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Box theme={theme} className="package-row">
                  <b>Due at Signing</b>
                </Box>
                <Box theme={theme} className="package-row">
                  Terms
                </Box>
                <Box theme={theme} className="package-row">
                  Rate
                </Box>
                <Box theme={theme} className="package-row">
                  Annual Mileage
                </Box>
                <Box theme={theme} className="package-row">
                  <hr />
                </Box>
                <Box theme={theme} className="package-row">
                  Selling Price
                </Box>
                <Box theme={theme} className="package-row">
                  Down Payment
                </Box>
                <Box theme={theme} className="package-row toggle">
                  <span>RV/Balloon</span>
                  {selectedAccordion.rvBalloon ? (
                    <Icon
                      name="ChevronDown"
                      className="toggle-btn ch-up"
                      onClick={() =>
                        setSelectedAccordion({
                          ...selectedAccordion,
                          rvBalloon: !selectedAccordion.rvBalloon
                        })
                      }
                    />
                  ) : (
                    <Icon
                      name="ChevronDown"
                      className="toggle-btn"
                      onClick={() =>
                        setSelectedAccordion({
                          ...selectedAccordion,
                          rvBalloon: !selectedAccordion.rvBalloon
                        })
                      }
                    />
                  )}
                </Box>
                <Accordion expanded={selectedAccordion.rvBalloon}>
                  <AccordionSummary
                    aria-con-trols="panel1a-content"
                    id="panel1a-header"
                  ></AccordionSummary>
                  <AccordionDetails>
                    <Box theme={theme} sx={{ pl: 3 }}>
                      <Box theme={theme} className="package-row">
                        End of Term Payment
                      </Box>
                      <Box theme={theme} className="package-row">
                        RV on Add-Ons
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Box theme={theme} className="package-row toggle">
                  <span>Add-Ons</span>
                  {selectedAccordion.options ? (
                    <Icon
                      name="ChevronDown"
                      className="toggle-btn ch-up"
                      onClick={() =>
                        setSelectedAccordion({
                          ...selectedAccordion,
                          options: !selectedAccordion.options
                        })
                      }
                    />
                  ) : (
                    <Icon
                      name="ChevronDown"
                      className="toggle-btn"
                      onClick={() =>
                        setSelectedAccordion({
                          ...selectedAccordion,
                          options: !selectedAccordion.options
                        })
                      }
                    />
                  )}
                </Box>
                <Accordion expanded={selectedAccordion.options}>
                  <AccordionSummary
                    aria-con-trols="panel1a-content"
                    id="panel1a-header"
                  ></AccordionSummary>
                  <AccordionDetails>
                    {sortedOptionsArray?.length > 0 &&
                      sortedOptionsArray?.map((option: any, index: any) => (
                        <Box theme={theme} sx={{ pl: 3 }} key={index}>
                          <>
                            {sortedOptionsArray[index - 1]?.installation_mode !==
                              option?.installation_mode && (
                              <Box theme={theme} className="package-row">
                                <b>
                                  <small>{option?.installation_mode}</small>
                                </b>
                              </Box>
                            )}
                            <Box theme={theme} className="package-row">
                              {option?.product_name}
                            </Box>
                          </>
                        </Box>
                      ))}
                  </AccordionDetails>
                </Accordion>

                <Box theme={theme} className="package-row toggle">
                  <span>Fees</span>
                  {selectedAccordion.fees ? (
                    <Icon
                      name="ChevronDown"
                      className="toggle-btn ch-up"
                      onClick={() => {
                        setSelectedAccordion({
                          ...selectedAccordion,
                          fees: !selectedAccordion.fees
                        })
                      }}
                    />
                  ) : (
                    <Icon
                      name="ChevronDown"
                      className="toggle-btn"
                      onClick={() => {
                        setSelectedAccordion({
                          ...selectedAccordion,
                          fees: !selectedAccordion.fees
                        })
                      }}
                    />
                  )}
                </Box>
                <Accordion expanded={selectedAccordion.fees}>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  ></AccordionSummary>

                  <AccordionDetails>
                    <Box theme={theme} sx={{ pl: 3 }}>
                      {feeData?.map((dealerData: any, index: number) => (
                        <Box theme={theme} key={index} className="package-row">
                          {dealerData?.fee_name}
                        </Box>
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Box theme={theme} className="package-row toggle">
                  <span>F&I Products</span>
                  {selectedAccordion.fIProducts ? (
                    <Icon
                      name="ChevronDown"
                      className="toggle-btn ch-up"
                      onClick={() =>
                        setSelectedAccordion({
                          ...selectedAccordion,
                          fIProducts: !selectedAccordion.fIProducts
                        })
                      }
                    />
                  ) : (
                    <Icon
                      name="ChevronDown"
                      className="toggle-btn"
                      onClick={() =>
                        setSelectedAccordion({
                          ...selectedAccordion,
                          fIProducts: !selectedAccordion.fIProducts
                        })
                      }
                    />
                  )}
                </Box>
                <Accordion expanded={selectedAccordion.fIProducts}>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  ></AccordionSummary>

                  <AccordionDetails>
                    <Box theme={theme} sx={{ pl: 3 }}>
                      {fnIData.map((fniData: any, index: number) => (
                        <Box theme={theme} key={index} className="package-row">
                          {fniData?.product_name}
                        </Box>
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Box theme={theme} className="package-row toggle">
                  <span>Trade-In Equity/Inequity</span>
                </Box>
                <Box theme={theme} className="package-row toggle">
                  <span>Taxes Capitalization</span>
                </Box>
                <Box theme={theme} className="package-row divider-row">
                  <hr />
                </Box>
                <Box theme={theme} className="package-row toggle">
                  <span>
                    <b>NFA/Adjusted Capitalized Cost</b>
                  </span>
                  {selectedAccordion.nfa ? (
                    <Icon
                      name="ChevronDown"
                      className="toggle-btn ch-up"
                      onClick={() =>
                        setSelectedAccordion({
                          ...selectedAccordion,
                          nfa: !selectedAccordion.nfa
                        })
                      }
                    />
                  ) : (
                    <Icon
                      name="ChevronDown"
                      className="toggle-btn"
                      onClick={() =>
                        setSelectedAccordion({
                          ...selectedAccordion,
                          nfa: !selectedAccordion.nfa
                        })
                      }
                    />
                  )}
                </Box>
                <Accordion expanded={selectedAccordion.nfa}>
                  <AccordionSummary aria-con-trols="panel1a-content" id="panel1a-header" />
                  <AccordionDetails>
                    <Box theme={theme} sx={{ pl: 3 }}>
                      <Box theme={theme} className="package-row toggle">
                        <span>Financed Amount/Gross Capitalized Cost</span>
                        {selectedAccordion.nfaList ? (
                          <Icon
                            name="ChevronDown"
                            className="toggle-btn ch-up"
                            onClick={() =>
                              setSelectedAccordion({
                                ...selectedAccordion,
                                nfaList: !selectedAccordion.nfaList
                              })
                            }
                          />
                        ) : (
                          <Icon
                            name="ChevronDown"
                            className="toggle-btn"
                            onClick={() =>
                              setSelectedAccordion({
                                ...selectedAccordion,
                                nfaList: !selectedAccordion.nfaList
                              })
                            }
                          />
                        )}
                      </Box>
                      <Accordion expanded={selectedAccordion.nfaList}>
                        <AccordionSummary aria-con-trols="panel1a-content" id="panel1a-header" />
                        <AccordionDetails>
                          <Box theme={theme} sx={{ pl: 3 }}>
                            <Box theme={theme} className="package-row">
                              Selling Price
                            </Box>
                            <Box theme={theme} className="package-row">
                              Add-Ons
                            </Box>
                            <Box theme={theme} className="package-row">
                              F&I Products
                            </Box>
                            <Box theme={theme} className="package-row">
                              Fees
                            </Box>
                            <Box theme={theme} className="package-row">
                              Trade-In Inequity
                            </Box>
                            <Box theme={theme} className="package-row">
                              Taxes Capitalization
                            </Box>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                      <Box theme={theme} className="package-row toggle">
                        <span>Capitalized Cost Reduction</span>
                        {selectedAccordion.ccr ? (
                          <Icon
                            name="ChevronDown"
                            className="toggle-btn ch-up"
                            onClick={() =>
                              setSelectedAccordion({
                                ...selectedAccordion,
                                ccr: !selectedAccordion.ccr
                              })
                            }
                          />
                        ) : (
                          <Icon
                            name="ChevronDown"
                            className="toggle-btn"
                            onClick={() =>
                              setSelectedAccordion({
                                ...selectedAccordion,
                                ccr: !selectedAccordion.ccr
                              })
                            }
                          />
                        )}
                      </Box>
                      <Accordion expanded={selectedAccordion.ccr}>
                        <AccordionSummary aria-con-trols="panel1a-content" id="panel1a-header" />
                        <AccordionDetails>
                          <Box theme={theme} sx={{ pl: 3 }}>
                            <Box theme={theme} className="package-row">
                              Down Payment
                            </Box>
                            <Box theme={theme} className="package-row">
                              Trade-In Equity
                            </Box>
                            <Box theme={theme} className="package-row">
                              Rebates
                            </Box>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Box>
          </Box>
          {!calcReqLoading &&
            programCalculationData?.length > 0 &&
            programCalculationData.map((quotations: any, index: number) => (
              <OrderGrid
                key={index}
                selectedAccordion={selectedAccordion}
                programCalculationData={!calcReqLoading && !programReqLoading && quotations}
                allDealerFeeData={allDealerFeeData?.filter((x: any) => x.is_active == true)}
                feeData={feeData}
                fnIData={fnIData}
                optionData={optionData}
                quotationParameterData={quotationParameterData}
                // setQuotationParameterData={setQuotationParameterData}
                quotationObject={
                  quotationObject
                    .map((data: any) =>
                      data.filter(
                        (obj: any) =>
                          obj.reference_number == quotations[0]?.program?.reference_number
                      )
                    )
                    .filter((array: any) => array.length > 0)[0]
                }
              />
            ))}
        </PackageWrap>
        {orderRequestData?.length <= 0 && !calcReqLoading && <NoCalculationData />}
      </Box>
    </>
  )
}
export default CompareOrders
