import { styled } from "@mui/material/styles"
import { Box, IBoxProps } from "@ntpkunity/controls"

export const DialogContentContainer = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.dialog-content-container": {
    '.u-accordion-header.u-accordion-group-header .main-title': {
      width: '100%',
      flex: '0 0 100%',
      maxWidth: '100%'
    },
    '.MuiCollapse-wrapperInner': {
      '&.MuiCollapse-vertical': {
        '.MuiAccordion-region': {
          '.u-accordion-group-body': {
            padding: 24,
            '.content-header': {
              borderBottom: '1px solid' + theme.palette.divider,
              marginBottom: 24,
              paddingBottom: 16,
              ':empty': {
                marginBottom: 0,      
                paddingBottom: 0,
                borderBottom: 0
              }
            },
            '.content-body': {
              '.fill-svg': {
                '&.primary': {
                  svg: {
                    path: {
                      stroke: theme.palette.primary.main
                    }
                  }
                },
                '&.info': {
                  svg: {
                    path: {
                      stroke: theme.palette.info.main
                    }
                  }
                },
                '&.warning': {
                  svg: {
                    path: {
                      stroke: theme.palette.warning.main
                    }
                  }
                },
                '&.success': {
                  svg: {
                    path: {
                      stroke: theme.palette.success.main
                    }
                  }
                },
                '&.error': {
                  svg: {
                    path: {
                      stroke: theme.palette.error.main
                    }
                  }
                }
              }
            },
            '.color': {
              '&.primary': {
                color: theme.palette.primary.main
              },
              '&.info': {
                color: theme.palette.info.main
              },
              '&.warning': {
                color: theme.palette.warning.main
              },
              '&.success': {
                color: theme.palette.success.main
              },
              '&.error': {
                color: theme.palette.error.main
              }
            },
            '.title': {
              textTransform: 'uppercase'
            }
          }
        }
      }
    }
  }
}))
