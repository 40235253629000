// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'UnityFontFamily';
  src: url('https://unitydmsbucket.s3.amazonaws.com/c6a592Inter.eot') format('embedded-opentype'),
    url('https://unitydmsbucket.s3.amazonaws.com/5140cbInter.woff2') format('woff2'),
    url('https://unitydmsbucket.s3.amazonaws.com/c341ffInter.woff') format('woff'),
    url('https://unitydmsbucket.s3.amazonaws.com/8b600dInter.ttf') format('truetype');
}
`, "",{"version":3,"sources":["webpack://./src/styles/font-family.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B;;;qFAGmF;AACrF","sourcesContent":["@font-face {\n  font-family: 'UnityFontFamily';\n  src: url('https://unitydmsbucket.s3.amazonaws.com/c6a592Inter.eot') format('embedded-opentype'),\n    url('https://unitydmsbucket.s3.amazonaws.com/5140cbInter.woff2') format('woff2'),\n    url('https://unitydmsbucket.s3.amazonaws.com/c341ffInter.woff') format('woff'),\n    url('https://unitydmsbucket.s3.amazonaws.com/8b600dInter.ttf') format('truetype');\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
