import { FC, useState, useEffect, useCallback, useMemo } from 'react'
import { useTheme } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Button, Grid, Icon, Typography } from '@ntpkunity/controls'
import { BtnTabs, ConfirmationDialog } from '@components'
import {
  DeskingActionTypes,
  FinanceTypes,
  OrderStage,
  useDeskingContext,
  VehiclesForComparisonTypes
} from './desking-context-provider'
import { FinanceLeaseButton, FLButtons } from './fl-buttons'
import { createOrderRequestPayloadFromState, getDealType, getIsProgramLoading } from '@app/desking/utils'
import { useGetOrderInformation, useSaveOrderRequest, useUpdateQuotationById } from '@apis/order-management.service'
import { useStoreContext } from '@store/storeContext'
import { ICustomer, IDealerFee, IVehicle } from '@models'
import { useUpdateOrderStatusAndLender } from '@apis/dealer-workqueue.service'
import { useSendCustomerOrderLink } from '@apis/customer-management.service'
import { FeeEvent } from '@helpers/enums/finance-type.enum'
import {
  CONTINUE_DEAL,
  UPDATE_DEAL,
  RESERVE_ASSET,
  SAVE_AND_CONTINUE,
  FEE_MONTHLY_PAYMENT_BASED,
  DEAL_DESK,
  COMPARISON_DESK
} from '@helpers/constants'
import { useGetReservation } from '@apis/configurations.service'
import ReservationDialog from 'controls/reservation-dialog'
import { useGetInventoryByVin } from '@apis/inventory-management.service'
import { useGetVehicleDetails } from '@apis/dealer-addon.service'
import { DialogMessages, InventoryType, QueryKeys, Status, Vehicle_Status } from '@helpers/enums'
import { APP_ROUTES } from '@helpers/links'
import { useEditPermissionHook } from '@hooks/useEditPermissionHook'
import { useQuery, useQueryClient } from 'react-query'
import { usePreventNavigation } from '@hooks/usePreventNavigation'

export const FinanceLeaseHeader: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [searchParams, setSearchParams] = useSearchParams()
  const [showPayment, setShowPayment] = useState(false)
  const [showVehicleNotAvailable, setShowVehicleNotAvailable] = useState<boolean>(false)
  const [showFAndIRemove, setShowFAndIRemove] = useState<boolean>(false)
  const orderReferenceId = searchParams.get('reference_id')
  const vsrSelectedVin = searchParams.get('vin')
  const {
    states: {
      dealDesk: { dealerFees },
      dealerInfo
    }
  } = useStoreContext()
  const { states } = useStoreContext()
  const { state, dispatch } = useDeskingContext()
  const {
    vehiclesForComparison,
    order,
    customer,
    isVehicleSelectedAndOrderSaved,
    lender_id,
    submission_tier,
    vehiclesData,
    preview,
    vehiclesVins,
    selection
  } = state

  const canUserEdit = useEditPermissionHook()

  const {
    mutate: getVehicleByVin,
    data: vehicle
  } = useGetInventoryByVin()
  const { mutate: saveOrderRequest, isLoading: saveRequestLodaing } = useSaveOrderRequest()
  const { mutateAsync: updateOrderRequest, isLoading: updateQuotationLoading } =
    useUpdateQuotationById()
  const { mutate: updateOrderStatus } = useUpdateOrderStatusAndLender()
  const { mutate: sendCustomerOrderLink, isLoading: sendCustomerOrderLinkLoading } =
    useSendCustomerOrderLink()
  const { mutate: getOrderInformation, isLoading: orderInformationLoading } = useGetOrderInformation()
  const { mutate: getVehicleDetails } = useGetVehicleDetails()
  const { data: getReservation } = useGetReservation(states?.dealerInfo?.dealer_code)
  const { isFetching: _orderFetching, data: lastOrderState } = useQuery(
    ['Order', orderReferenceId],
    () => { },
    { enabled: false }
  )

  const finance_type = useMemo(() => getDealType(state, selection?.vin), [selection?.vin, vehiclesForComparison, state.finance_type])
  const {
    vin,
    isDealerCodeMatch,
    isVehicleAndCustomerAdded,
    isBoxSelected
  } = useMemo(() => {
    const vin = selection?.vin ?? ""
    const dealerCode = states?.dealerInfo?.dealer_code
    const vehicleDealerCode = vehiclesData?.get(vin)?.vehicle?.dealer_code
    const isDealerCodeMatch = vehicleDealerCode === dealerCode

    const isVehicleAndCustomerAdded =
    Object.keys(customer).length !== 0 &&
    selection &&
    Object.keys(selection).length !== 0

    const isBoxSelected = selection?.finance_type !== undefined

    return {
      vin,
      isDealerCodeMatch,
      isVehicleAndCustomerAdded,
      isBoxSelected
    }
  }, [selection, states?.dealerInfo?.dealer_code, customer])

  useEffect(() => {
    if (!vin) return
    updateVehicleLoading(true)
    getVehicleByVin(
      { vin: vin },
      {
        onSettled() {
          updateVehicleLoading(false)
        }
      }
    )
  }, [vin])

  useEffect(() => {
    if (vsrSelectedVin && states?.dealerInfo?.company_id) {
      updateVehicleLoading(true)
      getVehicleDetails(
        { vin: vsrSelectedVin },
        {
          onSuccess(response: any) {
            if (response?.vehicle_status === Vehicle_Status.Available) {
              getVehicleByVin(
                { vin: vsrSelectedVin },
                {
                  onSuccess(vehicle: IVehicle) {
                    dispatch({
                      type: DeskingActionTypes.ADD_VEHICLE_VIN,
                      payload: vehicle
                    })
                  }
                }
              )
            } else setShowVehicleNotAvailable(true)
          },
          onError(error: any) {
            const vehicleNotFound = error?.response?.status === 404
            if (vehicleNotFound) setShowVehicleNotAvailable(true)
          },
          onSettled() {
            updateVehicleLoading(false)
          }
        }
      )
    }
  }, [vsrSelectedVin, states?.dealerInfo?.company_id])

  const updateVehicleLoading = useCallback((state: boolean) => {
    dispatch({
      type: DeskingActionTypes.ADD_VEHICLE_LOADING,
      payload: state
    })
  }, [])

  const handleClosePayment = () => {
    setShowPayment(false)
    if (state.proceededWithoutPayment) {
      dispatch({
        type: DeskingActionTypes.SKIP_RESERVATION,
        payload: true
      })
      dispatch({
        type: DeskingActionTypes.UPDATE_PROCEEDED_WITHOUT_PAYMENT,
        payload: false
      })
      dispatch({
        type: DeskingActionTypes.SAVE_ORDER,
        payload: false
      })

      if (orderReferenceId) {
        updateOrderRequest(
          {
            ...createOrderRequestPayloadFromState(state, dealerInfo, lender_id),
            order_stage: OrderStage.APPLICATION
          },
          {
            onSuccess(response: { reference_number: string }) {
              const params = new URLSearchParams()
              params.set('reference_id', response?.reference_number ?? orderReferenceId)

              if ('reference_id' in customer) {
                params.set('customer_reference_id', customer.reference_id ?? '')
              }

              setSearchParams(params)
              navigate(`/lane/desking?${params.toString()}`)
            }
          }
        )
      } else {
        const orderReqObj = createOrderRequestPayloadFromState(state, dealerInfo, lender_id)
        saveOrderRequest([orderReqObj], {
          onSuccess(response: { reference_number: string }[]) {
            const params = new URLSearchParams()
            params.set('reference_id', response[0]?.reference_number ?? '')

            if ('reference_id' in customer) {
              params.set('customer_reference_id', customer.reference_id ?? '')
            }

            setSearchParams(params)
            navigate(`/lane/desking?${params.toString()}`)
          }
        })
      }
    }
  }

  const handleCashTypeOrderFlow = () => {
    let orderReqObj: any = createOrderRequestPayloadFromState(state, dealerInfo, lender_id)
    orderReqObj.order_stage = OrderStage.QUOTATION

    return saveOrderRequest([orderReqObj], {
      onSuccess: async (response: { reference_number: string }[]) => {
        if (response?.[0]?.reference_number) {
          getOrderInformation({ reference_number: response[0].reference_number }, {
            onSuccess: (orderInfo: any) => {
              dispatch({
                type: DeskingActionTypes.MAP_ORDER_TO_STORE,
                payload: orderInfo
              })
            }
          })
          return response[0].reference_number
        }
      },
      onError: (_error: any) => {
      }
    })
  }

  const updateorderStatus = () => {
    const payload = {
      order_id: order?.reference_number,
      lender_id
    }

    updateOrderStatus(payload, {
      async onSuccess() {
        navigate(`/lane/deal-approved/${order?.reference_number}`)
      }
    })
  }

  const onSendOrderLinkToCustomer = () => {
    sendCustomerOrderLink({
      reference_number: order?.reference_number
    })
  }

  const isContinueDealDisabled = () => {
    const isOtherInventoryWithMatchingDealer =
      getReservation?.reservation?.applicable_to === InventoryType.OtherInventory &&
      isDealerCodeMatch

    return (
      saveRequestLodaing ||
      (isVehicleSelectedAndOrderSaved &&
        !submission_tier &&
        !state.hasUnsavedOrderChanges &&
        !state.proceededWithoutPayment &&
        !state.skipReservation &&
        !isOtherInventoryWithMatchingDealer)
    )
  }
  const isUpdateDealDisabled = () => {
    const vehiclesData = state.vehiclesData.get(vin)
    const program = (vehiclesData?.program ?? {})?.[finance_type]?.find(
      (p: any) =>
        p?.contract_term === state.order?.contract_term && p?.is_default &&
        (finance_type === FinanceTypes.FINANCE ||
          p?.mileage === state.order?.annual_usage)
    )

    return getPrimaryButtonText() === UPDATE_DEAL && (getIsProgramLoading(state, 0) || !program)
  }

  const getPrimaryButtonText = () => {
    const isReservationApplicable =
      getReservation?.reservation?.applicable_to !== null &&
      getReservation?.reservation?.applicable_to !== undefined
    const isOtherInventoryWithMatchingDealer =
      getReservation?.reservation?.applicable_to === InventoryType.OtherInventory &&
      isDealerCodeMatch

    if (finance_type === FinanceTypes.CASH) {
      if (
        isReservationApplicable &&
        getReservation?.reservation?.reservation_amount &&
        !state.skipReservation &&
        !state?.order?.order_payments &&
        !isOtherInventoryWithMatchingDealer &&
        state.vehiclesForComparison !== VehiclesForComparisonTypes.MULTIPLE
      ) {
        return RESERVE_ASSET
      }
      return SAVE_AND_CONTINUE
    }

    if (
      isReservationApplicable &&
      getReservation?.reservation?.reservation_amount &&
      isVehicleSelectedAndOrderSaved &&
      !state.skipReservation &&
      !state.proceededWithoutPayment &&
      !submission_tier &&
      !state?.order?.order_payments &&
      !isOtherInventoryWithMatchingDealer
    ) {
      return RESERVE_ASSET
    }
    if (isVehicleSelectedAndOrderSaved) {
      return submission_tier ? UPDATE_DEAL : CONTINUE_DEAL
    }
    return SAVE_AND_CONTINUE
  }

  const shouldShowPayment = () => {
    const isOtherInventoryWithMatchingDealer =
      getReservation?.reservation?.applicable_to === InventoryType.OtherInventory &&
      isDealerCodeMatch

    return (
      getReservation?.reservation?.applicable_to !== null &&
      getReservation?.reservation?.applicable_to !== undefined &&
      getReservation?.reservation?.reservation_amount &&
      isVehicleSelectedAndOrderSaved &&
      !state.skipReservation &&
      !state.proceededWithoutPayment &&
      !state?.order?.order_payments &&
      !submission_tier &&
      !isOtherInventoryWithMatchingDealer
    )
  }

  const isReserveAssetButtonEnabled = () => {
    const isOtherInventoryWithMatchingDealer =
      getReservation?.reservation?.applicable_to === InventoryType.OtherInventory &&
      isDealerCodeMatch

    return (
      isVehicleSelectedAndOrderSaved &&
      getReservation?.reservation?.applicable_to !== null &&
      getReservation?.reservation?.applicable_to !== undefined &&
      !state.skipReservation &&
      !state.proceededWithoutPayment &&
      !isOtherInventoryWithMatchingDealer
    )
  }

  const handlePrimaryButtonClick = async () => {
    const orderReqObj = createOrderRequestPayloadFromState(state, dealerInfo, lender_id)

    if (finance_type === FinanceTypes.CASH) {
      if (getPrimaryButtonText() === RESERVE_ASSET) {
        setShowPayment(true)
        !order?.reference_number && handleCashTypeOrderFlow()
        return
      }
      const existingOrderRef = state?.order?.reference_number
      if (existingOrderRef) {
        const order_stage = state.vehiclesForComparison == VehiclesForComparisonTypes.MULTIPLE ? OrderStage.QUOTATION : OrderStage.APPLICATION
        updateOrderRequest(
          { ...orderReqObj, order_stage, reference_number: existingOrderRef },
          {
            onSuccess(response: { reference_number: string }) {
              const params = new URLSearchParams()
              params.set('reference_id', response?.reference_number ?? existingOrderRef)

              if ('reference_id' in customer) {
                params.set('customer_reference_id', customer.reference_id ?? '')
              }

              setSearchParams(params)
              dispatch({
                type: DeskingActionTypes.VEHICLE_SELECTED_AND_QUOTATION_SAVED,
                payload: true
              })
              getOrderInformation(
                { reference_number: response?.reference_number ?? existingOrderRef },
                {
                  onSuccess: (orderInfo: any) => {
                    dispatch({
                      type: DeskingActionTypes.MAP_ORDER_TO_STORE,
                      payload: orderInfo
                    })
                    queryClient.invalidateQueries(['Order', existingOrderRef])
                    navigate(`/lane/desking?${params.toString()}`)
                  }
                }
              )
            }
          }
        )
        return
      }

      if (state.proceededWithoutPayment && !existingOrderRef) {
        if (orderReferenceId) {
          const order_stage = state.vehiclesForComparison == VehiclesForComparisonTypes.MULTIPLE ? OrderStage.QUOTATION : OrderStage.APPLICATION
          await updateOrderRequest(
            { ...orderReqObj, order_stage },
            {
              onSuccess(response: { reference_number: string }) {
                const params = new URLSearchParams()
                params.set('reference_id', response?.reference_number ?? orderReferenceId)

                if ('reference_id' in customer) {
                  params.set('customer_reference_id', customer.reference_id ?? '')
                }

                setSearchParams(params)
                navigate(`/lane/desking?${params.toString()}`)
                dispatch({
                  type: DeskingActionTypes.VEHICLE_SELECTED_AND_QUOTATION_SAVED,
                  payload: true
                })
              }
            }
          )
        } else {
          saveOrderRequest([orderReqObj], {
            onSuccess(response: { reference_number: string }[]) {
              const params = new URLSearchParams()
              params.set('reference_id', response[0]?.reference_number ?? '')

              if ('reference_id' in customer) {
                params.set('customer_reference_id', customer.reference_id ?? '')
              }

              setSearchParams(params)
              navigate(`/lane/desking?${params.toString()}`)
              dispatch({
                type: DeskingActionTypes.VEHICLE_SELECTED_AND_QUOTATION_SAVED,
                payload: true
              })
            }
          })
        }
        return
      }
    }

    if (shouldShowPayment()) {
      setShowPayment(true)
      return
    }

    if (orderReferenceId && (isVehicleSelectedAndOrderSaved || state.order?.reference_number)) {
      const payload = state.isVehicleSelectedAndOrderSaved ? {
        ...orderReqObj, order_stage: OrderStage.APPLICATION
      } : { ...orderReqObj }
      updateOrderRequest(payload, {
        onSuccess(response: { reference_number: string }) {
          dispatch({
            type: DeskingActionTypes.SAVE_ORDER,
            payload: false
          })
          setTimeout(() => {
            const params = new URLSearchParams()
            params.set('reference_id', response?.reference_number ?? orderReferenceId)

            if ('reference_id' in customer) {
              params.set('customer_reference_id', customer.reference_id ?? '')
            }

            setSearchParams(params)
            if (submission_tier) {
              updateorderStatus()
              return
            }
            if (state.order?.status === Status.PendingConfirmation) {
              navigate(`/lane/deal-summary/${orderReferenceId}`)
            } else {
              navigate(`/lane/desking?${params.toString()}`)
            }
            dispatch({
              type: DeskingActionTypes.VEHICLE_SELECTED_AND_QUOTATION_SAVED,
              payload: true
            })
          }, 0)
        }
      })
    } else {
      const eotFees = dealerFees[finance_type]
        ?.filter((fee: IDealerFee) => fee.is_active && fee.event === FeeEvent.ENDOFTERM)
        ?.map((fee: IDealerFee) => {
          if (fee.calculation_type === FEE_MONTHLY_PAYMENT_BASED) {
            const multipliedValue =
              state?.selection?.calculations?.monthly_payment * fee.multiplication_factor
            if (multipliedValue < (fee?.applied_price ?? fee.default_amount)) {
              fee.applied_price = multipliedValue
            }
          }
          return {
            dealer_fee_id: fee.id,
            fee_name: fee.fee_name,
            applied_price: fee?.applied_price ?? fee.default_amount,
            fee_handling: fee?.fee_handling,
            is_active: fee?.is_active,
            applicable_finance_type: fee?.applicable_finance_type,
            is_taxable: true,
            vendor: fee?.vendor,
            fee_type: fee?.event
          }
        })
      if (eotFees?.length > 0) {
        orderReqObj.order_fees = [...orderReqObj.order_fees, ...eotFees]
      }

      saveOrderRequest([orderReqObj], {
        onSuccess(response: { reference_number: string }[]) {
          const params = new URLSearchParams()
          params.set('reference_id', response[0]?.reference_number ?? '')
          if ('reference_id' in customer) {
            params.set('customer_reference_id', (customer as ICustomer).reference_id ?? '')
          }
          setSearchParams(params)
          navigate(`/lane/desking?${params.toString()}`)
          dispatch({
            type: DeskingActionTypes.VEHICLE_SELECTED_AND_QUOTATION_SAVED,
            payload: true
          })
        }
      })
    }
  }

  const hasValidationError = () => {
    if (
      state.isVehicleSelectedAndOrderSaved &&
      (state.preview?.validation_errors?.down_payment || state.preview?.validation_errors?.rv)
    ) {
      return true
    }

    let currentCalculations = state[state.vehiclesForComparison][finance_type]
    if (state.vehiclesForComparison === VehiclesForComparisonTypes.MULTIPLE) {
      currentCalculations = currentCalculations?.reduce((acc: any, item: any) => {
        if (item?.vin !== selection?.vin) return acc
        acc.down_payment.push(...(item?.validation_errors?.down_payment ?? []))
        acc.rv.push(...(item?.validation_errors?.rv ?? []))
        return acc
      }, { down_payment: [], rv: [] })
    } else {
      currentCalculations = currentCalculations?.validation_errors
    }

    if (
      currentCalculations &&
      [...(currentCalculations?.down_payment ?? []), ...(currentCalculations?.rv ?? [])]?.some(
        (item: boolean) => item === true
      )
    ) {
      return true
    }

    return false
  }

  const hideVehicleNotAvailableHandler = () => {
    setShowVehicleNotAvailable(false)
    navigate(APP_ROUTES.DESKING)
  }

  const isDisabled =
    (isContinueDealDisabled() && !isReserveAssetButtonEnabled() && state.hasUnsavedOrderChanges) ||
    !canUserEdit ||
    updateQuotationLoading ||
    isUpdateDealDisabled() ||
    !isVehicleAndCustomerAdded ||
    !isBoxSelected ||
    hasValidationError() ||
    saveRequestLodaing ||
    orderInformationLoading ||
    (!vehicle && vin)
  
  const handleRemoveFAndI = async () => {
    const orderReqObj = createOrderRequestPayloadFromState(state, dealerInfo, lender_id)
    try {
      if ((lastOrderState as any)?.order_fnI?.length || (lastOrderState as any)?.order_rebates?.length) {
        await updateOrderRequest({
          ...orderReqObj,
          order_fnI: [],
          order_rebates: null
        })

        dispatch({
          type: DeskingActionTypes.SAVE_ORDER,
          payload: false
        })

        await queryClient.fetchQuery(['Order', orderReferenceId])
      }
      queryClient.invalidateQueries(QueryKeys.EP_GET_REBATES)
    } catch (error) {
    } finally {
      const vin = isVehicleSelectedAndOrderSaved ? preview.vin : vehiclesVins[0]
      const { dealer_options, fees } = vehiclesData?.get(vin) ?? {}
      setShowFAndIRemove(false)
      dispatch({
        type: DeskingActionTypes.UPDATE_VEHICLES_FOR_COMPARISON,
        payload: VehiclesForComparisonTypes.SINGLE
      })
      dispatch({
        type: DeskingActionTypes.UPDATE_OPTIONS_FEES_FNI,
        payload: {
          vinUOFF: vin,
          fni: { finance: [], lease: [] } as any,
          fees,
          dealer_options,
          rebates: []
        }
      })
    }
  }

  const handleIsShowFAndIRemove = () => {
    const { fni, rebates } = vehiclesData?.get(vin) ?? {}

    const allowSwitching = (finance_type === FinanceTypes.CASH ||
      ((!fni?.[finance_type?.toLowerCase()]?.length && !(lastOrderState as any)?.order_fnI?.length) &&
        ((!rebates?.length) && !(lastOrderState as any)?.order_rebates?.length))
    )

    if (allowSwitching) {
      dispatch({
        type: DeskingActionTypes.UPDATE_VEHICLES_FOR_COMPARISON,
        payload: VehiclesForComparisonTypes.SINGLE
      })
      return
    }
    setShowFAndIRemove(true)
  }
  const handleSave = async () => {
    const orderReqObj = createOrderRequestPayloadFromState(state, dealerInfo, lender_id)
    if (state.isVehicleSelectedAndOrderSaved) {
      if (orderReqObj.finance_type === FinanceTypes.CASH) {
        await updateOrderRequest({
          ...orderReqObj,
          order_stage: OrderStage.QUOTATION
        })
      } else {
        await updateOrderRequest({
          ...orderReqObj,
          skipInvalidation: true
        })
      }
      dispatch({
        type: DeskingActionTypes.SAVE_ORDER,
        payload: false
      })
      dispatch({
        type: DeskingActionTypes.UPDATE_VEHICLES_FOR_COMPARISON,
        payload: VehiclesForComparisonTypes.SINGLE
      })
    } else if (orderReqObj.finance_type === FinanceTypes.CASH) {
      saveOrderRequest([{
        ...orderReqObj,
        order_stage: OrderStage.QUOTATION
      }], {
        onSuccess(_response: { reference_number: string }[]) {
          if (nextLocation) {
            navigate(nextLocation, { replace: true })
          }
        }
      })
    } else {
      saveOrderRequest([orderReqObj], {
        onSuccess(_response: { reference_number: string }[]) {
          if (nextLocation) {
            navigate(nextLocation, { replace: true })
          }
        }
      })
    }
  }
  
  const [
    { showDialog, setShowDialog, nextLocation },
    { handleSaveAndLeave, handleCancel }
  ] = usePreventNavigation({
    isDirty: state.hasUnsavedOrderChanges && !isDisabled,
    onSave: handleSave
  })

  const handleDiscardChanges = useCallback(() => {
    setShowDialog(false)
    if (nextLocation) {
      navigate(nextLocation, { replace: true })
    }
  }, [navigate, nextLocation])

  return (
    <Box theme={theme} className="fl-header-wrap">
      <Grid theme={theme} container columnSpacing={1}>
        <Grid theme={theme} item {...(state.vehiclesForComparison === VehiclesForComparisonTypes.MULTIPLE ? { xl: 4, lg: 4 } : { xl: 3, lg: 3.5 })} md={4} sm={4} xs={4} pt={0.5}>
          {vehiclesForComparison === VehiclesForComparisonTypes.SINGLE ? (
            <FLButtons />
          ) : state.isVehicleSelectedAndOrderSaved
            ? <BtnTabs theme={theme} className="btn-tabs">
              <FinanceLeaseButton
                financeType={state?.preview?.finance_type}
                isSelected
                text={state?.preview?.finance_type}
              />
            </BtnTabs>
            : null
          }
        </Grid>
        <Grid theme={theme} item {...(state.vehiclesForComparison === VehiclesForComparisonTypes.MULTIPLE ? { xl: 4, lg: 4 } : { xl: 3, lg: 3.5 })} md={4} sm={4} xs={4} pt={0.5}>
          {!submission_tier && (
            <Box theme={theme} display={'flex'} justifyContent={'end'} alignItems={'center'} gap={1}>
              <Typography theme={theme} variant='subtitle2' component={'label'} children={state.vehiclesForComparison == VehiclesForComparisonTypes.MULTIPLE ? COMPARISON_DESK : DEAL_DESK} />
              <BtnTabs theme={theme} className="btn-tabs rounded">
                <Button
                  theme={theme}
                  type="button"
                  className={
                    vehiclesForComparison === VehiclesForComparisonTypes.SINGLE ? 'selected' : ''
                  }
                  iconText={<Icon name="GridView" />}
                  defaultBtn
                  onClick={handleIsShowFAndIRemove}
                />
                <Button
                  theme={theme}
                  type="button"
                  className={
                    vehiclesForComparison === VehiclesForComparisonTypes.MULTIPLE ? 'selected' : ''
                  }
                  iconText={<Icon name="ListView" />}
                  defaultBtn
                  onClick={() => {
                    dispatch({
                      type: DeskingActionTypes.UPDATE_VEHICLES_FOR_COMPARISON,
                      payload: VehiclesForComparisonTypes.MULTIPLE
                    })
                  }}
                />
              </BtnTabs>
            </Box>
          )}
        </Grid>
        <Grid theme={theme} item {...(state.vehiclesForComparison === VehiclesForComparisonTypes.MULTIPLE ? { xl: 4, lg: 4 } : { xl: 6, lg: 5 })} md={4} sm={4} xs={4}>
          <Box theme={theme} className="fl-action-btn">
            {(isVehicleSelectedAndOrderSaved && finance_type !== FinanceTypes.CASH) && !submission_tier && (
              <Button
                theme={theme}
                secondary
                size="small"
                text="Save"
                disabled={!canUserEdit || hasValidationError()}
                onClick={() => {
                  const orderReqObj = createOrderRequestPayloadFromState(
                    state,
                    dealerInfo,
                    lender_id
                  )
                  updateOrderRequest(orderReqObj, {
                    onSuccess(_response: any) {
                      dispatch({
                        type: DeskingActionTypes.SAVE_ORDER,
                        payload: false
                      })
                    }
                  })
                }}
              />
            )}
            {(isVehicleSelectedAndOrderSaved && finance_type !== FinanceTypes.CASH) ? (
              <Button
                theme={theme}
                secondary
                disabled={sendCustomerOrderLinkLoading || !canUserEdit || hasValidationError()}
                size="small"
                text="Send To Customer"
                onClick={onSendOrderLinkToCustomer}
              />
            ) : null}
            <Button
              theme={theme}
              primary
              size="small"
              text={getPrimaryButtonText()}
              disabled={isDisabled}
              onClick={handlePrimaryButtonClick}
            />
          </Box>
        </Grid>
      </Grid>
      {!!showFAndIRemove && (
        <ConfirmationDialog
          openPopUp={showFAndIRemove}
          confirmationTitle={DialogMessages.fniAndMspRemoveTitle}
          confirmationText={DialogMessages.fniAndMspRemoveSubTitle}
          primaryBtnText={DialogMessages.fniAndMspRemoveBtnText}
          icon={<Icon className="alert-icon" name="DialogAlertIcon" />}
          hideCancelButton={false}
          setPopUpState={() => { }}
          isPrimaryButtonDisabled={updateQuotationLoading || _orderFetching}
          onConfirm={handleRemoveFAndI}
          onCancel={() => setShowFAndIRemove(false)}
        />
      )}
      {(showPayment && order?.reference_number) && <ReservationDialog onClose={handleClosePayment} />}
      <ConfirmationDialog
        openPopUp={showVehicleNotAvailable}
        confirmationTitle={DialogMessages.assetUnavailableDialogueTitle}
        confirmationText={DialogMessages.assetUnavailableDialogueSubTitle}
        primaryBtnText={DialogMessages.assetUnavailableDialogueBtnText}
        icon={<Icon name="DialogCrossIcon" />}
        hideCancelButton={true}
        setPopUpState={setShowVehicleNotAvailable}
        onConfirm={hideVehicleNotAvailableHandler}
        onCancel={hideVehicleNotAvailableHandler}
      />
      <ConfirmationDialog
        openPopUp={showDialog}
        confirmationTitle={DialogMessages.saveChanges}
        confirmationText={DialogMessages.unsaveChanges}
        primaryBtnText="Save"
        secondaryBtnText="Discard"
        icon={<Icon className="alert-icon" name="DialogAlertIcon" />}
        hideCancelButton={false}
        setPopUpState={setShowDialog}
        isPrimaryButtonDisabled={updateQuotationLoading}
        onConfirm={handleSaveAndLeave}
        onSecondaryBtnClick={handleDiscardChanges}
        onCancel={handleCancel}
      />
    </Box>
  )
}
