import { FC, Fragment, Key, memo, useEffect, useMemo, useState } from 'react'
import { useTheme } from '@mui/material'
import { Box, Button, Dialog, DuoTab, Icon, Typography } from '@ntpkunity/controls'
import { BtnAddWrap, DuoTabWrap, VehicleList } from '@components'
import EditFees from 'controls/fees/edit-fees'
import AddOnOption from 'controls/addon-control/addon'
import { DeskingActionTypes, useDeskingContext, VehiclesForComparisonTypes } from './desking-context-provider'
import AddOnPopup from 'controls/addon-control/add-on-popup'
import { useModal } from 'react-modal-hook'
import { useSearchParams } from 'react-router-dom'
import FinanceInsuranceControl from 'controls/FinanceInsuranceControl/finance-insurance-control'
import { useStoreContext } from '@store/storeContext'
import {
  IntegrationProvider,
  LenderIntegrationType,
  TabName
} from '@helpers/enums'
import { IExtrasPopupProps, IRebate, IStipulationResponse } from '@models'
import { StipCodes } from '@helpers/enums/stipulation-type.enum'
import { CustomTheme } from '@styles/customTheme'
import { useEditPermissionHook } from '@hooks/useEditPermissionHook'
import { useGetIntegrationByProviderName } from '@apis/customer-detail.service'
import { Rebates } from './add-rebates'
import { FinanceType } from '@helpers/enums/finance-type.enum'
import { Finance_Type } from 'controls/FinanceInsuranceControl/finance-insurance-types'

export const Tabs = {
  [TabName.FNI]: 0,
  [TabName.OPTIONS]: 1,
  [TabName.FEES]: 2,
  [TabName.MSP]: 3,
  [TabName.REBATES]: 4
}

export const ExtrasPopup: FC<Partial<IExtrasPopupProps>> = ({
  vin,
  vehiclesData,
  finance_type,
  tabName,
  data,
  showFni,
  showMsp,
  selection,
  hide,
  saveExtrasData,
  deskingState
}) => {
  const theme = useTheme()
  const { mutate: getPenIntegrations } = useGetIntegrationByProviderName(
    IntegrationProvider.PEN,
    LenderIntegrationType.FNI_PRODUCTS
  )

  const { dealer_options = [], fees = {}, fni = { finance: [], lease: [], cash: [] }, rebates = [] } = data || {}

  const {
    states: { dealDesk, dealerInfo }
  } = useStoreContext()

  const [extrasData, setExtrasData] = useState({
    dealer_options,
    fees,
    fni,
    rebates
  })
  const [fniData, setFniData] = useState(fni)
  const [addedItems, setAddedItems] = useState<any>([])
  const [fAndIPenEnabled, setFAndIPenEnabled] = useState<boolean>(false)
  const [openAddOnDialog, setOpenAddOnDialog] = useState<boolean>(false)
  const [newRebates, setNewRebates] = useState<IRebate[]>([])
  const [selectedRebates, setSelectedRebates] = useState<IRebate[]>(rebates)
  const [totalSumArray, setTotalSumArray] = useState<any>([])
  const [currentTab, setCurrentTab] = useState<{ tabTitle: string, tabIndex: number }>({
    tabTitle: '',
    tabIndex: 0
  })

  useEffect(() => {
    getPenIntegrations(
      {
        provider_name: IntegrationProvider.PEN,
        integration_type: LenderIntegrationType.FNI_PRODUCTS
      },
      {
        onSuccess(response: any) {
          setFAndIPenEnabled(response?.is_active)
        }
      }
    )
  }, [])

  const updateExtrasData = (type: 'dealer_options' | 'fees', newData: any) => {
    setExtrasData((prev) => ({ ...prev, [type]: newData }))
  }

  const defaultCurrency = (dealDesk?.defaultCurrency as { symbol: string })?.symbol
  const defaultDealerTerms = dealDesk?.contractTerms?.find(
    (item) => item.id === dealDesk?.dealerPreferences?.default_term_id
  )

  const quote_params = selection?.quote_params || selection?.quoteParams
  const contract_term = quote_params?.contract_term
  const apr = deskingState.vehiclesForComparison == VehiclesForComparisonTypes.MULTIPLE ? quote_params?.apr : (vehiclesData?.get(vin as string)?.program ?? {})?.[finance_type as string]?.find(
    (p: any) => p?.contract_term === (contract_term ?? defaultDealerTerms?.term)
  )?.final_customer_rate

  const preInstalledDealerOptions =
    vehiclesData?.get(vin as string)?.preInstalledDealerOptions ?? []

  const compatibleModels = (vehiclesData?.get(vin as string)?.vehicle?.model ?? '')
  const handleNewAddOn = () => {
    setOpenAddOnDialog(true)
  }
  const tabItems = [
    ...(showFni
      ? [
        {
          title: TabName.FNI,
          content: TabName.FNI == currentTab.tabTitle && (
            <FinanceInsuranceControl
              dealerProfileData={dealDesk?.dealerProfile}
              company_id={dealerInfo?.company_id}
              defaultCurrency={defaultCurrency}
              isMSP={false}
              fniData={fniData}
              fni={fni}
              setFniData={setFniData}
              terms={contract_term}
              apr={apr}
              selectedVehicle={vehiclesData?.get(vin as string)?.vehicle}
              fAndIPenEnabled={fAndIPenEnabled}
              deskingState={deskingState}
              finance_type={finance_type as Finance_Type}
            />
          )
        }
      ]
      : []),
    {
      title: TabName.OPTIONS,
      content: TabName.OPTIONS == currentTab.tabTitle && (
        <AddOnOption
          defaultOptions={dealer_options}
          setTotalSumArray={setTotalSumArray}
          totalSumArray={totalSumArray}
          preInstalledDealerOptions={preInstalledDealerOptions}
          defaultCurrency={defaultCurrency}
          modelName={vehiclesData?.get(vin as string)?.vehicle?.model}
          setSelectedObjectsArray={(newOptions: any) =>
            updateExtrasData('dealer_options', newOptions)
          }
          vin={vin ?? ''}
          setAddedItems={setAddedItems}
          addedItems={addedItems}
        />
      )
    },
    {
      title: TabName.FEES,
      content: TabName.FEES == currentTab.tabTitle && (
        <EditFees
          dealerFees={extrasData?.fees}
          financeType={finance_type ?? 'Finance'}
          defaultCurrency={defaultCurrency}
          setDealerFees={(newFees: any) => updateExtrasData('fees', newFees)}
          vin={vin ?? ''}
        />
      )
    },
    ...(showMsp
      ? [
        {
          title: TabName.MSP,
          content: TabName.MSP == currentTab.tabTitle && (
            <FinanceInsuranceControl
              dealerProfileData={dealDesk?.dealerProfile}
              company_id={dealerInfo?.company_id}
              defaultCurrency={defaultCurrency}
              isMSP={true}
              fniData={fniData}
              fni={fni}
              setFniData={setFniData}
              terms={contract_term}
              apr={apr}
              selectedVehicle={vehiclesData?.get(vin as string)?.vehicle}
              fAndIPenEnabled={fAndIPenEnabled}
              deskingState={deskingState}
              finance_type={finance_type as Finance_Type}
            />
          )
        }
      ]
      : []),
    ...((deskingState?.isVehicleSelectedAndOrderSaved || finance_type?.toLowerCase() === FinanceType.CASH) ? [{
      title: TabName.REBATES,
      content: TabName.REBATES == currentTab.tabTitle && (
        <Rebates
          defaultCurrency={defaultCurrency}
          rebates={newRebates}
          selectedRebates={selectedRebates}
          deskingState={deskingState}
          setRebates={setNewRebates}
          setSelectedRebates={setSelectedRebates}
          vin={vin ?? ''}
          finance_type={finance_type as string}
        />
      )
    }] : [])
  ]

  const tabIndexMap = tabItems.reduce<Record<string, number>>((acc, item, index) => {
    acc[item.title] = index
    return acc
  }, {})

  useEffect(() => {
    if (tabName) setCurrentTab({ tabIndex: tabIndexMap[tabName], tabTitle: tabName })
  }, [tabName])

  const isAddons = useMemo(() => {
    return !!Object.keys(tabIndexMap)?.find(key => (currentTab.tabIndex === tabIndexMap[key] && key === TabName.OPTIONS))
  }, [currentTab.tabIndex])

  return (
    <CustomTheme key={'ExtrasPopup'} theme={theme} className="custom-theme">
      <Dialog
        theme={theme}
        variant={undefined}
        size="xl"
        title="Add Products"
        open={true}
        onCloseDialog={hide}
        disablePortal={true}
        customFooter={
          <>
            <Box theme={theme} display='flex' justifyContent='center' gap='16px'>
              {isAddons && <Button theme={theme} secondary text="Create New Add-On" onClick={handleNewAddOn} />}
              <Button
                theme={theme}
                primary
                text="Continue"
                onClick={() => {
                  saveExtrasData?.({ ...extrasData, rebates: selectedRebates, fni: fniData })
                }}
              />
            </Box>
          </>
        }
      >
        <DuoTabWrap theme={theme} className="duo-tab-wrap align-center sm fixed-child-tab-height">
          <DuoTab
            theme={theme}
            key={currentTab.tabIndex}
            defaultTabIndex={currentTab.tabIndex}
            items={tabItems}
            value={currentTab.tabIndex}
            onChange={(_key, index) => setCurrentTab({ tabIndex: Number(index ?? 0), tabTitle: tabItems[Number(index ?? 0)].title })}
          />
        </DuoTabWrap>
      </Dialog>
      {openAddOnDialog && (
        <>
          <AddOnPopup
            defaultOptions={dealer_options}
            setTotalSumArray={setTotalSumArray}
            totalSumArray={totalSumArray}
            dealerInfo={dealerInfo}
            compatibleModels={compatibleModels}
            defaultCurrency={defaultCurrency}
            setOpenAddOnDialog={setOpenAddOnDialog}
            setAddedItems={setAddedItems}
            addedItems={addedItems}
            setSelectedObjectsArray={(newOptions: any) =>
              updateExtrasData('dealer_options', newOptions)
            }
          />
        </>
      )}
    </CustomTheme>

  )
}

export const useExtrasModal = () => {
  const [params, setParams] = useState<Omit<IExtrasPopupProps, 'hide'> | null>(null)
  const {
    vin,
    vehiclesData,
    data,
    finance_type,
    tabName,
    selection,
    showFni,
    showMsp,
    saveExtrasData,
    deskingState
  } = params ?? {}

  const [showPopup, hidePopup] = useModal(
    () => (
      <ExtrasPopup
        vin={vin}
        vehiclesData={vehiclesData}
        data={data}
        showFni={showFni}
        showMsp={showMsp}
        finance_type={finance_type}
        selection={selection}
        tabName={tabName ?? TabName.OPTIONS}
        hide={hideExtrasPopup}
        saveExtrasData={saveExtrasData}
        deskingState={deskingState}
      />
    ),
    [
      vin,
      vehiclesData,
      data,
      finance_type,
      tabName,
      selection,
      showFni,
      showMsp,
      saveExtrasData
    ]
  )

  const showExtrasPopup = (params: Omit<IExtrasPopupProps, 'hide'>) => {
    setParams(params)
    showPopup()
  }

  const hideExtrasPopup = () => {
    hidePopup()
    setParams(null)
  }

  return { showExtrasPopup, hideExtrasPopup }
}

interface VlItemProps {
  data: any
  nameKey: string
  priceKey: string
  secondaryPriceKey: string
}

const VlItem: FC<VlItemProps> = ({ data, nameKey, priceKey, secondaryPriceKey }) => {
  const theme = useTheme()
  const { formatCurrency } = useDeskingContext()
  const image = data?.image?.[0]?.location ?? data?.rebate_images?.[0]?.location

  return (
    <Fragment key={'VlItem'}>
      {nameKey !== 'fee_name' ? (
        <Box theme={theme} className="vl-img-wrap">
          <img
            src={image ?? require('../../../src/public/assets/images/no-img.svg')}
            alt="Car"
          />
        </Box>
      ) : null}
      <Box theme={theme} className="vl-details">
        <Typography
          theme={theme}
          variant="caption"
          component="p"
          className=" text-overflow vl-title-ul"
          children={data?.[nameKey]}
        />
        <Typography
          theme={theme}
          variant="caption"
          component="p"
          className=" text-overflow vl-title-ul"
          children={
            <b>
              {formatCurrency(data?.is_price_inclusive ? 0 : (data?.[priceKey] || data?.[priceKey] == 0) ? data?.[priceKey] || 0 : data?.[secondaryPriceKey] || 0)}
            </b>
          }
        />
      </Box>
    </Fragment>
  )
}

const AddOpptions: FC<{ tabName: keyof typeof Tabs; showFni: boolean; showMsp: boolean }> = ({
  tabName,
  showFni,
  showMsp
}) => {
  const theme = useTheme()
  const {
    state,
    state: {
      vehiclesData,
      vehiclesVins,
      finance_type,
      isVehicleSelectedAndOrderSaved,
      preview,
      submission_tier,
      stipulationData: stipulations,
      selection,
      order
    },
    dispatch
  } = useDeskingContext()
  const [searchParams] = useSearchParams()
  const orderReferenceId = searchParams.get('reference_id')

  const vin = isVehicleSelectedAndOrderSaved ? preview.vin : vehiclesVins[0]
  const { dealer_options, fees, fni, rebates } = vehiclesData?.get(vin) ?? {}

  const { showExtrasPopup, hideExtrasPopup } = useExtrasModal()

  const canUserEdit = useEditPermissionHook()

  const handleExtrasData = (data: any) => {
    dispatch({
      type: DeskingActionTypes.UPDATE_OPTIONS_FEES_FNI,
      payload: {
        vinUOFF: vin,
        // fni: [],
        ...data
      }
    })
    hideExtrasPopup()
  }

  const isTerms: boolean = stipulations?.some(
    (item: IStipulationResponse) => item?.stipulation_code === StipCodes.TERMS
  )

  const renderItems = () => {
    let dataList = []
    let nameKey = ''
    let priceKey = ''
    let secondaryPriceKey = ''

    const getFnIData = (isMSP: boolean) => {
      return (fni && fni[finance_type?.toLowerCase()]?.length) ? fni[finance_type?.toLowerCase()]?.filter(
        (fniItem: any) =>
          fniItem.MSP === isMSP ||
          fniItem.is_msp === isMSP ||
          ((fniItem.MSP || fniItem.is_msp) && !isMSP && fniItem?.bundle_id)
      ) : []
    }

    const hasAppliedPriceKey = fni?.[finance_type.toLowerCase()]?.length > 0 &&
      fni?.[finance_type.toLowerCase()]?.every((item: any) => item?.applied_price !== undefined)

    switch (tabName) {
      case TabName.OPTIONS:
        dataList = dealer_options || []
        nameKey = 'product_name'
        priceKey = (orderReferenceId || order?.id) ? 'applied_price' : 'price'
        secondaryPriceKey = 'price'
        break
      case TabName.FEES:
        dataList = fees?.[finance_type] || []
        nameKey = 'fee_name'
        priceKey = (orderReferenceId || order?.id) ? 'applied_price' : 'default_amount'
        secondaryPriceKey = 'default_amount'
        break
      case TabName.FNI:
        dataList = getFnIData(false)
        nameKey = 'product_name'
        priceKey = hasAppliedPriceKey ? 'applied_price' : 'price'
        secondaryPriceKey = 'price'
        break
      case TabName.MSP:
        dataList = getFnIData(true)
        nameKey = 'product_name'
        priceKey = hasAppliedPriceKey ? 'applied_price' : 'price'
        secondaryPriceKey = 'price'
        break
      case TabName.REBATES:
        dataList = rebates || []
        nameKey = 'rebate_name'
        priceKey = 'rebate_amount'
        break
      default:
        return null
    }
    return dataList.map((value: any, colIndex: Key | null | undefined) => (
      <Box theme={theme} className="vl-item sm item-li" mb={1} key={`VlItem${colIndex}`}>
        <VlItem data={value} nameKey={nameKey} priceKey={priceKey} secondaryPriceKey={secondaryPriceKey} />
      </Box>
    ))
  }

  return (
    <Fragment key={'AddOpptions'}>
      <BtnAddWrap theme={theme} className="btn-add-wrap">
        <Button
          theme={theme}
          fullWidth
          startIcon={<Icon name="AddCircleIcon" />}
          disabled={
            !vin ||
            !Object.keys(vehiclesData.get(vin)?.vehicle?.vin ?? {}).length ||
            Boolean(submission_tier) ||
            isTerms ||
            !canUserEdit
          }
          text={<span className='text-overflow'>Add {tabName}</span>}
          title={'Add ' + tabName}
          size="medium"
          className='btn-add'
          onClick={() => {
            showExtrasPopup({
              vin,
              vehiclesData,
              finance_type,
              tabName,
              showFni,
              showMsp,
              selection,
              data: vehiclesData?.get(vin) ?? {},
              deskingState: state,
              saveExtrasData: handleExtrasData
            })
          }}
        />
      </BtnAddWrap>
      <VehicleList theme={theme} className="vehicle-list" mt={1}>
        <Box theme={theme} className="vl-scroll">
          {renderItems()}
        </Box>
      </VehicleList>
    </Fragment>
  )
}

export default memo(AddOpptions)
