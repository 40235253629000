import { FC, useEffect, useMemo, useState } from 'react'
import { useTheme } from '@mui/material'
import { Grid, Icon, Typography } from '@ntpkunity/controls'
import CalculationGrid from './finance-lease-calculator'
import PaymentDetails from './payment-details'
import { FinanceLeaseHeader } from './finance-lease-header'
import MultipleVehicleComparison from './product-list-view'
import { DeskingActionTypes, FinanceTypes, useDeskingContext, VehiclesForComparisonTypes } from './desking-context-provider'
import DealPreviewComponent from './deal-preview-component'
import { ConfirmationDialog, StatusWrap } from '@components'
import { useGetReservation } from '@apis/configurations.service'
import { useStoreContext } from '@store/storeContext'
import { InventoryType } from '@helpers/enums/status.enum'
import { RESERVATION_TYPE_PERCENTAGE_MSRP } from '@helpers/constants/constants'
import CashPaymentDetails from './cash-payment-details'
import { DialogMessages } from '@helpers/enums'
import { usePreventNavigation } from '@hooks/usePreventNavigation'
import { useForm } from 'react-hook-form'
import { createOrderRequestPayloadFromState } from './utils'
import { useUpdateQuotationById } from '@apis/order-management.service'
import { useLocation } from 'react-router-dom'
import { APP_ROUTES } from '@helpers/links'

const CalculationSection: FC = () => {
  const theme = useTheme()
  const { state, dispatch } = useDeskingContext()
  const location = useLocation()
  const isDealStipulationScreen = location?.pathname?.startsWith(APP_ROUTES.ORDER_STIPULATIONS || '')

  const {
    state: { finance_type, hasUnsavedOrderChanges }
  } = useDeskingContext()
  const { mutateAsync: updateOrderRequest } = useUpdateQuotationById()
  const { getValues } = useForm()

  const [isDirty, setIsDirty] = useState(false)
  const isCash = useMemo(() => finance_type === FinanceTypes.CASH, [finance_type])

  const { vehiclesForComparison, isVehicleSelectedAndOrderSaved } = state
  const { states } = useStoreContext()
  const { data: getReservation, isLoading: isReservationLoading } = useGetReservation(
    states?.dealerInfo?.dealer_code
  )

  const getVehicleVin = () => {
    let vin = state?.vehiclesVins?.[0]
    if (
      vehiclesForComparison === VehiclesForComparisonTypes.MULTIPLE &&
      Object.keys(state?.selection)?.length
    ) vin = state?.selection.vin
    return vin
  }

  const dealerCode = states?.dealerInfo?.dealer_code
  const { vehiclesData } = state
  const vin = getVehicleVin()
  const vehicleDealerCode = vehiclesData?.get(vin)?.vehicle?.dealer_code
  const isDealerCodeMatch = vehicleDealerCode === dealerCode
  const areDealerCodesAvailable = !!dealerCode && !!vehicleDealerCode

  const showReservationAmount =
    !isReservationLoading &&
    getReservation?.reservation?.applicable_to &&
    areDealerCodesAvailable &&
    !(
      getReservation?.reservation?.applicable_to === InventoryType.OtherInventory &&
      isDealerCodeMatch
    )

  const getMsrp = () => {
    const { order, vehiclesData, isVehicleSelectedAndOrderSaved } = state
    let vin = getVehicleVin()

    if (isVehicleSelectedAndOrderSaved && order?.order_asset?.msrp) {
      return order.order_asset.msrp
    }

    if (vin && vehiclesData) {
      const vehicleData = vehiclesData.get(vin)
      return vehicleData?.vehicle?.msrp || 0
    }

    return
  }

  const calculateReservationAmount = () => {
    if (!getReservation?.reservation?.reservation_amount) return '0.00'

    if (getReservation?.reservation?.reservation_type === RESERVATION_TYPE_PERCENTAGE_MSRP) {
      const msrp = getMsrp() || 0
      const percentage = getReservation?.reservation?.reservation_amount || 0
      const amount = (msrp * percentage) / 100
      return amount.toFixed(2)
    }

    return getReservation?.reservation?.reservation_amount.toFixed(2)
  }

  const saveOrder = () => {
    const orderReqObj = createOrderRequestPayloadFromState(
      state,
      states.dealerInfo,
      state.lender_id
    )
    updateOrderRequest(orderReqObj, {
      onSuccess(_response: any) {
        dispatch({
          type: DeskingActionTypes.SAVE_ORDER,
          payload: false
        })
      }
    })
  }

  const onSubmit = async (_values: any) => {
    setIsDirty(false)
    saveOrder()
  }

  const handleDealPreviewChange = (isDealPreviewDirty: boolean) => {
    setIsDirty(isDealPreviewDirty)
  }

  useEffect(() => {
    setIsDirty(hasUnsavedOrderChanges && isDealStipulationScreen)
  }, [hasUnsavedOrderChanges])

  const [
    { showDialog, setShowDialog },
    { handleSaveAndLeave, handleCancel }
  ] = usePreventNavigation({
    isDirty,
    onSave: async () => {
      const values = getValues()
      await onSubmit(values)
    }
  })

  return (
    <>
      {(!state?.isStipulationScreen || state?.submission_tier) && <FinanceLeaseHeader />}
      {/******* Finance/Lease/Cash *******/}
      <Grid theme={theme} container columnSpacing={2} rowSpacing={2} >
        <Grid item theme={theme} {...(
          (state.vehiclesForComparison === VehiclesForComparisonTypes.MULTIPLE && !isCash) ? { xs: 9 } : (state.vehiclesForComparison === VehiclesForComparisonTypes.MULTIPLE && isCash) ? { xs: 9.5 } : isCash ? { xs: 9 } : { xl: 6, lg: 7, md: 12, sm: 12, xs: 12 }
        )}>
          {isVehicleSelectedAndOrderSaved
            ? <DealPreviewComponent onDirtyStateChange={handleDealPreviewChange} />
            : <>
              {
                vehiclesForComparison === VehiclesForComparisonTypes.SINGLE
                  ? <CalculationGrid />
                  : <MultipleVehicleComparison />
              }
            </>
          }
        </Grid>
        <Grid item theme={theme} {...(
          (state.vehiclesForComparison === VehiclesForComparisonTypes.MULTIPLE && !isCash) ? { xs: 3 } : (state.vehiclesForComparison === VehiclesForComparisonTypes.MULTIPLE && isCash) ? { xs: 2.5 } : isCash ? { xs: 3 } : { xl: 6, lg: 5, md: 12, sm: 12, xs: 12 }
        )}>
          {(showReservationAmount) && (
            <StatusWrap theme={theme} className="status-wrap reserve-status" mb={2}>
              <Typography
                theme={theme}
                component="small"
                variant="caption"
                className="w-100 d-block fw-600 status rejected"
              >
                Reservation Amount: ${calculateReservationAmount()}
              </Typography>
            </StatusWrap>
          )}
          {isCash ? <CashPaymentDetails /> : <PaymentDetails />}
        </Grid>
      </Grid>

      <ConfirmationDialog
        openPopUp={showDialog}
        confirmationTitle={DialogMessages.saveChanges}
        confirmationText={DialogMessages.unsaveChanges}
        primaryBtnText="Save"
        secondaryBtnText="Discard"
        icon={<Icon className="alert-icon" name="DialogAlertIcon" />}
        hideCancelButton={false}
        setPopUpState={setShowDialog}
        onConfirm={handleSaveAndLeave}
        onCancel={handleCancel}
      />
    </>
  )
}

export default CalculationSection
