import { FC, useState, useEffect } from 'react'
import { useTheme } from '@mui/material'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Icon,
  Input,
  Select,
  Tooltip,
  Typography
} from '@ntpkunity/controls'
import { useGetFnIProductRate } from '@apis/financial-insurance.service'
const FIPriceDialog: FC<{
  isSaveButtonDisabled?: boolean
  isOpen: any
  setIsOpen: any
  details: any
  defaultCurrency: any
  selectDataObj?: any
  dealerInfo?: any
  formattedData?: any
  editPopupCallback?: any
}> = ({
  isSaveButtonDisabled,
  isOpen,
  setIsOpen,
  details,
  defaultCurrency,
  selectDataObj,
  dealerInfo,
  formattedData,
  editPopupCallback
}) => {
  const theme = useTheme()
  const {
    mutate: getProductRates,
    data: productRates,
    isLoading: isLoadingGetFnIProductRate
  } = useGetFnIProductRate()
  const [coverageName, setCoverageName] = useState<string>(details?.coverage_name ?? '')
  const [selected, setSelected] = useState<string[]>(details.surcharges ?? [])
  const [coverageFormId, setCoverageFormId] = useState<string>(`${details?.form_id}`)
  const [rateID, setRateID] = useState<string>(`${details?.rate_id}`)
  const [retailPrice, setRetailPrice] = useState<any>()
  const [deductiblePrice, setDeductiblePrice] = useState<any>()
  const [termMonths, setTermMonths] = useState<string>(details?.term ?? details?.term_months)
  const [coverageData, setCoverageData] = useState<{
    term_miles: Array<string>
    term_months: Array<string>
  }>({ term_miles: [], term_months: [] })

  useEffect(() => {
    if (formattedData && details && dealerInfo) {
      getProductRates({
        data: getRateRequestObject(formattedData, details, dealerInfo)
      })
    }
  }, [formattedData, details, dealerInfo])

  const normalizeDeductibles = (deductibles: any): any[] => {
    if (!deductibles) return []
    return Array.isArray(deductibles) ? deductibles : [deductibles]
  }

  const getRateRequestObject = (formattedData: any, details: any, dealerInfo: any) => {
    return {
      deal_info: dealerInfo,
      products_info: [
        {
          product_id: details?.product_id,
          starting_mileage: 0,
          ending_mileage: 10000,
          surcharges: formattedData?.surcharges
        }
      ],
      test_request: true
    }
  }

  useEffect(() => {
    if (details?.price) {
      setRetailPrice(details.price)
    }
    if (details?.term ?? details?.term_months) {
      setTermMonths(details?.term ?? details?.term_months)
      setCoverageData((prevState) => ({
        ...prevState,
        ...(details?.term_months ? { term_months: [details.term_months] } : {}),
        ...(details?.term_miles ? { term_miles: [details.term_miles] } : {})
      }))
    }
    if (details?.coverage_name) setCoverageName(details?.coverage_name)
    if (details?.deductiblePrice) setDeductiblePrice(details?.deductiblePrice)
    if (details?.form_id) setCoverageFormId(details?.form_id)
    if (details?.rate_id) setRateID(details?.rate_id)
  }, [details])

  useEffect(() => {
    if (productRates?.rates?.rate) {
      const finalCoverage = productRates?.rates?.rate?.coverages
      if (finalCoverage) {
        const coverages = Array.isArray(finalCoverage?.coverage)
          ? finalCoverage?.coverage
          : [finalCoverage?.coverage]
        const matchedCoverage = coverages?.find((coverage: any) => {
          const coverageDeductible = coverage?.deductibles?.deductible

          const isDeductibleMatched = Array.isArray(coverageDeductible)
            ? coverageDeductible.some((deductible: any) => deductible?.rate_id === Number(rateID))
            : coverageDeductible?.rate_id === Number(rateID)

          return isDeductibleMatched
        })

        setCoverageName(matchedCoverage?.coverage_name)
        setCoverageFormId(matchedCoverage?.form?.form_id)
        setTermMonths(matchedCoverage?.term_months)

        const coverageDeductible = matchedCoverage?.deductibles?.deductible

        const matchedDeductible = Array.isArray(coverageDeductible)
          ? coverageDeductible.find((deductible: any) => deductible?.rate_id === Number(rateID))
          : coverageDeductible

        const retailPrice = matchedDeductible?.retail_price

        setDeductiblePrice(retailPrice)
      }
    }
  }, [productRates, details?.product_id])

  const handleClose = () => {
    setIsOpen(false)
  }

  const calculateRetailPrice = (basePrice: number, markup: number): number => {
    if (!basePrice || !markup) return basePrice || 0
    return basePrice + (basePrice * markup) / 100
  }

  const updatePrice = () => {
    const coverageObj = selectDataObj.find((x: any) => x?.product_id === details?.product_id)
    if (coverageObj) {
      coverageObj.price = retailPrice
      coverageObj.surcharges = selected
      coverageObj.form_id = coverageFormId
      coverageObj.rate_id = rateID
      coverageObj.deductiblePrice = deductiblePrice
      coverageObj.term_months = termMonths
      coverageObj.coverage_name = coverageName
      selectDataObj = selectDataObj.map((obj: any) =>
        obj?.product_id === details?.product_id
          ? {
              ...obj,
              price: coverageObj.price
            }
          : obj
      )
      editPopupCallback(coverageObj)
    }
    setIsOpen(false)
  }

  const getPriceToolTip = (price: any): string | null => {
    const finalPrice = calculateRetailPrice(price, details?.markup)
    return `${(finalPrice - price).toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })} (${details?.markup}%)`
  }

  const getBaseAmount = (price: any): any => {
    return price.toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })
  }
  const handleCheckboxChange = (code: string) => {
    const isBoxSelected = selected.includes(code)
    const updatedSelected = isBoxSelected
      ? selected.filter((item) => item !== code)
      : [...selected, code]
    setSelected(updatedSelected)
    let totalSurchargeAmount = 0
    updatedSelected?.forEach((code) => {
      const surCharge = selectedCoverage?.[0]?.surcharges?.surcharged?.find(
        (s: any) => s?.code === code
      )
      if (surCharge) {
        totalSurchargeAmount += parseFloat(surCharge?.retail_price)
      }
    })
    const markupAmount = details?.markup || 0
    const markup =
      updatedSelected?.length > 0
        ? ((totalSurchargeAmount + deductiblePrice) * markupAmount) / 100
        : (deductiblePrice * markupAmount) / 100
    const totalRetailPrice = markup + deductiblePrice + totalSurchargeAmount
    setRetailPrice(totalRetailPrice)
  }
  const formatDeductible = (price: number) =>
    defaultCurrency +
    price?.toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })

  const productCoverageRates = productRates?.rates?.rate?.coverages?.coverage
  
  const availableCoveragesByCoverageName = [].concat(productCoverageRates || [])
    .filter((productCoverage: any) => productCoverage?.coverage_name === coverageName)
  
  const availableTermsByCoverageName = (Array.isArray(productCoverageRates) && productCoverageRates?.length)
    ? productCoverageRates
        ?.filter((productCoverage: any) => productCoverage?.coverage_name === coverageName)
        ?.map((filteredCoverages: any) => ({
          value: filteredCoverages?.term_months,
          text: `${filteredCoverages?.term_months} Months`
        }))
        ?.reduce((unique: any[], item: any) => {
          if (!unique.some((existingItem: any) => existingItem.value === item.value)) {
            unique.push(item)
          }
          return unique
        }, [])
        ?.sort((a: any, b: any) => a.value - b.value) // Sorting by the 'value'
    : [
        {
          value: productCoverageRates?.term_months,
          text: `${productCoverageRates?.term_months} Months`
        }
      ]

  const availableDeductibles = normalizeDeductibles(productCoverageRates)
    ?.filter(
      (productCoverage: any) =>
        productCoverage?.coverage_name === coverageName &&
        parseInt(productCoverage?.term_months) === parseInt(termMonths)
    )
    ?.map((item: any) => item?.deductibles?.deductible)
    ?.flat()
    ?.map((deductibleItem: any) => ({
      value: deductibleItem?.rate_id,
      text: formatDeductible(deductibleItem?.retail_price)
    }))

  const selectedCoverage = normalizeDeductibles(productCoverageRates)?.filter(
    (productCoverage: any) =>
      productCoverage?.coverage_name === coverageName &&
      parseInt(productCoverage?.term_months) === parseInt(termMonths) &&
      (productCoverage?.deductibles?.deductible?.rate_id === Number(rateID) ||
        (Array.isArray(productCoverage?.deductibles?.deductible) &&
          productCoverage?.deductibles?.deductible.some(
            (deductible: any) => deductible?.rate_id === Number(rateID)
          )))
  )

  const flattenDeductibles = (coverageData: any[]) => {
    return coverageData
      ?.map((coverage: any) =>
        Array.isArray(coverage?.deductibles?.deductible)
          ? coverage?.deductibles?.deductible
          : [coverage?.deductibles?.deductible]
      )
      ?.flat()
  }

  return (
    <Dialog
      theme={theme}
      title={details?.product_name}
      size="xs"
      open={isOpen}
      onCloseDialog={handleClose}
      customFooter={
        <>
          <Button theme={theme} secondary text={'Cancel'} onClick={handleClose} />
          <Button
            theme={theme}
            disabled={isSaveButtonDisabled || isLoadingGetFnIProductRate}
            type="submit"
            primary
            text="Save"
            onClick={updatePrice}
          />
        </>
      }
    >
      <Box theme={theme}>
        <Select
          theme={theme}
          disablePortal={true}
          disabled={false}
          fullWidth
          label={'Coverage Name'}
          value={coverageName}
          items={
            Array.isArray(productCoverageRates)
              ? (Array.from(
                  new Map(
                    productCoverageRates?.map((coverage: any) => [
                      coverage?.coverage_name,
                      {
                        text: coverage?.coverage_name,
                        value: coverage?.coverage_name
                      }
                    ])
                  ).values()
                ) as any[])
              : [
                  {
                    text: productCoverageRates?.coverage_name,
                    value: productCoverageRates?.coverage_name
                  }
                ]
          }
          onChange={(e: any) => {
            const selectedCoverage = e?.target?.value
            setCoverageName(selectedCoverage)

            let matchingCoverages = productCoverageRates?.filter(
              (coverage: any) =>
                coverage?.coverage_name === selectedCoverage &&
                coverage?.term_months === details?.term_months
            )

            if (matchingCoverages?.length === 0) {
              const fallBackMonthlyTerm = Math.min(
                ...(productCoverageRates
                  ?.filter(
                    (filterCoverage: any) => filterCoverage?.coverage_name === selectedCoverage
                  )
                  ?.map((coverage: any) => parseInt(coverage?.term_months)) || [])
              )

              matchingCoverages = productCoverageRates?.filter(
                (coverage: any) =>
                  coverage?.coverage_name === selectedCoverage &&
                  parseInt(coverage?.term_months) === fallBackMonthlyTerm
              )
            }

            setTermMonths(matchingCoverages?.[0]?.term_months)
            setCoverageFormId(matchingCoverages?.[0]?.form?.form_id)
            const deductibleToSelect = flattenDeductibles(matchingCoverages)

            let innerDeductible = deductibleToSelect.flat()

            if (Array.isArray(innerDeductible)) {
              innerDeductible = innerDeductible.reduce((lowest, current) => {
                return current.retail_price < lowest.retail_price ? current : lowest
              }, innerDeductible[0])
            }
            const selectedRateId = (innerDeductible as any)?.rate_id
            if (selectedRateId) {
              setRateID(selectedRateId)
              setSelected([])
              const retailPrice = (innerDeductible as any)?.retail_price
              setDeductiblePrice(retailPrice)
              setRetailPrice(calculateRetailPrice(retailPrice, details?.markup))
            }
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        />
        <Select
          theme={theme}
          disablePortal={false}
          fullWidth
          disabled={false}
          label={'Term'}
          value={termMonths || ''}
          items={availableTermsByCoverageName}
          onChange={(e: any) => {
            const selectedTermMonths = e?.target?.value
            const deductibleToSelect = availableCoveragesByCoverageName
              ?.filter((coverage: any) => coverage?.term_months === selectedTermMonths)
              ?.map((item: any) =>
                Array.isArray(item?.deductibles?.deductible)
                  ? item?.deductibles?.deductible
                  : [item?.deductibles?.deductible]
              )
              ?.flat()
            let innerDeductible = deductibleToSelect
            if (Array.isArray(innerDeductible)) {
              innerDeductible = innerDeductible.reduce((lowest, current) => {
                return current.retail_price < lowest.retail_price ? current : lowest
              }, innerDeductible[0])
            }
            setTermMonths(selectedTermMonths)
            const selectedRateId =
              innerDeductible?.[0]?.rate_id ?? (innerDeductible as any)?.rate_id
            if (selectedRateId) {
              setRateID(selectedRateId)
              const retailPrice =
                innerDeductible?.[0]?.retail_price ?? (innerDeductible as any)?.retail_price
              setSelected([])
              setDeductiblePrice(retailPrice)
              setRetailPrice(calculateRetailPrice(retailPrice, details?.markup))
            }
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        />
        <Select
          theme={theme}
          disablePortal={false}
          fullWidth
          disabled={false}
          label={'Mileage'}
          value={coverageData?.term_miles?.[0] || ''}
          items={coverageData?.term_miles?.map((milage: any) => ({
            value: milage,
            text: `${new Intl.NumberFormat('en-US')?.format(milage)} Miles`
            // text: `${milage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} Miles`
          }))}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        />
        <Select
          theme={theme}
          disablePortal={false}
          fullWidth
          disabled={false}
          label={'Deductible'}
          value={rateID}
          items={availableDeductibles}
          onChange={(e: any) => {
            const selectedRateId = e?.target?.value
            if (selectedRateId) {
              setRateID(selectedRateId)
              const updatedAvailableDeductibles = availableDeductibles.map((item: any) => ({
                ...item,
                retail_price: parseFloat(item?.text?.replace(/[^\d.-]/g, '')),
                rate_id: item?.value
              }))

              const selectedDeductible = updatedAvailableDeductibles?.find(
                (item: any) => item?.rate_id === Number(selectedRateId)
              )
              setSelected([])
              setDeductiblePrice(selectedDeductible?.retail_price)
              setRetailPrice(
                calculateRetailPrice(selectedDeductible?.retail_price, details?.markup)
              )
            }
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        />
        <Box theme={theme}>
          {selectedCoverage?.[0]?.surcharges?.surcharged ? (
            <Box theme={theme} mb={3}>
              <Typography variant="subtitle1" component={'div'} theme={theme} mb={2}>
                Surcharges
              </Typography>
              {selectedCoverage?.[0]?.surcharges?.surcharged?.map(
                (surcharge: any, _index: number) => (
                  <Box
                    key={surcharge?.code}
                    theme={theme}
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    mb={2}
                  >
                    <Checkbox
                      theme={theme}
                      label={surcharge?.description}
                      checkBoxChecked={selected?.includes(surcharge?.code)}
                      onChange={() => handleCheckboxChange(surcharge?.code)}
                    />
                    <Typography variant="body2" component={'span'} theme={theme}>
                      {defaultCurrency}
                      {(surcharge?.retail_price ?? 0).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </Typography>
                  </Box>
                )
              )}
            </Box>
          ) : null}
          <Input
            theme={theme}
            fullWidth
            label={'Price'}
            type={'text'}
            masking
            maskNumeric
            maskDecimalScale={2}
            value={retailPrice}
            onChange={(value) => setRetailPrice(Number(value))}
            startAdornment={defaultCurrency}
            endAdornment={
              <Tooltip
                theme={theme}
                title={
                  <>
                    <Typography
                      theme={theme}
                      component="p"
                      variant="subtitle2"
                      children={`Markup: ${defaultCurrency}${getPriceToolTip(
                        deductiblePrice ?? 0
                      )}`}
                    />
                    <Typography
                      theme={theme}
                      component="p"
                      variant="subtitle2"
                      children={`Base Amount: ${defaultCurrency}${getBaseAmount(
                        deductiblePrice ?? 0
                      )}`}
                    />
                  </>
                }
              >
                <Icon name="InfoIcon" />
              </Tooltip>
            }
          />
        </Box>
      </Box>
    </Dialog>
  )
}

export default FIPriceDialog
