// @ts-nocheck
import { FC, useState, useEffect } from 'react'
import { useTheme, ToggleButton, Link, TextField } from '@mui/material'
import { Autocomplete, Box, Icon } from '@ntpkunity/controls'
import clsx from 'clsx'
import { DeskingActionTypes, useDeskingContext, VehiclesForComparisonTypes } from '@app/desking/desking-context-provider'
import { AutoSearch } from '@components'
import { useEditPermissionHook } from '@hooks/useEditPermissionHook'
import { getDealType } from '@app/desking/utils'
import { PricingTypes } from '@helpers/enums/pricing-type.enum'
import { SELECT_PROGRAM } from '@helpers/constants'

interface ProgramSelectionProps {
    vin: string
}

interface ProgramItem {
    value: string
    text: string
    contractTerm: string
}

const ProgramSelection: FC<ProgramSelectionProps> = ({ vin }) => {
    const theme = useTheme()
    const { state, dispatch } = useDeskingContext()
    const canUserEdit = useEditPermissionHook()
    const isVehicleSelected = Object.keys(state.selection ?? {}).length > 0
    const finance_type = state.isVehicleSelectedAndOrderSaved
        ? state.preview.finance_type
        : state.selection.finance_type

    const { program } = state?.vehiclesData?.get(vin) ?? {}
    const quoteParams = state.isVehicleSelectedAndOrderSaved
        ? state.preview.quote_params
        : isVehicleSelected
            ? state.selection?.vehicleForComparison === VehiclesForComparisonTypes.SINGLE
                ? (state[VehiclesForComparisonTypes.SINGLE]?.[state.selection?.finance_type]
                    ?.calculationsBoxes?.[state.selection?.row]?.[0] || {})
                : ((state[VehiclesForComparisonTypes.MULTIPLE] &&
                    state.selection?.finance_type &&
                    state[VehiclesForComparisonTypes.MULTIPLE][state.selection?.finance_type]?.[state.selection?.col]?.quote_params) || {})
            : {}

    const [openProgram, setOpenProgram] = useState(false)
    const [programItems, setProgramItems] = useState<ProgramItem[]>([])
    const [programData, setProgramData] = useState<string>('')

    const filteredPrograms = program?.[finance_type]
        ?.filter((programItem: { contract_term: { toString: () => any }; pricing_method: string }) => {
            const contractTerm = quoteParams?.contract_term?.toString()
            return (
                (!contractTerm || programItem.contract_term?.toString() === contractTerm) &&
                [PricingTypes.EXTERNAL, PricingTypes.INTERNAL].includes(programItem.pricing_method)
            )
        }) || []

    useEffect(() => {
        setProgramItems([])
        setProgramData('')
    }, [state.lender_id])

    useEffect(() => {
        if (filteredPrograms?.length) {
            const uniquePrograms = new Map()
            filteredPrograms?.forEach((program: any) => {
                if (!program) return
                const uniqueKey = `${program.program_name}_${program.contract_term}`
                if (!uniquePrograms.has(uniqueKey)) {
                    uniquePrograms.set(uniqueKey, {
                        value: program.fp_id,
                        text: `${program.program_name}`,
                        contractTerm: program.contract_term?.toString() || ''
                    })
                }
            })
            const items = Array.from(uniquePrograms.values())
            setProgramItems(items)
        }
    }, [filteredPrograms])

    useEffect(() => {
        if (programData && !programItems.some(item => item.value === programData)) setProgramData('')
    }, [programItems])

    useEffect(() => {
        if (state?.isStipulationScreen || state?.isVehicleSelectedAndOrderSaved || state?.program_id) {
            const programId = state?.program_id ?? state?.order?.program_id
            if (programId) {
                setProgramData(programId)
            } else {
                const defaultProgram = filteredPrograms.find(
                    (
                        program: { is_default: boolean; contract_term: { toString: () => any } }
                    ) => program.is_default && program.contract_term?.toString() === quoteParams?.contract_term?.toString()
                )
                if (defaultProgram) {
                    setProgramData(defaultProgram.fp_id)
                } else if (filteredPrograms.length > 0) {
                    setProgramData(filteredPrograms[0].fp_id)
                }
            }
        }
    }, [
        state?.isStipulationScreen,
        state?.order?.program_id,
        filteredPrograms,
        quoteParams?.contract_term,
        state?.isVehicleSelectedAndOrderSaved
    ])

    const handleProgramChange = (_event: any, newValue: ProgramItem | null) => {
        if (newValue && newValue.value) {
            const newSelectedProgram = filteredPrograms.find(
                (program: { fp_id: string }) => program.fp_id === newValue.value
            )
            const previousProgramId = programData || ''
            if (newSelectedProgram) {
                dispatch({
                    type: DeskingActionTypes.UPDATE_DEFAULT_PROGRAM,
                    payload: {
                        newDefaultProgramId: newValue.value,
                        previousDefaultProgramId: previousProgramId,
                        vinUPD: vin,
                        contractTermUPD: quoteParams?.contract_term
                    }
                })
                setProgramData(newValue.value)
            }
        }
        setOpenProgram(false)
    }

    const showProgramName = () => {
        const selectedProgram = programItems.find((program) => program.value === programData)
        if (selectedProgram) return selectedProgram.text
        if (programItems.length > 0) {
            const firstProgram = programItems[0]
            setProgramData(firstProgram.value)
            return firstProgram.text
        }
        return SELECT_PROGRAM
    }

    return (
        <AutoSearch theme={theme} className="auto-search">
            <Box theme={theme} className="btn-toggle">
                <ToggleButton
                    value="check"
                    className="text-overflow"
                    fullWidth
                    selected={openProgram}
                    onChange={() => {
                        setOpenProgram(!openProgram)
                    }}
                    disabled={
                        !isVehicleSelected ||
                        !canUserEdit ||
                        programItems.length === 0
                    }
                >
                    <span className="btn-label text-overflow">
                        {programItems.length > 0 ? showProgramName() : 'No Programs Available'}
                    </span>
                    <Icon name="ChevronDown" />
                </ToggleButton>
            </Box>
            {openProgram && (
                <>
                    <Link className="overlay" onClick={() => setOpenProgram(false)} />
                    <Box theme={theme} className="list-wrap" textAlign="left">
                        <Autocomplete
                            theme={theme}
                            id="select_programs"
                            disablePortal
                            open={openProgram}
                            disabled={
                                !isVehicleSelected ||
                                !canUserEdit ||
                                programItems.length === 0
                            }
                            endAdornment={<Icon name="SearchIcon" />}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Search Program"
                                    className={clsx('u-form-group')}
                                    variant="outlined"
                                    fullWidth

                                />
                            )}
                            items={programItems}
                            renderOption={(props, option) => (
                                <li {...props} key={option.value}>
                                    <span>{option.text}</span>
                                </li>
                            )}
                            getOptionLabel={(option) => option.text}
                            onChange={handleProgramChange}
                            onBlur={() => setOpenProgram(false)}
                        />
                    </Box>
                </>
            )}
        </AutoSearch>
    )
}

export default ProgramSelection
