import { FC } from 'react'
import { InputAdornment, useTheme } from '@mui/material'
import { Select, Grid, Icon, Input, ContactNumInput } from '@ntpkunity/controls'
import { Controller } from 'react-hook-form'
import { SSN_MASKING_REGEX } from '@helpers/constants'
import useGetDealerCountryCode from '@hooks/useGetDealerCountryCode'

const prefixOptions = [
  {
    text: 'Dr.',
    value: 'DR'
  },
  {
    text: 'Ms.',
    value: 'MS'
  },
  {
    text: 'Mrs.',
    value: 'MRS'
  },
  {
    text: 'Miss.',
    value: 'MISS'
  },
  {
    text: 'Mr.',
    value: 'MR'
  }
]

const CustomerInfoComponent: FC<{
  form: any
  permissions: any
}> = ({ form, permissions }) => {
  const theme = useTheme()
  const countryCodes = useGetDealerCountryCode()
  const {
    control,
    setValue,
    formState: { errors }
  } = form

  const clearSSN = () => {
    setValue('customer_profile.ssn', '')
  }
  
  return (
    <>
      <Grid
        className="container"
        theme={theme}
        container
        rowSpacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid theme={theme} item xs={12} sm={6} md={2.4} lg={2.4}>
          <Controller
            name="customer_profile.prefix"
            defaultValue={'SELECT'}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                theme={theme}
                label={'Prefix'}
                items={prefixOptions}
                sxProps={''}
                value={value}
                selectError={(errors as any)?.customer_profile?.prefix?.message?.toString()}
                onChange={(e: any) => {
                  onChange(e)
                }}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} sm={6} md={2.4} lg={2.4}>
          <Controller
            name="customer_profile.first_name"
            defaultValue={''}
            control={control}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                placeholder={'Type here..'}
                label={`First Name ${permissions?.first_name ? '*' : ''}`}
                type="text"
                {...field}
                error={(errors as any)?.customer_profile?.first_name?.message}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} sm={6} md={2.4} lg={2.4}>
          <Controller
            name="customer_profile.middle_name"
            defaultValue={''}
            control={control}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                placeholder={'Type here..'}
                label={`Middle Name ${permissions?.middle_name ? '*' : ''}`}
                type="text"
                {...field}
                error={(errors as any)?.customer_profile?.middle_name?.message}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} sm={6} md={2.4} lg={2.4}>
          <Controller
            name="customer_profile.last_name"
            defaultValue={''}
            control={control}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                placeholder={'Type here..'}
                label={`Last Name ${permissions?.last_name ? '*' : ''}`}
                type="text"
                {...field}
                error={(errors as any)?.customer_profile?.last_name?.message}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} sm={6} md={2.4} lg={2.4}>
          <Controller
            name="customer_profile.suffix"
            defaultValue={''}
            control={control}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                placeholder={'Type here..'}
                label={`Suffix ${permissions?.suffix ? '*' : ''}`}
                type="text"
                {...field}
                error={(errors as any)?.customer_profile?.suffix?.message}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12}>
          <Controller
            name="customer_profile.ssn"
            defaultValue={''}
            control={control}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                masking
                {...field}
                inputMask={SSN_MASKING_REGEX}
                placeholder={'000 - 00 - 0000'}
                label={`Social Security Number ${permissions?.ssn ? '*' : ''}`}
                type="text"
                endAdornment={
                  <InputAdornment position="end" className="input-adornment">
                    <Icon onClick={clearSSN} name="DeleteIcon" />
                  </InputAdornment>
                }
                helperText="We take your privacy seriously. Your SSN will be fully encrypted."
                error={(errors as any)?.customer_profile?.ssn?.message}
                maskAlphaNumeric={true}
                onChange={(e) => {
                  let sanitizedValue = e
                  if (e) sanitizedValue = e?.replace(/[^0-9]/g, '')
                  field.onChange(sanitizedValue)
                }}
              />
            )}
          />
        </Grid>
       
        <Grid theme={theme} item xs={12} sm={6}>
          <Controller
            name="customer_profile.home_number"
            defaultValue={''}
            control={control}
            render={({ field }) => (
              <ContactNumInput 
              theme={theme}
              country={countryCodes[0]}
              inputLabel={`Home Phone Number ${permissions?.home_number ? '*' : ''}`}
              errorMessage={(errors as any)?.customer_profile?.home_number?.message}
              countriesList={[...countryCodes] as string[]}
              id={'customer_profile.home_number'}
              disabled={false}
              {...field}
              value={field.value ?? ''}
            />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12} sm={6}>
          <Controller
            name="customer_profile.mobile_number"
            defaultValue={''}
            control={control}
            render={({ field }) => (
              <ContactNumInput 
                theme={theme}
                country={countryCodes[0]}
                inputLabel={`Mobile Phone Number ${permissions?.mobile_number ? '*' : ''}`}
                errorMessage={(errors as any)?.customer_profile?.mobile_number?.message}
                countriesList={[...countryCodes] as string[]}
                id={'customer_profile.mobile_number'}
                disabled={false}
                {...field}
                value={field.value ?? ''}
              />
            )}
          />
        </Grid>
        <Grid theme={theme} item xs={12}>
          <Controller
            name="customer_profile.email"
            defaultValue={''}
            control={control}
            render={({ field }) => (
              <Input
                theme={theme}
                fullWidth
                placeholder="sample@sample.com *"
                label="Email Address"
                type="email"
                {...field}
                disabled={true}
                error={(errors as any)?.customer_profile?.email?.message}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  )
}
export default CustomerInfoComponent
