import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { FileDragDrop, FilePreview, Select } from '@ntpkunity/controls'
import { Grid, Input, NestedMultiSelect } from 'components'
import { Controller } from 'react-hook-form'
import { validation } from '@helpers/methods'
import { InstallationMode, Vehicle_Type } from '@helpers/enums'
import { useStoreContext } from '@store/storeContext'
import {
  useGetMasterListAccessories,
  useSaveAddOn,
  useUpdateAddOn
} from '@apis/dealer-addon.service'
import { AddOns } from '@models'
import { useGetAssetMakeTrims } from '@apis/configurations.service'

interface IFormInputs {
  id?: number
  offered_by: string
  description: string
  product_name: string
  category: string
  specfic_category: string
  vehicle_type: string
  part_no: string
  supplier: string
  installation_mode: string
  price: number
  price_inclusive: boolean
  rv_adder: number
  compatible_models: any[]
  is_active: boolean
  image?: string[]
  is_price_inclusive?: boolean
}

const NewAddOns: FC<{
  form: any
  formData?: IFormInputs
  isEditMode: boolean
  defaultFormValues: IFormInputs
}> = ({ form, formData, isEditMode, defaultFormValues }) => {
  const theme = useTheme()
  const { actions } = useStoreContext()
  const { mutate: SaveAddOn } = useSaveAddOn()
  const { mutate: updateAddon } = useUpdateAddOn()
  const { mutate: getAssetTrims, data: assetTrimsData } = useGetAssetMakeTrims()
  const [compatibleModels, setcompatibleModels] = useState<any[]>([])
  const { mutate: getMasterListAccessories, data: masterListAccessoriesData } =
    useGetMasterListAccessories()

  const {
    control,
    formState: { errors },
    setValue,
    watch
  } = form
  const handleChangeDelear = (_event: any) => {
    setValue('description', '')
    setValue('product_name', '')
    setValue('category', '')
    setValue('part_no', '')
    setValue('installation_mode', '')
    setValue('price', 0)
    setValue('supplier', '')
    setValue('compatible_models', [])
    setValue('rv_adder', 0)
    setValue('vehicle_type', '')
    setValue('image', [])
    setImageFiles([])
    setcompatibleModels([])
  }
  const image = watch('image')
  const offeredBy = watch('offered_by')
  const installationMode = watch('installation_mode')
  const [isOEMAddonSelected, setIsOEMAddonSelected] = useState(false)
  const handleChange = (event: any) => {
    const selectedMasterAddon = masterListAccessoriesData.filter(
      (masterAddon: any) => masterAddon.id === event.target.value
    )
    setValue('product_name', selectedMasterAddon[0].productName.toString())
    setValue('category', selectedMasterAddon[0].category)
    setValue('part_no', selectedMasterAddon[0].partNo.toString())
    setValue('installation_mode', selectedMasterAddon[0].installationMode)
    setValue('price', selectedMasterAddon[0].price.toString())
    setValue('supplier', selectedMasterAddon[0].offeredBy)
    setIsOEMAddonSelected(true)
    setValue('compatible_models', selectedMasterAddon[0].compatibleModels)
    setValue('rv_adder', masterListAccessoriesData[0].rvAdder)
    setValue('image', masterListAccessoriesData[0].image)
  }
  const onSubmit = async (data: any) => {
    if (data) {
      if (data.offered_by === 'OEM') {
        data.description = masterListAccessoriesData.filter(
          (item: any) => item.id === data.description
        )[0].description
      }
      const associatedTrims = compatibleModels.map((trim) => {
        return trim.value
      })
      data.compatible_models = associatedTrims
      if (isEditMode) {
        updateAddon(
          {
            ...data
          },
          {
            onSuccess(response: AddOns) {
              actions.setToast({
                toastMessage: 'Add-On Saved Sucessfully',
                toastState: true
              })
              actions.updateAddonRow(response)
            },
            onError(error: any) {
              actions.setToast({
                toastMessage: error.error.toString(),
                toastState: true,
                variant: 'error'
              })
            }
          }
        )
      } else {
        SaveAddOn(
          {
            ...data
          },
          {
            onSuccess(response: AddOns) {
              actions.setToast({
                toastMessage: 'Add-On Saved Sucessfully',
                toastState: true
              })
              actions.addAddonRow(response)
            },
            onError(error: any) {
              actions.setToast({
                toastMessage: error.error.toString(),
                toastState: true,
                variant: 'error'
              })
            }
          }
        )
      }
    }
  }

  const lookupCategory = [
    { text: 'Alloy Values', value: 'Alloy Values' },
    { text: 'Communication & Technology', value: 'Communication & Technology' },
    { text: 'E-Mobility', value: 'E-Mobility' },
    { text: 'Exterior Design', value: 'Exterior Design' },
    { text: 'Interior Design', value: 'Interior Design' },
    { text: 'Light Alloy Wheels', value: 'Light Alloy Wheels' },
    { text: 'Luggage & Leisure', value: 'Luggage & Leisure' },
    { text: 'Maintance & Care', value: 'Maintance & Care' },
    { text: 'Music & Electronics', value: 'Music & Electronics' },
    { text: 'Protection & Safety', value: 'Protection & Safety' },
    { text: 'Others', value: 'Others' }
  ]

  const [imageFiles, setImageFiles] = useState<any[]>([])
  const onimageUpload = (e: any) => {
    if (e && e?.target && e?.target?.files && e?.target?.files.length > 0) {
      addFilesForPreview(e.target.files)
    }
  }
  const onimageDrop = (e: any) => {
    if (
      e &&
      e?.dataTransfer &&
      e?.dataTransfer?.files &&
      e?.dataTransfer?.files &&
      e?.dataTransfer?.files.length > 0
    ) {
      addFilesForPreview(e.dataTransfer.files)
    }
  }

  const addFilesForPreview = (files: any) => {
    let fileReader = new FileReader()
    for (let i = 0; i < files.length; i++) {
      fileReader.readAsDataURL(files[i])
      fileReader.onload = (event) => {
        setValue('image', [...(image || []), event?.target?.result?.toString() || ''])
        setImageFiles([...imageFiles, files[i]])
      }
    }
  }
  const onRemoveFile = (e: any, _index: number) => {
    setImageFiles(e)
  }

  useEffect(() => {
    getMasterListAccessories()
    getAssetTrims()
  }, [])

  useEffect(() => {
    onSubmit(formData)
  }, [formData])

  useEffect(() => {
    if (isEditMode) {
      if (defaultFormValues.offered_by === 'OEM') {
        setIsOEMAddonSelected(true)
        setValue(
          'description',
          masterListAccessoriesData.filter(
            (item: any) => item.description === defaultFormValues.description
          )[0].id
        )
      }
      setcompatibleModels(defaultFormValues?.compatible_models || [])
      setImageFiles(
        defaultFormValues && defaultFormValues.image
          ? defaultFormValues.image.map((image) => {
              return dataURLtoFile(image, 'currentImage.jpg')
            })
          : []
      )
    }
  }, [isEditMode])

  /***Here is code for converting "Base64" to javascript "File Object".***/

  const dataURLtoFile = (dataurl: string, filename: string) => {
    if (dataurl && dataurl.length > 0) {
      var arr = dataurl.split(',') || [],
        mime = arr[0].match(/:(.*?);/),
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime ? mime[1] : '' })
    }
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          name="offered_by"
          control={control}
          defaultValue={''}
          render={({ field: { value, onChange } }) => (
            <Select
              theme={theme}
              label={'Offered By'}
              items={[
                { text: 'OEM', value: 'OEM' },
                { text: 'Dealer', value: 'Dealer' }
              ]}
              value={value}
              sxProps={''}
              onChange={(e) => {
                if (e.target.value === 'Dealer') {
                  setIsOEMAddonSelected(false)

                  handleChangeDelear(e)
                }

                onChange(e)
              }}
            ></Select>
          )}
        />
      </Grid>
      {(!offeredBy || offeredBy === 'Dealer') && (
        <Grid item xs={12}>
          <Controller
            name="description"
            control={control}
            defaultValue={''}
            rules={validation('Description', true)}
            render={({ field }) => (
              <Input
                theme={theme}
                label={'Description'}
                error={errors?.description?.message}
                type="text"
                id="Description"
                fullWidth
                {...field}
              />
            )}
          />
        </Grid>
      )}
      {offeredBy && offeredBy === 'OEM' && (
        <Grid item xs={12}>
          <Controller
            name="description"
            control={control}
            defaultValue={''}
            rules={validation('Description', true)}
            render={({ field: { value, onChange } }) => (
              <Select
                theme={theme}
                label={'Description'}
                items={masterListAccessoriesData?.map((addOn: any) => {
                  return { value: addOn.id, text: addOn.description }
                })}
                value={value}
                sxProps={''}
                onChange={(e) => {
                  handleChange(e)
                  onChange(e)
                }}
              ></Select>
            )}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Controller
          name="product_name"
          control={control}
          defaultValue={''}
          rules={validation('Product Name', true)}
          render={({ field }) => (
            <Input
              theme={theme}
              label={'Product Name'}
              type="text"
              fullWidth
              {...field}
              error={errors?.productName?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="category"
          control={control}
          rules={validation('Category', true)}
          defaultValue={''}
          render={({ field }) => (
            <Select
              theme={theme}
              label={'Category'}
              items={lookupCategory}
              {...field}
              value={field.value}
              sxProps={''}
              error={errors?.category?.message as never}
              onChange={field.onChange}
            ></Select>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="vehicle_type"
          control={control}
          defaultValue={''}
          rules={validation('Vehicle Condition', true)}
          render={({ field: { value, onChange } }) => (
            <Select
              theme={theme}
              label={'Vehicle Condition'}
              items={Object.keys(Vehicle_Type)
                .filter((value) => isNaN(Number(value)) === false)
                .map((key: any) => ({
                  value: Number(key),
                  text: Vehicle_Type[key]
                }))}
              value={value}
              sxProps={''}
              onChange={onChange}
            ></Select>
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="part_no"
          control={control}
          render={({ field }) => (
            <Input theme={theme} label={'Part No'} type="text" id="partNo" fullWidth {...field} />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="supplier"
          control={control}
          defaultValue={''}
          rules={validation('Supplier', true)}
          render={({ field }) => (
            <Input
              theme={theme}
              label={'Supplier'}
              type="text"
              id="supplier"
              disabled={isOEMAddonSelected}
              fullWidth
              {...field}
              error={errors?.supplier?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="installation_mode"
          control={control}
          defaultValue={''}
          rules={validation('Installed Mode', true)}
          render={({ field }) => (
            <Select
              theme={theme}
              label={'Installed Mode'}
              items={Object.entries(InstallationMode).map((item) => ({
                text: item[1],
                value: item[1]
              }))}
              {...field}
              value={field.value}
              sxProps={''}
              onChange={field.onChange}
            ></Select>
          )}
        />
      </Grid>
      {installationMode &&
        ((installationMode === InstallationMode.PRE_INSTALLED &&
          !installationMode.is_price_inclusive) ||
          installationMode.installation_mode !== InstallationMode.PRE_INSTALLED) && (
          <>
            <Grid item xs={6}>
              <Controller
                name="price"
                control={control}
                rules={validation('Price', true)}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    label={'Price'}
                    type="number"
                    startAdornment="$"
                    id="price"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="is_price_inclusive"
                control={control}
                defaultValue={true}
                render={({ field }) => (
                  <Select
                    theme={theme}
                    label={''}
                    items={[
                      {
                        text: 'Inclusive',
                        value: true
                      },
                      {
                        text: 'Exclusive',
                        value: false
                      }
                    ]}
                    {...field}
                    value={field.value}
                    sxProps={''}
                    onChange={field.onChange}
                  ></Select>
                )}
              />
            </Grid>
          </>
        )}
      {(!installationMode || installationMode === InstallationMode.OPTIONAL) && (
        <Grid item xs={12}>
          <Controller
            name="price"
            control={control}
            rules={validation('Price', true)}
            render={({ field }) => (
              <Input
                theme={theme}
                label={'Price'}
                type="number"
                startAdornment="$"
                id="price"
                fullWidth
                {...field}
              />
            )}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Controller
          name="rv_adder"
          control={control}
          render={({ field }) => (
            <Input
              theme={theme}
              disabled={true}
              label={'RV On Add-On'}
              type="decimal"
              id="rvAdder"
              endAdornment={'%'}
              fullWidth
              {...field}
            />
          )}
        />
      </Grid>
      {assetTrimsData && (
        <Grid item xs={12}>
          <Controller
            name="compatible_models"
            control={control}
            render={({ field }) => (
              <NestedMultiSelect
                theme={theme}
                label={'Compatible Trims'}
                placeholder={'Select'}
                {...field}
                value={field.value}
                items={assetTrimsData?.map((model: any) => {
                  return {
                    text: model.model_name,
                    value: model.id,
                    subItems: model.asset_trim?.map((trim: any) => {
                      return { text: trim.trim_name, value: trim.id }
                    })
                  }
                })}
                sxProps={''}
                onChange={(_e: any, child: any) => {
                  setcompatibleModels(child)
                }}
              ></NestedMultiSelect>
            )}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Controller
          name="image"
          control={control}
          rules={validation('Atleast one Image', true)}
          render={({ field }) => (
            <FileDragDrop
              {...field}
              theme={theme}
              width="300"
              height="200px"
              backgroundColor="white"
              onDrop={onimageDrop}
              onChange={onimageUpload}
              allowMultiple={true}
            />
          )}
        />
        {imageFiles && imageFiles.length > 0 && imageFiles[0] != null && (
          <FilePreview
            files={imageFiles.length > 0 ? imageFiles : []}
            onRemoveFile={onRemoveFile}
            theme={theme}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="is_active"
          control={control}
          defaultValue={true}
          rules={validation('Status', true)}
          render={({ field: { value, onChange } }) => (
            <Select
              theme={theme}
              label={'Status'}
              defaultValue={true}
              items={[
                { text: 'Enabled', value: true },
                { text: 'Disabled', value: false }
              ]}
              value={value}
              sxProps={''}
              onChange={onChange}
            ></Select>
          )}
        />
      </Grid>
    </Grid>
  )
}

export default NewAddOns
