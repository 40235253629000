import * as yup from 'yup'
import { STATES_FOR_ADDRESS_LOCATION, ZIP_CODE_REGEX_MAP } from '@helpers/constants'

export function createCustomerValidatorsSchema(
  isGaraging: boolean,
  isBilling: boolean,
  isPreviousAddress: boolean,
  permissions: any,
  isUsAddress: boolean,
  dealerState?: any
) {
  const schema = yup.object().shape({
    customer_profile: yup.object().shape(
      {
        first_name: yup.string().when('$a', {
          is: () => permissions?.first_name,
          then: yup
            .string()
            .nullable()
            .required('First Name is required')
            .max(100, 'First Name maximum length is up to 100 characters')
            .matches(/^[A-Za-z]*$/, 'Only alphabets are allowed'),
          otherwise: yup
            .string()
            .nullable()
            .notRequired()
            .max(100, 'First Name maximum length is up to 100 characters')
            .matches(/^[A-Za-z]*$/, 'Only alphabets are allowed')
        }),
        middle_name: yup.string().when('$a', {
          is: () => permissions?.middle_name,
          then: yup
            .string()
            .nullable()
            .required('Middle Name is required')
            .max(100, 'Middle Name maximum length is up to 100 characters')
            .matches(/^[A-Za-z]*$/, 'Only alphabets are allowed'),
          otherwise: yup
            .string()
            .nullable()
            .notRequired()
            .max(100, 'Middle Name maximum length is up to 100 characters')
            .matches(/^[A-Za-z]*$/, 'Only alphabets are allowed')
        }),
        last_name: yup.string().when('$a', {
          is: () => permissions?.last_name,
          then: yup
            .string()
            .nullable()
            .required('Last Name is required')
            .max(100, 'Last Name maximum length is up to 100 characters')
            .matches(/^[A-Za-z]*$/, 'Only alphabets are allowed'),
          otherwise: yup
            .string()
            .nullable()
            .notRequired()
            .max(100, 'Last Name maximum length is up to 100 characters')
            .matches(/^[A-Za-z]*$/, 'Only alphabets are allowed')
        }),
        suffix: yup.string().when('$a', {
          is: () => permissions?.suffix,
          then: yup
            .string()
            .nullable()
            .required('Customer Suffix is required')
            .max(100, 'Customer Suffix maximum length is up to 100 characters')
            .matches(/^[A-Za-z]*$/, 'Only alphabets are allowed'),
          otherwise: yup
            .string()
            .nullable()
            .notRequired()
            .max(100, 'Customer Suffix maximum length is up to 100 characters')
            .matches(/^[A-Za-z]*$/, 'Only alphabets are allowed')
        }),
        ssn: yup.string().when('$a', {
          is: () => permissions?.ssn,
          then: yup
            .string()
            .nullable()
            .required('Social Security Number is required')
            .length(9, 'Social Security Number must be exactly 9 digits')
            .matches(/^(\d{9}|\●{9})$/, 'Social Security Number must be numeric'),
          otherwise: yup.string().nullable().notRequired()
        }),
        mobile_number: yup.string().when('$a', {
          is: () => permissions?.mobile_number,
          then: yup
            .string()
            .nullable()
            .required('Mobile Phone Number is required')
            .matches(/^1[0-9]{10}$/, 'Valid Mobile Phone Number is required'),
          otherwise: yup
            .string()
            .nullable()
            .notRequired()
            .matches(/^1[0-9]{10}$/, 'Valid Mobile Phone Number is required')
        }),
        home_number: yup.string().when('$a', {
          is: () => permissions?.home_number,
          then: yup
            .string()
            .nullable()
            .required('Home Phone Number is required')
            .matches(/^1[0-9]{10}$/, 'Valid Home Phone Number is required'),
          otherwise: yup
            .string()
            .nullable()
            .notRequired()
            .matches(/^1[0-9]{10}$/, 'Valid Home Phone Number is required')
        })
      },
      [['ssn', 'ssn']]
    ),
    mail_address: yup.object().shape({
      address_line_1: yup.string().when('$a', {
        is: () => permissions?.mailingAddressLine1,
        then: yup.string().nullable().required('Address Line 1 is required'),
        otherwise: yup.string().nullable().notRequired()
      }),
      city: yup.string().when('$a', {
        is: () => permissions?.mailingAddressCity,
        then: yup.string().nullable().required('City is required'),
        otherwise: yup.string().nullable().notRequired()
      }),
      state_name: yup.string().when('$a', {
        is: () => permissions?.mailingAddressState,
        then: yup.string().nullable().required('State Name is required'),
        otherwise: yup.string().nullable().notRequired()
      }),
      state_id: yup.string().when('$a', {
        is: () => permissions?.mailingAddressZipCode,
        then: yup.string().nullable().required('State Name is required'),
        otherwise: yup.string().nullable().notRequired()
      }),
      zip_code: yup.string().when('$a', {
        is: () => permissions?.mailingAddressZipCode,
        then: yup
          .string()
          .nullable()
          .test(function (value) {
            if (!value) {
              return this.createError({ message: 'Zip Code is required' })
            }

            if (isUsAddress && !ZIP_CODE_REGEX_MAP.US.regex.test(value)) {
              return this.createError({ message: ZIP_CODE_REGEX_MAP.US.errorMessage })
            }

            return true
          }),
        otherwise: yup.string().nullable().notRequired()
      }),
      move_in_duration: yup.string().when('$a', {
        is: () => permissions?.mailingAddressMoveInDate,
        then: yup
          .string()
          .nullable()
          .required('Move-In Date is required')
          .matches(/^(0[1-9]|1[0-2])(19|20)\d{2}$/, 'Move-In Date should be in MM/YYYY format'),
        otherwise: yup.string().nullable().notRequired()
      }),
      address_location_type: yup.string().when('$a', {
        is: () => STATES_FOR_ADDRESS_LOCATION.includes(dealerState),
        then: yup
          .string()
          .nullable()
          .required('Address Type is required'),
        otherwise: yup.string().nullable().notRequired()
      })
      
    }),
    previous_address: isPreviousAddress
      ? yup.object().shape({
          address_line_1: yup.string().nullable().required('Address Line 1 is required'),
          city: yup.string().nullable().required('City is required'),
          state_name: yup.string().nullable().required('State Name is required'),
          state_id: yup.string().nullable().required('State Name is required'),
          zip_code: yup.string().nullable().required('Zip Code is required'),
          move_in_duration: yup
            .string()
            .nullable()
            .required('Move-In Date is required')
            .matches(/^(0[1-9]|1[0-2])(19|20)\d{2}$/, 'Move-In Date should be in MM/YYYY format')
        })
      : yup.object().shape({}),
    garaging_address: !isGaraging
      ? yup.object().shape({
          address_line_1: yup.string().when('$a', {
            is: () => permissions?.garagingAddressLine1,
            then: yup.string().nullable().required('Address Line 1 is required'),
            otherwise: yup.string().nullable().notRequired()
          }),
          city: yup.string().when('$a', {
            is: () => permissions?.garagingAddressCity,
            then: yup.string().nullable().required('City is required'),
            otherwise: yup.string().nullable().notRequired()
          }),
          state_name: yup.string().when('$a', {
            is: () => permissions?.garagingAddressState,
            then: yup.string().nullable().required('State Name is required'),
            otherwise: yup.string().nullable().notRequired()
          }),
          state_id: yup.string().when('$a', {
            is: () => permissions?.garagingAddressState,
            then: yup.string().nullable().required('State Name is required'),
            otherwise: yup.string().nullable().notRequired()
          }),
          zip_code: yup.string().when('$a', {
            is: () => permissions?.garagingAddressZipCode,
            then: yup
              .string()
              .nullable()
              .test(function (value) {
                if (!value) {
                  return this.createError({ message: 'Zip Code is required' })
                }

                if (isUsAddress && !ZIP_CODE_REGEX_MAP.US.regex.test(value)) {
                  return this.createError({ message: ZIP_CODE_REGEX_MAP.US.errorMessage })
                }

                return true
              }),
            otherwise: yup.string().nullable().notRequired()
          })
        })
      : yup.object().shape({}),
    billing_address: !isBilling
      ? yup.object().shape({
          address_line_1: yup.string().when('$a', {
            is: () => permissions?.billingAddressLine1,
            then: yup.string().nullable().required('Address Line 1 is required'),
            otherwise: yup.string().nullable().notRequired()
          }),
          city: yup.string().when('$a', {
            is: () => permissions?.billingAddressCity,
            then: yup.string().nullable().required('City is required'),
            otherwise: yup.string().nullable().notRequired()
          }),
          state_name: yup.string().when('$a', {
            is: () => permissions?.billingAddressState,
            then: yup.string().nullable().required('State Name is required'),
            otherwise: yup.string().nullable().notRequired()
          }),
          state_id: yup.string().when('$a', {
            is: () => permissions?.billingAddressState,
            then: yup.string().nullable().required('State Name is required'),
            otherwise: yup.string().nullable().notRequired()
          }),
          zip_code: yup.string().when('$a', {
            is: () => permissions?.billingAddressZipCode,
            then: yup
              .string()
              .nullable()
              .test(function (value) {
                if (!value) {
                  return this.createError({ message: 'Zip Code is required' })
                }

                if (isUsAddress && !ZIP_CODE_REGEX_MAP.US.regex.test(value)) {
                  return this.createError({ message: ZIP_CODE_REGEX_MAP.US.errorMessage })
                }

                return true
              }),
            otherwise: yup.string().nullable().notRequired()
          })
        })
      : yup.object().shape({})
  })
  return schema
}
