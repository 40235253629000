import { useCheckFinancialInsuranceByDealerCode } from '@apis/financial-insurance.service'
import { FinanceTypes, useDeskingContext } from '@app/desking/desking-context-provider'
import { useStoreContext } from '@store/storeContext'
import { useMemo } from 'react'
const useFniAndMspAndRebatesPresent = (orderReferenceId?: string | null) => {
  const {
    states: {
      dealDesk: {
        dealerProfile: { dealer_code }
      },
      dealerInfo: { company_id }
    }
  } = useStoreContext()

  const {
    state: { vehiclesData, vehiclesVins, isVehicleSelectedAndOrderSaved, preview, finance_type }
  } = useDeskingContext()

  const isCash = useMemo(() => finance_type == FinanceTypes.CASH, [finance_type])

  const { isPreviewPage, isCheckFniMsp } = useMemo(
    () => ({
      isCheckFniMsp: !!dealer_code && !!company_id,
      isPreviewPage: !!orderReferenceId && !!dealer_code && !!company_id && isVehicleSelectedAndOrderSaved
    }),
    [dealer_code, company_id, orderReferenceId, isVehicleSelectedAndOrderSaved]
  )

  const { data: fniAndMsp } = useCheckFinancialInsuranceByDealerCode(
    isCheckFniMsp && {
      dealer_code,
      company_id
    }
  )

  const vin = useMemo(
    () => (isVehicleSelectedAndOrderSaved ? preview.vin : vehiclesVins[0]),
    [isVehicleSelectedAndOrderSaved, preview.vin, vehiclesVins]
  )

  const { fni } = vehiclesData?.get(vin) ?? {}

  const { isFniPresentInOrder, isMSPPresentInOrder } = useMemo(() => {
    const isFniOrMsp = fni?.finance?.length ? fni.finance : fni?.lease ?? []

    return {
      isFniPresentInOrder: isFniOrMsp.some((orderItem: any) => !orderItem?.is_msp),
      isMSPPresentInOrder: isFniOrMsp.some((orderItem: any) => orderItem?.is_msp)
    }
  }, [fni])

  return {
    showFni: (fniAndMsp?.fni && isPreviewPage) || isFniPresentInOrder || (isCash && fniAndMsp?.fni),
    showMsp: (fniAndMsp?.msp && isPreviewPage) || isMSPPresentInOrder || (isCash && fniAndMsp?.msp),
    showRebates: isVehicleSelectedAndOrderSaved || isCash,
    isCash,
    isVehicleSelectedAndOrderSaved
  }
}

export default useFniAndMspAndRebatesPresent
