import { checkPermission } from '@helpers/methods'

export const getAllCustomerDetailsPermissions = (permissions: any, dealexPermissions: any) => {
  return {
    contactDetailsPermissions: {
      prefix: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS?.CONTACT_DETAILS_PREFIX
      ),
      first_name: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS?.CONTACT_DETAILS_FIRST_NAME
      ),
      middle_name: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS?.CONTACT_DETAILS_MIDDLE_NAME
      ),
      last_name: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS?.CONTACT_DETAILS_LAST_NAME
      ),
      suffix: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS?.CONTACT_DETAILS_SUFFIX
      ),
      ssn: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS?.CONTACT_DETAILS_SSN
      ),
      mobile_number: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS?.CONTACT_DETAILS_MOBILE_NUMBER
      ),
      home_number: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS?.CONTACT_DETAILS_HOME_PHONE_NUMBER
      ),
      mailingAddressLine1: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS
          ?.CONTACT_DETAILS_MAILING_ADDRESS_LINE_1
      ),
      mailingAddressCity: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS?.CONTACT_DETAILS_MAILING_CITY
      ),
      mailingAddressState: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS?.CONTACT_DETAILS_MAILING_STATE
      ),
      mailingAddressZipCode: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS?.CONTACT_DETAILS_MAILING_ZIP_CODE
      ),
      mailingAddressCounty: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS?.CONTACT_DETAILS_MAILING_COUNTY
      ),
      mailingAddressMoveInDate: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS?.CONTACT_DETAILS_MAILING_MOVE_IN_DATE
      ),
      billingAddressLine1: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS
          ?.CONTACT_DETAILS_BILLING_ADDRESS_LINE_1
      ),
      billingAddressCity: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS?.CONTACT_DETAILS_BILLING_CITY
      ),
      billingAddressState: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS?.CONTACT_DETAILS_BILLING_STATE
      ),
      billingAddressZipCode: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS?.CONTACT_DETAILS_BILLING_ZIP_CODE
      ),
      billingAddressCounty: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS?.CONTACT_DETAILS_BILLING_COUNTY
      ),
      garagingAddressLine1: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS
          ?.CONTACT_DETAILS_GARAGING_ADDRESS_LINE_1
      ),
      garagingAddressCity: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS?.CONTACT_DETAILS_GARAGING_CITY
      ),
      garagingAddressState: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS?.CONTACT_DETAILS_GARAGING_STATE
      ),
      garagingAddressZipCode: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS?.CONTACT_DETAILS_GARAGING_ZIP_CODE
      ),
      garagingAddressCounty: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS?.CONTACT_DETAILS_GARAGING_COUNTY
      ),
      previousAddressLine1: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS
          ?.CONTACT_DETAILS_PREVIOUS_ADDRESS_ADDRESS_LINE_1
      ),
      previousAddressCity: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS
          ?.CONTACT_DETAILS_PREVIOUS_ADDRESS_CITY
      ),
      previousAddressState: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS
          ?.CONTACT_DETAILS_PREVIOUS_ADDRESS_STATE
      ),
      previousAddressZipCode: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS
          ?.CONTACT_DETAILS_PREVIOUS_ADDRESS_ZIP_CODE
      ),
      previousAddressCounty: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.CONTACT_DETAILS
          ?.CONTACT_DETAILS_PREVIOUS_ADDRESS_COUNTY
      )
    },
    licenseDetailsPermission: {
      licenseDetailFirstName: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.LICENSE_DETAILS?.LICENSE_DETAILS_FIRST_NAME
      ),
      licenseDetailLastName: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.LICENSE_DETAILS?.LICENSE_DETAILS_LAST_NAME
      ),
      licenseDetailNumber: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.LICENSE_DETAILS?.LICENSE_DETAILS_NUMBER
      ),
      licenseDetailState: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.LICENSE_DETAILS?.LICENSE_DETAILS_STATE
      ),
      licenseDetailExpiration: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.LICENSE_DETAILS?.LICENSE_DETAILS_EXPIRATION
      ),
      licenseDetailDOB: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.LICENSE_DETAILS?.LICENSE_DETAILS_DOB
      )
    },
    employmentDetailsPermissions: {
      employmentDetailType: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.EMPLOYEE_DETAILS?.EMPLOYER_DETAILS_EMPLOYMENT_TYPE
      ),
      employmentDetailName: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.EMPLOYEE_DETAILS?.EMPLOYER_DETAILS_NAME
      ),
      employmentDetailPhoneNumber: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.EMPLOYEE_DETAILS?.EMPLOYER_DETAILS_PHONE_NUMBER
      ),
      employmentDetailOccupation: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.EMPLOYEE_DETAILS?.EMPLOYER_DETAILS_OCCUPATION
      ),
      employmentDetailSince: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.EMPLOYEE_DETAILS?.EMPLOYER_DETAILS_SINCE
      ),
      employmentDetailAnnualEmploymentIncome: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.EMPLOYEE_DETAILS
          ?.EMPLOYER_DETAILS_ANNUAL_EMPLOYMENT_INCOME
      ),
      employmentDetailAnnualOtherIncome: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.EMPLOYEE_DETAILS
          ?.EMPLOYER_DETAILS_ANNUAL_OTHER_INCOME
      ),
      employmentDetailsAddressLine1: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.EMPLOYEE_DETAILS?.EMPLOYER_DETAILS_ADDRESS_LINE_1
      ),
      employmentDetailsAddressCity: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.EMPLOYEE_DETAILS?.EMPLOYER_DETAILS_CITY
      ),
      employmentDetailsAddressState: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.EMPLOYEE_DETAILS?.EMPLOYER_DETAILS_STATE
      ),
      employmentDetailsAddressZipCode: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.EMPLOYEE_DETAILS?.EMPLOYER_DETAILS_ZIP_CODE
      ),
      employmentDetailsAddressCounty: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.EMPLOYEE_DETAILS?.EMPLOYER_DETAILS_COUNTY
      )
    },
    personalFinanceDetailsPermissions: {
      personalFinanceMonthlyDetails: checkPermission(
        permissions,
        dealexPermissions?.CREDIT_APPLICATION?.PERSONAL_FINANCE_DETAILS
          ?.PERSONAL_FINANCE_DETAILS_MONTHLY_PAYMENT
      )
    }
  }
}
