import { FC, useEffect, useMemo, useState } from 'react'
import { useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { styled } from '@mui/material/styles'

import { Typography, Grid, Button, PersistentDrawer } from 'components'
import AddOnsTable from '@app/accessories-add-ons/add-ons-table'
import NewAddOns from '@app/accessories-add-ons/new-addons-drawer'
import { useForm } from 'react-hook-form'

const PageHeader = styled(
  Grid,
  {}
)(({ theme }) => ({
  '&.page-header': {
    padding: '24px 0',
    '.btn-primary': {
      marginLeft: 16,
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    }
  }
}))

interface IFormInputs {
  id?: number
  offered_by: string
  description: string
  product_name: string
  category: string
  specfic_category: string
  vehicle_type: string
  part_no: string
  supplier: string
  installation_mode: string
  price: number
  price_inclusive: boolean
  rv_adder: number
  compatible_models: any[]
  is_active: boolean
  image?: string[]
  is_price_inclusive?: boolean
}
const AddOns: FC = () => {
  const theme = useTheme()
  const [popUpState, setPopUpState] = useState(false)
  const handleshow = () => {
    form.reset({} as IFormInputs)
    setPopUpState(true)
    setIsEditMode(false)
  }
  const [formData, setFormData] = useState<IFormInputs>()
  const [formDefaultData, setFormDefaultData] = useState<IFormInputs>()
  const [isEditMode, setIsEditMode] = useState(false)
  const onSubmit = (formValues: IFormInputs): void => {
    setFormData(formValues)
    setPopUpState(false)
    setIsEditMode(false)
  }
  const form: {
    handleSubmit: any
    reset: any
  } = useForm<IFormInputs>()

  const defaultValues: IFormInputs = useMemo(() => {
    const updatedFormDefaultValues = formDefaultData
    if (updatedFormDefaultValues) {
      updatedFormDefaultValues.compatible_models = formDefaultData
        ? formDefaultData.compatible_models.reduce<{ text: string; value: any }[]>(
            (previous, model: any) => {
              return [...previous, { text: model.trim_name, value: model.id }]
            },
            []
          )
        : []
      setFormDefaultData(updatedFormDefaultValues)
    }
    return formDefaultData
  }, [formDefaultData]) as unknown as IFormInputs

  const onEdit = (formData: IFormInputs) => {
    setFormDefaultData(formData)
    setIsEditMode(true)
    setPopUpState(true)
  }

  useEffect(() => {
    form.reset(defaultValues)
  }, [defaultValues])

  useEffect(() => {
    if (!popUpState) {
      setIsEditMode(false)
    }
  }, [popUpState])

  return (
    <LayoutWithSideNav theme={theme}>
      <PageHeader className="page-header" theme={theme} container item spacing={2}>
        <Grid item xs={12} sm={6} lg={7} md={6}>
          <Typography variant="h2" component="h2" theme={theme}>
            Dealer Add-Ons
          </Typography>
        </Grid>
        <Grid item xs={12} lg={5} sm={6} md={6} textAlign="right">
          <Button primary onClick={handleshow} text="+ Add New Add-On" />
        </Grid>
      </PageHeader>

      <PersistentDrawer
        title={isEditMode ? 'Edit Add-On' : 'Add New Add-On'}
        openPopUp={popUpState}
        setPopUpState={setPopUpState}
        theme={theme}
        customFooter={
          <Button
            primary
            type="submit"
            text="Save Add-On"
            fullWidth
            onClick={form.handleSubmit(onSubmit)}
          />
        }
      >
        <form onSubmit={(e) => e.preventDefault()}>
          <NewAddOns
            form={form}
            formData={formData}
            isEditMode={isEditMode}
            defaultFormValues={defaultValues}
          ></NewAddOns>
        </form>
      </PersistentDrawer>

      <AddOnsTable onEdit={(data) => onEdit(data)}></AddOnsTable>
    </LayoutWithSideNav>
  )
}

export default AddOns
