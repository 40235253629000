import { FC } from 'react'
import { useTheme } from '@mui/material'
import { Box, Button } from '@ntpkunity/controls'
import { BtnTabs } from '@components'
import {
  DeskingActionTypes,
  FinanceType,
  FinanceTypes,
  useDeskingContext
} from './desking-context-provider'
import { useStoreContext } from '@store/storeContext'

interface FinanceLeaseButtonProps {
  financeType: FinanceType
  isSelected: boolean
  text: string
}

export const FinanceLeaseButton: FC<FinanceLeaseButtonProps> = ({
  financeType,
  isSelected,
  text
}) => {
  const theme = useTheme()
  const { dispatch } = useDeskingContext()

  return (
    <Button
      theme={theme}
      type="button"
      text={text}
      defaultBtn
      className={isSelected ? 'selected' : ''}
      onClick={() => {
        dispatch({
          type: DeskingActionTypes.UPDATE_FINANCE_TYPE,
          payload: financeType
        })
      }}
    />
  )
}

export const FLButtons: FC = () => {
  const theme = useTheme()
  const { state } = useDeskingContext()
  const {
    states: {
      dealDesk: { dealerProfile }
    }
  } = useStoreContext()
  const { finance_type, isVehicleSelectedAndOrderSaved, vehiclesVins, vehiclesData } = state
  const vin = vehiclesVins[0]
  const hasFinanceTypePrograms = vehiclesData?.get(vin)?.program?.[FinanceTypes.FINANCE]?.length > 0
  const hasLeaseTypePrograms = vehiclesData?.get(vin)?.program?.[FinanceTypes.LEASE]?.length > 0
  const financeTypesFromPrograms = []

  if (hasFinanceTypePrograms) financeTypesFromPrograms.push(FinanceTypes.FINANCE)
  if (hasLeaseTypePrograms) financeTypesFromPrograms.push(FinanceTypes.LEASE)

  const { available_finance_types = [] } = dealerProfile

  const allowedFinanceTypes =
    financeTypesFromPrograms.length > 0 ? financeTypesFromPrograms : available_finance_types

  return (
    <Box theme={theme} className="flc-li-left-col">
      <BtnTabs theme={theme} className="btn-tabs">
        {!isVehicleSelectedAndOrderSaved ? (
          <>
            {allowedFinanceTypes.includes(FinanceTypes.FINANCE) ? (
              <FinanceLeaseButton
                financeType={FinanceTypes.FINANCE}
                isSelected={finance_type === FinanceTypes.FINANCE}
                text="Finance"
              />
            ) : null}
            {allowedFinanceTypes.includes(FinanceTypes.LEASE) ? (
              <FinanceLeaseButton
                financeType={FinanceTypes.LEASE}
                isSelected={finance_type === FinanceTypes.LEASE}
                text="Lease"
              />
            ) : null}
          </>
        ) : (
          <FinanceLeaseButton
            financeType={finance_type}
            isSelected={true}
            text={finance_type === FinanceTypes.FINANCE ? 'Finance' : 'Lease'}
          />
        )}
      </BtnTabs>
    </Box>
  )
}
