import { getInsuranceCompanies } from '@apis/configurations.service'
import { QueryKeys } from '@helpers/enums'
import { useQuery, UseQueryResult } from 'react-query'
import { IInsuranceCompanies } from 'types/responses/insuranceCompanies'

export const useGetInsuranceCompanies = (
  companyId: number
): UseQueryResult<IInsuranceCompanies[]> => {
  return useQuery<IInsuranceCompanies[]>({
    queryKey: [QueryKeys.GET_INSURANCE_COMPANIES_BY_ID],
    queryFn: () => getInsuranceCompanies(companyId),
    enabled: !!companyId
  })
}
