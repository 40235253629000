import { useStoreContext } from "@store/storeContext"
import { useMemo } from "react"

const useGetDealerCountryCode = () => {
  const { states } = useStoreContext()
  return useMemo(
    () =>
      states?.dealDesk?.countries
        ?.filter(
          (x) =>
            x?.country_code &&
            x?.id === states?.dealDesk?.dealerProfile?.dealer_address?.country_id
        )
        .map((x) => x?.country_code?.toLowerCase()),
    [states?.dealDesk?.dealerProfile?.dealer_address]
  )
}

export default useGetDealerCountryCode
