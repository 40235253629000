import { FC, useEffect, useMemo, useState } from 'react'
import { Link, useTheme } from '@mui/material'
import { Grid, Box, Button, Skeleton } from '@ntpkunity/controls'
import { AgreementPopup, ProductBanner } from 'components'
import { OrderInformationDetails } from 'models/order-information.interface'
import { Status, QueryKeys, AvailableDealTypes } from '@helpers/enums'
import { useQueryClient } from 'react-query'
import { useStoreContext } from '@store/storeContext'
import OrderAssetControl from 'controls/OrderAsser/order-asset-control'
import {
  useGetIndividualAgreement,
  useUpdateIndividualizedAgreement
} from '@apis/configurations.service'
import { useNavigate } from 'react-router-dom'
import { useConvertQuotationToApplication } from '@apis/order-management.service'
import { FinanceTypes } from '@app/desking/desking-context-provider'

const AssetDetails: FC<{
  isDraftOrder?: Boolean
  order_info: OrderInformationDetails
  setConfirmationPopupState?: any
  lenderName?: string
  openSlModal?: any
  lenders?: any
  setSelectedLenders?: any
  setOptionObject?: (order_values: OrderInformationDetails) => void
}> = ({
  isDraftOrder = false,
  order_info,
  setConfirmationPopupState,
  lenderName,
  openSlModal,
  setOptionObject,
  setSelectedLenders,
  lenders
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [defaultCurrency, setDefaultCurrency] = useState('')
  const { states } = useStoreContext()
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [agreementPopUp, setAgreementPopUp] = useState(false)
  const [individual, setIndividualAgreement] = useState<any[]>([])

  const path = window.location.pathname
  const orderReferenceId = path.split('/').pop()
  const { data: individualAgreement } = useGetIndividualAgreement(
    states?.dealerInfo?.dealer_code,
    orderReferenceId
  )
  const {
    mutate: updateIndividualizedAgreement,
    isLoading: _updateRequestLoading,
    data: _updateResponse,
    error: _updateRequestError
  } = useUpdateIndividualizedAgreement()
  const { mutate: convertQuotationRequest } = useConvertQuotationToApplication()
  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])

  const totalAmount = useMemo(() => order_info?.finance_type == FinanceTypes.CASH ? order_info.due_at_signing : order_info?.net_finance_amount, [order_info])

  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0].symbol : ''
    )
  }, [currencies])
  useEffect(() => {
    const res = individualAgreement?.map((r: any) => ({
      ...r,
      selected: false
    }))
    const doesExist = res?.find(
      (agreement: any) => order_info?.individualized_agreement === agreement.agreement_clause
    )
    if (doesExist) {
      doesExist.selected = true
    }
    if (!doesExist && order_info?.individualized_agreement) {
      res?.push({
        agreement_clause: order_info?.individualized_agreement,
        selected: true,
        id: '1'
      })
    }
    setIndividualAgreement(res)
  }, [order_info, individualAgreement])

  useEffect(() => {
    if (!order_info?.individualized_agreement && updateIndividualizedAgreement) {
      const defaultAgreements = individualAgreement?.filter((item: any) => item.is_default)
      if (defaultAgreements?.length > 0 && order_info) {
        updateIndividualizedAgreement(
          {
            reference_number: order_info?.reference_number,
            individualized_agreement: defaultAgreements[0]['agreement_clause']
          },
          {
            onSuccess(_response: any) {
              setOptionObject?.(_response)
            }
          }
        )
      }
    }
  }, [individualAgreement, order_info])
// Cash Deal Type Handling
  const isCashDealType = order_info?.finance_type == AvailableDealTypes.CASH

  return (
    <>
      {agreementPopUp ? (
        <AgreementPopup
          order={order_info}
          setAgreementPopUp={setAgreementPopUp}
          individualAgreement={individual}
          setOptionObject={setOptionObject}
        />
      ) : (
        ''
      )}
      <ProductBanner theme={theme} className="product-banner">
        <Box theme={theme} className="pb-wrap">
            <Box theme={theme} className="thumbnail">
              {!order_info ? (
                <Skeleton theme={theme} variant="text" width={'100%'} height={'104px'} />
              ) : (
                  <img
                    src={
                      order_info?.order_asset?.photo_urls &&
                      order_info?.order_asset?.photo_urls?.length > 0
                        ? order_info?.order_asset?.photo_urls[0]?.location
                        : ''
                    }
                  />
            )}
          </Box>
          <Box theme={theme} className="product-details">
            <Grid theme={theme} container columnSpacing={2}>
              <Grid theme={theme} item sm={6} xs={12}>
                <Box theme={theme} className="title-md">
                  {order_info?.Vehicle_details?.year} {order_info?.Vehicle_details?.make}{' '}
                  {order_info?.Vehicle_details?.model}
                </Box>
                <Box theme={theme} className="title-lg">
                  {order_info?.Vehicle_details?.trim_description}
                </Box>
                <Box theme={theme} className="title-sm">
                  Deal ID: <b>{order_info?.reference_number}</b>
                  {!isCashDealType && lenderName && (
                    <>
                      , Lender: <b>{lenderName}</b>
                    </>
                  )}
                  {
                    isCashDealType && 
                    <>
                    {' VIN'}: <b>{order_info?.Vehicle_details?.vin}</b>
                    </>
                  }

                </Box>
                {
                  !isCashDealType && 
                  <Box theme={theme} className="title-sm">
                  VIN: <b>{order_info?.Vehicle_details?.vin}</b>
                  {[
                    Status.FullyReceived,
                    Status.AwaitingMore,
                    Status.Approved,
                    Status.Submitted,
                    Status.InReview,
                    Status.AwaitingMore,
                    Status.Completed,
                    Status.Appointment,
                    Status.SchedulePickup,
                    Status.PendingConfirmation,
                    Status.Reviewed,
                    Status.Draft
                  ]?.includes(order_info?.status) && (
                    <>
                      , Odometer{' '}
                      <b>
                        {(order_info?.order_asset.mileage || 0)?.toLocaleString(undefined, {
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0
                        })}
                      </b>
                    </>
                  )}
                  <>
                    {[
                      Status.PendingConfirmation,
                      Status.Reviewed,
                      Status.Draft,
                      Status.InReview
                    ]?.includes(order_info?.status) &&
                      (individualAgreement?.length > 0 ? (
                        <>
                          , Individualized Agreement:{' '}
                          <Link
                            className="link"
                            onClick={() => {
                              setAgreementPopUp(true)
                            }}
                          >
                            View/Edit
                          </Link>
                        </>
                      ) : null)}
                  </>
                </Box>
                }

              </Grid>
              <Grid theme={theme} item sm={6} xs={12} textAlign="right">
                <Box theme={theme} className="actions-wrap">
                  <Box theme={theme} className="action-details">
                    <Box theme={theme} className="title-md">
                      {order_info?.finance_type === FinanceTypes.CASH ? 'Total Payable Amount' : 'Net Financed Amount'}
                    </Box>
                    <Box theme={theme} className="title-lg">
                      {isDraftOrder
                        ? '-'
                        : `
                      ${defaultCurrency}
                      
                      ${totalAmount ? totalAmount?.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      }) : '-'}`}
                    </Box>
                    <Box theme={theme} className="title-sm">
                      {!order_info ? (
                        <Skeleton theme={theme} variant="text" width={'100%'} height={'16px'} />
                      ) : (
                        <>
                          Deal Type: <b>
                            {order_info?.finance_type === FinanceTypes.CASH 
                              ? FinanceTypes.CASH 
                              : `${order_info?.contract_term} Months ${!isDraftOrder ? ` ${order_info?.finance_type}` : ''}`}
                          </b>

                          {order_info?.finance_type !== FinanceTypes.CASH && (
                            <>
                              , Rate: <b>
                                {isDraftOrder
                                  ? '-'
                                  : `${order_info?.apr?.toLocaleString(undefined, {
                                      maximumFractionDigits: 4,
                                      minimumFractionDigits: 4
                                    })} %`}
                              </b>
                            </>
                          )}
                        </>
                      )}
                    </Box>
                    {order_info?.finance_type !== FinanceTypes.CASH ? (
                      <Box theme={theme} className="title-sm">
                      {order_info?.status == Status.SchedulePickup ||
                      order_info?.status == Status.Appointment ||
                      order_info?.status == Status.Completed ? (
                        <>Periodic Payment: </>
                      ) : (
                        <>Est. Periodic Payment: </>
                      )}
                      <b>
                        {isDraftOrder
                          ? '-'
                          : `${defaultCurrency}
                  ${(order_info?.estimated_monthly_payment || 0).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}`}
                      </b>
                    </Box>
                    ) : null}
                  </Box>
                  {(order_info?.status == Status.PendingConfirmation ||
                    order_info?.status == Status.InReservation) && (
                    <Box theme={theme} className="actions">
                      <Button
                        secondary
                        fullWidth
                        theme={theme}
                        onClick={() => {
                          setConfirmationPopupState(true)
                        }}
                        text="Not Available"
                      />
                      <Button
                        primary
                        fullWidth
                        theme={theme}
                        onClick={() => {
                          if (order_info?.status == Status.InReservation) {
                            const updatedOrderPayload = {
                              ...order_info,
                              changeVehicleStatus: false
                            }
                            convertQuotationRequest(updatedOrderPayload, {
                              onSuccess() {
                                setTimeout(() => {
                                  navigate(
                                    `/lane/desking?reference_id=${order_info?.reference_number}&customer_reference_id=${order_info?.customer_info?.reference_id}`
                                  )
                                }, 100)
                              }
                            })
                          } else {
                            if (lenders && lenders?.length > 0) {
                              const defaultLender = lenders?.find(
                                (lender: any) =>
                                  lender.default_lender && !lender.is_already_selected
                              )
                              setSelectedLenders((defaultLender?.id && [defaultLender.id]) || [])
                              openSlModal()
                            }
                          }
                        }}
                        text="Available"
                      />
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
            {dialogOpen && (
              <OrderAssetControl
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                orderObj={order_info}
                setOptionObject={setOptionObject}
              />
            )}
          </Box>
        </Box>
      </ProductBanner>
    </>
  )
}

export default AssetDetails
