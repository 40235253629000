// @ts-nocheck

import { useEffect, useState, useMemo } from 'react'
import Http from '@helpers/http-api'
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query'
import {
  EP_GET_DEALER_FEE,
  EP_DEALER_PREFERENCE,
  EP_GET_FEES,
  EP_DEALER_PREFERENCE_BY_DEALER_CODE,
  EP_SAVE_DEALER_PROFILE,
  EP_DEALER_TRADEIN,
  EP_DEALER_PAYMENT,
  EP_DEALER_PAYMENT_BY_DEALER_ID,
  EP_DEALER_TRADEIN_BY_DEALER_ID,
  EP_SAVE_PEN_DEALER_ID,
  EP_DEALER_CONFIGURATIONS,
  EP_GET_DEALER_PROFILE_BY_ID,
  EP_GET_DEALER_AND_LENDER,
  EP_GET_COMPANY_BY_TENANT_ID,
  DMS_GET_DEALERS,
  EP_GET_DISCLAIMERS_BY_NAME,
  EP_GET_CONFIG_CONTRACT_TEMRS,
  EP_GET_CONFIG_ALLOWED_MILEAGE,
  EP_GET_STATES,
  EP_GET_RATING,
  EP_GET_CURRENCIES,
  EP_CALCULATE_ORDER_QUOTATION,
  EP_GET_PROGRAMS,
  EP_SAVE_ORDER_QUOTATION,
  EP_GET_ORDER_INFORMATION,
  GET_VEHICLE,
  EP_GET_OPTIONS,
  EP_VEHICLE_BY_MODEL_CODE,
  EP_GET_LENDER,
  EP_GET_DEALER_LENDER
} from '@helpers/endpoints'
import { useStoreContext } from '@store/storeContext'
import {
  IDealerPreference,
  IDealerProfile,
  IDealerTradeIn,
  IDealerPayment,
  IDealerConfigurations,
  ICustomer,
  IVehicle
} from '@models'
import { getMarketPlaceToken } from '@helpers/methods'
import { HttpStatus, QueryKeys } from '@helpers/enums'
import { DeskingActionTypes, useDeskingContext } from '@app/desking/desking-context-provider'
import { DEFAULT_RENTAL_FREQUENCY } from '@helpers/constants'
import { InstallationMode } from '@helpers/enums'
import { IOption } from 'controls/addon-control/addon-interface'
import { createPricingRequestPayloadFromState, createV2Key } from '@app/desking/utils'

const token = getMarketPlaceToken()
const headers: any = {
  Authorization: `Bearer ${token}`
}

export const useGetDealerAndLender = (): any => {
  // const { actions } = useStoreContext();
  const { data, isLoading, error, mutate } = useMutation<{ body: any }, any>((body: any) => {
    const apiService = Http.createConnection({
      baseUrl: process.env.API_GATEWAY
    })
    const { email, tenant_id }: any = body
    return apiService.get<any>(
      EP_GET_DEALER_AND_LENDER + `?tenant_id=${tenant_id}&email=${email}`,
      {},
      { headers }
    )
  })
  return { data, isLoading, error, mutate }
}
export const useGetLenderByTenantId = (): any => {
  const { data, isLoading, error, mutate } = useMutation<{ tenant_id: string }, any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.get<any>(`${EP_GET_COMPANY_BY_TENANT_ID}/${body.tenant_id}`, {})
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetDealerFee = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, error, mutate } = useMutation<{}, any>(
    () => {
      const apiService = Http.createConnection()
      return apiService.get<any>(EP_GET_DEALER_FEE)
    },
    {
      onSuccess(_response: any) {
        actions.setDealerFeeInitialData(_response)
      }
    }
  )
  return { data, isLoading, error, mutate }
}

export const useDeleteDealerFeeById = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.delete<any>(`${EP_GET_DEALER_FEE}/${body.id}`)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}
export const useUpdateDealerFeeById = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, mutate } = useMutation<{ body: any }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<any>(`${EP_GET_DEALER_FEE}/${body.id}`, body)
    },
    {
      onSuccess(response: any) {
        actions.updateDealerFeeRow(response)
        actions.setToast({
          toastMessage: 'Data Updated Sucessfully',
          toastState: true
        })
      }
    }
  )
  return { data, isLoading, mutate }
}

export const useGetDealerFeeById = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.get<any>(`${EP_GET_DEALER_FEE}/${body.id}`)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useSaveDealerPreference = (): any => {
  const { data, isLoading, mutate } = useMutation<IDealerPreference, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<IDealerPreference>(`${EP_DEALER_PREFERENCE}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}
export const useGetPreferenceByDealerCode = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, mutate } = useMutation<{ dealer_code: string }, any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.get<any>(
        `${EP_DEALER_PREFERENCE_BY_DEALER_CODE}/${body.dealer_code}`,
        {},
        { headers }
      )
    },
    {
      onSuccess(_response: any) {
        actions.setDealerPreferenceData(_response)
      }
    }
  )
  return { data, isLoading, mutate }
}

export const useUpdateDealerPreference = (): any => {
  const { data, isLoading, mutate } = useMutation<IDealerPreference, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<IDealerPreference>(`${EP_DEALER_PREFERENCE}/${body.id}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetPreference = (): any => {
  const { data, isLoading, error, mutate } = useMutation(
    () => {
      const apiService = Http.createConnection()
      return apiService.get<any>(EP_DEALER_PREFERENCE)
    },
    {
      onSuccess(_response: any) {}
    }
  )
  return { data, isLoading, error, mutate }
}

export const useSaveDealerFee = (): any => {
  const { data, isLoading, mutate } = useMutation<
    {
      id?: number
      fee_name: string
      state_id: number[]
      default_amount: number
      taxable: boolean
      is_active: boolean
      dealer_id: number
    },
    any
  >(
    (body: any) => {
      body.dealer_id = 1
      const apiService = Http.createConnection()
      return apiService.post<any>(`${EP_GET_DEALER_FEE}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetFees = (): any => {
  const { data, isLoading, error, mutate } = useMutation(
    () => {
      const apiService = Http.createConnection({
        baseUrl: process.env.CONFIG_API_GATEWAY
      })
      return apiService.get<any>(EP_GET_FEES)
    },
    {
      onSuccess(_response: any) {}
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetTradeInByDealerId = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.get<any>(`${EP_DEALER_TRADEIN_BY_DEALER_ID}/${body.id}`)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useSaveDealerTradeIn = (): any => {
  const { data, isLoading, mutate } = useMutation<IDealerTradeIn, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<IDealerTradeIn>(`${EP_DEALER_TRADEIN}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useUpdateDealerTradeIn = (): any => {
  //update method
  const { data, isLoading, mutate } = useMutation<IDealerTradeIn, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<IDealerTradeIn>(`${EP_DEALER_TRADEIN}/${body.id}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useSaveDealerProfile = (): any => {
  const { data, isLoading, mutate } = useMutation<IDealerProfile, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<IDealerProfile>(`${EP_SAVE_DEALER_PROFILE}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}
export const useGetProfilebyDealerId = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.get<any>(`${EP_SAVE_DEALER_PROFILE}/${body.id}`)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}
export const useUpdateDealerProfilebyId = (): any => {
  const { data, isLoading, mutate } = useMutation<IDealerProfile, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<IDealerProfile>(`${EP_SAVE_DEALER_PROFILE}/${body.id}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useUpdatePenDealerId = (): any => {
  const { data, isLoading, mutate } = useMutation<IDealerProfile, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<IDealerProfile>(
        `${EP_SAVE_PEN_DEALER_ID}/${body.id}/${body.pen_dealer_id}`,
        body
      )
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}
export const useSaveDealerPayment = (): any => {
  const { data, isLoading, mutate } = useMutation<IDealerPayment, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<IDealerPayment>(`${EP_DEALER_PAYMENT}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetPaymentByDealerId = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.get<any>(`${EP_DEALER_PAYMENT_BY_DEALER_ID}/${body.id}`)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetDealerProfileById = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.get<any>(
        `${EP_GET_DEALER_PROFILE_BY_ID}/${body.dealer_id}`,
        {},
        { headers }
      )
    },
    {
      onSuccess(_response: any) {
        actions.setDealerProfileData(_response)
      }
    }
  )
  return { data, isLoading, mutate }
}

export const useUpdateDealerPaymentById = (): any => {
  const { data, isLoading, mutate } = useMutation<{ body: any }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<any>(`${EP_DEALER_PAYMENT}/${body.id}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useSaveDealerConfigurations = (): any => {
  const { data, isLoading, mutate } = useMutation<IDealerConfigurations, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<IDealerConfigurations>(`${EP_DEALER_CONFIGURATIONS}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetAllDealerFeebyState = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, mutate, mutateAsync } = useMutation<
    { dealer_id: number; state_code: any },
    any
  >(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      body.dealer_id ? `${body.dealer_id}` : ''
      return apiService.get<any>(
        `${`dealer/fee/dealer-code`}/${body.dealer_id}?state_code=${body.state_code}`,
        {},
        { headers }
      )
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate, mutateAsync }
}

export const useGetDealers = (entity_type: string) => {
  return useQuery<any, Error>(
    ['entities', entity_type],
    () => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      const url = `${DMS_GET_DEALERS}?entity_type=${entity_type}`
      return apiService.get<any>(url)
    },
    {
      enabled: !!entity_type
    }
  )
}

export const useGetDisclaimersDataByName = (name: string) => {
  return useQuery(
    [QueryKeys.EP_GET_DISCLAIMERS_BY_NAME, name],
    async () => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.get<any>(`${EP_GET_DISCLAIMERS_BY_NAME}/${name}`, {}, { headers })
    },
    { keepPreviousData: true, enabled: !!name }
  )
}

export const useGetCompanyConfigurations = (companyId: string) => {
  const { states, actions } = useStoreContext()

  const stateId = states?.dealDesk?.dealerProfile?.dealer_address?.state_id
  const currencyId = states?.lenderInfo?.default_currency

  const apiService = Http.createConnection({
    baseUrl: process.env.API_GATEWAY
  })

  const queries = useQueries([
    {
      queryKey: ['COMPANY_CONFIGURATIONS', 'contractTerms', companyId],
      queryFn: async () => {
        headers.company_id = companyId
        const response = await apiService.get<any>(EP_GET_CONFIG_CONTRACT_TEMRS, {}, { headers })
        return response
      },
      onSuccess: (data: any[]) => {
        actions.setContractTermsAndOptions(data)
      },
      enabled: !!companyId
    },
    {
      queryKey: ['COMPANY_CONFIGURATIONS', 'allowedMillage', companyId],
      queryFn: async () => {
        headers.company_id = companyId
        const response = await apiService.get<any>(EP_GET_CONFIG_ALLOWED_MILEAGE, {}, { headers })
        return response
      },
      onSuccess: (data: any[]) => {
        actions.setAllowedMilleageAndOptions(data)
      },
      enabled: !!companyId
    },
    {
      queryKey: ['COMPANY_CONFIGURATIONS', 'states'],
      queryFn: async () => {
        const response = await apiService.get<any>(EP_GET_STATES)
        return response
      },
      enabled: !!states?.dealDesk?.dealerProfile?.dealer_address?.state_id,
      onSuccess: (states: any[]) => {
        const stateCode = states.find((st) => st.id === stateId)?.code
        if (stateCode) {
          actions.setStateCode(stateCode)
        }
      }
    },
    {
      queryKey: ['COMPANY_CONFIGURATIONS', 'creditRatings', companyId],
      queryFn: async () => {
        headers.company_id = companyId
        const response = await apiService.get<any>(EP_GET_RATING, {}, { headers })
        return response
      },
      enabled: !!companyId,
      onSuccess: (creditRatings: any[]) => {
        actions.setCreditTerms(creditRatings)
      }
    },
    {
      queryKey: ['COMPANY_CONFIGURATIONS', 'currencies', companyId],
      queryFn: async () => {
        headers.company_id = companyId
        const response = await apiService.get<any>(EP_GET_CURRENCIES, {}, { headers })
        return response
      },
      onSuccess: (currencies: any[]) => {
        const defaultCurrency = currencies.find((cr) => cr.id === currencyId)
        if (defaultCurrency) {
          actions.setDefaultCurrency(defaultCurrency)
        }
      },
      enabled: !!companyId && !!currencyId,
      keepPreviousData: true
    }
  ])

  const isLoading = queries.some((query) => query.isLoading)
  const data = queries.map((query) => query.data)
  const errors = queries.map((query) => query.error)

  return { data, isLoading, errors, queries }
}

export const useGetDealerData = (dealerCode: string, stateCode: string, financeType: string) => {
  const { actions } = useStoreContext()
  const apiService = Http.createConnection({
    baseUrl: process.env.API_GATEWAY
  })

  const queries = useQueries([
    {
      queryKey: ['DEALER', 'dealerProfile', dealerCode],
      queryFn: async () => {
        const response = await apiService.get<any>(
          `${EP_GET_DEALER_PROFILE_BY_ID}/${dealerCode}`,
          {},
          { headers }
        )
        return response
      },
      onSuccess: (data: any) => {
        actions.setDealerProfile(data)
      },
      enabled: !!dealerCode
    },
    {
      queryKey: ['DEALER', 'dealerPreference', dealerCode],
      queryFn: async () => {
        const response = await apiService.get<any>(
          `${EP_DEALER_PREFERENCE_BY_DEALER_CODE}/${dealerCode}`,
          {},
          { headers }
        )
        return response
      },
      onSuccess: (data: any) => {
        actions.setDealerPreferences(data)
      },
      enabled: !!dealerCode
    },
    {
      queryKey: ['DEALER', 'dealerFees', dealerCode, stateCode],
      queryFn: async () => {
        const response = await apiService.get<any>(
          `${`dealer/fee/dealer-code`}/${dealerCode}?state_code=${stateCode}`,
          {},
          { headers }
        )
        return response
      },
      onSuccess: (data: any[]) => {
        actions.setDealerFees(data)
      },
      enabled: !!dealerCode && !!stateCode
    },
    {
      queryKey: ['DEALER', 'lender', dealerCode],
      queryFn: async () => {
        const apiService = Http.createConnection({
          baseUrl: process.env.CONFIG_API_GATEWAY
        })
        const response = await apiService.get<any>(
          `${EP_GET_DEALER_LENDER}/${dealerCode}?finance_type=${financeType}`
        )
        return response
      },
      onSuccess: (lenders: any[]) => {
        actions.setLendersData(lenders)
      },
      enabled: !!financeType
    }
  ])

  const isLoading = queries.some((query) => query.isLoading)
  const data = queries.map((query) => query.data)
  const errors = queries.map((query) => query.error)

  return { data, isLoading, errors }
}

export const useGetProgramInformation = (
  vin: string | undefined,
  submission_tier: string | undefined,
  index: number
) => {
  const {
    states: {
      lendersData,
      lenderInfo: { company_code },
      dealerInfo: { company_id, id, dealer_code },
      dealDesk: { allowedMillages, dealerPreferences, contractTerms, creditTerms }
    }
  } = useStoreContext()
  const { state, dispatch } = useDeskingContext()
  const { customer, vehiclesData, lender_id } = state
  const creditRating =
    ((customer as ICustomer)?.credit_rating ?? '').length > 0
      ? (customer as ICustomer)?.credit_rating
      : creditTerms?.[0]?.description
  const lenderCode = lendersData?.filter((lender: any) => lender.id === lender_id)[0]?.external_code

  return useQuery(
    ['VehicleData', 'Programs', vin, creditRating, dealer_code, company_code, lenderCode],
    async () => {
      dispatch({
        type: DeskingActionTypes.RESET_CALCULATIONS,
        payload: {
          vinForCalculationsToReset: vin as string,
          indexToReset: index
        }
      })

      const payload = {
        company_code: company_code,
        dealer_code: dealer_code,
        lender_code: lenderCode,
        credit_rating: creditRating,
        make_name: vehiclesData.get(vin)?.vehicle?.make,
        model_name: vehiclesData.get(vin)?.vehicle?.model,
        model_code: vehiclesData.get(vin)?.vehicle?.model_code,
        model_detail_name: vehiclesData.get(vin)?.vehicle?.trim_description,
        rental_frequency: DEFAULT_RENTAL_FREQUENCY,
        rental_mode: null,
        annual_usage: allowedMillages.filter(
          (mileage: any) => mileage.id === dealerPreferences?.default_mileage_id
        )[0]?.value,
        terms: contractTerms?.filter(
          (term: any) => term.id == dealerPreferences?.default_term_id
        )?.[0]?.term,
        retrieve_all_programs: false,
        vin: vin,
        odometer: vehiclesData.get(vin)?.vehicle?.mileage,
        selling_price: vehiclesData.get(vin)?.vehicle?.internet_price,
        msrp: vehiclesData.get(vin)?.vehicle?.msrp,
        is_new: vehiclesData.get(vin)?.vehicle?.type?.toLowerCase() === 'new',
        total_score: creditTerms?.filter((rating: any) => rating.description == creditRating)?.[0]
          ?.score_from,
        annual_mileage: allowedMillages.filter(
          (mileage: any) => mileage.id === dealerPreferences?.default_mileage_id
        )[0]?.value,
        tier: submission_tier ?? undefined
      }

      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.post<any>(`${EP_GET_PROGRAMS}`, payload)
    },
    {
      enabled:
        !!vin &&
        !!company_code &&
        !!dealer_code &&
        !!lender_id &&
        !!lenderCode &&
        (!!customer?.credit_rating || creditTerms?.[0]?.description) &&
        !!Object.keys(vehiclesData.get(vin)?.vehicle ?? {}).length,
      retry: (_, error: any) => {
        if (Number(error?.error?.response?.status) === HttpStatus.Bad_Request) {
          return false
        }
        return true
      }
    }
  )
}

export const useGetPricingCalculation = (
  vin: string,
  rowIndex: number,
  colIndex: number,
  comparison: string
) => {
  const { states } = useStoreContext()
  const { state } = useDeskingContext()
  const queryClient = useQueryClient()

  const pricingPayload = useMemo(() => {
    return createPricingRequestPayloadFromState(
      state,
      states?.dealDesk,
      rowIndex,
      colIndex,
      vin ?? ''
    )
  }, [state, states?.dealDesk, rowIndex, colIndex, vin])

  const { apr, credit_rating, dealer_id, lender_id, finance_type, terms, per_unit_selling_price } =
    pricingPayload

  const enabled =
    !!vin &&
    !!dealer_id &&
    !!lender_id &&
    !!credit_rating &&
    !!finance_type &&
    !!apr &&
    !!terms &&
    !!per_unit_selling_price

  return useQuery(
    createV2Key(vin, comparison, pricingPayload),
    async () => {
      const apiService = Http.createConnection()
      return apiService.post(`${EP_CALCULATE_ORDER_QUOTATION}`, pricingPayload, { headers })
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      enabled,
      retry: 8,
      onSuccess: (calculationData) => {
        const key = createV2Key(vin, comparison, {
          ...pricingPayload,
          tspk: calculationData?.tax_amount
        })
        queryClient.setQueryData(key, { ...calculationData, tspk: calculationData?.tax_amount })
      }
    }
  )
}

export const useSaveOrderRequest = (): any => {
  const { data, isLoading, mutate, error } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<any>(`${EP_SAVE_ORDER_QUOTATION}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate, error }
}

export const useGetOrder = (reference_number: string | undefined | null) => {
  const {
    states: {
      dealDesk: { contractTermOptions, milleageOptions }
    }
  } = useStoreContext()
  const { dispatch } = useDeskingContext()

  return useQuery(
    ['Order', reference_number],
    async () => {
      const apiService = Http.createConnection()
      return await apiService.get(`${EP_GET_ORDER_INFORMATION}/${reference_number}`)
    },
    {
      enabled: !!reference_number && !!contractTermOptions.length && !!milleageOptions.length
    }
  )
}

export const useGetVehicle = (vin: string | undefined) => {
  const apiService = Http.createConnection({
    baseUrl: process.env.CONFIG_API_GATEWAY
  })

  return useQuery({
    queryKey: ['VehicleData', vin],
    queryFn: async () => {
      const response = await apiService.get(`${GET_VEHICLE}/${vin}`)
      return response
    },
    enabled: !!vin
  })
}

export const useGetVehicleOptions = (
  vin: string | undefined,
  dealer_code: string | undefined,
  model_name: string | undefined
) => {
  const apiService = Http.createConnection({
    baseUrl: process.env.API_GATEWAY
  })

  return useQuery({
    queryKey: ['VehicleData', 'VehicleOptions', vin, dealer_code, model_name],
    queryFn: async () => {
      const response = await apiService.get(
        `${EP_GET_OPTIONS}?dealer_code=${dealer_code}&model_name=${model_name}`
      )
      return response
    },
    enabled: !!vin && !!dealer_code && !!model_name
  })
}

export const useGetVehicleData = (vin: string | undefined) => {
  const {
    states: { dealDesk }
  } = useStoreContext()
  const { state, dispatch } = useDeskingContext()

  const vehicleQuery = useGetVehicle(vin)
  const {
    data: vehicleData,
    isLoading: vehicleDataLoading,
    isSuccess: vehicleDataSuccess
  } = vehicleQuery

  const vehicleOptionsQuery = useGetVehicleOptions(
    vin,
    (vehicleData as IVehicle)?.dealer_code ?? '',
    (vehicleData as IVehicle)?.model ?? ''
  )
  const {
    data: vehicleOptions,
    isLoading: vehicleOptionsLoading,
    isSuccess: vehicleOptionsSuccess
  } = vehicleOptionsQuery

  const allSuccess = vehicleDataSuccess && vehicleOptionsSuccess
  const allLoading = vehicleDataLoading || vehicleOptionsLoading

  useEffect(() => {
    if (allSuccess) {
      const dealerPreinstalledOptions = state?.vehiclesData?.get(
        (vehicleData as IVehicle).vin
      ).preInstalledDealerOptions
      const preInstalledOptions = (vehicleOptions ?? []).filter((opt: any) =>
        dealerPreinstalledOptions?.includes(opt.id)
      )
      dispatch({
        type: DeskingActionTypes.ADD_VEHICLE,
        payload: {
          vin: (vehicleData as IVehicle).vin,
          vehicle: vehicleData,
          ...(Object.keys(state?.order?.reference_number ?? '').length === 0
            ? { fni: [] }
            : { fni: state?.vehiclesData?.get((vehicleData as IVehicle).vin)?.fni }),
          ...(Object.keys(state?.order?.reference_number ?? '').length === 0
            ? { dealer_options: preInstalledOptions }
            : { dealer_options: state.order.order_options }),
          ...(Object.keys(state?.order?.reference_number ?? '').length === 0
            ? { fees: dealDesk.dealerFees }
            : { fees: state?.vehiclesData?.get((vehicleData as IVehicle).vin)?.fees }),
          ...{ program: state?.vehiclesData?.get((vehicleData as IVehicle).vin)?.program ?? [] }
        }
      })
    }
  }, [allSuccess, vehicleData, vehicleOptions])

  return {
    vehicleQuery,
    vehicleOptionsQuery,
    data: { vehicleData, vehicleOptions },
    allSuccess,
    allLoading
  }
}

export const useDebouncedState = <T>(
  initialValue: T,
  delay = 800
): [T, React.Dispatch<React.SetStateAction<T>>, T] => {
  const [state, setState] = useState<T>(initialValue)
  const [debouncedValue, setDebouncedValue] = useState<T | null>(null)

  useEffect(() => {
    setState(initialValue)
  }, [initialValue])

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(state)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [state, delay])

  return [debouncedValue, setState, state]
}
