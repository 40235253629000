import { useMutation } from 'react-query'
import Http from '@helpers/http-api'
import {
  EP_GET_CHECK_S3_CREDENTIALS,
  EP_GET_BUCKET_UPLOAD_FILE,
  EP_GET_BUCKET_DELETE_FILE,
  EP_UPLOAD_GENERAL_FILE
} from '@helpers/endpoints'

export const useMutation_CheckS3Credentials = (): any => {
  const { data, isLoading, error, mutate } = useMutation<{}, any>((body: any) => {
    const apiService = Http.createConnection({
      baseUrl: process.env.CONFIG_API_GATEWAY
    })
    return apiService.get<any>(
      `${EP_GET_CHECK_S3_CREDENTIALS}/${body}`,
      {},
      {
        headers: {
          company_id: body?.company_id
        }
      }
    )
  })
  return { data, isLoading, error, mutate }
}

export const UseMutation_uploadS3Image = (): any => {
  const { data, isLoading, error, mutate } = useMutation<{}, any>((body: any) => {
    const apiService = Http.createConnection({
      baseUrl: process.env.CONFIG_API_GATEWAY
    })
    return apiService.post<any>(`${EP_GET_BUCKET_UPLOAD_FILE}/${body?.tenant_id}`, body.files, {
      headers: {
        company_id: body?.company_id
      }
    })
  })
  return { data, isLoading, error, mutate }
}

export const UseMutation_deleteS3Image = (): any => {
  const { data, isLoading, error, mutate } = useMutation<{}, any>((body: any) => {
    const apiService = Http.createConnection({
      baseUrl: process.env.CONFIG_API_GATEWAY
    })
    return apiService.delete<any>(
      `${EP_GET_BUCKET_DELETE_FILE}/${body.fileKey}/${body?.tenant_id}`,
      {
        headers: {
          company_id: body?.company_id
        }
      }
    )
  })
  return { data, isLoading, error, mutate }
}

export const useUploadImage = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<any>(`${EP_UPLOAD_GENERAL_FILE}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}
