import { styled } from '@mui/material'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const DeskingWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.desking-wrap': {
    '.dsk-disabled': {
      opacity: 0.3,
      pointerEvents: 'none',
      userSelect: 'none'
    },
    '.text-overflow-wrap .MuiTypography-root': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.u-form-group': {
      '.u-form-control, .u-form-control.u-select': {
        height: 32,
        backgroundColor: theme.customVariables.tableStripedBg,
        transition: 'all 0.3s',
        '.MuiInputBase-input': {
          padding: '4px 8px',
          fontSize: 12,
          maskImage: 'none',
          borderRadius: theme.shape.borderRadius
        },
        '.MuiSelect-icon': {
          height: 16,
          width: 16,
          right: 8
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent'
        },
        '&.Mui-error': {
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.error.main
          },
          '&:hover': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.error.main
            }
          }
        },
        '+ .MuiFormHelperText-root': {
          display: 'none'
        },
        '.MuiPaper-root.MuiMenu-paper': {
          top: '47px!important',
          '.MuiList-root.MuiMenu-list': {
            '.MuiButtonBase-root.u-dropdown-item': {
              padding: 8,
              fontSize: theme.typography.caption.fontSize
            }
          }
        },
        'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
          {
            boxShadow: '0 0 0 30px inset' + theme.customVariables.tableStripedBg + '!important'
          },
        '&:hover': {
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'inherit'
          }
        }
      },
      '.MuiInputBase-adornedStart, .MuiInputBase-adornedEnd': {
        '.adornment-icon': {
          fontSize: theme.typography.caption.fontSize,
          color: theme.palette.grey[400]
        }
      },
      '.MuiInputBase-adornedStart': {
        paddingLeft: 8,
        '.adornment-icon': {
          fontSize: theme.typography.caption.fontSize,
          marginRight: '4px !important'
        },
        '.MuiInputBase-input': {
          padding: '6px 8px 6px 4px !important',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0
        }
      },
      '.MuiInputBase-adornedEnd': {
        paddingRight: 8,
        '.adornment-icon': {
          marginLeft: '4px !important'
        },
        '.MuiInputBase-input': {
          padding: '6px 4px 6px 8px !important',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0
        }
      },
      '&.u-date-picker': {
        '.u-form-control': {
          height: 25,
          borderRadius: theme.shape.borderRadius,
          '.MuiInputBase-root': {
            padding: 0,
            '.MuiInputBase-input': {
              maskImage: 'none'
            },
            '.MuiInputAdornment-root': {
              position: 'absolute',
              width: '100%',
              margin: 0,
              '.MuiButtonBase-root': {
                width: '100%',
                display: 'block',
                padding: 0,
                height: 28,
                svg: {
                  display: 'none'
                }
              }
            }
          }
        }
      }
    },
    '.u-form-text-right': {
      '.u-form-group': {
        '.u-form-control, .u-form-control.u-select': {
          '.MuiInputBase-input': {
            textAlign: 'right'
          }
        },
        '&.u-date-picker': {
          '.u-form-control': {
            '.MuiInputBase-root.MuiInputBase-adornedEnd': {
              '.MuiInputBase-input.MuiInputBase-inputAdornedEnd': {
                padding: '3px 4px !important'
              }
            }
          }
        }
      }
    },
    '.relative-card': {
      position: 'relative',
      overflow: 'hidden',
      '&::before': {
        content: "''",
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.16)',
        width: '100%',
        height: '100%'
      },
      '.absolute-loader': {
        position: 'absolute',
        top: 16,
        right: 8,
        '.loader-wrap .loader': {
          transform: 'translateY(0)',
          position: 'static'
        }
      }
    },
    ///////////////////// Finance/Lease Header Wrap ///////////////////////
    '.fl-header-wrap': {
      paddingBottom: '8px',
      marginBottom: '16px',
      borderBottom: '1px solid' + theme.palette.grey[50],
      '.fl-action-btn': {
        width: '100%',
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        '.btn': {
          padding: '12px 16px',
          fontSize: theme.typography.subtitle2.fontSize,
          lineHeight: theme.typography.subtitle2.lineHeight,
          marginRight: '8px',
          '&.MuiButton-sizeSmall': {
            height: 40,
            lineHeight: '16px'
          },
          ':last-child': {
            marginRight: 0
          }
        }
      },
      [theme.breakpoints.down('md')]: {
        '.btn-tabs': {
          width: '100%',
          '.btn': {
            flexWrap: 'nowrap',
            flexGrow: 1,
            '&:last-of-type': {
              width: 'auto !important'
            }
          }
        },
        '.fl-action-btn': {
          justifyContent: 'center'
        }
      }
    },
    ///////////////////// Finance/Lease Calculation Wrap ///////////////////////
    '.flc-wrap': {
      '.amount': {
        padding: '8px !important',
        borderRadius: theme.shape.borderRadius,
        width: '100%',
        color: theme.palette.common.black + '!important',
        '&.btn': {
          fontSize: '3vh',
          minWidth: '2vh',
          fontWeight: theme.typography.fontWeightBold,
          color: theme.palette.grey[900],

          span: {
            fontSize: '1.8vh',
            fontWeight: theme.typography.fontWeightRegular,
            marginRight: 5,
            color: theme.palette.grey[600],
            transition: 'all 0.3s'
          },

          '&:hover, &:focus, &:active, &.selected': {
            backgroundColor: theme.palette.grey[600],
            color: theme.palette.common.white + '!important',
            span: {
              color: theme.palette.common.white
            }
          },

          '&:active, &.selected': {
            backgroundColor: theme.palette.grey[700],
            span: {
              color: theme.palette.common.white
            },
            '.MuiSkeleton-root': {
              backgroundColor: 'rgba(255,255,255,0.15)'
            }
          }
        },
        '&.relative-card': {
          '.absolute-loader': {
            '.loader-wrap .loader': {
              width: 24,
              height: 24
            }
          }
        }
      },
      '.flc-ul': {
        padding: 0,
        margin: 0,
        listStyle: 'none',
        width: '100%',

        '.flc-li': {
          padding: 0,
          margin: '0 0 8px 0',
          listStyle: 'none',
          width: '100%',
          display: 'flex',
          alignItems: 'flex-start',
          alignContent: 'flex-start',
          gap: 8,

          '.flc-li-left-col': {
            width: 'calc(100% - 70%)'
          },

          '.flc-li-right-col': {
            display: 'flex',
            gap: 8
          },

          '.flc-li-selfStretch-col': {
            flex: '1',
            alignSelf: 'stretch',
            '.styled-tooltip': {
              height: '100%',
              '& > div': {
                height: '100%',
                '.btn': {
                  height: '100%'
                }
              }
            }
          },

          '&.full-width': {
            '.flc-li-left-col': {
              width: '100%'
            }
          },

          '&.list-view': {
            '.btn.amount, .MuiSkeleton-root': {
              height: 64
            }
          },
          '&.single-product': {
            minHeight: 80,
            alignItems: 'center',
            '.flc-li-left-col .flc-labels-wrap .label-row': {
              '.label': {
                width: '100%'
              }
            }
          },
          '.btn-add-wrap .btn': {
            display: 'inline-block',
            textAlign: 'center',
            width: '100%',
            minHeight: 120,
            '&:before': {
              left: 0,
              top: 0,
              height: '100%'
            },
            '.MuiButton-startIcon': {
              display: 'block',
              margin: 0
            }
          }
        }
      },
      '.flc-labels-wrap': {
        width: '100%',
        display: 'inline-block',
        '.label-row': {
          display: 'flex',
          alignItems: 'center',
          height: 32,
          width: '100%',
          marginBottom: 2,
          '&:last-child': {
            marginBottom: 0
          },
          '.label': {
            width: 48
          },
          '.MuiTypography-caption': {
            color: theme.palette.grey[600]
          },
          '.amount, .u-form-group, .styled-tooltip': {
            flex: '1',
            marginLeft: 4
          },
          '.styled-tooltip': {
            marginLeft: 0
          },
          '.amount': {
            marginTop: -2
          },
          '.btn': {
            padding: 5,
            backgroundColor: theme.customVariables.tableStripedBg,
            marginLeft: 5,
            width: 32,

            '&:hover': {
              backgroundColor: theme.palette.grey[900]
            },
            '&:focus': {
              backgroundColor: theme.palette.primary.main
            },
            '&.with-icon.icon-only': {
              svg: {
                height: 18
              },
              '&:hover, &:focus': {
                'svg path': {
                  stroke: theme.palette.common.white
                }
              }
            }
          }
        }
      },

      '.form-text-center': {
        '.u-form-group .u-form-control .MuiInputBase-input': {
          textAlign: 'center !important'
        }
      },

      '.flc-footer': {
        '.amount': {
          padding: '8px 0',
          borderRadius: 0
        },
        '&.border-bottom, .border-bottom': {
          borderBottom: '1px solid' + theme.palette.divider
        },
        '&.full-width': {
          justifyContent: 'center',
          textAlign: 'center',
          paddingBottom: 8,
          gap: 0,
          '.MuiTypography-caption': {
            marginRight: 5,
            color: theme.palette.grey[600]
          },
          '.amount': {
            padding: '0 !important',
            width: 'auto',
            textAlign: 'initial'
          }
        }
      }
    },
    ///////////////////// Vehicle Amount Wrap ///////////////////////
    '.vehicle-amount-wrap': {
      padding: '8px',
      marginBottom: '8px',
      borderRadius: 8,
      textAlign: 'center',
      '.u-custom-chip': {
        borderRadius: 8,
        height: 'auto',
        marginBottom: '8px',
        '.MuiChip-label': {
          fontSize: '12px',
          textTransform: 'uppercase',
          padding: '2px 8px'
        }
      }
    }
  }
}))
