import { FC, useEffect, useMemo, useState } from 'react'
import { useTheme } from '@mui/material'
import {
  Select,
  unityTheme,
  Typography,
  Radio,
  FileDragDrop,
  FilePreview,
  Grid,
  Input,
  Button,
  Dialog
} from '@ntpkunity/controls'
import { createFileUploadUtils, validation } from '@helpers/methods'
import { Controller, useForm } from 'react-hook-form'
import { IOrderTradeIn } from '@models'
import { TradeInControlProps } from './trade-in-control.types'
import { useStoreContext } from '@store/storeContext'

const TradeInControl: FC<TradeInControlProps> = ({
  tradeInData,
  selectTradeInObj,
  setTradeInOpenDialog,
  openTradeInDialog
}) => {
  const theme = useTheme()
  const { states } = useStoreContext()
  const defaultCurrency = states?.dealDesk?.defaultCurrency?.symbol || ''
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    clearErrors,
    formState: { errors }
  } = useForm<IOrderTradeIn>({
    defaultValues: tradeInData
  })

  const [imageFiles, setImageFiles] = useState<any[]>([])

  const fileUploadUtils = useMemo(
    () =>
      createFileUploadUtils(
        'asset_image',
        {
          setValue,
          getValues,
          setImageFiles,
          clearErrors
        },
        true
      ),
    [setValue, getValues, setImageFiles, clearErrors]
  )

  useEffect(() => {
    const assetImages: any[] = getValues('asset_image') || []
    const trim = getValues('trim') || ''
    const make = getValues('make') || ''
    const model = getValues('model') || ''

    const assetImageForPreview = assetImages.map((file: string, index: number) => {
      const filename = `${trim}_${make}_${model}_Image_${index + 1}`

      return {
        document: file,
        name: filename
      }
    })
    setValue('asset_image', assetImageForPreview as any)
    setImageFiles(assetImageForPreview)
  }, [getValues])

  const handleTradeInClose = () => {
    setTradeInOpenDialog(false)
  }

  const onSubmit = (data: any) => {
    setTradeInOpenDialog(false)
    const { asset_image, ...rest } = data
    const formData = {
      ...rest,
      asset_image:
        asset_image?.map((image: any) => {
          if (image && image.document) {
            return image.document.split('/')[0] === 'data:image'
              ? image.document
              : 'data:image/png;base64,' + image.document
          }
          return null
        }) || []
    }
    selectTradeInObj(formData)
  }

  const loanLeaseBalanceExist = watch('loan_lease_balance')

  return (
    <>
      <Dialog
        variant={undefined}
        size="lg"
        title="Trade-In"
        open={openTradeInDialog}
        onCloseDialog={handleTradeInClose}
        customFooter={
          <Button theme={theme} primary text="Continue" onClick={handleSubmit(onSubmit)} />
        }
        theme={theme}
        children={
          <>
            <Grid theme={theme} container rowSpacing={3} columnSpacing={3}>
              <Grid theme={theme} item xs={12} sm={6}>
                <Controller
                  name="year"
                  control={control}
                  rules={{
                    minLength: {
                      value: 4,
                      message: 'Invalid Year'
                    },
                    maxLength: {
                      value: 4,
                      message: 'Invalid Year'
                    },
                    required: {
                      value: true,
                      message: 'Year is required'
                    }
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      theme={unityTheme}
                      fullWidth
                      label="Year"
                      placeholder=" Add here.."
                      type="text"
                      min={0}
                      max={9999}
                      value={value}
                      onChange={(e) => {
                        onChange(
                          e
                            .replace(/[^0-9]/gi, '')
                            .replace(/\+|-/gi, '')
                            .slice(0, 4)
                        )
                      }}
                      error={errors?.year?.message}
                    ></Input>
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={12} sm={6}>
                <Controller
                  name="make"
                  control={control}
                  rules={validation('Make', true)}
                  render={({ field }) => (
                    <Input
                      theme={unityTheme}
                      fullWidth
                      label="Make"
                      placeholder="Add here . . "
                      type="text"
                      {...field}
                      error={errors?.make?.message}
                    ></Input>
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={12} sm={6}>
                <Controller
                  name="model"
                  control={control}
                  rules={validation('Model', true)}
                  render={({ field }) => (
                    <Input
                      label="Model"
                      theme={theme}
                      fullWidth
                      {...field}
                      placeholder={' Add here..'}
                      type="text"
                      error={errors?.model?.message}
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={12} sm={6}>
                <Controller
                  name="trim"
                  control={control}
                  render={({ field }) => (
                    <Input
                      label="Trim (Optional)"
                      theme={theme}
                      fullWidth
                      {...field}
                      placeholder={' Add here..'}
                      type="text"
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={12} sm={6}>
                <Controller
                  name="mileage"
                  control={control}
                  rules={validation('Current Mileage', true)}
                  render={({ field }) => (
                    <Input
                      label="Current Mileage (Odometer)"
                      theme={theme}
                      fullWidth
                      {...field}
                      placeholder={' Add here..'}
                      type="decimal"
                      masking={true}
                      maskNumeric={true}
                      maskDecimalScale={0}
                      error={errors?.mileage?.message}
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={12} sm={6}>
                <Controller
                  name="vin"
                  control={control}
                  rules={{
                    validate: (value) => {
                      if (value && (value.length < 17 || value.length > 17)) {
                        return 'VIN must be exactly 17 characters long'
                      }
                      return true
                    }
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      label="VIN (Optional)"
                      theme={theme}
                      fullWidth
                      onChange={(e) => {
                        onChange(
                          e
                            .replace(/[^A-Z0-9]/gi, '')
                            .toUpperCase()
                            .slice(0, 17)
                        )
                      }}
                      value={value}
                      placeholder={' Add here..'}
                      type="text"
                      error={errors?.vin?.message}
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={12} sm={6}>
                <Controller
                  name="condition"
                  control={control}
                  rules={validation('Condition', true)}
                  render={({ field }) => (
                    <Select
                      label="Condition"
                      theme={theme}
                      items={[
                        { text: 'Fair', value: 'Fair' },
                        { text: 'Good', value: 'Good' },
                        { text: 'Very Good', value: 'Very Good' },
                        { text: 'Excellent', value: 'Excellent' }
                      ]}
                      {...field}
                      value={field.value}
                      sxProps={''}
                      onChange={(e) => {
                        field.onChange(e)
                      }}
                      selectError={errors?.condition?.message}
                    ></Select>
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={12} sm={6}>
                <Controller
                  name="trade_in_amount"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Trade Amount is required'
                    },
                    pattern: {
                      value: /^\d*(\.\d{0,2})?$/,
                      message: 'Only two decimal allowed'
                    }
                  }}
                  render={({ field }) => (
                    <Input
                      label="Trade-In Amount"
                      theme={theme}
                      fullWidth
                      startAdornment={defaultCurrency}
                      {...field}
                      min={0}
                      placeholder={' Add here..'}
                      type="decimal"
                      error={errors?.trade_in_amount?.message}
                      masking={true}
                      maskNumeric={true}
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={12} sm={6}>
                <Typography variant="subtitle1" component={'div'} theme={theme} sx={{ mb: 1 }}>
                  Is asset titled in customer's name?
                </Typography>
                <Controller
                  name="is_customer_titled"
                  control={control}
                  rules={{
                    required: {
                      value: getValues('is_customer_titled') == (undefined || null),
                      message: 'Is asset titled in customers name is required'
                    }
                  }}
                  render={({ field: { value, onChange } }) => (
                    <Radio
                      onChange={onChange}
                      value={value}
                      options={[
                        {
                          label: 'Yes',
                          value: true
                        },
                        {
                          label: 'No',
                          value: false
                        }
                      ]}
                      radioError={errors?.is_customer_titled?.message}
                      row
                      theme={theme}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={12} sm={6}>
                <Typography variant="subtitle1" component={'div'} theme={theme} sx={{ mb: 1 }}>
                  Does the customer have lease/loan balance?
                </Typography>

                <Controller
                  name="loan_lease_balance"
                  control={control}
                  rules={{
                    required: {
                      value: getValues('loan_lease_balance') == (undefined || null),
                      message: 'Does the customer have lease/loan balance is required'
                    }
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Radio
                      options={[
                        {
                          label: 'Yes',
                          value: true
                        },
                        {
                          label: 'No',
                          value: false
                        }
                      ]}
                      radioError={errors?.loan_lease_balance?.message}
                      row
                      value={value}
                      onChange={(e) => {
                        onChange(e === 'true')
                        setValue('lender_contract_number', '')
                        setValue('lender_name', '')
                        setValue('lender_address', '')
                        setValue('lender_contact_number', '')
                        setValue('payment_type', '')
                        setValue('payoff_amount', 0)
                      }}
                      theme={theme}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              {loanLeaseBalanceExist && (
                <>
                  <Grid theme={theme} item xs={12} sm={6}>
                    <Controller
                      name="lender_contract_number"
                      control={control}
                      rules={validation('Contract No. / Credit Reference', true)}
                      render={({ field }) => (
                        <Input
                          label="Contract No. / Credit Reference"
                          theme={theme}
                          fullWidth
                          {...field}
                          placeholder={' Add here..'}
                          type="text"
                          error={errors?.lender_contract_number?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid theme={theme} item xs={12} sm={6}>
                    <Controller
                      name="lender_name"
                      control={control}
                      rules={validation('Lender Name', true)}
                      render={({ field }) => (
                        <Input
                          label="Lender Name"
                          theme={theme}
                          fullWidth
                          {...field}
                          placeholder={' Add here..'}
                          type="text"
                          error={errors?.lender_name?.message}
                        />
                      )}
                    />
                  </Grid>

                  <Grid theme={theme} item xs={12} sm={6}>
                    <Controller
                      name="lender_address"
                      control={control}
                      render={({ field }) => (
                        <Input
                          label="Lender Physical Address (Optional)"
                          theme={theme}
                          fullWidth
                          {...field}
                          placeholder={' Add here..'}
                          type="text"
                        />
                      )}
                    />
                  </Grid>
                  <Grid theme={theme} item xs={12} sm={6}>
                    <Controller
                      name="lender_contact_number"
                      control={control}
                      rules={{
                        minLength: {
                          value: 10,
                          message: 'Invalid Lender Phone Number'
                        },
                        maxLength: {
                          value: 10,
                          message: 'Invalid Lender Phone Number'
                        }
                      }}
                      render={({ field }) => (
                        <Input
                          theme={theme}
                          fullWidth
                          label="Lender Phone Number (Optional)"
                          type="text"
                          {...field}
                          placeholder="000-000-0000"
                          error={errors?.lender_contact_number?.message}
                          format="###-###-####"
                          masking={true}
                          maskNumeric={true}
                        />
                      )}
                    />
                  </Grid>
                  <Grid theme={theme} item xs={12} sm={6}>
                    <Controller
                      name="payment_type"
                      control={control}
                      rules={validation('Payment Type', true)}
                      render={({ field }) => (
                        <Select
                          label="Payment Type"
                          theme={theme}
                          items={[
                            { text: 'Lease', value: 'Lease' },
                            { text: 'Finance', value: 'Finance' }
                          ]}
                          fullWidth
                          {...field}
                          sxProps={''}
                          value={field.value}
                          onChange={field.onChange}
                          selectError={errors?.payment_type?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid theme={theme} item xs={12} sm={6}>
                    <Controller
                      name="payoff_amount"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Payoff Amount is required'
                        },
                        pattern: {
                          value: /^\d*(\.\d{0,2})?$/,
                          message: 'Only two decimal allowed'
                        }
                      }}
                      render={({ field }) => (
                        <Input
                          label="Payoff Amount (Within 10 days)"
                          theme={theme}
                          fullWidth
                          startAdornment={defaultCurrency}
                          {...field}
                          min={0}
                          placeholder={' Add here..'}
                          type="decimal"
                          error={errors?.payoff_amount?.message}
                          masking={true}
                          maskNumeric={true}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
              <Grid theme={theme} item xs={12}>
                <Controller
                  name="asset_image"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <FileDragDrop
                      {...field}
                      theme={theme}
                      width="300"
                      height="200px"
                      backgroundColor="white"
                      onDrop={fileUploadUtils.onImageDrop}
                      onChange={fileUploadUtils.onImageUpload}
                      allowMultiple={true}
                      hoverLabel={
                        <>
                          Drag and drop vehicle image, or{' '}
                          <span className="text-primary">browse</span>
                        </>
                      }
                    />
                  )}
                />
                {imageFiles && imageFiles.length > 0 && imageFiles[0] != null && (
                  <FilePreview
                    files={imageFiles.length > 0 ? imageFiles : []}
                    onRemoveFile={fileUploadUtils.onRemoveFile}
                    theme={theme}
                  />
                )}
              </Grid>
            </Grid>
          </>
        }
      />
    </>
  )
}
export default TradeInControl
