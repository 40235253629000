import { FC, useMemo } from 'react'
import { useTheme } from '@mui/material'
import { Box } from '@ntpkunity/controls'
import {
  CalculationBox,
  CashDeal,
  DownPayment,
  Label,
  QuoteParam,
  SellingPrice,
  Value
} from './finance-lease-calculator'
import { AddVehicle } from './add-vehicle'
import { FinanceTypes, useDeskingContext, VehiclesForComparisonTypes } from './desking-context-provider'

interface DealPreviewComponentProps {
  onDirtyStateChange?: (isDirty: boolean) => void
}

const DealPreviewComponent: FC<DealPreviewComponentProps> = ({ onDirtyStateChange }) => {
  const theme = useTheme()
  const {
    state: { preview, vehiclesData, selection, finance_type }
  } = useDeskingContext()
  const isCash = useMemo(() => finance_type == FinanceTypes.CASH, [finance_type])

  const { vin, vinIndex, down_payments, quote_params, calculationsBoxes } = preview
  const financedAmount = calculationsBoxes?.adjusted_capitalized_cost
  const vehicleData = vehiclesData.get(vin)

  const carryPercent =
    (vin ?? '').length > 0 && !!financedAmount && Object.keys(vehicleData ?? {}).length > 0
      ? (calculationsBoxes?.adjusted_capitalized_cost / vehicleData.vehicle.msrp) * 100
      : null

  const handleVehicleChange = () => {
    if (onDirtyStateChange) {
      onDirtyStateChange(true)
    }
  }

  const handleSellingPriceChange = () => {
    if (onDirtyStateChange) {
      onDirtyStateChange(true)
    }
  }

  const handleDownPaymentChange = () => {
    if (onDirtyStateChange) {
      onDirtyStateChange(true)
    }
  }

  return (
    <>
      <Box theme={theme} className="flc-wrap">
        <Box theme={theme} className="flc-ul">
          <Box theme={theme} className="flc-li">
            <AddVehicle vin={vin} index={vinIndex} onChange={handleVehicleChange} />
          </Box>

          {!isCash &&
            <>
              <Box theme={theme} className="flc-li">
                <Box theme={theme} className="flc-li-left-col">
                  <Box theme={theme} className="flc-labels-wrap">
                    <Box theme={theme} className="label-row">
                      <Label name={'Selling Price'} />
                    </Box>
                  </Box>
                </Box>
                <Box theme={theme} className="flc-li-selfStretch-col">
                  <SellingPrice vin={vin} onChange={handleSellingPriceChange} />
                </Box>
              </Box>
              <Box theme={theme} className="flc-li">
                <Box theme={theme} className="flc-li-left-col">
                  <Box theme={theme} className="flc-labels-wrap">
                    <Box theme={theme} className="label-row">
                      <Label name={'Down Payment'} />
                    </Box>
                  </Box>
                </Box>
                <Box theme={theme} className="flc-li-selfStretch-col">
                  <DownPayment
                    vin={vin}
                    rowIndex={0}
                    colIndex={0}
                    value={down_payments.value}
                    onChange={handleDownPaymentChange}
                  />
                </Box>
              </Box>
            </>
          }
          <Box theme={theme} className="flc-li">
            {isCash
              ? <CashDeal theme={theme} vin={vin} onChange={() => onDirtyStateChange?.(true)} />
              : <>
                <QuoteParam vin={vin} rowIndex={0} colIndex={0} value={quote_params} onChange={() => onDirtyStateChange?.(true)} />
                <CalculationBox
                  key={`${VehiclesForComparisonTypes.SINGLE}-${selection?.row}-${selection?.col}`}
                  comparison={VehiclesForComparisonTypes.PREVIEW}
                  vin={vin}
                  value={calculationsBoxes}
                  rowIndex={selection?.row}
                  colIndex={selection?.col}
                  selected
                  onChange={() => onDirtyStateChange?.(true)}
                />
              </>
            }
          </Box>
          {!isCash &&
            <>
              <Box theme={theme} className="flc-li flc-footer">
                <Box theme={theme} className="flc-li-left-col">
                  <Box theme={theme} className="flc-labels-wrap">
                    <Box theme={theme} className="label-row">
                      <Label name={'Financed Amount'} />
                    </Box>
                  </Box>
                </Box>
                <Box theme={theme} className="flc-li-selfStretch-col border-bottom">
                  <Value key={''} value={financedAmount ?? '0.00'} />
                </Box>
              </Box>
              <Box theme={theme} className="flc-li flc-footer">
                <Box theme={theme} className="flc-li-left-col">
                  <Box theme={theme} className="flc-labels-wrap">
                    <Box theme={theme} className="label-row">
                      <Label name={'LTV%'} />
                    </Box>
                  </Box>
                </Box>
                <Box theme={theme} className="flc-li-selfStretch-col border-bottom">
                  <Value key={''} value={carryPercent ?? '0.00'} isCurrency={false} />
                </Box>
              </Box>
            </>
          }
        </Box>
      </Box>
    </>
  )
}

export default DealPreviewComponent
