import { FC, useEffect, useState } from 'react'
import { styled, useTheme } from '@mui/material'
import {
  Typography,
  Grid,
  Input,
  DatePicker,
  Select,
  Icon,
  Box,
  Skeleton,
  Dialog,
  Button
} from '@ntpkunity/controls'
import DriverLicenseAddress from './Address'
import { Divider, NoPreviewImage, ImgCard } from 'components'
import { useStoreContext } from '@store/storeContext'
import { useDonwnloadDocument } from '@apis/dealer-workqueue.service'
import { Link } from 'react-router-dom'
import DisableLoader from '@public/assets/images/loader-disabled.gif'

export const ImageRotate = styled('div')(() => ({
  display: 'inline-block',
  width: '100%',
  // marginTop: 50,
  '.thumbnail': {
    float: 'left',
    width: '90%',
    height: '59vh',
    textAlign: 'center',
    overflow: 'hidden',
    verticalAlign: 'middle',
    display: 'flex',
    // overflowY: "auto",

    img: {
      display: 'table',
      maxHeight: '100%',
      maxWidth: '100%',
      width: 'auto',
      height: 'auto',
      margin: 'auto'
    },
    '&.max': {
      height: '100vh',
      img: {
        maxHeight: '100vh'
      }
    }
  },
  '.btn': {
    width: 50,
    float: 'right',
    '&.btn-rotate': {
      transform: 'scaleX(-1)'
    }
  }
}))

const DriversLicense: FC = () => {
  const theme = useTheme()
  const [licenseIssueSelect, setLicenseIssueSelectState] = useState([{ text: '', value: '' }])
  const [licenseVerificationSelect, setLicenseVerificationSelectState] = useState([
    { text: '', value: '' }
  ])
  const [dialogueOpen, setDialogOpened] = useState<any>(false)
  const [showAccordionDetail, setShowAccordionDetail] = useState<boolean>(false)
  const {
    states: { licenseInformation }
  } = useStoreContext()
  const { mutate: getDocURL } = useDonwnloadDocument()
  const [selfieImage, setSelfieImage] = useState<string | undefined>(undefined)
  const [frontImage, setFrontImage] = useState<string | undefined>(undefined)
  const [isImageDownloading, setIsImageDownloading] = useState(false)
  const [image, setImage] = useState<any>()
  const [image_url, setImageUrl] = useState<any>()
  const [image_type, setImageType] = useState<any>()
  const [rotateImage, setRotateImage] = useState<any>(0)
  const [toggleImageClass, setToggleImageClass] = useState<boolean>(false)

  useEffect(() => {
    if (licenseInformation) {
      setLicenseIssueSelectState([
        {
          text: licenseInformation?.address?.state_name,
          value: licenseInformation?.address?.state_name
        }
      ])
      setLicenseVerificationSelectState([
        { text: licenseInformation?.status, value: licenseInformation?.status }
      ])
    }
  }, [licenseInformation])

  const downloadDocumentByArtifactId = (artifactId: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      getDocURL(
        { artifact_id: artifactId },
        {
          onSuccess: (data: any) => {
            resolve(data?.document_url)
          },
          onError: (error: any) => {
            reject(error)
          }
        }
      )
    })
  }
  useEffect(() => {
    const licenseInfo = [licenseInformation]
    if (licenseInformation != (undefined || null)) {
      licenseInfo?.map(async (element) => {
        setFrontImage(await downloadDocumentByArtifactId(element?.front_image))
        setSelfieImage(await downloadDocumentByArtifactId(element?.selfie_image))
      })
    }
  }, [licenseInformation])
  const HANDLE_LICENCE_IMAGE_DOWNLOAD = async (image_url: any, image_type: any) => {
    setIsImageDownloading(true)
    downloadImage(await downloadDocumentByArtifactId(image_url), `${image_type}.png`)
  }
  const downloadImage = async (signedUrl: globalThis.RequestInfo | URL, filename: string) => {
    // Fetch the image data
    const response = await fetch(signedUrl)
    const blob = await response.blob()

    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
    setIsImageDownloading(false)
  }

  return (
    <>
      <Typography theme={theme} variant="h3" component="h3" className="title" sx={{ mb: 2 }}>
        Driver’s License Details
        <Icon
          name="ChevronDown"
          className={`toggle-btn${showAccordionDetail ? ' ch-up' : ''}`}
          onClick={() => setShowAccordionDetail(!showAccordionDetail)}
        />
      </Typography>
      {showAccordionDetail && (
        <>
          <Grid theme={theme} container columnSpacing={3} rowSpacing={4} sx={{ mb: 3 }}>
            <Grid theme={theme} item md={3} sm={6} xs={12}>
              <Input
                theme={theme}
                type={'text'}
                disabled={true}
                value={licenseInformation?.first_name}
                fullWidth
                label="First Name"
                placeholder="Type here.."
              />
            </Grid>
            <Grid theme={theme} item md={3} sm={6} xs={12}>
              <Input
                theme={theme}
                type={'text'}
                disabled={true}
                fullWidth
                label="Last Name"
                value={licenseInformation?.last_name}
                placeholder="Type here.."
              />
            </Grid>
            <Grid theme={theme} item md={3} sm={6} xs={12}>
              <Input
                theme={theme}
                type={'text'}
                disabled={true}
                fullWidth
                label="License Number"
                value={licenseInformation?.license_number}
                placeholder="Type here.."
              />
            </Grid>
            <Grid theme={theme} item md={3} sm={6} xs={12}>
              <Select
                theme={theme}
                label={'License Issuing State'}
                disabled={true}
                items={licenseIssueSelect}
                value={licenseIssueSelect[0]?.text}
              />
            </Grid>
            <Grid theme={theme} item md={3} sm={6} xs={12}>
              <DatePicker
                theme={theme}
                disabled={true}
                label="License Issuing Date"
                value={licenseInformation?.issue_date?.split('T')[0]}
              />
            </Grid>
            <Grid theme={theme} item md={3} sm={6} xs={12}>
              <DatePicker
                theme={theme}
                label="Expiration"
                disabled={true}
                value={licenseInformation?.expiry_date?.split('T')[0]}
              />
            </Grid>
            <Grid theme={theme} item md={3} sm={6} xs={12}>
              <DatePicker
                theme={theme}
                label="Date of Birth"
                disabled={true}
                value={licenseInformation?.date_of_birth?.split('T')[0]}
              />
            </Grid>
            <Grid theme={theme} item md={3} sm={6} xs={12}>
              <Select
                theme={theme}
                label={'Verification Status'}
                disabled={true}
                items={licenseVerificationSelect}
                value={licenseVerificationSelect[0]?.text}
              />
            </Grid>
          </Grid>
          <DriverLicenseAddress />
          <Divider sx={{ mt: '35px' }} />
          <NoPreviewImage>
            <Grid theme={theme} container columnSpacing={3} rowSpacing={4}>
              <Grid theme={theme} item md={4} sm={6} xs={12}>
                <Box theme={theme} className="image-card">
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="p"
                    children="Driver's License Front"
                    sx={{ textAlign: 'center', mt: 3, mb: 1 }}
                  />
                  <Box
                    className={
                      licenseInformation?.front_image
                        ? 'image-box image-show'
                        : 'image-box image-hide'
                    }
                    theme={theme}
                  >
                    {!licenseInformation ? (
                      <Skeleton width={'100%'} height={274} theme={theme}></Skeleton>
                    ) : (
                      <ImgCard>
                        {licenseInformation?.front_image ? (
                          <Link
                            onClick={() => {
                              setDialogOpened(true)
                              setImage(frontImage)
                              setImageUrl(licenseInformation?.front_image)
                              setImageType("Driver's License Front")
                              setRotateImage(0)
                            }}
                            to=""
                          >
                            <img width={'100%'} height={'100%'} src={frontImage} alt="" />
                          </Link>
                        ) : (
                          <Box theme={theme} className="notImage">
                            <Icon name="DoubleImageIcon" />
                          </Box>
                        )}
                      </ImgCard>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid theme={theme} item md={4} sm={6} xs={12}>
                <Box theme={theme} className="image-card">
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="p"
                    children="Selfie Image"
                    sx={{ textAlign: 'center', mt: 3, mb: 1 }}
                  />
                  <Box
                    className={
                      licenseInformation?.selfie_image
                        ? 'image-box image-show'
                        : 'image-box image-hide'
                    }
                    theme={theme}
                  >
                    {!licenseInformation ? (
                      <Skeleton width={'100%'} height={274} theme={theme}></Skeleton>
                    ) : (
                      <ImgCard>
                        {licenseInformation?.selfie_image ? (
                          <Link
                            onClick={() => {
                              setDialogOpened(true)
                              setImage(selfieImage)
                              setImageUrl(licenseInformation?.selfie_image)
                              setImageType('Selfie Image')
                              setRotateImage(0)
                            }}
                            to=""
                          >
                            <img width="100%" height="100%" src={selfieImage} alt="" />
                          </Link>
                        ) : (
                          <Box theme={theme} className="notImage">
                            <Icon name="DoubleImageIcon" />
                          </Box>
                        )}
                      </ImgCard>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </NoPreviewImage>
        </>
      )}
      <Dialog
        disablePortal={false}
        theme={theme}
        size="lg"
        title={image_type}
        open={dialogueOpen}
        onCloseDialog={() => setDialogOpened(false)}
        customFooter={
          <>
            <Grid theme={theme} container spacing={2}>
              <Grid theme={theme} item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  theme={theme}
                  className="btn-rotate"
                  text="Rotate"
                  secondary
                  onClick={() => {
                    setRotateImage(-90 + rotateImage)
                    setToggleImageClass(!toggleImageClass)
                  }}
                  startIcon={<Icon name="ResetIcon" />}
                />
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Button
                  theme={theme}
                  primary
                  text="Download"
                  onClick={() => HANDLE_LICENCE_IMAGE_DOWNLOAD(image_url, image_type)}
                  startIcon={<Icon name="SaveIcon" />}
                  disabled={isImageDownloading}
                  endIcon={isImageDownloading && <img src={DisableLoader} alt="Loader" />}
                />
              </Grid>
            </Grid>
          </>
        }
      >
        <ImageRotate theme={theme}>
          <Box theme={theme} className={`thumbnail ${toggleImageClass && 'max'}`}>
            <img
              className={'image-response'}
              style={{ rotate: `${rotateImage}deg` }}
              src={image}
              alt=""
            />
          </Box>
        </ImageRotate>
      </Dialog>

      <Divider sx={{ mt: '35px', mb: '35px' }} />
    </>
  )
}

export default DriversLicense
