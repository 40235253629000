import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { useTheme } from '@mui/material'
import { ScrollableTabs } from '@ntpkunity/controls'
import VehicleAmountDraft from './vehicle-amount-draft'
import { ScrollableTabsWrap } from '@components'
import AddOpptions from './add-options'
import { TabName } from '@helpers/enums'
import CashVehicleAmountDraft from './cash-vehicle-amount-draft'
import useFniAndMspAndRebatesPresent from '@hooks/useFniAndMspPresent'

interface OrderDetailsProps {
  orderReferenceId?: string | null
}

type OrderDetailsScrollAbleTab = { content: ReactNode; title: ReactNode }

const OrderDetails: FC<OrderDetailsProps> = ({ orderReferenceId }) => {
  const theme = useTheme()
  const { showFni, showMsp, showRebates, isCash, isVehicleSelectedAndOrderSaved } = useFniAndMspAndRebatesPresent(orderReferenceId)
  const [currentTab, setCurrentTab] = useState<number>(0)

  const tabs = useMemo(() => {
    const items: OrderDetailsScrollAbleTab[] = []

    if (showFni)
      items.push({
        title: 'F&I Products',
        content: <AddOpptions tabName={TabName.FNI} showFni={showFni} showMsp={showMsp} />
      })

    items.push(
      {
        title: 'Add-Ons',
        content: <AddOpptions tabName={TabName.OPTIONS} showFni={showFni} showMsp={showMsp} />
      },
      {
        title: 'Fees',
        content: <AddOpptions tabName={TabName.FEES} showFni={showFni} showMsp={showMsp} />
      }
    )

    if (showMsp)
      items.push({
        title: 'Maint. & Service Products',
        content: <AddOpptions tabName={TabName.MSP} showFni={showFni} showMsp={showMsp} />
      })
    if (showRebates) {
      items.push({
        title: 'Rebates',
        content: <AddOpptions tabName={TabName.REBATES} showFni={showFni} showMsp={showMsp} />
      })
    }
    return items
  }, [showRebates, showMsp, showFni])

  useEffect(() => {
    if (typeof isVehicleSelectedAndOrderSaved !== 'undefined') setCurrentTab(0)
  }, [isVehicleSelectedAndOrderSaved, isCash])

  return (
    <>
      {isCash ? <CashVehicleAmountDraft /> : <VehicleAmountDraft />}
      <ScrollableTabsWrap theme={theme} className="scrollable-tabs-wrap sm">
        <ScrollableTabs
          key={currentTab}
          theme={theme}
          items={tabs}
          scrollButtons={'auto'}
          tabValue={currentTab}
          onChange={(_key, index) => setCurrentTab(Number(index ?? 0))}
        />
      </ScrollableTabsWrap>
    </>
  )
}

export default OrderDetails
