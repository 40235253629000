import { FC, Fragment, useEffect, useMemo, useState } from 'react'
import { useQueries, useQuery } from 'react-query'
import { useTheme } from '@mui/material'
import { DuoTab, Box, Skeleton } from '@ntpkunity/controls'
import {
  useGetAllFinancialInsuranceProductsByDealerCode,
  useGetFinancialInsuranceProductsByDealerId,
  useGetFnIProductRate
} from '@apis/financial-insurance.service'
import FiProductsTable from './../financial-insurance/individual-products/products-table'
import {
  FinanceInsuranceControlProps,
  FinanceLeaseItem,
  FniPriceState,
  ProductInfo,
  SumState,
  DealerInfo,
  Finance_Type
} from './finance-insurance-types'
import { DialogTotal } from '@components'
import ProductsBundle from 'controls/financial-insurance/product-bundles/products-bundle'
import { useStoreContext } from '@store/storeContext'
import { roundToTwoDecimal } from '@app/desking/utils'
import { FinanceTypes } from '@app/desking/desking-context-provider'

const FinanceInsuranceControl: FC<FinanceInsuranceControlProps> = ({
  dealerProfileData,
  defaultCurrency,
  fniData,
  setFniData,
  terms,
  apr,
  selectedVehicle,
  company_id,
  isMSP = false,
  fAndIPenEnabled,
  deskingState,
  finance_type
}) => {
  const theme = useTheme()
  const { states } = useStoreContext()
  const [products_info, setNewProductsInfo] = useState<ProductInfo[]>([])
  const [selectedFniItems, setSelectedFniItems] = useState<FinanceLeaseItem[]>([])
  const [fniBundleChanges, setFniBundleChanges] = useState<
    Record<number, { removed: number[]; added: number[]; prices: number[] }>
  >({})
  const [addedItems, setAddedItems] = useState<any>([])
  const [selectFANDIProductsList, setselectFANDIProductsList] = useState<FinanceLeaseItem[]>([])
  const [selectMSPProductsList, setselectMSPProductsList] = useState<FinanceLeaseItem[]>([])
  const [dealerInfo, setDealerInfo] = useState<DealerInfo | undefined>(undefined)
  const [columnFilters, setColumnFilters] = useState()
  const [filters, setFilters] = useState<any>()
  const [fniPrice, setFniPrice] = useState<FniPriceState>({ finance: [], lease: [], cash: [] })

  const isCash = useMemo(() => finance_type.includes(FinanceTypes.CASH), [])

  const { data: fniProducts } = useGetFinancialInsuranceProductsByDealerId({
    data: `dealer_code=${dealerProfileData?.dealer_code}&is_active=true&MSP=${isMSP}${
      columnFilters ? columnFilters : `&page_number=${0}&page_size=${5}`
    }`,
    company_id: company_id
  })

  const {
    mutate: getProductRates,
    data: ProductRates,
    isLoading: loadingProductRates
  } = useGetFnIProductRate()
  const { data: allFniProducts } = useGetAllFinancialInsuranceProductsByDealerCode(
    states?.dealerInfo?.dealer_code
  )
  const { data: monthlyImpactAPI } = useQuery<{ type: string; isFetching: boolean }>(
    ['fniMonthlyImpactsAPI'],
    {
      enabled: true
    }
  )

  const monthlyImpactQueries = useQueries([
    {
      queryKey: [monthlyImpactAPI?.type ? monthlyImpactAPI.type + 'fniMonthlyImpacts' : ''],
      enabled: !!monthlyImpactAPI?.type
    },
    {
      queryKey: [
        monthlyImpactAPI?.type == 'bundle'
          ? 'individualfniMonthlyImpacts'
          : 'bundlefniMonthlyImpacts'
      ],
      enabled: true
    }
  ])

  const monthlyIpmactProducts = monthlyImpactQueries[0]?.data
  const otherMonthyImpactProducts = monthlyImpactQueries[1]?.data

  useEffect(() => {
    if (fniProducts) {
      if (isMSP) {
        const mspProductsList = fniProducts?.result?.filter((x: { MSP: any }) => x.MSP)
        let unmatchedOrderItems = selectedFniItems.filter(
          (orderItem: any) =>
            !mspProductsList.some(
              (mspProduct: any) => mspProduct?.id === orderItem?.financial_insurance_id
            )
        )
        unmatchedOrderItems = unmatchedOrderItems.filter(
          (orderItem: any) =>
            orderItem?.is_msp && addedItems.includes(orderItem?.financial_insurance_id)
        )
        setselectMSPProductsList([...unmatchedOrderItems, ...mspProductsList])
      } else {
        const fniProductsList = fniProducts?.result?.filter((x: { MSP: any }) => !x.MSP)
        let unmatchedOrderItems = selectedFniItems.filter(
          (orderItem: any) =>
            !fniProductsList.some(
              (fniProduct: any) => fniProduct?.id === orderItem?.financial_insurance_id
            )
        )
        unmatchedOrderItems = unmatchedOrderItems.filter(
          (orderItem: any) =>
            !orderItem?.is_msp && addedItems.includes(orderItem?.financial_insurance_id)
        )
        setselectFANDIProductsList([...unmatchedOrderItems, ...fniProductsList])
      }
    }
  }, [
    fniProducts,
    selectedFniItems,
    ProductRates
  ])

  const [sum, setSum] = useState<SumState>({
    total: '0:00',
    fni: '0:00',
    msp: '0:00',
    mspMonthlyImpact: '0.00',
    fniMonthlyImpact: '0.00'
  })

  useEffect(() => {
    setFniPrice({
      finance: [],
      lease: [],
      cash: []
    })
    const selectedFniItems = fniData[finance_type.toLowerCase() as Finance_Type]
    setSelectedFniItems(selectedFniItems || [])

    selectedFniItems
      ?.filter((arrayItem: any) => !arrayItem?.bundle_id)
      ?.forEach((itemId: any) => {
        setAddedItems((prevAddedItems: any) => [
          ...prevAddedItems,
          itemId?.financial_insurance_id ?? itemId?.id
        ])
      })

    setSum({
      ...sum,
      total: selectedFniItems?.length ? getTotal(selectedFniItems).sum : '0:00'
    })
  }, [])

  useEffect(() => {
    if (
      products_info?.length == allFniProducts?.length &&
      products_info?.length > 0 &&
      dealerProfileData
    ) {
      getProductRates({
        data: getRateRequestObject(products_info, finance_type)
      })
    }
  }, [products_info, dealerProfileData])

  useEffect(() => {
    if (fniProducts) {
      const selectedFniItems = fniData[finance_type.toLowerCase() as Finance_Type]
      const isSelectedFniItems = selectedFniItems?.some((item) => 'order_id' in item)
      if (isSelectedFniItems) setSelectedFniItems(mapFnIArray(selectedFniItems || []))
    }
  }, [fniProducts])

  const mapFnIArray = (array: any) => {
    const mappedArray = array?.map((priceItem: any) => {
      const otherProperties = fniProducts?.result?.find(
        (item: any) => item.id === priceItem.financial_insurance_id
      )

      const baseObject = otherProperties
        ? {
            ...otherProperties,
            form_id: priceItem?.form_id,
            rate_id: priceItem?.rate_id,
            surcharges: priceItem?.surcharges,
            price: priceItem?.price ?? priceItem?.applied_price,
            term: isCash ? 1 : priceItem?.term_months ?? priceItem?.term,
            term_months: isCash ? 1 : priceItem?.term_months ?? priceItem?.term
          }
        : {
            ...priceItem,
            price: priceItem?.price ?? priceItem?.applied_price,
            term: isCash ? 1 : priceItem?.term_months ?? priceItem?.term,
            term_months: isCash ? 1 : priceItem?.term_months ?? priceItem?.term
          }

      if (priceItem?.bundle_id) {
        baseObject.bundle_id = priceItem?.bundle_id
      }

      return baseObject
    })

    return mappedArray
  }

  useEffect(() => {
    if (allFniProducts) {
      const isCash = finance_type.includes(FinanceTypes.CASH)

      let productInfo: Array<any> = []
      allFniProducts?.map((obj: any) => {
        const newObj: any = {
          product_id: obj?.product_id,
          starting_mileage: 0,
          ending_mileage: 10000,
          filters: isCash ? [] : [{ term_months: `${terms}` }]
        }
        productInfo.push(newObj)
      })
      setNewProductsInfo(productInfo)
    }
  }, [allFniProducts])

  useEffect(() => {
    if (allFniProducts?.length > 0 && ProductRates) {
      applyRetailPrice(allFniProducts, ProductRates)
    }
    if (fniProducts?.result?.length > 0 && ProductRates) {
      applyRetailPrice(fniProducts?.result, ProductRates)
    }
  }, [allFniProducts, fniProducts, ProductRates])

  const getRateRequestObject = (new_products_info: any, finance_type: string) => {
    const currentDate = new Date(Date.now()).toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    })
    const isCash = finance_type.includes(FinanceTypes.CASH)
   
    const ratesRequestObject = {
      deal_info: {
        pen_dealer_id: dealerProfileData?.pen_dealer_id,
        vin: selectedVehicle?.vin,
        mileage: finance_type === 'Lease' ? (selectedVehicle?.mileage ?? 0) : 0,
        car_status: 'NEW',
        effective_date: currentDate,
        in_service_date: currentDate,
        finance_type: finance_type,
        vehicle_msrp: selectedVehicle?.msrp,
        vehicle_purchase_price: (selectedVehicle as any)?.internet_price,
        deal_type: isCash ? 'CASH' : 'LOAN',
        finance_terms: isCash ? [1] : [terms],
        finance_terms_mileage: isCash ? [1] : [terms],
        apr: isCash ? 0 : apr,
        financed_amount: isCash ? 0 : deskingState?.order?.net_finance_amount,
        language: 'ENGLISH',
        user_role: 'FI_MANAGER'
      },
      products_info: new_products_info,
      test_request: true
    }
    setDealerInfo(ratesRequestObject?.deal_info)
    return ratesRequestObject
  }

  function getFniMonthlyImpact(item: any) {
    const products = [
      ...((monthlyIpmactProducts as any)?.products ?? []),
      ...((otherMonthyImpactProducts as any)?.products ?? [])
    ]
    const monthlyImpact =
      Array.isArray(products) &&
      products?.length > 0 &&
      products?.find(
        (impact: any) => impact?.name === item?.product_name && item?.price === impact?.price
      )?.monthly_impact
    return monthlyImpact ? monthlyImpact : 0
  }

  const getTotal = (array: Array<any>, isMSPItem: boolean = false) => {
    if (!array?.length) return { sum: '0.00', monthlyImpact: '0.00' }

    const filteredArray = array.filter((obj) => {
      const isMSP = obj?.MSP ?? obj?.is_msp
      return isMSPItem ? isMSP && !obj?.bundle_id : obj?.bundle_id ? true : !isMSP
    })

    const sum = filteredArray.reduce((total, obj) => {
      const price = roundToTwoDecimal(obj?.price || 0)
      return total + price
    }, 0)

    const monthlyImpact = isCash ? '0.00' : filteredArray.reduce((total, obj) => {
      const price = roundToTwoDecimal(getFniMonthlyImpact(obj || 0))
      return total + price
    }, 0)

    return {
      sum: sum.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }),
      monthlyImpact: monthlyImpact.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    }
  }

  useEffect(() => {
      const { sum: fniTotal, monthlyImpact: fniMonthlyImpact } = getTotal(
        selectedFniItems,
        false
      )
      const { sum: mspTotal, monthlyImpact: mspMonthlyImpact } = getTotal(
        selectedFniItems,
        true
      )
      const totalSum = (parseFloat(fniTotal) + parseFloat(mspTotal)).toFixed(2)

      setSum((prev) => ({
        ...prev,
        total: totalSum,
        fni: fniTotal,
        msp: mspTotal,
        fniMonthlyImpact,
        mspMonthlyImpact
      }))
  }, [selectedFniItems, monthlyIpmactProducts])

  useEffect(() => {
      const updatedFniItems = selectedFniItems.map((item) => {
        const fniItem = fniPrice[finance_type?.toLowerCase() as Finance_Type]?.find((f) => f.id === item.id)
        return {
          ...item,
          mileage: fniItem ? fniItem.mileage : 999999,
          term: parseInt(item?.term_months ?? item?.term)
        }
      })

      const { sum: fniTotal, monthlyImpact: fniMonthlyImpact } = getTotal(
        updatedFniItems,
        false
      )
      const { sum: mspTotal, monthlyImpact: mspMonthlyImpact } = getTotal(updatedFniItems, true)
      const totalSum = (parseFloat(fniTotal) + parseFloat(mspTotal)).toFixed(2)

      setSum((prev) => ({
        ...prev,
        total: totalSum,
        fni: fniTotal,
        msp: mspTotal,
        mspMonthlyImpact,
        fniMonthlyImpact
      }))

      setFniData((prev: any) => ({
        ...prev,
        [finance_type.toLowerCase()]: updatedFniItems
      }))
  }, [selectedFniItems, fniPrice])

  function applyRetailPrice(fniProduct: any, fniRates: any) {
    const rates = fniRates?.rates?.rate
    return fniProduct?.map((product: any) => {
      let smallestRetailPrice = Infinity
      let rate_id = ''
      let form_id = ''
      let term_months = ''
      let term_miles = ''
      let coverage_name = ''
      let session_id = ''
      const normalizedRates = Array.isArray(rates) ? rates : [rates]
      const matchingRate = normalizedRates?.find(
        (rate: any) => rate?.product_id === product?.product_id
      )
      if (matchingRate) {
        let coverages = matchingRate?.coverages?.coverage
        if (Array.isArray(coverages)) {
          coverages = coverages.flat()
          coverages?.forEach((coverage: any) => {
            if (Array.isArray(coverage.deductibles.deductible)) {
              coverage.deductibles.deductible.forEach((deductible: any) => {
                const retailPrice = deductible?.retail_price
                if (retailPrice < smallestRetailPrice) {
                  smallestRetailPrice = retailPrice
                  rate_id = coverage?.deductibles?.deductible?.rate_id
                  form_id = coverage?.form?.form_id
                  term_months = coverage?.term_months
                  term_miles = coverage?.term_miles
                  coverage_name = coverage?.coverage_name
                  session_id = matchingRate?.session_id
                }
              })
            } else {
              const retailPrice = coverage?.deductibles?.deductible?.retail_price
              if (retailPrice < smallestRetailPrice) {
                smallestRetailPrice = retailPrice
                rate_id = coverage?.deductibles?.deductible?.rate_id
                form_id = coverage?.form?.form_id
                term_months = coverage?.term_months
                term_miles = coverage?.term_miles
                coverage_name = coverage?.coverage_name
                session_id = matchingRate?.session_id
              }
            }
          })
        } else {
          smallestRetailPrice =
            matchingRate?.coverages?.coverage?.deductibles?.deductible?.retail_price
          rate_id = matchingRate?.coverages?.coverage?.deductibles?.deductible?.rate_id
          form_id = matchingRate?.coverages?.coverage?.form?.form_id
          term_months = matchingRate?.coverages?.coverage?.term_months
          term_miles = matchingRate?.coverages?.coverage?.term_miles
          coverage_name = matchingRate?.coverages?.coverage?.coverage_name
          session_id = matchingRate?.session_id
          // deductiblePrice = matchingRate?.coverages?.coverage?.deductibles?.deductible?.retail_price
        }
      }
      if (smallestRetailPrice !== Infinity) {
        const selectedFniProduct = selectedFniItems?.find(
          (item) =>
            item.product_id === product?.product_id && item?.bundle_id === product?.bundle_id
        )

        const selectedProductCoverage =
          selectedFniProduct?.coverage_name
        const selectedProductPrice = selectedFniProduct?.price
        const selectedSurcharges =
          selectedFniProduct?.surcharges
        const deductablePrice =
          selectedFniProduct?.deductiblePrice
        const selectedRateId = selectedFniProduct?.rate_id
        const selectedFormID = selectedFniProduct?.form_id
        product.rate_id = selectedRateId ?? rate_id
        product.form_id = selectedFormID ?? form_id
        product.session_id = session_id
        product.term_months = term_months
        product.term_miles = term_miles
        product.deductiblePrice = deductablePrice ?? smallestRetailPrice
        product.pen_dealer_id = dealerProfileData?.pen_dealer_id
        product.coverage_name = selectedProductCoverage ?? coverage_name
        product.surcharges = selectedSurcharges
        product.price =
          selectedProductPrice ??
          (smallestRetailPrice !== undefined && smallestRetailPrice !== null
            ? smallestRetailPrice +
              ((product?.bundle_markup ?? product?.markup) / 100) * smallestRetailPrice
            : '')
      }
      return product
    })
  }

  return (
    <Fragment key={'FinanceInsuranceControl'}>
      {!!fAndIPenEnabled && !loadingProductRates ? (
        <>
          {!isMSP ? (
            <>
              <DuoTab
                theme={theme}
                varient={'underline'}
                items={[
                  {
                    title: 'Individual Products',
                    content: (
                      <FiProductsTable
                        selectedFniItems={selectedFniItems}
                        setSelectedFniItems={setSelectedFniItems}
                        defaultCurrency={defaultCurrency}
                        terms={terms}
                        data={selectFANDIProductsList}
                        setColumnFilters={setColumnFilters}
                        filters={filters}
                        setFniPrice={setFniPrice}
                        fniPrice={fniPrice}
                        addedItems={addedItems}
                        setAddedItems={setAddedItems}
                        paginationData={fniProducts}
                        setFilters={setFilters}
                        dealerInfo={dealerInfo}
                        deskingState={deskingState}
                        states={states}
                        finance_type={finance_type}
                        selectedVehicle={selectedVehicle}
                      />
                    )
                  },
                  {
                    title: 'Product Bundles',
                    content: (
                      <ProductsBundle
                        selectedFniItems={selectedFniItems}
                        setSelectedFniItems={setSelectedFniItems}
                        defaultCurrency={defaultCurrency}
                        terms={terms}
                        allFniProducts={allFniProducts}
                        productRates={ProductRates}
                        applyRetailPrice={applyRetailPrice}
                        dealerInfo={dealerInfo}
                        fniBundleChanges={fniBundleChanges}
                        setFniBundleChanges={setFniBundleChanges}
                        deskingState={deskingState}
                        finance_type={finance_type}
                        selectedVehicle={selectedVehicle}
                      />
                    )
                  }
                ]}
                defaultTabIndex={0}
              />
              <DialogTotal
                theme={theme}
                className="dialog-total"
                display={'flex'}
                gap={2}
                justifyContent={'space-between'}
              >
                <Box theme={theme} className="fw-700">
                  Total
                </Box>
                <Box
                  theme={theme}
                  className="primary"
                  textAlign="right"
                  sx={{
                    display: 'flex'
                  }}
                >
                  {defaultCurrency}
                  {sum?.fni}
                  &nbsp;
                  {!isCash && 
                  <>{monthlyImpactAPI?.isFetching ? (
                    <Skeleton
                      theme={theme}
                      variant="rectangular"
                      width={'30px'}
                      sx={{ borderRadius: '8px' }}
                    />
                  ) : (
                    <>
                      ({defaultCurrency}
                      {sum?.fniMonthlyImpact})
                    </>
                  )}</>
                }
                </Box>
              </DialogTotal>
            </>
          ) : (
            <>
              <DuoTab
                theme={theme}
                varient={'underline'}
                items={[
                  {
                    title: 'Individual Products',
                    content: (
                      <FiProductsTable
                        selectedFniItems={selectedFniItems}
                        setSelectedFniItems={setSelectedFniItems}
                        defaultCurrency={defaultCurrency}
                        terms={terms}
                        data={selectMSPProductsList}
                        setColumnFilters={setColumnFilters}
                        filters={filters}
                        setFniPrice={setFniPrice}
                        fniPrice={fniPrice}
                        addedItems={addedItems}
                        setAddedItems={setAddedItems}
                        paginationData={fniProducts}
                        setFilters={setFilters}
                        dealerInfo={dealerInfo}
                        deskingState={deskingState}
                        states={states}
                        finance_type={finance_type}
                        selectedVehicle={selectedVehicle}
                      />
                    )
                  }
                ]}
                defaultTabIndex={0}
              />
              <DialogTotal
                theme={theme}
                className="dialog-total"
                display={'flex'}
                gap={2}
                justifyContent={'space-between'}
              >
                <Box theme={theme} className="fw-700">
                  Total
                </Box>
                <Box
                  theme={theme}
                  className="primary"
                  textAlign="right"
                  sx={{
                    display: 'flex'
                  }}
                >
                  {defaultCurrency}
                  {sum?.msp}
                  &nbsp;
                  {!isCash && 
                  <>
                  {monthlyImpactAPI?.isFetching ? (
                    <Skeleton
                      theme={theme}
                      variant="rectangular"
                      width={'30px'}
                      sx={{ borderRadius: '8px' }}
                    />
                  ) : (
                    <>
                      ({defaultCurrency}
                      {sum?.mspMonthlyImpact})
                    </>
                  )}
                  </>
}
                </Box>
              </DialogTotal>
            </>
          )}
        </>
      ) : (
        <Skeleton
          theme={theme}
          variant="circular"
          width={'100%'}
          height={'50px'}
          sx={{ borderRadius: '8px' }}
        />
      )}
    </Fragment>
  )
}

export default FinanceInsuranceControl
