import { FC, useEffect, useMemo, useState } from 'react'
import { useTheme } from '@mui/material'
import { Box, Icon, VerticalTabs } from '@ntpkunity/controls'

import ContactDetailsComponent from '@app/customer-details/contact-details'
import EmploymentDetailsComponent from '@app/customer-details/employment-details'
import LicenseDetailsComponent from '@app/customer-details/license-details'
import PersonalFinanceDetailsComponent from '@app/customer-details/personal-finance-details'
import { useStoreContext } from '@store/storeContext'
import { IOrder } from '@models'
import { isEmpty } from '@helpers/methods'
import { mapPayload } from '@helpers/lead'
import { useUpdateLead } from '@hooks/lead-management'
import {
  dealexPermissions,
  useGetAllPermissions
  //@ts-ignore
} from '@ntpkunity/controls-ums'
import { getAllCustomerDetailsPermissions } from '@helpers/permissions'
import { AvailableDealTypes } from '@helpers/enums'
import { Disclosures } from './disclosures'
import { useGetCustomerDisclosure } from '@apis/customer-detail.service'
import { useDeskingContext } from '@app/desking/desking-context-provider'

const CustomerDetailVerticalTabs: FC<{
  customerReferenceId?: string
  order?: IOrder
  isGoogleEnabled: boolean
  contactData: any
  licenseInfo: any
  employmentInfo: any
  personalFinanceData: any
  isCoApplicant?: boolean
  setIsDetailsCompleted: (value: boolean) => void
  setTabStates?: any
}> = ({
  customerReferenceId,
  order,
  isGoogleEnabled,
  contactData,
  licenseInfo,
  employmentInfo,
  personalFinanceData,
  isCoApplicant,
  setIsDetailsCompleted,
  setTabStates
}) => {
  const theme = useTheme()
  const { mutate: updateLead } = useUpdateLead()
  const {
    states: { dealerInfo }
  } = useStoreContext()
  const { state } = useDeskingContext()
  const permissions = useGetAllPermissions()
  const { data: disclosureData } = useGetCustomerDisclosure(
    state?.order?.reference_number,
    isCoApplicant
  )
  const [route, setRoute] = useState<number>(0)
  const [pendingRoute, setPendingRoute] = useState<number | null>(null)
  const [isTabsDisabled, setIsTabsDisabled] = useState(false)
  const [isContact, setIsContact] = useState<boolean>(false)
  const [isContactErrors, setIsContactErrros] = useState<boolean>(false)
  const [isLicenseErrors, setIsLicenseErrors] = useState<boolean>(false)
  const [isLicense, setIsLicense] = useState<boolean>(false)
  const [isEmployment, setIsEmployment] = useState<boolean>(false)
  const [isEmploymentErrors, setIsEmploymentErrors] = useState<boolean>(false)
  const [isPersonalFinance, setPersonalFinance] = useState<boolean>(false)
  const [isPersonalFinanceErrors, setIsPersonalFinanceErrors] = useState<boolean>(false)
  const [isDisclosure, setIsDisclosure] = useState<boolean>(false)
  const [isDisclosureErrors, setIsDisclosureErrors] = useState<boolean>(false)
  const checkDisclosure = (order?.finance_type === AvailableDealTypes.CASH || isCoApplicant) || (isDisclosure && !isDisclosureErrors)
  const isDetailCompleted = isContact && isLicense && isEmployment && isPersonalFinance && checkDisclosure
  setIsDetailsCompleted(isDetailCompleted)
  const creditDetailsPermissions = getAllCustomerDetailsPermissions(permissions, dealexPermissions)
  const disclosureTabPermission: any = creditDetailsPermissions.disclosurePermissions
  const showDisclosureTab = useMemo(
    () => !isCoApplicant && disclosureTabPermission?.disclosureTab,
    [isCoApplicant, disclosureTabPermission]
  )
  useEffect(() => {
    if (!isEmpty(contactData?.ssn)) {
      setIsContact?.(true)
      setIsContactErrros?.(true)
    }
    if (!isEmpty(employmentInfo)) {
      setIsEmployment?.(true)
      setIsEmploymentErrors?.(true)
    }
    if (!isEmpty(licenseInfo)) {
      setIsLicense?.(true)
      setIsLicenseErrors?.(true)
    }
    if (!isEmpty(personalFinanceData)) {
      setPersonalFinance?.(true)
      setIsPersonalFinanceErrors?.(true)
    }

    if (contactData) {
      let lead = mapPayload(
        dealerInfo?.id,
        customerReferenceId,
        isCoApplicant ? 'coapplicant_details' : 'customer_details',
        {
          ...licenseInfo,
          ...employmentInfo,
          ...contactData,
          date_of_birth: contactData?.date_of_birth || licenseInfo?.date_of_birth
        }
      )
      updateLead(lead)
    }
  }, [contactData, personalFinanceData, licenseInfo, employmentInfo])
  useEffect(() => {
    if (disclosureData?.order_asset?.program_options) {
      setIsDisclosure(true)
    }
  }, [disclosureData])

  const setNextRoute = () => {
    if (showDisclosureTab) {
      setRoute(route + 1)
    }
  }

  return (
    <Box theme={theme} sx={{ pl: 4 }}>
      <VerticalTabs
        theme={theme}
        onChange={(val: any) => !isTabsDisabled && setPendingRoute(val)}
        items={[
          {
            title: (
              <>
                <Box className="title-with-icon" theme={theme}>
                  <Icon className="icon icon-green" name="GreenCheckIcon" />
                  <span className="icon icon-text">1. </span>
                  <span>Contact Details</span>
                </Box>
                <Box theme={theme} className="line" />
              </>
            ),
            content: (
              <>
                <ContactDetailsComponent
                  customerReferenceId={customerReferenceId}
                  setRoute={setRoute}
                  pendingRoute={pendingRoute}
                  setPendingRoute={setPendingRoute}
                  setIsContactErrros={setIsContactErrros}
                  setIsContact={setIsContact}
                  contactData={contactData}
                  isGoogleEnabled={isGoogleEnabled}
                  dealer_code={dealerInfo?.dealer_code ?? ''}
                  isCoApplicant={isCoApplicant}
                  permissionName={creditDetailsPermissions?.contactDetailsPermissions}
                  setIsTabsDisabled={setIsTabsDisabled}
                />
              </>
            ),
            isCompleted: isContact && isContactErrors,
            isActive: true
          },
          {
            title: (
              <>
                <Box className="title-with-icon" theme={theme}>
                  <Icon className="icon icon-green" name="GreenCheckIcon" />
                  <span className="icon icon-text">2. </span>
                  <span>License Details</span>
                </Box>
                <Box theme={theme} className="line" />
              </>
            ),
            content: (
              <>
                <LicenseDetailsComponent
                  licenseoData={licenseInfo}
                  setIsLicenseErrors={setIsLicenseErrors}
                  pendingRoute={pendingRoute}
                  setPendingRoute={setPendingRoute}
                  customerReferenceId={customerReferenceId}
                  setRoute={setRoute}
                  setIsLicense={setIsLicense}
                  order={order}
                  isCoApplicant={isCoApplicant}
                  permissionName={creditDetailsPermissions?.licenseDetailsPermission}
                  setTabStates={setTabStates}
                  setIsTabsDisabled={setIsTabsDisabled}
                />
              </>
            ),
            isCompleted: isLicense && isLicenseErrors,
            isActive: true
          },

          ...(order?.finance_type === AvailableDealTypes.CASH
            ? []
            : [
                {
                  title: (
                    <>
                      <Box className="title-with-icon" theme={theme}>
                        <Icon className="icon icon-green" name="GreenCheckIcon" />
                        <span className="icon icon-text">3. </span>
                        <span>Employment Details</span>
                      </Box>
                      <Box theme={theme} className="line" />
                    </>
                  ),
                  content: (
                    <>
                      <EmploymentDetailsComponent
                        employInfoData={employmentInfo}
                        pendingRoute={pendingRoute}
                        setPendingRoute={setPendingRoute}
                        customerReferenceId={customerReferenceId}
                        setIsEmploymentErrors={setIsEmploymentErrors}
                        setRoute={setRoute}
                        setIsEmployment={setIsEmployment}
                        isGoogleEnabled={isGoogleEnabled}
                        dealer_code={dealerInfo?.dealer_code ?? ''}
                        permissionName={creditDetailsPermissions?.employmentDetailsPermissions}
                        setIsTabsDisabled={setIsTabsDisabled}
                      />
                    </>
                  ),
                  isCompleted: isEmployment && isEmploymentErrors,
                  isActive: true
                },
                {
                  title: (
                    <>
                      <Box className="title-with-icon" theme={theme}>
                        <Icon className="icon icon-green" name="GreenCheckIcon" />
                        <span className="icon icon-text">4. </span>
                        <span>Personal Finance Details</span>
                      </Box>
                      <Box theme={theme} className="line" />
                    </>
                  ),
                  content: (
                    <>
                      <PersonalFinanceDetailsComponent
                        // setIsOrderSubmit={setIsOrderSubmit}
                        setNextRoute={setNextRoute}
                        pendingRoute={pendingRoute}
                        setPendingRoute={setPendingRoute}
                        setIsPersonalFinanceErrors={setIsPersonalFinanceErrors}
                        setRoute={setRoute}
                        customerReferenceId={customerReferenceId}
                        personalFinanceData={personalFinanceData}
                        setPersonalFinance={setPersonalFinance}
                        dealer_code={dealerInfo?.dealer_code ?? ''}
                        permissionName={creditDetailsPermissions?.personalFinanceDetailsPermissions}
                        setIsTabsDisabled={setIsTabsDisabled}
                      />
                    </>
                  ),
                  isCompleted: isPersonalFinance && isPersonalFinanceErrors,
                  isActive: true
                },
                ...(showDisclosureTab
                  ? [
                      {
                        title: (
                          <>
                            <Box className="title-with-icon" theme={theme}>
                              <Icon className="icon icon-green" name="GreenCheckIcon" />
                              <span className="icon icon-text">5. </span>
                              <span>Disclosures</span>
                            </Box>
                            <Box theme={theme} className="line" />
                          </>
                        ),
                        content: (
                          <Disclosures
                            pendingRoute={pendingRoute}
                            setPendingRoute={setPendingRoute}
                            setRoute={setRoute}
                            setIsDisclosure={setIsDisclosure}
                            setIsDisclosureErrors={setIsDisclosureErrors}
                            disclosurePermissions={disclosureTabPermission}
                            disclosureData={disclosureData}
                            setIsTabsDisabled={setIsTabsDisabled}
                          />
                        ),
                        isCompleted: isDisclosure && !isDisclosureErrors,
                        isActive: true
                      }
                    ]
                  : [])
              ])
        ]}
        defaultTabIndex={0}
        dynamic={true}
        route={route}
      />
    </Box>
  )
}

export default CustomerDetailVerticalTabs
