import React, { FC } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'

import { useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { IBoxProps, Icon } from '@ntpkunity/controls'
import { Grid, Box, Button, Input, Typography } from 'components'
import { useNavigate } from 'react-router-dom'
import { useChangePassword } from '@apis/authentication.service'

import { getUser, validation } from '@helpers/methods'
import { PASSWORD_REGEX } from '@helpers/constants'
import { APP_ROUTES } from '@helpers/links'

const loginImg = `url(${require('../public/assets/images/login-img.jpg')})`

const LoginScreen = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.auth-layout': {
    '.layout-img': {
      backgroundImage: loginImg,
      height: 'calc(100% - 80px)',
      position: 'fixed',
      left: 40,
      top: 40,
      width: 'calc(50% - 80px)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      borderRadius: 24,
      backgroundSize: 'cover',
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    '.content-area': {
      maxWidth: 'calc(100% - 395px)',
      margin: 'auto',
      height: 'calc(100vh - 110px)',
      paddingTop: 68,
      [theme.breakpoints.down('lg')]: {
        maxWidth: 'calc(100% - 100px)'
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'calc(100% - 70px)'
      }
    },
    '.u-form-group': {
      '.u-form-control': {
        '&.MuiInputBase-adornedEnd': {
          svg: {
            path: {
              stroke: theme.palette.grey[300]
            }
          }
        }
      }
    },
    '.decoration-none': {
      textDecoration: 'none'
    },
    '.fp-link': {
      a: {
        color: 'rgba(0,0,0,0.6)',
        paddingLeft: 15
      }
    },
    '.auth-footer': {
      color: theme.palette.grey[600],
      a: {
        color: theme.palette.grey[600]
      }
    }
  }
}))

interface IFormInputs {
  newPassword: string
  confirmPassword: string
}
const ChangePassword: FC = () => {
  let navigate = useNavigate()

  const { mutateAsync: changePassword } = useChangePassword()
  const theme = useTheme()
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues
  } = useForm<IFormInputs>()

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    let userData = getUser()
    const passwordData = {
      id: userData.id,
      old_password: userData.password,
      new_password: data.newPassword
    }
    await changePassword(passwordData)

    userData = getUser()
    // if(userData.is_default_password_changed){
    navigate(APP_ROUTES.WORKQUEUE)
    // }
  }

  const [showNewPassword, setShowNewPassword] = React.useState(false)
  const handleNewPasswordChange = () => {
    setShowNewPassword(!showNewPassword)
  }

  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false)
  const handleConfirmPasswordChange = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  return (
    <LoginScreen theme={theme} className="auth-layout">
      <Grid className="main-content-section" theme={theme} container spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          <Box className="layout-img"></Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Box
            className="content-area"
            flexDirection={'column'}
            display={'flex'}
            justifyContent={'space-between'}
          >
            <Box className="logo-area">
              <img src={require('../public/assets/images/unity-logo.svg')} alt="Unity" />
            </Box>
            <Box className="auth-form">
              <Typography mb={5} display={'block'} variant="h2" component="h2">
                Reset Your Password?
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box mb={4}>
                  <Controller
                    name="newPassword"
                    control={control}
                    defaultValue={''}
                    
                    rules={validation(
                      'New Password',
                      true,
                      true,
                      PASSWORD_REGEX,
                      'Password is not strong.'
                    )}
                    render={({ field }) => (
                      <Input
                        fullWidth
                        inputWrapperSx={{ marginBottom: '34px !important' }}
                        label={'Enter New Password'}
                        type={showNewPassword ? 'text' : 'password'}
                        {...field}
                        error={errors?.newPassword?.message}
                        endAdornment={<Icon name="IcView" onClick={handleNewPasswordChange} />}
                      />
                    )}
                  />
                  <Controller
                    name="confirmPassword"
                    control={control}
                    defaultValue={''}
                    
                    rules={{
                      ...validation('Confirm Password', true),
                      validate: {
                        matchesNewPassword: (value: string | undefined) => {
                          const { newPassword } = getValues()
                          return newPassword === value || 'Passwords must match'
                        }
                      }
                    }}
                    render={({ field }) => (
                      <Input
                        fullWidth
                        label={'Confirm Password'}
                        type={showConfirmPassword ? 'text' : 'password'}
                        {...field}
                        error={errors?.confirmPassword?.message}
                        endAdornment={<Icon name="IcView" onClick={handleConfirmPasswordChange} />}
                      />
                    )}
                  />
                </Box>
                <Button type={'submit'} primary text={'save'} fullWidth></Button>
              </form>
            </Box>
            <Box className="auth-footer">
              <Typography variant="caption" component="small" display={'block'}>
                Support: 0-000-000-0000 |{' '}
                <a className="decoration-none" href="mailto:sample@sample.com">
                  sample@sample.com
                </a>
              </Typography>
              <Typography variant="caption" component="small" display={'block'}>
                Weekdays 6am-6pm PST - Weekends 10am-6pm PST
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </LoginScreen>
  )
}

export default ChangePassword
