export enum LenderIntegrationType {
  ADDRESS_VALIDATION = 'location_services',
  FRAUD_IDENTIFICATION = 'fraud_identification',
  EXISTING_CUSTOMER_CHECK = 'Existing Customer Check',
  CALCULATION_AND_TAXATION = 'Calculation & Taxation',
  INVENTORY_UPLOAD = 'Inventory Upload',
  FNI_PRODUCTS = 'fni_products',
  IDENTITY_VERIFICATION = 'identity_verification',
  TRADE_IN_VALUATION = 'trade_in_valuation',
  PAYMENT_PROCESSING = 'payment_processing',
  CREDIT_BUREAU = 'Credit Bureau',
  DEAL_PUSH = 'deal_push'
}

export enum IntegrationProvider {
  GOOGLE = 'Google',
  SOCURE = 'Socure',
  KBB = 'KBB',
  MITEK = 'MiTek',
  PEN = 'PEN',
  SHIFT_DIGITAL = 'ShiftDigital',
  CDK = 'Cdk',
  TEKION = 'Tekion',
  STRIPE_CONNECT = 'Stripe Connect',
  PLAID = 'Plaid',
  MARKET_SCAN = 'MarketScan'
}

export enum IntegrationTypeForProvider {
  CALCULATION_AND_TAXATION = 'calculation_and_taxation'
}
