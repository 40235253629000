import { useEffect, useState } from 'react'
import { FC } from 'react'
import { useTheme } from '@mui/material'
import { Grid, Box, Typography, Button, Icon, Checkbox } from '@ntpkunity/controls'
import { useForm } from 'react-hook-form'
import { useUpdateCustomerContact } from '@apis/customer-detail.service'
import { IAddress } from '@models'
import { useStoreContext } from '@store/storeContext'
import { yupResolver } from '@hookform/resolvers/yup'
import CustomerInfoComponent from './customer-info'
import AddressDetailsComponent from './address-detail'
import { AddressType } from '@helpers/enums'
import { findAddress, getMonthsFromGivenDateFormat } from '@helpers/methods'
import {
  createOrderFeeObject,
  createOrderRequestPayloadFromState,
  createPricingRequestPayloadFromState,
  isDealerInUs
} from '@app/desking/utils'
import { useDeskingContext } from '@app/desking/desking-context-provider'
import { useOrderCalculationRequest } from '@apis/inventory-management.service'
import { useUpdateQuotationById } from '@apis/order-management.service'
import { useQueryClient } from 'react-query'
import { useGetAllDealerFeebyState } from '@apis/dealer-configurations.service'
import { createCustomerValidatorsSchema } from '@helpers/validators'

interface IFormInputs {
  customer_profile?: {
    prefix?: string | null
    first_name?: string | null
    middle_name?: string | null
    last_name?: string | null
    suffix?: string | null
    date_of_birth: string
    ssn?: string | null
    mobile_number?: string | null
    home_number?: string | null
    email?: string | number
  }
  mail_address: IAddress | null
  garaging_address?: IAddress | null
  billing_address?: IAddress | null
  previous_address?: IAddress | null
}

interface IContactDetailsComponentProps {
  setIsContact?: (value: boolean) => void
  setIsContactErrros?: (value: boolean) => void
  setRoute: (value: number) => void
  contactData?: any
  customerReferenceId?: string
  isGoogleEnabled: boolean
  dealer_code?: string
  isCoApplicant?: boolean
  permissionName?: any
}

const ContactDetailsComponent: FC<IContactDetailsComponentProps> = ({
  setIsContact,
  setIsContactErrros,
  setRoute,
  contactData,
  customerReferenceId,
  isGoogleEnabled,
  dealer_code,
  isCoApplicant,
  permissionName
}) => {
  const theme = useTheme()

  const queryClient = useQueryClient()
  const stateData: any = queryClient.getQueryData(['COMPANY_CONFIGURATIONS', 'states'])
  const [getCustomerDataByReferenceId, setCustomerDataByReferenceId] = useState<IFormInputs>()
  const [isGaraging, setIsGaraging] = useState(false)
  const [isBilling, setIsBilling] = useState(false)
  const [isPreviousAddress, setIsPreviousAddress] = useState(false)
  const { states, actions } = useStoreContext()
  const isUsAddress = isDealerInUs(states)

  const validationSchema = createCustomerValidatorsSchema(
    isGaraging,
    isBilling,
    isPreviousAddress,
    permissionName,
    isUsAddress
  )
  const form = useForm<IFormInputs>({
    defaultValues: getCustomerDataByReferenceId,
    resolver: yupResolver(validationSchema),
    mode: 'onTouched'
  })
  const {
    handleSubmit,
    formState: { errors, isValid },
    reset,
    getValues,
    setValue
  } = form
  const { state } = useDeskingContext()
  const [isVerified, setIsVerified] = useState(false)
  const [_isAddressDirty, setIsAddressDirty] = useState(false)
  const { mutate: updateCustomerByReferenceId } = useUpdateCustomerContact()
  const [isgaragingVerified, setIsGaragingVerified] = useState(false)
  const [isbillingVerified, setIsBillingVerified] = useState(false)
  const [isPreviousAddressVerified, setIsPreviousAddressVerified] = useState(false)
  const [recalculate, setRecalculate] = useState(false)
  const [updatedAddress, setUpdatedAddress] = useState<IAddress[]>()

  const addressPayloadName = (validation: any) => {
    return {
      ADDRESS_LAYOUT_ADDRESS_LINE_1: `Address Line 1 ${
        permissionName[validation.line1] ? '*' : ''
      }`,
      ADDRESS_LAYOUT_CITY: `City ${permissionName[validation.city] ? '*' : ''}`,
      ADDRESS_LAYOUT_STATE: `State ${permissionName[validation.state] ? '*' : ''}`,
      ADDRESS_LAYOUT_POSTAL_CODE: `Zip code ${permissionName[validation.zip] ? '*' : ''}`,
      ADDRESS_LAYOUT_ADDRESS_LINE_2_OPTIONAL: `Address Line 2`
    }
  }

  const {
    mutateAsync: getOrderCalculationRequest,
    isLoading: _calcReqLoading,
    error: _calcReqError,
    data: _orderRequestData
  } = useOrderCalculationRequest()
  const {
    mutateAsync: updateOrderRequest,
    isLoading: _updateRequestLoading,
    data: _updateResponse,
    error: _updateRequestError
  } = useUpdateQuotationById()
  const { mutateAsync: getAllDealerFee, data: allDealerFeeData } = useGetAllDealerFeebyState()

  useEffect(() => {
    if (contactData) {
      const mailAddress = contactData?.customer_addresses?.find(
        (x: any) => x?.address_type === AddressType.MAILING
      )
      const { ssn, ...restOfContactData } = contactData || {}
      setCustomerDataByReferenceId({
        ...getCustomerDataByReferenceId,
        customer_profile: { ...restOfContactData },
        mail_address: { ...mailAddress }
      })
      setTimeout(() => {
        form.setValue('customer_profile.ssn', contactData?.ssn ? '●●●●●●●●●' : '')
      }, 500)
    }
  }, [contactData])
  useEffect(() => {
    if (getCustomerDataByReferenceId) {
      const mailAddress = contactData?.customer_addresses?.find(
        (x: any) => x?.address_type === AddressType.MAILING
      )
      const garagingAddress = contactData?.customer_addresses?.find(
        (x: any) => x?.address_type === AddressType.GARAGING
      )
      const billingAddress = contactData?.customer_addresses?.find(
        (x: any) => x?.address_type === AddressType.BILLING
      )
      const previousAddress = contactData?.customer_addresses?.find(
        (x: any) => x?.address_type === AddressType.PREVIOUS
      )
      const isGAddress = isCoApplicant
        ? true
        : garagingAddress && garagingAddress.entity_type
        ? garagingAddress?.entity_type === AddressType.MAILING
        : isGaraging
      const isBAddress =
        billingAddress && billingAddress.entity_type
          ? billingAddress?.entity_type === AddressType.MAILING
          : isBilling
      reset(getCustomerDataByReferenceId)
      setValue('mail_address', mailAddress)
      setValue('mail_address.state_id', mailAddress?.state_name)
      setValue('mail_address.move_in_duration', setMoveInDate(mailAddress?.move_in_date))
      setIsGaraging(isGAddress)
      setIsBilling(isBAddress)
      if (garagingAddress && !isGAddress) {
        setValue('garaging_address', garagingAddress)
        setValue('garaging_address.state_id', garagingAddress?.state_name)
      }
      if (billingAddress && !isBAddress) {
        setValue('billing_address', billingAddress)
        setValue('billing_address.state_id', billingAddress?.state_name)
      }

      if (previousAddress) {
        setIsPreviousAddress(true)
        setValue('previous_address', previousAddress)
        setValue('previous_address.state_id', previousAddress?.state_name)
        setValue('previous_address.move_in_duration', setMoveInDate(previousAddress?.move_in_date))
      }
    }
  }, [getCustomerDataByReferenceId])

  useEffect(() => {
    const mailAddress = getValues('mail_address')
    if (mailAddress?.verified || (!isGoogleEnabled && mailAddress)) {
      setIsVerified(true)
    } else {
      setIsVerified(false)
    }
  }, [getValues('mail_address'), isGoogleEnabled])

  useEffect(() => {
    const customerGaragingAddress = findAddress(
      recalculate ? updatedAddress : contactData?.customer_addresses
    )
    if (customerGaragingAddress) {
      if (
        customerGaragingAddress?.state_name !==
          states.dealDesk.dealerProfile.dealer_address.state_name ||
        recalculate
      ) {
        getAllDealerFee(
          {
            dealer_id: states?.dealerInfo?.dealer_code,
            state_code: `${
              stateData?.find((x: any) => x?.name === customerGaragingAddress.state_name)?.code
            }`
          },
          {
            onSuccess(res: any) {
              calculationOrder(customerGaragingAddress, res)
            }
          }
        )
      } else {
        actions.setRecalculation(false)
      }
    } else if (isCoApplicant && recalculate) {
      setRoute(1)
    }
  }, [stateData, contactData, recalculate])

  useEffect(() => {
    const mailAddress = getValues('mail_address')
    if (mailAddress && mailAddress?.move_in_duration) {
      const moveInDate = mailAddress.move_in_duration
      const moveInMonths = getMonthsFromGivenDateFormat(moveInDate || '')

      if (moveInMonths < parseInt(states?.lenderInfo?.min_months_stay_at_address || '')) {
        setIsPreviousAddress(true)
      } else {
        setIsPreviousAddress(false)
      }
    }
  }, [getValues('mail_address'), states?.lenderInfo])

  const fillGargingAddress = (e: any) => {
    if (e.target.checked) {
      const mailAddress = getValues('mail_address')
      setIsGaraging(true)
      setValue('garaging_address', mailAddress)
    } else {
      reset({ ...getValues(), garaging_address: {} })
      setIsGaraging(false)
    }
  }
  const fillBillingAddress = (e: any) => {
    if (e.target.checked) {
      const mailAddress = getValues('mail_address')
      setIsBilling(true)
      setValue('billing_address', mailAddress)
    } else {
      reset({ ...getValues(), billing_address: {} })
      setIsBilling(false)
    }
  }

  const calculationOrder = async (data: any, fee?: any) => {
    const pricingPayload = createPricingRequestPayloadFromState(
      state,
      states?.dealDesk,
      0,
      0,
      state.vehiclesVins[0] ?? ''
    )
    const orderReqObj = createOrderRequestPayloadFromState(
      state,
      states.dealerInfo,
      state.order.lender_id
    )
    const fees_data = allDealerFeeData ? allDealerFeeData : fee

    await getOrderCalculationRequest({
      ...pricingPayload,
      fees: fees_data
        ?.filter(
          (fee: any) => fee.applicable_finance_type.includes(state.finance_type) && fee.is_active
        )
        ?.map((fee: any) => {
          return {
            fee_name: fee.fee_name,
            fee_amount: fee.default_amount,
            fee_handling: fee?.fee_handling,
            is_active: fee?.is_active,
            applicable_finance_type: fee?.applicable_finance_type,
            is_taxable: fee?.taxable
          }
        }),
      customer_address: {
        street_address: data?.address_line_1,
        city: data?.city,
        state: data?.state_name,
        zip_code: data?.zip_code
      }
    }).then((calculationResponse: any) => {
      if (recalculate) {
        setRoute(1)
      }
      if (
        state?.order?.estimated_monthly_payment !== calculationResponse?.monthly_payment ||
        state?.order?.due_at_signing !== calculationResponse?.due_at_signing
      ) {
        const reqobj = {
          ...orderReqObj,
          order_fees: fee.map((f: any) => {
            return createOrderFeeObject(f)
          }),
          monthly_deprecation: calculationResponse?.monthly_deprecation,
          monthly_sales_use_tax: calculationResponse?.monthly_sales_use_tax,
          capital_cost_reduction_tax: calculationResponse?.taxes?.capital_cost_reduction_tax,
          tax_on_capitalized_cost_reduction: calculationResponse?.tax_on_capitalized_cost_reduction,
          rebate: calculationResponse?.rebate_promotions,
          down_payment:
            (Number(calculationResponse?.down_payment) * 100) / calculationResponse?.selling_price,
          down_payment_value: calculationResponse?.down_payment,
          due_at_signing: calculationResponse?.due_at_signing,
          net_finance_amount: calculationResponse?.adjusted_capitalized_cost,
          finance_amount: calculationResponse?.gross_capitalized_cost,
          capitalized_cost_reduction: calculationResponse?.capitalized_cost_reduction,
          estimated_monthly_payment: calculationResponse?.monthly_payment,
          tax_amount: calculationResponse?.tax_amount,
          is_payment_updated: true
        }
        updateOrderRequest(reqobj, {
          onSuccess(_response: any) {
            actions.setRecalculation(true)
          }
        })
      } else {
        actions.setRecalculation(false)
      }
    })
  }

  useEffect(() => {
    const garagingAddress = getValues('garaging_address')
    if (isGaraging || garagingAddress?.verified || (!isGoogleEnabled && garagingAddress)) {
      setIsGaragingVerified(true)
    } else {
      setIsGaragingVerified(false)
    }
  }, [getValues('garaging_address'), isGaraging, isGoogleEnabled])

  useEffect(() => {
    const billingAddress = getValues('billing_address')
    if (isBilling || billingAddress?.verified || (!isGoogleEnabled && billingAddress)) {
      setIsBillingVerified(true)
    } else {
      setIsBillingVerified(false)
    }
  }, [getValues('billing_address'), isBilling, isGoogleEnabled])

  useEffect(() => {
    if (getValues('previous_address')?.verified) {
      setIsPreviousAddressVerified(true)
    }
  }, [getValues('previous_address')])

  const getMoveInDate = (moveInDuration: string) => {
    let date = null
    if (moveInDuration && moveInDuration?.toString()?.length > 3) {
      const month = parseInt(moveInDuration?.toString().slice(0, 2))
      let year = parseInt(moveInDuration?.toString().slice(2, 6))
      date = `${year}-${month}-${1}`
      return date
    }
    return date
  }

  const setMoveInDate = (moveInDate: string) => {
    let monthYear = ''

    if (moveInDate?.length > 0) {
      if (parseInt(moveInDate?.toString()?.slice(0, 4)) > new Date().getFullYear()) {
      }
      monthYear = moveInDate?.toString()?.slice(5, 7) + moveInDate?.toString()?.slice(0, 4)
    }
    return monthYear
  }

  const hasErrors = Object.keys(errors).length > 0
  if (hasErrors) {
    setIsContact?.(false)
    setIsContactErrros?.(hasErrors)
  }
  const onSubmit = (data: IFormInputs) => {
    const addresses = []
    const mailAddress = getValues('mail_address')
    const garagingAddress = getValues('garaging_address')
    const billingAddress = getValues('billing_address')
    const previousAddress = getValues('previous_address')
    if (mailAddress) {
      mailAddress.address_type = AddressType.MAILING
      if (!isGoogleEnabled) {
        mailAddress.verified = false
      }
      addresses.push({
        ...mailAddress,
        move_in_date: mailAddress?.move_in_duration
          ? getMoveInDate(mailAddress?.move_in_duration)
          : null
      })
    }

    if (previousAddress) {
      previousAddress.address_type = AddressType.PREVIOUS
      if (!isGoogleEnabled) {
        previousAddress.verified = false
      }
      addresses.push({
        ...previousAddress,
        move_in_date: previousAddress?.move_in_duration
          ? getMoveInDate(previousAddress?.move_in_duration)
          : null,
        is_active: isPreviousAddress,
        is_deleted: !isPreviousAddress
      })
    }

    if (garagingAddress && !isGaraging) {
      garagingAddress.address_type = AddressType.GARAGING
      if (!isGoogleEnabled) {
        garagingAddress.verified = false
      }
      addresses.push({
        ...garagingAddress
      })
    }
    if (billingAddress && !isBilling) {
      billingAddress.address_type = AddressType.BILLING
      if (!isGoogleEnabled) {
        billingAddress.verified = false
      }
      addresses.push({
        ...billingAddress
      })
    }

    if (mailAddress && isGaraging && !isCoApplicant) {
      addresses.push({
        address_type: AddressType.GARAGING,
        entity_type: AddressType.MAILING
      })
    }
    if (mailAddress && isBilling) {
      addresses.push({
        address_type: AddressType.BILLING,
        entity_type: AddressType.MAILING
      })
    }
    setUpdatedAddress(addresses)
    const newData = {
      customer_profile: {
        prefix: data?.customer_profile?.prefix === 'SELECT' ? null : data?.customer_profile?.prefix,
        first_name: data?.customer_profile?.first_name
          ? data?.customer_profile?.first_name
          : undefined,
        middle_name: data?.customer_profile?.middle_name
          ? data?.customer_profile?.middle_name
          : undefined,
        last_name: data?.customer_profile?.last_name
          ? data?.customer_profile?.last_name
          : undefined,
        suffix: data?.customer_profile?.suffix ? data?.customer_profile?.suffix : undefined,
        ssn: /^\●+$/?.test(data?.customer_profile?.ssn || '')
          ? contactData?.ssn
          : data?.customer_profile?.ssn
          ? data?.customer_profile?.ssn
          : undefined,
        mobile_number: data?.customer_profile?.mobile_number
          ? data?.customer_profile?.mobile_number
          : undefined,
        home_number: data?.customer_profile?.home_number ?? '',
        updated_by: 'Netsol'
      },
      customer_addresses: addresses
    }
    updateCustomerByReferenceId(
      {
        ...newData,
        reference_id: customerReferenceId,
        dealer_code: dealer_code
      },
      {
        onSuccess() {
          actions.setToast({
            toastMessage: 'Record updated successfully',
            toastState: true
          })
          setRecalculate(true)
          setIsContact?.(true)
          setIsContactErrros?.(!isValid)
        },
        onError(error: any) {
          const status = error?.response?.status
          actions.setToast({
            toastMessage:
              status === 409
                ? 'SSN must be unique.'
                : error?.detail?.message?.message?.toString() ||
                  'An error occurred while processing your request. Please try again later.',
            toastState: true,
            variant: 'error'
          })
          setIsContact?.(false)
          setIsContactErrros?.(true)
        }
      }
    )
  }
  return (
    <>
      <Typography theme={theme} component="h3" variant="h3" sx={{ mb: 3 }}>
        Contact Details
      </Typography>
      {/* <form onSubmit={handleSubmit(submit)}> */}
      <Box theme={theme} sx={{ mb: 4 }}>
        <CustomerInfoComponent form={form} permissions={permissionName} />
      </Box>
      <Box theme={theme} sx={{ mb: 4 }}>
        <hr />
      </Box>
      <Typography theme={theme} component="h4" variant="h4" className="title-icon" mb={2}>
        <span>Mailing Address </span>{' '}
        {isVerified ? (
          <Box theme={theme} display={'inline'} sx={{ position: 'relative', top: '-4px' }}>
            <Icon name="GreenCheckIcon" />
          </Box>
        ) : null}
      </Typography>

      <Box theme={theme} sx={{ mb: 4 }}>
        <AddressDetailsComponent
          form={form}
          isGoogleEnabled={isGoogleEnabled}
          addressValue={'mail_address'}
          setIsAddressDirty={setIsAddressDirty}
          errorProps={errors.mail_address}
          placeholders={addressPayloadName({
            line1: 'mailingAddressLine1',
            city: 'mailingAddressCity',
            state: 'mailingAddressCity',
            zip: 'mailingAddressZipCode'
          })}
        />
      </Box>

      <Box theme={theme} sx={{ mb: 4 }}>
        <hr />
      </Box>

      {isPreviousAddress && (
        <>
          <Typography theme={theme} component="h4" variant="h4" className="title-icon" mb={2}>
            <span>Previous Address </span>{' '}
            {isPreviousAddressVerified ? (
              <Box theme={theme} display={'inline'} sx={{ position: 'relative', top: '-4px' }}>
                <Icon name="GreenCheckIcon" />
              </Box>
            ) : null}
          </Typography>

          <Box theme={theme} sx={{ mb: 4 }}>
            <AddressDetailsComponent
              form={form}
              isGoogleEnabled={isGoogleEnabled}
              addressValue={'previous_address'}
              setIsAddressDirty={setIsAddressDirty}
              errorProps={errors.previous_address}
              hideMoveInHelper
              placeholders={addressPayloadName({
                line1: 'previousAddressLine1',
                city: 'previousAddressCity',
                state: 'previousAddressState',
                zip: 'previousAddressZipCode'
              })}
            />
          </Box>

          <Box theme={theme} sx={{ mb: 4 }}>
            <hr />
          </Box>
        </>
      )}

      <Typography theme={theme} component="h4" variant="h4" className="title-icon" mb={2}>
        <span>Billing Address </span>{' '}
        {isbillingVerified ? (
          <Box theme={theme} display={'inline'} sx={{ position: 'relative', top: '-4px' }}>
            <Icon name="GreenCheckIcon" />
          </Box>
        ) : null}
      </Typography>
      <Box theme={theme}>
        <Checkbox
          checkBoxChecked={isBilling}
          label="Same as mailing address"
          onChange={(e: any) => fillBillingAddress(e)}
          theme={theme}
        />
      </Box>

      <Grid theme={theme} container spacing={3} sx={{ mb: 4 }}>
        <Grid theme={theme} item xs={12}>
          {!isBilling ? (
            <AddressDetailsComponent
              form={form}
              isGoogleEnabled={isGoogleEnabled}
              addressValue={'billing_address'}
              setIsAddressDirty={setIsAddressDirty}
              moveIn={false}
              errorProps={errors.billing_address}
              placeholders={addressPayloadName({
                line1: 'billingAddressLine1',
                city: 'billingAddressCity',
                state: 'billingAddressState',
                zip: 'billingAddressZipCode'
              })}
            />
          ) : null}
        </Grid>
      </Grid>

      {!isCoApplicant && (
        <>
          <Typography theme={theme} component="h4" variant="h4" className="title-icon" mb={2}>
            <span>Garaging Address </span>{' '}
            {isgaragingVerified ? (
              <Box theme={theme} display={'inline'} sx={{ position: 'relative', top: '-4px' }}>
                <Icon name="GreenCheckIcon" />
              </Box>
            ) : null}
          </Typography>
          <Box theme={theme}>
            <Checkbox
              checkBoxChecked={isGaraging}
              label="Same as mailing address"
              onChange={(e: any) => fillGargingAddress(e)}
              theme={theme}
            />
          </Box>

          <Grid theme={theme} container spacing={3} sx={{ mb: 4 }}>
            <Grid theme={theme} item xs={12}>
              {!isGaraging ? (
                <AddressDetailsComponent
                  form={form}
                  isGoogleEnabled={isGoogleEnabled}
                  addressValue={'garaging_address'}
                  setIsAddressDirty={setIsAddressDirty}
                  errorProps={errors.garaging_address}
                  moveIn={false}
                  placeholders={addressPayloadName({
                    line1: 'garagingAddressLine1',
                    city: 'garagingAddressCity',
                    state: 'garagingAddressState',
                    zip: 'garagingAddressZipCode'
                  })}
                />
              ) : null}
            </Grid>
          </Grid>

          <Box theme={theme} sx={{ mb: 4 }}>
            <hr />
          </Box>
        </>
      )}
      <Box theme={theme} textAlign="right" sx={{ mb: 5 }}>
        <Button
          theme={theme}
          primary
          text="Save Contact Details"
          type="submit"
          // disabled={!isDirty || !isAddressDirty}
          onClick={handleSubmit(onSubmit)}
        />
      </Box>
    </>
  )
}
export default ContactDetailsComponent
