import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import {
  Button,
  Dialog,
  DuoTab,
  AddressLayout,
  Skeleton,
  Box,
  Typography,
  Icon
} from '@ntpkunity/controls'
import { RescheduleAppointmentWrap } from './order-summary-style'
import {
  useAddAppointment,
  useGetWorkdays,
  useUpdateAppointment,
  useDealerHolidays
} from '@apis/appointment-management.service'
import { useUpdateOrderStatus } from '@apis/dealer-workqueue.service'
import { useStoreContext } from '@store/storeContext'
import dayjs from 'dayjs'
import { ChooseDateTime } from '@ntpkunity/controls-common'
import moment from 'moment'
import {
  ScheduleOptions,
  Status,
  AddressType,
  IntegrationProvider,
  LenderIntegrationType,
  DialogMessages
} from '@helpers/enums'
import { useNavigate } from 'react-router-dom'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { useGetStates, useGetCountries } from '@apis/dealer-workqueue.service'
import { APP_ROUTES } from '@helpers/links'
import { Controller, useForm } from 'react-hook-form'
import { IAddress } from '@models'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { accessToken, ZIP_CODE_REGEX_MAP } from '@helpers/constants'
import { combineDateTime, getTimeZoneData } from '@helpers/methods'
import { useGetIntegrationByProviderName } from '@apis/customer-detail.service'
import { HolidayResponse } from './workdays-types'
import { isDealerInUs } from '@app/desking/utils'
import { ConfirmationDialog } from '@components'

function createValidationSchema(isUsAddress: boolean) {
  const schema = yup.object().shape({
    address: yup.object().shape({
      address_line_1: yup.string().nullable().required('Address Line 1 is required'),
      city: yup.string().nullable().required('City is required'),
      state_name: yup.string().nullable().required('State Name is required'),
      zip_code: yup
        .string()
        .nullable()
        .test(function (value) {
          if (!value) {
            return this.createError({ message: 'Zip Code is required' })
          }

          if (isUsAddress && !ZIP_CODE_REGEX_MAP.US.regex.test(value)) {
            return this.createError({ message: ZIP_CODE_REGEX_MAP.US.errorMessage })
          }

          return true
        })
      // county: yup.string().nullable().required('County is required')
    })
  })
  return schema
}

interface IFormInputs {
  address: IAddress | null
}

const RescheduleAppointmentDialog: FC<{
  openPopup: boolean
  setOpenPopup: any
  order_detail: any
  dealerProfileData?: any
}> = ({ openPopup, setOpenPopup, order_detail, dealerProfileData }) => {
  const { actions, states } = useStoreContext()
  const [openAppointmentPopup, setOpenAppointmentPopup] = useState(false)
  const { mutate: addAppointmentDetail, isLoading: isAddLoading } = useAddAppointment()
  const { mutate: updateAppointmentDetail, isLoading: isUpdateLoading } = useUpdateAppointment()
  const { mutate: updateOrderStatus } = useUpdateOrderStatus()
  const [isVerified, setIsVerified] = useState(false)
  const [isAddressDirty, setIsAddressDirty] = useState(false)
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false)

  let navigate = useNavigate()
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(customParseFormat)
  dayjs.extend(localizedFormat)

  const theme = useTheme()
  const [isGoogleEnabled, setIsGoogleEnabled] = useState(false)
  const [selectedTimeZone, setSelectedTimeZone] = useState({ value: '', name: '' })
  const [deliveryAddress, setdeliveryAddress] = useState<IAddress | null | undefined>()
  const { mutate: getStates, data: statesData } = useGetStates()
  const { mutate: getCountry, data: countriesData } = useGetCountries()
  const { mutate: getProviderbyName } = useGetIntegrationByProviderName(
    IntegrationProvider.GOOGLE,
    LenderIntegrationType.ADDRESS_VALIDATION
  )
  const [dealerCountry, setDealerCountry] = useState([])
  const isUsAddress = isDealerInUs(states)
  const validationSchema = createValidationSchema(isUsAddress)
  const scheduleOptionLength = dealerProfileData?.schedule_option?.length || 0
  const isPickupOnly =
    scheduleOptionLength === 1 && dealerProfileData?.schedule_option?.includes('pickup')
  const isDeliveryOnly =
    scheduleOptionLength === 1 && dealerProfileData?.schedule_option?.includes('delivery')

  const originalTab = scheduleOptionLength <= 1 ? 0 : order_detail?.schedule_option == ScheduleOptions.PickUp ? 0 : 1

  const [tab, setTab] = useState(
    scheduleOptionLength <= 1 ? 0 : order_detail?.schedule_option == ScheduleOptions.PickUp ? 0 : 1
  )

  const form = useForm<IFormInputs | any>({
    defaultValues: {
      ...deliveryAddress,
      selectedDate: null,
      selectedTime: null,
      isDateDisabled: false
    },
    resolver: yupResolver(validationSchema),
    mode: 'all'
  })

  const { data: workDays } = useGetWorkdays(
    states?.dealerInfo?.dealer_code,
    states?.dealerInfo?.company_id
  )
  const { data: holidays } = useDealerHolidays(
    states?.dealerInfo?.dealer_code,
    states?.dealerInfo?.company_id
  )
  const {
    handleSubmit,
    setValue,
    control,
    watch,
    getValues,
    formState: { isDirty, errors }
  } = form
  useEffect(() => {
    if (openPopup) {
      setOpenAppointmentPopup(openPopup)
    }
  }, [openPopup])
  useEffect(() => {
    getStates()
    getCountry()
  }, [])

  useEffect(() => {
    if (order_detail) {
      const address = order_detail?.appointment_details?.address ?? (order_detail?.customer_info?.customer_addresses)?.find(
        (x: any) =>
          (x?.address_type === AddressType.MAILING && x?.is_garaging) ||
          x?.address_type === AddressType.GARAGING
      )
      setIsVerified(address?.verified)
      setdeliveryAddress(address)
      setTab(
        scheduleOptionLength === 1
          ? 0
          : order_detail?.schedule_option == ScheduleOptions.PickUp
            ? 0
            : 1
      )
      form.reset({
        address,
        selectedDate: null,
        selectedTime: null,
        isDateDisabled: false
      })
      if (order_detail?.appointment_details) {
        handleUpdateDateTime()
      }
    }
    if (
      order_detail?.appointment_details?.time_zone ||
      (dealerProfileData && !!Object.keys(dealerProfileData)?.length)
    ) {
      const timeZone = order_detail?.appointment_details?.time_zone
        ? getTimeZoneData(
          order_detail?.appointment_details?.time_zone,
          order_detail?.appointment_details?.comments
        )
        : getTimeZoneData(undefined, undefined, dealerProfileData)

      setSelectedTimeZone(timeZone)
    }
  }, [order_detail, dealerProfileData, openAppointmentPopup])

  const handleUpdateDateTime = () => {
    const appointmentTimestamp = order_detail?.appointment_details?.appointment_date
    if (!appointmentTimestamp) {
      return
    }
    const timezoneOffset = order_detail?.appointment_details?.time_zone
    const [hours, minutes] = timezoneOffset?.split(':').map(Number)
    const totalOffSetMinutes = hours * 60 + minutes
    const appointmentDate = moment.unix(appointmentTimestamp).utcOffset(totalOffSetMinutes)

    form.setValue('selectedDate', moment(appointmentDate))
    form.setValue('selectedTime', appointmentDate.format('hh:mm A'))
  }
  const handleAppointmentDialog = () => ((isAddressDirty || isDirty || (tab !== originalTab)) && openAppointmentPopup) ? setShowConfirmationPopup(true) : handleClosePopup()

  const selectedDate = watch('selectedDate')
  const selectedTime = watch('selectedTime')

  const handleClosePopup = () => {
    setIsAddressDirty(false)
    setOpenAppointmentPopup(false)
    setOpenPopup(false)
  }
  const handleCancelSaveDialog = () => {
    setShowConfirmationPopup(false)
  }

  const handleDiscardAndClose = () => {
    handleUpdateDateTime()
    setIsAddressDirty(false)
    setShowConfirmationPopup(false)
    setOpenAppointmentPopup(false)
    setOpenPopup(false)
    form.clearErrors()
    form.reset({
      ...deliveryAddress,
      selectedDate: form.getValues('selectedDate'),
      selectedTime: form.getValues('selectedTime'),
      isDateDisabled: form.getValues('isDateDisabled')
    })
    setTab(originalTab)
  }

  const handleSaveAndClose = async () => {
    if (!selectedTime || !selectedTime) {
      showErrorMessage()
    } else {
      const isValid = await form.handleSubmit(addAppointment)()
      if (isValid !== undefined && isValid) {
        setShowConfirmationPopup(false)
        setOpenAppointmentPopup(false)
        setOpenPopup(false)
      }
    }
  }
  useEffect(() => {
    getProviderbyName(
      {
        provider_name: IntegrationProvider.GOOGLE,
        integration_type: LenderIntegrationType.ADDRESS_VALIDATION
      },
      {
        onSuccess(response: any) {
          setIsGoogleEnabled(response?.is_active)
        }
      }
    )
  }, [])

  const dealerWorkDays =
    workDays?.working_days
      ?.filter((day) => day.is_active && !day.is_deleted)
      .map((day) => ({
        workday: day.workday,
        day_start_time: day.day_start_time,
        day_end_time: day.day_end_time
      })) || []

  const dealerHolidays = Array.isArray(holidays)
    ? holidays
      .filter((holiday: HolidayResponse) => holiday.is_active && !holiday.is_deleted)
      .map((holiday) => ({
        holiday_date: holiday.holiday_date,
        holiday_occurance: holiday.holiday_occurance
      }))
    : []

  const showErrorMessage = () => {
    const errorMessage = !selectedDate && !selectedTime
      ? 'Please select both date and time'
      : !selectedDate
      ? 'Please select a date'
      : !selectedTime
      ? 'Please select a time'
      : ''

    if (errorMessage) {
      actions.setToast({
        toastMessage: errorMessage,
        toastState: true,
        variant: 'error'
      })
    }
  }

  const addAppointment = () => {
    const selectedDateMoment = getValues('selectedDate')
    if (
      order_detail?.appointment_details?.id &&
      getValues('selectedDate') &&
      getValues('selectedTime')
    ) {
      updateAppointmentDetail(
        {
          id: order_detail?.appointment_details?.id,
          order_id: order_detail?.id,
          dealer_id: order_detail?.dealer_id,
          customer_id: order_detail?.customer_id,
          time_zone: selectedTimeZone.value,
          appointment_date: combineDateTime(
            moment(selectedDateMoment),
            getValues('selectedTime'),
            selectedTimeZone.value
          ),
          comments: selectedTimeZone.name,
          appointment_type:
            scheduleOptionLength === 1
              ? isPickupOnly
                ? ScheduleOptions.PickUp
                : ScheduleOptions.Delivery
              : tab == 0
                ? ScheduleOptions.PickUp
                : ScheduleOptions.Delivery,
          address_id: order_detail?.appointment_details?.address_id
            ? order_detail?.appointment_details?.address_id
            : null,
          address_data:
            ((scheduleOptionLength === 1 && isDeliveryOnly) ||
              (scheduleOptionLength > 1 && tab == 1)) &&
              deliveryAddress
              ? {
                address_line_1: deliveryAddress.address_line_1,
                address_line_2: deliveryAddress.address_line_2,
                state_name: deliveryAddress.state_name,
                zip_code: deliveryAddress.zip_code,
                county: deliveryAddress.county,
                city: deliveryAddress.city,
                contact_number: deliveryAddress.contact_number,
                verified: true,
                address_type: AddressType.GARAGING
              }
              : null
        },
        {
          onSuccess() {
            handleClosePopup()
            const data = {
              order_id: order_detail?.reference_number,
              reqBody: {
                status: Status.Appointment,
                user_id: 0
              }
            }
            if (order_detail?.status !== Status.Appointment) {
              updateOrderStatus(data, {
                onSuccess() {
                  handleAppointmentDialog()
                  actions.setToast({
                    toastMessage: 'Appointment Scheduled Sucessfully',
                    toastState: true
                  })
                  navigate(APP_ROUTES?.WORKQUEUE)
                },
                onError(error: any) {
                  actions.setToast({
                    toastMessage: error.error.toString(),
                    toastState: true,
                    variant: 'error'
                  })
                }
              })
            } else {
              navigate(APP_ROUTES?.WORKQUEUE)
            }
          },
          onError(error: any) {
            if (error?.error?.message?.includes('status code 409')) {
              actions.setToast({
                toastMessage: 'The appointment date has passed. Please choose a future date.',
                toastState: true,
                variant: 'error'
              })
            } else {
              actions.setToast({
                toastMessage: error.error.toString(),
                toastState: true,
                variant: 'error'
              })
            }
          }
        }
      )
    } else if (watch('selectedDate') && watch('selectedTime')) {
      addAppointmentDetail(
        {
          order_id: order_detail?.id,
          dealer_id: order_detail?.dealer_id,
          customer_id: order_detail?.customer_id,
          time_zone: dealerProfileData?.time_zone_difference,
          appointment_date: combineDateTime(
            moment(selectedDateMoment),
            getValues('selectedTime'),
            selectedTimeZone.value
          ),
          comments: selectedTimeZone.name,
          appointment_type:
            scheduleOptionLength === 1
              ? isPickupOnly
                ? ScheduleOptions.PickUp
                : ScheduleOptions.Delivery
              : tab == 0
                ? ScheduleOptions.PickUp
                : ScheduleOptions.Delivery,
          dealer_code: states?.dealerInfo?.dealer_code,
          address_id: null,
          address_data:
            ((scheduleOptionLength === 1 && isDeliveryOnly) ||
              (scheduleOptionLength > 1 && tab == 1)) &&
              deliveryAddress
              ? {
                address_line_1: deliveryAddress.address_line_1,
                address_line_2: deliveryAddress.address_line_2,
                state_name: deliveryAddress.state_name,
                zip_code: deliveryAddress.zip_code,
                county: deliveryAddress.county,
                city: deliveryAddress.city,
                contact_number: deliveryAddress.contact_number,
                verified: true,
                address_type: AddressType.GARAGING
              }
              : null
        },
        {
          onSuccess() {
            const data = {
              order_id: order_detail?.reference_number,
              reqBody: {
                status: Status.Appointment,
                user_id: 0
              }
            }
            if (order_detail?.status !== Status.Appointment) {
              updateOrderStatus(data, {
                onSuccess() {
                  handleAppointmentDialog()
                  actions.setToast({
                    toastMessage: 'Appointment Scheduled Sucessfully',
                    toastState: true
                  })
                  navigate(APP_ROUTES?.WORKQUEUE)
                },
                onError(error: any) {
                  actions.setToast({
                    toastMessage: error.error.toString(),
                    toastState: true,
                    variant: 'error'
                  })
                }
              })
            } else {
              navigate(APP_ROUTES?.WORKQUEUE)
            }
          },
          onError(error: any) {
            if (error?.error?.message?.includes('status code 409')) {
              actions.setToast({
                toastMessage: 'The appointment date has passed. Please choose a future date.',
                toastState: true,
                variant: 'error'
              })
            } else {
              actions.setToast({
                toastMessage: error.error.toString(),
                toastState: true,
                variant: 'error'
              })
            }
          }
        }
      )
    }
  }
  const RescheduleAppointments = [
    ...(dealerProfileData?.schedule_option?.includes('pickup')
      ? [
        {
          title: 'In-Store Pickup',
          content: (
            <>
              <RescheduleAppointmentWrap theme={theme} className="dialog date-time-picker">
                <ChooseDateTime
                  theme={theme}
                  form={form}
                  selectedTimeZone={selectedTimeZone}
                  setSelectedTimeZone={setSelectedTimeZone}
                  workdaySchedule={dealerWorkDays}
                  holidays={dealerHolidays}
                />
              </RescheduleAppointmentWrap>
            </>
          )
        }
      ]
      : []),
    ...(dealerProfileData?.schedule_option?.includes('delivery')
      ? [
        {
          title: 'Delivery',
          content: (
            <>
              <RescheduleAppointmentWrap theme={theme} className="dialog date-time-picker">
                <ChooseDateTime
                  theme={theme}
                  form={form}
                  selectedTimeZone={selectedTimeZone}
                  setSelectedTimeZone={setSelectedTimeZone}
                  workdaySchedule={dealerWorkDays}
                  holidays={dealerHolidays}
                />
                <Box theme={theme} className="address-layout">
                  <Typography
                    theme={theme}
                    component="h4"
                    variant="h4"
                    sx={{ mb: 1, mt: 3 }}
                    display={'flex'}
                    className="title-icon"
                  >
                    Choose Delivery Location
                    {isVerified && isGoogleEnabled ? (
                      <Box theme={theme} sx={{ marginLeft: '6px' }}>
                        <Icon name="GreenCheckIcon" />
                      </Box>
                    ) : null}
                  </Typography>
                  {dealerCountry?.length > 0 &&
                    statesData?.length > 0 &&
                    ((scheduleOptionLength === 1 && isDeliveryOnly) ||
                      (scheduleOptionLength > 1 && tab == 1)) ? (
                    <Controller
                      name="address"
                      control={control}
                      defaultValue={
                        order_detail?.appointment_details?.address
                          ? order_detail?.appointment_details?.address
                          : deliveryAddress || watch('address')
                      }
                      render={({ field }) => (
                        <AddressLayout
                          {...field}
                          addressVerificationRequired={isGoogleEnabled}
                          hideAddressButton={!isGoogleEnabled}
                          theme={theme}
                          state={{
                            data: statesData,
                            selectedItemObject: { text: 'name', value: 'name' },
                            controlName: 'state_name'
                          }}
                          countries={dealerCountry}
                          validationProps={errors.address}
                          isContactRequired={true}
                          value={deliveryAddress || watch('address')}
                          verifyAddressURL={`${process.env.INTEGRATION_API_GATEWAY}/api/verify-address/`}
                          token={
                            JSON.parse(localStorage.getItem(accessToken) as string)?.access_token
                          }
                          dealerCode={states?.dealerInfo?.dealer_code}
                          setIsAddressDirty={setIsAddressDirty}
                          onChange={(e: any) => {
                            if (isGoogleEnabled && e?.verified) {
                              setdeliveryAddress(e)
                              setIsVerified(true)
                            } else if (!isGoogleEnabled) {
                              setdeliveryAddress(e)
                            }
                            setValue('address', e)
                            setValue('address.state_name', e?.state_name)
                            setValue('address.state_id', e?.state_id)
                          }}
                          zipCodeErrorMessage={(errors?.address as any)?.zip_code?.message}
                        ></AddressLayout>
                      )}
                    />
                  ) : (
                    <>
                      <Skeleton theme={theme} width={'100%'} height={'60px'} />
                      <Skeleton theme={theme} width={'100%'} height={'60px'} />
                      <Skeleton theme={theme} width={'100%'} height={'60px'} />
                    </>
                  )}
                </Box>
              </RescheduleAppointmentWrap>
            </>
          )
        }
      ]
      : [])
  ]
  useEffect(() => {
    setDealerCountry(
      countriesData?.filter(
        (x: any) => x?.id == states?.dealerProfileData?.dealer_address?.country_id
      )
    )
  }, [countriesData, states?.dealerProfileData])

  return (
    order_detail && (
      <>
        <Dialog
          disablePortal
          variant={undefined}
          size="sm"
          title="Schedule Appointment"
          open={openAppointmentPopup}
          onCloseDialog={handleAppointmentDialog}
          customFooter={
            <>
              {(form.watch('selectedDate') &&
                form.watch('selectedTime') &&
                ((scheduleOptionLength === 1 && isDeliveryOnly) ||
                  (scheduleOptionLength > 1 && tab == 1))) ? (
                <Button
                  disabled={isUpdateLoading || isAddLoading || form.watch('isDateDisabled')}
                  theme={theme}
                  primary
                text="Confirm Appointment"
                  onClick={handleSubmit(addAppointment)}
                />
              ) : (
                <Button
                  disabled={isUpdateLoading || isAddLoading || form.watch('isDateDisabled')}
                  theme={theme}
                  primary
                text="Confirm Appointment"
                  onClick={addAppointment}
                />
              )}
            </>
          }
          theme={theme}
        >
          {order_detail &&
            workDays &&
            holidays &&
            dealerProfileData &&
            !!Object.keys(dealerProfileData)?.length ? (
            <DuoTab
              theme={theme}
              varient={'default'}
              items={RescheduleAppointments}
              defaultTabIndex={
                dealerProfileData?.schedule_option?.length <= 1
                  ? 0
                  : order_detail && order_detail?.schedule_option == ScheduleOptions.PickUp
                    ? 0
                    : 1
              }
              onChange={(e: any) => {
                if (dealerProfileData?.schedule_option?.length <= 1) {
                  return
                }
                if (e?.target?.innerText == 'In-Store Pickup') {
                  setTab(0)
                }
                if (e?.target?.innerText == 'Delivery') {
                  setTab(1)
                }
              }}
            />
          ) : (
            <>
              <Skeleton theme={theme} width={'100%'} height={'60px'} />
              <Skeleton theme={theme} width={'100%'} height={'60px'} />
              <Skeleton theme={theme} width={'100%'} height={'60px'} />
            </>
          )}
        </Dialog>
        {showConfirmationPopup && (
          <ConfirmationDialog
            openPopUp={showConfirmationPopup}
            onConfirm={handleSaveAndClose}
            setPopUpState={setShowConfirmationPopup}
            confirmationTitle={DialogMessages.saveChanges}
            confirmationText={DialogMessages.unsaveChanges}
            primaryBtnText="Save"
            secondaryBtnText="Discard"
            icon={<Icon className="alert-icon" name="ErrorTriangle" />}
            onCancel={handleCancelSaveDialog}
            onSecondaryBtnClick={handleDiscardAndClose}
            hideCancelButton={false}
          />
        )}
      </>
    )
  )
}
export default RescheduleAppointmentDialog
