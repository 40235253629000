import {
  EP_CREATE_PAYMENT_DETAIL,
  EP_GET_PAYMENT_DETAIL,
  EP_REMOVE_PAYMENT_DETAIL
} from '@helpers/endpoints'
import Http from '@helpers/http-api'
import { IOrderPaymentDetail } from '@models'
import { useMutation, useQueryClient } from 'react-query'

export const useGetPaymentDetails = (): any => {
  const { data, isLoading, mutate } = useMutation<IOrderPaymentDetail, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.get<any>(`${EP_GET_PAYMENT_DETAIL}/${body?.reference_number}`)
    },
    {
      onSuccess() { }
    }
  )
  return { data, isLoading, mutate }
}

export const useUpdatePaymentDetail = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.put<any>(`${EP_REMOVE_PAYMENT_DETAIL}/${body.order_payment_detail_id}`, {})
    },
    {
      onSuccess() { }
    }
  )
  return { data, isLoading, mutate }
}
export const useSavePaymentDetail = (): any => {
  const queryClient = useQueryClient()
  const { data, isLoading, mutate, error } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<any>(`${EP_CREATE_PAYMENT_DETAIL}`, body)
    },
    {
      onSuccess() {
        queryClient.invalidateQueries('Order')
      }
    }
  )
  return { data, isLoading, mutate, error }
}
