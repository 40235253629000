import { Accordion, Box, Dialog } from '@ntpkunity/controls'
import { useTheme } from '@mui/material'
import { DialogContentContainer } from './source-response.styles'
import HeaderSection from './HeaderSection'
import BodySection from './BodySection'
import { FC } from 'react'

interface SourceResponseDialogProps {
  openPopup: boolean;
  setPopUpState: any;
  response: any;
}

const SourceResponseDialog: FC<SourceResponseDialogProps> = ({ openPopup, setPopUpState, response }) => {
  const theme = useTheme()

  function formatKeyToReadableUppercase(key: string, details: any): string | null {
    const lowerCaseKey = key?.toLowerCase()

    const matchingKey = Object.keys(details).find(
      (detailKey) => detailKey?.toLowerCase() === lowerCaseKey
    )

    if (matchingKey) {
      return matchingKey
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .toUpperCase()
    }

    return key
  }

  function filterDecisionDetails(decisionDetails: any, details: any): any {
    const detailKeys = details ? Object.keys(details).map((key) => key.toLowerCase()) : []

    const filteredDetails: any = {}

    if (decisionDetails) {
        Object.keys(decisionDetails).forEach((key) => {
            const lowerCaseKey = key.toLowerCase()
            if (detailKeys.includes(lowerCaseKey)) {
                filteredDetails[key] = decisionDetails[key]
            }
        })
    }

    return filteredDetails
}

  function transformApiResponse(apiResponse: any) {
    const { decision } = apiResponse

    const filteredData = Object.entries(apiResponse)?.filter(
      ([key, value]) => typeof value === "object" && value !== null && !Array.isArray(value) && key !== "decision"
    )

    const details = Object.fromEntries(filteredData)

    const decisionDetails = filterDecisionDetails(decision?.details, details) as any

    const decisionData = {
        title: "Decision",
        status: decision?.value?.toUpperCase(),
        scoreList: [],
        fieldValidations: [],
        riskTitle: "",
        infoTitle: "",
        riskCodes: [],
        infoCodes: [],
        decisionList: [
            {
                description: `Model Name: ${decision?.modelName || "N/A"}`,
                code: decision?.code || "N/A",
                status: "info"
            }
        ],
        decisionDetails: Object.entries(decisionDetails as any[]).map(([key, value]) => ({
            title: `${formatKeyToReadableUppercase(key, details)}: ${value?.decision?.toUpperCase() || "N/A"}`,
            icon: value?.decision === "accept" ? "GreenCheckIcon" : value?.decision === "reject" ? "CloseRed" : value?.decision === "refer" ? "RightArrowCircle" : "IcView",
            status: value?.decision === "accept" ? "success" : value?.decision === "reject" ? "error" : "warning",
            description: `version: ${decision?.modelVersion}`
        })),
        addressTitle: undefined,
        addressDetails: undefined,
        addressLink: undefined
    }

    const sections = Object.keys(details)?.map((sectionKey: any) => {
        const section = details?.[sectionKey] as any
        const title = sectionKey?.replace(/([A-Z])/g, " $1")?.trim()

        const riskCodes = section?.reasonCodes?.filter((code: any) => !code?.code?.startsWith('I'))?.map((code: any) => ({
            code: code?.code,
            status: "error",
            description: code?.description
        }))

        const infoCodes = section?.reasonCodes?.filter((code: any) => code?.code?.startsWith('I'))?.map((code: any) => ({
            code: code?.code,
            status: "info",
            description: code?.description
        }))

        return {
            title: title?.charAt(0)?.toUpperCase() + title?.slice(1),
            scoreList: section?.scores
                ? section?.scores?.map((score: any) => ({
                      title: `SCORE`,
                      score: score?.score?.toFixed(3),
                      description: `${score?.name} (${score?.version})`,
                      scoreStatus: section?.score < 0.33 ? "success" : section?.score < 0.66 ? "warning" : "error"
                  }))
                : section?.score ? [{
                    title: `SCORE`,
                    score: section?.score?.toFixed(3),
                    description: section?.score?.name ? `${section?.score?.name} ${section?.score?.version ? `(${section?.score?.version})` : ''} ` : '',
                    scoreStatus: section?.score < 0.33 ? "success" : section?.score < 0.66 ? "warning" : "error"
                }] : [],
            fieldValidations: section?.fieldValidations ? [section?.fieldValidations] : [],
            decisionList: [],
            decisionDetails: [],
            riskTitle: "Risk Codes",
            infoTitle: "Informational Codes",
            riskCodes,
            infoCodes,
            addressTitle: undefined,
            addressDetails: undefined,
            addressLink: undefined
        }
    })

    return [decisionData, ...sections]
}

const riskData = transformApiResponse(response)

  return (
    <Dialog
      theme={theme}
      size={'xl'}
      onCloseDialog={() => setPopUpState(false)}
      open={openPopup}
      title="Socure Response Details"
      noFooter={true}
    >
      <DialogContentContainer theme={theme} className="dialog-content-container">
        <Accordion
          theme={theme}
          varient="grouped"
          shouldCollapse={true}
          items={riskData?.map((risk, index) => ({
            title: <>
              <Box theme={theme} display={'flex'} alignItems={'center'} gap={1}>
                <span>{risk?.title}</span>
              </Box>
            </>,
            key: index.toString(),
            onOpenTab: () => { },
            onCloseTab: () => { },
            content: (
              <>
                <HeaderSection scoreList={risk?.scoreList} fieldValidations={risk?.fieldValidations} decisionList={risk?.decisionList}/>
                <BodySection
                  riskCodes={risk?.riskCodes}
                  infoCodes={risk?.infoCodes}
                  riskTitle={risk?.riskTitle}
                  infoTitle={risk?.infoTitle}
                  addressTitle={risk?.addressTitle}
                  addressDetails={risk?.addressDetails}
                  addressLink={risk?.addressLink}
                  decisionDetails={risk?.decisionDetails}
                />
              </>
            )
          }))}
        />
      </DialogContentContainer>
    </Dialog>
  )
}

export default SourceResponseDialog
