import { useGetIntegrationByProviderName } from '@apis/customer-detail.service'
import {
  useGetFinancialInsuranceProductsByDealerId,
  useGetFnIProductRate
} from '@apis/financial-insurance.service'
import { IntegrationProvider, LenderIntegrationType } from '@helpers/enums'
import { useTheme } from '@mui/material'
import { DuoTab, Box } from '@ntpkunity/controls'
import { FC, useEffect, useMemo, useState } from 'react'
// import FinancialInsurancePopup from './finance-insurance-popup'
import FiProductsTable from './../financial-insurance/individual-products/products-table'
import {
  FinanceInsuranceControlProps,
  FinanceLeaseItem,
  FniPriceState,
  ProductInfo,
  SumState
} from './finance-insurance-types'
import { DialogTotal } from '@components'

const FinanceInsuranceControl: FC<FinanceInsuranceControlProps> = ({
  dealerProfileData,
  defaultCurrency,
  fniData,
  setFniData,
  terms,
  apr,
  selectedFinanceType,
  selectedVehicle,
  company_id,
  isMSP = false
}) => {
  const theme = useTheme()
  const [products_info, setNewProductsInfo] = useState<ProductInfo[]>([])
  const isFinanceType = selectedFinanceType === 'Finance'
  const [activeTab, _] = useState<any>(isFinanceType ? 0 : 1)
  const [selectedFinanceItems, setSelectedFinanceItems] = useState<FinanceLeaseItem[]>([])
  const [selectedLeaseItems, setSelectedLeaseItems] = useState<FinanceLeaseItem[]>([])
  const [penEnabled, setPenEnabled] = useState<boolean>(false)
  const [addedItemPrices, setAddedItemPrices] = useState<{ [key: number]: number }>({})
  const [draftItemPrices, setDraftItemPrices] = useState<{ [key: number]: number }>({})
  const [columnFilters, setColumnFilters] = useState()
  const [filters, setFilters] = useState<any>()
  const [_fniPrice, setFniPrice] = useState<FniPriceState>({ finance: [], lease: [] })

  const { mutate: getPenIntegrations } = useGetIntegrationByProviderName(
    IntegrationProvider.PEN,
    LenderIntegrationType.FNI_PRODUCTS
  )
  const { mutate: getFandIProducts, data: fniProducts } =
    useGetFinancialInsuranceProductsByDealerId()

  const { mutate: getFinanceProductRates, data: financeProductRates } = useGetFnIProductRate()
  const { mutate: getLeaseProductRates, data: leaseProductRates } = useGetFnIProductRate()
  const [sum, setSum] = useState<SumState>({
    finance: '0.00',
    lease: '0.00',
    fniFinance: '0.00',
    fniLease: '0.00',
    mspFinance: '0.00',
    mspLease: '0.00'
  })

  useEffect(() => {
    setFniPrice({
      finance: [],
      lease: []
    })
    setSelectedFinanceItems(fniData?.finance || [])
    setSelectedLeaseItems(fniData?.lease || [])
    setSum({
      ...sum,
      finance: fniData?.finance?.length > 0 ? getTotal(fniData?.finance) : '0.00',
      lease: fniData?.lease?.length > 0 ? getTotal(fniData?.lease) : '0.00'
    })
  }, [])

  useEffect(() => {
    if (
      products_info?.length == fniProducts?.result?.length &&
      products_info?.length > 0 &&
      dealerProfileData
    ) {
      if (isFinanceType) {
        getFinanceProductRates({
          data: getRateRequestObject(products_info, 'Finance')
        })
      } else {
        getLeaseProductRates({
          data: getRateRequestObject(products_info, 'Lease')
        })
      }
    }
  }, [products_info, dealerProfileData])

  useEffect(() => {
    if (fniProducts) {
      const financeHasOrderIdField = fniData?.finance?.some((item) => 'order_id' in item)
      const leaseHasOrderIdField = fniData?.lease?.some((item) => 'order_id' in item)

      if (financeHasOrderIdField || leaseHasOrderIdField) {
        setSelectedFinanceItems(mapFnIArray(fniData?.finance || []))
        setSelectedLeaseItems(mapFnIArray(fniData?.lease || []))
      }
    }
  }, [fniProducts])

  const mapFnIArray = (array: any) => {
    const mappedArray = array.map((priceItem: any) => {
      const otherProperties = fniProducts?.result?.find(
        (item: any) => item.id === priceItem.financial_insurance_id
      )
      return { ...otherProperties, price: priceItem?.applied_price }
    })
    return mappedArray
  }
  useEffect(() => {
    getPenIntegrations(
      {
        provider_name: IntegrationProvider.PEN,
        integration_type: LenderIntegrationType.FNI_PRODUCTS
      },
      {
        onSuccess(response: any) {
          setPenEnabled(response?.is_active)
        }
      }
    )
  }, [])

  useEffect(() => {
    getFandIProducts({
      data: `dealer_code=${dealerProfileData?.dealer_code
        }&is_active=true&MSP=${isMSP}${columnFilters ? columnFilters : `&page_number=${0}&page_size=${5}`}`,
      company_id: company_id
    })
  }, [dealerProfileData?.dealer_code, columnFilters])

  useEffect(() => {
    if (fniProducts) {
      let productInfo: Array<any> = []
      fniProducts?.result?.map((obj: any) => {
        const newObj: any = {
          product_id: obj?.product_id,
          starting_mileage: 0,
          ending_mileage: 10000,
          filters: [{ term_months: `${terms}` }]
        }
        productInfo.push(newObj)
      })
      setNewProductsInfo(productInfo)
    }
  }, [fniProducts])

  useEffect(() => {
    if (isFinanceType) {
      if (fniProducts?.result.length > 0 && financeProductRates) {
        applyMarkupOnCoverages(fniProducts.result, financeProductRates)
      } else {
        setSelectedFinanceItems([])
      }
    }
  }, [fniProducts, financeProductRates])

  useEffect(() => {
    if (!isFinanceType) {
      if (fniProducts?.result.length > 0 && leaseProductRates) {
        applyMarkupOnCoverages(fniProducts.result, leaseProductRates)
      } else {
        setSelectedLeaseItems([])
      }
    }
  }, [fniProducts, leaseProductRates])

  const getRateRequestObject = (new_products_info: any, finance_type: string) => {
    const currentDate = new Date(Date.now()).toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    })
    const ratesRequestObject = {
      deal_info: {
        pen_dealer_id: dealerProfileData?.pen_dealer_id,
        vin: selectedVehicle?.vin,
        mileage: finance_type == 'Lease' ? selectedVehicle?.mileage ?? 0 : 0,
        car_status: 'NEW',
        effective_date: currentDate,
        in_service_date: currentDate,
        finance_type: finance_type,
        vehicle_msrp: selectedVehicle?.msrp,
        vehicle_purchase_price: selectedVehicle?.internetPrice || 346445,
        deal_type: 'LOAN',
        finance_terms: [terms],
        finance_terms_mileage: [24],
        apr,
        financed_amount: selectedVehicle?.financedAmount || 33718.28,
        language: 'ENGLISH',
        user_role: 'FI_MANAGER'
      },
      products_info: new_products_info,
      test_request: true
    }
    return ratesRequestObject
  }
  const getTotal = (array: Array<any>, isMSPItem: boolean = false) => {
    if (!array?.length) return '0.00'

    const filteredArray = array.filter(obj => obj.MSP === isMSPItem)
    const sum = filteredArray.reduce((total, obj) => {
      const price = parseFloat(obj.price) || 0
      return total + price
    }, 0)
    return sum.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }
  useEffect(() => {
    if (isFinanceType) {
      const fniTotal = getTotal(selectedFinanceItems, false)
      const mspTotal = getTotal(selectedFinanceItems, true)
      const totalSum = (parseFloat(fniTotal) + parseFloat(mspTotal)).toFixed(2)

      setSum(prev => ({
        ...prev,
        finance: totalSum,
        fniFinance: fniTotal,
        mspFinance: mspTotal
      }))
    }
  }, [selectedFinanceItems])

  function applyMarkupOnCoverages(fniProduct: any, fniRates: any) {
    const rates = fniRates?.rates?.rate
    const rateArray = Array.isArray(rates) ? rates : rates ? [rates] : []
    rateArray?.map((rate: any) => {
      let markup = fniProduct.find((x: any) => x.product_id == rate?.product_id)
      //when only single coverage is found then pen returns object instead of array
      if (rate?.coverages?.coverage && !Array.isArray(rate?.coverages?.coverage)) {
        let price = rate?.coverages?.coverage?.deductibles?.deductible?.retail_price
        price = price + (markup?.markup / 100) * price
        rate.coverages.coverage.deductibles.deductible.retail_price = price
        rate.coverages.coverage = [rate?.coverages?.coverage]
      } else {
        rate?.coverages?.coverage?.map((item: any) => {
          let price = item.deductibles.deductible.retail_price
          price = price + (markup?.markup / 100) * price
          item.deductibles.deductible.retail_price = price
        })
      }
    })
  }

  const totalPrice = useMemo(() => {
    return Object.values(addedItemPrices).reduce((acc, price) => acc + price, 0)
  }, [addedItemPrices])

  useEffect(() => {
    const prices: Record<string, number> = {}
    const financeType = selectedFinanceType?.toLowerCase() as 'finance' | 'lease'
    fniData?.[financeType]?.forEach((item: any) => {
      if (item?.id) {
        prices[item?.id] = item?.price ?? 0
      }
    })

    setAddedItemPrices(prices)
    setDraftItemPrices(prices)
  }, [])

  useEffect(() => {
    const selectedItems = isFinanceType ? selectedFinanceItems : selectedLeaseItems
    const filteredData = selectedItems
      ?.filter(item => addedItemPrices.hasOwnProperty(item?.id))

    setFniData((prevData: any) => {
      const selectedData = prevData?.[selectedFinanceType?.toLowerCase()] || []

      const updatedData = selectedData?.map((item: any) => {
        if (addedItemPrices.hasOwnProperty(item?.id)) {
          return { ...item, price: addedItemPrices?.[item?.id] }
        }
        return item
      }).filter((item: any) => addedItemPrices.hasOwnProperty(item?.id))

      const newFniData = [...updatedData, ...filteredData]

      const uniqueFniData = newFniData.reduce((acc: any[], item: any) => {
        if (!acc.some(existingItem => existingItem?.id === item?.id)) {
          acc.push(item)
        }
        return acc
      }, [])

      return {
        ...prevData,
        [selectedFinanceType?.toLowerCase()]: uniqueFniData
      }
    })
  }, [addedItemPrices])

  return (
    <>
      {penEnabled ? (
        <>
          {!isMSP && (
            <>
              <DuoTab
                theme={theme}
                varient={'underline'}
                items={[
                  {
                    title: 'Individual Products',
                    content: <FiProductsTable
                      data={fniProducts}
                      financeRates={financeProductRates?.rates?.rate}
                      leaseRates={leaseProductRates?.rates?.rate}
                      selectedFinanceItems={selectedFinanceItems}
                      selectedLeaseItems={selectedLeaseItems}
                      setSelectedLeaseItems={setSelectedLeaseItems}
                      setSelectedFinanceItems={setSelectedFinanceItems}
                      activeTab={activeTab}
                      defaultCurrency={defaultCurrency}
                      filters={filters}
                      terms={terms}
                      setFilters={setFilters}
                      addedItemPrices={addedItemPrices}
                      setAddedItemPrices={setAddedItemPrices}
                      setColumnFilters={setColumnFilters}
                      draftItemPrices={draftItemPrices}
                      setDraftItemPrices={setDraftItemPrices}
                    />
                  }
                  // {
                  //   title: 'Product Bundles',
                  //   content: <FiProductsTable />
                  // }
                ]}
                defaultTabIndex={0}
              />
              <DialogTotal
                theme={theme}
                className="dialog-total"
                display={'flex'}
                gap={2}
                justifyContent={'space-between'}
              >
                <Box theme={theme}>Total</Box>
                <Box theme={theme} className="primary" textAlign="right">
                  {defaultCurrency}
                  {(totalPrice ?? 0)?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </Box>
              </DialogTotal>
            </>

          )}
          {isMSP && (
            <>
              <DuoTab
                theme={theme}
                varient={'underline'}
                items={[
                  {
                    title: 'Individual Products',
                    content: <FiProductsTable
                      data={fniProducts}
                      financeRates={financeProductRates?.rates?.rate}
                      leaseRates={leaseProductRates?.rates?.rate}
                      selectedFinanceItems={selectedFinanceItems}
                      selectedLeaseItems={selectedLeaseItems}
                      setSelectedLeaseItems={setSelectedLeaseItems}
                      setSelectedFinanceItems={setSelectedFinanceItems}
                      activeTab={activeTab}
                      defaultCurrency={defaultCurrency}
                      filters={filters}
                      terms={terms}
                      setFilters={setFilters}
                      addedItemPrices={addedItemPrices}
                      setAddedItemPrices={setAddedItemPrices}
                      setColumnFilters={setColumnFilters}
                    />
                  }
                  // {
                  //   title: 'Product Bundles',
                  //   content: <FiProductsTable />
                  // }
                ]}
                defaultTabIndex={0}
              />
              <DialogTotal
                theme={theme}
                className="dialog-total"
                display={'flex'}
                gap={2}
                justifyContent={'space-between'}
              >
                <Box theme={theme}>Total</Box>
                <Box theme={theme} className="primary" textAlign="right">
                  {defaultCurrency}
                  {activeTab == 0 ? sum?.mspFinance : sum?.mspLease}
                </Box>
              </DialogTotal>
            </>
          )}
        </>
      ) : (
        ''
      )}
    </>
  )
}

export default FinanceInsuranceControl
