import { useTheme } from '@mui/material'
import { IConfirmationPopUp } from './ConfirmationDialog.type'
import { Dialog, Typography, Box, Button, Textarea } from '@ntpkunity/controls'
import { Controller, useForm } from 'react-hook-form'

export const ConfirmationDialog = <T extends any>({
  openPopUp,
  setPopUpState,
  confirmationText,
  confirmationTitle,
  onConfirm,
  onCancel,
  hideCancelButton,
  icon,
  primaryBtnText,
  secondaryBtnText,
  isButtonTypeDanger,
  optionaldata
}: IConfirmationPopUp<T>) => {
  const theme = useTheme()

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm()

  const onClose = () => {
    setPopUpState(false)
  }

  const onCancelClick = (e: any) => {
    onClose()
    onCancel(e)
  }

  const onConfirmClick = (e: any) => {
    setPopUpState(false)
    onConfirm(e)
  }

  const orderReason = (data: any) => {
    setPopUpState(false)
    onConfirm(data)
  }

  return (
    <Dialog
      disablePortal
      size="xs"
      variant="confirmation"
      open={openPopUp}
      noFooter={false}
      className={'u-dialog-sm'}
      onCloseDialog={onClose}
      theme={theme}
      customFooter={
        <>
          {!hideCancelButton && (
            <Button
              theme={theme}
              secondary
              text={secondaryBtnText ? secondaryBtnText : 'Cancel'}
              onClick={onCancelClick}
            ></Button>
          )}
          <Button
            theme={theme}
            onClick={!hideCancelButton ? handleSubmit(orderReason) : onConfirmClick}
            primary={isButtonTypeDanger ? false : true}
            text={primaryBtnText}
            danger={isButtonTypeDanger}
          ></Button>
        </>
      }
      children={
        <>
          <Box theme={theme} className="dialog-icon">
            {icon}
          </Box>

          <Box theme={theme}>
            <Typography theme={theme} className="content-title" variant="h2" component="h2">
              {confirmationTitle}
            </Typography>

            <Typography theme={theme} className="text-muted" variant="body2" component="p" mt={2}>
              {confirmationText}
            </Typography>

            {optionaldata && (
              <Box theme={theme} mt={2}>
                <Controller
                  control={control}
                  name="reason"
                  render={({ field }) => (
                    <Textarea
                      id={'assets_unavailable_mark'}
                      theme={theme}
                      fullWidth
                      type="text"
                      rows={3}
                      placeholder="Sample reason text..."
                      label="Reason"
                      {...field}
                      error={errors?.reason?.message?.toString()}
                    />
                  )}
                />
              </Box>
            )}
          </Box>
        </>
      }
    />
  )
}
