import { FC } from 'react'
import { useTheme } from '@mui/material'
import { Box, Chip, Typography } from '@ntpkunity/controls'
import { TableList } from '@components'
import { FinanceTypes, useDeskingContext, VehiclesForComparisonTypes } from './desking-context-provider'
import { formatNumber } from './utils'
import { useStoreContext } from '@store/storeContext'
import { cashDueAtSigningCalculator } from '@helpers/caculationHelper'

const CashVehicleAmountDraft: FC = () => {
  const theme = useTheme()
  const { states } = useStoreContext()
  const { state } = useDeskingContext()
  const defaultCurrency = states?.dealDesk?.defaultCurrency?.symbol
  const isVehicleSelectedAndOrderSaved = state.isVehicleSelectedAndOrderSaved
  const isVehicleSelected = Object.keys(state.selection ?? {}).length > 0
  let calculationsData: any = {}

  if (isVehicleSelectedAndOrderSaved) {
    calculationsData = state.preview.calculationsBoxes
  } else if (isVehicleSelected) {
    const vehicleComparisonType = state?.vehiclesForComparison
    if (vehicleComparisonType === VehiclesForComparisonTypes.SINGLE) {
      calculationsData =
        state?.[state.vehiclesForComparison]?.Cash?.calculationsBoxes
    } else if (vehicleComparisonType === VehiclesForComparisonTypes.MULTIPLE) {
      calculationsData =
        state?.[state.vehiclesForComparison]?.Cash?.find((vehicleData: any) => vehicleData.vin == state.selectedVehicleVin)?.calculationsBoxes
    }
  }

  return (
    <Box theme={theme} className="vehicle-amount-wrap grey-bg">
      <Chip
        theme={theme}
        label={state?.preview?.status ?? 'DRAFT'}
        component="div"
        clickable={false}
        variant="filled"
        href=""
      />
      <Typography
        theme={theme}
        component="p"
        variant="caption"
        sx={{ mb: 1 }}
        className="text-overflow"
        children={
          <>
            {defaultCurrency}{' '}
            <Typography
              theme={theme}
              component="span"
              variant="h3"
              sx={{ verticalAlign: 'middle' }}
            >
              {formatNumber(cashDueAtSigningCalculator(calculationsData))}
            </Typography>
          </>
        }
      />
      <TableList
        withBg
        items={[
          {
            title: 'Deal ID',
            value: state?.order?.reference_number ?? '-'
          },
          {
            title: 'Deal Type',
            value: FinanceTypes.CASH
          }
        ]}
      />
    </Box>
  )
}

export default CashVehicleAmountDraft
