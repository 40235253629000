import { FinanceTypes } from '@app/desking/desking-context-provider'
import { IContractTerms, IStates } from '@models'
import { ICountry } from 'models/country.interface'
import { Dispatch, ReducerAction } from 'react'

enum DeskingActionTypes {
  ADD_STATE_CODE = 'ADD_STATE_CODE',
  ADD_DEFAULT_CURRENCY = 'ADD_DEFAULT_CURRENCY',
  ADD_CREDIT_TERM = 'ADD_CREDIT_TERM',
  ADD_CONTRACT_TERMS = 'ADD_CONTRACT_TERMS',
  ADD_ALLOWED_MILLAGE = 'ADD_ALLOWED_MILLAGE',
  ADD_DEALER_PROFILE = 'ADD_DEALER_PROFILE',
  ADD_DEALER_PREFERENCES = 'ADD_DEALER_PREFERENCES',
  ADD_DEALER_FEES = 'ADD_DEALER_FEES',
  ADD_RECALCULATION = 'ADD_RECALCULATION',
  ADD_STATES = 'ADD_STATES',
  ADD_COUNTRIES = 'ADD_COUNTRIES'
}

type DeskingActions =
  | { type: DeskingActionTypes.ADD_STATE_CODE; payload: string }
  | { type: DeskingActionTypes.ADD_DEFAULT_CURRENCY; payload: any }
  | { type: DeskingActionTypes.ADD_CONTRACT_TERMS; payload: any[] }
  | { type: DeskingActionTypes.ADD_CREDIT_TERM; payload: any[] }
  | { type: DeskingActionTypes.ADD_ALLOWED_MILLAGE; payload: any[] }
  | { type: DeskingActionTypes.ADD_DEALER_PROFILE; payload: any }
  | { type: DeskingActionTypes.ADD_DEALER_PREFERENCES; payload: any }
  | { type: DeskingActionTypes.ADD_DEALER_FEES; payload: any[] }
  | { type: DeskingActionTypes.ADD_RECALCULATION; payload: boolean }
  | { type: DeskingActionTypes.ADD_STATES; payload: IStates[] }
  | { type: DeskingActionTypes.ADD_COUNTRIES; payload: ICountry[] }

type EmptyObject = {}

export type ISelectOptions = {
  text: string
  value: number
}

export type DefaultCurrency = {
  symbol: string
  code: string
  id?: number
  is_active?: boolean
  is_deleted?: boolean
  name?: string
}

export type DealDeskingState = {
  stateCode: string
  defaultCurrency: DefaultCurrency
  contractTerms: any[]
  creditTerms: any[]
  contractTermOptionsFinance: IContractTerms[]
  contractTermOptionsLease: IContractTerms[]
  allowedMillages: any[]
  milleageOptions: any[]
  dealerProfile: any | EmptyObject
  dealerPreferences: any | EmptyObject
  dealerFees: any | EmptyObject
  recalculation: boolean
  states: IStates[]
  countries: ICountry[]
}

export const deskingInitialState: DealDeskingState = {
  stateCode: '',
  defaultCurrency: {
    code: '',
    symbol: ''
  },
  contractTerms: [],
  creditTerms: [],
  contractTermOptionsFinance: [],
  contractTermOptionsLease: [],
  allowedMillages: [],
  milleageOptions: [],
  dealerProfile: {},
  dealerPreferences: {},
  dealerFees: {},
  recalculation: false,
  states: [],
  countries: []
}

export const deskingReducer = (
  state: DealDeskingState,
  action: DeskingActions
): DealDeskingState => {
  switch (action.type) {
    case DeskingActionTypes.ADD_STATE_CODE:
      return { ...state, stateCode: action.payload }
    case DeskingActionTypes.ADD_DEFAULT_CURRENCY:
      return { ...state, defaultCurrency: action.payload }
    case DeskingActionTypes.ADD_CONTRACT_TERMS:
      const _contractTerms = action.payload.sort((a, b) => a.term - b.term)
      const contractTermOptionsFinance: IContractTerms[] = []
      const contractTermOptionsLease: IContractTerms[] = []

      _contractTerms.forEach((contractTerm) => {
        if (contractTerm.finance_type?.includes(FinanceTypes.FINANCE)) {
          contractTermOptionsFinance.push(contractTerm)
        }
        if (contractTerm.finance_type?.includes(FinanceTypes.LEASE)) {
          contractTermOptionsLease.push(contractTerm)
        }
      })
      return {
        ...state,
        contractTerms: _contractTerms,
        contractTermOptionsFinance,
        contractTermOptionsLease
      }
    case DeskingActionTypes.ADD_CREDIT_TERM:
      return { ...state, creditTerms: action.payload }
    case DeskingActionTypes.ADD_ALLOWED_MILLAGE:
      const _allowedMilleages = action.payload.sort((a, b) => a.value - b.value)
      return {
        ...state,
        allowedMillages: _allowedMilleages,
        milleageOptions: _allowedMilleages.map((allowedMillage) => ({
          text: `${parseInt(allowedMillage.value) / 1000}k`,
          value: allowedMillage.value
        }))
      }
    case DeskingActionTypes.ADD_DEALER_PROFILE:
      return { ...state, dealerProfile: action.payload }
    case DeskingActionTypes.ADD_DEALER_PREFERENCES:
      return { ...state, dealerPreferences: action.payload }
    case DeskingActionTypes.ADD_RECALCULATION:
      return { ...state, recalculation: action.payload }
    case DeskingActionTypes.ADD_STATES:
      return { ...state, states: action.payload }
    case DeskingActionTypes.ADD_COUNTRIES:
      return { ...state, countries: action.payload }
    default:
      return state
  }
}

export const setStateCode = (
  data: string,
  dispatch: Dispatch<ReducerAction<typeof deskingReducer>>
): void => {
  if (data) {
    dispatch({ type: DeskingActionTypes.ADD_STATE_CODE, payload: data })
  }
}

export const setDefaultCurrency = (
  data: any,
  dispatch: Dispatch<ReducerAction<typeof deskingReducer>>
): void => {
  if (data) {
    dispatch({ type: DeskingActionTypes.ADD_DEFAULT_CURRENCY, payload: data })
  }
}

export const setContractTermsAndOptions = (
  data: any[],
  dispatch: Dispatch<ReducerAction<typeof deskingReducer>>
): void => {
  if (data) {
    dispatch({ type: DeskingActionTypes.ADD_CONTRACT_TERMS, payload: data })
  }
}

export const setCreditTerms = (
  data: any[],
  dispatch: Dispatch<ReducerAction<typeof deskingReducer>>
): void => {
  if (data) {
    dispatch({ type: DeskingActionTypes.ADD_CREDIT_TERM, payload: data })
  }
}

export const setAllowedMilleageAndOptions = (
  data: any[],
  dispatch: Dispatch<ReducerAction<typeof deskingReducer>>
): void => {
  if (data) {
    dispatch({ type: DeskingActionTypes.ADD_ALLOWED_MILLAGE, payload: data })
  }
}

export const setDealerProfile = (
  data: any,
  dispatch: Dispatch<ReducerAction<typeof deskingReducer>>
): void => {
  if (data) {
    dispatch({ type: DeskingActionTypes.ADD_DEALER_PROFILE, payload: data })
  }
}

export const setDealerPreferences = (
  data: any,
  dispatch: Dispatch<ReducerAction<typeof deskingReducer>>
): void => {
  if (data) {
    dispatch({ type: DeskingActionTypes.ADD_DEALER_PREFERENCES, payload: data })
  }
}

export const setDealerFees = (
  data: any[],
  dispatch: Dispatch<ReducerAction<typeof deskingReducer>>
): void => {
  if (data) {
    dispatch({ type: DeskingActionTypes.ADD_DEALER_FEES, payload: data })
  }
}
export const setRecalculation = (
  data: boolean,
  dispatch: Dispatch<ReducerAction<typeof deskingReducer>>
): void => {
  dispatch({ type: DeskingActionTypes.ADD_RECALCULATION, payload: data })
}

export const setStates = (
  data: any[],
  dispatch: Dispatch<ReducerAction<typeof deskingReducer>>
): void => {
  if (data) {
    dispatch({ type: DeskingActionTypes.ADD_STATES, payload: data })
  }
}

export const setCountries = (
  data: any[],
  dispatch: Dispatch<ReducerAction<typeof deskingReducer>>
): void => {
  if (data) {
    dispatch({ type: DeskingActionTypes.ADD_COUNTRIES, payload: data })
  }
}

export default deskingReducer
