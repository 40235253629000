import { FC, memo, useEffect, useState } from 'react'
import { Box, HeaderSearch, PageHeader, Typography } from 'components'
import OrdersGridUi from '@app/order-management/order-view/orders-grid'
import { IDealerWorkQueueOrderGrid } from '@models'
import { Link, useTheme } from '@mui/material'
import { Button, Icon, Input, Select } from '@ntpkunity/controls'
import { CustomerColumns, WORKQUEUE_SCREEN_COMPONENTS } from '@helpers/constants'
import {
  useGetReadyCheckList,
  useGetSchedulePickUpRequest,
  useGetVehiclesAvailableConfirmation
} from '@apis/dealer-workqueue.service'
import { useStoreContext } from '@store/storeContext'
import { initialDealerWorkQueueState } from '@helpers/methods'

const DealerWorkQueueOrder: FC<IDealerWorkQueueOrderGrid> = ({
  heading,
  subheading,
  data,
  ordersLoading
}) => {
  const theme = useTheme()
  const { actions, states } = useStoreContext()
  const [searchfilterValue, setSearchFilterValue] = useState<string>('')
  const [inputFilterValue, setInputFilterValue] = useState<string>('')
  const [resetSearch, setResetSearch] = useState<string>('')
  const [counter, setCounter] = useState(0)

  const { mutate: getVehicleConfirmation, isLoading: vehcilceAvailableLoading } =
    useGetVehiclesAvailableConfirmation()
  const { mutate: getReadyCheckList, isLoading: readyChecklistLoading } = useGetReadyCheckList()
  const { mutate: getSchedulePickups, isLoading: schedulePickupLoading } =
    useGetSchedulePickUpRequest()

  const [selectItems] = useState([
    { text: 'VIN', value: CustomerColumns.VIN },
    { text: 'Stock Number', value: CustomerColumns.Stock_Number },
    { text: 'Deal Id', value: CustomerColumns.ORDER_IDENTIFIER },
    { text: 'First Name', value: CustomerColumns.FIRST_NAME },
    { text: 'Last Name', value: CustomerColumns.LAST_NAME }
  ])
  const namesSearchArray = ['first_name', 'last_name']
  const [open, setOpen] = useState<boolean>(false)

  const onSeacrhIconClick = () => {
    dealerWorkQueueSearch()
  }

  const dealerWorkQueueSearch = () => {
    // let requestBody = []
    let additionalParams: any[] = []
    const checkCondition =
      Object.keys(states?.selectedANDealer)?.length > 0 && states?.selectedANDealer?.id !== ''
    if (checkCondition) {
      addConditionToRequestBody(
        CustomerColumns.DEALER_ID,
        states?.selectedANDealer?.id,
        'Equals',
        additionalParams
      )
    }
    if (searchfilterValue == '' || inputFilterValue == '') {
      if (WORKQUEUE_SCREEN_COMPONENTS[0].heading == heading) {
        actions.setDealerVehicleAvailableConfirmationOrders(initialDealerWorkQueueState)
        getVehicleConfirmation({ additionalParams }, {})
      } else if (WORKQUEUE_SCREEN_COMPONENTS[1].heading == heading) {
        actions.setDealerGetReadyCheckList(initialDealerWorkQueueState)
        getReadyCheckList({ additionalParams }, {})
      } else {
        actions.setDealerSchedulePickups(initialDealerWorkQueueState)
        getSchedulePickups({ additionalParams }, {})
      }

      setOpen(!open)
    } else {
      let operator = ''
      if (!namesSearchArray.includes(searchfilterValue)) {
        operator = 'Equals'
      } else {
        operator = 'Contains'
      }
      addConditionToRequestBody(
        searchfilterValue,
        inputFilterValue.toUpperCase(),
        operator,
        additionalParams
      )
      if (WORKQUEUE_SCREEN_COMPONENTS[0].heading == heading) {
        actions.setDealerVehicleAvailableConfirmationOrders(initialDealerWorkQueueState)
        getVehicleConfirmation({ additionalParams }, {})
      } else if (WORKQUEUE_SCREEN_COMPONENTS[1].heading == heading) {
        actions.setDealerGetReadyCheckList(initialDealerWorkQueueState)
        getReadyCheckList({ additionalParams }, {})
      } else {
        actions.setDealerSchedulePickups(initialDealerWorkQueueState)
        getSchedulePickups({ additionalParams }, {})
      }

      setOpen(!open)
    }
  }

  const addConditionToRequestBody = (
    field: string,
    value: any,
    operator: string,
    requestBody: any[]
  ) => {
    // Push the condition object to the requestBody array
    requestBody.push({
      field,
      operator: operator,
      value,
      and_or: 'And'
    })
  }

  useEffect(() => {
    if (resetSearch) {
      dealerWorkQueueSearch()
    }
  }, [resetSearch])

  return (
    <>
      <PageHeader className="main-page-header">
        <HeaderSearch theme={theme} className={open ? 'show-search' : 'hide-search'}>
          <Link className="overlay" onClick={() => setOpen(!open)} />
          <Box theme={theme} className="search-wrap">
            <Box theme={theme} className="search-area">
              <Box theme={theme} className="search-dropdown">
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={'Search deals by'}
                  className="main-title"
                />
                <Select
                  disablePortal
                  theme={theme}
                  items={selectItems}
                  value={searchfilterValue}
                  onChange={(e: any) => setSearchFilterValue(e.target.value)}
                />
              </Box>
              <Input
                type="text"
                label="Enter your search here..."
                theme={theme}
                value={inputFilterValue}
                onChange={(e: any) => setInputFilterValue(e)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    dealerWorkQueueSearch()
                  }
                }}
                endAdornment={
                  <>
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="CloseBlack" />}
                      onClick={() => setInputFilterValue('')}
                    />
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="ResetIcon" />}
                      onClick={() => {
                        setSearchFilterValue('')
                        setInputFilterValue('')
                        setResetSearch(searchfilterValue + (counter + 1).toString())
                        setCounter((prevCounter) => prevCounter + 1)
                      }}
                    />
                  </>
                }
              />
              <Button
                theme={theme}
                primary
                className="btn-search"
                iconText={<Icon name="SearchIcon" />}
                onClick={onSeacrhIconClick}
              />
            </Box>
          </Box>
        </HeaderSearch>
        <Box flexGrow={'1'}>
          <Typography theme={theme} variant="h2" component="h2" className="title">
            {heading}
          </Typography>
          {data?.result && data?.result.length > 0 && (
            <Typography theme={theme} variant="subtitle1" component="p" className="counter">
              {data?.total_results}
            </Typography>
          )}
          <Typography theme={theme} variant="body2" component="span" className="caption">
            {subheading}
          </Typography>
        </Box>
        <Box
          theme={theme}
          className="action-area"
          flexShrink={0}
          display={'flex'}
          alignItems={'center'}
        >
          <Box theme={theme} sx={{ mr: 1 }}>
            <Button
              defaultBtn
              theme={theme}
              iconText={<Icon name="SearchIcon" />}
              onClick={() => setOpen(!open)}
            />
          </Box>
        </Box>
      </PageHeader>
      <Box theme={theme} sx={{ mb: 4 }}>
        <OrdersGridUi
          data={data}
          ordersLoading={
            ordersLoading ||
            vehcilceAvailableLoading ||
            readyChecklistLoading ||
            schedulePickupLoading
          }
          componentType={heading}
        />
      </Box>
      <Box theme={theme} sx={{ mb: 2 }}>
        <hr className="spr" />
      </Box>
    </>
  )
}

export default memo(DealerWorkQueueOrder)
