import { FC, Key, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { Box, Grid, Typography, Accordion as CustomAccordion } from '@ntpkunity/controls'
import { DetailCard, PageHeader, ProductBanner } from '@components'
import { useGetLeadDetails } from '@apis/lead-management.service'
import { useStoreContext } from '@store/storeContext'
import { useQueryClient } from 'react-query'
import { AddressType, QueryKeys } from '@helpers/enums'
import PropTypes from 'prop-types'
import PaymentBreakdown from '@app/order-management/order-summary/payment-breakdown'
import { FinanceTypes } from '@app/desking/desking-context-provider'

interface DetailsRowProps {
  label?: string
  value?: string | React.ReactNode
}

const DetailsRow: React.FC<DetailsRowProps> = ({ label, value }) => {
  const theme = useTheme()
  return (
    <Grid theme={theme} className="container" container>
      <Grid theme={theme} item sm={3} xs={12}>
        <Typography theme={theme} variant="body2" component="h3" className="label">
          {label}
        </Typography>
      </Grid>
      <Grid theme={theme} item sm={9} xs={12}>
        <Typography theme={theme} variant="body2" component="span">
          {value || '-'}
        </Typography>
      </Grid>
    </Grid>
  )
}

DetailsRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}

interface Filters {
  [key: string]: string
}

const defaultFilters: Filters = {
  emailAddressFilter: '',
  yearMakeModelTrimFilter: '',
  leadStatusFilter: '',
  leadIdFilter: '',
  firstNameFilter: '',
  lastNameFilter: '',
  contactNumberFilter: '',
  preferredCommunicationFilter: '',
  vinFilter: '',
  stockFilter: '',
  createdAtFilter: '',
  lastUpdatedAtFilter: '',
  lastSyncedStatusFilter: '',
  leadSourceFilter: '',
  dealIdFilter: '',
  dealStatusFilter: '',
  dealerNameFilter: ''
}

const LeadSummary: FC = () => {
  const theme = useTheme()
  const { id } = useParams<{ id: string }>()
  const [defaultCurrency, setDefaultCurrency] = useState('')
  const [leadData, setLeadData] = useState<Record<string, any> | null>(null)
  const { states } = useStoreContext()
  const queryClient = useQueryClient()
  const filtersWithId = {
    ...defaultFilters,
    leadIdFilter: id || ''
  }
  const { data } = useGetLeadDetails(states?.dealerAndLenderInfo?.id, -1, -1, filtersWithId)
  const mapVehicleDetailsToOrderInfo = (vehicleDetails: {
    trade_in_amount: number; annual_usage: any; rebate: any; security_deposit: any; finance_amount: any; capitalized_cost_reduction: any; due_at_signing: any; order_fees: any; payoff_amount: number; order_options: any; InstallationMode: any; applied_price: any; tax_amount: any; selling_price: any; internet_price: any; estimated_monthly_payment: any; monthly_sales_use_tax: any; contract_term: any; apr: any; unit_price: any; down_payment_value: any; order_tradein: { trade_in_amount: any; payoff_amount: any }; finance: { credit_rating: any; our_offer: any }; make: any; model: any; trim_description: any; year: any; vin: any; stock_number: any; mileage: any; type: any; photo_urls: any; status: any; lead_id: any; net_finance_amount: any; finance_type: any
}) => {
      return {
      estimated_monthly_payment: vehicleDetails?.estimated_monthly_payment,
      monthly_sales_use_tax: vehicleDetails?.monthly_sales_use_tax,
      contract_term: vehicleDetails?.contract_term,
      capitalized_cost_reduction: vehicleDetails?.capitalized_cost_reduction,
      finance_amount: vehicleDetails?.finance_amount,
      security_deposit: vehicleDetails?.security_deposit,
      apr: vehicleDetails?.apr,
      tax_amount: vehicleDetails?.tax_amount,
      due_at_signing: vehicleDetails?.due_at_signing,
      rebate: vehicleDetails?.rebate,
      annual_usage: vehicleDetails?.annual_usage,
      selling_price: vehicleDetails?.selling_price,
      order_asset: {
          unit_price: vehicleDetails?.unit_price
        },
      ...(vehicleDetails?.order_tradein ? {
        order_tradein: {
          trade_in_amount: vehicleDetails?.order_tradein?.trade_in_amount || vehicleDetails?.trade_in_amount || 0,
          payoff_amount: vehicleDetails?.order_tradein?.payoff_amount || vehicleDetails?.payoff_amount || 0
        }
      } : {}),
      down_payment: ((vehicleDetails?.down_payment_value / vehicleDetails?.unit_price) * 100) || 0,
      order_options: vehicleDetails?.order_options ? vehicleDetails.order_options : [
        {
          applied_price: vehicleDetails?.applied_price || 0
        }
      ],
        order_fees: vehicleDetails?.order_fees ? vehicleDetails.order_fees : [
        {
          applied_price: vehicleDetails?.applied_price || 0
        }
      ],
      finance: {
        credit_rating: vehicleDetails?.finance?.credit_rating,
        our_offer: vehicleDetails?.finance?.our_offer
      },
      make: vehicleDetails?.make,
      model: vehicleDetails?.model,
      trim_description: vehicleDetails?.trim_description,
      year: vehicleDetails?.year,
      vin: vehicleDetails?.vin,
      stock_number: vehicleDetails?.stock_number,
      mileage: vehicleDetails?.mileage,
      type: vehicleDetails?.type,
      photo_urls: vehicleDetails?.photo_urls,
      status: vehicleDetails?.status,
      lead_id: vehicleDetails?.lead_id,
      net_finance_amount: vehicleDetails?.net_finance_amount,
      finance_type: vehicleDetails?.finance_type
    }
  }

  useEffect(() => {
    if (data?.data && id) {
      const lead = data?.data?.[0]
      if (lead) {
        const vehicle = lead?.data?.vehicle_details?.find(
          (vehicle: any) => vehicle?.id === lead?.data?.vehicle_details?.[0]?.id
        )
        setLeadData({ ...lead, vehicleDetails: vehicle })
      } else {
        console.error(`No lead found for id: ${id}`)
        setLeadData(null)
      }
    }
  }, [data?.data, id])

  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])

  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0]?.symbol || '' : ''
    )
  }, [currencies])

  const customerDetails = leadData?.data?.customer_details
  const customerAddress = customerDetails?.customer_addresses?.find(
    (address: any) => address.address_type === AddressType.MAILING
  )
  const vehicleDetails = leadData?.data?.vehicle_details?.find(
    (vehicleDetail: any) => vehicleDetail?.lead_id === id
  )
  const coapplicantDetails = leadData?.data?.coapplicant_details
  const coApplicantMailingAddress = coapplicantDetails?.customer_addresses?.find(
    (address: any) => address.address_type === AddressType.MAILING
  )

  return (
    <LayoutWithSideNav theme={theme}>
      <Box theme={theme} sx={{ mb: 2.5 }}>
        <PageHeader className="main-page-header border-bottom" theme={theme}>
          <Box flexGrow={'1'} theme={theme}>
            <Typography theme={theme} variant="h2" component="h2" className="title">
              Lead Summary
            </Typography>
            <Typography theme={theme} variant="body2" component="p" className="caption">
              You can review the lead details below and reach out to the customer
            </Typography>
          </Box>
        </PageHeader>
      </Box>
      <ProductBanner theme={theme} className="product-banner">
        <Box theme={theme} className="pb-wrap">
          <Box theme={theme} className="thumbnail">
            <img src={vehicleDetails?.photo_urls?.[0]?.location || ''} alt="Product" />
          </Box>
          <Box theme={theme} className="product-details">
            <Grid theme={theme} container columnSpacing={2}>
              <Grid theme={theme} item sm={6} xs={12}>
                <Box theme={theme} className="title-md">
                  {`${vehicleDetails?.make || '-'} ${vehicleDetails?.model || '-'}`}
                </Box>
                <Box theme={theme} className="title-lg">
                  {vehicleDetails?.trim_description || '-'}
                </Box>
                <Box theme={theme} className="title-sm">
                  Lead ID: <b>{vehicleDetails?.lead_id || '-'}</b>, Status:{' '}
                  {vehicleDetails?.status || '-'}
                </Box>
                <Box theme={theme} className="title-sm">
                  VIN: <b>{vehicleDetails?.vin || '-'}</b>
                </Box>
              </Grid>
              <Grid theme={theme} item sm={6} xs={12} textAlign="right">
                <Box theme={theme} className="actions-wrap">
                  <Box theme={theme} className="action-details">
                    <Box theme={theme} className="title-md">
                      {vehicleDetails?.finance_type === FinanceTypes.CASH
                        ? "Total Payable Amount"
                        : "Net Financed Amount"}
                    </Box>
                    <Box theme={theme} className="title-lg">
                      {defaultCurrency}
                      {vehicleDetails?.net_finance_amount
                        ? Math.abs(vehicleDetails?.net_finance_amount).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          })
                        : '-'}
                    </Box>
                    {vehicleDetails?.finance_type !== FinanceTypes.CASH && (
                      <>
                    <Box theme={theme} className="title-sm">
                      Deal Type:{' '}
                      <b>
                        {vehicleDetails?.contract_term && vehicleDetails?.finance_type
                          ? `${vehicleDetails?.contract_term} Months ${vehicleDetails?.finance_type}`
                          : '-'}
                      </b>
                      , Rate:{' '}
                      <b>
                        {vehicleDetails?.apr
                          ? `${vehicleDetails?.apr.toLocaleString(undefined, {
                              maximumFractionDigits: 4,
                              minimumFractionDigits: 4
                            })}%`
                          : '-'}
                      </b>
                    </Box>
                    <Box theme={theme} className="title-sm">
                      <>Est. Periodic Payment: </>
                      <b>
                        {defaultCurrency}
                        {vehicleDetails?.estimated_monthly_payment
                          ? Math.abs(vehicleDetails?.estimated_monthly_payment).toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                              }
                            )
                          : '-'}
                      </b>
                    </Box>
                      </>
                    )}
                    {vehicleDetails?.finance_type === FinanceTypes.CASH && (
                      <Box theme={theme} className="title-sm">
                        Deal Type:{' '}
                        <b>
                          {vehicleDetails?.finance_type
                            ? `${vehicleDetails?.finance_type}`
                            : '-'}
                        </b>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </ProductBanner>
      <Grid theme={theme} className="container" container spacing={2}>
        <Grid theme={theme} item xl={8} lg={8} md={8} sm={12} xs={12}>
          <DetailCard className="detail-card" mb={2}>
            <Typography theme={theme} mb={2} component="h4" variant="h4">
              Customer Details
            </Typography>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Name"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={
                    [customerDetails?.first_name, customerDetails?.last_name]
                      ?.filter(Boolean)
                      ?.join(' ') || '-'
                  }
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Address"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={
                    [
                      customerAddress?.address_line_1,
                      customerAddress?.city,
                      customerAddress?.state_name,
                      customerAddress?.zip_code
                    ]
                      ?.filter(Boolean)
                      ?.join(', ') || '-'
                  }
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Email"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={customerDetails?.email || '-'}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Credit Score"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={vehicleDetails?.finance?.credit_rating || '-'}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Mobile Phone Number"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={customerDetails?.mobile_number || '-'}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Preferred Communication"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={customerDetails?.preferred_contact || '-'}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="License Number"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={
                    (customerDetails?.license_info?.license_number ??
                      customerDetails?.license_number) ||
                    '-'
                  }
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Date of Birth"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={
                    customerDetails?.license_info?.date_of_birth?.split('T')[0] ||
                    customerDetails?.date_of_birth?.split('T')[0] ||
                    '-'
                  }
                />
              </Grid>
            </Grid>
            {coapplicantDetails && (
              <Box theme={theme} className="accordion-c" pt={2}>
                <CustomAccordion
                  theme={theme}
                  items={[
                    {
                      isExpanded: false,
                      key: '0',
                      title: 'Co-Applicant',
                      content: (
                        <>
                          <DetailsRow
                            label="Name"
                            value={
                              [coapplicantDetails?.first_name, coapplicantDetails?.last_name]
                                ?.filter(Boolean)
                                ?.join(' ') || '-'
                            }
                          />
                          <DetailsRow
                            label="Address"
                            value={
                              [
                                coApplicantMailingAddress?.address_line_1,
                                coApplicantMailingAddress?.city,
                                coApplicantMailingAddress?.state_name,
                                coApplicantMailingAddress?.zip_code
                              ]
                                ?.filter(Boolean)
                                ?.join(', ') || '-'
                            }
                          />
                          <DetailsRow label="Email" value={coapplicantDetails?.email || '-'} />
                          <DetailsRow
                            label="Mobile Phone Number"
                            value={coapplicantDetails?.mobile_number || '-'}
                          />
                          <DetailsRow
                            label="Preferred Communication"
                            value={coapplicantDetails?.preferred_contact || '-'}
                          />
                          <DetailsRow
                            label="License Number"
                            value={
                              (coapplicantDetails?.license_info?.license_number ??
                                coapplicantDetails?.license_number) ||
                              '-'
                            }
                          />
                          <DetailsRow
                            label="Date of Birth"
                            value={
                              (coapplicantDetails?.license_info?.date_of_birth?.split('T')[0] ??
                                coapplicantDetails?.date_of_birth?.split('T')[0]) ||
                              '-'
                            }
                          />
                        </>
                      )
                    }
                  ]}
                  shouldCollapse={true}
                />
              </Box>
            )}
          </DetailCard>
          <DetailCard className="detail-card" mb={2}>
            <Typography theme={theme} mb={2} component="h4" variant="h4">
              Asset Details
            </Typography>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Asset"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={`${vehicleDetails?.make || '-'} ${vehicleDetails?.model || '-'} ${
                    vehicleDetails?.trim_description || '-'
                  }`}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Asset Condition"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={`${vehicleDetails?.type || '-'}`}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Stock Number"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={`${vehicleDetails?.stock_number || '-'}`}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Current Mileage"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={vehicleDetails?.mileage ? `${vehicleDetails?.mileage} Miles` : '-'}
                />
              </Grid>
            </Grid>
          </DetailCard>
          {vehicleDetails?.order_tradein && (
            <DetailCard className="detail-card" mb={2}>
              <Box className="card-body" theme={theme}>
                <Box theme={theme} mb={2}>
                  <Typography theme={theme} component="h4" variant="h4" children="Trade-In" />
                  <Grid theme={theme} className="container" container>
                    <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                      <Typography
                        theme={theme}
                        variant="body2"
                        component="h3"
                        className="label"
                        children={
                          <>
                            {vehicleDetails?.order_tradein?.year || '-'}{' '}
                            {vehicleDetails?.order_tradein?.make || '-'}
                            {vehicleDetails?.order_tradein?.model || '-'}
                          </>
                        }
                      />
                      <Typography
                        theme={theme}
                        variant="subtitle1"
                        component="div"
                        className="card-title"
                        children={vehicleDetails?.order_tradein?.trim || '-'}
                      />
                    </Grid>
                    {vehicleDetails?.order_tradein?.asset_image?.length > 0 && (
                      <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                        <Box theme={theme} className="thumbnail">
                          <img
                            src={vehicleDetails?.order_tradein?.asset_image?.[0] || ''}
                            alt="Product"
                          />
                        </Box>
                      </Grid>
                    )}
                    <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                      <Box theme={theme} className="priceList">
                        <Typography
                          theme={theme}
                          className="card-title"
                          mb={1}
                          component="h4"
                          variant="h4"
                          children={
                            vehicleDetails?.order_tradein?.order_tradein_amount != null ? (
                              <>
                                {defaultCurrency}
                                {Math.abs(vehicleDetails?.order_tradein?.order_tradein_amount).toLocaleString(
                                  undefined,
                                  {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                  }
                                )}
                              </>
                            ) : (
                              '-'
                            )
                          }
                        />
                        <Box theme={theme} className="price-detail">
                          <Typography
                            theme={theme}
                            className="detail offer"
                            component="span"
                            variant="caption"
                            children="Our Offer:&nbsp;"
                          />
                          <Typography
                            theme={theme}
                            className="detail price"
                            component="span"
                            variant="caption"
                            children={
                              <>
                                {vehicleDetails?.finance?.our_offer != null ? (
                                  <>
                                    {defaultCurrency}
                                    {Math.abs(vehicleDetails?.finance?.our_offer).toLocaleString(
                                      undefined,
                                      {
                                        maximumFractionDigits: 4,
                                        minimumFractionDigits: 2
                                      }
                                    )}
                                  </>
                                ) : (
                                  '-'
                                )}
                              </>
                            }
                          />
                        </Box>
                        <Box theme={theme} className="price-detail">
                          <Typography
                            theme={theme}
                            className="detail offer"
                            component="span"
                            variant="caption"
                            children={<>Lease Balance:&nbsp; </>}
                          />
                          <Typography
                            theme={theme}
                            className="detail price"
                            component="span"
                            variant="caption"
                            children={
                              <>
                                {vehicleDetails?.order_tradein?.payoff_amount != null ? (
                                  <>
                                    {defaultCurrency}
                                    {Math.abs(
                                      vehicleDetails?.order_tradein?.payoff_amount
                                    ).toLocaleString(undefined, {
                                      maximumFractionDigits: 4,
                                      minimumFractionDigits: 2
                                    })}
                                  </>
                                ) : (
                                  '-'
                                )}
                              </>
                            }
                          />
                        </Box>
                        <Box theme={theme} className="price-detail">
                          <Typography
                            theme={theme}
                            className="detail offer"
                            component="span"
                            variant="caption"
                            children="Net Trade-In Amount:&nbsp;"
                          />
                          <Typography
                            theme={theme}
                            className="detail price"
                            component="span"
                            variant="caption"
                            children={
                              vehicleDetails?.order_tradein?.trade_in_amount != null ? (
                                <>
                                  {defaultCurrency}
                                  {Math.abs(
                                    vehicleDetails?.order_tradein?.trade_in_amount
                                  ).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                  })}
                                </>
                              ) : (
                                '-'
                              )
                            }
                          />
                        </Box>
                        <Box theme={theme} className="price-detail">
                          <Typography
                            theme={theme}
                            className="detail offer"
                            component="span"
                            variant="caption"
                            children="Estimated Mileage:&nbsp;"
                          />
                          <Typography
                            theme={theme}
                            className="detail price"
                            component="span"
                            variant="caption"
                            children={
                              <> {vehicleDetails?.order_tradein?.mileage?.toLocaleString() || '-'} </>
                            }
                          />
                        </Box>
                        <Box theme={theme} className="price-detail">
                          <Typography
                            theme={theme}
                            className="detail offer"
                            component="span"
                            variant="caption"
                            children="Condition:&nbsp;"
                          />
                          <Typography
                            theme={theme}
                            className="detail price"
                            component="span"
                            variant="caption"
                            children={<> {vehicleDetails?.order_tradein?.condition || '-'} </>}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </DetailCard>
          )}
          <DetailCard className="detail-card" mb={2}>
            <Typography theme={theme} mb={2} component="h4" variant="h4">
              Search Details
            </Typography>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Asset Condition"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={`${
                    leadData?.data?.vehicle_search_criteria?.[0]?.asset_conditions[0] || '-'
                  }`}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Selling Price"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={
                    vehicleDetails?.internet_price ? (
                      <>
                        {defaultCurrency}
                        {Math.abs(vehicleDetails?.internet_price).toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                      </>
                    ) : (
                      '-'
                    )
                  }
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Year"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={vehicleDetails?.year ?? '-'}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Make/Model/Trim"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={
                    leadData && leadData?.data?.vehicle_search_criteria?.length > 0
                      ? leadData.data.vehicle_search_criteria
                          .map(
                            (
                              criteria: { makes: any[]; models: any[]; trims: any[] },
                              index: Key | null | undefined
                            ) => {
                              const parts = [
                                criteria.makes?.[0],
                                criteria.models?.[0],
                                criteria.trims?.[0]
                              ].filter(Boolean)

                              return parts.length > 0 ? (
                                <div key={index}>{parts.join(', ')}</div>
                              ) : null
                            }
                          )
                          .filter(Boolean)
                      : '-'
                  }
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="VIN"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={`${leadData?.data?.vehicle_search_criteria?.[0]?.vin || '-'}`}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Stock Number"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={`${leadData?.data?.vehicle_search_criteria?.[0]?.stock || '-'}`}
                />
              </Grid>
            </Grid>
          </DetailCard>
        </Grid>
        <PaymentBreakdown order_info={mapVehicleDetailsToOrderInfo(vehicleDetails)} />
      </Grid>
    </LayoutWithSideNav>
  )
}

export default LeadSummary
