import { FC, ReactNode } from 'react'
import cls from 'clsx'
import { useTheme } from '@mui/material'
import { Box, Typography } from '@ntpkunity/controls'
import { TableListWrap } from './table-list.style'

interface ListItemProps {
  listTitle?: string | ReactNode
  withBg?: boolean
  items: Array<{
    title?: string | ReactNode
    value?: string | ReactNode
    editable?: boolean
    hasPrimaryText?: boolean
    hasBoldText?: boolean
    width?: string
  }>
}

export const TableList: FC<ListItemProps> = ({ listTitle, items, withBg }) => {
  const theme = useTheme()
  return (
    <TableListWrap
      theme={theme}
      className={cls({ 'table-list-wrap': true, 'with-bg': withBg })}
      mb={2}
    >
      <Typography
        theme={theme}
        variant="body2"
        component="p"
        className=" text-overflow"
        mb={1}
        children={<b>{listTitle}</b>}
      />
      <Box theme={theme} className="table-list-ul">
        {items.map((item, index) => (
          <Box theme={theme} className="table-list-li" key={index}>
            <Box theme={theme} className="label-col text-overflow" textAlign="left" width={item.width}>
              <Typography
                theme={theme}
                component="label"
                variant="caption"
                textAlign="left"
                className="list-title"
                children={item.title}
              />
            </Box>
            {item.editable ? (
              <Box theme={theme} className="editable-col" textAlign="right">
                {item.value}
              </Box>
            ) : (
              <Box theme={theme} className="value-col" textAlign="right" width={item.width}>
                <Typography
                  theme={theme}
                  component="div"
                  variant="caption"
                  className={`list-value text-overflow ${item.hasPrimaryText ? 'text-primary' : ''
                    } ${item.hasBoldText ? 'fw-700' : ''}`}
                  children={item.value}
                />
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </TableListWrap>
  )
}
