import { FC, Key, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { Box, Button, Dialog, DuoTab, Icon, Typography } from '@ntpkunity/controls'
import { BtnAddWrap, DuoTabWrap, VehicleList } from '@components'
import EditFees from 'controls/fees/edit-fees'
import AddOnOption from 'controls/addon-control/addon'
import { DeskingActionTypes, FinanceType, useDeskingContext } from './desking-context-provider'
import { useModal } from 'react-modal-hook'
import { useSearchParams } from 'react-router-dom'
import FinanceInsuranceControl from 'controls/FinanceInsuranceControl/finance-insurance-control'
import { useStoreContext } from '@store/storeContext'
import { AvailableFinanceTypes } from '@helpers/enums'

export enum TabName {
  FNI = 'f&i',
  OPTIONS = 'options',
  FEES = 'fees',
  MSP = 'Maint. & Service Products'
}

export const Tabs = {
  [TabName.FNI]: 0,
  [TabName.OPTIONS]: 1,
  [TabName.FEES]: 2,
  [TabName.MSP]: 3
}

interface Fni {
  finance: any[]
  lease: any[]
}

export interface IExtrasPopupProps {
  vin: string
  vehiclesData: Map<string, any>
  finance_type: FinanceType
  tabName: TabName
  data: {
    dealer_options: any[]
    fees: Object
    fni?: Fni
  }
  hide: () => void
  saveExtrasData: (extrasData: any) => void
}

export const ExtrasPopup: FC<Partial<IExtrasPopupProps>> = ({
  vin,
  vehiclesData,
  finance_type,
  tabName,
  data,
  hide,
  saveExtrasData
}) => {
  const theme = useTheme()
  const defaultTab = Tabs[tabName as TabName] ?? -1

  const { dealer_options = [], fees = {}, fni = { finance: [], lease: [] } } = data || {}

  const { states } = useStoreContext()

  const [extrasData, setExtrasData] = useState({
    dealer_options,
    fees,
    fni
  })

  const updateExtrasData = (type: 'dealer_options' | 'fees', newData: any) => {
    setExtrasData((prev) => ({ ...prev, [type]: newData }))
  }

  const [fniData, setFniData] = useState(fni)
  const defaultCurrency = (states?.dealDesk?.defaultCurrency as { symbol: string })?.symbol
  const apr = vehiclesData
    ?.get(vin as string)
    ?.program?.find((x: any) => x.finance_type === finance_type)?.final_customer_rate

  return (
    <Dialog
      variant={undefined}
      size="xl"
      title="Add Products"
      open={true}
      onCloseDialog={hide}
      customFooter={
        <Button
          theme={theme}
          primary
          text="Save Changes"
          onClick={() => {
            saveExtrasData?.({ ...extrasData, fni: fniData })
          }}
        />
      }
      theme={theme}
      children={
        <>
          <DuoTabWrap theme={theme} className="duo-tab-wrap align-center sm fixed-child-tab-height">
            <DuoTab
              theme={theme}
              defaultTabIndex={defaultTab}
              items={[
                {
                  title: 'F&I',
                  content: (
                    <FinanceInsuranceControl
                      dealerProfileData={states?.dealDesk?.dealerProfile}
                      defaultCurrency={defaultCurrency}
                      isMSP={false}
                      fniData={fniData}
                      setFniData={setFniData}
                      terms={
                        states?.dealDesk?.contractTerms?.filter(
                          (term: any) =>
                            term.id == states?.dealDesk?.dealerPreferences?.default_term_id
                        )?.[0]?.term
                      }
                      apr={apr}
                      selectedFinanceType={finance_type as 'Finance' | 'Lease'}
                      selectedVehicle={vehiclesData?.get(vin as string)?.vehicle}
                    />
                  )
                },
                {
                  title: 'Options',
                  content: (
                    <AddOnOption
                      defaultOptions={dealer_options}
                      defaultCurrency={defaultCurrency}
                      modelName={vehiclesData?.get(vin as string)?.vehicle?.model}
                      setSelectedObjectsArray={(newOptions: any) =>
                        updateExtrasData('dealer_options', newOptions)
                      }
                      vin={vin ?? ''}
                    />
                  )
                },
                {
                  title: 'Fees',
                  content: (
                    <EditFees
                      dealerFees={fees}
                      financeType={finance_type ?? 'Finance'}
                      setDealerFees={(newFees: any) => updateExtrasData('fees', newFees)}
                    />
                  )
                },
                {
                  title: 'Maint. & Service Products',
                  content: (
                    <FinanceInsuranceControl
                      dealerProfileData={states?.dealDesk?.dealerProfile}
                      defaultCurrency={defaultCurrency}
                      isMSP={true}
                      fniData={fniData}
                      setFniData={setFniData}
                      terms={
                        states?.dealDesk?.contractTerms?.find(
                          (term: any) =>
                            term.id === states?.dealDesk?.dealerPreferences?.default_term_id
                        )?.term
                      }
                      apr={apr}
                      selectedFinanceType={finance_type as 'Finance' | 'Lease'}
                      selectedVehicle={vehiclesData?.get(vin as string)?.vehicle}
                    />
                  )
                }
              ]}
            />
          </DuoTabWrap>
        </>
      }
    />
  )
}

export const useExtrasModal = () => {
  const [params, setParams] = useState<Omit<IExtrasPopupProps, 'hide'> | null>(null)
  const { vin, vehiclesData, data, finance_type, tabName, saveExtrasData } = params ?? {}

  const [showPopup, hidePopup] = useModal(
    () => (
      <ExtrasPopup
        vin={vin}
        vehiclesData={vehiclesData}
        data={data}
        finance_type={finance_type}
        tabName={tabName ?? TabName.OPTIONS}
        hide={hideExtrasPopup}
        saveExtrasData={saveExtrasData}
      />
    ),
    [vin, vehiclesData, data, finance_type, tabName, saveExtrasData]
  )

  useEffect(() => {
    if (Object.keys(params ?? {}).length > 0) {
      showPopup()
    }
  }, [params])

  const showExtrasPopup = (params: Omit<IExtrasPopupProps, 'hide'>) => {
    setParams(params)
  }

  const hideExtrasPopup = () => {
    hidePopup()
    setParams(null)
  }

  return { showExtrasPopup, hideExtrasPopup }
}

interface VlItemProps {
  data: any
  nameKey: string
  priceKey: string
}

const VlItem: FC<VlItemProps> = ({ data, nameKey, priceKey }) => {
  const theme = useTheme()
  const { formatCurrency } = useDeskingContext()

  return (
    <>
      <Box theme={theme} className="vl-img-wrap">
        <img src={data?.image?.[0]?.location ?? require('../../../src/public/assets/images/no-img.svg')} alt="Car" />
      </Box>
      <Box theme={theme} className="vl-details">
        <Typography
          theme={theme}
          variant="caption"
          component="p"
          className=" text-overflow vl-title-ul"
          children={data?.[nameKey]}
        />
        <Typography
          theme={theme}
          variant="caption"
          component="p"
          className=" text-overflow vl-title-ul"
          children={<b>{formatCurrency(data?.is_price_inclusive ? 0 : data?.[priceKey] ?? 0)}</b>}
        />
      </Box>
    </>
  )
}

const AddOpptions: FC<{ tabName: keyof typeof Tabs }> = ({ tabName }) => {
  const theme = useTheme()
  const {
    state: {
      order,
      vehiclesData,
      vehiclesVins,
      finance_type,
      isVehicleSelectedAndOrderSaved,
      preview,
      submission_tier
    },
    dispatch
  } = useDeskingContext()
  const [searchParams] = useSearchParams()
  const orderReferenceId = searchParams.get('reference_id')

  const vin = isVehicleSelectedAndOrderSaved ? preview.vin : vehiclesVins[0]
  const { dealer_options, fees, fni } = vehiclesData?.get(vin) ?? {}

  const { showExtrasPopup, hideExtrasPopup } = useExtrasModal()

  const handleExtrasData = (data: any) => {
    dispatch({
      type: DeskingActionTypes.UPDATE_OPTIONS_FEES_FNI,
      payload: {
        vinUOFF: vin,
        // fni: [],
        ...data
      }
    })
    hideExtrasPopup()
  }

  const renderItems = () => {
    let dataList = []
    let nameKey = ''
    let priceKey = ''

    const getFnIData = (isMSP: boolean) => {
      const fnIData =
        order?.order_fnI?.length > 0
          ? order.order_fnI
          : finance_type === AvailableFinanceTypes.FINANCE
          ? fni?.finance || []
          : fni?.lease || []
      return fnIData.filter((fniItem: any) => fniItem.MSP === isMSP || fniItem.is_msp === isMSP)
    }

    switch (tabName) {
      case 'options':
        dataList = dealer_options || []
        nameKey = 'product_name'
        priceKey = orderReferenceId ? 'applied_price' : 'price'
        break
      case 'fees':
        dataList = fees?.[finance_type] || []
        nameKey = 'fee_name'
        priceKey = orderReferenceId ? 'applied_price' : 'default_amount'
        break
      case 'f&i':
        dataList = getFnIData(false)
        nameKey = 'product_name'
        priceKey = order?.order_fnI?.length > 0 ? 'applied_price' : 'price'
        break
      case 'Maint. & Service Products':
        dataList = getFnIData(true)
        nameKey = 'product_name'
        priceKey = order?.order_fnI?.length > 0 ? 'applied_price' : 'price'
        break
      default:
        return null
    }
    return dataList.map((value: any, colIndex: Key | null | undefined) => (
      <Box theme={theme} className="vl-item sm item-li" mb={1} key={colIndex}>
        <VlItem data={value} nameKey={nameKey} priceKey={priceKey} />
      </Box>
    ))
  }

  return (
    <>
      <BtnAddWrap theme={theme} className="btn-add-wrap">
        <Button
          theme={theme}
          fullWidth
          startIcon={<Icon name="AddCircleIcon" />}
          disabled={
            !vin || !Object.keys(vehiclesData.get(vin) ?? {}).length || Boolean(submission_tier)
          }
          text={'Add ' + tabName}
          size="medium"
          onClick={() => {
            showExtrasPopup({
              vin,
              vehiclesData,
              finance_type,
              tabName,
              data: {
                dealer_options,
                fees: fees,
                fni: {
                  finance:
                    finance_type === AvailableFinanceTypes.FINANCE && order?.order_fnI?.length > 0
                      ? order?.order_fnI
                      : fni?.finance || [],
                  lease:
                    finance_type === AvailableFinanceTypes.LEASE && order?.order_fnI?.length > 0
                      ? order?.order_fnI
                      : fni?.lease || []
                }
              },
              saveExtrasData: handleExtrasData
            })
          }}
        />
      </BtnAddWrap>
      <VehicleList theme={theme} className="vehicle-list" mt={1}>
        <Box theme={theme} className="vl-scroll">
          {renderItems()}
        </Box>
      </VehicleList>
    </>
  )
}

export default AddOpptions
