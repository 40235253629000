import * as yup from 'yup'
// import { validatePhoneNumber } from '@helpers/methods'
import { ZIP_CODE_REGEX_MAP } from '@helpers/constants'

export function createDisclosureValidatorsSchema(permissions: any, isUsAddress: boolean) {
  const schema = yup.object().shape({
    customer: yup
      .object()
      .shape({
        marital_status: yup.string().nullable().notRequired()
      })
      .nullable(),
    spouse: yup
      .object()
      .shape({
        first_name: yup.string().when('$a', {
          is: () => permissions?.spouse?.first_name?.isMandatory,
          then: yup
            .string()
            .nullable()
            .required('First Name is required')
            .max(100, 'First Name maximum length is up to 100 characters')
            .matches(/^[A-Za-z]*$/, 'Only alphabets are allowed'),
          otherwise: yup.string().nullable().notRequired()
        }),
        last_name: yup.string().when('$a', {
          is: () => permissions?.spouse?.last_name?.isMandatory,
          then: yup
            .string()
            .nullable()
            .required('Last Name is required')
            .max(100, 'Last Name maximum length is up to 100 characters')
            .matches(/^[A-Za-z]*$/, 'Only alphabets are allowed'),
          otherwise: yup.string().nullable().notRequired()
        }),
        address: permissions?.spouse?.address?.isMandatory
          ? yup.object().shape({
              address_line_1: yup.string().nullable().required('Address Line 1 is required'),
              city: yup.string().nullable().required('City is required'),
              state_name: yup.string().nullable().required('State Name is required'),
              state_id: yup.string().nullable().required('State Name is required'),
              zip_code: yup
                .string()
                .nullable()
                .test(function (value) {
                  if (!value) {
                    return this.createError({ message: 'Zip Code is required' })
                  }

                  if (isUsAddress && !ZIP_CODE_REGEX_MAP.US.regex.test(value)) {
                    return this.createError({ message: ZIP_CODE_REGEX_MAP.US.errorMessage })
                  }

                  return true
                })
            })
          : yup.object().shape({})
      })
      .nullable(),
    is_spouse_as_coapplicant: yup.boolean().nullable().notRequired(),
    is_negotiated_with_applicant: yup.boolean().nullable().notRequired(),
    is_negotiated_with_coapplicant: yup.boolean().nullable().notRequired(),
    tax_credit_amount: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .notRequired(),
    right_to_cancel: yup.number().when('$a', {
      is: () => permissions?.right_to_cancel?.isMandatory,
      then: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .required('No. Of Days are required'),
      otherwise: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .notRequired()
    }),
    purchase_option_fee: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .notRequired(),
    tax_exemption: yup.boolean().nullable().notRequired(),
    exemption_level: yup.string().nullable().notRequired(),
    applicant_negotiated_languages: yup.array().nullable().notRequired(),
    coapplicant_negotiated_languages: yup.array().nullable().notRequired(),
    order_asset: yup.object().shape({
      type: yup.string().when('$a', {
        is: () => permissions?.order_asset?.type?.isMandatory,
        then: yup.string().nullable().required('Asset Condition is required'),
        otherwise: yup.string().nullable().notRequired()
      }),
      engine_cylinder: yup.number().when('$a', {
        is: () => permissions?.order_asset?.engine_cylinder?.isMandatory,
        then: yup.number().nullable().required('No. Of Engine Cylinders is required'),
        otherwise: yup
          .number()
          .transform((value) => (Number.isNaN(value) ? null : value))
          .nullable()
          .notRequired()
      }),
      program_options: yup.array().when('$a', {
        is: () => permissions?.order_asset?.program_options?.isMandatory,
        then: yup.array().of(yup.string().nullable().required()).nullable().min(1, "You need at least 1 program Option").required('Program Options is required'),
        otherwise: yup.array().nullable().notRequired()
      }),
      transmission_type: yup.string().when('$a', {
        is: () => permissions?.order_asset?.transmission_type?.isMandatory,
        then: yup.string().nullable().required('Transmission Type is required'),
        otherwise: yup.string().nullable().notRequired()
      }),
      vehicle_uses: yup.string().when('$a', {
        is: () => permissions?.order_asset?.vehicle_uses?.isMandatory,
        then: yup.string().nullable().required('Vehicle Prior Use is required'),
        otherwise: yup.string().nullable().notRequired()
      }),
      mileage: yup.number().when('$a', {
        is: () => permissions?.order_asset?.mileage?.isMandatory,
        then: yup.number().nullable().required('Milage is required'),
        otherwise: yup
          .number()
          .transform((value) => (Number.isNaN(value) ? null : value))
          .nullable()
          .notRequired()
      }),
      odometer_disclaimer: yup.string().when('$a', {
        is: () => permissions?.order_asset?.odometer_disclaimer?.isMandatory,
        then: yup.string().nullable().required('Select an option to proceed'),
        otherwise: yup.string().nullable().notRequired()
      })
    })
  })
  return schema
}
