import { FC, useState, useEffect } from 'react'
import { InputAdornment, useTheme } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { Divider, PriceList, DialogTotal } from 'components'
import { useStoreContext } from '@store/storeContext'
import { Button, Grid, Box, Input, Typography, DuoTab, Icon, Dialog } from '@ntpkunity/controls'
import { styled } from '@mui/material/styles'
import { FinancialAndInsurancePopup } from '../popup-screens'
import {
  useGetFinancialInsuranceProductsByDealerId,
  useGetFnIProductRate
} from '@apis/financial-insurance.service'
import { useGetIntegrationByProviderName } from '@apis/customer-detail.service'
import { AvailableFinanceTypes, IntegrationProvider, LenderIntegrationType } from '@helpers/enums'

const StickyDuoTab = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'inline-block',
  '&.mini-tabs': {
    '.TabsUnstyled-root': {
      '.tab-list-default': {
        width: '400px',
        margin: 'auto',
        marginBottom: 24
      }
    }
  },
  '.tab-list-underline': {
    backgroundColor: theme.palette.common.white,
    position: 'sticky',
    top: -60,
    left: 0,
    zIndex: 1,
    width: '75%'
  },
  '.TabPanelUnstyled-root': {
    overflow: 'hidden',
    paddingTop: 16,
    marginTop: -16
  },
  '.search': {
    width: '25%',
    display: 'inline-block',
    borderBottom: '1px solid' + theme.palette.divider,
    backgroundColor: theme.palette.common.white,
    position: 'sticky',
    top: -60,
    right: 0,
    zIndex: 1,
    float: 'right',
    paddingLeft: 24,
    paddingTop: 8,
    '.MuiOutlinedInput-notchedOutline': {
      border: '0 solid transparent',
      borderRadius: 0
    },
    '.Mui-focused fieldset': {
      border: '0 solid transparent'
    },
    '.MuiInputAdornment-root': {
      position: 'absolute',
      right: 0,
      zIndex: -1,
      opacity: 0.75
    },
    '.MuiOutlinedInput-input': {
      paddingRight: '15px !important'
    }
  }
}))
interface IFormInputs {
  search: string
  text: string
}
const OrderFnIProducts: FC<{
  isDisable?: boolean
  selectedFandIProducts?: any
  fnIRatesReqData: any
  defaultCurrency?: string
  quotationParameterData?: any
  fniData?: any
  setFniData?: any
  programs?: any
  dealerProfileData?: any
  isOrderConditioned?: boolean
  //@ts-ignore
}> = ({
  isDisable,
  defaultCurrency,
  fniData,
  setFniData,
  programs,
  quotationParameterData,
  dealerProfileData,
  isOrderConditioned
}: any) => {
  const theme = useTheme()
  const [openFnIDialog, setOpenFnIDialog] = useState(false)
  const [productTypes, setProductTypes] = useState<Array<any>>([])
  const [products_info, setNewProductsInfo] = useState<any>([])
  const [activeTab, setActiveTab] = useState<any>(0)
  const [selectedFinanceItems, setSelectedFinanceItems] = useState([])
  const [selectedLeaseItems, setSelectedLeaseItems] = useState([])
  const [penEnabled, setPenEnabled] = useState<boolean>(false)
  const { mutate: getPenIntegrations } = useGetIntegrationByProviderName(
    IntegrationProvider.PEN,
    LenderIntegrationType.FNI_PRODUCTS
  )

  //There are prices when product is not selected and user change its coverage
  const [fniPrice, setFniPrice] = useState<{
    finance: Array<any>
    lease: Array<any>
  }>({ finance: [], lease: [] })
  // const [selectedLeaseItems, setSelectedLeaseItems] = useState([])
  const [terms, setTerms] = useState<any>()
  const { mutate: getFandIProducts, data: fniProducts } =
    useGetFinancialInsuranceProductsByDealerId()
  const { mutate: getFinanceProductRates, data: financeProductRates } = useGetFnIProductRate()
  const { mutate: getLeaseProductRates, data: leaseProductRates } = useGetFnIProductRate()
  //handle total of finance and lease
  const [sum, setSum] = useState<{
    finance: any
    lease: any
  }>({ finance: '0.00', lease: '0.00' })
  const { states } = useStoreContext()
  const handleFnIOpen = () => {
    setOpenFnIDialog(true)
    setFniPrice({
      finance: [],
      lease: []
    })
    setActiveTab(isOrderConditioned ? (programs[0].finance_type === 'Finance' ? 0 : 1) : 0)
    setSelectedFinanceItems(fniData?.finance?.length > 0 ? mapFnIArray(fniData?.finance) : [])
    setSelectedLeaseItems(fniData?.lease?.length > 0 ? mapFnIArray(fniData?.lease) : [])
    setSum({
      ...sum,
      finance: fniData?.finance?.length > 0 ? getTotal(fniData?.finance) : '0.00',
      lease: fniData?.lease?.length > 0 ? getTotal(fniData?.lease) : '0.00'
    })
  }

  useEffect(() => {
    if (
      openFnIDialog &&
      products_info?.length == fniProducts?.result?.length &&
      products_info?.length > 0
    ) {
      getFinanceProductRates({
        data: getRateRequestObject(products_info, AvailableFinanceTypes.FINANCE)
      })
      getLeaseProductRates({
        data: getRateRequestObject(products_info, AvailableFinanceTypes.LEASE)
      })
    }
  }, [openFnIDialog, products_info])

  const mapFnIArray = (array: any) => {
    const mappedArray = array.map((priceItem: any) => {
      const otherProperties = fniProducts?.result?.find((item: any) => item.id === priceItem.id)
      return {
        ...otherProperties,
        price: priceItem?.price,
        rate_id: priceItem?.rate_id,
        form_id: priceItem?.form_id
      }
    })
    return mappedArray
  }
  useEffect(() => {
    getPenIntegrations(
      {
        provider_name: IntegrationProvider.PEN,
        integration_type: LenderIntegrationType.FNI_PRODUCTS
      },
      {
        onSuccess(response: any) {
          setPenEnabled(response?.is_active)
        }
      }
    )
  }, [])
  useEffect(() => {
    if (
      states?.orderData?.selected_vehicle &&
      Object.keys(states?.orderData?.selected_vehicle).length > 0
    ) {
      getFandIProducts({
        data: `dealer_code=${
          states?.dealerAndLenderInfo?.dealer_code
        }&page_number=${0}&page_size=${1000}&is_active=true`
      })
    }
  }, [states?.orderData?.selected_vehicle])
  useEffect(() => {
    setTerms(
      states?.contractTermsData?.find((item) => item.id == quotationParameterData?.contract_term_id)
        ?.term
    )
  }, [quotationParameterData])

  const total_results = fniProducts ? fniProducts.total_results : 0

  const handleFnIClose = () => {
    setOpenFnIDialog(false)
  }
  const handleContinue = () => {
    setFniData({
      finance: selectedFinanceItems,
      lease: selectedLeaseItems
    })
    setOpenFnIDialog(false)
  }

  useEffect(() => {
    if (fniProducts) {
      //Set Product Info For Pen
      let productInfo: Array<any> = []
      fniProducts?.result?.map((obj: any) => {
        const newObj: any = {
          product_id: obj?.product_id, // 5 for this pendealerid
          starting_mileage: 0,
          ending_mileage: 10000, //0
          filters: [{ term_months: `${terms}` }]
        }
        productInfo.push(newObj)
      })
      setNewProductsInfo(productInfo)
    }
  }, [fniProducts])
  useEffect(() => {
    if (fniProducts?.result.length > 0 && financeProductRates) {
      applyMarkupOnCoverages(fniProducts.result, financeProductRates)
    }
  }, [fniProducts, financeProductRates])
  useEffect(() => {
    if (fniProducts?.result.length > 0 && leaseProductRates) {
      applyMarkupOnCoverages(fniProducts.result, leaseProductRates)
    }
  }, [fniProducts, leaseProductRates])
  useEffect(() => {
    if (fniProducts) {
      //Divide FnI Product into categories based on product type
      let FnI_Tabs: Array<any> = []
      let product_type = fniProducts?.result?.map((x: any) => x.product_type)
      product_type = [...new Set(product_type)] // get unique product types
      product_type?.map((pt: string) => {
        const obj = {
          title: pt,
          content: (
            <FinancialAndInsurancePopup
              data={fniProducts?.result?.filter((x: any) => x.product_type == pt)}
              activeTab={activeTab}
              setSelectedLeaseItems={setSelectedLeaseItems}
              setSelectedFinanceItems={setSelectedFinanceItems}
              selectedFinanceItems={selectedFinanceItems}
              selectedLeaseItems={selectedLeaseItems}
              financeRates={financeProductRates?.rates?.rate}
              leaseRates={leaseProductRates?.rates?.rate}
              defaultCurrency={defaultCurrency}
              terms={terms}
              fniPrice={fniPrice}
              setFniPrice={setFniPrice}
              newProductsInfo={products_info}
              setNewProductsInfo={setNewProductsInfo}
            />
          )
        }
        FnI_Tabs.push(obj)
      })
      setProductTypes(FnI_Tabs)
    }
  }, [
    fniProducts,
    activeTab,
    financeProductRates,
    selectedFinanceItems,
    selectedLeaseItems,
    terms,
    fniPrice
  ])
  const { control } = useForm<IFormInputs>()
  const getRateRequestObject = (new_products_info: any, finance_type: string) => {
    const currentDate = new Date(Date.now()).toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    })
    const ratesRequestObject = {
      deal_info: {
        pen_dealer_id: dealerProfileData?.pen_dealer_id, 
        vin: states?.orderData?.selected_vehicle?.vin,
        mileage: finance_type == 'Lease' ? states?.orderData?.selected_vehicle?.mileage : 0,
        car_status: 'NEW',
        effective_date: currentDate,
        in_service_date: currentDate,
        finance_type: finance_type,
        vehicle_msrp: states?.orderData?.selected_vehicle?.msrp, 
        vehicle_purchase_price: states?.orderData?.selected_vehicle?.internet_price, 
        deal_type: 'LOAN',
        finance_terms: [terms],
        finance_terms_mileage: [24],
        apr: programs?.find((x: any) => x.finance_type == finance_type)?.final_customer_rate,
        financed_amount: 33718.28,
        language: 'ENGLISH',
        user_role: 'FI_MANAGER'
      },
      products_info: new_products_info,
      test_request: true
    }
    return ratesRequestObject
  }
  const getTotal = (array: Array<any>) => {
    let sum = array?.reduce((sum, obj) => {
      return sum + obj.price
    }, 0)
    return sum.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }
  useEffect(() => {
    setSum({
      ...sum,
      finance: selectedFinanceItems?.length > 0 ? getTotal(selectedFinanceItems) : '0.00'
    })
  }, [selectedFinanceItems])
  useEffect(() => {
    setSum({
      ...sum,
      lease: selectedLeaseItems?.length > 0 ? getTotal(selectedLeaseItems) : '0.00'
    })
  }, [selectedLeaseItems])
  useEffect(() => {
    if (programs?.length == 1) {
      programs?.find((x: any) => x?.finance_type == 'Finance') ? setActiveTab(0) : setActiveTab(1)
    }
  }, [programs])
  function applyMarkupOnCoverages(fniProduct: any, fniRates: any) {
    const rates = fniRates?.rates?.rate
    const rateArray = Array.isArray(rates) ? rates : rates ? [rates] : []
    rateArray?.map((rate: any) => {
      let markup = fniProduct.find((x: any) => x.product_id == rate?.product_id)?.markup
      //when only single coverage is found then pen returns object instead of array
      if (rate?.coverages?.coverage && !Array.isArray(rate?.coverages?.coverage)) {
        let price = rate?.coverages?.coverage?.deductibles?.deductible?.retail_price
        price = price + (markup / 100) * price
        rate.coverages.coverage.deductibles.deductible.retail_price = price
        rate.coverages.coverage = [rate?.coverages?.coverage]
      } else {
        rate?.coverages?.coverage?.map((item: any) => {
          let price = item.deductibles.deductible.retail_price
          price = price + (markup / 100) * price
          item.deductibles.deductible.retail_price = price
        })
      }
    })
  }
  return (
    <>
      {total_results > 0 && penEnabled ? (
        <>
          <Typography theme={theme} variant="h4" component="h4" sx={{ mb: 2.5 }}>
            F&amp;I Products
          </Typography>
          {isOrderConditioned ? (
            programs[0].finance_type === 'Finance' ? (
              fniData?.finance?.length > 0 && (
                <>
                  <Typography theme={theme} variant="caption" component="div" sx={{ mb: 2.5 }}>
                    <b>FINANCE</b>{' '}
                  </Typography>
                  <PriceList className="price-list">
                    {fniData?.finance?.map((obj: any, index: any) => (
                      <div key={index}>
                        <Grid theme={theme} container columnSpacing={1} sx={{ mb: 2 }}>
                          <Grid theme={theme} item xs={8}>
                            {obj?.product_name}
                          </Grid>
                          <Grid theme={theme} item xs={4} textAlign="right">
                            {defaultCurrency}
                            {obj?.price?.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2
                            })}
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                    <Box theme={theme} sx={{ mb: 2 }}>
                      <Divider />
                    </Box>
                    <Grid theme={theme} container columnSpacing={1} sx={{ mb: 2 }}>
                      <Grid theme={theme} item xs={8}>
                        <b>Total</b>
                      </Grid>
                      <Grid theme={theme} item xs={4} textAlign="right">
                        <b className="total">
                          {' '}
                          {`${defaultCurrency}${getTotal(fniData?.finance)}`}
                          {/* : `${defaultCurrency}0.00`} */}
                        </b>
                      </Grid>
                    </Grid>
                    <Box theme={theme} sx={{ mb: 2 }}>
                      <Divider />
                    </Box>
                  </PriceList>
                </>
              )
            ) : (
              fniData?.lease?.length > 0 && (
                <>
                  <Typography theme={theme} variant="caption" component="div" sx={{ mb: 2.5 }}>
                    <b>LEASE</b>{' '}
                  </Typography>
                  <PriceList className="price-list">
                    {fniData?.lease?.map((obj: any, index: any) => (
                      <div key={index}>
                        <Grid theme={theme} container columnSpacing={1} sx={{ mb: 2 }}>
                          <Grid theme={theme} item xs={8}>
                            {obj?.product_name}
                          </Grid>
                          <Grid theme={theme} item xs={4} textAlign="right">
                            {defaultCurrency}
                            {obj?.price?.toLocaleString(undefined, {
                              maximumFractionDigits: 4,
                              minimumFractionDigits: 2
                            })}
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                    <Box theme={theme} sx={{ mb: 2 }}>
                      <Divider />
                    </Box>
                    <Grid theme={theme} container columnSpacing={1} sx={{ mb: 2 }}>
                      <Grid theme={theme} item xs={8}>
                        <b>Total</b>
                      </Grid>
                      <Grid theme={theme} item xs={4} textAlign="right">
                        <b className="total">
                          {' '}
                          {`${defaultCurrency}${getTotal(fniData?.lease)}`}
                          {/* : `${defaultCurrency}0.00`} */}
                        </b>
                      </Grid>
                    </Grid>
                  </PriceList>
                </>
              )
            )
          ) : (
            <>
              {fniData?.finance?.length > 0 && (
                <>
                  <Typography theme={theme} variant="caption" component="div" sx={{ mb: 2.5 }}>
                    <b>FINANCE</b>{' '}
                  </Typography>
                  <PriceList className="price-list">
                    {fniData?.finance?.map((obj: any, index: any) => (
                      <div key={index}>
                        <Grid theme={theme} container columnSpacing={1} sx={{ mb: 2 }}>
                          <Grid theme={theme} item xs={8}>
                            {obj?.product_name}
                          </Grid>
                          <Grid theme={theme} item xs={4} textAlign="right">
                            {defaultCurrency}
                            {obj?.price?.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2
                            })}
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                    <Box theme={theme} sx={{ mb: 2 }}>
                      <Divider />
                    </Box>
                    <Grid theme={theme} container columnSpacing={1} sx={{ mb: 2 }}>
                      <Grid theme={theme} item xs={8}>
                        <b>Total</b>
                      </Grid>
                      <Grid theme={theme} item xs={4} textAlign="right">
                        <b className="total">
                          {' '}
                          {`${defaultCurrency}${getTotal(fniData?.finance)}`}
                          {/* : `${defaultCurrency}0.00`} */}
                        </b>
                      </Grid>
                    </Grid>
                    <Box theme={theme} sx={{ mb: 2 }}>
                      <Divider />
                    </Box>
                  </PriceList>
                </>
              )}
              {fniData?.lease?.length > 0 && (
                <>
                  <Typography theme={theme} variant="caption" component="div" sx={{ mb: 2.5 }}>
                    <b>LEASE</b>{' '}
                  </Typography>
                  <PriceList className="price-list">
                    {fniData?.lease?.map((obj: any, index: any) => (
                      <div key={index}>
                        <Grid theme={theme} container columnSpacing={1} sx={{ mb: 2 }}>
                          <Grid theme={theme} item xs={8}>
                            {obj?.product_name}
                          </Grid>
                          <Grid theme={theme} item xs={4} textAlign="right">
                            {defaultCurrency}
                            {obj?.price?.toLocaleString(undefined, {
                              maximumFractionDigits: 4,
                              minimumFractionDigits: 2
                            })}
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                    <Box theme={theme} sx={{ mb: 2 }}>
                      <Divider />
                    </Box>
                    <Grid theme={theme} container columnSpacing={1} sx={{ mb: 2 }}>
                      <Grid theme={theme} item xs={8}>
                        <b>Total</b>
                      </Grid>
                      <Grid theme={theme} item xs={4} textAlign="right">
                        <b className="total">
                          {' '}
                          {`${defaultCurrency}${getTotal(fniData?.lease)}`}
                          {/* : `${defaultCurrency}0.00`} */}
                        </b>
                      </Grid>
                    </Grid>
                  </PriceList>
                </>
              )}
            </>
          )}

          <Box theme={theme} sx={{ mb: 4 }}>
            <Button
              theme={theme}
              secondary
              text="Add F&I Products"
              fullWidth
              onClick={handleFnIOpen}
              disabled={isDisable}
            />
          </Box>
          <Box theme={theme} sx={{ mb: 4 }}>
            <Divider />
          </Box>
          {/* <Box sx={{ mb: 4 }}>
        <Divider />
      </Box> */}
          <Dialog
            disablePortal
            variant={undefined}
            size="xl"
            title="F&I Products"
            open={openFnIDialog}
            onCloseDialog={handleFnIClose}
            customFooter={
              <>
                <Button theme={theme} primary text="Continue " onClick={handleContinue} />
              </>
            }
            theme={theme}
            children={
              <>
                <StickyDuoTab className="mini-tabs" theme={theme}>
                  <DuoTab
                    theme={theme}
                    varient={'default'}
                    onChange={(_e, activeTabValue) => {
                      setActiveTab(activeTabValue)
                    }}
                    items={programs?.map((program: any) => {
                      return {
                        title: program.finance_type,
                        content: (
                          <>
                            <Box theme={theme} className="search">
                              <Controller
                                name="search"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                  <Input
                                    theme={theme}
                                    fullWidth
                                    type="text"
                                    placeholder="Search.."
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <Icon name="SearchIcon" />
                                      </InputAdornment>
                                    }
                                    {...field}
                                  />
                                )}
                              />
                            </Box>
                            <DuoTab
                              theme={theme}
                              varient={'underline'}
                              items={productTypes}
                              defaultTabIndex={0}
                            />
                          </>
                        )
                      }
                    })}
                    defaultTabIndex={0}
                  />
                </StickyDuoTab>
                <DialogTotal
                  theme={theme}
                  className="dialog-total"
                  display={'flex'}
                  gap={2}
                  justifyContent={'space-between'}
                >
                  <Box theme={theme}>Total</Box>
                  <Box theme={theme} className="primary" textAlign="right">
                    {defaultCurrency}
                    {activeTab == 0 ? sum?.finance ?? '0.00' : sum?.lease ?? '0.00'}
                  </Box>
                </DialogTotal>
              </>
            }
          />
        </>
      ) : (
        ''
      )}
    </>
  )
}

export default OrderFnIProducts
