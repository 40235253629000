import { FC, useEffect, useState } from 'react'
import { useTheme, Link } from '@mui/material'
import {
  Icon,
  Menu,
  Button,
  Grid,
  Box,
  Typography,
  CircleLoader,
  Dialog
} from '@ntpkunity/controls'
import {
  AlertBox,
  ConfirmationDialog,
  CustomDialogWrap,
  PageHeader,
  ProductBanner,
  AgreementPopup
} from 'components'
import {
  useGetCompanyConfigurations,
  useGetDealerData,
  useGetOrder
} from '@apis/dealer-configurations.service'
import {
  useGetIndividualAgreement,
  useUpdateIndividualizedAgreement
} from '@apis/configurations.service'
import { useStoreContext } from '@store/storeContext'
import OrderAssetControl from 'controls/OrderAsser/order-asset-control'
import { DeskingActionTypes, useDeskingContext } from '@app/desking/desking-context-provider'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CustomerDetails from './customer-details'
import { ILenderIntegration } from '@models'
import {
  DialogMessages,
  IntegrationProvider,
  LenderIntegrationType,
  Reasons,
  Status
} from '@helpers/enums'
import {
  useGetIntegrationByProviderName,
  useUpdateDMSCUSTOMER
} from '@apis/customer-detail.service'
import { useUpdateOrderStatus } from '@apis/dealer-workqueue.service'
import OrderInReview from './order-in-review'
import {
  useConvertApplicationToQuotation,
  useCreditResponse,
  useGetSubmittedOrderInformation
} from '@apis/order-management.service'
import { APP_ROUTES } from '@helpers/links'
import { useModal } from 'react-modal-hook'
import { ILender, MultiLenderDialog } from 'controls/multi-lender-dialog'
import DisableLoader from '@public/assets/images/loader-disabled.gif'
import { useSendCustomerOrderLink } from '@apis/customer-management.service'

const Customer: FC = () => {
  const theme = useTheme()
  const { states, actions } = useStoreContext()
  let navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const orderReferenceId = searchParams.get('reference_id')
  const customerReferenceId = searchParams.get('customer_reference_id')
  const {
    state: { vehiclesVins, order, vehiclesData },
    dispatch
  } = useDeskingContext()

  const vehicleDetail = vehiclesData.get(vehiclesVins[0])
  const { data: _orderData } = useGetOrder(orderReferenceId)
  const { mutate: getSubmittedOrderInformation, isLoading: getSubmittedOrderInformationLoading } =
    useGetSubmittedOrderInformation()
  const { data: _companyConfigurations, isLoading: companyConfigurationsLoading } =
    useGetCompanyConfigurations(states?.dealerInfo?.company_id)
  // state code needs to be picked from dealer state id from dealer address and states endpoint
  const { data: _dealerData, isLoading: dealerDataLoading } = useGetDealerData(
    states?.dealerInfo.dealer_code,
    states?.dealDesk?.stateCode,
    states?.dealerProfileData?.available_finance_types
  )

  const [tabStates, setTabStates] = useState(false)
  const [isBanner, setIsBanner] = useState(false)
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [agreementPopUp, setAgreementPopUp] = useState(false)
  const { mutate: getProviderbyName } = useGetIntegrationByProviderName(
    IntegrationProvider.SOCURE,
    LenderIntegrationType.FRAUD_IDENTIFICATION
  )
  const { mutate: updateOrderStage, isLoading: orderStageLoading } =
    useConvertApplicationToQuotation()
  const { data: individualAgreement } = useGetIndividualAgreement(
    states?.dealerInfo?.dealer_code,
    orderReferenceId
  )
  const { mutate: sendCustomerOrderLink } = useSendCustomerOrderLink()
  const {
    mutate: updateIndividualizedAgreement,
    isLoading: _updateRequestLoading,
    data: _updateResponse,
    error: _updateRequestError
  } = useUpdateIndividualizedAgreement()
  const { mutate: creditResponse, isLoading: creditResponseLoading } = useCreditResponse()
  const { mutate: updateOrderStatus, isLoading: orderStatusLoading } = useUpdateOrderStatus()
  const [provider, setProvider] = useState<ILenderIntegration>()
  const { mutate: updateDmsData } = useUpdateDMSCUSTOMER()
  const [submitCustomerPopUp, setSubmitCustomerPopUp] = useState<boolean>(false)
  const [confirmationPopupState, setConfirmationPopupState] = useState<boolean>(false)
  const [selectedLenders, setSelectedLenders] = useState<number[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [individual, setIndividualAgreement] = useState<any[]>([])
  const [_, setOptionObject] = useState<any[]>([])

  useEffect(() => {
    if (orderStatusLoading || creditResponseLoading || getSubmittedOrderInformationLoading)
      setLoading(true)
    else setLoading(false)
  }, [orderStatusLoading, creditResponseLoading, getSubmittedOrderInformationLoading])

  useEffect(() => {
    const res = individualAgreement?.map((r: any) => ({
      ...r,
      selected: false
    }))
    const doesExist = res?.find(
      (agreement: any) => order?.individualized_agreement === agreement.agreement_clause
    )
    if (doesExist) {
      doesExist.selected = true
    }
    if (!doesExist && order?.individualized_agreement) {
      res?.push({
        agreement_clause: order?.individualized_agreement,
        selected: true,
        id: '1'
      })
    }
    setIndividualAgreement(res)
  }, [order, individualAgreement])
  useEffect(() => {
    if (!order?.individualized_agreement && updateIndividualizedAgreement) {
      const defaultAgreements = individualAgreement?.filter((item: any) => item.is_default)
      if (defaultAgreements?.length > 0) {
        updateIndividualizedAgreement(
          {
            reference_number: order.reference_number,
            individualized_agreement: defaultAgreements[0]['agreement_clause']
          },
          {
            onSuccess(_response: any) {
              setOptionObject?.(_response)
              dispatch({
                type: DeskingActionTypes.MAP_ORDER_TO_STORE,
                payload: _response
              })
            }
          }
        )
      }
    }
  }, [individualAgreement])

  useEffect(() => {
    getProviderbyName(
      {
        provider_name: IntegrationProvider.SOCURE,
        integration_type: LenderIntegrationType.FRAUD_IDENTIFICATION
      },
      {
        onSuccess(response: any) {
          setProvider(response?.is_active)
        }
      }
    )
  }, [])

  const onDeleteConfirm = () => {
    const data = {
      order_id: orderReferenceId,
      reqBody: {
        status: Status.Cancelled,
        user_id: 0,
        reason: Reasons.CANCELED_BY_DEALER
      }
    }
    updateOrderStatus(data, {
      onSuccess() {
        actions.setToast({
          toastMessage: 'Deal Cancelled',
          toastState: true
        })
        navigate(APP_ROUTES?.WORKQUEUE)
      },
      onError(error: any) {
        actions.setToast({
          toastMessage: error.error.toString(),
          toastState: true,
          variant: 'error'
        })
      }
    })
  }
  const submitFraudCheck = () => {
    const payload = {
      order_id: order.reference_number,
      reqBody: {
        status: 'In Review',
        user_id: 0
      }
    }
    updateOrderStatus(payload, {
      onSuccess(response: any) {
        dispatch({
          type: DeskingActionTypes.UPDATE_STATUS,
          payload: response.status
        })
      },
      onError(error: any) {
        actions.setToast({
          toastMessage: error.error.toString(),
          toastState: true,
          variant: 'error'
        })
      }
    })
  }
  const handleCreditApplication = () => {
    if (states?.dealDesk?.recalculation) {
      setIsBanner(true)
      actions.setRecalculation(false)
    } else {
      handleShowSelectLenderPopup()
    }
  }

  const handleFraudCheck = () => {
    if (provider) {
      submitFraudCheck()
    } else {
      navigate(`/dms/deal-summary/${orderReferenceId}`)
    }
  }

  const submitOrder = () => {
    closeSlModal()
    const payload = {
      order_id: orderReferenceId,
      reqBody: {
        status: 'Submitted',
        user_id: 0
      }
    }
    updateOrderStatus(payload, {
      onSuccess(response: any) {
        submitCreditApprover(response.status)
      },
      onError(error: any) {
        actions.setToast({
          toastMessage: error.error.toString(),
          toastState: true,
          variant: 'error'
        })
      }
    })
  }
  const submitCreditApprover = (status: string) => {
    getSubmittedOrderInformation(
      { reference_number: orderReferenceId, order_status: status },
      {
        onSuccess(response: any) {
          if (response) response.lender_ids = selectedLenders
          creditResponse(response, {
            async onSuccess() {
              actions.setToast({
                toastMessage: 'Deal Submitted for Credit Response',
                toastState: true
              })
              navigate(`/dms/deal-summary/${orderReferenceId}`)
            },
            onError(error: any) {
              actions.setToast({
                toastMessage: error.error.toString(),
                toastState: true,
                variant: 'error'
              })
            }
          })
        }
      }
    )
  }
  const updateData = () => {
    const data = {
      dealer_code: states?.dealerInfo.dealer_code,
      reference_id: customerReferenceId
    }
    updateDmsData(data)
  }
  const updateStage = () => {
    updateOrderStage(
      {
        identifier: order?.identifier,
        reference_number: orderReferenceId
      },
      {
        onSuccess(res: any) {
          dispatch({
            type: DeskingActionTypes.UPDATE_STAGE,
            payload: res.order_stage
          })
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: 'error'
          })
        }
      }
    )
  }

  const onSendOrderLinkToCustomer = () => {
    sendCustomerOrderLink({
      reference_number: orderReferenceId
    })
  }

  const handleShowSelectLenderPopup = () => {
    if (states?.lendersData && states?.lendersData?.length > 0) {
      const defaultLender = states?.lendersData.find((lender: ILender) => lender.default_lender)
      setSelectedLenders((defaultLender?.id && [defaultLender.id]) || [])

      if (states?.lendersData?.length > 1) openSlModal()
      else submitOrder()
    }
  }

  const [openSlModal, closeSlModal] = useModal(
    () => (
      <CustomDialogWrap theme={theme} className="custom-dialog-wrap footer-separator">
        <Dialog
          disablePortal
          theme={theme}
          variant={undefined}
          open
          size="xl"
          title="Select Lenders"
          onCloseDialog={closeSlModal}
          customFooter={
            <>
              <Button
                theme={theme}
                primary
                text="Submit Application"
                onClick={submitOrder}
                disabled={selectedLenders?.length < 1}
              />
            </>
          }
        >
          <MultiLenderDialog
            selectedLenders={selectedLenders}
            setSelectedLenders={setSelectedLenders}
            lenders={states?.lendersData}
          />
        </Dialog>
      </CustomDialogWrap>
    ),
    [selectedLenders, states?.lendersData]
  )

  if (companyConfigurationsLoading || dealerDataLoading) {
    return (
      <Box theme={theme} className="absolute-loader">
        <CircleLoader theme={theme} size="lg" />
      </Box>
    )
  }
  return (
    <>
      {agreementPopUp ? (
        <>
          <AgreementPopup
            setAgreementPopUp={setAgreementPopUp}
            individualAgreement={individual}
            order={order}
            setOptionObject={setOptionObject}
          />
        </>
      ) : (
        ''
      )}
      <PageHeader
        className="main-page-header border-bottom"
        theme={theme}
        container
        item
        spacing={2}
      >
        <Grid theme={theme} container spacing={2}>
          <Grid theme={theme} item lg={7} md={6} sm={12} xs={12}>
            <Box theme={theme} className="icon-btn-wrap">
              <Button
                theme={theme}
                defaultBtn
                id="productFilter"
                iconText={<Icon name="IconLeftArrow" />}
                className="back-btn"
                disabled={orderStageLoading}
                onClick={(_e: any) => {
                  updateStage()
                }}
              ></Button>
              <Typography theme={theme} variant="h2" component="h2">
                Customer Details
              </Typography>
            </Box>
          </Grid>
          <Grid
            theme={theme}
            item
            lg={5}
            md={6}
            sm={12}
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              gap: 2
            }}
          >
            {order.status === Status.Reviewed ? (
              <>
                <Menu
                  theme={theme}
                  options={[
                    {
                      optionText: 'Cancel Deal',
                      optionValue: 'Cancel'
                    },
                    {
                      optionText: 'Send To Customer',
                      optionValue: 'SendToCustomer'
                    }
                  ]}
                  handleOptionClick={(_event, _key, value) => {
                    switch (value) {
                      case 'Cancel':
                        setConfirmationPopupState(true)
                        break
                      case 'SendToCustomer':
                        onSendOrderLinkToCustomer()
                        break
                    }
                  }}
                  render={(onMenuSelection) => (
                    <Button
                      theme={theme}
                      secondary
                      iconText={<Icon name="MoreIcon" />}
                      onClick={onMenuSelection}
                    ></Button>
                  )}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                />
                <Button
                  theme={theme}
                  secondary
                  disabled={loading}
                  text="Submit Credit Application"
                  onClick={handleCreditApplication}
                  startIcon={loading && <img src={DisableLoader} alt="Loader" />}
                />
              </>
            ) : order.status === Status.Draft && order.order_stage === 'Application' ? (
              <>
                {!provider && isBanner ? (
                  <Menu
                    theme={theme}
                    options={[
                      {
                        optionText: 'Edit Deal',
                        optionValue: 'Edit'
                      },
                      {
                        optionText: 'Cancel Deal',
                        optionValue: 'Cancel'
                      },
                      {
                        optionText: 'Send To Customer',
                        optionValue: 'SendToCustomer'
                      }
                    ]}
                    handleOptionClick={(_event, _key, value) => {
                      switch (value) {
                        case 'Cancel':
                          setConfirmationPopupState(true)
                          break
                        case 'Edit':
                          updateStage()
                          break
                        case 'SendToCustomer':
                          onSendOrderLinkToCustomer()
                          break
                      }
                    }}
                    render={(onMenuSelection) => (
                      <Button
                        theme={theme}
                        secondary
                        iconText={<Icon name="MoreIcon" />}
                        onClick={onMenuSelection}
                      ></Button>
                    )}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left'
                    }}
                  />
                ) : (
                  <Menu
                    theme={theme}
                    options={[
                      {
                        optionText: 'Cancel Deal',
                        optionValue: 'Cancel'
                      },
                      {
                        optionText: 'Send To Customer',
                        optionValue: 'SendToCustomer'
                      }
                    ]}
                    handleOptionClick={(_event, _key, value) => {
                      switch (value) {
                        case 'Cancel':
                          setConfirmationPopupState(true)
                          break
                        case 'SendToCustomer':
                          onSendOrderLinkToCustomer()
                          break
                      }
                    }}
                    render={(onMenuSelection) => (
                      <Button
                        theme={theme}
                        secondary
                        iconText={<Icon name="MoreIcon" />}
                        onClick={onMenuSelection}
                      ></Button>
                    )}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left'
                    }}
                  />
                )}

                <Button
                  disabled={!tabStates || loading}
                  theme={theme}
                  onClick={handleFraudCheck}
                  secondary
                  text={'Submit Deal'}
                  startIcon={loading && <img src={DisableLoader} alt="Loader" />}
                />
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </PageHeader>
      <ProductBanner theme={theme} className="product-banner">
        <Box theme={theme} className="pb-wrap">
          <Box theme={theme} className="thumbnail">
            <img
              src={
                vehicleDetail?.vehicle?.photo_urls && vehicleDetail?.vehicle?.photo_urls?.length > 0
                  ? vehicleDetail?.vehicle?.photo_urls[0]?.location
                  : ''
              }
              alt="Product"
            />
          </Box>
          <Box theme={theme} className="product-details">
            <Grid theme={theme} container columnSpacing={2}>
              <Grid theme={theme} item sm={6} xs={12}>
                <Box theme={theme} className="title-md">
                  {vehicleDetail?.vehicle?.vehicle_display_name}
                </Box>
                <Box theme={theme} className="title-lg">
                  <Typography
                    theme={theme}
                    className="text-muted"
                    display={'block'}
                    variant="body1"
                    component="p"
                  >
                    {vehicleDetail?.vehicle?.year
                      ? vehicleDetail?.vehicle?.year
                      : order
                      ? order?.Vehicle_details?.year
                      : ''}{' '}
                    {vehicleDetail?.vehicle?.make
                      ? vehicleDetail?.vehicle?.make
                      : order
                      ? order?.Vehicle_details?.make
                      : ''}{' '}
                    {vehicleDetail?.vehicle?.model
                      ? vehicleDetail?.vehicle?.model
                      : order
                      ? order?.Vehicle_details?.model
                      : ''}
                  </Typography>

                  {vehicleDetail?.vehicle?.trim_description}
                </Box>
                <Box theme={theme} className="title-sm">
                  Deal ID: <b>{order?.reference_number ? order?.reference_number : ' - '}</b>
                </Box>

                <Box theme={theme} className="title-sm">
                  VIN:{' '}
                  <b>
                    {vehicleDetail?.vehicle?.vin
                      ? vehicleDetail?.vehicle?.vin
                      : order
                      ? order?.Vehicle_details?.vin
                      : ''}
                  </b>
                  <>
                    {' , '}Odometer:{' '}
                    {
                      <Link
                        className="link"
                        onClick={() => {
                          setDialogOpen(true)
                        }}
                      >
                        {order?.order_asset?.mileage ? (
                          <Link
                            className="link"
                            onClick={() => {
                              setDialogOpen(true)
                            }}
                          >
                            {order?.order_asset?.mileage.toLocaleString(undefined, {
                              maximumFractionDigits: 0,
                              minimumFractionDigits: 0
                            })}
                          </Link>
                        ) : (
                          'Add'
                        )}
                      </Link>
                    }
                  </>
                  {individualAgreement?.length > 0 && (
                    <>
                      , Individualized Agreement:{' '}
                      <Link
                        className="link"
                        onClick={() => {
                          setAgreementPopUp(true)
                        }}
                      >
                        View/Edit
                      </Link>
                    </>
                  )}
                </Box>
                <Box theme={theme} className="title-sm"></Box>
              </Grid>
              <Grid theme={theme} item sm={6} xs={12} textAlign="right">
                <Box theme={theme} className="actions-wrap">
                  <Box theme={theme} className="action-details">
                    <Box theme={theme} className="title-md">
                      Net Financed Amount
                    </Box>
                    <Box theme={theme} className="title-lg">
                      {'$'}
                      {order?.net_finance_amount &&
                        order?.net_finance_amount.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                    </Box>
                    <Box theme={theme} className="title-sm">
                      Type:{' '}
                      <b>
                        {order?.contract_term} Months {order?.finance_type}
                      </b>
                      , Rate:{' '}
                      <b>
                        {order?.apr &&
                          order?.apr?.toLocaleString(undefined, {
                            maximumFractionDigits: 4,
                            minimumFractionDigits: 4
                          })}
                        %
                      </b>
                    </Box>
                    <Box theme={theme} className="title-sm">
                      <>Est. Periodic Payment: </>
                      <b>
                        {'$'}
                        {order?.estimated_monthly_payment &&
                          order?.estimated_monthly_payment?.toLocaleString(undefined, {
                            maximumFractionDigits: 4,
                            minimumFractionDigits: 2
                          })}
                      </b>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </ProductBanner>
      {isBanner && (
        <AlertBox className="dark buttons">
          <span>You have done some changes. Please save them to update quotation.</span>
        </AlertBox>
      )}
      {order?.status === Status.InReview ||
      order?.status === Status.Reviewed ||
      order?.status === Status.Cancelled ? (
        <OrderInReview orderId={orderReferenceId ?? ''} />
      ) : (
        <CustomerDetails
          customerReferenceId={customerReferenceId ?? ''}
          setTabStates={setTabStates}
          order={order}
        ></CustomerDetails>
      )}
      {submitCustomerPopUp && (
        <ConfirmationDialog
          openPopUp={submitCustomerPopUp}
          onConfirm={function (_action: any): void {
            submitOrder()
            updateData()
          }}
          setPopUpState={setSubmitCustomerPopUp}
          confirmationTitle={DialogMessages.updatedDMSDATA}
          confirmationText={DialogMessages.updatedDMSDATATEXT}
          primaryBtnText="Yes"
          secondaryBtnText="No"
          icon={<Icon name="CircleTickIcon" />}
          onCancel={function (_action: any): void {
            setSubmitCustomerPopUp(false)
            submitOrder()
          }}
          hideCancelButton={false}
        />
      )}
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.cancelBtnText}
          isButtonTypeDanger
          icon={<Icon className='alert-icon' name="ErrorTriangle" />}
          onCancel={function (_action: any): void {
            setConfirmationPopupState(false)
          }}
          optionaldata={true}
          hideCancelButton={false}
        />
      )}
      {dialogOpen && (
        <OrderAssetControl dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} orderObj={order} />
      )}
    </>
  )
}

export default Customer
