import { FC, ReactNode } from "react"

interface CashDealWrapProps {
  isShow: boolean;
  children: ReactNode;
}

const CashDealWrap: FC<CashDealWrapProps> = ({ isShow, children }) => {
  if (!isShow) return null
  return <>{children}</>
}

export default CashDealWrap
