import { FC, useEffect, useMemo, useState } from 'react'
import { useTheme } from '@mui/material'
import { ConfirmationDialog, TextBox } from '@components'
import { DialogMessages, Employment_Type } from '@helpers/enums'
import { Select, Grid, Box, Typography, Button, Input, Icon } from '@ntpkunity/controls'

import { useForm, SubmitHandler, Controller, useWatch } from 'react-hook-form'
import { useSaveEmploymentDetails, useUpdateEmployInfo } from 'apis/customer-detail.service'
import { IAddress, IEmploymentDetails } from '@models'
import { isEmpty, validation } from '@helpers/methods'
import AddressDetailsComponent from './address-detail'
import { yupResolver } from '@hookform/resolvers/yup'
import { useQueryClient } from 'react-query'
import { useStoreContext } from '@store/storeContext'
import { QueryKeys } from '@helpers/enums'
import { createEmploymentValidationSchema } from '@helpers/validators'
import { getRoute, isDealerInUs, isNotNullOrUndefined } from '@app/desking/utils'
import { usePreventNavigation } from '@hooks/usePreventNavigation'
import { useNavigate } from 'react-router-dom'
import { isEqual } from 'lodash'

export const defaultValuesCustomer = {
  employment_type: '',
  name: '',
  phone: '',
  occupation: '',
  employed_since: '',
  annual_employment_income: 0,
  annual_other_income: 0,
  source_of_other_income: '',
  reference_id: ''
}

interface ILicenseDetailsComponentProps {
  setIsEmployment?: (value: boolean) => void
  setIsEmploymentErrors?: (value: boolean) => void
  setRoute: (value: number) => void
  employInfoData?: any
  isGoogleEnabled: any
  customerReferenceId?: string
  dealer_code?: string
  permissionName?: any
  pendingRoute?: any
  setPendingRoute?: any
  setIsTabsDisabled?: (value: boolean) => void
}

interface IFormInputs {
  employment_type: string
  name?: string
  phone?: string
  occupation?: string
  employed_since?: string
  annual_employment_income?: number
  annual_other_income?: number | 0
  source_of_other_income?: string | 0
  address: IAddress | null
}

const EmploymentDetailsComponent: FC<ILicenseDetailsComponentProps> = ({
  setIsEmployment,
  setIsEmploymentErrors,
  setRoute,
  employInfoData,
  isGoogleEnabled,
  customerReferenceId,
  dealer_code,
  permissionName,
  pendingRoute,
  setPendingRoute,
  setIsTabsDisabled
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [errorDialog, setErrorDialog] = useState<any>({ visible: false, error: '' })
  const { mutate: addEmploymentData } = useSaveEmploymentDetails(dealer_code)
  const [months, setMonths] = useState(0)
  const [years, setYears] = useState(0)
  const [isAddressDirty, setIsAddressDirty] = useState(false)
  const { mutate: updateEmployInfoReferenceId } = useUpdateEmployInfo(dealer_code)
  const [isVerified, setIsVerified] = useState(false)
  const [getMailValue] = useState('Employment')
  const { states } = useStoreContext()
  const isUsAddress = isDealerInUs(states)
  const validationSchema = createEmploymentValidationSchema(permissionName, isUsAddress)
  const queryClient = useQueryClient()
  const [defaultCurrency, setDefaultCurrency] = useState('')
  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])
  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0].symbol : ''
    )
  }, [currencies])
  const form = useForm<IFormInputs>({
    defaultValues: employInfoData,
    resolver: yupResolver(validationSchema),
    mode: 'all'
  })

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    getValues,
    formState: { errors, isDirty }
  } = form

  const addressPayloadName = (validation: any) => {
    return {
      ADDRESS_LAYOUT_ADDRESS_LINE_1: `Address Line 1 ${permissionName[validation.line1] ? '*' : ''
        }`,
      ADDRESS_LAYOUT_CITY: `City ${permissionName[validation.city] ? '*' : ''}`,
      ADDRESS_LAYOUT_STATE: `State ${permissionName[validation.state] ? '*' : ''}`,
      ADDRESS_LAYOUT_POSTAL_CODE: `Zip code${permissionName[validation.zip] ? '*' : ''}`,
      ADDRESS_LAYOUT_ADDRESS_LINE_2_OPTIONAL: `Address Line 2`
    }
  }
  useEffect(() => {
    const address = employInfoData?.address
    reset(employInfoData)
    setValue('employed_since', setMoveInDate(employInfoData?.employed_since))
    setValue('address', address ?? null)
    setValue('address.state_id', address?.state_name)
    setValue('address.address_type', getMailValue)
  }, [employInfoData])

  const employment_type = watch('employment_type')
  const is_employee_type =
    employment_type === Employment_Type.Employed ||
    employment_type === Employment_Type.Self_Employed

  useEffect(() => {
    return () => {
      if (employInfoData) {
        setIsEmploymentErrors?.(true)
        setIsEmployment?.(true)
      }
    }
  }, [employInfoData])

  useEffect(() => {
    if (getValues('address')?.verified) {
      setIsVerified(true)
    }
  }, [getValues('address')])

  function handleEmployedSince(e: any) {
    const [inputMonth, inputYear] = e?.target?.value
      ?.toString()
      ?.split('/')
      ?.map((num: string) => parseInt(num))
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1

    if (inputMonth > 12 || inputMonth === 0 || inputYear === 0) {
      setErrorDialog({ visible: true, error: 'Please enter a valid Employed Since Date' })
      return
    }

    if (inputYear > currentYear || (inputYear >= currentYear && inputMonth > currentMonth)) {
      setErrorDialog({ visible: true, error: 'Employed Since Date can not be a future date' })
      return
    }
    setErrorDialog({ visible: false, error: '' })
    if (inputMonth > 0 && inputYear > 0) {
      calculateDateDifference(inputMonth, inputYear)
    }
  }

  function calculateDateDifference(inputMonth: number, inputYear: number): void {
    const currentDate = new Date()
    const months = currentDate.getMonth() + 1
    const yearDiff = currentDate.getFullYear() - inputYear
    const monthDiff = months - inputMonth

    if (monthDiff < 0) {
      setYears(yearDiff - 1)
      setMonths(12 + monthDiff)
    } else {
      setYears(yearDiff)
      setMonths(monthDiff)
    }
  }

  const getEmployedSinceDate = () => {
    let date = null
    const employedSince = getValues('employed_since')?.toString()
    if (employedSince && employedSince?.toString()?.length > 5) {
      const month = employedSince?.toString().slice(0, 2)
      const year = employedSince?.toString().slice(2, 6)
      date = `${year}-${month}-${'01'}`
      return date
    }
    return date
  }

  const setMoveInDate = (moveInDate: string) => {
    let monthYear = ''
    if (moveInDate?.length > 0) {
      monthYear = moveInDate?.toString()?.slice(5, 7) + moveInDate?.toString()?.slice(0, 4)
      if (moveInDate?.length > 6) {
        calculateDateDifference(
          parseInt(moveInDate?.toString()?.slice(5, 7)),
          parseInt(moveInDate?.toString()?.slice(0, 4))
        )
      }
    }
    return monthYear
  }

  const hasErrors = Object.keys(errors).length > 0
  if (hasErrors) {
    setIsEmployment?.(false)
    setIsEmploymentErrors?.(hasErrors)
  }

  const onSubmit: SubmitHandler<any> = (formData) => {
    const payload = {
      ...formData,
      reference_id: customerReferenceId,
      annual_other_income: parseFloat(formData.annual_other_income),
      annual_employment_income: parseFloat(formData.annual_employment_income),
      employed_since: getEmployedSinceDate(),
      phone: formData?.address?.contact_number
    }
    if (isEmpty(employInfoData)) {
      setIsTabsDisabled?.(true)
      addEmploymentData(payload, {
        onSuccess: (__response: IEmploymentDetails) => {
          setIsEmployment?.(true)
          setIsEmploymentErrors?.(true)
          setRoute(getRoute(pendingRoute, 3))
          setPendingRoute(null)
          setIsTabsDisabled?.(false)
        },
        onError(__error: any) { 
          setIsTabsDisabled?.(false)
         }
      })
    } else if (isDirty || isAddressDirty) {
      setIsTabsDisabled?.(true)
      updateEmployInfoReferenceId(payload, {
        onSuccess: (__response: IEmploymentDetails) => {
          setIsEmployment?.(true)
          setIsEmploymentErrors?.(true)
          setRoute(getRoute(pendingRoute, 3))
          setPendingRoute(null)
          setIsTabsDisabled?.(false)
        },
        onError(__error: any) { 
          setIsTabsDisabled?.(false)
        }
      })
    } else {
      setIsEmployment?.(true)
      setIsEmploymentErrors?.(true)
      setRoute(getRoute(pendingRoute, 3))
      setPendingRoute(null)
    }
  }
  const employmentCases = [
    Employment_Type.Employed.toString(),
    Employment_Type.Self_Employed.toString()
  ]

  const handleDiscard = () => {
    if (isNotNullOrUndefined(pendingRoute)) {
      setRoute(pendingRoute)
      setPendingRoute(null)
    } else {
      if (nextLocation) {
        navigate(nextLocation, { replace: true })
      }
    }
  }

  const watchedValues = useWatch({ control })
  const watchedAddress = useWatch({ control, name: 'address' })

  const isTrulyDirty = useMemo(() => {
    if (!isDirty) return false

    const { address: currentAddress, employed_since: currentRawValue, ...currentFields } = watchedValues
    const { address: originalAddress, employed_since: originalRawValue, ...originalFields } = employInfoData || {}

    const formattedOriginal = setMoveInDate(originalRawValue || '')
    const formattedCurrent = currentRawValue?.length === 6 ? currentRawValue : setMoveInDate(currentRawValue ?? '')

    const cleanedCurrentAddress = { ...watchedAddress }
    delete cleanedCurrentAddress.state_id

    return (
        (formattedOriginal !== formattedCurrent) ||
        !isEqual(cleanedCurrentAddress, originalAddress) ||
        !isEqual(currentFields, originalFields)
    )
  }, [isDirty, employInfoData, watchedValues, watchedAddress])

  const [
      { showDialog, setShowDialog, nextLocation },
      { handleSaveAndLeave }
    ] = usePreventNavigation({
      isDirty: isTrulyDirty,
      onSave: async () => {
        const isValidForm = await form.trigger()

        if (!isValidForm) {
          setPendingRoute(null)
          throw new Error('Form has validation errors')
        }

        const values = getValues()

        await onSubmit(values)

        if (nextLocation) {
            if (isNotNullOrUndefined(pendingRoute)) {
              setRoute(pendingRoute)
              setPendingRoute(null)
              return
            }
            navigate(nextLocation, { replace: true })
        }
    }
  })

  useEffect(() => {
    if (isNotNullOrUndefined(pendingRoute) && isTrulyDirty) {
      setShowDialog(true)
    } else {
      if (isNotNullOrUndefined(pendingRoute)) {
        setRoute(pendingRoute)
        setPendingRoute(null)
      }
    }
  }, [pendingRoute, isTrulyDirty])

  return (
    <>
      <Typography theme={theme} component="h3" variant="h3" sx={{ mb: 3 }}>
        Employment Details
      </Typography>
      <Grid theme={theme} container spacing={3} sx={{ mb: 4 }}>
        <Grid theme={theme} item xs={12} sm={6}>
          <Controller
            name={'employment_type'}
            defaultValue={''}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                theme={theme}
                label={`Employment Type ${permissionName?.employmentDetailType ? '*' : ''}`}
                items={Object.entries(Employment_Type).map((item) => ({
                  text: item[1],
                  value: item[1]
                }))}
                sxProps={''}
                value={value}
                selectError={errors?.employment_type?.message?.toString()}
                onChange={(e: any) => {
                  onChange(e)
                }}
              />
            )}
          />
        </Grid>
        {employmentCases.includes(watch('employment_type')) && (
          <>
            <Grid theme={theme} item xs={12} sm={6}>
              <Controller
                name={'name'}
                defaultValue={''}
                control={control}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    fullWidth
                    placeholder={'Type here..'}
                    label={`Employment Name ${permissionName?.employmentDetailName ? '*' : ''}`}
                    type="text"
                    {...field}
                    error={errors?.name?.message}
                  />
                )}
              />
            </Grid>

            <Grid theme={theme} item xs={12} sm={6}>
              <Controller
                name={'occupation'}
                defaultValue={defaultValuesCustomer.occupation}
                control={control}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    fullWidth
                    placeholder={'Type here..'}
                    label={`Occupation ${permissionName?.employmentDetailOccupation ? '*' : ''}`}
                    type="text"
                    {...field}
                    error={errors?.occupation?.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12} sm={6}>
              <Controller
                name={'employed_since'}
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <Input
                    maskNumeric
                    masking
                    format="##/####"
                    theme={theme}
                    fullWidth
                    placeholder="MM/YYYY"
                    label={`Employed Since ${permissionName?.employmentDetailSince ? '*' : ''}`}
                    {...field}
                    onBlur={(e) => {
                      field.onBlur()
                      handleEmployedSince(e)
                    }}
                    type={'text'}
                    helperText={
                      (errors?.employed_since?.message?.toString() ?? '').length <= 0 &&
                        errorDialog?.visible == false
                        ? `Duration: ${years.toString().padStart(2, '0')} year(s), ${months
                          .toString()
                          .padStart(2, '0')} month(s)`
                        : ''
                    }
                    error={
                      errorDialog?.visible == false
                        ? errors?.employed_since?.message?.toString()
                        : errorDialog?.error
                    }
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12} sm={6}>
              <Controller
                name={'annual_employment_income'}
                
                rules={validation(
                  'Annual Employment Income',
                  permissionName?.employmentDetailAnnualEmploymentIncome
                )}
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    theme={theme}
                    fullWidth
                    masking
                    maskNumeric
                    maskDecimalScale={2}
                    placeholder={'Annual Employment Income'}
                    label={`Annual Employment Income ${permissionName?.employmentDetailAnnualEmploymentIncome ? '*' : ''}`}
                    startAdornment={defaultCurrency}
                    type="text"
                    error={errors?.annual_employment_income?.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12} sm={6}>
              <Controller
                name={'annual_other_income'}
                
                rules={validation(
                  'Annual Employment Income',
                  permissionName?.employmentDetailAnnualEmploymentIncome
                )}
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    theme={theme}
                    fullWidth
                    masking
                    maskNumeric
                    maskDecimalScale={2}
                    placeholder={'Annual Other Income'}
                    label={`Annual Other Income ${permissionName?.employmentDetailAnnualEmploymentIncome ? '*' : ''}`}
                    startAdornment={defaultCurrency}
                    type="text"
                    error={errors?.annual_other_income?.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12} sm={6}>
              <Controller
                name={'source_of_other_income'}
                defaultValue={''}
                control={control}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    fullWidth
                    placeholder={'Type here..'}
                    label={'Source of Other Income'}
                    type="text"
                    {...field}
                    error={errors?.source_of_other_income?.message?.toString()}
                  />
                )}
              />
            </Grid>
          </>
        )}
        {!employmentCases.includes(watch('employment_type')) && (
          <>
            <Grid theme={theme} item xs={12} sm={6}>
              <Controller
                name={'annual_other_income'}
                defaultValue={0}
                control={control}
                render={({ field }) => (
                  <TextBox
                    label={'Annual Other Income'}
                    type="text"
                    numeric={true}
                    masking={true}
                    startAdornment={defaultCurrency}
                    scale={2}
                    {...field}
                    error={errors?.annual_other_income?.message?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid theme={theme} item xs={12} sm={6}>
              <Controller
                name={'source_of_other_income'}
                defaultValue={''}
                control={control}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    fullWidth
                    placeholder={'Type here..'}
                    label={'Source of Other Income'}
                    type="text"
                    {...field}
                    error={errors?.source_of_other_income?.message?.toString()}
                  />
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Box theme={theme} sx={{ mb: 4 }}>
        <hr />
      </Box>
      {is_employee_type ? (
        <>
          <Typography theme={theme} component="h4" variant="h4" className="title-icon" mb={2}>
            <span>Employment Address</span>{' '}
            {isVerified ? (
              <Box theme={theme} display={'inline'} sx={{ position: 'relative', top: '-4px' }}>
                <Icon name="GreenCheckIcon" />
              </Box>
            ) : null}
          </Typography>
          <Box theme={theme} sx={{ mb: 4 }}>
            <AddressDetailsComponent
              form={form}
              isGoogleEnabled={isGoogleEnabled}
              addressValue={'address'}
              setIsAddressDirty={setIsAddressDirty}
              moveIn={false}
              errorProps={errors.address}
              isContactRequired={true}
              placeholders={addressPayloadName({
                line1: 'employmentDetailsAddressLine1',
                city: 'employmentDetailsAddressCity',
                state: 'employmentDetailsAddressState',
                zip: 'employmentDetailsAddressZipCode'
              })}
            />
          </Box>

          <Box theme={theme} sx={{ mb: 4 }}>
            <hr />
          </Box>
        </>
      ) : null}
      <Box theme={theme} textAlign="right" sx={{ mb: 5 }}>
        <Button
          type="submit"
          theme={theme}
          primary
          onClick={errorDialog?.visible == false ? handleSubmit(onSubmit) : () => { }}
          text="Save Employment Details"
        />
      </Box>
      <ConfirmationDialog
        openPopUp={showDialog}
        confirmationTitle={DialogMessages.saveChanges}
        confirmationText={DialogMessages.unsaveChanges}
        primaryBtnText="Save"
        secondaryBtnText="Discard"
        icon={<Icon className="alert-icon" name="DialogAlertIcon" />}
        hideCancelButton={false}
        setPopUpState={setShowDialog}
        onConfirm={handleSaveAndLeave}
        onCancel={handleDiscard}
      />
    </>
  )
}
export default EmploymentDetailsComponent
