import { useState, useEffect, useCallback, Dispatch, SetStateAction } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

interface PreventNavigationHookProps {
    isDirty: boolean
    onSave?: () => Promise<void>
}

interface PreventNavigationState {
    showDialog: boolean
    setShowDialog: Dispatch<SetStateAction<boolean>>
    nextLocation: string | null | any
}

interface PreventNavigationActions {
    handleSaveAndLeave: () => Promise<void>
    handleDiscardChanges: () => void
    handleCancel: () => void
}

type PushStateFunction = typeof window.history.pushState

export const usePreventNavigation = ({
    isDirty,
    onSave
}: PreventNavigationHookProps): [PreventNavigationState, PreventNavigationActions] => {
    const navigate = useNavigate()
    const location = useLocation()
    const [showDialog, setShowDialog] = useState(false)
    const [nextLocation, setNextLocation] = useState<string | null>(null)

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (isDirty) {
                event.preventDefault()
                event.returnValue = ''
                return ''
            }
        }

        window.addEventListener('beforeunload', handleBeforeUnload)
        return () => window.removeEventListener('beforeunload', handleBeforeUnload)
    }, [isDirty])

    useEffect(() => {
        if (!isDirty) return

        const originalPushState: PushStateFunction = window.history.pushState.bind(window.history)

        window.history.pushState = function (data: any, unused: string, url?: string | URL | null) {
            if (isDirty) {
                const nextUrl = url?.toString() ?? ''
                setNextLocation(nextUrl)
                setShowDialog(true)
            } else {
                originalPushState(data, unused, url)
            }
        }

        const handlePopState = (event: PopStateEvent) => {
            if (isDirty) {
                event.preventDefault()
                setNextLocation(window.location.pathname + window.location.search)
                setShowDialog(true)
            } else {
                window.removeEventListener('popstate', handlePopState)
            }
        }

        window.addEventListener('popstate', handlePopState)

        return () => {
            window.history.pushState = originalPushState
            window.removeEventListener('popstate', handlePopState)
        }
    }, [isDirty, location, setShowDialog])

    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            const target = event.target as HTMLElement
            const anchor = target.closest('a')

            if (anchor && anchor.href && !anchor.target && isDirty) {
                const url = new URL(anchor.href)
                if (url.origin === window.location.origin) {
                    event.preventDefault()
                    setNextLocation(url.pathname + url.search)
                    setShowDialog(true)
                }
            }
        }

        document.addEventListener('click', handleClick)
        return () => document.removeEventListener('click', handleClick)
    }, [isDirty])

    const handleSaveAndLeave = async () => {
        try {
            if (onSave) {
                await onSave()
            }
            if (nextLocation) {
                setTimeout(() => {
                    navigate(nextLocation, { replace: true })
                    setNextLocation(null)
                }, 0)
            }
            setShowDialog(false)
        } catch (error) {
        }
    }

    const handleDiscardChanges = useCallback(() => {
        setShowDialog(false)
        if (nextLocation) {
            setTimeout(() => navigate(nextLocation, { replace: true }), 0)
        }
    }, [navigate, nextLocation])

    const handleCancel = useCallback(() => {
        setShowDialog(false)

        if (nextLocation) {
            setTimeout(() => {
                navigate(nextLocation, { replace: true })
                setNextLocation(null)
            }, 0)
        }
    }, [navigate, nextLocation])

    return [
        { showDialog, setShowDialog, nextLocation },
        { handleSaveAndLeave, handleDiscardChanges, handleCancel }
    ]
}
