import { FC, useState, useEffect } from 'react'
import { useTheme } from '@mui/material'
import { Box, Button, Dialog, Icon, Input, Select, Tooltip, Typography } from '@ntpkunity/controls'

const FIPriceDialog: FC<{
    isOpen: any
    setIsOpen: any
    details: any
    defaultCurrency: any
    rates?: any
    selectDataObj?: any
    selectDataState?: any
    setDraftItemPrices?: any
    addedItemPrices?: any
    setAddedItemPrices?: any
}> = ({
    isOpen,
    setIsOpen,
    details,
    defaultCurrency,
    rates,
    selectDataObj,
    selectDataState,
    setDraftItemPrices,
    addedItemPrices,
    setAddedItemPrices
}) => {
        const theme = useTheme()
        const [selectedFinalCoverage, setSelectedFinalCoverage] = useState<any>([])
        const [deductiblePrice, setDeductiblePrice] = useState<any>(details?.price)
        const [coverageData, setCoverageData] = useState<{
            term_miles: Array<string>
            term_months: Array<string>
        }>({ term_miles: [], term_months: [] })

        useEffect(() => {
            if (rates && Array.isArray(rates) && rates.length > 0) {
                const finalCoverage = rates.find((product) => product.product_id === details?.product_id)
                if (finalCoverage) {
                    setSelectedFinalCoverage(finalCoverage)
                }
            }
        }, [rates, details?.product_id])

        const handleClose = () => {
            setIsOpen(false)
        }
        const selectFAndIData = (data: any) => {
            const coverageObj = selectedFinalCoverage?.coverages?.coverage?.find((x: any) => x?.coverage_name == data)
            if (coverageObj) {
                setDeductiblePrice(coverageObj?.deductibles?.deductible?.retail_price)
                setCoverageData({
                    term_miles: coverageObj?.term_miles ? [coverageObj.term_miles] : [],
                    term_months: coverageObj?.term_months ? [coverageObj.term_months] : []
                })
            }
        }

        const updatePrice = () => {
            const coverageObj = selectDataObj.find((x: any) => x?.product_id == details?.product_id)
            const updatedObject = { ...coverageObj, price: deductiblePrice }
            const updatedState = selectDataObj?.map((obj: any) =>
                obj.product_id === details?.product_id ? { ...obj, ...updatedObject } : obj
              )
            selectDataState(updatedState)
            setDraftItemPrices((prevPrices: any) => ({
                ...prevPrices,
                [updatedObject?.id]: updatedObject?.price ?? 0
            }))
            if (updatedObject?.id in addedItemPrices) {
                setAddedItemPrices((prevPrices: any) => ({
                    ...prevPrices,
                    [updatedObject?.id]: updatedObject?.price ?? addedItemPrices?.[updatedObject?.id] ?? 0
                }))
            }
            setIsOpen(false)
        }

        const getPriceToolTip = (price: any): string | null => {
            const finalPrice = price / (1 + details?.markup / 100)
            return `${(
                price - finalPrice
            ).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
            })
                } ( ${details?.markup} %)`
        }

        const getBaseAmount = (price: any): any => {
            const finalPrice = price / (1 + details?.markup / 100)
            const baseAmount = price - finalPrice
            return (price - baseAmount).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })
        }

        return (
            <Dialog
                theme={theme}
                title={'Sample Product Name'}
                size="xs"
                open={isOpen}
                onCloseDialog={handleClose}
                onBackBtn={() => { setIsOpen(false) }}
                customFooter={
                    <>
                        <Button
                            theme={theme}
                            secondary
                            text={'Cancel'}
                            onClick={handleClose}
                        />
                        <Button
                            theme={theme}
                            disabled={false}
                            type="submit"
                            primary
                            text="Save"
                            onClick={updatePrice}
                        />
                    </>
                }
            >
                <Box theme={theme}>
                    <Select
                        theme={theme}
                        disablePortal={true}
                        disabled={false}
                        label={'Coverage Name'}
                        items={selectedFinalCoverage?.coverages?.coverage?.map((category: any) => {
                            return {
                                value: category?.coverage_name,
                                text: category?.coverage_name
                            }
                        })}
                        onChange={(e: any) => {
                            const coverage = e?.target?.value
                            if (coverage) {
                                selectFAndIData(coverage)
                            }
                        }}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                    />
                    <Select
                        theme={theme}
                        disablePortal={false}
                        disabled={false}
                        label={'Term'}
                        value={coverageData?.term_months}
                        items={coverageData?.term_months?.map((term: any) => {
                            return {
                                value: term,
                                text: `${term} Months`
                            }
                        })}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                    />
                    <Select
                        theme={theme}
                        disablePortal={false}
                        disabled={false}
                        label={'Mileage'}
                        value={coverageData?.term_miles}
                        items={coverageData?.term_miles?.map((milage: any) => {
                            return {
                                value: milage,
                                text: `${(milage ?? 0).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })} Miles`
                            }
                        })}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                    />
                    <Select
                        theme={theme}
                        disablePortal={false}
                        disabled={false}
                        label={'Deductible'}
                        value={deductiblePrice}
                        items={selectedFinalCoverage?.coverages?.coverage?.map((coverage: any) => {
                            return {
                                value: coverage?.deductibles?.deductible?.retail_price,
                                text: `${defaultCurrency}${(coverage?.deductibles?.deductible?.retail_price ?? 0).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}`
                            }
                        })}
                        onChange={(e: any) => {
                            const deductiblePrice = e?.target?.value
                            if (deductiblePrice) {
                                setDeductiblePrice(deductiblePrice)
                            }
                        }}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                    />
                    <Box theme={theme}>
                        {/* <Typography variant="subtitle1" component={'div'} theme={theme} mb={2}>
                            Surcharges
                        </Typography>
                        <Box theme={theme} mb={3}>
                            <Box theme={theme} display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
                                <Checkbox
                                    theme={theme}
                                    checkBoxChecked={true}
                                    label="Commercial Coverage"
                                    onChange={() => { }}
                                />
                                <Typography variant="body2" component={'span'} theme={theme}>
                                    $100.00
                                </Typography>
                            </Box>
                            <Box theme={theme} display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
                                <Checkbox
                                    theme={theme}
                                    checkBoxChecked={true}
                                    label="Light Duty Coverage Use"
                                    onChange={() => { }}
                                />
                                <Typography variant="body2" component={'span'} theme={theme}>
                                    $50.00
                                </Typography>
                            </Box>
                            <Box theme={theme} display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
                                <Checkbox
                                    theme={theme}
                                    checkBoxChecked={false}
                                    label="Ride Share Coverage"
                                    onChange={() => { }}
                                />
                                <Typography variant="body2" component={'span'} theme={theme}>
                                    $50.00
                                </Typography>
                            </Box>
                        </Box> */}
                        <Input
                            theme={theme}
                            fullWidth
                            label={'Price'}
                            type={'text'}
                            value={`${defaultCurrency}${(deductiblePrice ?? 0).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}`}
                            endAdornment={
                            <Tooltip theme={theme} title={
                            <>
                                <Typography theme={theme} component="p" variant="subtitle2" children={`Markup: ${defaultCurrency}${getPriceToolTip(deductiblePrice ?? 0)}`} />
                                <Typography theme={theme} component="p" variant="subtitle2" children={`Base Amount: ${defaultCurrency}${getBaseAmount(deductiblePrice ?? 0)}`} />

                            </>
                            }>
                                <Icon name="InfoIcon" />
                            </Tooltip>
                            }
                        />
                    </Box>
                </Box>
            </Dialog>
        )
    }

export default FIPriceDialog
