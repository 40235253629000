import { FC } from 'react'
import { useTheme } from '@mui/material'
import { Box, Button, Grid, Input, Tooltip, Typography } from '@ntpkunity/controls'
import CalculationGrid from './finance-lease-calculator'
import PaymentDetails from './payment-details'
import { FinanceLeaseHeader } from './finance-lease-header'
import MultipleVehicleComparison from './product-list-view'
import { useDeskingContext, VehiclesForComparisonTypes } from './desking-context-provider'
import DealPreviewComponent from './deal-preview-component'
import { StatusWrap, TableList } from '@components'
import { useGetReservation } from '@apis/configurations.service'
import { useStoreContext } from '@store/storeContext'
import { InventoryType } from '@helpers/enums/status.enum'
import { RESERVATION_TYPE_PERCENTAGE_MSRP } from '@helpers/constants/constants'
import { AddVehicle } from './add-vehicle'

const CalculationSection: FC = () => {
  const theme = useTheme()
  const { state } = useDeskingContext()
  const { vehiclesForComparison, isVehicleSelectedAndOrderSaved } = state
  const { states } = useStoreContext()
  const { data: getReservation, isLoading: isReservationLoading } = useGetReservation(
    states?.dealerInfo?.dealer_code
  )

  const dealerCode = states?.dealerInfo?.dealer_code
  const { vehiclesData } = state
  const vin = state?.vehiclesVins?.[0]
  const vehicleDealerCode = vehiclesData?.get(vin)?.vehicle?.dealer_code
  const isDealerCodeMatch = vehicleDealerCode === dealerCode
  const areDealerCodesAvailable = !!dealerCode && !!vehicleDealerCode

  const showReservationAmount =
    !isReservationLoading &&
    getReservation?.reservation?.applicable_to &&
    areDealerCodesAvailable &&
    !(
      getReservation?.reservation?.applicable_to === InventoryType.OtherInventory &&
      isDealerCodeMatch
    )

  const getMsrp = () => {
    const { order, vehiclesVins, vehiclesData } = state
    const vin = vehiclesVins?.[0]

    if (order?.order_asset?.msrp) {
      return order.order_asset.msrp
    }

    if (vin && vehiclesData) {
      const vehicleData = vehiclesData.get(vin)
      return vehicleData?.vehicle?.msrp || 0
    }

    return
  }

  const calculateReservationAmount = () => {
    if (!getReservation?.reservation?.reservation_amount) return '0.00'

    if (getReservation?.reservation?.reservation_type === RESERVATION_TYPE_PERCENTAGE_MSRP) {
      const msrp = getMsrp() || 0
      const percentage = getReservation?.reservation?.reservation_amount || 0
      const amount = (msrp * percentage) / 100
      return amount.toFixed(2)
    }

    return getReservation?.reservation?.reservation_amount.toFixed(2)
  }
  const {
    state: { preview }
  } = useDeskingContext()

  const { vinIndex } = preview

  return (
    <>
      {(!state?.isStipulationScreen || state?.submission_tier) && <FinanceLeaseHeader />}
      {/******* Finance/Lease *******/}
      <Grid theme={theme} container columnSpacing={2} rowSpacing={2}>
        <Grid theme={theme} item xl={6} lg={7} md={12} sm={12} xs={12}>
          {isVehicleSelectedAndOrderSaved ? (
            <DealPreviewComponent />
          ) : (
            <>
              {vehiclesForComparison === VehiclesForComparisonTypes.SINGLE ? (
                <CalculationGrid />
              ) : (
                <MultipleVehicleComparison />
              )}
            </>
          )}
        </Grid>
        <Grid theme={theme} item xl={6} lg={5} md={12} sm={12} xs={12}>
          {showReservationAmount && (
            <StatusWrap theme={theme} className="status-wrap reserve-status" mb={2}>
              <Typography
                theme={theme}
                component="small"
                variant="caption"
                className="w-100 d-block fw-600 status rejected"
              >
                Reservation Amount: ${calculateReservationAmount()}
              </Typography>
            </StatusWrap>
          )}
          <PaymentDetails />
        </Grid>
      </Grid>
      {/******* Cash *******/}
      <Grid theme={theme} container columnSpacing={2} rowSpacing={2} display={'none'}>
        <Grid theme={theme} item xs={9}>
          <Box theme={theme} className="flc-wrap">
            <Box theme={theme} className="flc-ul">
              <Box theme={theme} className="flc-li">
                <AddVehicle index={vinIndex} />
              </Box>
              <Box theme={theme} className="flc-li single-product">
                <Box theme={theme} className="flc-li-left-col">
                  <Box theme={theme} className="flc-labels-wrap">
                    <Box theme={theme} className="label-row">
                      <Typography
                        theme={theme}
                        variant="caption"
                        component="div"
                        className="label text-overflow"
                        children="Payment"
                      />
                    </Box>
                  </Box>
                </Box>
                <Box theme={theme} className="flc-li-selfStretch-col">
                  <Tooltip theme={theme} title={'No Offer Found!'}>
                    <Button
                      theme={theme}
                      defaultBtn
                      className="amount grey-bg"
                      text={
                        <>
                          <span>$</span> 0.00
                        </>
                      }
                    />
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid theme={theme} item xs={3}>
          <TableList
            listTitle="Due at Signing"
            items={[
              {
                title: 'MSRP',
                value: '$49,500.00'
              },
              {
                title: 'Dealer Discount',
                editable: true,
                value: (
                  <Input
                    theme={theme}
                    type="text"
                    fullWidth
                    startAdornment={'$'}
                    masking
                    maskNumeric
                    maskDecimalScale={2}
                  />
                )
              },
              {
                title: 'Selling Price',
                editable: true,
                value: (
                  <Input
                    theme={theme}
                    type="text"
                    fullWidth
                    startAdornment={'$'}
                    masking
                    maskNumeric
                    maskDecimalScale={2}
                  />
                )
              },
              {
                title: 'Options',
                value: '$00,000.00'
              },
              {
                title: 'F&I Products',
                value: '$00,000.00'
              },
              {
                title: 'Fees',
                value: '$00,000.00'
              },
              {
                title: 'Trade-In Inequity',
                value: '$00,000.00'
              },
              {
                title: 'Taxes',
                value: '$00,000.00'
              },
              {
                title: 'Rebates & Incentives',
                value: '$00,000.00'
              },
              {
                title: 'Total',
                value: '$00,000.00',
                hasBoldText: true
              }
            ]}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default CalculationSection
