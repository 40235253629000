import {
  IMitekInterface,
  IEmploymentDetails,
  IPersonalFinance,
  IContactDetails,
  ICustomerLicenseData
} from '../models/customer-details.interface'

import Http from '../helpers/http-api'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import {
  ADD_FINANCE_DETAILS,
  EP_SAVE_CONTACT_DETAILS,
  EP_GET_INTEGRATION_BY_PROVIDER_NAME,
  LICENSE_DETAILS,
  EP_SAVE_EMPLOYMENT_DETAILS,
  EP_UPDATE_EMPLOYMENT_DETAILS,
  EP_GET_EMPLOYMENT_INFO,
  EP_GET_STATES,
  EP_GET_CUSTOMER_LICENSE_INFO,
  LICENSE_VERIFICATION,
  EP_CUSTOMER_SIGNATURE,
  EP_CUSTOMER_DOCUMENT_SIGNATURE,
  EP_CUSTOMER_LICENSE_INFO_BY_PASS,
  EP_CUSTOMER_SIGNATURE_BY_DEALER,
  EP_CHECK_DMS_CUSTOMER,
  EP_UPDATE_DMS_CUSTOMER
} from '../helpers/endpoints'

import {
  EP_GET_PREFIX,
  EP_GET_LENDER_INTEGRATIONS,
  EP_GET_CUSTOMER,
  EP_UPDATE_CONTACT_DETAILS
} from '@helpers/endpoints'
import { useStoreContext } from '../store/storeContext'
import { getMarketPlaceToken } from '@helpers/methods'
import { QueryKeys } from '@helpers/enums'
const token = getMarketPlaceToken()
const headers: any = {
  Authorization: `Bearer ${token}`
}

export const useSaveCustomerContactDetails = (): any => {
  const { data, isLoading, mutate } = useMutation<IContactDetails, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<IContactDetails>(`${EP_SAVE_CONTACT_DETAILS}`, body)
    },
    {
      onSuccess() { }
    }
  )
  return { data, isLoading, mutate }
}

export const useAddLicenseDetails = (dealer_code?: string): any => {
  const queryClient = useQueryClient()

  const { data, mutate, error } = useMutation<ICustomerLicenseData, any>(
    async (body) => {
      let url = LICENSE_DETAILS
      if (dealer_code) {
        url += `?dealer_code=${encodeURIComponent(dealer_code)}`
      }

      const apiService = Http.createConnection({})
      return await apiService.post<any>(url, body)
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_LICENSE_DETAILS)
      }
    }
  )
  return { data, mutate, error: error?.message }
}

export const useUpdateCustomerLicenseInfo = (dealer_code?: string) => {
  const queryClient = useQueryClient()
  const { data, isLoading, error, mutate } = useMutation(
    (body: any) => {
      const { reference_id, ...restPayload } = body
      let url =
        EP_GET_CUSTOMER_LICENSE_INFO +
        `?reference_id=${reference_id}&is_presigned_url_required=true`
      if (dealer_code) {
        url += `&dealer_code=${encodeURIComponent(dealer_code)}`
      }
      const apiService = Http.createConnection()
      return apiService.patch<any>(url, restPayload)
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_LICENSE_DETAILS)
      }
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetStates = (): any => {
  const { data, error } = useQuery(
    EP_GET_STATES,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return await apiService.get<any>(EP_GET_STATES)
    },
    { refetchOnWindowFocus: false }
  )
  return { data, error }
}

const getCustomerContact = (reference_id: string) => {
  const apiService = Http.createConnection()
  return apiService.get<any>(
    EP_GET_CUSTOMER + `?reference_id=${encodeURIComponent(reference_id)}`,
    {}
  )
}

export const UseQuery_Get_CustomerContact = (reference_id: string) => {
  return useQuery(
    [QueryKeys.GET_CUSTOMER_CONTACT_DETAILS, reference_id],
    async () => {
      return getCustomerContact(reference_id)
    },
    { enabled: !!reference_id, keepPreviousData: true }
  )
}

export const useUpdateCustomerContact = () => {
  const queryClient = useQueryClient()

  const { data, isLoading, error, mutate } = useMutation(
    (body: any) => {
      const { reference_id, dealer_code, ...restPayload } = body
      const apiService = Http.createConnection()

      let url = `${EP_UPDATE_CONTACT_DETAILS}?reference_id=${encodeURIComponent(reference_id)}`

      if (dealer_code) {
        url += `&dealer_code=${encodeURIComponent(dealer_code)}`
      }

      return apiService.patch<any>(url, restPayload)
    },
    {
      onSuccess: async (_response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_CUSTOMER_CONTACT_DETAILS)
      }
    }
  )

  return { data, isLoading, error, mutate }
}

export const useGetPreFix = (): any => {
  const { data, isLoading, error, mutate } = useMutation(
    () => {
      const apiService = Http.createConnection({
        baseUrl: process.env.CONFIG_API_GATEWAY
      })
      return apiService.get<any>(EP_GET_PREFIX)
    },
    {
      onSuccess(_response: any) { }
    }
  )
  return { data, isLoading, error, mutate }
}

export const useSaveEmploymentDetails = (dealer_code?: string): any => {
  const queryClient = useQueryClient()
  const { data, isLoading, mutate } = useMutation<IEmploymentDetails, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      let url = EP_SAVE_EMPLOYMENT_DETAILS
      if (dealer_code) {
        url += `?dealer_code=${encodeURIComponent(dealer_code)}`
      }
      return apiService.post<IEmploymentDetails>(url, body)
    },
    {
      onSuccess: async (_responce: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_CUSTOMER_EMPLOYMENT_DETAILS)
      }
    }
  )
  return { data, isLoading, mutate }
}

export const useUpdateEmployInfo = (dealer_code?: string): any => {
  const queryClient = useQueryClient()
  const { data, isLoading, mutate } = useMutation<IEmploymentDetails, any>(
    (body: any) => {
      const { reference_id, ...restPayload } = body
      let url = EP_UPDATE_EMPLOYMENT_DETAILS + `?reference_id=${reference_id}`
      if (dealer_code) {
        url += `&dealer_code=${encodeURIComponent(dealer_code)}`
      }
      const apiService = Http.createConnection()
      return apiService.patch<IEmploymentDetails>(url, restPayload)
    },
    {
      onSuccess: async (_responce: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_CUSTOMER_EMPLOYMENT_DETAILS)
      }
    }
  )
  return { data, isLoading, mutate }
}

export const useAddPersonalFinance = (dealer_code?: string): any => {
  const queryClient = useQueryClient()
  const { data, mutate, error } = useMutation<IPersonalFinance, any>(
    async (body) => {
      const apiService = Http.createConnection({})
      let url = ADD_FINANCE_DETAILS
      if (dealer_code) {
        url += `?dealer_code=${encodeURIComponent(dealer_code)}`
      }
      return await apiService.post<any>(url, body)
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QueryKeys.GET_PERSONAL_FINANCE)
      }
    }
  )
  return { data, mutate, error: error?.message }
}

const getPersonalFinanceInfo = (reference_id: string) => {
  const apiService = Http.createConnection()
  return apiService.get<any>(
    ADD_FINANCE_DETAILS + `?reference_id=${encodeURIComponent(reference_id)}`,
    {}
  )
}

export const UseQuery_Get_Personal_Finance = (reference_id: string) => {
  return useQuery(
    [QueryKeys.GET_PERSONAL_FINANCE, reference_id],
    async () => {
      try {
        const data = await getPersonalFinanceInfo(reference_id)
        return data
      } catch (error) { }
    },
    { enabled: !!reference_id, keepPreviousData: true }
  )
}

const getLicenseInfo = (reference_id: string) => {
  const apiService = Http.createConnection()
  return apiService.get<any>(
    EP_GET_CUSTOMER_LICENSE_INFO +
    `?reference_id=${encodeURIComponent(reference_id)}&is_presigned_url_required=true`,
    {}
  )
}

export const UseQuery_Get_License_Information = (reference_id: string) => {
  return useQuery(
    [QueryKeys.GET_LICENSE_DETAILS, reference_id],
    async () => {
      try {
        const data = await getLicenseInfo(reference_id)
        return data
      } catch (error) { }
    },
    { enabled: !!reference_id, keepPreviousData: true }
  )
}
export const useUpdatePeronalFinanceInfo = (dealer_code?: string) => {
  const queryClient = useQueryClient()
  const { data, isLoading, error, mutate } = useMutation(
    (body: any) => {
      const { reference_id, ...restPayload } = body
      let url = ADD_FINANCE_DETAILS + `?reference_id=${reference_id}`
      if (dealer_code) {
        url += `&dealer_code=${encodeURIComponent(dealer_code)}`
      }
      const apiService = Http.createConnection()
      return apiService.patch<any>(url, restPayload)
    },
    {
      onSuccess: async (_response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_PERSONAL_FINANCE)
      }
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetIntegrationByProviderName = (
  provider_name: string,
  integration_type: string
): any => {
  const { states } = useStoreContext()
  const url = EP_GET_INTEGRATION_BY_PROVIDER_NAME
  const { data, isLoading, error, mutate } = useMutation(() => {
    const apiService = Http.createConnection({
      baseUrl: process.env.API_GATEWAY
    })
    headers.company_id = states?.dealerInfo?.company_id
    return apiService.get<any>(
      `${url}/${states?.dealerInfo?.dealer_code}/${integration_type}/${provider_name}`,
      {},
      { headers }
    )
  })
  return { data, isLoading, error, mutate }
}

export const useAddLicenseDetailsToMitek = (): any => {
  const { data, mutate, error } = useMutation<IMitekInterface, any>(
    async (body) => {
      const apiService = Http.createConnection()
      return await apiService.post<any>(LICENSE_VERIFICATION, body)
    },
    {
      onSuccess(_response: any) {

      }
    }
  )
  return { data, mutate, error: error?.message }
}

const getGetEmployInfo = (reference_id: string) => {
  const apiService = Http.createConnection()
  return apiService.get<any>(
    EP_GET_EMPLOYMENT_INFO + `?reference_id=${encodeURIComponent(reference_id)}`,
    {}
  )
}

export const UseQuery_Get_CustomerEmployment = (reference_id: string) => {
  return useQuery(
    [QueryKeys.GET_CUSTOMER_EMPLOYMENT_DETAILS, reference_id],
    async () => {
      try {
        const data = await getGetEmployInfo(reference_id)
        return data
      } catch (error) { }
    },
    { keepPreviousData: true }
  )
}

export const useGetLenderIntegrations = (): any => {
  const { states } = useStoreContext()
  const { data, isLoading, error, mutate } = useMutation(
    () => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      headers.company_id = states?.dealerAndLenderInfo?.company_id
      return apiService.get<any>(EP_GET_LENDER_INTEGRATIONS, {}, { headers })
    },
    {
      onSuccess(_response: any) { }
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetCustomerSignatures = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.get<any>(`${EP_CUSTOMER_SIGNATURE}?reference_id=${body.reference_id}`, {})
    },
    {
      onSuccess() { }
    }
  )
  return { data, isLoading, mutate }
}

export const useGetCustomerSignaturesByDealer = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.get<any>(
        `${EP_CUSTOMER_SIGNATURE_BY_DEALER}?dealer_id=${body.dealer_id}`,
        {}
      )
    },
    {
      onSuccess() { }
    }
  )
  return { data, isLoading, mutate }
}

export const useSaveCustomerSignatures = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.post<any>(`${EP_CUSTOMER_SIGNATURE}`, body)
    },
    {
      onSuccess() { }
    }
  )
  return { data, isLoading, mutate }
}

export const useSaveCustomerDocumentSignature = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.post<any>(`${EP_CUSTOMER_DOCUMENT_SIGNATURE}`, body)
    },
    {
      onSuccess() { }
    }
  )
  return { data, isLoading, mutate }
}

export const useGetDMSCUSTOMER = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.get<any>(
        `${EP_CHECK_DMS_CUSTOMER}?reference_id=${encodeURIComponent(
          body.reference_id
        )}&dealer_code=${encodeURIComponent(body.dealer_code)}`,
        {}
      )
    },
    {
      onSuccess() { }
    }
  )
  return { data, isLoading, mutate }
}

export const useUpdateDMSCUSTOMER = () => {
  const { data, isLoading, error, mutate } = useMutation((body: any) => {
    let url = EP_UPDATE_DMS_CUSTOMER + `?reference_id=${encodeURIComponent(body?.reference_id)}`
    if (body?.dealer_code) {
      url += `&dealer_code=${encodeURIComponent(body?.dealer_code)}`
    }
    const apiService = Http.createConnection()
    return apiService.patch<any>(url)
  })
  return { data, isLoading, error, mutate }
}

export const useAddLicenseByPass = (): any => {
  const queryClient = useQueryClient()
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.post<any>(`${EP_CUSTOMER_LICENSE_INFO_BY_PASS}`, body)
    },
    {
      onSuccess() {
        queryClient.invalidateQueries('Order')
       }
    }
  )
  return { data, isLoading, mutate }
}
