export enum ToastMessages {
  Record_Added_Success = 'Record Added Successfully',
  Record_Updated_Success = 'Record Updated Successfully',
  Record_Deleted_Success = 'Record Deleted Successfully',
  VERIFY_ADDRESS = 'Please Verify Address First',
  SAVE_AS_DRAFT = 'Program has been saved in draft state as mandatory information is missing',
  DISABLE_TOGGLE = 'Draft program cannot be enabled',
  Feedback_Submitted_Successfully = 'Feedback Submitted Successfully',
  RECORD_ALREADY_IN_USE = 'This record is already in use',
  PROFILE_UPDATED_SUCCESS = 'Profile Updated Successfully',
  CHANGE_PASSWORD_SUCCESS = 'Password Changed Successfully',
  SEND_ORDER_LINK_SUCCESS = 'Deal link successfully sent to the customer',
  SEND_ORDER_LINK_FAILED = 'Failed to send deal link',
  INTERNAL_SERVER_ERROR = "Internal Server Error"
}
