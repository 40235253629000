import { FC, useEffect, useMemo, useState } from 'react'
import { SelectChangeEvent, useTheme } from '@mui/material'
import { Box, Button, Icon, Select } from '@ntpkunity/controls'
import { BtnTabs, ConfirmationDialog } from '@components'
import {
  DeskingActionTypes,
  FinanceType,
  FinanceTypes,
  useDeskingContext,
  VehiclesForComparisonTypes
} from './desking-context-provider'
import { useStoreContext } from '@store/storeContext'
import { useEditPermissionHook } from '@hooks/useEditPermissionHook'
import { DialogMessages } from '@helpers/enums'
import { dealButtonOptions } from '@helpers/constants'
import { getDealerDealTypes } from './utils'

interface FinanceLeaseButtonProps {
  financeType: FinanceType
  isSelected: boolean
  text: string
  disabled?: boolean
  handleDealTypeClick?: (financeType: FinanceType) => void
}

export const FinanceLeaseButton: FC<FinanceLeaseButtonProps> = ({
  financeType,
  isSelected,
  text,
  disabled = false,
  handleDealTypeClick
}) => {
  const theme = useTheme()

  return (
    <Button
      disabled={disabled}
      theme={theme}
      type="button"
      text={text}
      defaultBtn
      className={isSelected ? 'selected' : ''}
      onClick={() => { handleDealTypeClick?.(financeType) }}
    />
  )
}

export const FLButtons = ({ selectedVehicleVin }: { selectedVehicleVin?: string }) => {
  const theme = useTheme()
  const { state, dispatch } = useDeskingContext()
  const {
    states: {
      dealDesk: { dealerProfile }
    }
  } = useStoreContext()
  const [showFAndIRemove, setShowFAndIRemove] = useState<{ show: boolean, type: any }>({ show: false, type: '' })
  const [selectedMultipleFinanceType, setSelectedMultipleFinanceType] = useState<string>('')

  const { finance_type, order, vehiclesData, vehiclesForComparison, selectedVehicleVin: vehicleVin, vehiclesVins } = state
  const isMultiVehicle = vehiclesForComparison === VehiclesForComparisonTypes.MULTIPLE
  const vin = isMultiVehicle ? selectedVehicleVin : vehicleVin
  const selectedVehicleDealType = vehiclesData?.get(vehicleVin.length ? vehicleVin : vehiclesVins[0]) ?? ''
  const vehicleDetails = vehiclesData?.get(vin ?? '') ?? {}
  const { fni, rebates, finance_type: multiVehicleDealType } = vehicleDetails

  const canUserEdit = useEditPermissionHook()
  let allowedDealTypes = getDealerDealTypes(
    vehicleDetails,
    dealerProfile,
    state.vehiclesForComparison === VehiclesForComparisonTypes.SINGLE,
    selectedVehicleDealType?.finance_type
  )

  const dealTypeFinalOptions = useMemo(() => {
    return dealButtonOptions?.filter(item => Object.keys(state.order)?.length ? item?.type === finance_type
      : allowedDealTypes.includes(item?.type)
    )
  }, [allowedDealTypes, dealButtonOptions])

  useEffect(() => {
    if (typeof multiVehicleDealType !== 'undefined') setSelectedMultipleFinanceType(multiVehicleDealType)
  }, [multiVehicleDealType])

  useEffect(() => {
    const defaultSelectedDeal = dealButtonOptions.find(
      ({ type }) => Object.keys(state.order)?.length ? type === finance_type
        : allowedDealTypes.includes(type)
    )
    if (!defaultSelectedDeal?.type) return
    if (!multiVehicleDealType) setSelectedMultipleFinanceType(defaultSelectedDeal?.type)
    if (state.vehiclesForComparison === VehiclesForComparisonTypes.SINGLE && !state.vehiclesVins?.length) dispatch({
      type: DeskingActionTypes.UPDATE_FINANCE_TYPE,
      payload: order?.finance_type ?? defaultSelectedDeal?.type
    })
  }, [])

  const handleDealTypeClick = (financeType: FinanceType) => {
    const lowerFinanceType = finance_type.toLowerCase()
    const hasFinanceData = (fni?.[lowerFinanceType]?.length || rebates?.length)

    if (finance_type === FinanceTypes.CASH && hasFinanceData) {
      setShowFAndIRemove({ show: true, type: financeType })
      return
    }

    dispatch({
      type: DeskingActionTypes.UPDATE_FINANCE_TYPE,
      payload: financeType
    })
  }

  const handleRemoveFAndIForCashDeal = async () => {
    dispatch({
      type: DeskingActionTypes.UPDATE_OPTIONS_FEES_FNI,
      payload: {
        vinUOFF: vin,
        ...vehiclesData?.get(vin ?? '') ?? {},
        fni: { finance: [], lease: [], cash: [] } as any,
        rebates: []
      }
    })
    dispatch({
      type: DeskingActionTypes.UPDATE_FINANCE_TYPE,
      payload: showFAndIRemove.type
    })
    setShowFAndIRemove({ show: false, type: '' })
  }

  const handleChangeDealTypeDropdown = (e: SelectChangeEvent<unknown>) => {
    const financeType: string = e.target.value as string
    setSelectedMultipleFinanceType(financeType)
    if (financeType && selectedVehicleVin) {
      dispatch({
        type: DeskingActionTypes.UPDATE_FINANCE_TYPE_MULTI_VEHICLE,
        payload: {
          multiVehicleFinance: financeType,
          multiVehicleVin: selectedVehicleVin as string
        }
      })
    }
  }

  return (
    <>
      {state.vehiclesForComparison === VehiclesForComparisonTypes.MULTIPLE
        ? <Select
          theme={theme}
          fullWidth
          disablePortal
          items={dealTypeFinalOptions?.map(item => { return { value: item.text, text: item.text } })}
          value={selectedMultipleFinanceType}
          onChange={handleChangeDealTypeDropdown}
        />
        : <Box theme={theme} className="flc-li-left-col">
          <BtnTabs theme={theme} className="btn-tabs with-spr">
            {!order?.id ? (
              <>
                {dealTypeFinalOptions?.map(({ type, text }) => (
                  <FinanceLeaseButton
                    key={type}
                    financeType={type}
                    isSelected={finance_type === type}
                    text={text}
                    disabled={type !== FinanceTypes.FINANCE && !canUserEdit}
                    handleDealTypeClick={handleDealTypeClick}
                  />
                )
                )}
              </>
            ) : (
              <FinanceLeaseButton
                financeType={finance_type}
                isSelected={true}
                text={finance_type}
                disabled={!canUserEdit}
              />
            )}
          </BtnTabs>
          {!!showFAndIRemove.show && (
            <ConfirmationDialog
              openPopUp={showFAndIRemove.show}
              confirmationTitle={DialogMessages.fniAndMspRemoveTitle}
              confirmationText={DialogMessages.fniAndMspRemoveSubTitle}
              primaryBtnText={DialogMessages.fniAndMspRemoveBtnText}
              icon={<Icon className="alert-icon" name="DialogAlertIcon" />}
              hideCancelButton={false}
              setPopUpState={() => { }}
              onConfirm={handleRemoveFAndIForCashDeal}
              onCancel={() => setShowFAndIRemove({ show: false, type: '' })}
            />
          )}
        </Box>
      }
    </>
  )
}
